import { Reducer } from 'redux';
import { SitesActionTypes } from '../sites/types';
import { CommonActionTypes, CommonState, LiveDataTypes, ToastType } from './types';

const defaultWSUrl = `${process.env.REACT_APP_WEBSOCKET_HOST || 'ws://localhost:21234'}`;
export const initialState: CommonState = {
  // TODO(anttipalola, 20190604) Process websocket address from eg. cloud API
  websocketAddress: defaultWSUrl,
  connected: false,
  isReceivingData: true,
  lastEventTime: 0,
  toast: {
    isOpen: false,
    messageKey: '',
    type: ToastType.DEFAULT,
  },
};

const reducer: Reducer<CommonState> = (state = initialState, action) => {
  switch (action.type) {
    case CommonActionTypes.SHOW_TOAST:
      return {
        ...state,
        toast: { isOpen: true, messageKey: action.payload.messageKey, type: action.payload.type },
      };
    case CommonActionTypes.HIDE_TOAST:
      return { ...state, toast: { isOpen: false, messageKey: '', type: ToastType.DEFAULT } };
    case LiveDataTypes.CONNECTION_ERROR:
      return { ...state, connected: false };
    case LiveDataTypes.CONNECTION_SUCCESS:
      return { ...state, connected: true };
    case LiveDataTypes.DISCONNECT_SUCCESS:
      return { ...state, connected: false };
    case LiveDataTypes.SET_IS_RECEIVING_DATA:
      return { ...state, isReceivingData: action.payload };
    case LiveDataTypes.INCOMING_EVENT:
    case LiveDataTypes.UPDATE_LAST_EVENT_TIME:
    case SitesActionTypes.START_SITES_POLL:
    case SitesActionTypes.SELECT_SITE: {
      return { ...state, lastEventTime: new Date().getTime() };
    }
    default: {
      return state;
    }
  }
};

export { reducer as commonReducer };
