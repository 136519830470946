import React, { useCallback, useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';

import { connect, localeConnect } from 'localeConnect';
import { ApplicationState } from 'store';
import { ContainerItem, ContainerType, DocType, MasterDataItem } from 'store/common/types';
import { joinUniqueMasterDataItemNames } from 'store/common/utils';

import { listOrders, showOrderInfo } from 'store/orders/actions';
import {
  Order,
  ListOrderQueryParams,
  ListOrderQueryMode,
  OrderResults,
  OrderStatus,
  OperatorOrderMode,
  OperatorOrderTab,
} from 'store/orders/types';
import { formatOrderTitle } from 'store/orders/utils';
import { RoutePaths } from 'routes';

import { operatorPaginationStyles } from 'theme';
import { translate } from 'utils/translate';
import Pagination from '../Pagination';
import RegisterNumber from '../RegisterNumber';
import OperatorTable, {
  OperatorTableMenu,
  OperatorTableMenuItem,
  OperatorTableColumn,
} from './OperatorTable';

// TODO(mikkogy,20200508) ideally cleanup day limit should be defined in one
// place and be included in scale info. At the time of implementing changes were
// not expected so cutting corners a little bit was considered OK.
const closedOrderCleanupDayLimit = 50;

const oneCloudUrl = `${process.env.REACT_APP_PILVILINNA_URL || 'http://localhost/'}`;

const useStyles = makeStyles({
  orderTable: {
    marginBottom: '30px',
  },
  ...operatorPaginationStyles,
});

interface PropsFromState {
  queryOrders: OrderResults;
  queryParams: ListOrderQueryParams;
}

interface PropsFromDispatch {
  listOrders: typeof listOrders;
  showOrderInfo: typeof showOrderInfo;
}

/**
 * NOTE(mikkogy, 20200507) There's a bug in Eslint config that has been reported but not yet fixed
 * which complains about missing indentation when multiline binary expressions are used.
 * See: https://github.com/typescript-eslint/typescript-eslint/issues/398
 */
/*eslint-disable */
export type AllProps = PropsFromDispatch & PropsFromState;
/*eslint-enable */

const mapStateToProps = (state: ApplicationState) => ({
  queryOrders: state.orders.operatorQueryResults,
  queryParams: state.orders.operatorQueryParams,
});

const mapDispatchToProps = {
  listOrders: listOrders,
  showOrderInfo,
};

function getMasterDataItems(order: Order, docType: DocType) {
  if (!order.linkedData) {
    return [];
  }
  return order.linkedData.filter((link: MasterDataItem) => link.docType === docType);
}

function getMasterDataName(order: Order, docType: DocType) {
  if (!order.linkedData) {
    return [];
  }
  return joinUniqueMasterDataItemNames(order.linkedData, docType);
}

function getTrucks(order: Order) {
  return getMasterDataItems(order, DocType.CONTAINER).filter(
    (container: MasterDataItem) =>
      (container as ContainerItem).containerType === ContainerType.TRUCK,
  );
}

function registerNumbers(order: Order) {
  return (
    <div>
      {getTrucks(order).map((truck: MasterDataItem) => (
        <RegisterNumber key={truck.key} text={truck.name} />
      ))}
    </div>
  );
}

function getCustomer(order: Order) {
  return getMasterDataName(order, DocType.CUSTOMER);
}

function getMaterial(order: Order) {
  return getMasterDataName(order, DocType.MATERIAL);
}

function getTransportCo(order: Order) {
  return getMasterDataName(order, DocType.TRANSPORT_CO);
}

function hasOrderTruck(order: Order) {
  return getTrucks(order).length > 0;
}

function hasOrderJob(order: Order) {
  if (!order.realization) {
    return false;
  }
  const keys = Object.keys(order.realization);
  for (const i in keys) {
    const realization = order.realization[keys[i]];
    if (realization.jobs && realization.jobs.length > 0) {
      return true;
    }
  }
  return false;
}

function getContact(order: Order) {
  const customers = getMasterDataItems(order, DocType.CUSTOMER);
  if (customers.length === 0) {
    return '';
  }
  const customer = customers[0];
  if (customer.card && customer.card.tel && customer.card.tel.value) {
    return customer.card.tel.value;
  }
  if (customer.card && customer.card.email && customer.card.email.value) {
    return customer.card.email.value;
  }
  return '';
}

const OperatorQueryOrders = (props: AllProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<{ [key: string]: Element }>({});

  const { listOrders, queryParams } = props;
  const doQuery = useCallback(
    (queryParams: ListOrderQueryParams) => {
      listOrders(queryParams.pagination, queryParams.status);
    },
    [listOrders],
  );

  useEffect(() => {
    doQuery(queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const queryOrders = props.queryOrders;
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, key: string) => {
    const anchorEl: { [key: string]: Element } = {};
    anchorEl[key] = event.currentTarget;
    setAnchorEl(anchorEl);
  };

  const handleMenuClose = () => {
    setAnchorEl({});
  };

  const columns: OperatorTableColumn[] = [
    {
      key: 'externalId',
      translationKey: 'operatorOrder.orders.externalId',
      dataGetter: (order: Order) => formatOrderTitle(order.externalId, order.closePolicy),
      useLongTextStyle: true,
    },
    {
      key: 'status',
      translationKey: 'operatorOrder.orders.status',
      dataGetter: (order: Order) => translate(`operatorOrder.orders.statuses.${order.status}`),
      useLongTextStyle: false,
    },
    {
      key: 'registerNumbers',
      translationKey: 'operatorOrder.orders.registerNumber',
      dataGetter: (order: Order) => registerNumbers(order),
      useLongTextStyle: false,
    },
    {
      key: 'customer',
      translationKey: 'operatorOrder.orders.customer',
      dataGetter: (order: Order) => getCustomer(order),
      useLongTextStyle: true,
    },
    {
      key: 'material',
      translationKey: 'operatorOrder.orders.material',
      dataGetter: (order: Order) => getMaterial(order),
      useLongTextStyle: true,
    },
    {
      key: 'transportCo',
      translationKey: 'operatorOrder.orders.transportCo',
      dataGetter: (order: Order) => getTransportCo(order),
      useLongTextStyle: true,
    },
    {
      key: 'contact',
      translationKey: 'operatorOrder.orders.contact',
      dataGetter: (order: Order) => getContact(order),
      useLongTextStyle: false,
    },
    {
      key: 'menu',
      dataGetter: (order: Order) => {
        const isOpen = order.status === OrderStatus.OPEN;
        return (
          <OperatorTableMenu
            id={order.key}
            idPrefix="in-progress-menu"
            anchorEl={anchorEl[order.key]}
            handleClick={handleMenuClick}
            handleClose={handleMenuClose}
          >
            <OperatorTableMenuItem
              onClick={() => {
                props.showOrderInfo(
                  order.key,
                  OperatorOrderTab.Weighing,
                  isOpen ? OperatorOrderMode.Weighing : OperatorOrderMode.Viewing,
                );
              }}
              handleClose={handleMenuClose}
              disabled={
                !order ||
                !order.key ||
                order.status === OrderStatus.DISCARDED ||
                (isOpen && !hasOrderTruck(order)) ||
                (!isOpen && !hasOrderJob(order))
              }
              routePath={RoutePaths.OPERATOR_ORDER}
              text={translate(
                isOpen ? 'operatorOrder.orders.doWeighing' : 'operatorOrder.orders.viewReceipt',
              )}
            />
            <OperatorTableMenuItem
              onClick={() => {
                props.showOrderInfo(
                  order.key,
                  OperatorOrderTab.Order,
                  isOpen ? OperatorOrderMode.Weighing : OperatorOrderMode.Viewing,
                );
              }}
              handleClose={handleMenuClose}
              disabled={false}
              routePath={RoutePaths.OPERATOR_ORDER}
              text={translate('operatorOrder.orders.viewOrder')}
            />
          </OperatorTableMenu>
        );
      },
      useLongTextStyle: false,
    },
  ];

  const pagination = {
    currentPageItemCount: queryOrders.orders.length,
    itemCount: queryOrders.pagination.itemCount,
    pageNumber: queryOrders.pagination.pageNumber,
    pageSize: queryOrders.pagination.pageSize,
  };

  const isShowingOnlyOpenOrders =
    queryOrders.status === ListOrderQueryMode.OPEN_BY_ACCEPTANCE ||
    queryOrders.status === ListOrderQueryMode.OPEN_BY_TIME;

  return (
    <div>
      <div className={classes.pagination}>
        <Pagination
          pagination={pagination}
          showPage={(pageNumber: number) => {
            const params = { ...props.queryParams };
            params.pagination.pageNumber = pageNumber;
            doQuery(params);
          }}
        />
      </div>
      <div>
        {queryOrders.orders.length > 0 && (
          <div className={classes.orderTable}>
            <OperatorTable
              columns={columns}
              data={queryOrders.orders}
              keyGetter={(order: Order) => order.key}
            />
          </div>
        )}
        {queryOrders.orders.length === 0 && (
          <div>{translate('operatorOrder.orders.noResults')}</div>
        )}
        {!isShowingOnlyOpenOrders && (
          <div
            dangerouslySetInnerHTML={{
              __html: translate('operatorOrder.orders.oneCloudOrderHint', {
                days: closedOrderCleanupDayLimit,
                url: oneCloudUrl,
              }),
            }}
          />
        )}
      </div>
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(OperatorQueryOrders);
