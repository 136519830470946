export enum SupportedLanguage {
  fi = 'fi',
  en = 'en',
  sv = 'sv',
  pl = 'pl',
  da = 'da',
  de = 'de',
  cs = 'cs',
}

export const getLanguageFromLocale = (locale: string | undefined): SupportedLanguage => {
  if (!locale) {
    return SupportedLanguage.en;
  }

  const localeParts = locale.split('_');
  const language = localeParts[0] as SupportedLanguage;
  const supportedLanguages = Object.values(SupportedLanguage);

  return supportedLanguages.includes(language) ? language : SupportedLanguage.en;
};
