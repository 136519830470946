import React from 'react';
import { translate } from 'utils/translate';

import OperatorContainer from './OperatorContainer';
import OperatorGenericContainerTable from './OperatorGenericContainerTable';

const OperatorGenericContainers = () => {
  return (
    <OperatorContainer
      title={translate('operatorGenericContainers.title')}
      title2={translate('operatorGenericContainers.title2')}
    >
      <OperatorGenericContainerTable />
    </OperatorContainer>
  );
};

export default OperatorGenericContainers;
