import { eventChannel, END } from 'redux-saga';
import {
  call,
  delay,
  put,
  take,
  takeEvery,
  takeLatest,
  takeMaybe,
  fork,
  cancel,
  select,
  all,
  race,
} from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import { createBrowserHistory } from 'history';
import { RoutePaths } from 'routes';
import {
  getBridgeWebsocketAddress,
  getEnabledFeatures,
  getLastEventTime,
  getLatestStatusTime,
  getSelectedUiRole,
  getSite,
  getToken,
  getUser,
  getWebsocketConnected,
} from '../selectors';
import { loadHistoryItems } from '../master-data/actions';
import { changeSite } from '../sites/actions';
import { SitesActionTypes } from '../sites/types';
import { destroyToken, logout } from '../user/actions';
import { updateStoreUserDomains } from '../user/sagas';
import { UserActionTypes, UserDomain } from '../user/types';
import { isUserInDomain } from '../user/utils';
import { effectiveUiRole, parseDocumentKey } from '../utils';
import {
  CommonActionTypes,
  LiveDataTypes,
  RemoteReceiveTypes,
  RemoteRequestIds,
  RemoteRequestTypes,
  ToastType,
} from './types';
import {
  connectionError,
  connectionSuccess,
  connectionRequest,
  disconnectRequest,
  disconnectSocketSaga,
  disconnectSocketTasks,
  disconnectSuccess,
  hello,
  hideToast,
  incomingEvent,
  newToast,
  sendMessage,
  setIsReceivingData,
  showToast,
  updateLastEventTime,
} from './actions';

const history = createBrowserHistory();

function createWebSocketConnection(address: string, scaleKey: string) {
  const parts = parseDocumentKey(scaleKey);
  const organizationId = parts.organizationId;
  const scaleId = parts.id;
  return new Promise((resolve, reject) => {
    const socket = new WebSocket(`${address}?organization=${organizationId}&scale=${scaleId}`);

    socket.onopen = function () {
      console.log('Socket opened');
      resolve(socket);
    };

    socket.onerror = function (evt) {
      // NOTE(mikkogy,20191204) saga does not seem to bubble the exception if
      // promise is rejected so we can't just catch it. We need to resolve with
      // null and check the resolved value in the calling code.
      resolve(null);
    };
  });
}

function createSocketChannel(socket: WebSocket) {
  return eventChannel((emit) => {
    socket.onmessage = (event) => {
      emit(event.data);
    };

    socket.onclose = () => {
      emit(END);
    };

    const unsubscribe = () => {
      socket.onmessage = null;
    };

    return unsubscribe;
  });
}

/**
 * External listener for websocket incoming messages
 */
function* receiveMessageListener(socketChannel: ReturnType<typeof createSocketChannel>): any {
  while (true) {
    const payload = yield take(socketChannel);
    try {
      const message = JSON.parse(payload);
      if (message.msgType === RemoteReceiveTypes.ERROR) {
        yield put(connectionError(message.payload));
      } else {
        yield put(incomingEvent(message));
      }
    } catch (e: any) {
      yield put(connectionError(e));
      break;
    }
  }
}

/**
 * Internal listener for sending messages to websocket
 */
function* sendMessageListener(socket: WebSocket): any {
  while (true) {
    try {
      const data = yield take(LiveDataTypes.SEND_MESSAGE);
      data.payload.authorization = yield select(getToken);
      if (socket.readyState === WebSocket.CLOSING || socket.readyState === WebSocket.CLOSED) {
        console.log(
          'Websocket is closing or closed. Destroying token. Socket state:',
          socket.readyState,
        );
        // NOTE(mikkogy,20210622) at this point we are not sure but it is likely
        // that token is expired. Destroy it to enable trying again.
        yield put(destroyToken());
        break;
      }
      socket.send(JSON.stringify(data.payload));
    } catch (e) {
      console.log('error in sending message', e);
      break;
    }
  }
}

function* listenForSocketMessages(): any {
  const address = yield select(getBridgeWebsocketAddress);
  const scaleKey = yield select(getSite);
  if (!scaleKey) {
    yield put(replace(RoutePaths.SITES));
    return;
  }

  const lastEventTime = yield select(getLastEventTime);
  const timeoutMinutes = process.env.REACT_APP_SITE_TIMEOUT_MINUTES || '300';
  const timeoutMs = parseInt(timeoutMinutes, 10) * 60 * 1000;
  if (lastEventTime + timeoutMs < new Date().getTime()) {
    console.log('Clearing selected site due to timeout');
    yield put(changeSite(true));
    yield put(updateLastEventTime());
    return;
  } else {
    console.log('Not clearing selected site');
  }

  const user = yield select(getUser);
  if (!user.organizationData.key) {
    // NOTE(mikkogy,20210712) in staging environment we have seen a case where
    // we did not have organization key due to failed response from server. If
    // it happens pretty much only good option is to log out.
    yield put(logout());
    return;
  }
  if (user.organizationData.key !== parseDocumentKey(scaleKey).organizationId) {
    console.log('Clearing selected site because it is in another organization');
    yield put(changeSite(true));
    yield put(updateLastEventTime());
    return;
  }

  console.log('Creating WS connection to', address, 'scale', scaleKey);

  const socket = yield call(createWebSocketConnection, address, scaleKey);
  if (socket === null) {
    yield put(disconnectSuccess());
    return;
  }
  const socketChannel = yield call(createSocketChannel, socket);

  yield put(connectionSuccess());
  try {
    const sendTask = yield fork(sendMessageListener, socket);
    const receiveTask = yield fork(receiveMessageListener, socketChannel);
    yield take(LiveDataTypes.DISCONNECT_SOCKET_TASKS);
    yield cancel(sendTask);
    yield cancel(receiveTask);
  } finally {
  }
  try {
    socketChannel.close();
  } finally {
  }
  try {
    socket.close();
  } finally {
  }
}

function* handleConnect(): any {
  while (true) {
    try {
      yield take(LiveDataTypes.CONNECTION_REQUEST);
      const siteScaleKey = yield select(getSite);
      if (!siteScaleKey) {
        // NOTE(mikkogy,20221128) better to ignore connection request for now
        // because without site connecting is not attempted and the next request
        // will have to wait for error checking to clean up the failed attempt.
        continue;
      }
      const socketTask = yield fork(listenForSocketMessages);
      yield takeMaybe(LiveDataTypes.DISCONNECT_SOCKET_SAGA);
      yield cancel(socketTask);
      yield put(disconnectSuccess());
    } catch (err) {}
  }
}

function* handleConnectionSuccess() {
  yield put(hello());
  yield put(loadHistoryItems());
}

function* handleHello(action: ReturnType<typeof hello>) {
  const payload = {
    msgType: RemoteRequestTypes.HELLO,
    reqId: RemoteRequestIds.HELLO,
  };
  yield put(sendMessage(payload));
}

function* handleNewToast(action: ReturnType<typeof newToast>) {
  const uiRole = effectiveUiRole(
    yield select(getSelectedUiRole),
    yield select(getUser),
    yield select(getEnabledFeatures),
  );
  const { context, messageKey, type } = action.payload;
  // NOTE(mikkogy,20200212) for convenience it's not needed to define role at
  // all if all contexts are relevant. Typically we want to block some toasts
  // for some roles in most contexts.
  if (context[uiRole]) {
    const routePath = history.location.pathname as RoutePaths;
    if (context[uiRole].indexOf(routePath) < 0) {
      console.log('skipped irrelevant toast in current context', messageKey);
      return;
    }
  }
  const toastQueueAnimationDelayMsStr = `${
    process.env.REACT_APP_TOAST_QUEUE_ANIMATION_DELAY_MS || '250'
  }`;
  const toastQueueAnimationDelayMs = parseInt(toastQueueAnimationDelayMsStr);
  yield delay(toastQueueAnimationDelayMs);
  yield put(hideToast());
  yield delay(toastQueueAnimationDelayMs);
  yield put(showToast(messageKey, type));
}

function* handleLoginOrFetch() {
  yield put(connectionRequest());
}

function* watchConnectRequest() {
  yield takeEvery(SitesActionTypes.SELECTED, handleLoginOrFetch);
  yield takeEvery(UserActionTypes.LOGIN_SUCCESS, handleLoginOrFetch);
  yield takeEvery(UserActionTypes.FETCH_SUCCESS, handleLoginOrFetch);
}

function* handleDisconnect() {
  yield put(disconnectSocketTasks());
  yield put(disconnectSocketSaga());
}

function* handleDisconnectRequest() {
  yield takeEvery(LiveDataTypes.DISCONNECT_REQUEST, handleDisconnect);
}

function* isReceivingData(): any {
  const expiredStatusS = `${process.env.REACT_APP_EXPIRED_STATUS_S || '10'}`;
  const expiredStatusMs = parseInt(expiredStatusS, 10) * 1000;
  const isConnected = yield select(getWebsocketConnected);
  const latestStatusTime = yield select(getLatestStatusTime);
  return isConnected && latestStatusTime + expiredStatusMs >= new Date().getTime();
}

function* connectionErrorChecker(): any {
  const delayS = `${process.env.REACT_APP_CONNECTION_POLL_S || '10'}`;
  while (true) {
    try {
      yield delay(parseInt(delayS, 10) * 1000);

      const user = yield select(getUser);
      if (user.isAuthed) {
        const isReceiving = yield call(isReceivingData);
        if (!isReceiving) {
          let selectedScale = yield select(getSite);
          yield put(setIsReceivingData(!selectedScale));
          yield put(disconnectRequest());
          // NOTE(mikkogy,20200826) wait for disconnect but not forever as
          // connection may have failed already and error checking must keep
          // running.
          yield race([takeMaybe(LiveDataTypes.DISCONNECT_SUCCESS), delay(3000)]);
          // NOTE(mikkogy,20200826) in case of timeout site may have been
          // selected while waiting.
          selectedScale = yield select(getSite);
          if (!!selectedScale) {
            const userDomains: UserDomain[] = yield call(updateStoreUserDomains);
            const isDomainUser =
              isUserInDomain(userDomains, user.userData.organization) ||
              isUserInDomain(userDomains, selectedScale);
            if (!isDomainUser && !!selectedScale) {
              yield put(newToast('errorUserNotInDomain', ToastType.ERROR));
            }
            yield put(connectionRequest());
          }
        }
      }
    } catch (err) {}
  }
}

function* connectionSuccessChecker(): any {
  while (true) {
    try {
      yield delay(1000);
      const user = yield select(getUser);
      if (user.isAuthed) {
        const isReceiving = yield call(isReceivingData);
        if (isReceiving) {
          yield put(setIsReceivingData(true));
        }
      } else {
        // NOTE(mikkogy,20191204) although technically we are not receiving data
        // it's best to initialize value back to true to clear errors.
        yield put(setIsReceivingData(true));
      }
    } catch (err) {}
  }
}

function* watchConnectionSuccess() {
  yield takeEvery(LiveDataTypes.CONNECTION_SUCCESS, handleConnectionSuccess);
}

function* watchHello() {
  yield takeEvery(LiveDataTypes.HELLO, handleHello);
}

function* watchNewToast() {
  yield takeLatest(CommonActionTypes.NEW_TOAST, handleNewToast);
}

function* websocketSaga() {
  yield all([
    fork(connectionErrorChecker),
    fork(connectionSuccessChecker),
    fork(handleConnect),
    fork(watchConnectRequest),
    fork(handleDisconnectRequest),
    fork(watchConnectionSuccess),
    fork(watchHello),
    fork(watchNewToast),
  ]);
}

export default websocketSaga;
