import React from 'react';
import { makeStyles } from '@mui/styles';

import { containerValidLoads } from 'models/multipart.model';

import { Job } from 'store/jobs/types';
import { jobsTrucksAndTrailers } from 'store/jobs/utils';
import { Order } from 'store/orders/types';
import { secondaryContainer, secondaryContainerTareKgs } from 'store/orders/utils';
import { FormatUnit, undefinedMassKg } from 'store/weighing/types';
import theme from 'theme';
import { translate } from 'utils/translate';

import SecondaryContainerTareFeature from '../SecondaryContainerTareFeature';

import { formatMass } from '../utils';

function formatKgs(kgs: number | undefined) {
  if (kgs === undefined) return formatMass(undefinedMassKg, FormatUnit.WITHOUT_UNIT);
  return formatMass(kgs);
}

const useStyles = makeStyles({
  containerKgs: {
    fontSize: '1.1rem',
    fontWeight: 600,
    marginTop: '10px',
  },
  deduction: {
    color: theme.palette.error.main,
  },
  label: {
    marginRight: '30px',
  },
});

interface MultipartJobContainerKgProps {
  componentId: string;
  job: Job;
  order: Order;
}

const MultipartJobContainerKgs = (props: MultipartJobContainerKgProps) => {
  const classes = useStyles();
  const containers = jobsTrucksAndTrailers([props.job]);
  const deductionContainerName = secondaryContainer(props.order, props.componentId)?.name;
  const deductionKgs = secondaryContainerTareKgs(props.order, props.componentId);
  return (
    <div className={classes.containerKgs}>
      <div>
        {containers.map((container) => {
          const validLoads = containerValidLoads(props.job, container);
          const kgs = validLoads.length > 1 ? container.sumMassKg : undefined;
          return (
            <div key={container.key}>
              <div>
                <span className={classes.label}>
                  {translate(`weighing.containerTypes.${container.containerType}`)}
                </span>
                <span>{formatKgs(kgs)}</span>
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <SecondaryContainerTareFeature componentId={props.componentId} order={props.order}>
          <span className={classes.label}>{translate('orderDetails.containerDeductionTitle')}</span>
          <span className={classes.deduction}>
            {translate('orderDetails.containerDeduction', {
              kgs: formatKgs(-deductionKgs),
              containerName: deductionContainerName,
            })}
          </span>
        </SecondaryContainerTareFeature>
      </div>
    </div>
  );
};

export default MultipartJobContainerKgs;
