import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';
import { v4 as uuid } from 'uuid';

import { connect, localeConnect } from 'localeConnect';

import { NetContainer, clearMainMaterials } from 'models/vehicleweighing.model';

import { GenericMasterData, MasterDataState } from 'store/master-data/types';
import { undefinedMassKg } from 'store/weighing/types';
import { getNormalWeighingActiveComponentLinkedData } from 'store/orders/utils';
import { Context } from 'store/orders/types';
import { BridgeEnabledFeatures } from 'store/scale-info/types';
import { Deduction, DeductionRow, DeductionOperation } from 'store/jobs/types';
import { areDeductionKgsValid, getManualDeductions, hasDeductionError } from 'store/jobs/utils';

import { ContainerType, MasterDataItem, RemoteMasterDataType } from 'store/common/types';

import { ApplicationState } from 'store';
import { masterDataSelected, searchMasterData } from 'store/master-data/actions';
import { toMasterDataStateType } from 'store/master-data/utils';
import { newToast } from 'store/common/actions';
import { translate } from 'utils/translate';
import { DisabledWrap } from '../DisabledWrap';
import { DeductionHint } from '../DeductionHint';
import DeductionInput from '../DeductionInput';
import DeductionOperationSelect from '../DeductionOperationSelect';
import { OperatorVehicles } from './OperatorVehicleWeighing';
import OperatorMasterDataSelect from './OperatorMasterDataSelect';

const useStyles = makeStyles({
  additionalInfo: {
    padding: '12px 10px',
  },
  deductionContainerHeader: {
    display: 'flex',
  },
  deductionContainerName: {
    paddingRight: '20px',
  },
  container: {
    // eslint-disable-next-line
    marginBottom: '20px',
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  total: {
    fontWeight: 600,
    fontSize: '18px',
    marginTop: '20px',
  },
  deductionRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '5px',
    marginTop: '10px',
    borderRadius: '3px',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    justifyContent: 'space-between',
    padding: '13px',
  },
  deductionRowInfo: {
    flexDirection: 'row',
    alignItems: 'end',
  },
  deductionOperationSelect: {
    display: 'grid',
    minWidth: '50px',
  },
  deleteButtonContainer: {
    textAlign: 'center',
  },
  addRowButton: {
    marginTop: '10px',
  },
});

interface PropsFromState {
  masterData: MasterDataState;
  enabledFeatures: BridgeEnabledFeatures;
}

interface PropsFromDispatch {
  searchMasterData: typeof searchMasterData;
  newToast: typeof newToast;
  masterDataSelected: typeof masterDataSelected;
}

/*eslint-disable */
export type AllProps = PropsFromDispatch & PropsFromState & ParameterProps;
/*eslint-enable */

const mapStateToProps = (state: ApplicationState) => ({
  masterData: state.masterData,
  enabledFeatures: state.currentScaleInfo.enabledFeatures,
});

const mapDispatchToProps = {
  searchMasterData,
  newToast,
  masterDataSelected,
};

interface ParameterProps {
  context: Context;
  net: NetContainer[];
  initialComment: string;
  visible: boolean;
  onDeductionsConfirmed: (manualDeductions: Deduction[], comment: string) => void;
  onNotConfirmed: () => void;
}

function i18nValue(keyPart: string) {
  return translate(`operatorOrder.weighing.deductionDialog.${keyPart}`);
}

interface DeductionInfoRowProps {
  rowId: string;
  disabledMaterialKeys: string[];
  net: NetContainer;
  mainMaterial: GenericMasterData | undefined;
  materialMasterData: GenericMasterData[];
  isSearching: boolean;
  enabledFeatures: BridgeEnabledFeatures;
  lastSelected: GenericMasterData[];
  deduction: DeductionRow;
  isTrailerUsed: boolean;
  newToast: (messageKey: string) => void;
  searchMasterData: (text: string) => void;
  handleDeductionRowChange: (deduction: DeductionRow) => void;
  handleDeleteRow: (rowId: string) => void;
  masterDataSelected: typeof masterDataSelected;
}

const DeductionInfoRow = (props: DeductionInfoRowProps) => {
  const classes = useStyles();

  const [comment, doSetComment] = React.useState(props.deduction.comment ?? '');
  const [selectedMaterial, setSelectedMaterial] = React.useState(props.deduction.material);

  function setComment(comment: string) {
    doSetComment(comment);
    updateComment(comment);
  }

  const selectedOperation = props.deduction.isAddition
    ? DeductionOperation.ADDITION
    : DeductionOperation.DEDUCTION;

  const notSelectedMarker = '__NOT_SELECTED_MARKER__';
  const selectableMasterData = [
    {
      key: `${notSelectedMarker}`,
      name: translate('masterData.unknownItemName'),
      isNone: true,
    },
    ...props.materialMasterData,
  ];
  const lastSelected = [...props.lastSelected];

  const isOperationSelectionDisabled =
    selectedOperation === DeductionOperation.DEDUCTION && !!selectedMaterial?.key;
  const isMaterialSelectionDisabled = selectedOperation === DeductionOperation.ADDITION;

  function kgsSign(kgs: number, selectedOperation: DeductionOperation) {
    const absKgs = Math.abs(kgs);
    if (selectedOperation === DeductionOperation.ADDITION) {
      return -absKgs;
    }
    return absKgs;
  }

  const handleSelectedOperation = (event: any) => {
    const newDeduction = { ...props.deduction };
    newDeduction.kgs = kgsSign(newDeduction.kgs, event.target.value);
    const isAdditionSelected = event.target.value === DeductionOperation.ADDITION;
    if (isAdditionSelected) {
      newDeduction.material = undefined;
    }
    newDeduction.isAddition = isAdditionSelected;
    props.handleDeductionRowChange(newDeduction);
  };
  const handleSelectedMaterial = (material: GenericMasterData) => {
    setSelectedMaterial(material);
    const newDeduction = { ...props.deduction };
    newDeduction.material = material;
    props.handleDeductionRowChange(newDeduction);
  };
  const handleSelectedKgs = (kgs: number) => {
    const newDeduction = { ...props.deduction };
    newDeduction.kgs = kgsSign(kgs, selectedOperation);
    props.handleDeductionRowChange(newDeduction);
  };
  const updateComment = (comment: string) => {
    const newDeduction = { ...props.deduction };
    newDeduction.comment = comment;
    props.handleDeductionRowChange(newDeduction);
  };

  const operationSelect = () => {
    return (
      <DeductionOperationSelect
        value={selectedOperation}
        onChange={handleSelectedOperation}
        disabled={isOperationSelectionDisabled}
      />
    );
  };

  const deductionRowProps = () => {
    if (props.isTrailerUsed) {
      return { xs: 10 as const, md: 10 as const, lg: 10 as const, spacing: 2 as const };
    }
    return { xs: 10 as const, md: 11 as const, lg: 11 as const, spacing: 2 as const };
  };

  const operationSelectProps = () => {
    if (props.isTrailerUsed) {
      return { xs: 12 as const, sm: 3 as const, md: 3 as const, lg: 3 as const, xl: 3 as const };
    }
    return { xs: 12 as const, sm: 6 as const, md: 2 as const, lg: 2 as const, xl: 2 as const };
  };
  const materialSelectProps = () => {
    if (props.isTrailerUsed) {
      return { xs: 12 as const, sm: 4 as const, md: 5 as const, lg: 4 as const, xl: 4 as const };
    }
    return { xs: 12 as const, sm: 6 as const, md: 5 as const, lg: 2 as const, xl: 2 as const };
  };
  const deductionInputProps = () => {
    if (props.isTrailerUsed) {
      return { xs: 12 as const, sm: 5 as const, md: 4 as const, lg: 5 as const, xl: 5 as const };
    }
    return { xs: 12 as const, sm: 6 as const, md: 5 as const, lg: 2 as const, xl: 2 as const };
  };

  const commentProps = () => {
    if (props.isTrailerUsed) {
      return {
        xs: 12 as const,
        sm: 12 as const,
        md: 12 as const,
        lg: 12 as const,
        xl: 12 as const,
      };
    }
    return { xs: 12 as const, sm: 6 as const, md: 12 as const, lg: 6 as const, xl: 6 as const };
  };
  const deleteButtonProps = () => {
    if (props.isTrailerUsed) {
      return { xs: 2 as const, sm: 2 as const, md: 2 as const, lg: 2 as const, xl: 2 as const };
    }
    return { xs: 2 as const, sm: 2 as const, md: 1 as const, lg: 1 as const, xl: 1 as const };
  };

  const materialSelect = () => {
    return (
      <OperatorMasterDataSelect
        componentId={''}
        masterData={selectableMasterData}
        onSelect={(item: GenericMasterData) => {
          if (item.key.startsWith(notSelectedMarker)) {
            item.key = '';
            item.name = '';
          } else {
            props.masterDataSelected(
              {
                type: RemoteMasterDataType.MATERIAL,
              },
              item,
            );
          }
          handleSelectedMaterial(item);
        }}
        disabled={isMaterialSelectionDisabled}
        disabledKeys={props.disabledMaterialKeys}
        label={translate('orderDetails.material')}
        lastSelected={lastSelected}
        value={selectedMaterial}
        useCompactStyle={true}
        isRequired={false}
        isTypable={false}
        typableFieldShouldBeUpdated={false}
        placeHolder={props.deduction.isAddition ? props.mainMaterial?.name : ''}
        shouldBeFocused={false}
        updateTypableMasterData={() => {
          /* TODO(lindenlas, 20220204) This property is required, but is not used here.
          This should be refactored so that typable properties would be in an optional property.
          In optional property all typable related logic should be required properties. */
        }}
        searchMasterData={(text) => props.searchMasterData(text)}
        isSearching={props.isSearching}
        enabledFeatures={props.enabledFeatures}
      />
    );
  };

  return (
    <Grid container>
      <FormControl variant="standard" fullWidth className={classes.deductionRow}>
        <Grid item container {...deductionRowProps()} className={classes.deductionRowInfo}>
          <Grid {...operationSelectProps()} item className={classes.deductionOperationSelect}>
            {isOperationSelectionDisabled ? (
              <DisabledWrap
                onClick={() =>
                  props.newToast('editDeductions.notification.unableToUpdateOperation')
                }
              >
                {operationSelect()}
              </DisabledWrap>
            ) : (
              operationSelect()
            )}
          </Grid>
          <Grid {...materialSelectProps()} item>
            {isMaterialSelectionDisabled ? (
              <DisabledWrap
                onClick={() => props.newToast('editDeductions.notification.unableToUpdateMaterial')}
              >
                {materialSelect()}
              </DisabledWrap>
            ) : (
              materialSelect()
            )}
          </Grid>
          <Grid {...deductionInputProps()} item>
            <DeductionInput deduction={props.deduction} setDeductionKgs={handleSelectedKgs} />
          </Grid>
          {!!selectedMaterial?.key && (
            <Grid {...commentProps()} item>
              <TextField
                variant="standard"
                label={i18nValue('comment')}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Grid>
          )}
        </Grid>
        <Grid {...deleteButtonProps()} item className={classes.deleteButtonContainer}>
          <Button color="primary" onClick={() => props.handleDeleteRow(props.rowId)}>
            <span>{translate('editDeductions.actions.deleteRow')}</span>
          </Button>
        </Grid>
      </FormControl>
    </Grid>
  );
};

interface AdditionalInfoProps {
  net: NetContainer;
  disabledMaterialKeys: string[];
  materialMasterData: GenericMasterData[];
  mainMaterial: MasterDataItem | undefined;
  manualDeductions: DeductionRow[];
  isSearching: boolean;
  enabledFeatures: BridgeEnabledFeatures;
  lastSelected: GenericMasterData[];
  isTrailerUsed: boolean;
  newToast: (messageKey: string) => void;
  handleDeductionRowChange: (deduction: DeductionRow) => void;
  handleAddRow: () => void;
  handleDeleteRow: (rowId: string) => void;
  searchMasterData: (text: string) => void;
  masterDataSelected: typeof masterDataSelected;
}

const AdditionalInfo = (props: AdditionalInfoProps) => {
  const hasDeductons = !!props.manualDeductions?.length;
  const isAnyDeductionInvalid = props.manualDeductions.some(
    (deduction) => !areDeductionKgsValid(deduction.kgs),
  );
  const handleAddRow = () => {
    props.handleAddRow();
  };
  const handleDeleteRow = (rowId: string) => {
    props.handleDeleteRow(rowId);
  };
  const handleDeductionRowChange = (deduction: DeductionRow) => {
    props.handleDeductionRowChange(deduction);
  };

  const classes = useStyles();
  return (
    <div className={classes.additionalInfo}>
      <div className={classes.deductionContainerHeader}>
        <Typography variant="h5" className={classes.deductionContainerName}>
          {translate(`weighing.containerTypes.${props.net.containerType}`)}
        </Typography>
        <DeductionHint hasError={hasDeductons && isAnyDeductionInvalid} />
      </div>
      <Grid container>
        {props.manualDeductions.map((deduction) => {
          return (
            <DeductionInfoRow
              key={deduction.rowKey}
              disabledMaterialKeys={props.disabledMaterialKeys}
              mainMaterial={props.mainMaterial}
              materialMasterData={props.materialMasterData}
              rowId={deduction.rowKey ?? ''}
              deduction={deduction}
              net={props.net}
              searchMasterData={(text) => props.searchMasterData(text)}
              isSearching={props.isSearching}
              enabledFeatures={props.enabledFeatures}
              lastSelected={props.lastSelected}
              newToast={props.newToast}
              handleDeductionRowChange={(deduction) => handleDeductionRowChange(deduction)}
              handleDeleteRow={handleDeleteRow}
              isTrailerUsed={props.isTrailerUsed}
              masterDataSelected={props.masterDataSelected}
            />
          );
        })}
        <Button color="primary" onClick={handleAddRow} className={classes.addRowButton}>
          <AddIcon />
          <span>{translate('editDeductions.actions.addRow')}</span>
        </Button>
      </Grid>
    </div>
  );
};

interface VehicleContainerProps {
  net: NetContainer;
  context: Context;
  materialMasterData: GenericMasterData[];
  manualDeductions: DeductionRow[];
  isSearching: boolean;
  enabledFeatures: BridgeEnabledFeatures;
  lastSelected: GenericMasterData[];
  isTrailerUsed: boolean;
  searchMasterData: (text: string) => void;
  handleAddRow: () => void;
  handleDeleteRow: (rowId: string) => void;
  handleDeductionRowChange: (deduction: DeductionRow) => void;
  newToast: (messageKey: string) => void;
  masterDataSelected: typeof masterDataSelected;
}

const toVehicleContainer = (props: VehicleContainerProps) => {
  const linkedMaterials = getNormalWeighingActiveComponentLinkedData(
    props.context.order,
    props.context.splitLoad,
    RemoteMasterDataType.MATERIAL,
  );
  const containerLinkedMaterials =
    props.net.containerType === ContainerType.TRUCK
      ? linkedMaterials.truck
      : linkedMaterials.trailer;
  return {
    containerType: props.net.containerType,
    kgs: props.net.weighedKgs,
    key: props.net.key,
    additionalInfo: (
      <AdditionalInfo
        disabledMaterialKeys={containerLinkedMaterials.map((material) => material.key)}
        net={props.net}
        materialMasterData={props.materialMasterData}
        mainMaterial={
          containerLinkedMaterials.length === 1 ? containerLinkedMaterials[0] : undefined
        }
        manualDeductions={props.manualDeductions.filter(
          (deduction) => deduction.containerKey === props.net.key,
        )}
        isSearching={props.isSearching}
        enabledFeatures={props.enabledFeatures}
        lastSelected={props.lastSelected}
        handleAddRow={props.handleAddRow}
        newToast={props.newToast}
        searchMasterData={(text) => props.searchMasterData(text)}
        handleDeleteRow={(rowId) => props.handleDeleteRow(rowId)}
        handleDeductionRowChange={(deduction) => props.handleDeductionRowChange(deduction)}
        isTrailerUsed={props.isTrailerUsed}
        masterDataSelected={props.masterDataSelected}
      />
    ),
    isActive: props.net.deductionKgs !== undefinedMassKg,
  };
};

const DeductionDialogComponent = (props: AllProps) => {
  const [comment, setComment] = React.useState(props.initialComment);
  const [manualDeductions, setDeductions] = React.useState<DeductionRow[]>(
    getManualDeductions(props.context.weighingJobs ?? []),
  );
  const classes = useStyles();

  const linkedMaterials = getNormalWeighingActiveComponentLinkedData(
    props.context.order,
    props.context.splitLoad,
    RemoteMasterDataType.MATERIAL,
  );

  const handleConfirmDeductionsChanges = () => {
    const truckCleared = clearMainMaterials(
      manualDeductions,
      linkedMaterials.truck,
      props.net[0].key,
    );
    const trailerCleared = clearMainMaterials(
      truckCleared.deductions,
      linkedMaterials.trailer,
      props.net?.[1]?.key,
    );
    const hasMaterialChanged = truckCleared.hasMaterialChanged || trailerCleared.hasMaterialChanged;
    const clearedDeductions = [...trailerCleared.deductions];
    const confirmedDeductions = clearedDeductions.map((deduction) => {
      const confirmedDeduction: Deduction = {
        containerKey: deduction.containerKey,
        kgs: deduction.kgs,
        materialKey: deduction.material?.key ?? '',
        comment: deduction.material?.key ? deduction.comment : '',
      };
      return confirmedDeduction;
    });
    if (hasMaterialChanged) {
      props.newToast('orders.materialUsedInDeductionsChanged');
    }
    props.onDeductionsConfirmed(confirmedDeductions, comment);
  };

  const materialStateType = toMasterDataStateType(RemoteMasterDataType.MATERIAL, '');
  const materialDataType = props.masterData?.dataTypes?.[materialStateType];
  const materialSearch = materialDataType?.search;
  const materialMasterData = materialSearch?.searchResults || [];
  const isSearchingMaterials: boolean = materialSearch?.isLoading || false;
  const lastSelectedMaterial = materialDataType?.lastSelected || [];

  return (
    <div>
      <Dialog open={props.visible} maxWidth={'xl'}>
        <DialogTitle>{i18nValue('editDeductions')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{i18nValue('editDeductionDescription')}</DialogContentText>
          <div className={classes.container}>
            <OperatorVehicles
              containers={props.net.map((n, index) =>
                toVehicleContainer({
                  net: n,
                  context: props.context,
                  materialMasterData: materialMasterData,
                  manualDeductions: manualDeductions,
                  isSearching: isSearchingMaterials,
                  enabledFeatures: props.enabledFeatures,
                  lastSelected: lastSelectedMaterial,
                  isTrailerUsed: props.net.length > 1,
                  searchMasterData: function searchMasterData(text: string) {
                    props.searchMasterData({ type: RemoteMasterDataType.MATERIAL }, text);
                  },
                  handleAddRow: function handleAddRow() {
                    const newDeductions: DeductionRow[] = [...manualDeductions];
                    const rowId = uuid();
                    newDeductions.push({
                      containerKey: n.key,
                      containerType: n.containerType,
                      isAddition: false,
                      jobKey: '',
                      kgs: 0,
                      material: undefined,
                      rowKey: rowId,
                      comment: '',
                    });
                    setDeductions(newDeductions);
                  },
                  handleDeleteRow: function handleDeleteRow(deductionKey: string) {
                    const newDeductions: DeductionRow[] = [...manualDeductions];
                    const index = newDeductions.findIndex(
                      (deduction) => deduction.rowKey === deductionKey,
                    );
                    if (index > -1) {
                      newDeductions.splice(index, 1);
                    }
                    setDeductions(newDeductions);
                  },
                  handleDeductionRowChange: function handleDeductionRowChange(
                    deduction: DeductionRow,
                  ) {
                    const newDeductions: DeductionRow[] = [...manualDeductions];
                    const index = newDeductions.findIndex(
                      (newDeduction) => newDeduction.rowKey === deduction.rowKey,
                    );
                    if (index > -1) {
                      newDeductions[index] = deduction;
                    }
                    setDeductions(newDeductions);
                  },
                  newToast: function newToast(messageKey: string) {
                    props.newToast(messageKey);
                  },
                  masterDataSelected: props.masterDataSelected,
                }),
              )}
              isReversed={false}
              isMeasDeviceGroupManual={false}
              containerWidth={props.net.length === 2 ? 6 : 12}
            />
          </div>
          <div className={classes.container}>
            <TextField
              variant="standard"
              defaultValue={props.initialComment}
              label={i18nValue('comment')}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={props.onNotConfirmed} color="primary">
            {i18nValue('cancel')}
          </Button>
          <Button
            onClick={handleConfirmDeductionsChanges}
            variant="contained"
            disabled={hasDeductionError(manualDeductions)}
            color="primary"
            autoFocus={true}
          >
            {i18nValue('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(DeductionDialogComponent);
