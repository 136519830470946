import React, { useCallback, useEffect } from 'react';

import { AppBar, IconButton, List, ListItem, ListItemText, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';

import { connect, localeConnect } from 'localeConnect';
import { ApplicationState } from 'store';
import { newToast } from 'store/common/actions';
import { ToastType } from 'store/common/types';
import { clearQueryJobs } from 'store/jobs/actions';
import { getDefaultTrailerName } from 'store/master-data/utils';
import { BridgeEnabledFeatures } from 'store/scale-info/types';
import { Vehicle } from 'store/vehicles/types';
import { clearVehicle } from 'store/vehicles/actions';
import {
  cancelEditingMasterData,
  discardContextOrder,
  setDefaultWeighingProcess,
} from 'store/orders/actions';
import { destroyContext } from 'store/weighing/actions';
import { CacheImage } from 'store/images/types';
import { Context, WeighingProcess, Order, OrderState } from 'store/orders/types';

import { RoutePaths } from 'routes';
import ConnectedChangeSiteButton from 'components/ConnectedChangeSiteButton';
import ConnectedLogoutButton from 'components/ConnectedLogoutButton';
import ConfirmDialog from 'components/ConfirmDialog';
import DiscardConfirmDialog from 'components/DiscardConfirmDialog';
import ExpandingSiteInfo from 'components/ExpandingSiteInfo';
import RoleSelect from 'components/RoleSelect';
import SettingsDialogButton from 'components/SettingsDialogButton';
import TemporaryDrawer from 'components/TemporaryDrawer';
import { useNavBarStyles as useStyles } from 'components/utils';
import { NavDivider, NavToolbar } from 'components/NavDrawerContent';

import { translate } from 'utils/translate';
import { UserDetails } from 'store/user/types';
import { createOrderDetailsModel } from 'models/orderdetails.model';
import DeepLinkReturnLink from '../DeepLinkReturnLink';

import NavBarVehicle from '../NavBarVehicle';
import { findImages, hasUploadsInProgress } from '../utils';

interface ParameterProps {
  createContext: () => void;
  history: any;
  showSiteInfo: boolean;
}

interface PropsFromState {
  enabledFeatures: BridgeEnabledFeatures;
  selectedVehicle: Vehicle | undefined;
  selectedOrder: Order | undefined;
  context: Context | undefined;
  trailerKey: string | undefined;
  images: CacheImage[];
  userDetails: UserDetails | undefined;
  scaleKey: string;
}

// We can use `typeof` here to map our dispatch types to the props, like so.
interface PropsFromDispatch {
  cancelEditingMasterData: typeof cancelEditingMasterData;
  clearQueryJobs: typeof clearQueryJobs;
  clearVehicle: typeof clearVehicle;
  destroyContext: typeof destroyContext;
  discardContextOrder: typeof discardContextOrder;
  newToast: typeof newToast;
  setDefaultWeighingProcess: typeof setDefaultWeighingProcess;
}

function getContextProps(orders: OrderState) {
  if (orders.currentContextId) {
    if (orders.contexts[orders.currentContextId]) {
      const context = orders.contexts[orders.currentContextId];
      return { context: context, selectedOrder: context.order };
    }
  }
  return { context: undefined, selectedOrder: undefined };
}

// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
type AllProps = PropsFromDispatch & PropsFromState & ParameterProps;

const mapStateToProps = (state: ApplicationState) => {
  const contextProps = getContextProps(state.orders);
  const orderKey = contextProps.context?.orderKey;

  return {
    enabledFeatures: state.currentScaleInfo.enabledFeatures,
    selectedVehicle: state.vehicles.selectedVehicle,
    trailerKey: state.vehicles.trailerKey,
    scaleKey: state.currentScaleInfo.scaleKey,
    userDetails: state.user.user.userData,
    images: orderKey === undefined ? [] : findImages(state, orderKey),
    ...contextProps,
  };
};

// mapDispatchToProps is especially useful for constraining our actions to the connected component.
// You can access these via `this.props`.
const mapDispatchToProps = {
  cancelEditingMasterData,
  clearQueryJobs,
  clearVehicle,
  destroyContext,
  discardContextOrder,
  newToast,
  setDefaultWeighingProcess,
};

const NavBar = (props: AllProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const [isPhotoDiscardDialogOpen, setIsPhotoDiscardDialogOpen] = React.useState(false);
  const [discardContextId, setDiscardContextId] = React.useState('');

  const handleDrawerToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleDiscardClick = () => {
    if (!props.context) return;
    handleDrawerToggle();
    setDiscardContextId(props.context.contextId);
  };

  const handleChangeVehicleClick = () => {
    handleDrawerToggle();
    props.destroyContext();
    props.clearVehicle();
  };

  const handleReceiptHistoryClick = () => {
    handleDrawerToggle();
    props.clearQueryJobs();
    props.history.push(RoutePaths.RECEIPT_HISTORY);
  };

  const handleWeighTare = () => {
    handleDrawerToggle();
    props.setDefaultWeighingProcess(WeighingProcess.NORMAL);
    props.createContext();
    props.newToast('navigation.weighTareHint', ToastType.SUCCESS);
  };

  const classes = useStyles();

  const { destroyContext } = props;

  const handleBack = useCallback(() => {
    destroyContext();
    setIsPhotoDiscardDialogOpen(false);
  }, [destroyContext, setIsPhotoDiscardDialogOpen]);

  const hasUploads = hasUploadsInProgress(props.context, props.images);
  const handleBackClick = useCallback(() => {
    if (hasUploads) {
      setIsPhotoDiscardDialogOpen(true);
      return;
    }

    handleBack();
  }, [hasUploads, handleBack, setIsPhotoDiscardDialogOpen]);

  const backPressed = useCallback(
    (e: PopStateEvent) => {
      e.stopPropagation();
      handleBackClick();
    },
    [handleBackClick],
  );

  useEffect(() => {
    window.addEventListener('popstate', backPressed);
    return () => {
      // NOTE(eetuk,20221130) handler can't be removed immediately because
      // cleanup is called before the handler.
      setTimeout(() => {
        window.removeEventListener('popstate', backPressed);
      }, 50);
    };
  }, [backPressed]);

  const vehicleName = props.selectedVehicle
    ? props.selectedVehicle.name
    : translate('navigation.noVehicle');

  function getOrderDetailsModel() {
    if (props?.context?.weighingJobs === undefined) {
      return undefined;
    }
    return createOrderDetailsModel(
      props.context,
      props.context.order,
      props.context.process,
      props.context.weighingJobs,
      props.scaleKey,
      props.userDetails,
    );
  }
  const orderDetailsModel = getOrderDetailsModel();

  const isTrailerUsed = orderDetailsModel?.isTrailerUsed ?? props.trailerKey !== undefined;
  const trailerName = getDefaultTrailerName(isTrailerUsed);

  const isWeighTareShown =
    !props.context &&
    props.enabledFeatures.bridgePrimaryContainerTare &&
    props.trailerKey === undefined;

  return (
    <div>
      {props.context && props.selectedOrder && discardContextId === props.context.contextId && (
        <DiscardConfirmDialog
          orderExternalId={props.selectedOrder.externalId}
          visible={true}
          onConfirmed={() => {
            if (!props.context) return;
            props.discardContextOrder(props.context.contextId);
            setDiscardContextId('');
          }}
          onNotConfirmed={() => {
            setDiscardContextId('');
          }}
        />
      )}
      <ConfirmDialog
        title={translate('images.uploadCancel.title')}
        question={translate('images.uploadCancel.question')}
        visible={isPhotoDiscardDialogOpen}
        onConfirmed={handleBack}
        onNotConfirmed={() => setIsPhotoDiscardDialogOpen(false)}
      />
      <AppBar position="relative" className={classes.appBar}>
        <DeepLinkReturnLink isContextIgnored={false} />
        <Toolbar>
          <div className={classes.backButtonContainer}>
            {props.context && (
              <IconButton
                color="inherit"
                aria-label={translate('navigation.return')}
                edge="start"
                onClick={handleBackClick}
              >
                <BackIcon />
              </IconButton>
            )}
          </div>
          <NavBarVehicle vehicleName={vehicleName} trailerName={trailerName} />
          <IconButton
            color="inherit"
            aria-label={translate('navigation.openDrawer')}
            edge="end"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
        {props.showSiteInfo && (
          <div className={classes.siteInfoOuterContainer}>
            <div className={classes.siteInfoInnerContainer}>
              <div className={classes.siteInfo}>
                {/* TODO(mikkogy,20210622) add logic to hide dot after reading,
                for example store site info revision or whole info text in store
                and compare with current content. */}
                <ExpandingSiteInfo isForcingInstructionsEnabled={true} isRouteShown={true} />
              </div>
            </div>
          </div>
        )}
      </AppBar>

      <nav aria-label="Navigation">
        <TemporaryDrawer
          anchor="right"
          isOpen={isSidebarOpen}
          onClose={handleDrawerToggle}
          paperClass={classes.drawerPaper}
        >
          <div role="presentation">
            <NavToolbar />
            <NavDivider variant={'fullWidth'} />
            <List component={'ul'}>
              <ListItem
                component={'li'}
                button
                key="discard"
                onClick={handleDiscardClick}
                disabled={
                  !props.selectedOrder ||
                  !props.context ||
                  !props.context.isOrderTemporaryCopy ||
                  !props.context.weighingJobs ||
                  props.context.weighingJobs.reduce(
                    (loadCount, job) => loadCount + (job.loads ? job.loads.length : 0),
                    0,
                  ) > 0
                }
              >
                <ListItemText primary={translate('navigation.discardOrder')} />
              </ListItem>
              <NavDivider variant={'middle'} />
              <ConnectedChangeSiteButton iconVisible={false} onClick={handleDrawerToggle} />
              <ListItem
                component={'li'}
                button
                key="changeVehicle"
                onClick={handleChangeVehicleClick}
              >
                <ListItemText primary={translate('navigation.changeVehicle')} />
              </ListItem>
              <NavDivider variant={'middle'} />
              <ListItem
                component={'li'}
                button
                key="receiptHistory"
                onClick={handleReceiptHistoryClick}
              >
                <ListItemText primary={translate('navigation.receiptHistory')} />
              </ListItem>
              {isWeighTareShown && (
                <ListItem component={'li'} button key="weighTare" onClick={handleWeighTare}>
                  <ListItemText primary={translate('navigation.weighTare')} />
                </ListItem>
              )}
            </List>

            <List component={'ul'} className={classes.extraActions}>
              <SettingsDialogButton
                iconVisible={true}
                onOpen={handleDrawerToggle}
                onClose={undefined}
              />
              <NavDivider variant={'middle'} />
              <RoleSelect />
              <ConnectedLogoutButton iconVisible={true} onClick={handleDrawerToggle} />
            </List>
          </div>
        </TemporaryDrawer>
      </nav>
    </div>
  );
};
const connectResult = connect(mapStateToProps, mapDispatchToProps);
const exported = localeConnect<typeof connectResult>(mapStateToProps, mapDispatchToProps)(NavBar);

export default exported;
