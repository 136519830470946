import React from 'react';

import { makeStyles } from '@mui/styles';
import { Chip, Paper, List, ListItem, ListItemText, TextField } from '@mui/material';

import { OperatorQueryMasterData } from 'store/jobs/types';

import { operatorSearchFilter } from 'theme';
import HighlightedText from '../HighlightedText';

const useStyles = makeStyles({
  root: {
    '& .MuiChip-root': {
      marginRight: operatorSearchFilter.spacing,
    },
    // eslint-disable-next-line
    position: 'relative',
  },
  searchInput: {
    display: 'inline-block',
    width: '150px',
  },
  resultListContainer: {
    position: 'absolute',
    display: 'none',
    right: operatorSearchFilter.spacing,
    width: '150px',
    zIndex: 10000,
  },
  resultsVisible: {
    display: 'block',
  },
  label: {
    position: 'absolute',
    textAlign: 'left',
    left: operatorSearchFilter.spacing,
  },
  chip: {
    borderRadius: '0px',
    backgroundColor: '#fff',
  },
  content: {
    marginTop: '0px',
    float: 'left',
  },
  searchResultText: {
    display: 'inline-block',
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

export interface OperatorSearchResultItem {
  key: string;
  name: string;
}

export interface OperatorSearchResults {
  searchStr: string;
  result: OperatorSearchResultItem[];
}

export interface OperatorSearchProps {
  title: string;
  searchResults: OperatorSearchResults;
  search: (searchStr: string) => void;
  currentSelection: OperatorQueryMasterData[];
  select: (item: OperatorSearchResultItem) => void;
  remove: (key: string) => void;
  searchStr: string;
}

const blurTimeoutMs = 80;

const OperatorSearch = (props: OperatorSearchProps) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = React.useState(false);
  const rootRef = React.useRef();

  const showingResults = props.searchResults.result.filter((result) => {
    return props.currentSelection.filter((item) => item.key === result.key).length === 0;
  });

  function handleFocus(event: React.FocusEvent<HTMLInputElement>) {
    const nativeEvent: any = event.nativeEvent;
    if (isOpen || showingResults.length === 0 || !nativeEvent.sourceCapabilities) {
      return;
    }
    setIsOpen(true);
  }

  let timer: ReturnType<typeof setTimeout> | null = null;
  function handleBlur() {
    // NOTE(mikkogy,20200227) delay all blur handling to avoid rendering before
    // selection is handled.
    setTimeout(() => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      // NOTE(mikkogy,20200227) nested timeout enables canceling blurring.
      timer = setTimeout(() => {
        timer = null;
        setIsOpen(false);
        const element: any = rootRef && rootRef.current;
        if (element) {
          element.blur();
        }
      }, blurTimeoutMs);
    }, blurTimeoutMs);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const str = event.target.value as string;
    props.search(str);
    setIsOpen(true);
  }

  function selectResult(result: OperatorSearchResultItem) {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    props.select(result);
    const element: any = rootRef && rootRef.current;
    if (element) {
      element.focus();
    }
  }

  const shouldShow =
    isOpen && props.searchStr === props.searchResults.searchStr && showingResults.length > 0;
  return (
    <div className={`${classes.root}`}>
      <label className={`${classes.label} MuiFormLabel-root MuiInputLabel-shrink`}>
        {props.title}
      </label>
      <div className={classes.content}>
        {props.currentSelection.map((item) => (
          <Chip
            key={item.key}
            label={item.name}
            className={classes.chip}
            onDelete={() => props.remove(item.key)}
          />
        ))}
        <TextField
          variant="standard"
          autoComplete="off"
          className={classes.searchInput}
          label=""
          value={props.searchStr}
          inputRef={rootRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <div
          className={`${classes.resultListContainer} ${shouldShow ? classes.resultsVisible : ''}`}
        >
          <Paper>
            <List component={'ul'}>
              {showingResults.map((result) => {
                return (
                  <ListItem
                    key={result.key}
                    onClick={() => selectResult(result)}
                    component={'li'}
                    button
                  >
                    <ListItemText
                      className={classes.searchResultText}
                      primary={
                        <HighlightedText searchTerm={props.searchStr} fullText={result.name} />
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default OperatorSearch;
