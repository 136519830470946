import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { translate } from '../utils/translate';

interface DialogProps {
  visible: boolean;
  onConfirmed: () => void;
  onNotConfirmed: () => void;
}

const DisableMultipleSharesDialogComponent = (props: DialogProps) => {
  return (
    <div>
      <Dialog open={props.visible}>
        <DialogContent>
          <DialogContentText>{translate('disableMultipleSharesDialog.question')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onNotConfirmed} color="primary">
            {translate('confirmdialog.cancel')}
          </Button>
          <Button onClick={props.onConfirmed} color="primary" autoFocus={true}>
            {translate('confirmdialog.continue')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DisableMultipleSharesDialogComponent;
