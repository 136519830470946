import { all, call, fork, put, select, take, takeEvery } from 'redux-saga/effects';

import { callApi } from 'utils/api';

import { disconnectRequest } from '../common/actions';

import { changeSite, selectSite } from '../sites/actions';

import { createContext } from '../orders/actions';
import { Order, OrdersActionTypes } from '../orders/types';
import { getOrderTruck } from '../orders/utils';

import { loginSuccess, logout, roleChanged } from '../user/actions';
import { fetchAccount, fetchOrganization, UserAccountResult } from '../user/sagas';
import { OrganizationDetails, UiRoles } from '../user/types';
import { createTokenHeader } from '../user/utils';

import { selectVehicle } from '../vehicles/actions';

import { destroyContext } from '../weighing/actions';

import { getDeepLinkState } from '../selectors';

import {
  deepLinkFailure,
  deepLinkSuccess,
  loadDeepLink,
  setOrderKey,
  setReturnLink,
  startLoading,
} from './actions';
import { DeepLinkActionTypes, DeepLinkState } from './types';

const PUBLIC_API_ENDPOINT = `${
  process.env.REACT_APP_PILVILINNA_URL || 'http://localhost/'
}public/api/v1/`;

interface DeepLinkData {
  isTrailerUsed: boolean;
}
interface DeepLinkDetails {
  orderKey: string;
  returnLink: string;
  scaleKey: string;
  linkData: DeepLinkData | undefined;
}
interface DeepLinkResponse {
  deepLink: DeepLinkDetails;
  error?: any;
  order: Order;
  token: string;
}

function* handleLoad(action: ReturnType<typeof loadDeepLink>) {
  const deepLinkState: DeepLinkState = yield select(getDeepLinkState);
  if (
    deepLinkState.isDeepLinkViewVisible &&
    deepLinkState.inProgressDeepLinkId === action.payload
  ) {
    return;
  }
  yield put(startLoading(action.payload));
  function logError(err: any) {
    console.warn('deep link failed', err);
  }
  try {
    yield put(destroyContext());
    // NOTE(mikkogy,20220324) assuming deep links are used correctly we can
    // already prevent connecting to previous site (if any) while loading deep
    // link data.
    yield put(disconnectRequest());
    yield put(changeSite(false));
    yield put(logout());

    const res: DeepLinkResponse = yield call(
      callApi,
      'post',
      PUBLIC_API_ENDPOINT,
      `export/deep_link/consume/${action.payload}`,
    );

    if (res.error) {
      logError(res.error);
      yield put(deepLinkFailure());
      return;
    }
    const { orderKey, returnLink, scaleKey } = res.deepLink;
    yield put(setReturnLink(returnLink));
    const order = res.order;
    const orderTruck = getOrderTruck(order);
    if (!orderTruck) {
      console.warn('deep link order is missing truck');
      yield put(deepLinkFailure());
      return;
    }
    const token = res.token;
    const isTrailerUsed = res.deepLink?.linkData?.isTrailerUsed ?? false;
    yield put(roleChanged(UiRoles.DRIVER));

    const tokenHeader = createTokenHeader(token);
    const accountResult: UserAccountResult = yield call(fetchAccount, tokenHeader);
    if (!accountResult.userAccount || accountResult.error) {
      yield put(deepLinkFailure());
      return;
    }
    let organizationResponse: OrganizationDetails | undefined;
    if (accountResult.error || !accountResult.userAccount) {
      organizationResponse = undefined;
    } else {
      organizationResponse = yield fetchOrganization(
        accountResult.userAccount.userDetails.organization,
        tokenHeader,
      );
    }
    if (
      !organizationResponse ||
      !organizationResponse?.key ||
      accountResult.error ||
      !accountResult.userAccount
    ) {
      yield put(deepLinkFailure());
      return;
    }
    yield put(
      loginSuccess({
        domains: accountResult.userAccount.userDomains,
        isAuthed: true,
        token: token,
        organizationData: organizationResponse,
        userData: accountResult.userAccount.userDetails,
        loginData: undefined,
      }),
    );
    yield put(disconnectRequest());
    yield put(selectSite(scaleKey));

    const trailerKey = isTrailerUsed ? '' : undefined;
    yield put(selectVehicle(orderTruck, trailerKey));
    yield put(setOrderKey(orderKey));
    yield put(createContext(orderTruck.key, orderKey, trailerKey, false));
    yield take(OrdersActionTypes.SET_CURRENT_CONTEXT_ORDER_KEY);
    yield put(deepLinkSuccess(returnLink));
  } catch (err) {
    logError(err);
    yield put(deepLinkFailure());
  }
}

function* watchLoad() {
  yield takeEvery(DeepLinkActionTypes.LOAD, handleLoad);
}

function* deepLinkSaga() {
  yield all([fork(watchLoad)]);
}

export default deepLinkSaga;
