import { ContainerType } from '../store/common/types';
import { DeductionRow, Job, JobContainer, Load, LoadType } from '../store/jobs/types';
import { getManualDeductions, loadWeighedKgs } from '../store/jobs/utils';
import { GenericMasterData } from '../store/master-data/types';
import { Context, WeighingProcess, Order, SplitLoad } from '../store/orders/types';
import { getNormalWeighingMainComponents } from '../store/orders/utils';
import { BridgeEnabledFeatures } from '../store/scale-info/types';
import { Status, undefinedMassKg } from '../store/weighing/types';
import { toNormalWeighingDeduction } from './normalweighingdeduction.model';
import { isAmountMissing } from './splitload.model';

enum VehicleWeighingRound {
  First,
  Second,
}

export enum WeighingDirection {
  UNDEFINED = 'UNDEFINED',
  ADD = 'ADD',
  REDUCE = 'REDUCE',
}

export interface WeighingContainer {
  containerType: ContainerType;
  direction: WeighingDirection;
  key: string;
  isActive: boolean;
  isWrongDirection: boolean;
  kgs: number;
}

export interface NetContainer {
  containerType: ContainerType;
  key: string;
  manualDeductions: DeductionRow[] | undefined;
  weighedKgs: number;
  deductionKgs: number;
  netKgs: number;
}

export interface VehicleWeighingModel {
  round: number;
  containers: WeighingContainer[];
  isActive: boolean;
  isDiscardingEnabled: boolean;
  isWeighingEnabled: boolean;
}

export interface VehicleWeighingModels {
  models: VehicleWeighingModel[];
  net: NetContainer[];
  isFinishingEnabled: boolean;
  process: WeighingProcess;
}

function findContainers(job: Job) {
  const rootContainer = job.containers && job.containers[0] ? job.containers[0] : null;
  if (!rootContainer) return [];
  if (rootContainer.containerType === ContainerType.TRUCK) {
    return [rootContainer];
  } else if (rootContainer.containerType === ContainerType.TRUCK_TRAILER) {
    if (rootContainer.containers && rootContainer.containers.length === 2) {
      return rootContainer.containers;
    }
  }
  return [];
}

interface ContainerLoads {
  containerKey: string;
  loads: Load[];
}

function findContainerLoads(job: Job, containers: JobContainer[]) {
  return containers.reduce<ContainerLoads[]>((result, container) => {
    const loads = container.loadIndexes ? container.loadIndexes.map((i) => job.loads[i]) : [];
    result[result.length] = { containerKey: container.key, loads };
    return result;
  }, []);
}

function isLoadSuccessful(load: Load) {
  return load.loadType !== LoadType.FIX && load.relatedIndex === undefined;
}

function filterSuccessfulLoads(loadsByContainer: ContainerLoads[]) {
  return loadsByContainer.map((containerLoads) => {
    const loads = containerLoads.loads.filter(isLoadSuccessful);
    return { containerKey: containerLoads.containerKey, loads };
  });
}

function getMinLoadCount(loadsByContainer: ContainerLoads[]) {
  const min = loadsByContainer.reduce<number | undefined>((minCount, containerLoads) => {
    if (minCount === undefined) {
      return containerLoads.loads.length;
    }
    return Math.min(minCount, containerLoads.loads.length);
  }, undefined);
  return min === undefined ? 0 : min;
}

function getMaxLoadCount(loadsByContainer: ContainerLoads[]) {
  return loadsByContainer.reduce((maxCount, containerLoads) => {
    return Math.max(maxCount, containerLoads.loads.length);
  }, 0);
}

function isContainerCurrentForSingleDevice(
  successfulLoads: ContainerLoads[],
  minLoadCount: number,
  containerKey: string,
) {
  for (const i in successfulLoads) {
    const containerLoads = successfulLoads[i];
    if (containerLoads.loads.length === minLoadCount) {
      return containerLoads.containerKey === containerKey;
    }
  }
  return false;
}

function getLoad(
  successfulLoads: ContainerLoads[],
  containerKey: string,
  round: VehicleWeighingRound,
) {
  for (const i in successfulLoads) {
    const containerLoads = successfulLoads[i];
    if (containerLoads.containerKey === containerKey) {
      let index = -1;
      if (round === VehicleWeighingRound.First) {
        index = 0;
      } else if (round === VehicleWeighingRound.Second) {
        index = 1;
      }
      if (index < 0) {
        return undefined;
      }
      if (containerLoads.loads.length > index) {
        return containerLoads.loads[index];
      }
      return undefined;
    }
  }
  return undefined;
}

function toNumber(round: VehicleWeighingRound) {
  switch (round) {
    case VehicleWeighingRound.First:
      return 1;
    case VehicleWeighingRound.Second:
      return 2;
    default:
      throw new Error('unknown vehicle weighing round');
  }
}

function isLoadFixRelated(load?: Load) {
  if (!load) {
    return false;
  }
  return load.loadType === LoadType.FIX || load.relatedIndex !== undefined;
}

function isRoundCurrent(round: VehicleWeighingRound, minLoadCount: number) {
  return toNumber(round) - 1 === minLoadCount;
}

// NOTE(mikkogy,20210205) we want to be able to discard last real weighing not considering discarded
// deduction. A vehicle might still be on the bridge when deductions are added so in case deductions
// are removed we may want to discard last weighing and do it again.
function lastLoadIndexWithoutDiscardedDeductions(
  loads: Load[] | undefined,
  loadContainers: JobContainer[],
) {
  if (!loads || loads.length === 0) {
    return -1;
  }
  // NOTE(mikkogy,20210205) find effective weighed tare indices for each load container. Later
  // tares that have not been fixed are considered deductions.
  const tareIndices: number[] = [];
  for (const containerI in loadContainers) {
    const container = loadContainers[containerI];
    tareIndices.push(-1);
    if (container.loadIndexes === undefined) {
      continue;
    }
    for (const linkI in container.loadIndexes) {
      const link = container.loadIndexes[linkI];
      const load = loads[link];
      if (load.loadType === LoadType.TARE && load.relatedIndex === undefined) {
        tareIndices[tareIndices.length - 1] = link;
        break;
      }
    }
  }
  for (let loadIndex = loads.length - 1; loadIndex >= 0; loadIndex--) {
    const load = loads[loadIndex];
    const relatedIndex = load.relatedIndex;
    if (relatedIndex === undefined) {
      // NOTE(mikkogy,20210205) if no related index the load must not be a discarded
      // deduction. Either it's not discarded or deduction.
      return loadIndex;
    }
    const loadType = load.loadType;
    if (loadType !== LoadType.TARE && loadType !== LoadType.NET && loadType !== LoadType.FIX) {
      // NOTE(mikkogy,20210205) not related to discarded deduction because of type.
      return loadIndex;
    }
    const relatedLoadType = loads[relatedIndex].loadType;
    if (
      loadType === LoadType.FIX &&
      relatedLoadType !== LoadType.TARE &&
      relatedLoadType !== LoadType.NET
    ) {
      // NOTE(mikkogy,20210205) not related to tare or net so not discarded deduction related.
      return loadIndex;
    }
    // NOTE(mikkogy,20210205) we need to know to which container the load is related to in order
    // to find the effective weighed tare load.
    let loadContainerIndex = -1;
    for (
      let containerIndex = 0, end = loadContainers.length;
      containerIndex < end;
      containerIndex++
    ) {
      const container = loadContainers[containerIndex];
      if (!!container.loadIndexes && container.loadIndexes.includes(loadIndex)) {
        loadContainerIndex = containerIndex;
      }
    }
    if (
      loadContainerIndex >= 0 &&
      tareIndices[loadContainerIndex] >= 0 &&
      tareIndices[loadContainerIndex] < loadIndex
    ) {
      // NOTE(mikkogy,20210205) discarded deduction related load by type & related index and
      // we also have a weighed tare so we can keep finding the last relevant load index.
      continue;
    }
    return loadIndex;
  }
  return -1;
}

function measDeviceStatusByIndex(measDeviceStatuses: Status[], index: number | undefined) {
  if (!measDeviceStatuses || index === undefined) {
    return undefined;
  }
  if (measDeviceStatuses.length <= index) {
    return undefined;
  }
  return measDeviceStatuses[index];
}

function isContainerCurrent(
  measDeviceCount: number,
  successfulLoads: ContainerLoads[],
  minLoadCount: number,
  container: JobContainer,
) {
  let isCurrent = false;
  if (measDeviceCount === 1) {
    isCurrent = isContainerCurrentForSingleDevice(successfulLoads, minLoadCount, container.key);
  } else if (measDeviceCount === 2 && successfulLoads.length === 2) {
    const truckLoadCount = successfulLoads[0].loads.length;
    const trailerLoadCount = successfulLoads[1].loads.length;
    if (truckLoadCount === trailerLoadCount) {
      if (truckLoadCount === 0 || truckLoadCount === 1) {
        isCurrent = true;
      }
    }
  }
  return isCurrent;
}

interface WeighingInfo {
  referenceDirection: WeighingDirection;
  successfulLoads: ContainerLoads[];
  measDeviceStatuses: Status[];
  containerDirections: WeighingDirection[];
  minLoadCount: number;
  firstSuccessfulLoads: ContainerLoads[];
}

function createContainer(
  container: JobContainer,
  round: VehicleWeighingRound,
  containerIndex: number,
  isRoundCurrent: boolean,
  info: WeighingInfo,
) {
  const load = getLoad(info.successfulLoads, container.key, round);
  const measDeviceCount = info.measDeviceStatuses.length;
  const isCurrent = isContainerCurrent(
    measDeviceCount,
    info.successfulLoads,
    info.minLoadCount,
    container,
  );

  const isActive = isRoundCurrent && isCurrent;
  let kgs = undefinedMassKg;
  const deviceIndex = isCurrent ? (measDeviceCount > 1 ? containerIndex : 0) : undefined;
  const measDeviceStatus = measDeviceStatusByIndex(info.measDeviceStatuses, deviceIndex);
  if (load) {
    kgs = loadWeighedKgs(load);
  } else if (measDeviceStatus && isActive) {
    kgs = measDeviceStatus.massKg;
  }
  let isWrongDirection = false;
  let currentDirection = WeighingDirection.UNDEFINED;
  const referenceLoads = info.successfulLoads[containerIndex].loads;
  if (isActive) {
    if (referenceLoads.length > 0) {
      const referenceLoad = referenceLoads[referenceLoads.length - 1];
      const referenceKgs = loadWeighedKgs(referenceLoad);
      currentDirection = getKgsDirection(referenceKgs, kgs);
      const containerDirection = info.containerDirections[containerIndex];
      isWrongDirection =
        (containerDirection !== WeighingDirection.UNDEFINED &&
          currentDirection !== containerDirection) ||
        (info.referenceDirection !== WeighingDirection.UNDEFINED &&
          currentDirection !== info.referenceDirection) ||
        currentDirection === WeighingDirection.UNDEFINED;
    }
  } else {
    if (referenceLoads.length >= 2) {
      currentDirection = getKgsDirection(
        loadWeighedKgs(referenceLoads[0]),
        loadWeighedKgs(referenceLoads[1]),
      );
    } else {
      if (!info.firstSuccessfulLoads) throw new Error(JSON.stringify(info, null, 2));
      const referenceLoads = info.firstSuccessfulLoads[containerIndex].loads;
      if (referenceLoads && referenceLoads.length >= 2) {
        currentDirection = getKgsDirection(
          loadWeighedKgs(referenceLoads[0]),
          loadWeighedKgs(referenceLoads[1]),
        );
      }
    }
  }
  return {
    containerType: container.containerType,
    direction: currentDirection,
    key: container.key,
    isActive,
    isWrongDirection,
    kgs,
  };
}

export function toNormalVehicleWeighingModels(
  jobs: Job[],
  measDeviceStatuses: Status[],
  order: Order,
  enabledFeatures: BridgeEnabledFeatures,
  splitLoad: SplitLoad | undefined,
): VehicleWeighingModels {
  const rounds = [VehicleWeighingRound.First, VehicleWeighingRound.Second];
  const mainJob: Job = { ...jobs[0] };

  const containers = findContainers(mainJob) as JobContainer[];
  if (containers.length !== 1 && containers.length !== 2) {
    throw new Error(`unexpected number of containers: ${containers.length}`);
  }
  if (measDeviceStatuses.length > 2) {
    throw new Error(`unexpected number of meas devices: ${measDeviceStatuses.length}`);
  }
  if (measDeviceStatuses.length > 1 && containers.length !== measDeviceStatuses.length) {
    /*eslint-disable */
    throw new Error(
      `when multiple meas devices are used count must match container count, devices: ${measDeviceStatuses.length} containers: ${containers.length}`,
    );
    /*eslint-enable */
  }
  const containerLoads = findContainerLoads(mainJob, containers);
  const successfulLoads = filterSuccessfulLoads(containerLoads);
  const minLoadCount = getMinLoadCount(successfulLoads);
  const maxLoadCount = getMaxLoadCount(successfulLoads);
  if (minLoadCount < 2 && minLoadCount !== maxLoadCount && measDeviceStatuses.length > 1) {
    throw new Error(
      `unexpected number of meas devices when containers have different load counts: ${measDeviceStatuses.length}`,
    );
  }

  const canSum =
    measDeviceStatuses.length > 0 &&
    measDeviceStatuses
      .map((status) => status.canSum)
      .reduce((canSumAll, canSum) => canSumAll && canSum, true);

  function getWeighedKgs(loadLinks: number[]) {
    let hasTare = false;
    let hasGross = false;
    const weighedKgs = loadLinks.reduce((sum, link) => {
      const load = mainJob.loads[link];
      if (load.relatedIndex !== undefined || load.loadType === LoadType.FIX) {
        return sum;
      }
      if (load.loadType === LoadType.TARE) {
        if (hasTare) {
          return sum;
        }
        hasTare = true;
      }
      if (load.loadType === LoadType.NET) {
        return sum;
      }
      if (load.loadType === LoadType.GROSS) {
        if (hasGross) {
          return undefinedMassKg;
        }
        hasGross = true;
      }
      return sum + load.massKg;
    }, 0);
    if (hasTare && hasGross) {
      return weighedKgs;
    }
    return undefinedMassKg;
  }

  const containerDirections = getContainerDirections(successfulLoads);
  const truckReferenceDirection = containerDirections[0];
  const weighingInfo: WeighingInfo = {
    referenceDirection: truckReferenceDirection,
    successfulLoads,
    measDeviceStatuses,
    containerDirections,
    minLoadCount,
    firstSuccessfulLoads: successfulLoads,
  };

  const components = getNormalWeighingMainComponents(order);

  const netContainers = containers.map((c) => {
    const weighedKgs = c.loadIndexes ? getWeighedKgs(c.loadIndexes) : undefinedMassKg;
    const manualDeductions = getManualDeductions(jobs).filter(
      (deduction) => deduction.containerKey === c.key,
    );
    const manualDeductionKgs =
      weighedKgs === undefinedMassKg
        ? undefinedMassKg
        : manualDeductions.reduce((sum, deduction) => sum + deduction.kgs, 0);

    const componentId =
      c.containerType === ContainerType.TRUCK ? components.truck.id : components.trailer?.id;
    const deductionModel = toNormalWeighingDeduction(
      manualDeductionKgs,
      order,
      componentId,
      enabledFeatures,
    );

    const netKgs =
      weighedKgs === undefinedMassKg
        ? undefinedMassKg
        : c.sumMassKg - manualDeductionKgs - (deductionModel.tareDeductionKgs || 0);

    const deductionKgs =
      manualDeductionKgs === undefinedMassKg
        ? undefinedMassKg
        : manualDeductionKgs + (deductionModel.tareDeductionKgs || 0);

    return {
      containerType: c.containerType,
      key: c.key,
      weighedKgs: weighedKgs,
      manualDeductions: manualDeductions?.length ? manualDeductions : undefined,
      deductionKgs,
      netKgs: netKgs,
    };
  });

  const isSplitAmountMissing = isAmountMissing(order, splitLoad, mainJob);

  return {
    models: rounds.map((round, index) => {
      const numberRound = toNumber(round);

      const isWeighingEnabled = isRoundCurrent(round, minLoadCount) && canSum;

      const loadI = lastLoadIndexWithoutDiscardedDeductions(mainJob.loads, containers);
      const isLastLoadFixRelated = loadI >= 0 ? isLoadFixRelated(mainJob.loads[loadI]) : false;
      const isLoadCountOk = numberRound === maxLoadCount && numberRound >= minLoadCount;
      const isDiscardingEnabled = !isLastLoadFixRelated && isLoadCountOk;
      const isCurrentRound = isRoundCurrent(round, minLoadCount);
      const modelContainers = containers.map((c, index) =>
        createContainer(c, round, index, isCurrentRound, weighingInfo),
      );
      return {
        round: numberRound,
        containers: modelContainers,
        isActive: !!modelContainers.reduce(
          (isActive, container) => isActive || container.isActive,
          false,
        ),
        isDiscardingEnabled,
        isWeighingEnabled,
      };
    }),
    net: netContainers,
    isFinishingEnabled:
      minLoadCount >= 2 &&
      netContainers.every((container) => container.netKgs >= 0) &&
      !isSplitAmountMissing,
    process: WeighingProcess.NORMAL,
  };
}

export function contextToNormalVehicleWeighingModels(
  context: Context,
  measDeviceStatuses: Status[],
  enabledFeatures: BridgeEnabledFeatures,
): VehicleWeighingModels {
  return toNormalVehicleWeighingModels(
    context.weighingJobs ?? [],
    measDeviceStatuses,
    context.order,
    enabledFeatures,
    context.splitLoad,
  );
}

export function getCurrentMultipartJob(jobs: Job[]) {
  let job: Job | null = null;
  if (jobs && jobs.length > 0) {
    for (let i = jobs.length - 1; i >= 0; i--) {
      if (jobs[i].loads) {
        const loads = jobs[i].loads.filter(isLoadSuccessful);
        const containerCount =
          jobs[i].containers[0].containerType === ContainerType.TRUCK_TRAILER ? 2 : 1;
        if (loads.length >= containerCount) {
          job = jobs[i];
          break;
        }
      }
      if (i === 0) {
        job = jobs[0];
      }
    }
  }
  return job;
}

function getKgsDirection(a: number, b: number) {
  if (a === b) {
    return WeighingDirection.UNDEFINED;
  }
  return a < b ? WeighingDirection.ADD : WeighingDirection.REDUCE;
}

function getContainerDirections(containerLoads: ContainerLoads[]) {
  return containerLoads.map((container) => {
    const loads = container.loads.filter(isLoadSuccessful);
    if (loads.length < 2) {
      return WeighingDirection.UNDEFINED;
    }
    return getKgsDirection(loadWeighedKgs(loads[0]), loadWeighedKgs(loads[1]));
  });
}

export function toMultipartVehicleWeighingModels(jobs: Job[], measDeviceStatuses: Status[]) {
  const job = getCurrentMultipartJob(jobs);

  if (job === null || job === undefined) {
    throw new Error('no job for multipart models');
  }

  const containers = findContainers(job) as JobContainer[];
  if (containers.length !== 1 && containers.length !== 2) {
    throw new Error(`unexpected number of containers: ${containers.length}`);
  }
  if (measDeviceStatuses.length > 2) {
    throw new Error(`unexpected number of meas devices: ${measDeviceStatuses.length}`);
  }
  if (measDeviceStatuses.length > 1 && containers.length !== measDeviceStatuses.length) {
    /*eslint-disable */
    throw new Error(
      `when multiple meas devices are used count must match container count, devices: ${measDeviceStatuses.length} containers: ${containers.length}`,
    );
    /*eslint-enable */
  }
  const containerLoads = findContainerLoads(job, containers);
  const successfulLoads = filterSuccessfulLoads(containerLoads);
  const minLoadCount = getMinLoadCount(successfulLoads);
  const maxLoadCount = getMaxLoadCount(successfulLoads);
  if (minLoadCount % 2 !== 0 && minLoadCount !== maxLoadCount && measDeviceStatuses.length > 1) {
    throw new Error(
      `unexpected number of meas devices when containers have different load counts: ${measDeviceStatuses.length}`,
    );
  }
  const jobIndex = jobs.findIndex((oneJob) => oneJob === job);
  const multipartRound = jobIndex + 1 + (minLoadCount >= 1 ? 1 : 0);
  const rounds = [minLoadCount >= 1 ? VehicleWeighingRound.Second : VehicleWeighingRound.First];
  const firstJob = jobs[0];
  const firstContainers = findContainers(firstJob) as JobContainer[];
  const firstContainerLoads = findContainerLoads(firstJob, firstContainers);
  const firstSuccessfulLoads = filterSuccessfulLoads(firstContainerLoads);
  const firstMinLoadCount = getMinLoadCount(firstSuccessfulLoads);
  const firstMaxLoadCount = getMaxLoadCount(firstSuccessfulLoads);

  const containerDirections = getContainerDirections(firstSuccessfulLoads);

  const canSum =
    measDeviceStatuses.length > 0 &&
    measDeviceStatuses
      .map((status) => status.canSum)
      .reduce((canSumAll, canSum) => canSumAll && canSum, true);

  const truckReferenceDirection = containerDirections[0];
  const weighingInfo: WeighingInfo = {
    referenceDirection: truckReferenceDirection,
    successfulLoads,
    measDeviceStatuses,
    containerDirections,
    minLoadCount,
    firstSuccessfulLoads,
  };
  return {
    models: rounds.map((round, index) => {
      const isCurrentRound = isRoundCurrent(round, minLoadCount);
      const resultContainers = containers.map((c, index) =>
        createContainer(c, round, index, isCurrentRound, weighingInfo),
      );
      const isAnyWrongDirection = resultContainers.reduce(
        (isWrong, container) => isWrong || container.isWrongDirection,
        false,
      );
      const directions = resultContainers.map((container) => container.direction);
      const isAnyOpposite =
        directions.includes(WeighingDirection.ADD) && directions.includes(WeighingDirection.REDUCE);

      const isWeighingEnabled = isCurrentRound && canSum && !isAnyWrongDirection && !isAnyOpposite;

      const loadI = lastLoadIndexWithoutDiscardedDeductions(job.loads, containers);
      const isLastLoadFixRelated = loadI >= 0 ? isLoadFixRelated(job.loads[loadI]) : false;
      const isDiscardingEnabled = loadI >= 0 && !isLastLoadFixRelated;
      return {
        round: multipartRound,
        containers: resultContainers,
        isActive: !!resultContainers.reduce(
          (isActive, container) => isActive || container.isActive,
          false,
        ),
        isDiscardingEnabled,
        isWeighingEnabled,
      };
    }),
    net: [],
    isFinishingEnabled:
      ((minLoadCount === 1 && maxLoadCount === 1) || (minLoadCount === 2 && maxLoadCount === 2)) &&
      firstMinLoadCount === 2 &&
      firstMaxLoadCount === 2,
    process: WeighingProcess.MULTIPART,
  };
}

export interface MainMaterialClearResult {
  deductions: DeductionRow[];
  hasMaterialChanged: boolean;
}

export function clearMainMaterials(
  deductions: DeductionRow[],
  mainMaterials: GenericMasterData[],
  containerKey: string | undefined,
): MainMaterialClearResult {
  let hasMaterialChanged = false;
  const mainMaterialKeys: Record<string, boolean> = {};
  mainMaterials.forEach((material) => (mainMaterialKeys[material.key] = true));
  const clearedDeductions = deductions.map((deduction) => {
    let material = deduction.material;
    if (
      !!material &&
      mainMaterialKeys[material.key] &&
      !!containerKey &&
      containerKey === deduction.containerKey
    ) {
      // NOTE(mikkogy,20220221) it is possible to edit order to have the
      // same main material as deduction has. In this case it is impossible
      // to fix the deduction that has main material if it is in the other
      // container that is edited. To keep things simple we clear the
      // material and notify user.
      material = undefined;
      hasMaterialChanged = true;
    }
    return {
      ...deduction,
      material,
    };
  });
  return {
    deductions: clearedDeductions,
    hasMaterialChanged,
  };
}

export function isAnyNetNegative(vehicleWeighingModels: VehicleWeighingModels): boolean {
  return vehicleWeighingModels.net.some(isNetNegative);
}

export function isNetNegative(netContainer: NetContainer): boolean {
  const netKgs = netContainer.netKgs;
  return netKgs < 0 && netKgs !== undefinedMassKg;
}

export function isNetWeightVisible(netContainer: NetContainer | undefined): boolean {
  if (!netContainer) return false;
  return netContainer.weighedKgs !== undefinedMassKg && !isNetNegative(netContainer);
}
