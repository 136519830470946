import { Reducer } from 'redux';
import { UserActionTypes } from '../user/types';
import { SitesState, SitesActionTypes } from './types';

export const initialState: SitesState = {
  data: [],
  errors: undefined,
  selectedKey: undefined,
  loading: false,
  lastSelectedKeys: [],
  autoselectSite: true,
};

const reducer: Reducer<SitesState> = (state = initialState, action) => {
  switch (action.type) {
    case SitesActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true };
    }
    case SitesActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, data: action.payload, errors: undefined };
    }
    case SitesActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    case SitesActionTypes.SELECT_SITE: {
      const lastSelectedKeys = [action.payload].concat(state.lastSelectedKeys.slice(0, 2));
      return {
        ...state,
        lastSelectedKeys,
        selectedKey: action.payload,
      };
    }
    case SitesActionTypes.CHANGE_SITE: {
      return {
        ...state,
        loading: false,
        selectedKey: undefined,
        autoselectSite: action.payload,
      };
    }
    case UserActionTypes.LOGOUT: {
      return {
        ...state,
        loading: false,
        data: [],
        selectedKey: undefined,
        autoselectSite: true,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as sitesReducer };
