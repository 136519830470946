import { PersistedState } from 'redux-persist';

import { ContainerType, RemoteMasterDataType } from '../common/types';

import { MasterDataState } from './types';

import { initialPagination, toMasterDataStateType } from './utils';

export const masterDataMigrations = {
  1: (state: PersistedState) => {
    if (!state) return {} as PersistedState;
    const masterDataState = state as unknown as MasterDataState;
    if (!masterDataState || !masterDataState.dataTypes) {
      return {
        ...state,
      };
    }
    // NOTE(mikkogy,20220204) old version of interface. Need to cast to any or
    // maintain old types.
    const dataTypes = { ...masterDataState?.dataTypes } as any;
    function emptyList() {
      return {
        loading: false,
        data: [],
        pagination: initialPagination(),
      };
    }
    if (dataTypes['container']) {
      const truckStateType = toMasterDataStateType(
        RemoteMasterDataType.CONTAINER,
        ContainerType.TRUCK,
      );
      // NOTE(mikkogy,20210720) previously we only had trucks so existing lists
      // contain trucks only.
      dataTypes[truckStateType] = { ...dataTypes['container'] };
      if (!dataTypes[truckStateType].onePageList) {
        dataTypes[truckStateType].onePageList = emptyList();
      }
      if (!dataTypes[truckStateType].allDataList) {
        dataTypes[truckStateType].allDataList = emptyList();
      }
      if (!dataTypes[truckStateType].lastSelectedKeys) {
        dataTypes[truckStateType].lastSelectedKeys = [];
      }
      const genericStateType = toMasterDataStateType(
        RemoteMasterDataType.CONTAINER,
        ContainerType.GENERIC,
      );
      // NOTE(mikkogy,20210720) initialize generic too. We cannot have had data
      // so empty is fine.
      dataTypes[genericStateType] = {
        onePageList: emptyList(),
        allDataList: emptyList(),
        lastSelectedKeys: [],
      };
      delete dataTypes['container'];
    }
    return {
      ...state,
      dataTypes: dataTypes,
    } as PersistedState;
  },
  2: (state: PersistedState) => {
    if (!state) return {} as PersistedState;
    const masterDataState = state as unknown as MasterDataState;
    if (!masterDataState || !masterDataState.dataTypes) {
      return {
        ...state,
      };
    }
    // NOTE(mikkogy,20220204) old version of interface. Need to cast to any or
    // maintain old types.
    const dataTypes = { ...masterDataState?.dataTypes } as any;
    function emptyList() {
      return {
        loading: false,
        data: [],
        pagination: initialPagination(),
      };
    }
    const truckStateType = toMasterDataStateType(
      RemoteMasterDataType.CONTAINER,
      ContainerType.TRUCK,
    );
    Object.keys(dataTypes).forEach((type) => {
      const lastSelectedKeys = dataTypes[type]?.lastSelectedKeys || [];
      dataTypes[type] = {
        ...dataTypes[type],
        lastSelected: lastSelectedKeys.map((key: string) => ({
          key,
          name: '',
        })),
      };
      if (type !== truckStateType) {
        dataTypes[type].onePageList = emptyList();
        dataTypes[type].allDataList = emptyList();
      }
    });
    return {
      ...state,
      dataTypes: { ...dataTypes },
    } as PersistedState;
  },
  3: (state: PersistedState) => {
    if (!state) return {} as PersistedState;
    const masterDataState = state as unknown as MasterDataState;
    if (!masterDataState || !masterDataState.dataTypes) {
      return {
        ...state,
      };
    }
    // NOTE(mikkogy,20220204) old version of interface. Need to cast to any or
    // maintain old types.
    const dataTypes = { ...masterDataState?.dataTypes } as any;
    Object.keys(dataTypes).forEach((type) => {
      delete dataTypes[type].allDataList;
    });
    return {
      ...state,
      dataTypes: { ...dataTypes },
    } as PersistedState;
  },
};
