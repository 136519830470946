import { all, call, delay, fork, put, select, takeEvery } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import moment from 'moment';
import { callApi } from 'utils/api';
import { RoutePaths } from 'routes';
import i18n, { setupMoment } from 'i18n';
import { getLanguageFromLocale } from 'models/languages.model';
import {
  getCurrentContextId,
  getTokenHeader,
  getSelectedUiRole,
  getSite,
  getUser,
  getEnabledFeatures,
} from '../selectors';
import * as LiveDataActions from '../common/actions';
import { changeSite } from '../sites/actions';
import { SitesActionTypes } from '../sites/types';
import { destroyContext } from '../weighing/actions';
import { effectiveUiRole, parseDocumentKey } from '../utils';
import {
  appliedLocale,
  applyLocale,
  destroyToken,
  fetchError,
  fetchSuccess,
  loginRequest,
  loginSuccess,
  logout,
  logoutRequest,
  roleChanged,
  selectLocale,
  storeSelectedLocale,
  storeUserDomains,
  tokenRenewSuccess,
} from './actions';
import { UiRoleActionTypes, UiRoles, UserActionTypes, UserDetails, UserDomain } from './types';
import { createTokenHeader } from './utils';

const API_ENDPOINT = `${process.env.REACT_APP_PILVILINNA_URL || 'http://localhost/'}api/v1/`;
enum TokenResponseStatus {
  UNAUTHORIZED = 'UNAUTHORIZED',
}

const unauthorizedError = 'unauthorized';
const unknownError = 'unknown';

interface Res {
  error: string | undefined;
  status: string | number | undefined;
}

// NOTE(mikkogy,20220929) depending on how request fails One Cloud REST responds
// with a few different ways. This helper should not be used when a client error
// such as a missing or invalid parameter is possible.
function isResUnauthorized(res: Res | undefined) {
  if (!res) return false;
  return res.error || res.status === TokenResponseStatus.UNAUTHORIZED || res.status === 401;
}

function* doLogout() {
  yield put(logout());
  yield put(LiveDataActions.disconnectRequest());
  yield put(replace(RoutePaths.LOGIN));
}

function* handleDestroyToken(): any {
  const user = yield select(getUser);
  if (user && user.loginData) {
    console.warn('Destroying token, credentials found, trying to log in.');
    yield put(loginRequest(user.loginData));
  } else {
    console.warn('Destroying token, credentials not found, logging out.');
    yield call(doLogout);
  }
}

function* handleFetchError(error: string, stack?: string) {
  console.warn('User details request failed', error, stack);
  yield put(fetchError(error));
  yield put(destroyToken());
}

function* handleLoginError(error: string, stack?: string) {
  // Always logs out if request can't be made
  console.warn('User details request failed, logging out', error, stack);
  yield call(doLogout);

  yield put(fetchError(error));
}

interface UserAccount {
  userDetails: UserDetails;
  userDomains: UserDomain[];
}

export interface UserAccountResult {
  userAccount: UserAccount | undefined;
  error: string | undefined;
}

export function* fetchAccount(tokenHeader: Record<string, string>): any {
  let userResponse = undefined;
  if (tokenHeader) {
    try {
      userResponse = yield fetchUser(tokenHeader);
    } catch (e) {}
  }

  function* errorHandler(response: any, errorString: string) {
    const responseErrorResult: UserAccountResult = {
      userAccount: undefined,
      error: response?.error ?? unknownError,
    };
    console.error(errorString, responseErrorResult);
    if (!tokenHeader || isResUnauthorized(response)) {
      const errorString: string = responseErrorResult.error as string;
      yield call(handleFetchError, errorString);
    }
    return responseErrorResult;
  }

  if (!tokenHeader || !userResponse || isResUnauthorized(userResponse)) {
    return yield call(errorHandler, userResponse, 'user request failed');
  }

  const organizationId = userResponse.organization;
  const userKey = userResponse.key;
  let userDomainsResponse = undefined;
  try {
    userDomainsResponse = yield fetchUserDomains(organizationId, userKey, tokenHeader);
  } catch (e) {}

  if (!userDomainsResponse || isResUnauthorized(userDomainsResponse)) {
    return yield call(errorHandler, userDomainsResponse, 'user domain request failed');
  }

  const userAccount: UserAccount = { userDetails: userResponse, userDomains: userDomainsResponse };
  const userAccountResult = { userAccount, error: undefined };
  return userAccountResult;
}

export function fetchOrganization(organizationId: string, tokenHeader: Record<string, string>) {
  return call(
    callApi,
    'get',
    API_ENDPOINT,
    `export/organizations/${organizationId}`,
    null,
    tokenHeader,
  );
}

function fetchUser(tokenHeader: Record<string, string>) {
  return call(callApi, 'get', API_ENDPOINT, 'export/account/', null, tokenHeader);
}

function fetchUserDomains(
  organizationId: string,
  userKey: string,
  tokenHeader: Record<string, string>,
) {
  return call(
    callApi,
    'get',
    API_ENDPOINT,
    `export/organizations/${organizationId}/document_domain_search/${userKey}`,
    null,
    tokenHeader,
  );
}

export function* updateStoreUserDomains(): any {
  const tokenHeader = yield select(getTokenHeader);
  const user = yield select(getUser);
  if (!user.isAuthed) {
    const emptyDomains: UserDomain[] = [];
    yield put(storeUserDomains(emptyDomains));
    return emptyDomains;
  }

  const accountResult: UserAccountResult = yield call(fetchAccount, tokenHeader);
  const userDomains: UserDomain[] = accountResult.userAccount
    ? accountResult.userAccount.userDomains
    : user.domains;
  yield put(storeUserDomains(userDomains));
  return userDomains;
}

function* handleFetch(): any {
  try {
    const tokenHeader = yield select(getTokenHeader);
    const accountResult: UserAccountResult = yield call(fetchAccount, tokenHeader);

    if (accountResult.error) {
      yield handleFetchError(accountResult.error);
    } else {
      if (!accountResult.userAccount) {
        throw new Error('must not happen, user account fetch did not have error or userAccount');
      }
      const userDetails = accountResult.userAccount.userDetails;
      const userLanguage = getLanguageFromLocale(
        userDetails.locale ??
          (yield fetchOrganization(userDetails.organization, tokenHeader)).defaultLocale,
      );
      yield put(selectLocale(userLanguage));
      yield put(
        fetchSuccess({
          domains: accountResult.userAccount.userDomains,
          isAuthed: true,
          userData: userDetails,
        }),
      );
    }
  } catch (err) {
    if (err instanceof Error && err.stack != null) {
      yield handleFetchError(unknownError, err.stack);
    } else {
      yield handleFetchError(unknownError);
    }
  }
}

function getAuthHeader(username: string, password: string) {
  const authContent = `${username}:${password}`;
  const authHeader = `Basic ${Buffer.from(authContent).toString('base64')}`;
  return { Authorization: authHeader };
}

function* toInitialRoute(uiRole: UiRoles): any {
  const user = yield select(getUser);
  const scaleKey = yield select(getSite);
  if (!scaleKey) {
    console.log('No scale key, navigate to path', RoutePaths.SITES);
    yield put(replace(RoutePaths.SITES));
    return;
  }
  const role = effectiveUiRole(uiRole, user, yield select(getEnabledFeatures));
  if (role === UiRoles.OPERATOR) {
    yield put(replace(RoutePaths.DASHBOARD));
  } else {
    yield put(replace(RoutePaths.ORDERS));
  }
}

function* handleLogin(action: ReturnType<typeof loginRequest>): any {
  try {
    const headers = yield getAuthHeader(action.payload.username, action.payload.password);

    const res = yield call(callApi, 'post', API_ENDPOINT, 'export/token/', null, headers);

    if (isResUnauthorized(res)) {
      yield handleLoginError(unauthorizedError);
    } else {
      console.log('User token fetched');
      const tokenHeader = createTokenHeader(res.token);
      const accountResult: UserAccountResult = yield call(fetchAccount, tokenHeader);
      let organizationResponse;
      if (accountResult.error || !accountResult.userAccount) {
        organizationResponse = undefined;
      } else {
        organizationResponse = yield fetchOrganization(
          accountResult.userAccount.userDetails.organization,
          tokenHeader,
        );
      }
      console.log('Organization', organizationResponse);
      if (
        !organizationResponse ||
        !organizationResponse?.key ||
        accountResult.error ||
        !accountResult.userAccount
      ) {
        yield handleLoginError(unauthorizedError, organizationResponse);
      } else {
        const scaleKey = yield select(getSite);
        if (scaleKey && organizationResponse.key !== parseDocumentKey(scaleKey).organizationId) {
          yield put(changeSite(true));
          yield put(LiveDataActions.updateLastEventTime());
        }
        const userLanguage = getLanguageFromLocale(
          accountResult.userAccount.userDetails.locale ?? organizationResponse.defaultLocale,
        );
        yield put(selectLocale(userLanguage));
        yield put(
          loginSuccess({
            domains: accountResult.userAccount.userDomains,
            isAuthed: true,
            token: res.token,
            organizationData: organizationResponse,
            userData: accountResult.userAccount.userDetails,
            loginData: action.payload,
          }),
        );

        const uiRole = yield select(getSelectedUiRole);
        yield call(toInitialRoute, uiRole);
      }
    }
  } catch (err) {
    console.error('Error with user auth', err);
    if (err instanceof Error && err.stack != null) {
      yield handleLoginError(unknownError, err.stack);
    } else {
      yield handleLoginError(unknownError);
    }
  }
}

function* handleLogout(action: ReturnType<typeof logoutRequest>): any {
  const contextId = yield select(getCurrentContextId);
  if (contextId) {
    yield put(destroyContext());
  }
  yield call(doLogout);
}

function* handleSiteSelected(): any {
  const uiRole = yield select(getSelectedUiRole);
  yield call(toInitialRoute, uiRole);
}

function* handleRoleChanged(action: ReturnType<typeof roleChanged>) {
  yield call(toInitialRoute, action.payload);
}

function* handleApplyLocale(action: ReturnType<typeof applyLocale>) {
  const locale = action.payload;
  setupMoment();
  i18n.changeLanguage(locale);
  moment.locale(locale);
  yield put(appliedLocale());
}

function* handleSelectLocale(action: ReturnType<typeof selectLocale>) {
  const locale = action.payload;
  yield put(applyLocale(locale));
  yield put(storeSelectedLocale(locale));
}

function* watchSiteSelected() {
  yield takeEvery(SitesActionTypes.SELECTED, handleSiteSelected);
}

function* watchDestroyToken() {
  yield takeEvery(UserActionTypes.DESTROY_TOKEN, handleDestroyToken);
}

function* watchFetchRequest() {
  yield takeEvery(UserActionTypes.FETCH_REQUEST, handleFetch);
}

function* watchLoginRequest() {
  yield takeEvery(UserActionTypes.LOGIN_REQUEST, handleLogin);
}

function* watchLogoutRequest() {
  yield takeEvery(UserActionTypes.LOGOUT_REQUEST, handleLogout);
}

function* watchRoleChanged() {
  yield takeEvery(UiRoleActionTypes.ROLE_CHANGED, handleRoleChanged);
}

function* watchApplyLocale() {
  yield takeEvery(UserActionTypes.APPLY_LOCALE, handleApplyLocale);
}

function* watchSelectLocale() {
  yield takeEvery(UserActionTypes.SELECT_LOCALE, handleSelectLocale);
}

function* renewToken(): any {
  while (true) {
    try {
      const delayMs = `${process.env.REACT_APP_TOKEN_RENEWAL_S || '1800'}`;
      yield delay(parseInt(delayMs, 10) * 1000);

      const user = yield select(getUser);
      if (user.isAuthed) {
        const tokenHeader = yield select(getTokenHeader);
        if (!tokenHeader) {
          yield put(destroyToken());
          continue;
        }

        const res = yield call(callApi, 'post', API_ENDPOINT, 'export/token/', null, tokenHeader);
        if (!isResUnauthorized(res) && res.token) {
          yield put(tokenRenewSuccess(res.token));
        }
      }
    } catch (err) {}
  }
}

function* userSaga() {
  yield all([
    fork(renewToken),
    fork(watchDestroyToken),
    fork(watchFetchRequest),
    fork(watchLoginRequest),
    fork(watchLogoutRequest),
    fork(watchRoleChanged),
    fork(watchSiteSelected),
    fork(watchApplyLocale),
    fork(watchSelectLocale),
  ]);
}

export default userSaga;
