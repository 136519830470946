import { action } from 'typesafe-actions';
import { GenericMasterData } from '../master-data/types';
import { Vehicle, VehiclesActionTypes } from './types';

export const selectVehicle = (vehicle: Vehicle, trailerKey?: string) =>
  action(VehiclesActionTypes.SELECT_VEHICLE, { vehicle, trailerKey });
export const vehicleSelected = (vehicle: Vehicle, trailerKey?: string) =>
  action(VehiclesActionTypes.SELECTED, { vehicle, trailerKey });
export const clearVehicle = () => action(VehiclesActionTypes.CHANGE_VEHICLE);
export const updateSelectedVehicle = (masterData: GenericMasterData[]) =>
  action(VehiclesActionTypes.UPDATE_SELECTED_VEHICLE, { masterData });
