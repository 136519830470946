import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { RouterState } from 'connected-react-router';

import { Button } from '@mui/material';

import { makeStyles } from '@mui/styles';

import { connect, localeConnect } from '../localeConnect';
import { ApplicationState, ConnectedReduxProps } from '../store';

import { clear, deepLinkFailure, loadDeepLink } from '../store/deep-link/actions';

import { logout } from '../store/user/actions';

import { mobileContentMaxWidth, paperRoot } from '../theme';

import { RoutePaths } from '../routes';

import { translate } from '../utils/translate';
import LoadingIndicator, { LoadingIndicatorPositioning } from './LoadingIndicator';

interface PropsFromState {
  hasError: boolean;
  inProgressDeepLinkId?: string;
  returnLink?: string;
  router: RouterState;
}

interface PropsFromDispatch {
  clear: typeof clear;
  deepLinkFailure: typeof deepLinkFailure;
  loadDeepLink: typeof loadDeepLink;
  logout: typeof logout;
}

/*eslint-disable */
type AllProps = PropsFromDispatch &
  PropsFromState &
  RouteComponentProps<Record<string, never>> &
  ConnectedReduxProps;
/*eslint-enable */

const mapStateToProps = (state: ApplicationState) => ({
  hasError: state.deepLink.hasError,
  inProgressDeepLinkId: state.deepLink.inProgressDeepLinkId,
  returnLink: state.deepLink.returnLink,
});

const mapDispatchToProps = {
  clear,
  deepLinkFailure,
  loadDeepLink,
  logout,
};

const useStyles = makeStyles(() => ({
  // eslint-disable-next-line
  root: paperRoot,
  // eslint-disable-next-line
  container: {
    padding: '30px',
    textAlign: 'center',
    maxWidth: mobileContentMaxWidth,
    margin: 'auto',
  },
  '@keyframes backFadeIn': {
    '0%': {
      visibility: 'hidden',
      height: '0px',
    },
    '99%': {
      visibility: 'hidden',
      height: '0px',
    },
    '100%': {
      visibility: 'visible',
      height: '20px',
    },
  },
  // eslint-disable-next-line
  back: {
    // NOTE(mikkogy,20220329) just in case we don't catch all error cases
    // back button is made visible using an animation so user can always
    // return if this view remains visible.
    animation: '$backFadeIn 20s',
    marginTop: '20px',
    marginBottom: '30px',
  },
  // eslint-disable-next-line
  backVisible: {
    animation: 'none',
  },
  // eslint-disable-next-line
  text: {
    marginTop: '30px',
  },
  // eslint-disable-next-line
  spinnerContainer: {
    position: 'relative',
    minHeight: '200px',
  },
}));

const DeepLink = (props: AllProps) => {
  const classes = useStyles();
  const linkId = props.match.params.linkId;
  const loadDeepLink = props.loadDeepLink;

  useEffect(() => {
    if (linkId) {
      loadDeepLink(linkId);
    }
  }, [linkId, loadDeepLink]);

  function back() {
    const returnLink = props.returnLink;
    props.clear();
    props.logout();
    if (returnLink) {
      window.location.replace(returnLink);
    } else {
      props.history.push(RoutePaths.LOGIN);
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <img alt="Tamtron" src="/static/images/tamtron-logo.png" />
        {!props.hasError && (
          <div>
            <div className={classes.text}>{translate('deepLink.loading')}</div>
            <div className={classes.spinnerContainer}>
              <LoadingIndicator
                positioning={LoadingIndicatorPositioning.ABSOLUTE}
                isLoading={true}
              />
            </div>
          </div>
        )}
        {props.hasError && <div className={classes.text}>{translate('deepLink.error')}</div>}
        <Button
          color="primary"
          variant="outlined"
          className={`${classes.back} ${props.hasError ? classes.backVisible : ''}`}
          onClick={back}
        >
          <div>{translate('deepLink.back')}</div>
        </Button>
      </div>
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(mapStateToProps, mapDispatchToProps)(DeepLink);
