import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface ParameterProps {
  vehicleName: string;
  trailerName: string | undefined;
}

const useStyles = makeStyles({
  vehicleTitleContainer: {
    justifyContent: 'center',
    margin: '4px auto 4px auto',
  },
  vehicleTitleItem: {
    borderRadius: '5px',
    border: '1px solid white',
    letterSpacing: '0.25px',
    margin: '4px 8px 4px 8px',
    minWidth: '120px',
    padding: '0px 10px 0px 10px',
  },
});

const NavBarVehicle = (props: ParameterProps) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.vehicleTitleContainer}>
      <Grid item className={classes.vehicleTitleItem}>
        <Typography variant="h6">{props.vehicleName}</Typography>
      </Grid>
      {props.trailerName !== undefined && (
        <Grid item className={classes.vehicleTitleItem}>
          <Typography variant="h6">{props.trailerName}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default NavBarVehicle;
