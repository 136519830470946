import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { callApi } from 'utils/api';
import { incomingEvent, sendMessage } from '../common/actions';
import {
  LiveDataTypes,
  RemoteRequestIds,
  RemoteRequestTypes,
  RemoteReceiveTypes,
} from '../common/types';
import { ScaleInfoActionTypes } from '../scale-info/types';
import { getHasOrganizationRoleRight } from '../user/utils';
import {
  getDomainInfo,
  getDriverReceiptJobs,
  getIsDummyScale,
  getQueryParams,
  getSite,
  getTokenHeader,
  getUser,
  getWebsocketConnected,
} from '../selectors';
import { formatReceipt } from '../utils';
import {
  fetchSuccess,
  generateReceipt,
  jobQueryRequest,
  jobQuerySuccess,
  latestJobsQueryRequest,
  latestJobsQuerySuccess,
  receiptSuccess,
  setInspectionStatus,
} from './actions';
import {
  Job,
  JobsActionTypes,
  JobQueryParams,
  JobsResponsePayload,
  JobQueryResponse,
  JobStatus,
  OperatorQueryParams,
} from './types';

const API_ENDPOINT = `${process.env.REACT_APP_PILVILINNA_URL || 'http://localhost/'}api/v1/`;

function* handleFetch(): any {
  const payload = {
    msgType: RemoteRequestTypes.LIST_WEIGHING_JOBS,
    reqId: RemoteRequestIds.LIST_WEIGHING_JOBS,
  };
  const connected = yield select(getWebsocketConnected);

  // If the connection to websocket is not yet up, wait for it
  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* doJobQuery(
  queryParams: JobQueryParams,
  successHandler: (response: JobQueryResponse) => {
    type: JobsActionTypes;
    payload: JobQueryResponse;
  },
): any {
  function logError(err: any) {
    console.warn('job query failed', err);
  }
  function createParams(user: any) {
    const hasOrgRights = getHasOrganizationRoleRight(user.userData, 'listOrgJobs');

    if (hasOrgRights) {
      return { ...queryParams };
    }
    return { ...queryParams, userKey: user.userData.organization + '::' + user.userData.id };
  }
  try {
    const tokenHeader = yield select(getTokenHeader);
    if (!tokenHeader) {
      logError('no token');
      return;
    }
    const scaleKey = yield select(getSite);
    const isDummyScale = yield select(getIsDummyScale);
    if (!isDummyScale && !scaleKey) {
      return;
    }
    const user = yield select(getUser);
    const orgId = user.userData.organization;
    const params = createParams(user);
    params.organization = orgId;
    params.scaleKeys = isDummyScale ? undefined : [scaleKey];
    const res = yield call(
      callApi,
      'post',
      API_ENDPOINT,
      `export/organizations/${orgId}/job_query`,
      params,
      tokenHeader,
    );

    if (res.error) {
      logError(res.error);
    } else {
      yield put(successHandler(res));
    }
  } catch (err) {
    logError(err);
  }
}

function* handleJobQuery(action: ReturnType<typeof jobQueryRequest>) {
  yield call(doJobQuery, action.payload, jobQuerySuccess);
}

function* handleJobQueryRefresh() {
  const jobQueryParams: OperatorQueryParams = yield select(getQueryParams);
  yield call(doJobQuery, jobQueryParams, jobQuerySuccess);
}

function* handleLatestJobsQuery() {
  const startTime = new Date();
  startTime.setMonth(startTime.getMonth() - 1);
  // NOTE(mikkogy,20220217) discarded are not interesting but other statuses
  // are. There isn't this kind of filtering in Pilvilinna REST so we load more
  // than needed and show less. It is possible we don't get as many results as
  // needed or any at all but this is expected to be so rare that it does not
  // matter in real-world usage.
  const wantedCount = 10;
  const queryParams = {
    page: 0,
    size: wantedCount * 2,
    startTime: startTime.getTime(),
    endTime: new Date().getTime(),
  };
  function handler(results: JobQueryResponse) {
    const filteredResults = {
      ...results,
      results: results.results
        .filter((job: Job) => job.status !== JobStatus.DISCARDED)
        .slice(0, wantedCount),
    };
    return latestJobsQuerySuccess(filteredResults);
  }
  yield call(doJobQuery, queryParams, handler);
}

function* handleGenerateReceipt(action: ReturnType<typeof generateReceipt>): any {
  const payload = {
    msgType: RemoteRequestTypes.GENERATE_RECEIPT,
    reqId: RemoteRequestIds.GENERATE_RECEIPT,
    payload: {
      receipt: action.payload,
    },
  };
  const connected = yield select(getWebsocketConnected);

  // If the connection to websocket is not yet up, wait for it
  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleJobsResponse(response: JobsResponsePayload): any {
  const jobs = response.weighingJobs;
  yield put(fetchSuccess(jobs));
  if (jobs && jobs.length > 0) {
    const domainInfo = yield select(getDomainInfo);
    const driverReceiptJobs = yield select(getDriverReceiptJobs);
    for (const i in driverReceiptJobs) {
      const driverReceiptJob = driverReceiptJobs[i];
      yield put(generateReceipt(formatReceipt(driverReceiptJob, domainInfo)));
    }
  }
}

function* handleResponse(action: ReturnType<typeof incomingEvent>) {
  if (action.payload.msgType === RemoteReceiveTypes.WEIGHING_JOB_LIST) {
    const response: JobsResponsePayload = action.payload.payload;
    yield call(handleJobsResponse, response);
  } else if (action.payload.msgType === RemoteReceiveTypes.RECEIPT) {
    const receipt: string = action.payload.payload.receipt;
    yield put(receiptSuccess(receipt));
  }
}

function* handleSetInspectionStatus(action: ReturnType<typeof setInspectionStatus>): any {
  const payload = {
    msgType: RemoteRequestTypes.SET_INSPECTION_STATUS,
    reqId: RemoteRequestIds.SET_INSPECTION_STATUS,
    payload: {
      contextId: action.payload.contextId,
      inspectionStatus: action.payload.inspectionStatus,
      weighingJobKey: action.payload.weighingJobKey,
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* pollLatestJobs() {
  try {
    console.log('Starting polling latest jobs');
    while (true) {
      yield put(latestJobsQueryRequest());
      const delayS = `${process.env.REACT_APP_LATEST_JOBS_POLL_INTERVAL_S || '120'}`;
      yield delay(parseInt(delayS, 10) * 1000);
    }
  } finally {
    console.log('Stopped polling latest jobs');
  }
}

function* watchGenerateReceipt() {
  yield takeEvery(JobsActionTypes.GENERATE_RECEIPT, handleGenerateReceipt);
}

function* watchFetchRequest() {
  yield takeEvery(JobsActionTypes.FETCH_REQUEST, handleFetch);
}

function* watchJobQueryRefresh() {
  yield takeEvery(JobsActionTypes.JOB_QUERY_REFRESH, handleJobQueryRefresh);
}

function* watchJobQueryRequest() {
  yield takeEvery(JobsActionTypes.JOB_QUERY_REQUEST, handleJobQuery);
}

function* watchLatestJobsQueryRequest() {
  yield takeEvery(JobsActionTypes.LATEST_JOBS_QUERY_REQUEST, handleLatestJobsQuery);
}

function* watchJobsDataReceived() {
  yield takeLatest(LiveDataTypes.INCOMING_EVENT, handleResponse);
}

function* watchStartLatestJobsPoll(): any {
  while (yield take(JobsActionTypes.START_LATEST_JOBS_POLL)) {
    const task = yield fork(pollLatestJobs);
    yield take(JobsActionTypes.STOP_LATEST_JOBS_POLL);
    yield cancel(task);
  }
}

function* watchScaleInfoSuccess() {
  while (true) {
    yield take(ScaleInfoActionTypes.SCALE_INFO_SUCCESS);
    // NOTE(mikkogy,20200212) ensure store updates before trying to query jobs.
    yield delay(1);
    yield call(handleLatestJobsQuery);
  }
}
function* watchSetInspectionStatus() {
  yield takeEvery(JobsActionTypes.SET_INSPECTION_STATUS, handleSetInspectionStatus);
}

function* jobsSaga() {
  yield all([
    fork(watchFetchRequest),
    fork(watchJobQueryRefresh),
    fork(watchJobQueryRequest),
    fork(watchLatestJobsQueryRequest),
    fork(watchGenerateReceipt),
    fork(watchJobsDataReceived),
    fork(watchScaleInfoSuccess),
    fork(watchSetInspectionStatus),
    fork(watchStartLatestJobsPoll),
  ]);
}

export default jobsSaga;
