import React, { useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import { Box, Container, Drawer, IconButton, Paper } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import theme, { defaultRoot } from 'theme';

import { scrollToTop } from '../utils';
import TemporaryDrawer from '../TemporaryDrawer';
import AccountPopover from './AccountPopover';
import OperatorDrawerContent from './OperatorDrawerContent';

interface ParameterProps {
  children: NonNullable<React.ReactNode>;
  header?: React.ReactNode;
  title: string;
  title2: string;
}

const drawerWidth = 70;

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginLeft: '0px',
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuItemText: {
    marginLeft: '15px',
    marginRight: '20px',
  },
  topLeftMenuContainer: {
    float: 'left',
    marginTop: '0px',
    marginLeft: '0px',
    color: theme.palette.primary.main,
  },
  topRightMenuContainer: {
    float: 'right',
    marginTop: '15px',
    marginRight: '15px',
  },
  clear: {
    clear: 'both',
  },
  container: {
    maxWidth: '2000px',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerOpen: {
    width: 'inherit',
  },
  drawerClosed: {
    width: drawerWidth,
  },
  drawerPaper: {
    backgroundColor: '#006eaa',
  },
  toolbar: theme.mixins.toolbar,
  toolbarContent: {
    '& .MuiListItemIcon-root': {
      padding: 0,
      minWidth: '24px !important',
      color: '#fff',
    },
    '& .MuiListItem-gutters': {
      paddingLeft: '23px',
      paddingRight: 0,
    },
    '& a': {
      color: '#fff',
      textDecoration: 'none',
    },
  },
  header: {
    minHeight: '146px',
    marginBottom: '25px',
  },
  content: {
    flexGrow: 1,
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      maxWidth: `calc(100% - ${drawerWidth}px)`,
    },
  },
  titles: {
    // eslint-disable-next-line
    paddingLeft: '25px',
    // eslint-disable-next-line
    paddingRight: '25px',
    // eslint-disable-next-line
    textAlign: 'left',
    '& div': {
      marginBottom: '10px',
    },
  },
  title: {
    fontSize: '1.3rem',
  },
  title2: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
  },
});

export type AllProps = ParameterProps;

const OperatorContainer = (props: AllProps) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  useEffect(() => {
    scrollToTop();
  }, [props.title, props.title2]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (isOpen: boolean) => (
    <OperatorDrawerContent isOpen={isOpen} handleDrawerToggle={handleDrawerToggle} />
  );

  return (
    <div style={defaultRoot} className={classes.root}>
      <nav className={classes.drawer}>
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' } }}>
          <TemporaryDrawer
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            isOpen={mobileOpen}
            onClose={handleDrawerToggle}
            paperClass={`${classes.drawerPaper} ${classes.drawerOpen}`}
          >
            {drawer(true)}
          </TemporaryDrawer>
        </Box>
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' } }}>
          <Drawer
            classes={{
              paper: `${classes.drawerPaper} ${classes.drawerClosed}`,
            }}
            variant="permanent"
            open
          >
            {drawer(false)}
          </Drawer>
        </Box>
      </nav>
      <div className={classes.content}>
        <Paper className={`App-print-hide ${classes.header}`}>
          <div className={classes.topLeftMenuContainer}>
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <div className={classes.topRightMenuContainer}>
            <AccountPopover />
          </div>
          <div className={classes.clear} />
          <div>
            <div className={classes.titles}>
              <div className={classes.title}>{props.title}</div>
              <div className={classes.title2}>{props.title2}</div>
            </div>
          </div>
          {props.header}
        </Paper>
        <Container className={classes.container}>{props.children}</Container>
      </div>
    </div>
  );
};

export default OperatorContainer;
