import { ContainerItem } from 'store/common/types';
import { jobsTrucksAndTrailers } from 'store/jobs/utils';
import { Context, ComponentGroup } from 'store/orders/types';
import { getNormalWeighingMainComponents } from 'store/orders/utils';
import { BridgeEnabledFeatures, OrganizationEnabledFeatures } from 'store/scale-info/types';
import { UserDetails } from 'store/user/types';

import { createSplitLoadModelFromContext, SplitLoadModel } from './splitload.model';
import {
  NetContainer,
  WeighingContainer,
  contextToNormalVehicleWeighingModels,
} from './vehicleweighing.model';

export interface NormalWeighingContainerModel {
  firstRound: WeighingContainer;
  secondRound: WeighingContainer;
  net: NetContainer;
  componentId: string | undefined;
  container: ContainerItem;
  splitLoadModel: SplitLoadModel;
}

export function createContainerModels(
  context: Context,
  enabledFeatures: BridgeEnabledFeatures,
  organizationEnabledFeatures: OrganizationEnabledFeatures,
  scaleKey: string,
  userDetails: UserDetails,
): NormalWeighingContainerModel[] {
  const components = getNormalWeighingMainComponents(context.order);

  const model = contextToNormalVehicleWeighingModels(context, [], enabledFeatures);

  const truckFirstRound = model.models[0].containers[0];
  const trailerFirstRound = model.models[0].containers[1];
  const truckSecondRound = model.models[1].containers?.[0];
  const trailerSecondRound = model.models[1].containers?.[1];
  const truckNet = model.net[0];
  const trailerNet = model.net?.[1];
  const trucksAndTrailers = jobsTrucksAndTrailers(context.weighingJobs ?? []);
  const hasTrailer =
    !!trailerFirstRound && !!trailerSecondRound && !!trailerNet && !!trucksAndTrailers[1];

  const containerModels: NormalWeighingContainerModel[] = [
    {
      firstRound: truckFirstRound,
      secondRound: truckSecondRound,
      net: truckNet,
      componentId: components.truck.id,
      container: trucksAndTrailers[0],
      splitLoadModel: createSplitLoadModelFromContext({
        context,
        componentGroup: ComponentGroup.TRUCK,
        organizationEnabledFeatures,
        scaleKey,
        userDetails,
      }),
    },
  ];
  if (hasTrailer) {
    containerModels.push({
      firstRound: trailerFirstRound,
      secondRound: trailerSecondRound,
      net: trailerNet,
      componentId: components.trailer?.id,
      container: trucksAndTrailers[1],
      splitLoadModel: createSplitLoadModelFromContext({
        context,
        componentGroup: ComponentGroup.TRAILER,
        organizationEnabledFeatures,
        scaleKey,
        userDetails,
      }),
    });
  }
  return containerModels;
}
