export enum ScaleInfoActionTypes {
  DOMAIN_INFO = '@@scale-info/DOMAIN_INFO',
  SCALE_INFO_SUCCESS = '@@scale-info/SCALE_INFO_SUCCESS',
  SET_IS_VERSION_MISMATCH_SHOWN = '@@scale-info/SET_IS_VERSION_MISMATCH_SHOWN',
  SITE_INFO_SUCCESS = '@@scale-info/SITE_INFO_SUCCESS',
  STORE_VERSION_MISMATCH_RELOAD_TIME = '@@scale-info/STORE_VERSION_MISMATCH_RELOAD_TIME',
  MARK_SITE_INFO_SHOWN = '@scale-info/MARK_SITE_INFO_SHOWN',
}

export interface OrganizationEnabledFeatures {
  readonly bridgeSplitLoads: boolean;
}

export interface BridgeEnabledFeatures {
  readonly bridgePrimaryContainerTare: boolean;
  readonly bridgeSecondaryContainerTareDeduction: boolean;
  readonly bridgeIsMasterDataExternalIdVisible: boolean;
  readonly bridgeIsMasterDataLocalityVisible: boolean;
  readonly bridgeInspector: boolean;
  readonly bridgePhotosAsDriver: boolean;
}

export interface ScaleInfoState {
  readonly siteInfoHashes: { [key: string]: SiteInfoHashes };
}

export interface CurrentScaleInfoState {
  readonly domainInfo: DomainInfo;
  readonly enabledFeatures: BridgeEnabledFeatures;
  readonly isDummyScale: boolean;
  readonly isEnabledFeaturesLoaded: boolean;
  readonly isVersionMismatchShown: boolean;
  readonly organizationEnabledFeatures: OrganizationEnabledFeatures;
  readonly scaleKey: string;
  readonly scaleName: string;
  readonly siteInfo: SiteInfo;
  readonly versionMismatchReloadTime: number;
}

export interface SiteInfoHashes {
  readonly locationUrlHash: string;
  readonly instructionsHash: string;
  shownLocationUrlHash: string;
  shownInstructionHash: string;
}

export enum ShownSiteInfoType {
  INSTRUCTIONS = 'INSTRUCTIONS',
  LOCATION = 'LOCATION',
}

export interface ScaleInfo {
  readonly enabledFeatures: BridgeEnabledFeatures;
  readonly scaleKey: string;
  readonly scaleName: string;
  readonly swVersion: string;
  readonly swVersionHash: string;
}

export interface SiteInfo {
  readonly instructions?: string;
  readonly isShowingInstructionsToDriverForced?: boolean;
  readonly locationUrl?: string;
  readonly phoneNumber?: string;
  readonly showInstructionsAsUrl?: boolean;
}

export interface MasterDataCombo {
  readonly schema?: Record<string, unknown>;
  readonly schemaKey: string;
  readonly schemaVersion: string;
}

export interface DomainInfo {
  readonly domainConfiguration: Record<string, unknown>;
  readonly masterDataCombo: MasterDataCombo;
  readonly receiptSpec: ReceiptSpec;
}

export interface ReceiptSpec {
  readonly docType: string;
}
