import { Reducer } from 'redux';
import { SettingsActionTypes, SettingsState } from './types';

export const initialState: SettingsState = {
  userSettings: {},
};

const reducer: Reducer<SettingsState> = (state = initialState, action) => {
  switch (action.type) {
    case SettingsActionTypes.SAVE_USER_SETTINGS:
      const userSettings = { ...state.userSettings };
      userSettings[action.payload.userKey] = action.payload.userSettings;
      return {
        ...state,
        userSettings,
      };
    default: {
      return state;
    }
  }
};

export { reducer as settingsReducer };
