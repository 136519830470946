export enum RoutePaths {
  DEEP_LINK = '/link',
  LOGIN = '/login',
  VEHICLES = '/vehicles',
  ORDERS = '/orders',
  WEIGHING = '/weighing',
  RECEIPT = '/receipt',
  RECEIPT_HISTORY = '/receipt-history',
  PHOTO = '/photo',
  MEAS_DEVICES = '/meas-devices',
  EDIT_DEDUCTIONS = '/edit-deductions',
  EDIT_ORDER = '/edit-order',
  DASHBOARD = '/operator/dashboard',
  OPERATOR_GENERIC_CONTAINERS = '/operator/generic-containers',
  OPERATOR_JOBS = '/operator/jobs',
  OPERATOR_ORDER = '/operator/order',
  OPERATOR_ORDERS = '/operator/orders',
  SITES = '/sites',
}
