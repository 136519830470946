import React, { useState } from 'react';

import { makeStyles } from '@mui/styles';
import { Card, CardContent, CardMedia, Grid, IconButton, TextField } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

import { CacheImage, CacheImageType } from 'store/images/types';

import { translate } from 'utils/translate';
import ConfirmDialog from '../ConfirmDialog';
import LoadingIndicator, { LoadingIndicatorPositioning } from '../LoadingIndicator';

const useStyles = makeStyles({
  cardHeader: {
    fontWeight: 500,
  },
  cardContent: {
    padding: '0',
    paddingBottom: '0 !important',
  },
  deleteIconContainer: {
    position: 'absolute',
    top: '5px',
    right: 0,
  },
  deleteIcon: {
    opacity: '0.75',
    color: '#FFFFFF',
  },
  imageContainer: {
    position: 'relative',
    minHeight: '200px',
  },
  imageCard: {
    position: 'relative',
    marginLeft: '10px',
    marginRight: '10px',
    minWidth: '200px',
    minHeight: '200px',
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  descriptionField: {
    marginLeft: '10px',
    marginRight: '10px',
    width: 'calc(100% - 20px)',
  },
});

interface PhotoCardContentProps {
  cacheImage: CacheImage;
  descriptionBlurred: (key: string, description: string) => void;
  descriptionChanged: (key: string, description: string) => void;
  confirmDeleteImage: (image: CacheImage) => void;
}

const PhotoCardContent = (props: PhotoCardContentProps) => {
  const classes = useStyles();

  return (
    <CardContent className={classes.cardContent}>
      <IconButton
        className={classes.deleteIconContainer}
        color="inherit"
        disabled={props.cacheImage.type === CacheImageType.UPLOAD}
        aria-label={translate('images.removeImage')}
        edge="start"
        onClick={() => props.confirmDeleteImage(props.cacheImage)}
      >
        <DeleteIcon className={classes.deleteIcon} />
      </IconButton>
      <CardMedia
        component="img"
        image={props.cacheImage.data}
        alt={props.cacheImage.meta.description}
      />
      <TextField
        variant="standard"
        className={classes.descriptionField}
        disabled={props.cacheImage.type === CacheImageType.UPLOAD}
        defaultValue={props.cacheImage.meta.description}
        onBlur={(e) => props.descriptionBlurred(props.cacheImage.attachmentKey, e.target.value)}
        onChange={(e) => props.descriptionChanged(props.cacheImage.attachmentKey, e.target.value)}
        placeholder={translate('images.imageDescription')}
        InputProps={{ disableUnderline: true }}
      />
    </CardContent>
  );
};

interface AllProps {
  images: CacheImage[];
  removeImage: (image: CacheImage) => void;
  setImageDescription: (attachmentKey: string, description: string, useForce: boolean) => void;
}

const Photos = (props: AllProps) => {
  const classes = useStyles();

  const confirmDeleteImage = (image: CacheImage) => {
    setImageToBeDeleted(image);
    setIsDeleteImageDialogOpen(true);
  };

  const [isDeleteImageDialogOpen, setIsDeleteImageDialogOpen] = useState(false);
  const [imageToBeDeleted, setImageToBeDeleted] = useState<CacheImage | undefined>(undefined);

  const descriptionBlurred = (key: string, description: string) => {
    props.setImageDescription(key, description, true);
  };

  const descriptionChanged = (key: string, description: string) => {
    props.setImageDescription(key, description, false);
  };

  const isNotTypeDownload = (type: CacheImageType) => {
    return type !== CacheImageType.DOWNLOAD && type !== CacheImageType.WAIT_FOR_DOWNLOAD;
  };

  return (
    <div>
      <ConfirmDialog
        title={translate('images.imageDelete.title')}
        question={translate('images.imageDelete.question')}
        visible={isDeleteImageDialogOpen}
        onConfirmed={() => {
          if (imageToBeDeleted) {
            props.removeImage(imageToBeDeleted);
          }
          setIsDeleteImageDialogOpen(false);
        }}
        onNotConfirmed={() => {
          setIsDeleteImageDialogOpen(false);
          setImageToBeDeleted(undefined);
        }}
      />
      <Grid container spacing={2} direction="column">
        {props.images.map((cacheImage) => (
          <Grid item key={`${cacheImage.localId}_${cacheImage.type}`}>
            <Card className={classes.imageCard}>
              {isNotTypeDownload(cacheImage.type) && (
                <PhotoCardContent
                  cacheImage={cacheImage}
                  descriptionBlurred={descriptionBlurred}
                  descriptionChanged={descriptionChanged}
                  confirmDeleteImage={confirmDeleteImage}
                />
              )}
              {cacheImage.type !== CacheImageType.STORED && (
                <CardContent className={classes.image}>
                  <LoadingIndicator
                    isLoading={true}
                    positioning={LoadingIndicatorPositioning.ABSOLUTE}
                  />
                </CardContent>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Photos;
