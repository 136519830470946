import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';

import { connect, localeConnect } from 'localeConnect';

import theme, {
  darkerGreyColor,
  fadedGreyColor,
  inspectionStatusColors,
  separatorFadedColor,
} from 'theme';
import { ApplicationState } from 'store';
import { ComponentGroup, InProgressOrder, Order, WeighingProcess } from 'store/orders/types';
import { findTypesAndTitles, SchemaEntry } from 'store/scale-info/utils';
import {
  getTranslatedAndSortedSchemaEntries,
  getTranslatedTypeTitle,
} from 'store/master-data/utils';
import {
  getCurrentlySelectedMasterData,
  getNormalWeighingMainComponents,
} from 'store/orders/utils';
import { InspectionStatus, JobDirection } from 'store/jobs/types';
import { BridgeEnabledFeatures, OrganizationEnabledFeatures } from 'store/scale-info/types';
import { UserDetails } from 'store/user/types';
import { createSplitLoadModel } from 'models/splitload.model';
import {
  isAnyNetNegative,
  isNetWeightVisible,
  NetContainer,
  toNormalVehicleWeighingModels,
} from 'models/vehicleweighing.model';
import { jobsTrucksAndTrailers } from 'store/jobs/utils';
import { ContainerType, DocType } from 'store/common/types';
import MasterDataExternalIdVisibleFeature from 'components/MasterDataExternalIdVisibleFeature';
import MasterDataLocalityVisibleFeature from 'components/MasterDataLocalityVisibleFeature';
import DeductionInfo from 'components/DeductionInfo';
import { getMultipartRounds, roundKgs, roundLoadType, roundMaterial } from 'models/multipart.model';
import SecondaryContainerTareFeature from 'components/SecondaryContainerTareFeature';
import { formatMass } from 'components/utils';
import { FormatUnit } from 'store/weighing/types';
import { translate } from 'utils/translate';

const useStyles = makeStyles({
  cardContainer: {
    '& .MuiCardContent-root:last-child': {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  cardHeader: {
    // eslint-disable-next-line
    padding: '10px',
    '& .MuiCardHeader-action': {
      alignSelf: 'center',
      margin: 0,
    },
  },
  listItemContainer: {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: '10px',
  },
  detailsList: {
    // eslint-disable-next-line
    width: '100%',
    // eslint-disable-next-line
    paddingBottom: '10px',
    // eslint-disable-next-line
    paddingTop: 0,
    '& .MuiTypography-body1': {
      fontSize: '0.75rem',
      color: fadedGreyColor,
      textTransform: 'uppercase',
    },
    '& .MuiTypography-body2': {
      fontSize: '1rem',
      color: theme.palette.common.black,
    },
  },
  detailsListItem: {
    // eslint-disable-next-line
    paddingBottom: '5px',
    // eslint-disable-next-line
    paddingLeft: 0,
    // eslint-disable-next-line
    paddingRight: 0,
    // eslint-disable-next-line
    paddingTop: 0,
    // eslint-disable-next-line
    overflowWrap: 'break-word',
    // eslint-disable-next-line
    wordBreak: 'normal',
    '& .MuiListItemText-multiline': {
      margin: 0,
    },
  },
  cardHeaderItem: {
    border: '1px solid black',
    borderRadius: '5px',
    justifyContent: 'center',
    marginBottom: '5px',
    marginTop: '5px',
    marginRight: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    textAlign: 'center',
    letterSpacing: '0.25px',
    color: darkerGreyColor,
  },
  vehicleHeader: {
    width: 'fit-content',
  },
  trailerHeader: {
    width: '100px',
  },
  cardTitle: {
    cursor: 'pointer',
  },
  inspectionApproved: {
    borderLeft: `5px solid ${inspectionStatusColors.ok}`,
  },
  inspectionNeedsFixing: {
    borderLeft: `5px solid ${inspectionStatusColors.fail}`,
  },
  masterDataDetailsLabel: {
    fontSize: '0.75rem',
    color: fadedGreyColor,
    display: 'inline-block',
    marginRight: '10px',
  },
  masterDataDetailsValue: {
    fontSize: '0.75rem',
    color: theme.palette.common.black,
    overflowWrap: 'break-word',
    wordBreak: 'normal',
  },
  splitSeparator: {
    borderBottom: `1px solid ${separatorFadedColor}`,
  },
});

interface ParameterProps {
  inProgressOrder: InProgressOrder;
  onClick: () => void;
  order: Order;
}

interface PropsFromState {
  enabledFeatures: BridgeEnabledFeatures;
  organizationEnabledFeatures: OrganizationEnabledFeatures;
  scaleKey: string;
  schema?: Record<string, unknown>;
  userDetails: UserDetails | undefined;
}

interface PropsFromDispatch {}

export type AllProps = ParameterProps & PropsFromState & PropsFromDispatch;

const mapStateToProps = (state: ApplicationState) => ({
  enabledFeatures: state.currentScaleInfo.enabledFeatures,
  organizationEnabledFeatures: state.currentScaleInfo.organizationEnabledFeatures,
  scaleKey: state.currentScaleInfo.scaleKey,
  schema: state.currentScaleInfo.domainInfo.masterDataCombo.schema,
  userDetails: state.user.user.userData,
});

const mapDispatchToProps = {};

const InspectorOrderCollapsableItem = (props: AllProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const order = props.order;
  const vehicleName = jobsTrucksAndTrailers(props.inProgressOrder?.weighingJobs)?.find(
    (container) => container.containerType === ContainerType.TRUCK,
  )?.name;

  const inspectionStatus = props.inProgressOrder?.weighingJobs[0].inspection?.status;
  const isMultipartWeighing = props.inProgressOrder.process === WeighingProcess.MULTIPART;
  function getVehicleWeighingModels() {
    if (isMultipartWeighing) return undefined;
    return toNormalVehicleWeighingModels(
      props.inProgressOrder?.weighingJobs,
      [],
      order,
      props.enabledFeatures,
      props.inProgressOrder.splitLoad,
    );
  }
  const truckModelIndex = 0;
  const trailerModelIndex = 1;
  const models = getVehicleWeighingModels();
  const truckNetContainer = models?.net?.[truckModelIndex];
  const trailerNetContainer = models?.net?.[trailerModelIndex];

  function getInspectionStatusClass() {
    if (models && isAnyNetNegative(models)) return classes.inspectionNeedsFixing;
    return inspectionStatus === InspectionStatus.INSPECTED ? classes.inspectionApproved : '';
  }

  const inspectionStatusClass = getInspectionStatusClass();
  const jobDirection = getNormalWeighingMainComponents(order).truck.jobDirection;

  const componentSelectedItem = (
    entry: SchemaEntry,
    componentId: string,
    netContainer: NetContainer | undefined,
  ) => {
    const item = getCurrentSelectedItemData(entry, componentId);
    if (!item) {
      return null;
    }

    // NOTE(lindenlas,20220908) We don't want to show material with master data if net weight is visible
    // because material information is shown with weight
    if (
      (isMultipartWeighing || isNetWeightVisible(netContainer)) &&
      item.docType === DocType.MATERIAL
    ) {
      return null;
    }

    const currentItemExternalId = item.externalId;
    const currentItemLocality = item.card?.adr?.locality;
    return (
      <ListItem key={entry.typeName} className={classes.detailsListItem}>
        <ListItemText primary={getTranslatedTypeTitle(entry)} secondary={item.name} />
        {masterDataDetailsComponent(currentItemExternalId, currentItemLocality)}
      </ListItem>
    );
  };

  const multipartWeighingRounds = (inProgressOrder: InProgressOrder) => {
    const jobs = [...inProgressOrder.weighingJobs].reverse();
    const rounds = getMultipartRounds(jobs, inProgressOrder.order, props.enabledFeatures);

    return rounds.map((round) => {
      if (!round.netKgs && round.roundNumber > 1) return null;
      return (
        <List disablePadding key={round.key}>
          <Typography variant={'subtitle1'}>
            {translate('orders.multipartWeighingRoundTitle', { round: round.roundNumber })}
          </Typography>
          {round.roundNumber <= 1 ? (
            <ListItem className={classes.detailsListItem}>
              <ListItemText
                primary={roundLoadType(round)}
                secondary={roundKgs(round, FormatUnit.WITH_UNIT)}
              />
            </ListItem>
          ) : (
            <div>
              {translatedAndSortedSchemaEntries.map((entry: SchemaEntry) => {
                return componentSelectedItem(entry, round.componentId, undefined);
              })}
              <ListItem className={classes.detailsListItem}>
                <ListItemText
                  primary={translate('masterData.MATERIAL_TITLE')}
                  secondary={`${roundMaterial(round)} ${roundKgs(round, FormatUnit.WITH_UNIT)}`}
                />
              </ListItem>
              {!!round.comment && (
                <ListItem className={classes.detailsListItem}>
                  <ListItemText
                    primary={translate('orderDetails.comment')}
                    secondary={round.comment}
                  />
                </ListItem>
              )}
              <SecondaryContainerTareFeature
                componentId={round.componentId}
                order={inProgressOrder.order}
              >
                <ListItem className={classes.detailsListItem}>
                  <ListItemText
                    primary={translate('weighing.secondaryContainerDeduction')}
                    secondary={`${round.secondaryContainerName}
                  (${translate('masterData.CONTAINER_TITLE').toLowerCase()})
                  ${formatMass(round.secondaryContainerTareKgs)}`}
                  />
                </ListItem>
              </SecondaryContainerTareFeature>
            </div>
          )}
        </List>
      );
    });
  };

  const handleExpandToggle = () => {
    setExpanded(!expanded);
  };

  const getCurrentSelectedItemData = (schemaEntry: SchemaEntry, componentId: string) => {
    if (!props.order.linkedData || props.order.linkedData.length === 0) return undefined;

    const component = props.order.components.find((c) => c.id === componentId);
    if (!component || !component.dataLinks) return undefined;

    const linked = getCurrentlySelectedMasterData(
      props.order.linkedData,
      component.dataLinks,
      schemaEntry.typeName,
      schemaEntry.subtype,
    );
    return linked;
  };

  const schemaEntries = props.schema ? findTypesAndTitles(props.schema) : [];
  const translatedAndSortedSchemaEntries = getTranslatedAndSortedSchemaEntries(schemaEntries);

  const hasTrailer = (function () {
    if (props.inProgressOrder.weighingJobs.length < 1) {
      return false;
    }
    const job = props.inProgressOrder.weighingJobs[0];
    return job.containers[0].containerType === ContainerType.TRUCK_TRAILER;
  })();

  const containerDetailsComponent = (netContainer: NetContainer | undefined) => {
    if (!netContainer) return;
    const componentGroup =
      netContainer.containerType === ContainerType.TRUCK
        ? ComponentGroup.TRUCK
        : ComponentGroup.TRAILER;

    const splitLoadModel = createSplitLoadModel({
      order: props.order,
      componentGroup,
      isOrderTemporaryCopy: props.inProgressOrder.isOrderTemporaryCopy,
      splitLoad: props.inProgressOrder.splitLoad,
      organizationEnabledFeatures: props.organizationEnabledFeatures,
      scaleKey: props.scaleKey,
      userDetails: props.userDetails,
    });

    const componentHasMasterDataSelected = schemaEntries.some((entry) =>
      splitLoadModel.splits.some(
        (split) => getCurrentSelectedItemData(entry, split.component.id)?.name,
      ),
    );

    if (!netContainer.manualDeductions?.length && !componentHasMasterDataSelected) return;

    const containerTitle = () => {
      if (netContainer.containerType === ContainerType.TRUCK) {
        return translate('orderDetails.truckMasterData');
      } else if (netContainer.containerType === ContainerType.TRAILER) {
        return translate('orderDetails.trailerMasterData');
      }
    };

    function isLastSplit(index: number) {
      return index === splitLoadModel.splits.length - 1;
    }

    return (
      <div>
        <div>
          <Typography variant={'subtitle1'}>{containerTitle()}</Typography>
          <div>
            {splitLoadModel.splits.map((split, index) => (
              <div
                key={split.component.id}
                className={isLastSplit(index) ? '' : classes.splitSeparator}
              >
                {translatedAndSortedSchemaEntries.map((entry: SchemaEntry) => {
                  return componentSelectedItem(entry, split.component.id, netContainer);
                })}
              </div>
            ))}
          </div>
        </div>
        {netContainer && (
          <div>
            <DeductionInfo
              net={netContainer}
              deductionClassName={''}
              order={props.order}
              enabledFeatures={props.enabledFeatures}
              splitLoadModel={splitLoadModel}
            />
          </div>
        )}
      </div>
    );
  };

  const masterDataDetailsComponent = (externalId: string, locality: string) => {
    const hasAdditionalInformationToShow = !!externalId || !!locality;
    const additionalInfoListItem = (title: string, value: string) => {
      return (
        <li>
          <span className={classes.masterDataDetailsLabel}>{title}&nbsp;</span>
          <span className={classes.masterDataDetailsValue}>{value}</span>
        </li>
      );
    };
    return (
      hasAdditionalInformationToShow && (
        <ul>
          {!!externalId && (
            <MasterDataExternalIdVisibleFeature>
              {additionalInfoListItem(translate('masterData.EXTERNAL_ID_TITLE'), externalId)}
            </MasterDataExternalIdVisibleFeature>
          )}
          {!!locality && (
            <MasterDataLocalityVisibleFeature>
              {additionalInfoListItem(translate('masterData.LOCALITY_TITLE'), locality)}
            </MasterDataLocalityVisibleFeature>
          )}
        </ul>
      )
    );
  };

  return (
    <ListItem className={classes.listItemContainer}>
      <Card className={classes.cardContainer}>
        <CardHeader
          title={
            <Grid container onClick={props.onClick} className={classes.cardTitle}>
              <Grid item className={`${classes.cardHeaderItem} ${classes.vehicleHeader}`}>
                <Typography variant="h6">{vehicleName}</Typography>
              </Grid>
              {hasTrailer && (
                <Grid item className={`${classes.cardHeaderItem} ${classes.trailerHeader}`}>
                  <Typography variant="h6">{'-'}</Typography>
                </Grid>
              )}
            </Grid>
          }
          className={`${classes.cardHeader} ${inspectionStatusClass}`}
          action={
            <Button onClick={handleExpandToggle} color="primary">
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>
          }
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent className={`${inspectionStatusClass}`}>
            <Grid container>
              <List className={classes.detailsList}>
                <ListItem className={classes.detailsListItem}>
                  <ListItemText
                    primary={translate('operatorOrder.orderDetails.externalId')}
                    secondary={order.externalId}
                  />
                </ListItem>
                {!!jobDirection && jobDirection !== JobDirection.UNDEFINED && (
                  <ListItem className={classes.detailsListItem}>
                    <ListItemText
                      primary={translate('jobDirection.title')}
                      secondary={translate(
                        `jobDirection.values.JOB_DIRECTION_ENUM_${jobDirection}`,
                      )}
                    />
                  </ListItem>
                )}
                {isMultipartWeighing ? (
                  <div>{multipartWeighingRounds(props.inProgressOrder)}</div>
                ) : (
                  <div>
                    {containerDetailsComponent(truckNetContainer)}
                    {hasTrailer && containerDetailsComponent(trailerNetContainer)}
                  </div>
                )}
              </List>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </ListItem>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(InspectorOrderCollapsableItem);
