import React from 'react';

import { makeStyles } from '@mui/styles';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { KeyItem } from '../store/common/types';

interface AllProps {
  handleChange: (key: string) => void;
  items: KeyItem[];
  labelCallback: (item: KeyItem) => any;
  selectedKey: string;
}

const useStyles = makeStyles({
  itemGroup: {
    // eslint-disable-next-line
    width: '100%',
    '& .MuiFormControlLabel-label': {
      width: '100%',
      textAlign: 'left',
    },
    '& .MuiFormControlLabel-root': {
      borderBottom: 'solid 1px #e0e0e0',
    },
  },
});

export function KeyItemRadioForm(props: AllProps) {
  const classes = useStyles();
  return (
    <FormControl variant="standard" component="fieldset" className={classes.itemGroup}>
      <RadioGroup
        aria-label="data"
        value={props.selectedKey}
        onChange={(e: any) => props.handleChange(e.target.value)}
        className={classes.itemGroup}
      >
        {props.items.map((item) => (
          <FormControlLabel
            key={item.key}
            value={item.key}
            control={<Radio color="primary" />}
            label={props.labelCallback(item)}
            labelPlacement="start"
            checked={item.key === props.selectedKey}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default KeyItemRadioForm;
