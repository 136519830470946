import React from 'react';
import { FormControlLabel, FormGroup, Grid, Paper, Switch, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowForwardIos } from '@mui/icons-material';

import { translate } from 'utils/translate';
import { Vehicle } from 'store/vehicles/types';
import RegisterNumber from 'components/RegisterNumber';

const useStyles = makeStyles({
  useTrailerForm: {
    // eslint-disable-next-line
    marginTop: '20px',
    '& .MuiFormControlLabel-root': {
      justifyContent: 'space-between',
      marginLeft: '0px',
      width: '100%',
    },
  },
  containerTypeLabel: {
    color: '#868686',
  },
  containerPaper: {
    padding: '20px',
  },
});

export interface ParameterProps {
  isTrailerUsed: boolean;
  selectedVehicle: Vehicle | undefined;
  showSelection: () => void;
  setIsTrailerUsed: (isUsed: boolean) => void;
}

const VehicleSelection = (props: ParameterProps) => {
  const classes = useStyles();

  const setIsTrailerUsed = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setIsTrailerUsed(event.target.checked);
  };

  return (
    <div>
      <Paper square={true} elevation={1} className={classes.containerPaper}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography className={classes.containerTypeLabel} variant="h5">
              {translate('vehicles.subtitle')}
            </Typography>
          </Grid>
          <Grid item>
            <RegisterNumber
              text={props.selectedVehicle?.name || translate('vehicles.notSelected')}
              onClick={props.showSelection}
            />
          </Grid>
          <Grid item>
            <ArrowForwardIos onClick={props.showSelection} />
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.useTrailerForm} elevation={0}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch checked={props.isTrailerUsed} onChange={setIsTrailerUsed} color="primary" />
            }
            label={
              <Typography variant="h6" color="textPrimary">
                {translate('vehicles.useTrailer')}
              </Typography>
            }
            labelPlacement="start"
          />
        </FormGroup>
      </Paper>
    </div>
  );
};

export default VehicleSelection;
