import moment from 'moment';
import UAParser from 'ua-parser-js';
import { htmlToText } from 'html-to-text';
import { formatReceipt } from '../store/utils';
import { Job } from '../store/jobs/types';
import { DomainInfo } from '../store/scale-info/types';
import { translate } from './translate';

export const formatReceiptCount = (receiptNumber: number, receiptTotalCount: number) => {
  return `${receiptNumber}/${receiptTotalCount}`;
};

export const formatReceiptNumber = (receiptNumber: number | undefined) => {
  if (!receiptNumber) return '-';
  return `#${receiptNumber}`;
};

export const formatReceiptFilename = (receiptNumber: number | undefined) => {
  const startOfFileName = translate('receiptUtils.fileName');
  if (!receiptNumber) {
    return `${startOfFileName}-${moment().format('YYYY-MM-DDTHHmmssZZ')}`;
  }
  return `${startOfFileName}${receiptNumber.toString()}`;
};

export const isShareSupported = (): boolean => {
  return !!navigator.share;
};

const isIosBrowserBlocklisted = (blocklist: string[]): boolean => {
  const iOSName = 'iOS';
  const parsedUserAgent = UAParser(navigator.userAgent);
  return (
    blocklist.some((blocklisted) => parsedUserAgent.browser.name?.includes(blocklisted)) &&
    parsedUserAgent.os.name === iOSName
  );
};

export const isPdfDownloadSupported = (): boolean => {
  const pdfUnsupportedBrowsersIos = ['DuckDuckGo', 'Firefox', 'Safari'];
  const isPdfDownloadSupported = !isIosBrowserBlocklisted(pdfUnsupportedBrowsersIos);
  return isPdfDownloadSupported;
};

const generateHtmlDocument = (job: Job, domainInfo: DomainInfo) => {
  const css = 'body { font-family: RobotoMono,courier; }';
  const receiptHtml = document.implementation.createHTMLDocument(translate('receiptUtils.title'));
  const receiptStyle = document.createElement('style');
  receiptStyle.appendChild(document.createTextNode(css));
  receiptHtml.head.appendChild(receiptStyle);
  const metaTag = document.createElement('meta');
  metaTag.setAttribute('charset', 'UTF-8');
  receiptHtml.head.append(metaTag);
  const receiptElement = receiptHtml.createElement('div');
  receiptElement.id = 'receipttext';
  receiptElement.innerHTML = formatReceipt(job, domainInfo);
  receiptHtml.body.append(receiptElement);
  return receiptHtml;
};

export const shareReceipt = (job: Job, domainInfo: DomainInfo) => {
  if (!navigator.share) {
    console.warn(`Current browser doesn't support sharing, can't share job`, job);
    return;
  }

  interface ShareData {
    title: string;
    text: string;
    files?: File[];
  }

  const shareData: ShareData = {
    title: translate('receiptUtils.title') + formatReceiptNumber(job.receiptNumber),
    text: htmlToText(formatReceipt(job, domainInfo)),
  };

  try {
    if (navigator.canShare) {
      const receiptHtml = generateHtmlDocument(job, domainInfo);
      const blob = new Blob([receiptHtml.documentElement.outerHTML], { type: 'text/html' });
      const file = new File([blob], `${formatReceiptFilename(job.receiptNumber)}.html`, {
        type: blob.type,
      });
      shareData.files = [file];
      if (!navigator.canShare(shareData)) {
        delete shareData.files;
      }
    }
    navigator.share(shareData);
  } catch (error) {
    console.log(error);
  }
};
