import { makeStyles } from '@mui/styles';

import { ContainerItem } from 'store/common/types';
import { Context, WeighingProcess } from 'store/orders/types';
import { BridgeEnabledFeatures } from 'store/scale-info/types';
import {
  contextToNormalVehicleWeighingModels,
  isAnyNetNegative,
} from 'models/vehicleweighing.model';

import { setInspectionStatus } from 'store/jobs/actions';
import CommonOrderDetails from '../CommonOrderDetails';
import InspectorInspectionStatus from './InspectorInspectionStatus';

const useStyles = makeStyles({
  detailsContainer: {
    paddingBottom: '16px',
  },
});

// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
interface AllProps {
  context: Context;
  editOrder: (multipartComponentId: string | undefined) => void;
  editDeductions: (container: ContainerItem) => void;
  enabledFeatures: BridgeEnabledFeatures;
  setImageDescription: (attachmentKey: string, description: string, useForce: boolean) => void;
  setInspectionStatus: typeof setInspectionStatus;
}

const InspectorOrderDetails = (props: AllProps) => {
  const classes = useStyles();
  if (!props.context.weighingJobs) return null;

  function getVehicleWeighingModels() {
    if (props.context.process !== WeighingProcess.NORMAL) return undefined;
    return contextToNormalVehicleWeighingModels(props.context, [], props.enabledFeatures);
  }
  const models = getVehicleWeighingModels();

  return (
    <div>
      <div className={classes.detailsContainer}>
        <InspectorInspectionStatus
          hasError={!!models && isAnyNetNegative(models)}
          weighingContext={props.context}
          setInspectionStatus={props.setInspectionStatus}
        />
        <CommonOrderDetails
          context={props.context}
          editDeductions={props.editDeductions}
          editOrder={props.editOrder}
          isEditingDeductionsSupported={true}
          isRouteShown={false}
        />
      </div>
    </div>
  );
};

export default InspectorOrderDetails;
