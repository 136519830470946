import React, { useEffect } from 'react';

import { Container } from '@mui/material';

import { defaultRoot } from 'theme';

import { scrollToTop } from 'components/utils';
import SimpleNavBar from 'components/SimpleNavBar';

import { narrowContainerStyle } from '../utils';

interface ParameterProps {
  children: NonNullable<React.ReactNode>;
  showSiteInfo: boolean;
  title: string | JSX.Element;
  onGoBack: (() => void) | undefined;
}

export type AllProps = ParameterProps;

const InspectorContainer = (props: AllProps) => {
  useEffect(() => {
    if (typeof props.title === 'string') {
      scrollToTop();
    }
  }, [props.title]);

  return (
    <div style={defaultRoot}>
      <SimpleNavBar
        roleSelectionVisible={true}
        showSiteInfo={props.showSiteInfo}
        title={props.title}
        onGoBack={props.onGoBack}
      />
      <div>
        <Container style={narrowContainerStyle()}>{props.children}</Container>
      </div>
    </div>
  );
};

export default InspectorContainer;
