import { action } from 'typesafe-actions';
import { DomainInfo, ScaleInfo, ScaleInfoActionTypes, SiteInfo, ShownSiteInfoType } from './types';

export const domainInfo = (data: DomainInfo) => action(ScaleInfoActionTypes.DOMAIN_INFO, data);
export const scaleInfoSuccess = (data: ScaleInfo) =>
  action(ScaleInfoActionTypes.SCALE_INFO_SUCCESS, data);
export const siteInfoSuccess = (scaleKey: string, siteInfo: SiteInfo) =>
  action(ScaleInfoActionTypes.SITE_INFO_SUCCESS, { scaleKey, siteInfo });

export const setIsVersionMismatchShown = (isShown: boolean) =>
  action(ScaleInfoActionTypes.SET_IS_VERSION_MISMATCH_SHOWN, isShown);
export const storeVersionMismatchReloadTime = (time: number) =>
  action(ScaleInfoActionTypes.STORE_VERSION_MISMATCH_RELOAD_TIME, time);

export const markSiteInfoShown = (scaleKey: string, shownSiteInfoType: ShownSiteInfoType) =>
  action(ScaleInfoActionTypes.MARK_SITE_INFO_SHOWN, { scaleKey, shownSiteInfoType });
