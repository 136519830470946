import { filterValidLoads } from 'store/utils';
import { ContainerType } from '../common/types';
import { Context, WeighingProcess } from '../orders/types';
import { MeasDeviceGroup } from './types';

// TODO(mikkogy,20201217) when this changes it would be a very good idea to unit
// test it.
export function getAcceptableGroupSizes(context: Context, scaleKey: string) {
  if (!context.weighingJobs || !context.weighingJobs[0]) return [];
  // NOTE(mikkogy,20210426) in multipart we can have future jobs that are not
  // currently active when starting or because of discarding. So we need to find
  // the latest relevant job. Applies to normal too althoug it is always the
  // first job.
  let weighingJob = context.weighingJobs[0];
  if (context.process === WeighingProcess.MULTIPART) {
    for (let i = context.weighingJobs.length - 1; i >= 0; i--) {
      if (
        context.weighingJobs[i].loads &&
        filterValidLoads(context.weighingJobs[i].loads).length > 0
      ) {
        weighingJob = context.weighingJobs[i];
        break;
      }
      if (i === 0) {
        weighingJob = context.weighingJobs[0];
        break;
      }
    }
  }
  const mainContainer = weighingJob.containers[0];
  const isWeighingTruckTrailer = mainContainer.containerType === ContainerType.TRUCK_TRAILER;
  const loads = filterValidLoads(weighingJob.loads);
  // TODO(mikkogy,20201217) being even does not really work for all future
  // cases. What we really need is 0 or half of the loads but the logic for half
  // of the loads is hard-coded in other places too so it's not worth it to make
  // this perfect now.
  const isValidLoadCountEven = loads.length % 2 === 0;
  const acceptableGroupSizes = isWeighingTruckTrailer && isValidLoadCountEven ? [1, 2] : [1];
  return acceptableGroupSizes;
}

export function containerToBeWeightedNext(
  isTrailerInUse: boolean,
  acceptableGroupSizes: number[],
  selectedMeasDeviceGroup: MeasDeviceGroup | undefined,
) {
  if (!selectedMeasDeviceGroup || !selectedMeasDeviceGroup.measDeviceKeys) return undefined;

  if (!isTrailerInUse) return ContainerType.TRUCK;

  const truckHasBeenWeighted =
    isTrailerInUse &&
    acceptableGroupSizes &&
    acceptableGroupSizes.length === 1 &&
    acceptableGroupSizes[0] === 1;

  if (!truckHasBeenWeighted && selectedMeasDeviceGroup.measDeviceKeys.length > 1)
    return ContainerType.TRUCK_TRAILER;

  if (truckHasBeenWeighted) {
    return ContainerType.TRAILER;
  } else {
    return ContainerType.TRUCK;
  }
}
