import { Reducer } from 'redux';
import { SupportedLanguage } from 'models/languages.model';
import { UiRoles, UiRoleActionTypes, UserState, UserActionTypes } from './types';

export const initialState: UserState = {
  isLocaleApplied: false,
  user: {
    isAuthed: false,
    domains: [],
  },
  errors: undefined,
  loading: false,
  selectedLocale: SupportedLanguage.en,
  selectedUiRole: UiRoles.OPERATOR,
};

const reducer: Reducer<UserState> = (state = initialState, action) => {
  switch (action.type) {
    case UserActionTypes.FETCH_REQUEST:
    case UserActionTypes.LOGIN_REQUEST:
      return { ...state, loading: true };
    case UserActionTypes.LOGOUT:
      return { ...state, user: { isAuthed: false, domains: [] } };
    case UserActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, user: { ...state.user, ...action.payload } };
    }
    case UserActionTypes.DESTROY_TOKEN: {
      return {
        ...state,
        user: {
          ...state.user,
          token: undefined,
        },
      };
    }
    case UserActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case UserActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    case UserActionTypes.TOKEN_RENEW_SUCCESS:
      const user = state.user;
      user.token = action.payload;
      return { ...state, user: { ...user } };
    case UiRoleActionTypes.ROLE_CHANGED:
      return { ...state, selectedUiRole: action.payload };
    case UserActionTypes.APPLIED_LOCALE: {
      return { ...state, isLocaleApplied: true };
    }
    case UserActionTypes.STORE_SELECTED_LOCALE:
      return { ...state, selectedLocale: action.payload };
    case UserActionTypes.STORE_USER_DOMAINS:
      return { ...state, user: { ...state.user, domains: action.payload } };
    default: {
      return state;
    }
  }
};

export { reducer as userReducer };
