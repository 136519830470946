import React from 'react';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  hint: {
    backgroundColor: 'rgba(0,0,0,0.54)',
    color: '#ffffff',
    fontSize: '13.1px',
    marginLeft: '10px',
    marginRight: '10px',
    paddingTop: '10px',
    paddingBottom: '10px',
    borderRadius: '4px',
  },
});

interface Props {
  text: string;
}

const OperatorWeighingHint = (props: Props) => {
  const classes = useStyles();
  return <div className={classes.hint}>{props.text}</div>;
};

export default OperatorWeighingHint;
