import { RoutePaths } from 'routes';

export interface CommonState {
  readonly websocketAddress: string;
  readonly connected: boolean;
  readonly isReceivingData: boolean;
  readonly lastEventTime: number;
  readonly toast: Toast;
}

export enum ToastType {
  SUCCESS = 'success',
  DEFAULT = 'default',
  ERROR = 'error',
}

export interface Toast {
  isOpen: boolean;
  messageKey: string;
  type: ToastType;
}

export interface ToastContext {
  [key: string]: RoutePaths[];
}

export enum LiveDataTypes {
  CONNECTION_REQUEST = '@@livedata/CONNECTION_REQUEST',
  CONNECTION_SUCCESS = '@@livedata/CONNECTION_SUCCESS',
  HELLO = '@@livedata/HELLO',
  INCOMING_EVENT = '@@livedata/INCOMING_EVENT',
  SEND_MESSAGE = '@@livedata/SEND_MESSAGE',
  CONNECTION_ERROR = '@@livedata/CONNECTION_ERROR',
  SET_IS_RECEIVING_DATA = '@@livedata/SET_IS_RECEIVING_DATA',

  DISCONNECT_REQUEST = '@@livedata/DISCONNECT_REQUEST',
  DISCONNECT_SUCCESS = '@@livedata/DISCONNECT_SUCCESS',

  DISCONNECT_SOCKET_TASKS = '@@livedata/DISCONNECT_SOCKET_TASKS',
  DISCONNECT_SOCKET_SAGA = '@@livedata/DISCONNECT_SOCKET_SAGA',
  UPDATE_LAST_EVENT_TIME = '@@livedata/UPDATE_LAST_EVENT_TIME',
}

export enum RemoteRequestTypes {
  ADD_SPLIT = 'ADD_SPLIT',
  BLOB_DATA = 'BLOB_DATA',
  BLOB_SUCCEEDED = 'BLOB_SUCCEEDED',
  COPY_TRUCK_MASTER_DATA_TO_TRAILER = 'COPY_TRUCK_MASTER_DATA_TO_TRAILER',
  CREATE_MASTER_DATA = 'CREATE_MASTER_DATA',
  CREATE_DOWNLOAD = 'CREATE_DOWNLOAD',
  CREATE_UPLOAD = 'CREATE_UPLOAD',
  DELETE_SPLIT = 'DELETE_SPLIT',
  DISABLE_SPLIT_LOAD = 'DISABLE_SPLIT_LOAD',
  DISCARD_CONTEXT_ORDER = 'DISCARD_CONTEXT_ORDER',
  ENABLE_SPLIT_LOAD = 'ENABLE_SPLIT_LOAD',
  HELLO = 'HELLO',
  LIST_IN_PROGRESS_ORDERS = 'LIST_IN_PROGRESS_ORDERS',
  LIST_MASTER_DATA = 'LIST_MASTER_DATA',
  LIST_MEAS_DEVICES = 'LIST_MEAS_DEVICES',
  LIST_ORDERS = 'LIST_ORDERS',
  LIST_WEIGHING_JOBS = 'LIST_WEIGHING_JOBS',
  LOAD_MASTER_DATA_BY_KEYS = 'LOAD_MASTER_DATA_BY_KEYS',
  LOAD_ORDER_INFO = 'LOAD_ORDER_INFO',
  CREATE_CONTEXT = 'CREATE_CONTEXT',
  DESTROY_CONTEXT = 'DESTROY_CONTEXT',
  DISCARD_PREVIOUS_LOAD = 'DISCARD_PREVIOUS_LOAD',
  DO_MANUAL_WEIGHING = 'DO_MANUAL_WEIGHING',
  DO_WEIGHING = 'DO_WEIGHING',
  FINISH_CONTEXT = 'FINISH_CONTEXT',
  FINISH_WITH_CONTEXT_TARE = 'FINISH_WITH_CONTEXT_TARE',
  GENERATE_RECEIPT = 'GENERATE_RECEIPT',
  ACTIVATE_CONTEXT = 'ACTIVATE_CONTEXT',
  INACTIVATE_CONTEXT = 'INACTIVATE_CONTEXT',
  REMOVE_ATTACHMENT = 'REMOVE_ATTACHMENT',
  REQUEST_BLOB_DATA = 'REQUEST_BLOB_DATA',
  SAVE_TARE_AND_FINISH = 'SAVE_TARE_AND_FINISH',
  SEARCH_MASTER_DATA = 'SEARCH_MASTER_DATA',
  SEARCH_ORDER = 'SEARCH_ORDER',
  SET_COMMENT = 'SET_COMMENT',
  SET_INSPECTION_STATUS = 'SET_INSPECTION_STATUS',
  SET_PROCESS = 'SET_PROCESS',
  SET_SPLIT_AMOUNT = 'SET_SPLIT_AMOUNT',
  SET_TRAILER = 'SET_TRAILER',
  UPDATE_ORDER_JOB_DIRECTION = 'UPDATE_ORDER_JOB_DIRECTION',
  SET_MANUAL_DEDUCTIONS = 'SET_MANUAL_DEDUCTIONS',
  UPDATE_ATTACHMENT_PROPERTIES = 'UPDATE_ATTACHMENT_PROPERTIES',
  UPDATE_MASTER_DATA = 'UPDATE_MASTER_DATA',
  UPDATE_ORDER_PROPERTIES = 'UPDATE_ORDER_PROPERTIES',
  UPDATE_ORDER_MASTER_DATA = 'UPDATE_ORDER_MASTER_DATA',
  UPDATE_TRAFFIC_CONTROL_GROUP_STATUS = 'UPDATE_TRAFFIC_CONTROL_GROUP_STATUS',
  UPDATE_TRAFFIC_CONTROL_GROUP_MODE = 'UPDATE_TRAFFIC_CONTROL_GROUP_MODE',
  UPDATE_ORDER_TYPABLE_MASTER_DATA = 'UPDATE_ORDER_TYPABLE_MASTER_DATA',
  ZERO = 'ZERO',
}

// NOTE(mikkogy,20190830) so far UI has needed a unique id for each request
// type, not individual requests.
export enum RemoteRequestIds {
  LIST_MASTER_DATA_ONE_PAGE = '1',
  LIST_MEAS_DEVICES = '2',
  LIST_WEIGHING_JOBS = '4',
  CREATE_CONTEXT = '5',
  DESTROY_CONTEXT = '6',
  DO_WEIGHING = '7',
  FINISH_CONTEXT = '8',
  ACTIVATE_CONTEXT = '9',
  INACTIVATE_CONTEXT = '10',
  ZERO = '11',
  DISCARD_PREVIOUS_LOAD = '12',
  UPDATE_ORDER_MASTER_DATA = '13',
  GENERATE_RECEIPT = '14',
  SET_COMMENT = '15',
  LIST_IN_PROGRESS_ORDERS = '16',
  CREATE_CONTEXT_FOR_EMPTY_ORDER = '17',
  DESTROY_CONTEXT_WITH_ID = '18',
  LOAD_ORDER_INFO = '19',
  LIST_ORDERS = '20',
  UPDATE_ORDER_PROPERTIES = '21',
  CREATE_MASTER_DATA = '22',
  CREATE_UPLOAD = '23',
  BLOB_DATA = '24',
  CREATE_DOWNLOAD = '25',
  REQUEST_BLOB_DATA = '26',
  BLOB_SUCCEEDED = '27',
  UPDATE_ATTACHMENT_PROPERTIES = '28',
  REMOVE_ATTACHMENT = '29',
  SET_MANUAL_DEDUCTIONS = '30',
  DO_MANUAL_WEIGHING = '31',
  DISCARD_CONTEXT_ORDER = '32',
  FINISH_WITH_CONTEXT_TARE = '33',
  SAVE_TARE_AND_FINISH = '34',
  UPDATE_MASTER_DATA = '35',
  UPDATE_ORDER_JOB_DIRECTION = '36',
  SET_INSPECTION_STATUS = '37',
  UPDATE_TRAFFIC_CONTROL_GROUP_STATUS = '38',
  UPDATE_TRAFFIC_CONTROL_GROUP_MODE = '39',
  UPDATE_ORDER_TYPABLE_MASTER_DATA = '40',
  SEARCH_MASTER_DATA = '41',
  LOAD_MASTER_DATA_BY_KEYS = '42',
  LOAD_MASTER_DATA_BY_KEYS_SELECTED_VEHICLE = '43',
  SEARCH_ORDER = '44',
  SET_PROCESS = '45',
  SET_TRAILER = '46',
  HELLO = '47',
  COPY_TRUCK_MASTER_DATA_TO_TRAILER = '48',
  ADD_SPLIT = '49',
  DELETE_SPLIT = '50',
  DISABLE_SPLIT_LOAD = '51',
  ENABLE_SPLIT_LOAD = '52',
  SET_SPLIT_AMOUNT = '53',
}

export enum RemoteReceiveTypes {
  ACK = 'ACK',
  NACK = 'NACK',
  ERROR = 'ERROR',
  DOMAIN_INFO = 'DOMAIN_INFO',
  SCALE_INFO = 'SCALE_INFO',
  SITE_INFO = 'SITE_INFO',
  STATUS = 'STATUS',
  CONTEXT_CREATED = 'CONTEXT_CREATED',
  CONTEXT_STATUS = 'CONTEXT_STATUS',
  FINISH_CONTEXT_SUCCEEDED = 'FINISH_CONTEXT_SUCCEEDED',
  ORDER_LIST = 'ORDER_LIST',
  ORDER_SEARCH_RESULTS = 'ORDER_SEARCH_RESULTS',
  WEIGHING_JOB_LIST = 'WEIGHING_JOB_LIST',
  MASTER_DATA_BY_KEYS = 'MASTER_DATA_BY_KEYS',
  MASTER_DATA_CREATED = 'MASTER_DATA_CREATED',
  MASTER_DATA_LIST = 'MASTER_DATA_LIST',
  MASTER_DATA_SEARCH_RESULTS = 'MASTER_DATA_SEARCH_RESULTS',
  MASTER_DATA_UPDATED = 'MASTER_DATA_UPDATED',
  MEAS_DEVICE_LIST = 'MEAS_DEVICE_LIST',
  RECEIPT = 'RECEIPT',
  IN_PROGRESS_ORDER_LIST = 'IN_PROGRESS_ORDER_LIST',
  ORDER_INFO = 'ORDER_INFO',
  DOWNLOAD_CREATED = 'DOWNLOAD_CREATED',
  UPLOAD_CREATED = 'UPLOAD_CREATED',
  BLOB_DATA = 'BLOB_DATA',
  BLOB_DATA_RECEIVED = 'BLOB_DATA_RECEIVED',
  BLOB_FAILED = 'BLOB_FAILED',
  BLOB_SUCCEEDED = 'BLOB_SUCCEEDED',
  WEIGHING_FAILED = 'WEIGHING_FAILED',
  TRAFFIC_CONTROL_GROUP_STATUS = 'TRAFFIC_CONTROL_GROUP_STATUS',
  TRUCK_DEFAULT_SELECTIONS_APPLIED_FROM_VEHICLE = 'TRUCK_DEFAULT_SELECTIONS_APPLIED_FROM_VEHICLE',
}

export enum DocType {
  ATTACHMENT = 'ATTACHMENT',
  CUSTOMER = 'CUSTOMER',
  CONTAINER = 'CONTAINER',
  EXTENSION = 'DATA_EXT',
  JOB = 'JOB',
  MATERIAL = 'MATERIAL',
  ORDER = 'ORDER',
  TRANSPORT_CO = 'TRANSPORT_CO',
  SCALE = 'SCALE',
}

export enum RemoteMasterDataType {
  CONTAINER = 'container',
  MATERIAL = 'material',
  CUSTOMER = 'customer',
}

export enum ContainerType {
  GENERIC = 'GENERIC',
  TRUCK_TRAILER = 'TRUCK_TRAILER',
  TRUCK = 'TRUCK',
  TRAILER = 'TRAILER',
}

// NOTE(mjohans,20200908) generic master data type is a simple
// string. Type is for improved readablity only.
export type GenericMasterDataType = string;

export type AllowedMasterDataTypes = RemoteMasterDataType | GenericMasterDataType;

export interface DetailedMasterDataType {
  type: AllowedMasterDataTypes;
  subtype?: string;
}

// Common typed JSON API response type.
export type ApiResponse = Record<string, any>;

// NOTE(mikkogy,20210114) basically a document but we lift requirement for
// docType as it is often not interesting in the UI and enables inserting empty
// option for selections etc.
export interface KeyItem {
  key: string;
}

export interface Document extends ApiResponse {
  docType: DocType;
  key: string;
}

export interface MasterDataItem extends Document {
  name: string;
}

export enum ContainerRole {
  SOURCE_CONTAINER = 'SOURCE_CONTAINER',
  DESTINATION_CONTAINER = 'DESTINATION_CONTAINER',
  DIRECTIONLESS_CONTAINER = 'DIRECTIONLESS_CONTAINER',
}

export interface ContainerTare {
  massKg: number;
  timestamp: number;
  unfitForJobs?: boolean;
}

export type ContainerItem = MasterDataItem & {
  containerType: ContainerType;
  containers?: ContainerItem[];
  roles?: ContainerRole[];
  tare?: ContainerTare;
};

// Top level Websocket response. Payload contains data based on msgType.
export type MasterResponse = ApiResponse & {
  msgType: RemoteReceiveTypes;
  respId: string;
  payload: any;
};

export interface MasterDataResponsePayload {
  masterData: MasterDataItem[];
  masterDataType: RemoteMasterDataType;
  pagination: ServerPagination;
}

export interface ContainerResponsePayload {
  masterData: MasterDataItem[];
  masterDataType: RemoteMasterDataType;
  pagination: ServerPagination;
}

export interface Pagination {
  pageNumber: number;
  pageSize: number;
}

export interface ServerPagination extends Pagination {
  readonly currentPageItemCount?: number;
  readonly itemCount: number;
}

export enum CommonActionTypes {
  NEW_TOAST = '@@common/NEW_TOAST',
  SHOW_TOAST = '@@common/SHOW_TOAST',
  HIDE_TOAST = '@@common/HIDE_TOAST',
  NAVIGATE_TO_RECEIPTS = '@@common/NAVIGATE_TO_RECEIPTS',
  NAVIGATE_BACK = '@@common/NAVIGATE_BACK',
}
