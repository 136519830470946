import { connect, localeConnect } from '../localeConnect';
import { ApplicationState } from '../store';
import { BridgeEnabledFeatures } from '../store/scale-info/types';

interface ParameterProps {
  children: React.ReactNode;
  isFeatureEnabled: (enabledFeatures: BridgeEnabledFeatures) => boolean;
}

interface PropsFromState {
  enabledFeatures: BridgeEnabledFeatures;
}

export type AllProps = PropsFromState & ParameterProps;

const mapStateToProps = (state: ApplicationState) => ({
  enabledFeatures: state.currentScaleInfo.enabledFeatures,
});

const mapDispatchToProps = {};

function MasterDataVisibleFeature(props: AllProps) {
  if (props.isFeatureEnabled(props.enabledFeatures)) {
    return <span>{props.children}</span>;
  }
  return null;
}

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(MasterDataVisibleFeature);
