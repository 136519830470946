import { combineReducers, Dispatch, Action, AnyAction } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { createMigrate, persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';

import { UserState } from './user/types';
import { userReducer } from './user/reducer';
import userSaga from './user/sagas';

import { masterDataMigrations } from './master-data/migrations';
import { MasterDataState } from './master-data/types';
import { masterDataReducer } from './master-data/reducer';
import masterDataSaga from './master-data/sagas';

import { VehiclesState } from './vehicles/types';
import { vehiclesReducer } from './vehicles/reducer';
import vehiclesSaga from './vehicles/sagas';

import { SitesState } from './sites/types';
import { sitesReducer } from './sites/reducer';
import sitesSaga from './sites/sagas';

import { CommonState } from './common/types';
import { commonReducer } from './common/reducer';
import websocketSaga from './common/sagas';

import { OrderState } from './orders/types';
import { ordersReducer } from './orders/reducer';
import ordersSaga from './orders/sagas';

import { WeighingState } from './weighing/types';
import { weighingReducer } from './weighing/reducer';
import weighingSaga from './weighing/sagas';
import { jobsReducer } from './jobs/reducer';
import jobsSaga from './jobs/sagas';
import { JobsState } from './jobs/types';

import { CurrentScaleInfoState, ScaleInfoState } from './scale-info/types';
import { currentScaleInfoReducer, scaleInfoReducer } from './scale-info/reducer';
import scaleInfoSaga from './scale-info/sagas';

import { SettingsState } from './settings/types';
import { settingsReducer } from './settings/reducer';

import { MeasDeviceState } from './meas-devices/types';
import { measDevicesReducer } from './meas-devices/reducer';
import measDevicesSaga from './meas-devices/sagas';

import { ImagesState } from './images/types';
import { imagesReducer } from './images/reducer';
import imagesSaga from './images/sagas';

import { DeepLinkState } from './deep-link/types';
import { deepLinkReducer } from './deep-link/reducer';
import deepLinkSaga from './deep-link/sagas';

export interface ApplicationState {
  user: UserState;
  masterData: MasterDataState;
  vehicles: VehiclesState;
  orders: OrderState;
  weighing: WeighingState;
  jobs: JobsState;
  router: RouterState;
  common: CommonState;
  measDevices: MeasDeviceState;
  scaleInfo: ScaleInfoState;
  currentScaleInfo: CurrentScaleInfoState;
  settings: SettingsState;
  sites: SitesState;
  images: ImagesState;
  deepLink: DeepLinkState;
}

export interface ConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>;
}

const commonPersistConfig = {
  key: 'common',
  storage: sessionStorage,
  whitelist: ['lastEventTime'],
};

const orderPersistConfig = {
  key: 'orders',
  storage: sessionStorage,
  whitelist: [
    'currentContextOrderKey',
    'defaultWeighingProcess',
    'editedDeductions',
    'inspectorOrderTab',
    'selectedOperatorOrder',
    'operatorJobFilter',
    'operatorOrderTab',
    'operatorQueryParams',
  ],
};

const userPersistConfig = {
  key: 'user',
  storage: localStorage,
  whitelist: ['selectedLocale', 'selectedUiRole', 'user'],
};

const masterDataPersistConfig = {
  key: 'masterData',
  storage: localStorage,
  // NOTE(mikkogy,20210712) for lastSelected in each type state. Unless
  // lastSelected is persisted it is very unlikely any actual user will see
  // history items.
  whitelist: ['dataTypes', 'maxSearchHistoryCount'],
  version: 3,
  migrate: createMigrate(masterDataMigrations, { debug: false }),
};

const vehiclePersistConfig = {
  key: 'vehicles',
  storage: sessionStorage,
  whitelist: ['selectedVehicle', 'trailerKey'],
};

const scaleInfoPersistConfig = {
  key: 'scaleInfo',
  storage: localStorage,
  whitelist: ['siteInfoHashes'],
};

const currentScaleInfoPersistConfig = {
  key: 'currentScaleInfo',
  storage: sessionStorage,
  whitelist: ['versionMismatchReloadTime'],
};

const sitePersistConfig = {
  key: 'sites',
  storage: sessionStorage,
  whitelist: ['selectedKey'],
};

const measDevicePersistConfig = {
  key: 'measDevices',
  storage: sessionStorage,
  whitelist: ['manualKgs', 'measDeviceGroupReversing', 'selectedGroupId', 'pendingMeasDeviceKey'],
};

const jobsPersistConfig = {
  key: 'jobs',
  storage: sessionStorage,
  whitelist: ['driverReceiptJobKey', 'queryParams', 'queryJobs'],
};

const imagesPersistConfig = {
  key: 'images',
  storage: localStorage,
  whitelist: ['orderImages', 'schemaVersion'],
};

const settingsPersistConfig = {
  key: 'settings',
  storage: localStorage,
  whitelist: ['userSettings'],
};

const deepLinkPersistConfig = {
  key: 'deepLink',
  storage: sessionStorage,
  whitelist: ['orderKey', 'returnLink'],
};

export const createRootReducer = (history: History) =>
  combineReducers({
    user: persistReducer(userPersistConfig, userReducer),
    masterData: persistReducer(masterDataPersistConfig, masterDataReducer),
    vehicles: persistReducer(vehiclePersistConfig, vehiclesReducer),
    sites: persistReducer(sitePersistConfig, sitesReducer),
    orders: persistReducer(orderPersistConfig, ordersReducer),
    weighing: weighingReducer,
    jobs: persistReducer(jobsPersistConfig, jobsReducer),
    router: connectRouter(history),
    common: persistReducer(commonPersistConfig, commonReducer),
    scaleInfo: persistReducer(scaleInfoPersistConfig, scaleInfoReducer),
    currentScaleInfo: persistReducer(currentScaleInfoPersistConfig, currentScaleInfoReducer),
    measDevices: persistReducer(measDevicePersistConfig, measDevicesReducer),
    images: persistReducer(imagesPersistConfig, imagesReducer),
    settings: persistReducer(settingsPersistConfig, settingsReducer),
    deepLink: persistReducer(deepLinkPersistConfig, deepLinkReducer),
  });

export function* rootSaga() {
  yield all([
    fork(userSaga),
    fork(masterDataSaga),
    fork(vehiclesSaga),
    fork(sitesSaga),
    fork(ordersSaga),
    fork(websocketSaga),
    fork(weighingSaga),
    fork(jobsSaga),
    fork(scaleInfoSaga),
    fork(measDevicesSaga),
    fork(imagesSaga),
    fork(deepLinkSaga),
  ]);
}
