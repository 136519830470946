import React from 'react';
import { flushSync } from 'react-dom';
import { Redirect } from 'react-router';
import { makeStyles } from '@mui/styles';

import { Button, FormControlLabel, FormGroup, Grid, Switch, Typography } from '@mui/material';

import { connect, localeConnect } from 'localeConnect';

import {
  createSplitLoadModelFromContext,
  createSplitLoadModelFromSingleComponent,
  Split,
  SplitLoadModel,
} from 'models/splitload.model';
import { OrderDetailsModel } from 'models/orderdetails.model';

import { ApplicationState } from 'store';

import { RemoteMasterDataType } from 'store/common/types';
import { OrganizationEnabledFeatures } from 'store/scale-info/types';
import {
  addSplit,
  clearEditedMasterDataComponentId,
  copyTruckToTrailer,
  deleteSplit,
  disableSplitLoad,
  editMasterData,
  enableSplitLoad,
  setSplitAmount,
} from 'store/orders/actions';
import { getContextMultipartJob } from 'store/orders/utils';
import { Component, ComponentGroup, Context } from 'store/orders/types';
import { UserDetails } from 'store/user/types';

import { RoutePaths } from 'routes';
import { translate } from 'utils/translate';

import DisableMultipleSharesDialog from 'components/DisableMultipleSharesDialog';

import theme from 'theme';

import ComponentMasterData from './ComponentMasterData';
import MultipartJobContainerKgs from './MultipartJobContainerKgs';
import OrderEditorSplit from './OrderEditorSplit';

const useStyles = makeStyles({
  addShare: {
    marginBottom: '20px',
    textAlign: 'center',
  },
  allSharesZero: {
    color: theme.palette.error.main,
  },
  containerData: {
    marginBottom: '20px',
  },
  partTitle: {
    fontSize: '25px',
    fontWeight: 600,
    wordBreak: 'break-all',
  },
  sharesSwitchContainer: {
    marginTop: '31px',
  },
  splitLoadModelContainer: {
    borderBottom: '1px solid #4d4d4f',
    paddingBottom: '20px',
    marginBottom: '37px',
  },
});

interface PropsFromState {
  editedMasterDataComponentId: string;
  multipartEditOrderComponentId: string;
  organizationEnabledFeatures: OrganizationEnabledFeatures;
  scaleKey: string;
  schema?: Record<string, unknown>;
  userDetails: UserDetails | undefined;
}

interface PropsFromDispatch {
  addSplit: typeof addSplit;
  clearEditedMasterDataComponentId: typeof clearEditedMasterDataComponentId;
  copyTruckToTrailer: typeof copyTruckToTrailer;
  deleteSplit: typeof deleteSplit;
  disableSplitLoad: typeof disableSplitLoad;
  editMasterData: typeof editMasterData;
  enableSplitLoad: typeof enableSplitLoad;
  setSplitAmount: typeof setSplitAmount;
}

const mapStateToProps = (state: ApplicationState) => ({
  editedMasterDataComponentId: state.orders.editedMasterDataComponentId,
  multipartEditOrderComponentId: state.orders.multipartEditOrderComponentId,
  organizationEnabledFeatures: state.currentScaleInfo.organizationEnabledFeatures,
  scaleKey: state.currentScaleInfo.scaleKey,
  schema: state.currentScaleInfo.domainInfo.masterDataCombo.schema,
  userDetails: state.user.user.userData,
});

const mapDispatchToProps = {
  addSplit,
  clearEditedMasterDataComponentId,
  copyTruckToTrailer,
  deleteSplit,
  disableSplitLoad,
  editMasterData,
  enableSplitLoad,
  setSplitAmount,
};

interface ParameterProps {
  currentContext: Context;
  orderDetailsModel: OrderDetailsModel;
}

type AllProps = ParameterProps & PropsFromDispatch & PropsFromState;

interface EditorSplitLoadModel extends SplitLoadModel {
  title: string;
  additionalElement: any;
  key: string;
}

function getSplitLoadModels(
  currentContext: Context,
  multipartEditOrderComponentId: string,
  isTrailerUsed: boolean,
  organizationEnabledFeatures: OrganizationEnabledFeatures,
  scaleKey: string,
  userDetails: UserDetails | undefined,
): EditorSplitLoadModel[] | undefined {
  const splitLoadModels: EditorSplitLoadModel[] = [];
  if (multipartEditOrderComponentId) {
    const componentFinder = (component: Component) =>
      component.id === multipartEditOrderComponentId;
    const component = currentContext.order.components.find(componentFinder);
    if (!component) {
      return undefined;
    }
    const job = getContextMultipartJob(currentContext, multipartEditOrderComponentId);
    if (!job) {
      return undefined;
    }
    const componentIndex = currentContext.order.components.findIndex(componentFinder) as number;
    const splitLoadModel = createSplitLoadModelFromSingleComponent(
      currentContext.order,
      component.id,
    );
    splitLoadModels.push({
      ...splitLoadModel,
      additionalElement: (
        <MultipartJobContainerKgs
          componentId={component.id}
          job={job}
          order={currentContext.order}
        />
      ),
      key: splitLoadModel.splits.map((split) => split.component.id).join('!'),
      title: translate('orderDetails.multipartRoundTitle', { round: componentIndex + 2 }),
    });
  } else {
    const splitLoadModel = createSplitLoadModelFromContext({
      context: currentContext,
      componentGroup: ComponentGroup.TRUCK,
      organizationEnabledFeatures,
      scaleKey,
      userDetails,
    });
    const splitsToKey = (splitLoadModel: SplitLoadModel): string =>
      splitLoadModel.splits.map((split) => split.component.id).join('!');
    splitLoadModels.push({
      ...splitLoadModel,
      additionalElement: null,
      key: splitsToKey(splitLoadModel),
      title: translate('orderDetails.truckMasterData'),
    });
    if (isTrailerUsed && currentContext.order.components.length > 1) {
      const splitLoadModel = createSplitLoadModelFromContext({
        context: currentContext,
        componentGroup: ComponentGroup.TRAILER,
        organizationEnabledFeatures,
        scaleKey,
        userDetails,
      });
      splitLoadModels.push({
        ...splitLoadModel,
        additionalElement: null,
        key: splitsToKey(splitLoadModel),
        title: translate('orderDetails.trailerMasterData'),
      });
    }
  }
  return splitLoadModels;
}

const OrderEditorSplits = (props: AllProps) => {
  const classes = useStyles();

  const [isDisableMultipleSharesDialogVisible, setIsDisableMultipleSharesDialogVisible] =
    React.useState(false);
  const [disableDialogComponentGroup, setDisableDialogComponentGroup] = React.useState(
    ComponentGroup.TRUCK,
  );

  const splitLoadModels = getSplitLoadModels(
    props.currentContext,
    props.multipartEditOrderComponentId,
    props.orderDetailsModel.isTrailerUsed,
    props.organizationEnabledFeatures,
    props.scaleKey,
    props.userDetails,
  );
  if (splitLoadModels === undefined) {
    return <Redirect to={RoutePaths.ORDERS} />;
  }

  function setMultipleSharesEnabled(componentGroup: ComponentGroup, isEnabled: boolean) {
    if (isEnabled) {
      props.enableSplitLoad(props.currentContext.contextId, componentGroup);
    } else {
      props.disableSplitLoad(props.currentContext.contextId, componentGroup);
    }
  }

  const multipleSharesChanged = (
    e: React.ChangeEvent<HTMLInputElement>,
    componentGroup: ComponentGroup,
  ) => {
    const isEnabled = e.target.checked;
    if (
      !isEnabled &&
      splitLoadModels[componentGroup === ComponentGroup.TRAILER ? 1 : 0].splits.length > 1
    ) {
      setDisableDialogComponentGroup(componentGroup);
      setIsDisableMultipleSharesDialogVisible(true);
      return;
    }
    setMultipleSharesEnabled(componentGroup, isEnabled);
  };

  const isSplitLoadUsed = splitLoadModels.reduce((isUsed, model) => {
    if (isUsed) return true;
    return model.isSplitEnabled;
  }, false);

  const isCopyFromTruckToTrailerShown =
    splitLoadModels.length > 1 &&
    !isSplitLoadUsed &&
    props.currentContext.order.components.length === 2;

  function titleContent(splitLoadModel: EditorSplitLoadModel) {
    const title = (
      <Typography variant="h2" className={classes.partTitle}>
        {splitLoadModel.title}
      </Typography>
    );

    if (
      !isCopyFromTruckToTrailerShown ||
      splitLoadModel.componentGroup !== ComponentGroup.TRAILER
    ) {
      return title;
    }

    return (
      <Grid container>
        <Grid item xs={7}>
          {title}
        </Grid>
        <Grid item xs={5}>
          <Button
            color="primary"
            variant="contained"
            disabled={props.orderDetailsModel.isOrderUpdateDisabled}
            onClick={() => {
              props.copyTruckToTrailer(props.currentContext.contextId);
            }}
          >
            {translate('orders.copyFromTruck')}
          </Button>
        </Grid>
      </Grid>
    );
  }

  if (props.editedMasterDataComponentId) {
    const scrollComponentId = props.editedMasterDataComponentId;
    // NOTE(mikkogy,20230130) flushSync can't be called directly from render so
    // we need a timeout.
    setTimeout(() => {
      props.clearEditedMasterDataComponentId();
      // NOTE(mikkogy,20230130) the point of flushSync is to ensure rendering so
      // timeout durations would not make any difference as long as we make sure
      // the callbacks are not run immediately without scheduling for later.
      flushSync(() => {
        // NOTE(mikkogy,20230130) apparently scrolling to component in flushSync
        // handler does not work so we need another timer to handle scroll.
        setTimeout(() => {
          const element = document.getElementById(scrollComponentId);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 50);
      });
    }, 50);
  }

  return (
    <div>
      {splitLoadModels
        .filter((splitLoadModel) => splitLoadModel.splits.length > 0)
        .map((splitLoadModel) => (
          <div key={splitLoadModel.key} className={classes.splitLoadModelContainer}>
            {titleContent(splitLoadModel)}
            {splitLoadModel.isSplitAvailable && (
              <FormGroup className={classes.sharesSwitchContainer}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={splitLoadModel.isSplitEnabled}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        multipleSharesChanged(e, splitLoadModel.componentGroup);
                      }}
                    />
                  }
                  label={translate('orders.multipleShares.multipleShares')}
                />
              </FormGroup>
            )}
            {splitLoadModel.additionalElement && <div>{splitLoadModel.additionalElement}</div>}
            {splitLoadModel.areAllAmountsZero && splitLoadModel.splits.length > 0 && (
              <div className={classes.allSharesZero}>
                {translate('orders.multipleShares.allSharesZeroError')}
              </div>
            )}
            {splitLoadModel.isSplitEnabled && splitLoadModel.splits.length > 0 && (
              <div id={splitLoadModel.splits[0].component.id}>
                <ComponentMasterData
                  context={props.currentContext}
                  componentId={splitLoadModel.splits[0].component.id}
                  excludedTypes={undefined}
                  includedTypes={[RemoteMasterDataType.CONTAINER]}
                  isEditingMasterDataAllowed={!props.orderDetailsModel.isOrderUpdateDisabled}
                />
              </div>
            )}
            {splitLoadModel.splits.map((split: Split, index: number) => (
              <div
                id={
                  splitLoadModel.isSplitEnabled && index === 0
                    ? 'not-interesting'
                    : split.component.id
                }
                className={classes.containerData}
                key={split.component.id}
              >
                <OrderEditorSplit
                  context={props.currentContext}
                  componentId={split.component.id}
                  index={index}
                  isEditingMasterDataAllowed={!props.orderDetailsModel.isOrderUpdateDisabled}
                  split={split}
                  splitLoadModel={splitLoadModel}
                />
              </div>
            ))}
            <div className={classes.addShare}>
              {splitLoadModel.isSplitEnabled && (
                <Button
                  variant="contained"
                  disabled={!splitLoadModel.isAddingEnabled}
                  onClick={() => {
                    props.addSplit(props.currentContext.contextId, splitLoadModel.componentGroup);
                  }}
                >
                  {translate('orders.multipleShares.addShare')}
                </Button>
              )}
            </div>
          </div>
        ))}
      <DisableMultipleSharesDialog
        visible={isDisableMultipleSharesDialogVisible}
        onConfirmed={() => {
          setMultipleSharesEnabled(disableDialogComponentGroup, false);
          setIsDisableMultipleSharesDialogVisible(false);
        }}
        onNotConfirmed={() => setIsDisableMultipleSharesDialogVisible(false)}
      />
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(OrderEditorSplits);
