import React, { useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { connect, localeConnect } from 'localeConnect';
import { ApplicationState } from 'store';

import { MeasDeviceGroup, MeasDeviceAvailability } from 'store/meas-devices/types';
import { getAcceptableGroupSizes } from 'store/meas-devices/utils';
import { Context } from 'store/orders/types';
import { translate } from 'utils/translate';

const useStyles = makeStyles({
  content: {
    textAlign: 'left',
    display: 'flex',
    paddingRight: '30px',
  },
  measDeviceSelect: {
    minWidth: '200px',
  },
});

interface AutoSelectProps {
  hasSelectionChanged: boolean;
  measDeviceGroups: MeasDeviceGroup[];
  onAutoSelectRun: () => void;
  onSelect: (key: string) => void;
  scaleKey: string;
  weighingContext: Context;
}

const AutoSelect = (props: AutoSelectProps) => {
  const onAutoSelectRun = props.onAutoSelectRun;
  const onSelect = props.onSelect;
  const hasSelectionChanged = props.hasSelectionChanged;

  const measDeviceGroups = props.measDeviceGroups;
  const context = props.weighingContext;
  const noMeasDeviceGroupId = 'none';
  const measDeviceGroupId = context.measDeviceGroupId || noMeasDeviceGroupId;
  const acceptableGroupSizes = getAcceptableGroupSizes(context, props.scaleKey);

  // NOTE(mikkogy,20200812) if there is only one non-manual meas device and it
  // is available at the time of creating this component it should be
  // automatically selected.
  const hasContextMeasDevice = context.measDeviceKeys.length > 0;
  const nonManualGroups = measDeviceGroups.filter((group) => !group.isManual);
  const isOnlyGroupAvailable =
    nonManualGroups.length === 1 &&
    nonManualGroups[0].availability === MeasDeviceAvailability.Available;
  const onlyMeasDeviceGroupId = nonManualGroups.length === 1 ? nonManualGroups[0].id : '';
  const shouldAutoselect =
    !hasSelectionChanged &&
    measDeviceGroupId === noMeasDeviceGroupId &&
    !hasContextMeasDevice &&
    isOnlyGroupAvailable &&
    acceptableGroupSizes.includes(nonManualGroups[0].measDeviceKeys.length);

  // NOTE(mikkogy,20220531) initially context may not contain weighing jobs
  // although they will appear soon so we must not try to autoselect when there
  // are no acceptable group sizes. If we do we will only mark selection changed
  // and skip autoselect.
  const acceptableGroupSizesLength = acceptableGroupSizes.length;
  useEffect(() => {
    if (hasSelectionChanged || acceptableGroupSizesLength === 0) {
      return;
    }
    if (onlyMeasDeviceGroupId && shouldAutoselect) {
      onSelect(onlyMeasDeviceGroupId);
    }
    onAutoSelectRun();
  }, [
    hasSelectionChanged,
    onAutoSelectRun,
    onlyMeasDeviceGroupId,
    shouldAutoselect,
    onSelect,
    acceptableGroupSizesLength,
  ]);

  return null;
};

interface ParameterProps {
  weighingContext: Context;
  selectionTrailerKey?: string;
  onSelect: (key: string) => void;
  onUnselect: () => void;
}

interface PropsFromState {
  measDeviceGroups: MeasDeviceGroup[] | undefined;
  scaleKey: string;
}

interface PropsFromDispatch {}

const mapStateToProps = (state: ApplicationState) => ({
  measDeviceGroups: state.measDevices.measDeviceGroups,
  scaleKey: state.currentScaleInfo.scaleKey,
});

const mapDispatchToProps = {};

type AllProps = ParameterProps & PropsFromDispatch & PropsFromState;

const OperatorMeasDeviceSelect = (props: AllProps) => {
  const classes = useStyles();
  const onSelect = props.onSelect;
  const [hasSelectionChanged, setHasSelectionChanged] = React.useState(false);
  const measDeviceGroups = props.measDeviceGroups;
  const context = props.weighingContext;
  const noMeasDeviceGroupId = 'none';
  const measDeviceGroupId = context.measDeviceGroupId || noMeasDeviceGroupId;
  const acceptableGroupSizes = getAcceptableGroupSizes(context, props.scaleKey);

  const handleChange = (event: SelectChangeEvent<string>) => {
    setHasSelectionChanged(true);
    if (measDeviceGroupId !== noMeasDeviceGroupId) {
      props.onUnselect();
    }
    const key = event.target.value as string;
    if (key !== noMeasDeviceGroupId) {
      onSelect(key);
    }
  };

  if (!measDeviceGroups) {
    // NOTE(mikkogy,20221202) while meas device groups are not loaded rendering
    // select with a value that is not in the empty items will cause a warning.
    return null;
  }

  return (
    <div className={classes.content}>
      <AutoSelect
        hasSelectionChanged={hasSelectionChanged}
        measDeviceGroups={measDeviceGroups}
        onAutoSelectRun={() => setHasSelectionChanged(true)}
        onSelect={onSelect}
        scaleKey={props.scaleKey}
        weighingContext={props.weighingContext}
      />
      <FormControl variant="standard">
        <InputLabel htmlFor="vehicle-select">
          {translate('operatorOrder.measDeviceSelect.scale')}
        </InputLabel>
        <Select
          variant="standard"
          inputProps={{
            name: 'meas-device',
            id: 'meas-device-select',
          }}
          value={measDeviceGroupId}
          onChange={handleChange}
          className={classes.measDeviceSelect}
        >
          <MenuItem key={noMeasDeviceGroupId} value={noMeasDeviceGroupId}>
            {translate('operatorOrder.measDeviceSelect.noScale')}
          </MenuItem>
          {measDeviceGroups
            .sort((g1, g2) => g1.name.localeCompare(g2.name))
            .map((group: MeasDeviceGroup) => (
              <MenuItem
                key={group.id}
                value={group.id}
                disabled={
                  group.availability !== MeasDeviceAvailability.Available ||
                  !acceptableGroupSizes.includes(group.measDeviceKeys.length)
                }
              >
                {group.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(OperatorMeasDeviceSelect);
