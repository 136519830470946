import React, { useState } from 'react';
import { RouterState } from 'connected-react-router';
import { RouteComponentProps } from 'react-router';

import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';

import { connect, localeConnect } from 'localeConnect';
import { ApplicationState, ConnectedReduxProps } from 'store';
import { ContainerType, DetailedMasterDataType, RemoteMasterDataType } from 'store/common/types';
import { masterDataSelected } from 'store/master-data/actions';
import { selectVehicle } from 'store/vehicles/actions';
import { Vehicle, VehiclesState } from 'store/vehicles/types';
import { mobileContentMaxWidth, paperRoot } from 'theme';

import { GenericMasterData, MasterDataState, MasterDataTypeState } from 'store/master-data/types';
import { toMasterDataStateType } from 'store/master-data/utils';
import { PendingMasterDataUpdate } from 'store/orders/types';
import SimpleNavBar from 'components/SimpleNavBar';
import { translate } from 'utils/translate';
import MasterDataSearch from '../MasterDataSearch';
import VehicleSelection from './VehicleSelection';

const useStyles = makeStyles({
  root: paperRoot,
});

const contentStyles = makeStyles({
  container: {
    padding: '30px',
    maxWidth: mobileContentMaxWidth,
    margin: 'auto',
    textAlign: 'left',
  },
  continue: {
    marginTop: '40px',
    float: 'right',
  },
});

const masterDataType = RemoteMasterDataType.CONTAINER;
const subtype = ContainerType.TRUCK;
const detailedType: DetailedMasterDataType = {
  type: masterDataType,
  subtype: subtype,
};

interface ContentProps {
  isContinueShown: boolean;
  isSelectionShown: boolean;
  isTrailerUsed: boolean;
  onContinue: () => void;
  selectedVehicle: Vehicle | undefined;
  typeState: MasterDataTypeState;
  selectVehicle: (
    masterDataType: DetailedMasterDataType,
    pendingSelect: PendingMasterDataUpdate,
  ) => void;
  showSelection: () => void;
  setIsTrailerUsed: (isUsed: boolean) => void;
}

const VehiclesContent = (props: ContentProps) => {
  const classes = contentStyles();

  return (
    <div className={classes.container}>
      {props.isSelectionShown && (
        <MasterDataSearch
          disabledKeys={[]}
          requireSelection={true}
          select={(item: GenericMasterData | undefined) => {
            const vehicle = item as Vehicle;
            if (vehicle) {
              props.selectVehicle(detailedType, vehicle);
            }
          }}
          selectedItem={props.selectedVehicle}
          type={detailedType}
          typeState={props.typeState}
        />
      )}
      {!props.isSelectionShown && (
        <div>
          <VehicleSelection
            isTrailerUsed={props.isTrailerUsed}
            selectedVehicle={props.selectedVehicle}
            showSelection={props.showSelection}
            setIsTrailerUsed={props.setIsTrailerUsed}
          />
          {props.isContinueShown && (
            <Button
              variant="contained"
              color="primary"
              disabled={!props.selectedVehicle}
              className={classes.continue}
              onClick={props.onContinue}
            >
              {translate('vehicles.continue')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

// Separate state props + dispatch props to their own interfaces.
interface PropsFromState extends VehiclesState {
  masterData: MasterDataState;
  router: RouterState;
  useTrailer: boolean;
}

// We can use `typeof` here to map our dispatch types to the props, like so.
interface PropsFromDispatch {
  masterDataSelected: typeof masterDataSelected;
  selectVehicle: typeof selectVehicle;
}

// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
/*eslint-disable */
export type AllProps = PropsFromDispatch &
  PropsFromState &
  RouteComponentProps<{}> &
  ConnectedReduxProps;
/*eslint-enable */

const mapStateToProps = ({ masterData, vehicles }: ApplicationState) => ({
  masterData: masterData,
  selectedVehicle: vehicles.selectedVehicle,
  useTrailer: vehicles.trailerKey !== undefined,
  previousTrailerKey: vehicles.previousTrailerKey,
});

const mapDispatchToProps = {
  masterDataSelected,
  selectVehicle,
};

export const Vehicles = (props: AllProps) => {
  const classes = useStyles();

  const [selectedVehicle, setSelectedVehicle] = useState(props.selectedVehicle);
  const [useTrailerState, setUseTrailerState] = useState(props.previousTrailerKey ? true : false);
  const [showTruckSelection, setShowTruckSelection] = useState(false);

  const previousVehicleKeyOnStart = props.selectedVehicle?.key;

  const selectVehicle = (
    masterDataType: DetailedMasterDataType,
    pendingSelect: PendingMasterDataUpdate,
  ) => {
    console.log('Selected', pendingSelect.key);
    setSelectedVehicle(pendingSelect as Vehicle);
    setShowTruckSelection(false);
    // NOTE(mikkogy,20201105) needed to ensure last selected contains the latest
    // selection, so if user opens list again, the correct truck will be
    // pre-selected in the list.
    props.masterDataSelected(detailedType, pendingSelect);
  };

  const setUseTrailer = (isTrailerUsed: boolean) => {
    setUseTrailerState(isTrailerUsed);
  };

  const confirm = () => {
    if (selectedVehicle) {
      props.selectVehicle(selectedVehicle, useTrailerState ? '' : undefined);
    } else {
      console.warn('Select vehicle first');
    }
  };

  const showSelection = () => {
    setShowTruckSelection(true);
  };

  const closeSelection = () => {
    setShowTruckSelection(false);
  };

  const stateType = toMasterDataStateType(masterDataType, subtype);
  const typeState = props.masterData.dataTypes?.[stateType];

  const getOnGoBackHandler = () => {
    const handler = showTruckSelection ? closeSelection : confirm;
    return previousVehicleKeyOnStart ? handler : undefined;
  };
  const onGoBackHandler = getOnGoBackHandler();

  return (
    <div className={classes.root}>
      <SimpleNavBar
        roleSelectionVisible={true}
        showSiteInfo={false}
        title={translate('vehicles.title')}
        onGoBack={onGoBackHandler}
      />
      <VehiclesContent
        isContinueShown={!previousVehicleKeyOnStart}
        isSelectionShown={showTruckSelection}
        isTrailerUsed={useTrailerState}
        selectedVehicle={selectedVehicle}
        typeState={typeState}
        onContinue={confirm}
        selectVehicle={selectVehicle}
        showSelection={showSelection}
        setIsTrailerUsed={setUseTrailer}
      />
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(mapStateToProps, mapDispatchToProps)(Vehicles);
