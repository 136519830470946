import { UserDetails, UserDomain } from './types';

export const getHasOrganizationRoleRight = (
  userDetails: UserDetails | undefined,
  right: string,
) => {
  if (userDetails && userDetails.organizationRoleRights) {
    return !!userDetails.organizationRoleRights[right];
  }
  return false;
};

export const formatUserDisplayName = (userData: UserDetails | undefined) => {
  if (!userData) {
    return '';
  }
  const card = userData.contactCard;
  if (card) {
    if (card.givenName && card.familyName) {
      return `${card.givenName} ${card.familyName}`;
    }
    if (card.givenName) {
      return card.givenName;
    }
    if (card.familyName) {
      return card.familyName;
    }
  }
  return userData.username;
};

export const isUserInDomain = (domains: UserDomain[], domainKey: string | undefined) => {
  if (!domains?.length || !domainKey) {
    console.warn(`user has no domains or key '${domainKey}' is invalid`);
    return false;
  }
  return domains.some((domain: any) => domain.key === domainKey);
};

export const createTokenHeader = (token: string): Record<string, string> => {
  return { 'X-AUTH-TOKEN': token };
};
