import React from 'react';

interface DisabledWrapProps {
  onClick: () => void;
  children: React.ReactNode;
}

export const DisabledWrap = (props: DisabledWrapProps) => (
  <div onClick={props.onClick}>{props.children}</div>
);

export default DisabledWrap;
