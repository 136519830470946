export interface HighlightedTextPart {
  text: string;
  isHighlighted: boolean;
}

interface Range {
  startInclusive: number;
  endExlusive: number;
}

function findRanges(searchTerm: string, text: string): Range[] {
  const searchTermParts: string[] = searchTerm
    .toLowerCase()
    .split(/(\s+)/)
    .filter((part) => !!part.trim());

  const textLowerCase = text.toLowerCase();

  const ranges: Range[] = [];
  searchTermParts.forEach((part) => {
    let position = 0;
    while (position !== -1) {
      position = textLowerCase.indexOf(part, position);
      if (position !== -1) {
        const endIndex = position + part.length;
        // NOTE(mikkogy,20220503) for simplicity we don't try to combine ranges
        // yet. It is not simple as search term parts can match anywhere in
        // text.
        ranges.push({ startInclusive: position, endExlusive: endIndex });
        position += 1;
      }
    }
  });
  return ranges;
}

function combineRanges(initialRanges: Range[]): Range[] {
  let ranges = initialRanges;
  // NOTE(mikkogy,20220503) sorting makes combining easier as a later range in
  // array will not combine three ranges into one.
  ranges.sort((range1, range2) => {
    return range1.startInclusive - range2.startInclusive;
  });
  for (let i = 0; i < ranges.length - 1; i++) {
    const range1 = ranges[i];
    const range2 = ranges[i + 1];
    if (range2.startInclusive <= range1.endExlusive) {
      const combined = {
        startInclusive: Math.min(range1.startInclusive, range2.startInclusive),
        endExlusive: Math.max(range1.endExlusive, range2.endExlusive),
      };
      ranges = [...ranges.slice(0, i), combined, ...ranges.slice(i + 2)];
      // NOTE(mikkogy,20220503) start over as ranges have changed. There may be
      // new ranges that can now be combined.
      // NOTE(mikkogy,20220503) for will increment after executing code block.
      // -1 turns into 0 when starting code block the next time.
      i = -1;
    }
  }
  return ranges;
}

export function highlightText(searchTerm: string, text: string) {
  const initialRanges = findRanges(searchTerm, text);
  const indexRanges = combineRanges(initialRanges);

  if (indexRanges.length === 0) {
    return [
      {
        text: text,
        isHighlighted: false,
      },
    ];
  }
  const highlightedParts: HighlightedTextPart[] = [];
  function addPart(text: string, isHighlighted: boolean) {
    highlightedParts.push({ text, isHighlighted });
  }
  let handledIndex = 0;
  indexRanges.forEach((range) => {
    if (range.startInclusive > handledIndex) {
      addPart(text.substring(handledIndex, range.startInclusive), false);
    }
    addPart(text.substring(range.startInclusive, range.endExlusive), true);
    handledIndex = range.endExlusive;
  });
  if (handledIndex < text.length) {
    addPart(text.substring(handledIndex), false);
  }
  return highlightedParts;
}
