import React from 'react';

import { makeStyles } from '@mui/styles';
import { Button, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import { Context } from 'store/orders/types';
import { getContextMainJob, hasContextRequiredMasterData } from 'store/orders/utils';

import { BridgeEnabledFeatures } from 'store/scale-info/types';

import CardDetailItem from 'components/CardDetailItem';
import ConfirmDialog from 'components/ConfirmDialog';
import PrimaryContainerTareFeature, { hasMassError } from 'components/PrimaryContainerTareFeature';

import { formatDateAndTime, formatMass } from 'components/utils';

import theme from 'theme';
import { translate } from 'utils/translate';

const useStyles = makeStyles({
  button: {
    marginTop: '20px',
  },
  cardHeader: {
    fontWeight: 500,
  },
  content: {
    lineHeight: '2.0',
  },
  error: {
    color: theme.palette.error.main,
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: 1.33,
    marginRight: '30px',
  },
});

interface Props {
  context: Context;
  enabledFeatures: BridgeEnabledFeatures;
  finishWithContextTare: () => void;
  rootClass?: string;
}

export default function DriverPrimaryContainerTare(props: Props) {
  const classes = useStyles();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
  const job = getContextMainJob(props.context);
  if (!job) return null;
  const presetTare = props.context?.presetTares[job?.containers[0]?.key];
  if (!presetTare) return null;
  const hasError =
    hasMassError(props.context, props.enabledFeatures) ||
    !hasContextRequiredMasterData(props.context);
  return (
    <PrimaryContainerTareFeature context={props.context} isExistingTareRequired={true}>
      <Card className={props.rootClass}>
        <CardHeader
          title={translate('orderDetails.presetTare.title')}
          className={classes.cardHeader}
        />
        <Divider variant="middle" component={'hr'} />
        <CardContent>
          <div>
            <div className={classes.content}>
              <Grid container spacing={2}>
                <Grid item xs={12} className={hasError ? classes.error : ''}>
                  <CardDetailItem
                    title={translate('orderDetails.presetTare.mass')}
                    value={formatMass(presetTare.massKg)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CardDetailItem
                    title={translate('orderDetails.presetTare.time')}
                    value={formatDateAndTime(presetTare.timestamp)}
                  />
                </Grid>
              </Grid>
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                disabled={hasError}
                onClick={() => setIsConfirmDialogOpen(true)}
              >
                {translate('orderDetails.presetTare.finishWithTare')}
              </Button>
            </div>
          </div>
          {isConfirmDialogOpen && (
            <ConfirmDialog
              title={translate('orderDetails.presetTare.confirmTitle')}
              question={translate('orderDetails.presetTare.confirmQuestion')}
              visible={isConfirmDialogOpen}
              onConfirmed={props.finishWithContextTare}
              onNotConfirmed={() => setIsConfirmDialogOpen(false)}
            />
          )}
        </CardContent>
      </Card>
    </PrimaryContainerTareFeature>
  );
}
