import React from 'react';

import { connect, localeConnect } from '../localeConnect';

import { ApplicationState } from '../store';
import { getCurrentUserSettings } from '../store/selectors';
import { ContainerType } from '../store/common/types';
import { LoadType } from '../store/jobs/types';
import { loadWeighedKgs } from '../store/jobs/utils';
import { Context, WeighingProcess } from '../store/orders/types';
import { getContextMainJob } from '../store/orders/utils';
import { BridgeEnabledFeatures } from '../store/scale-info/types';
import { saveUserSettings } from '../store/settings/actions';
import { UserSpecificSettings } from '../store/settings/types';
import { filterValidLoads } from '../store/utils';
import { createOrderDetailsModel } from '../models/orderdetails.model';
import { contextToNormalVehicleWeighingModels } from '../models/vehicleweighing.model';
import { translate } from '../utils/translate';
import { formatMass } from './utils';

import LongTextDialog from './LongTextDialog';
import SaveTareDialog, { SaveTareAnswer } from './SaveTareDialog';

interface ParameterProps {
  context: Context | undefined;
  visible: boolean;
  onAnswer: (isSaveRequested: boolean) => void;
}

interface PropsFromState {
  enabledFeatures: BridgeEnabledFeatures;
  userKey?: string;
  userSettings: UserSpecificSettings;
}

interface PropsFromDispatch {
  saveUserSettings: typeof saveUserSettings;
}

/*eslint-disable */
type AllProps = ParameterProps & PropsFromDispatch & PropsFromState;
/*eslint-enable */

const mapStateToProps = (state: ApplicationState) => ({
  enabledFeatures: state.currentScaleInfo.enabledFeatures,
  userKey: state.user?.user?.userData?.key,
  userSettings: getCurrentUserSettings(state),
});

const mapDispatchToProps = {
  saveUserSettings,
};

function getTareMass(context: Context | undefined) {
  const job = getContextMainJob(context);
  const loads = job?.loads || [];
  const validLoads = filterValidLoads(loads);
  const tare = validLoads.find((load) => load.loadType === LoadType.TARE);
  if (tare) {
    return formatMass(loadWeighedKgs(tare));
  }
  return '';
}

export function isShowingSaveTareDialogApplicable(
  context: Context | undefined,
  userSettings: UserSpecificSettings,
  enabledFeatures: BridgeEnabledFeatures,
) {
  if (
    !context ||
    context?.weighingJobs === undefined ||
    context?.weighingJobs?.length === 0 ||
    context.process !== WeighingProcess.NORMAL ||
    getContextMainJob(context)?.containers?.[0]?.containerType !== ContainerType.TRUCK
  ) {
    return false;
  }
  const model = contextToNormalVehicleWeighingModels(context, [], enabledFeatures);
  const orderDetailsModel = createOrderDetailsModel(
    context,
    context.order,
    context.process,
    context.weighingJobs,
    undefined,
    undefined,
  );
  return (
    enabledFeatures.bridgePrimaryContainerTare &&
    !userSettings.skipSavingTruckTare &&
    model.isFinishingEnabled &&
    orderDetailsModel.hasAllRequiredMasterData
  );
}

const SettingsSaveTareDialogComponent = (props: AllProps) => {
  const [isDontAskInfoDialogVisible, setIsDontAskInfoDialogVisible] = React.useState(false);
  if (!props.userKey) throw new Error('No user in settings. Not logged in?');

  function update(propertyUpdater: (userSettings: UserSpecificSettings) => UserSpecificSettings) {
    if (!props.userKey) return;
    props.saveUserSettings(props.userKey, propertyUpdater(props.userSettings));
  }

  function gotAnswer(answer: SaveTareAnswer) {
    if (!props.userKey) return;
    if (answer === SaveTareAnswer.DONT_SAVE_DONT_ASK_AGAIN) {
      update((userSettings: UserSpecificSettings) => {
        return { ...userSettings, skipSavingTruckTare: true };
      });
      setIsDontAskInfoDialogVisible(true);
      return;
    }
    props.onAnswer(answer === SaveTareAnswer.SAVE);
  }

  const containerName = getContextMainJob(props.context)?.containers[0]?.name ?? '';

  const isApplicable = isShowingSaveTareDialogApplicable(
    props.context,
    props.userSettings,
    props.enabledFeatures,
  );

  return (
    <div>
      {isApplicable && (
        <SaveTareDialog
          containerName={containerName}
          onAnswer={gotAnswer}
          tareMass={getTareMass(props.context)}
          visible={props.visible && !isDontAskInfoDialogVisible}
        />
      )}
      <LongTextDialog
        title={translate('saveTareDialog.dontSaveDontAskAgainInfoTitle')}
        textContent={translate('saveTareDialog.dontSaveDontAskAgainInfo')}
        onClose={() => {
          props.onAnswer(false);
          setIsDontAskInfoDialogVisible(false);
        }}
        open={isDontAskInfoDialogVisible}
      />
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsSaveTareDialogComponent);
