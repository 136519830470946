import { Document } from '../common/types';

export enum CacheImageType {
  UPLOAD = 'UPLOAD',
  DOWNLOAD = 'DOWNLOAD',
  STORED = 'STORED',
  WAIT_FOR_DOWNLOAD = 'WAIT_FOR_DOWNLOAD',
}

export interface CacheImageMeta {
  filename: string;
  mimeType: string;
  description?: string;
  timestamp: number;
}

export interface CacheImageUploadMeta {
  contextId: string;
  jobKey: string;
}

export interface CacheImage {
  data: string;
  type: CacheImageType;
  rawSizeBytes: number;
  createTime: number;
  accessTime: number;
  attachmentKey: string;
  transferId: string;
  progressRawBytes: number;
  localId: string;
  orderKey: string;
  meta: CacheImageMeta;
  uploadMeta: CacheImageUploadMeta | undefined;
}

export interface Attachment extends Document {
  description?: string;
  bridgeSpecificPropOwnerKey?: string;
  filename: string;
  mimeType: string;
  timestamp: number;
}

export interface OrderImageMap {
  [orderKey: string]: CacheImage[];
}

export interface OrderImages {
  // NOTE(mikkogy,20201021) images is a seemingly useless wrapper but for an
  // unknown reason localStorage does not persist data without wrapping the map.
  images: OrderImageMap;
}

export interface ImagesState {
  addImageJobKey: string | undefined;
  orderImages: OrderImages;
  // NOTE(mikkogy,20201021) for future migrations over incompatible schema
  // changes. Cache is supposed to be stored permanently and application may be
  // updated any time.
  schemaVersion: string;
  // NOTE(mikkogy,20201021) best effort performance helper, no guarantee that
  // valid id exists and in that case iterating orders is required.
  transferIdToOrderKeyMap: { [transferId: string]: string };
}

export enum ImagesActionTypes {
  ADD = '@@images/ADD',
  ADD_IMAGE_TO_JOB = '@@images/ADD_IMAGE_TO_JOB',
  REMOVE = '@@images/REMOVE',
  REMOVE_LOCAL_IMAGE = '@@images/REMOVE_LOCAL_IMAGE',
  REMOVE_UPLOAD_FAILED_IMAGE = '@@images/REMOVE_UPLOAD_FAILED_IMAGE',

  SAVE_TO_STORE = '@@images/SAVE_TO_STORE',
  REMOVE_BY_ORDER_KEYS = '@@images/REMOVE_BY_ORDER_KEYS',
  SET_ADD_IMAGE_JOB_KEY = '@@images/SET_ADD_IMAGE_JOB_KEY',
  SET_IMAGE_DESCRIPTION = '@@images/SET_IMAGE_DESCRIPTION',
  SET_IMAGE_DESCRIPTION_IMMEDIATELY = '@@images/SET_IMAGE_DESCRIPTION_IMMEDIATELY',
}
