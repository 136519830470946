import { translate } from 'utils/translate';
import {
  ContainerRole,
  ContainerType,
  DetailedMasterDataType,
  DocType,
  MasterDataItem,
  RemoteMasterDataType,
  ServerPagination,
} from '../common/types';
import { PendingMasterDataUpdates } from '../orders/types';
import { SchemaEntry } from '../scale-info/utils';
import { defaultPagination } from '../utils';
import { GenericMasterData } from './types';

export function getDefaultTrailerName(isTrailerUsed: boolean): string | undefined {
  return isTrailerUsed ? '-' : undefined;
}

export function getTranslatedAndSortedSchemaEntries(schemaEntries: SchemaEntry[]): SchemaEntry[] {
  const translatedAndSortedEntries = schemaEntries
    .map((entry: SchemaEntry) => {
      return {
        title: entry.title,
        translatedTitle: getTranslatedTypeTitle(entry),
        subtype: entry.subtype,
        typeName: entry.typeName,
        isTypable: entry.isTypable,
      };
    })
    .sort((a, b) => {
      const aTypable = !!a.isTypable;
      const bTypable = !!b.isTypable;
      if (aTypable !== bTypable) {
        if (aTypable) return 1;
        if (bTypable) return -1;
      }
      return a.translatedTitle.localeCompare(b.translatedTitle);
    })
    .map((entry) => {
      return {
        ...entry,
        translatedTitle: undefined,
      };
    });
  return translatedAndSortedEntries;
}

export function getTranslatedTypeTitle(entry: SchemaEntry): string {
  return entry.title.endsWith('_TITLE') ? translate(`masterData.${entry.title}`) : entry.title;
}

export function isSecondaryContainer(masterDataItem: MasterDataItem) {
  return (
    masterDataItem.docType === DocType.CONTAINER &&
    masterDataItem.containerType === ContainerType.GENERIC &&
    masterDataItem.roles.includes(ContainerRole.SOURCE_CONTAINER)
  );
}

export function updateLastUsed(
  oldItems: GenericMasterData[],
  newItem: GenericMasterData,
  maxItemCount: number,
) {
  if (maxItemCount === 0) return [];
  if (!newItem || !newItem.key) return [...oldItems].splice(0, maxItemCount);
  // NOTE(mikkogy,20220531) pick latest max-1 from old that do not match the new
  // key. This way we get max in total even if new was one of the old (assuming
  // there were max to begin with).
  return [newItem].concat(
    oldItems.filter((o) => o.key !== newItem.key).splice(0, maxItemCount - 1),
  );
}

export function parseStateType(stateType: string): DetailedMasterDataType {
  const parts = stateType.split('|');
  return { type: parts[0], subtype: parts[1] };
}

export function toMasterDataStateType(
  masterDataType: RemoteMasterDataType | string,
  subtype?: string,
): string {
  if (subtype === '') {
    subtype = undefined;
  }
  if (masterDataType !== RemoteMasterDataType.CONTAINER && subtype !== undefined) {
    throw new Error(
      `only container may have subtype, type: ${masterDataType}, subtype: ${subtype}`,
    );
  }
  if (masterDataType === RemoteMasterDataType.CONTAINER && subtype === undefined) {
    throw new Error('container must have subtype');
  }
  if (masterDataType !== RemoteMasterDataType.CONTAINER) {
    return masterDataType;
  }
  return `${masterDataType}|${subtype}`;
}

export function initialPagination(): ServerPagination {
  return { itemCount: 0, ...defaultPagination() };
}
export function shouldTypableBeUpdated(
  pendingMasterDataUpdates: PendingMasterDataUpdates,
  componentId: string,
  typeName: string,
) {
  if (pendingMasterDataUpdates[componentId]) {
    return !!pendingMasterDataUpdates[componentId][typeName];
  }
  return false;
}
