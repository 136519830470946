import React from 'react';

import { makeStyles } from '@mui/styles';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

import PhoneIcon from '@mui/icons-material/PhoneOutlined';
import DirectionsIcon from '@mui/icons-material/DirectionsOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import { connect, localeConnect } from '../localeConnect';
import { ApplicationState } from '../store';
import { SiteInfo, ShownSiteInfoType } from '../store/scale-info/types';

import { markSiteInfoShown } from '../store/scale-info/actions';
import { checkIfHashHasBeenUpdated } from '../store/scale-info/utils';
import { translate } from '../utils/translate';
import LongTextDialog from './LongTextDialog';

interface ParameterProps {
  isForcingInstructionsEnabled: boolean;
  isRouteShown: boolean;
}

interface PropsFromState {
  scaleKey: string;
  scaleName: string;
  siteInfo?: SiteInfo;
  isLocationChanged: boolean;
  isInstructionChanged: boolean;
}

interface PropsFromDispatch {
  markSiteInfoShown: typeof markSiteInfoShown;
}

export const mapStateToProps = (state: ApplicationState) => {
  const currentSiteInfoHashes = state.scaleInfo.siteInfoHashes[state.currentScaleInfo.scaleKey];
  return {
    scaleKey: state.currentScaleInfo.scaleKey,
    scaleName: state.currentScaleInfo.scaleName,
    siteInfo: state.currentScaleInfo.siteInfo,
    isLocationChanged: checkIfHashHasBeenUpdated(
      currentSiteInfoHashes?.locationUrlHash,
      currentSiteInfoHashes?.shownLocationUrlHash,
    ),
    isInstructionChanged: checkIfHashHasBeenUpdated(
      currentSiteInfoHashes?.instructionsHash,
      currentSiteInfoHashes?.shownInstructionHash,
    ),
  };
};

const mapDispatchToProps = {
  markSiteInfoShown: markSiteInfoShown,
};

/*eslint-disable */
export type AllProps = PropsFromDispatch & PropsFromState & ParameterProps;
/*eslint-enable */

const useStyles = makeStyles({
  root: {
    '& .Mui-disabled .MuiAvatar-colorDefault': {
      backgroundColor: 'rgba(0, 0, 0, 0.26)',
    },
    '& .MuiCardHeader-action': {
      margin: '0px',
    },
  },
  cardHeader: {
    fontWeight: 500,
    cursor: 'pointer',
  },
  actionAvatars: {
    width: '50px',
    height: '50px',
  },
  showContent: {
    display: 'block',
  },
  hideContent: {
    display: 'none',
  },
  contentChangeIndicatorParent: {
    position: 'relative',
    top: '12px',
    left: '5px',
  },
  headerChangeIndicatorParent: {
    position: 'absolute',
    top: '0px',
    right: '0px',
  },
  infoIconContainer: {
    position: 'relative',
  },
  changeIndicatorDot: {
    width: '6px',
    height: '6px',
    borderRadius: '3px',
    backgroundColor: 'red',
  },
});

const ExpandingSiteInfo = (props: AllProps) => {
  const classes = useStyles();
  const instructions = props.siteInfo?.instructions ?? '';
  const showInstructionsAsUrl = props.siteInfo?.showInstructionsAsUrl ?? false;
  const isShowingInstructionsForced =
    props.isForcingInstructionsEnabled &&
    instructions.trim().length > 0 &&
    props.isInstructionChanged &&
    !showInstructionsAsUrl &&
    (props.siteInfo?.isShowingInstructionsToDriverForced ?? false);

  // NOTE(mikkogy,20230315) instructions are forced open only on component
  // mount. If dialog needs to be forced open any time we need React.useEffect.
  const [isInstructionsDialogOpen, setIsInstructionsDialogOpen] = React.useState(
    isShowingInstructionsForced,
  );
  const [isExpanded, setIsExpanded] = React.useState(false);

  const locationUrl = props.siteInfo?.locationUrl ?? '';
  const phoneNumber = props.siteInfo?.phoneNumber ?? '';

  function markInstructionsShown() {
    props.markSiteInfoShown(props.scaleKey, ShownSiteInfoType.INSTRUCTIONS);
  }

  return (
    <div className={classes.root}>
      <LongTextDialog
        title={translate('orderDetails.instructions.title')}
        onClose={() => {
          setIsInstructionsDialogOpen(false);
          // NOTE(mikkogy,20230314) mark shown for forced use case. useEffect
          // would be ideal but this is simpler and probably good enough.
          markInstructionsShown();
        }}
        textContent={instructions}
        open={isInstructionsDialogOpen}
      />
      <Card>
        <CardHeader
          title={props.scaleName}
          className={classes.cardHeader}
          onClick={() => setIsExpanded(!isExpanded)}
          action={
            <div className={classes.infoIconContainer}>
              <InfoIcon />
              {instructions && (props.isLocationChanged || props.isInstructionChanged) && (
                <div className={classes.headerChangeIndicatorParent}>
                  <div className={classes.changeIndicatorDot} />
                </div>
              )}
            </div>
          }
        />
        <Divider
          variant="middle"
          component="hr"
          className={isExpanded ? classes.showContent : classes.hideContent}
        />
        <CardContent className={isExpanded ? classes.showContent : classes.hideContent}>
          <Grid container justifyContent="space-around" alignItems="center">
            <Box>
              <Button disabled={!phoneNumber} href={`tel:${phoneNumber}`}>
                <Avatar component={'span'} className={classes.actionAvatars}>
                  <PhoneIcon />
                </Avatar>
              </Button>
              <Typography align="center" component="div" variant="caption">
                {translate('orderDetails.call')}
              </Typography>
            </Box>
            {props.isRouteShown && (
              <Box>
                <Button
                  disabled={!locationUrl}
                  target="_blank"
                  rel="noopener"
                  href={locationUrl}
                  onClick={() =>
                    props.markSiteInfoShown(props.scaleKey, ShownSiteInfoType.LOCATION)
                  }
                >
                  <Avatar component={'span'} className={classes.actionAvatars}>
                    <DirectionsIcon />
                  </Avatar>
                </Button>
                <Typography align="center" component="div" variant="caption">
                  <>
                    {instructions && props.isLocationChanged && (
                      <span className={classes.contentChangeIndicatorParent}>
                        <div className={classes.changeIndicatorDot} />
                      </span>
                    )}
                    {translate('orderDetails.route')}
                  </>
                </Typography>
              </Box>
            )}
            <Box>
              {showInstructionsAsUrl && (
                <Button
                  disabled={!instructions}
                  target="_blank"
                  rel="noopener"
                  href={instructions}
                  onClick={() => {
                    // NOTE(mikkogy,20230314) mark shown as early as possible
                    // for good UX.
                    markInstructionsShown();
                  }}
                >
                  <Avatar component="span" className={classes.actionAvatars}>
                    <InfoIcon />
                  </Avatar>
                </Button>
              )}
              {!showInstructionsAsUrl && (
                <Button
                  disabled={!instructions}
                  onClick={() => {
                    setIsInstructionsDialogOpen(true);
                    // NOTE(mikkogy,20230314) mark shown as early as possible
                    // for good UX.
                    markInstructionsShown();
                  }}
                >
                  <Avatar component="span" className={classes.actionAvatars}>
                    <InfoIcon />
                  </Avatar>
                </Button>
              )}
              <Typography align="center" component="div" variant="caption">
                <>
                  {instructions && props.isInstructionChanged && (
                    <span className={classes.contentChangeIndicatorParent}>
                      <div className={classes.changeIndicatorDot} />
                    </span>
                  )}
                  {translate('orderDetails.info')}
                </>
              </Typography>
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(ExpandingSiteInfo);
