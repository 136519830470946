import React from 'react';
import { Select, MenuItem } from '@mui/material';

import { makeStyles } from '@mui/styles';

import { DeductionOperation } from '../store/jobs/types';
import { translate } from '../utils/translate';

interface Props {
  onChange: (event: any) => void;
  disabled: boolean;
  value: DeductionOperation;
}

const useStyles = makeStyles({
  select: {
    textAlign: 'left',
  },
});

const DeductionOperationSelect = (props: Props) => {
  const classes = useStyles();
  function i18nDeductionOperation(operation: DeductionOperation) {
    return translate(`editDeductions.operations.${operation}`);
  }

  const menuItem = (operation: DeductionOperation) => (
    <MenuItem key={operation} value={operation}>
      {i18nDeductionOperation(operation)}
    </MenuItem>
  );

  return (
    <Select
      variant="standard"
      fullWidth
      className={classes.select}
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
    >
      {Object.keys(DeductionOperation).map((operation) =>
        menuItem(operation as DeductionOperation),
      )}
    </Select>
  );
};

export default DeductionOperationSelect;
