import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { connect, localeConnect } from 'localeConnect';
import { createOrderDetailsModel } from 'models/orderdetails.model';

import { ApplicationState, ConnectedReduxProps } from 'store';

import { newToast } from 'store/common/actions';
import { ContainerItem, ContainerType, DocType } from 'store/common/types';
import { DomainInfo } from 'store/scale-info/types';
import { Job, JobStatus } from 'store/jobs/types';
import { OrganizationDetails, UserDetails } from 'store/user/types';
import { activateContext, setPendingMeasDeviceKey } from 'store/meas-devices/actions';
import { destroySelectedOperatorContext, setOperatorOrderTab } from 'store/orders/actions';
import {
  ClosePolicy,
  OperatorOrder as OperatorOrderType,
  OperatorOrderTab,
  OrderStatus,
  WeighingProcess,
} from 'store/orders/types';
import { hasContextRequiredMasterData } from 'store/orders/utils';
import { jobsTrucksAndTrailers, sortJobsByReceiptNumberDescending } from 'store/jobs/utils';

import theme from 'theme';
import { translate } from 'utils/translate';

import DisabledWrap from '../DisabledWrap';

import OperatorContainer from './OperatorContainer';
import OperatorOrderDetails from './OperatorOrderDetails';
import OperatorOrderSelection from './OperatorOrderSelection';
import OperatorWeighing from './OperatorWeighing';
import ReceiptContent from './ReceiptContent';
import { ContinuousOrderInfo, ContinuousOrderInfoType } from './ContinuousOrderInfo';

const useStyles = makeStyles({
  closedJobActions: {
    paddingTop: '24px',
    backgroundColor: theme.palette.background.default,
  },
  infoContainer: {
    backgroundColor: theme.palette.background.default,
    position: 'relative',
  },
  moreReceiptsInfo: {
    marginBottom: '20px',
  },
  orderContent: {
    minHeight: '200px',
    textAlign: 'left',
  },
  receiptContainer: {
    // eslint-disable-next-line
    backgroundColor: theme.palette.background.default,
    // eslint-disable-next-line
    paddingTop: '20px',
    // eslint-disable-next-line
    paddingBottom: '1px',
    '@media print': {
      breakInside: 'auto',
      breakBefore: 'avoid',
      breakAfter: 'avoid',
      marginTop: '7pt',
      marginBottom: 0,
      padding: 0,
      columns: '0.5vw 2',
      columnFill: 'auto',
    },
  },
  tab: {
    // eslint-disable-next-line
    marginTop: '32px',
    '@media print': {
      marginTop: '0px',
      boxShadow: 'none',
    },
  },
  tabs: {
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiTab-root': {
      textTransform: 'none',
      fontSize: '1.1rem',
      minWidth: '160px',
    },
  },
  missingRequiredData: {
    color: theme.palette.error.main,
  },
  hasRequiredData: {
    color: 'black',
  },
});

const maxReceiptCount = 30;

interface PropsFromState {
  domainInfo: DomainInfo;
  operatorJobFilter?: string;
  operatorOrder?: OperatorOrderType;
  operatorOrderTab: OperatorOrderTab;
  organization?: OrganizationDetails;
  pendingMeasDeviceKey: string;
  scaleKey: string;
  schema?: Record<string, unknown>;
  user?: UserDetails;
}

interface PropsFromDispatch {
  activateContext: typeof activateContext;
  destroySelectedOperatorContext: typeof destroySelectedOperatorContext;
  newToast: typeof newToast;
  setOperatorOrderTab: typeof setOperatorOrderTab;
  setPendingMeasDeviceKey: typeof setPendingMeasDeviceKey;
}

/**
 * NOTE(mikkogy, 20200220) There's a bug in Eslint config that has been reported but not yet fixed
 * which complains about missing indentation when multiline binary expressions are used.
 * See: https://github.com/typescript-eslint/typescript-eslint/issues/398
 */
/*eslint-disable */
export type AllProps = PropsFromDispatch &
  PropsFromState &
  RouteComponentProps<{}> &
  ConnectedReduxProps;
/*eslint-enable */

const mapStateToProps = (state: ApplicationState) => ({
  domainInfo: state.currentScaleInfo.domainInfo,
  organization: state.user.user.organizationData,
  operatorJobFilter: state.orders.operatorJobFilter,
  operatorOrder: state.orders.selectedOperatorOrder,
  operatorOrderTab: state.orders.operatorOrderTab,
  scaleKey: state.currentScaleInfo.scaleKey,
  pendingMeasDeviceKey: state.measDevices.pendingMeasDeviceKey,
  schema: state.currentScaleInfo.domainInfo.masterDataCombo.schema,
  user: state.user.user.userData,
});

const mapDispatchToProps = {
  activateContext,
  destroySelectedOperatorContext,
  newToast,
  setOperatorOrderTab,
  setPendingMeasDeviceKey,
};

function a11yProps(index: any) {
  return {
    // eslint-disable-next-line
    id: `operator-order-tab-${index}`,
    'aria-controls': `operator-order-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  className: string;
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Paper {...other} elevation={0}>
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`operator-order-tabpanel-${index}`}
        aria-labelledby={`operator-order-tab-${index}`}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    </Paper>
  );
}

function getContainerName(orderTruck: ContainerItem | null, jobs: Job[]) {
  function jobLoadCount(job: Job) {
    return job.loads ? job.loads.length : 0;
  }
  const hasJobLoads = jobs.reduce((count, job) => count + jobLoadCount(job), 0) > 0;
  if (!hasJobLoads) {
    return orderTruck ? orderTruck.name : '';
  }
  const containers = jobsTrucksAndTrailers(jobs).filter(
    (container) => container.containerType === ContainerType.TRUCK,
  );
  if (containers.length > 0) {
    return containers[0].name;
  }
  return '';
}

function jobsFromProps(props: AllProps) {
  return props.operatorOrder && props.operatorOrder.weighingJobs.length > 0
    ? props.operatorOrder.weighingJobs
    : [];
}

const OperatorOrder = (props: AllProps) => {
  const classes = useStyles();
  const [isJobFilterOn, setIsJobFilterOn] = useState(true);

  const { destroySelectedOperatorContext } = props;
  useEffect(() => {
    return () => {
      // NOTE(mikkogy,20221202) when exiting this view we need to destroy
      // context. Context can change for example because of creating one,
      // discarding it or ending it. In those cases we don't need to destroy so
      // we don't want to add context id dependency. But without a dependency
      // we cannot access the latest selected context id when the component is
      // destroyed. So an action without parameters is needed. It is up to
      // handler to check current state.
      destroySelectedOperatorContext();
    };
  }, [destroySelectedOperatorContext]);

  const jobs = jobsFromProps(props);
  const closedJobs = sortJobsByReceiptNumberDescending(
    jobs.filter((job) => job.status !== JobStatus.OPEN),
  );
  const hasOrder = props.operatorOrder && !!props.operatorOrder.order;
  const context =
    props.operatorOrder && props.operatorOrder.context ? props.operatorOrder.context : undefined;
  const order =
    hasOrder && props.operatorOrder && props.operatorOrder.order
      ? props.operatorOrder.order
      : undefined;
  const orderLinkedData = order && order.linkedData ? order.linkedData : [];
  const orderTrucks = orderLinkedData.filter(
    (link) => link.docType === DocType.CONTAINER && link.containerType === ContainerType.TRUCK,
  ) as ContainerItem[];
  const orderTruck = orderTrucks.length > 0 ? orderTrucks[0] : null;
  const hasJobs = jobs.length > 0;

  const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    props.setOperatorOrderTab(newValue as OperatorOrderTab);
    const pendingMeasDeviceKey = props.pendingMeasDeviceKey;
    if (context && pendingMeasDeviceKey) {
      props.activateContext(context.contextId, pendingMeasDeviceKey);
      props.setPendingMeasDeviceKey('');
    }
  };

  const orderDetailsModel = createOrderDetailsModel(
    context,
    order,
    context ? context.process : props.operatorOrder?.process ?? WeighingProcess.UNDEFINED,
    props.operatorOrder?.weighingJobs ?? [],
    props.scaleKey,
    props.user,
  );
  const isWeighingDisabled = !hasJobs || !!(context && !orderTruck);

  const isMultipart = orderDetailsModel.process === WeighingProcess.MULTIPART;

  interface TabLabelContent {
    textKey: string;
    className: string;
  }

  function getTabLabelContent(hasAllRequiredData: boolean, isMultipart: boolean) {
    return {
      info: {
        textKey:
          hasAllRequiredData || isMultipart
            ? 'operatorOrder.common.info'
            : 'operatorOrder.common.infoRequired',
        className:
          hasAllRequiredData || isMultipart ? classes.hasRequiredData : classes.missingRequiredData,
      },
      weighing: {
        textKey:
          hasAllRequiredData || !isMultipart
            ? 'operatorOrder.common.weighing'
            : 'operatorOrder.common.weighingDataRequired',
        className:
          hasAllRequiredData || !isMultipart
            ? classes.hasRequiredData
            : classes.missingRequiredData,
      },
    };
  }
  const hasAllRequiredData = hasContextRequiredMasterData(context);
  const tabLabelContent = getTabLabelContent(hasAllRequiredData, isMultipart);

  function getTabLabel(content: TabLabelContent) {
    return <span className={content.className}>{translate(content.textKey)}</span>;
  }

  const weighingTab = () => (
    <Tab
      label={getTabLabel(tabLabelContent.weighing)}
      disabled={isWeighingDisabled}
      {...a11yProps(1)}
    />
  );

  const header = () => (
    <Tabs
      value={props.operatorOrderTab}
      onChange={handleChange}
      aria-label="operator order tabs"
      className={classes.tabs}
    >
      <Tab label={getTabLabel(tabLabelContent.info)} {...a11yProps(0)} />
      {isWeighingDisabled && (
        <DisabledWrap
          onClick={() => {
            if (order && order.status === OrderStatus.DISCARDED) {
              props.newToast('operatorOrder.common.discardedOrder');
            } else {
              props.newToast('operatorOrder.common.selectOrderAndVehicleFirst');
            }
          }}
        >
          {weighingTab()}
        </DisabledWrap>
      )}
      {!isWeighingDisabled && weighingTab()}
    </Tabs>
  );

  const containerName = getContainerName(orderTruck, jobs);
  const title2 = containerName
    ? translate('operatorOrder.common.title2WithContainer', { containerName: containerName })
    : translate('operatorOrder.common.title2');

  const isJobFilterValid =
    !!props.operatorJobFilter && !!closedJobs.find((job) => job.key === props.operatorJobFilter);
  const isContinuousOrder = order?.closePolicy === ClosePolicy.SERVER_MANUAL;

  return (
    <OperatorContainer
      title={translate('operatorOrder.common.title')}
      title2={title2}
      header={header()}
    >
      <TabPanel
        value={props.operatorOrderTab}
        index={OperatorOrderTab.Order}
        className={classes.tab}
      >
        <div className={classes.orderContent}>
          {!hasOrder && !hasJobs && <OperatorOrderSelection />}
          {hasOrder && props.operatorOrder && (
            <OperatorOrderDetails operatorOrder={props.operatorOrder} />
          )}
        </div>
      </TabPanel>
      <TabPanel
        value={props.operatorOrderTab}
        index={OperatorOrderTab.Weighing}
        className={classes.tab}
      >
        {closedJobs.length === 0 && hasOrder && props.operatorOrder?.context?.weighingJobs && (
          <div>
            <OperatorWeighing operatorOrder={props.operatorOrder} />
          </div>
        )}
        <div className={classes.infoContainer}>
          {closedJobs.length > 0 && (
            <div className={`App-print-hide ${classes.closedJobActions}`}>
              {isJobFilterValid && closedJobs.length > 1 && props.operatorJobFilter && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isJobFilterOn}
                      onChange={(e) => {
                        setIsJobFilterOn(e.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label={translate('operatorOrder.receipts.currentJobFilterLabel')}
                />
              )}
              <Button color="primary" variant="contained" onClick={() => window.print()}>
                {closedJobs.length === 1 || (isJobFilterValid && isJobFilterOn)
                  ? translate('operatorOrder.receipts.print')
                  : translate('operatorOrder.receipts.printAll')}
              </Button>
            </div>
          )}
          {isContinuousOrder && (
            <ContinuousOrderInfo isNewInstance={false} infoType={ContinuousOrderInfoType.Receipt} />
          )}
        </div>
        {closedJobs.length > 0 && (
          <div className={classes.receiptContainer}>
            {closedJobs.length >= maxReceiptCount && (
              <div className={`App-print-hide ${classes.moreReceiptsInfo}`}>
                {translate('operatorOrder.receipts.moreReceipts')}
              </div>
            )}
            {closedJobs
              .map((job, index) => {
                const receiptNumber = index + 1;
                const receiptTotalCount = closedJobs.length;
                return (
                  <ReceiptContent
                    key={job.key}
                    job={job}
                    receiptNumber={receiptNumber}
                    receiptTotalCount={receiptTotalCount}
                  />
                );
              })
              .filter(
                (job) =>
                  !isJobFilterValid ||
                  !isJobFilterOn ||
                  !props.operatorJobFilter ||
                  job.key === props.operatorJobFilter,
              )}
          </div>
        )}
      </TabPanel>
    </OperatorContainer>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(OperatorOrder);
