import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
  throttle,
} from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';

import { debounce } from '@redux-saga/core/effects';
import { RoutePaths } from 'routes';
import { incomingEvent, newToast, sendMessage } from '../common/actions';
import {
  ContainerType,
  LiveDataTypes,
  MasterDataItem,
  RemoteRequestIds,
  RemoteRequestTypes,
  RemoteReceiveTypes,
  ToastContext,
  ToastType,
  RemoteMasterDataType,
} from '../common/types';
import { setOrderKey as setDeepLinkOrderKey } from '../deep-link/actions';
import {
  getContexts,
  getCurrentContextOrderKey,
  getCurrentContextId,
  getDeepLinkState,
  getDefaultWeighingProcess,
  getLastOrder,
  getLoadingOperatorOrderKey,
  getOperatorTrailerKey,
  getPendingOrderMasterDataUpdates,
  getSelectedOperatorOrder,
  getSelectedUiRole,
  getSelectedVehicle,
  getUser,
  getWebsocketConnected,
  getMeasDeviceGroups,
  getEnabledFeatures,
} from '../selectors';
import { masterDataSelected } from '../master-data/actions';
import {
  MeasDeviceActionTypes,
  MeasDeviceGroup,
  MeasDeviceAvailability,
} from '../meas-devices/types';
import { effectiveUiRole } from '../utils';
import { UiRoles } from '../user/types';
import {
  selectMeasDeviceGroup,
  startWeighing,
  clearWeighingStartError,
} from '../meas-devices/actions';
import { parseStateType } from '../master-data/utils';
import { VehiclesActionTypes } from '../vehicles/types';
import { destroyContext, destroyContextWithId } from '../weighing/actions';
import { getManualDeductions } from '../jobs/utils';
import {
  OrderSearchResults,
  Context,
  ContextOwner,
  WeighingProcess,
  InProgressOrders,
  OperatorOrderMode,
  ListOrderQueryMode,
  OrdersActionTypes,
  OrdersResponsePayload,
  ContextStatusResponsePayload,
  OperatorOrder,
  OperatorOrderTab,
  OrderInfoResponsePayload,
  OrderStatus,
  SelectOrderCleanup,
} from '../orders/types';
import {
  addSplit,
  contextsChanged,
  copyTruckToTrailer,
  createContext,
  createContextFailed,
  currentContextChanged,
  deleteSplit,
  destroySelectedOperatorContext,
  disableSplitLoad,
  discardContextOrder,
  doneEditingMasterData,
  enableSplitLoad,
  listOrders,
  listOrdersSuccess,
  selectOperatorOrder,
  selectedOperatorOrder,
  setContextComment,
  setContextManualDeductions,
  setContextProcess,
  setContextTrailerKey,
  setLoadingOperatorOrderKey,
  setSplitAmount,
  reloadOrderInfo,
  requestExternalIdUpdate,
  requestMasterDataUpdate,
  setCurrentContextOrderKey,
  fetchInProgressOrders,
  inProgressOrderSuccess,
  loadOrderInfoRequest,
  showOrderInfo,
  setOperatorJobFilter,
  setOperatorOrderTab,
  setJobDirection,
  editTypableMasterData,
  searchOrder,
  searchOrderSuccess,
} from './actions';

import { getOrderTruck, getNormalWeighingMainComponents } from './utils';

function* handleAddSplit(action: ReturnType<typeof addSplit>) {
  const payload = {
    msgType: RemoteRequestTypes.ADD_SPLIT,
    reqId: RemoteRequestIds.ADD_SPLIT,
    payload: {
      contextId: action.payload.contextId,
      componentGroup: action.payload.componentGroup,
    },
  };
  const connected: boolean = yield select(getWebsocketConnected);
  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleDeleteSplit(action: ReturnType<typeof deleteSplit>) {
  const payload = {
    msgType: RemoteRequestTypes.DELETE_SPLIT,
    reqId: RemoteRequestIds.DELETE_SPLIT,
    payload: {
      contextId: action.payload.contextId,
      componentId: action.payload.componentId,
    },
  };
  const connected: boolean = yield select(getWebsocketConnected);
  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleDisableSplitLoad(action: ReturnType<typeof disableSplitLoad>) {
  const payload = {
    msgType: RemoteRequestTypes.DISABLE_SPLIT_LOAD,
    reqId: RemoteRequestIds.DISABLE_SPLIT_LOAD,
    payload: {
      contextId: action.payload.contextId,
      componentGroup: action.payload.componentGroup,
    },
  };
  const connected: boolean = yield select(getWebsocketConnected);
  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleEnableSplitLoad(action: ReturnType<typeof enableSplitLoad>) {
  const payload = {
    msgType: RemoteRequestTypes.ENABLE_SPLIT_LOAD,
    reqId: RemoteRequestIds.ENABLE_SPLIT_LOAD,
    payload: {
      contextId: action.payload.contextId,
      componentGroup: action.payload.componentGroup,
    },
  };
  const connected: boolean = yield select(getWebsocketConnected);
  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleSetSplitAmount(action: ReturnType<typeof setSplitAmount>) {
  const payload = {
    msgType: RemoteRequestTypes.SET_SPLIT_AMOUNT,
    reqId: RemoteRequestIds.SET_SPLIT_AMOUNT,
    payload: {
      contextId: action.payload.contextId,
      componentId: action.payload.componentId,
      splitAmount: action.payload.splitAmount,
    },
  };
  const connected: boolean = yield select(getWebsocketConnected);
  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleDoneEditingMasterData(action: ReturnType<typeof doneEditingMasterData>): any {
  const pendingMasterDataUpdates = yield select(getPendingOrderMasterDataUpdates);
  // NOTE(mikkogy,20191010) if we wanted to skip update in case the same master
  // data item is already set to order we could check existing linkedData items
  // in the order.
  const order = yield select(getLastOrder);
  const componentIds = Object.keys(pendingMasterDataUpdates);
  for (const componentIdIndex in componentIds) {
    const componentId = componentIds[componentIdIndex];
    const types = Object.keys(pendingMasterDataUpdates[componentId]);
    for (const typeIndex in types) {
      const stateType = types[typeIndex];
      const masterDataDoc = pendingMasterDataUpdates[componentId][stateType];
      const masterDataKey = masterDataDoc?.key;
      const updateDoc = masterDataKey ? masterDataDoc : null;
      const editedType = parseStateType(stateType);
      yield put(requestMasterDataUpdate(order.key, componentId, editedType, updateDoc));
    }
  }
}

function* handleCopyTruckToTrailer(action: ReturnType<typeof copyTruckToTrailer>) {
  const payload = {
    msgType: RemoteRequestTypes.COPY_TRUCK_MASTER_DATA_TO_TRAILER,
    reqId: RemoteRequestIds.COPY_TRUCK_MASTER_DATA_TO_TRAILER,
    payload: {
      contextId: action.payload,
    },
  };
  const connected: boolean = yield select(getWebsocketConnected);
  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleDestroySelectedOperatorContext(
  action: ReturnType<typeof destroySelectedOperatorContext>,
) {
  const selected: OperatorOrder = yield select(getSelectedOperatorOrder);
  const contextId = selected?.context?.contextId;
  if (contextId) {
    yield put(destroyContextWithId(contextId));
    yield put(setOperatorOrderTab(OperatorOrderTab.Order));
  }
}

function* handleRequestExternalIdUpdate(action: ReturnType<typeof requestExternalIdUpdate>): any {
  const payload = {
    msgType: RemoteRequestTypes.UPDATE_ORDER_PROPERTIES,
    reqId: RemoteRequestIds.UPDATE_ORDER_PROPERTIES,
    payload: {
      orderKey: action.payload.orderKey,
      properties: { externalId: action.payload.externalId },
    },
  };
  const connected = yield select(getWebsocketConnected);

  // If the connection to websocket is not yet up, wait for it
  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleRequestMasterDataUpdate(action: ReturnType<typeof requestMasterDataUpdate>): any {
  const masterData = action.payload.masterData;
  const masterDataKey = masterData ? masterData.key : '';
  const fullType = action.payload.masterDataType;
  const masterDataType = fullType.type;
  if (masterData) {
    yield put(masterDataSelected(fullType, masterData));
    if (fullType.type === RemoteMasterDataType.MATERIAL) {
      const contextId = yield select(getCurrentContextId);
      const contexts = yield select(getContexts);
      const currentContext = contexts[contextId];
      if (
        currentContext &&
        currentContext.process === WeighingProcess.NORMAL &&
        currentContext.order
      ) {
        let updateContainerType: ContainerType | undefined = undefined;
        const components = getNormalWeighingMainComponents(currentContext.order);
        if (action.payload.componentId === components.truck.id) {
          updateContainerType = ContainerType.TRUCK;
        } else if (action.payload.componentId === components.trailer?.id) {
          updateContainerType = ContainerType.TRAILER;
        }
        const jobs = currentContext.weighingJobs;
        const manualDeductions = getManualDeductions(jobs);
        if (
          manualDeductions.some((deduction) => {
            return (
              deduction.material?.key === masterData.key &&
              updateContainerType !== undefined &&
              updateContainerType === deduction.containerType
            );
          })
        ) {
          yield put(newToast('orders.materialUsedInDeductionsChanged', ToastType.DEFAULT));
        }
      }
    }
  }
  const payload = {
    msgType: RemoteRequestTypes.UPDATE_ORDER_MASTER_DATA,
    reqId: RemoteRequestIds.UPDATE_ORDER_MASTER_DATA,
    payload: {
      orderKey: action.payload.orderKey,
      componentId: action.payload.componentId,
      masterDataType,
      masterDataKey,
    },
  };

  const connected = yield select(getWebsocketConnected);

  // If the connection to websocket is not yet up, wait for it
  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleSetContextComment(action: ReturnType<typeof setContextComment>): any {
  const payload = {
    msgType: RemoteRequestTypes.SET_COMMENT,
    reqId: RemoteRequestIds.SET_COMMENT,
    payload: {
      contextId: action.payload.contextId,
      comment: action.payload.comment,
      weighingJobKey: action.payload.weighingJobKey,
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleSetContextProcess(action: ReturnType<typeof setContextProcess>): any {
  const payload = {
    msgType: RemoteRequestTypes.SET_PROCESS,
    reqId: RemoteRequestIds.SET_PROCESS,
    payload: {
      contextId: action.payload.contextId,
      process: action.payload.process,
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleSetContextTrailerKey(action: ReturnType<typeof setContextTrailerKey>): any {
  const payload = {
    msgType: RemoteRequestTypes.SET_TRAILER,
    reqId: RemoteRequestIds.SET_TRAILER,
    payload: {
      contextId: action.payload.contextId,
      trailerKey: action.payload.trailerKey,
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleSetJobDirection(action: ReturnType<typeof setJobDirection>): any {
  const payload = {
    msgType: RemoteRequestTypes.UPDATE_ORDER_JOB_DIRECTION,
    reqId: RemoteRequestIds.UPDATE_ORDER_JOB_DIRECTION,
    payload: {
      orderKey: action.payload.orderKey,
      componentId: action.payload.componentId,
      jobDirection: action.payload.jobDirection,
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}
function* handleSetTypableMasterData(action: ReturnType<typeof editTypableMasterData>): any {
  const payload = {
    msgType: RemoteRequestTypes.UPDATE_ORDER_TYPABLE_MASTER_DATA,
    reqId: RemoteRequestIds.UPDATE_ORDER_TYPABLE_MASTER_DATA,
    payload: {
      orderKey: action.payload.orderKey,
      componentId: action.payload.componentId,
      masterDataType: action.payload.masterDataType,
      typableValue: action.payload.typableValue,
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleSetContextManualDeductions(
  action: ReturnType<typeof setContextManualDeductions>,
): any {
  const payload = {
    msgType: RemoteRequestTypes.SET_MANUAL_DEDUCTIONS,
    reqId: RemoteRequestIds.SET_MANUAL_DEDUCTIONS,
    payload: {
      contextId: action.payload.contextId,
      deductions: action.payload.deductions,
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* pollInProgressOrders() {
  try {
    console.log('Starting polling in progress orders');
    while (true) {
      yield put(fetchInProgressOrders());
      const delayS = `${process.env.REACT_APP_IN_PROGRESS_ORDER_POLL_INTERVAL_S || '60'}`;
      yield delay(parseInt(delayS, 10) * 1000);
    }
  } finally {
    console.log('Stopped polling in progress orders');
  }
}

function* handleFetchInProgressOrders(): any {
  const payload = {
    msgType: RemoteRequestTypes.LIST_IN_PROGRESS_ORDERS,
    reqId: RemoteRequestIds.LIST_IN_PROGRESS_ORDERS,
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleListOrder(action: ReturnType<typeof listOrders>): any {
  const pagination = action.payload.pagination;
  const mode = action.payload.status;
  const payload = {
    msgType: RemoteRequestTypes.LIST_ORDERS,
    reqId: RemoteRequestIds.LIST_ORDERS,
    payload: {
      pagination,
      mode,
    },
  };
  const connected = yield select(getWebsocketConnected);

  // If the connection to websocket is not yet up, wait for it
  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleLoadOrderInfo(action: ReturnType<typeof loadOrderInfoRequest>): any {
  const payload = {
    msgType: RemoteRequestTypes.LOAD_ORDER_INFO,
    reqId: RemoteRequestIds.LOAD_ORDER_INFO,
    payload: {
      orderKey: action.payload,
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleSelectOperatorOrder(action: ReturnType<typeof selectOperatorOrder>): any {
  if (action.payload.selectOrderCleanup === SelectOrderCleanup.DESTROY_PREVIOUS) {
    yield put(destroySelectedOperatorContext());
  }
  yield put(selectedOperatorOrder({ ...action.payload.operatorOrder }));
}

function* handleShowOrderInfo(action: ReturnType<typeof showOrderInfo>): any {
  // NOTE(mikkogy,20220607) simplify loading and rendering new order info by
  // getting rid of existing data. Otherwise we would have to ensure all inputs
  // support replacing data on the fly which makes bad UX if multiple users make
  // changes simultaneously.
  const cleanup =
    action.payload.mode === OperatorOrderMode.Weighing
      ? SelectOrderCleanup.DESTROY_PREVIOUS
      : SelectOrderCleanup.IGNORE;
  yield put(
    selectOperatorOrder(
      {
        context: undefined,
        order: undefined,
        process: WeighingProcess.UNDEFINED,
        weighingJobs: [],
      },
      cleanup,
    ),
  );
  yield put(setOperatorJobFilter(action.payload.jobFilter));
  yield put(setOperatorOrderTab(action.payload.operatorOrderTab));
  if (action.payload.mode === OperatorOrderMode.Weighing) {
    const operatorTrailerKey = yield select(getOperatorTrailerKey);
    yield put(createContext('', action.payload.orderKey, operatorTrailerKey, true));
  }
  yield put(setLoadingOperatorOrderKey(action.payload.orderKey));
  yield put(loadOrderInfoRequest(action.payload.orderKey));
}

function* handleOrdersResponse(response: OrdersResponsePayload) {
  const orders = response.orders;
  yield put(listOrdersSuccess(orders, response.pagination, response.mode));
  return;
}

function* handleContextCreated(response: Context) {
  let jobs = response.weighingJobs;
  if (!jobs) {
    jobs = [];
  }
  // NOTE(mikkogy,20220607) in case of creating an order we know at this point
  // that we can get rid of loadingOperatorOrderKey. When loading info for a
  // SERVER_MANUAL closePolicy a.k.a continuous order the order will never get
  // any jobs which is problematic in terms of reloading order info.
  yield put(setLoadingOperatorOrderKey(''));
  yield put(
    selectOperatorOrder(
      {
        context: response,
        order: response.order,
        process: response.process,
        weighingJobs: jobs,
      },
      SelectOrderCleanup.IGNORE,
    ),
  );
}

function* onContextsChanged(): any {
  let contextId = '';
  const contexts = yield select(getContexts) || {};
  const keys = Object.keys(contexts);
  const orderKey = yield select(getCurrentContextOrderKey);
  const hadOrderKey = !!orderKey;
  if (keys.length > 0) {
    if (orderKey) {
      for (const index in keys) {
        if (contexts[keys[index]].orderKey === orderKey) {
          contextId = keys[index];
          break;
        }
      }
    }
  }
  const user = yield select(getUser);
  const effectiveRole = effectiveUiRole(
    yield select(getSelectedUiRole),
    user,
    yield select(getEnabledFeatures),
  );
  const isDriverUi = effectiveRole === UiRoles.DRIVER;

  if (contextId) {
    yield put(currentContextChanged(contextId));
    // NOTE(mikkogy,20210121) if truck is removed from order and role is DRIVER,
    // we are basically in a dead end with the order. We can't weigh or most
    // likely even change data as at the time of writing this can only happen
    // with an online order. So it's best just to destroy the context to get
    // back to order selection.
    if (isDriverUi) {
      if (contexts[contextId] && contexts[contextId].order) {
        const order = contexts[contextId].order;
        if (order.components.length > 0) {
          const truckComponent = getNormalWeighingMainComponents(order).truck;
          const links: string[] = truckComponent.dataLinks ?? [];
          const truckLink = links.find((l) => {
            const linked = (order.linkedData || []).find(
              (linked: MasterDataItem) => linked.key === l,
            );
            return (
              !!linked && !!linked.containerType && linked.containerType === ContainerType.TRUCK
            );
          });
          if (!truckLink) {
            yield put(destroyContext());
          }
        }
      }
    }
  }
  if (!contextId && hadOrderKey) {
    yield put(currentContextChanged(undefined));
    yield put(setCurrentContextOrderKey(undefined));

    const context: ToastContext = {};
    context[UiRoles.OPERATOR as string] = [];
    yield put(newToast('orders.currentOrderDisappeared', ToastType.DEFAULT, context));
  }
  if (!contextId && !hadOrderKey && isDriverUi) {
    const selectedVehicle = yield select(getSelectedVehicle);
    // NOTE(mikkogy,20210609) force UI to existing context when user owns the
    // context and currently selected truck matches. Without forcing user has
    // no way to open the context again as creating context with the same order
    // fails.
    for (const index in keys) {
      const context = contexts[keys[index]];
      const ownerKeys = (context?.owners || []).map((owner: ContextOwner) => owner.userKey);
      const contextOrderTruck = context?.order ? getOrderTruck(context.order) : undefined;
      const isUsingSelectedTruck =
        !!selectedVehicle && !!contextOrderTruck && selectedVehicle.key === contextOrderTruck.key;
      if (ownerKeys.includes(user.userData.key) && isUsingSelectedTruck) {
        yield put(currentContextChanged(context.contextId));
        yield put(setCurrentContextOrderKey(context.orderKey));
        return;
      }
    }
  }
  const operatorOrder = yield select(getSelectedOperatorOrder);
  if (operatorOrder && operatorOrder.context) {
    const updatedContext = contexts[operatorOrder.context.contextId];
    if (updatedContext) {
      yield call(handleContextCreated, updatedContext);
    } else {
      yield put(
        selectOperatorOrder(
          {
            context: undefined,
            order: undefined,
            process: WeighingProcess.UNDEFINED,
            weighingJobs: [],
          },
          SelectOrderCleanup.IGNORE,
        ),
      );
    }
  }
}

function* handleContextStatusResponse(response: ContextStatusResponsePayload) {
  yield put(contextsChanged(response.status));
  yield call(onContextsChanged);
}

function* handleSelectMeasDeviceGroup(): any {
  const order = yield select(getLastOrder);
  if (order) {
    const measDeviceGroups: MeasDeviceGroup[] = yield select(getMeasDeviceGroups);
    const applicableGroups = measDeviceGroups.filter(
      (group) => !group.isManual && !group.isHiddenFromDriverUi,
    );
    if (
      applicableGroups &&
      applicableGroups.length === 1 &&
      applicableGroups[0].availability === MeasDeviceAvailability.Available
    ) {
      yield put(clearWeighingStartError());
      yield put(selectMeasDeviceGroup(applicableGroups[0].id));
      yield put(startWeighing());
      const returnAction = yield take([
        MeasDeviceActionTypes.STARTED_WEIGHING,
        MeasDeviceActionTypes.FAILED_TO_START_WEIGHING,
      ]);
      if (returnAction.type === MeasDeviceActionTypes.FAILED_TO_START_WEIGHING) {
        yield put(newToast('order.common.weighingStartError'));
      }
      return;
    }
    yield put(push(RoutePaths.MEAS_DEVICES));
  }
}

function* handleOrderInfoResponse(response: OrderInfoResponsePayload): any {
  const contexts = yield select(getContexts);
  const orderKey = response.order.key;
  const currentContextOrderKey = yield select(getCurrentContextOrderKey);
  if (orderKey !== currentContextOrderKey && currentContextOrderKey) {
    // NOTE(mikkogy,20210512) current order has changed so must not show
    // previously requested data.
    return;
  }
  let orderContext;
  if (contexts) {
    const contextKeys = Object.keys(contexts);
    for (const i in contextKeys) {
      const context = contexts[contextKeys[i]];
      if (context.orderKey === orderKey) {
        orderContext = context;
        break;
      }
    }
  }
  yield put(setLoadingOperatorOrderKey(''));
  yield put(
    selectOperatorOrder(
      {
        context: orderContext,
        order: response.order,
        process: orderContext?.process ?? response.process,
        weighingJobs: response.weighingJobs,
      },
      SelectOrderCleanup.IGNORE,
    ),
  );
}

function* handleReloadOrderInfo(action: ReturnType<typeof reloadOrderInfo>) {
  yield delay(2000);
  yield put(loadOrderInfoRequest(action.payload));
}

function* handleResponse(action: ReturnType<typeof incomingEvent>): any {
  if (action.payload.msgType === RemoteReceiveTypes.ORDER_LIST) {
    const response: OrdersResponsePayload = action.payload.payload;
    yield call(handleOrdersResponse, response);
  } else if (action.payload.msgType === RemoteReceiveTypes.IN_PROGRESS_ORDER_LIST) {
    const orders: InProgressOrders = action.payload.payload;
    yield put(inProgressOrderSuccess(orders));
  } else if (action.payload.msgType === RemoteReceiveTypes.CONTEXT_STATUS) {
    const response: ContextStatusResponsePayload = action.payload.payload;
    yield call(handleContextStatusResponse, response);
  } else if (action.payload.msgType === RemoteReceiveTypes.CONTEXT_CREATED) {
    const response: Context = action.payload.payload;
    if (action.payload.respId === RemoteRequestIds.CREATE_CONTEXT) {
      yield put(setCurrentContextOrderKey(response.orderKey));
      const deepLinkState = yield select(getDeepLinkState);
      if (
        deepLinkState.isDeepLinkViewVisible &&
        response.orderKey &&
        deepLinkState.orderKey !== response.orderKey
      ) {
        // NOTE(mikkogy,20220324) in case of SERVER_MANUAL order the order in
        // the context will be a copy of the original.
        yield put(setDeepLinkOrderKey(response.orderKey));
      }
      yield call(handleContextCreated, response);
    }
  } else if (action.payload.msgType === RemoteReceiveTypes.ORDER_INFO) {
    const response: OrderInfoResponsePayload = action.payload.payload;
    if (
      response.order &&
      (response?.weighingJobs?.length > 0 || response.order.status === OrderStatus.DISCARDED)
    ) {
      yield call(handleOrderInfoResponse, response);
    } else {
      const loadingOperatorOrderKey = yield select(getLoadingOperatorOrderKey);
      if (loadingOperatorOrderKey) {
        yield put(reloadOrderInfo(loadingOperatorOrderKey));
      }
    }
  } else if (action.payload.msgType === RemoteReceiveTypes.ORDER_SEARCH_RESULTS) {
    const text = action.payload.payload.request.text;
    yield put(searchOrderSuccess(action.payload.payload.orders, text));
  } else if (action.payload.msgType === RemoteReceiveTypes.ACK) {
    if (action.payload.respId === RemoteRequestIds.DISCARD_CONTEXT_ORDER) {
      yield put(currentContextChanged(undefined));
      yield put(setCurrentContextOrderKey(undefined));
    }
  } else if (action.payload.msgType === RemoteReceiveTypes.NACK) {
    if (action.payload.respId === RemoteRequestIds.CREATE_CONTEXT) {
      yield put(createContextFailed());
      yield call(onContextsChanged);
    } else if (action.payload.respId === RemoteRequestIds.DISCARD_CONTEXT_ORDER) {
      yield put(newToast('orders.discardOrderFailed'));
    } else if (action.payload.respId === RemoteRequestIds.LOAD_ORDER_INFO) {
      yield put(setLoadingOperatorOrderKey(''));
      yield put(newToast('orders.loadOrderInfoFailed'));
      yield put(push(RoutePaths.DASHBOARD));
    }
  } else if (
    action.payload.msgType === RemoteReceiveTypes.TRUCK_DEFAULT_SELECTIONS_APPLIED_FROM_VEHICLE
  ) {
    yield put(newToast('orders.truckDefaultSelectionsAppliedFromVehicle'));
  }
}

function* handleCreateContext(action: ReturnType<typeof createContext>): any {
  const defaultWeighingProcess = yield select(getDefaultWeighingProcess);
  const payload = {
    msgType: RemoteRequestTypes.CREATE_CONTEXT,
    reqId: RemoteRequestIds.CREATE_CONTEXT,
    payload: {
      containerKey: action.payload.containerKey,
      orderKey: action.payload.orderKey,
      process: defaultWeighingProcess,
      trailerKey: action.payload.trailerKey,
      keepExistingTrailer: action.payload.keepExistingTrailer,
    },
  };
  const connected = yield select(getWebsocketConnected);

  // If the connection to websocket is not yet up, wait for it
  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleCreateContextFailed(action: ReturnType<typeof createContextFailed>) {
  yield put(newToast('orders.createContextFailed'));
}

function* handleDiscardContextOrder(action: ReturnType<typeof discardContextOrder>): any {
  const payload = {
    msgType: RemoteRequestTypes.DISCARD_CONTEXT_ORDER,
    reqId: RemoteRequestIds.DISCARD_CONTEXT_ORDER,
    payload: {
      contextId: action.payload,
    },
  };
  const connected = yield select(getWebsocketConnected);

  // If the connection to websocket is not yet up, wait for it
  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleEndViewingOrder() {
  yield put(replace(RoutePaths.ORDERS));
}

function* watchAddSplit() {
  yield takeLatest(OrdersActionTypes.ADD_SPLIT, handleAddSplit);
}

function* watchDeleteSplit() {
  yield takeLatest(OrdersActionTypes.DELETE_SPLIT, handleDeleteSplit);
}

function* watchDisableSplitLoad() {
  yield takeLatest(OrdersActionTypes.DISABLE_SPLIT_LOAD, handleDisableSplitLoad);
}

function* watchEnableSplitLoad() {
  yield takeLatest(OrdersActionTypes.ENABLE_SPLIT_LOAD, handleEnableSplitLoad);
}

function* watchSetSplitAmount() {
  yield debounce(300, OrdersActionTypes.SET_SPLIT_AMOUNT, handleSetSplitAmount);
}

function* watchDoneEditingMasterData() {
  yield takeLatest(OrdersActionTypes.DONE_EDITING_MASTER_DATA, handleDoneEditingMasterData);
}

function* watchCopyTruckToTrailer() {
  yield debounce(300, OrdersActionTypes.COPY_TRUCK_TO_TRAILER, handleCopyTruckToTrailer);
}

function* watchDestroySelectedOperatorContext() {
  yield takeLatest(
    OrdersActionTypes.DESTROY_SELECTED_OPERATOR_CONTEXT,
    handleDestroySelectedOperatorContext,
  );
}

function* watchUpdateExternalId() {
  yield throttle(1000, OrdersActionTypes.REQUEST_EXTERNAL_ID_UPDATE, handleRequestExternalIdUpdate);
}

function* watchUpdateMasterData() {
  yield takeLatest(OrdersActionTypes.REQUEST_MASTER_DATA_UPDATE, handleRequestMasterDataUpdate);
}

function* watchQuery() {
  yield takeEvery(OrdersActionTypes.LIST_ORDER, handleListOrder);
}

function* watchCreateContext() {
  yield takeLatest(OrdersActionTypes.CREATE_CONTEXT, handleCreateContext);
}

function* watchCreateContextFailed() {
  yield takeLatest(OrdersActionTypes.CREATE_CONTEXT_FAILED, handleCreateContextFailed);
}

function* watchDiscardContextOrder() {
  yield takeLatest(OrdersActionTypes.DISCARD_CONTEXT_ORDER, handleDiscardContextOrder);
}

function* watchOrdersDataReceived() {
  yield takeLatest(LiveDataTypes.INCOMING_EVENT, handleResponse);
}

function* watchStartWeighing() {
  yield takeLatest(OrdersActionTypes.SELECT_MEAS_DEVICE_GROUP, handleSelectMeasDeviceGroup);
}

function* watchEndViewingOrder() {
  yield takeLatest(OrdersActionTypes.END_VIEWING_ORDER, handleEndViewingOrder);
}

function* watchSetContextComment() {
  yield throttle(10000, OrdersActionTypes.SET_CONTEXT_COMMENT, handleSetContextComment);
}

function* watchSetContextCommentImmediately() {
  yield takeLatest(OrdersActionTypes.SET_CONTEXT_COMMENT_IMMEDIATELY, handleSetContextComment);
}

function* watchSetContextManualDeductions() {
  yield takeLatest(
    OrdersActionTypes.SET_CONTEXT_MANUAL_DEDUCTIONS,
    handleSetContextManualDeductions,
  );
}

function* watchSetContextProcess() {
  yield takeLatest(OrdersActionTypes.SET_CONTEXT_PROCESS, handleSetContextProcess);
}

function* watchSetContextTrailerKey() {
  yield takeLatest(OrdersActionTypes.SET_CONTEXT_TRAILER_KEY, handleSetContextTrailerKey);
}

function* watchSetJobDirection() {
  yield takeLatest(OrdersActionTypes.UPDATE_ORDER_JOB_DIRECTION, handleSetJobDirection);
}

function* watchUpdateTypableMasterDataItem() {
  yield debounce(
    1000,
    OrdersActionTypes.UPDATE_ORDER_TYPABLE_MASTER_DATA,
    handleSetTypableMasterData,
  );
}
function* watchUpdateTypableMasterDataItemImmediately() {
  yield takeLatest(
    OrdersActionTypes.UPDATE_ORDER_TYPABLE_MASTER_DATA_IMMEDIATELY,
    handleSetTypableMasterData,
  );
}
let isPollingInProgressOrders = false;
function* watchStartInProgressOrderPoll(): any {
  while (yield take(OrdersActionTypes.START_IN_PROGRESS_ORDER_POLL)) {
    isPollingInProgressOrders = true;
    const task = yield fork(pollInProgressOrders);
    yield take(OrdersActionTypes.STOP_IN_PROGRESS_ORDER_POLL);
    yield cancel(task);
    isPollingInProgressOrders = false;
  }
}

function* watchFetchInProgressOrders() {
  yield takeLatest(OrdersActionTypes.FETCH_IN_PROGRESS_ORDERS, handleFetchInProgressOrders);
}

function* watchLoadOrderInfo() {
  yield takeLatest(OrdersActionTypes.LOAD_ORDER_INFO_REQUEST, handleLoadOrderInfo);
}

function* watchReloadOrderInfo() {
  yield takeLatest(OrdersActionTypes.RELOAD_ORDER_INFO, handleReloadOrderInfo);
}

function* watchSelectOperatorOrder() {
  yield takeLatest(OrdersActionTypes.SELECT_OPERATOR_ORDER, handleSelectOperatorOrder);
}

function* watchShowOrderInfo() {
  yield takeLatest(OrdersActionTypes.SHOW_ORDER_INFO, handleShowOrderInfo);
}

function* watchMeasDeviceList(): any {
  while (yield take(MeasDeviceActionTypes.RECEIVE_LIST_SUCCESS)) {
    if (isPollingInProgressOrders) {
      yield put(fetchInProgressOrders());
    }
  }
}

function* handleSearch(action: ReturnType<typeof searchOrder>): any {
  const mode: ListOrderQueryMode = ListOrderQueryMode.OPEN_BY_ACCEPTANCE;
  const payload = {
    msgType: RemoteRequestTypes.SEARCH_ORDER,
    reqId: RemoteRequestIds.SEARCH_ORDER,
    payload: {
      maxResultCount: OrderSearchResults + 1,
      text: action.payload.text,
      mode: mode,
      vehicleKey: action.payload.vehicleKey ?? '',
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* delaySearch(action: ReturnType<typeof searchOrder>) {
  yield delay(500);
  yield call(handleSearch, action);
}

function* watchSearch(): any {
  let task;
  while (true) {
    const action = yield take(OrdersActionTypes.SEARCH_REQUEST);
    if (task) {
      yield cancel(task);
    }
    task = yield fork(delaySearch, action);
  }
}

function* watchVehicleSelected() {
  yield takeLatest(VehiclesActionTypes.SELECTED, onContextsChanged);
}

function* ordersSaga() {
  yield all([
    fork(watchAddSplit),
    fork(watchDeleteSplit),
    fork(watchDoneEditingMasterData),
    fork(watchDisableSplitLoad),
    fork(watchEnableSplitLoad),
    fork(watchUpdateExternalId),
    fork(watchUpdateMasterData),
    fork(watchQuery),
    fork(watchCreateContext),
    fork(watchCreateContextFailed),
    fork(watchDestroySelectedOperatorContext),
    fork(watchDiscardContextOrder),
    fork(watchOrdersDataReceived),
    fork(watchStartWeighing),
    fork(watchEndViewingOrder),
    fork(watchReloadOrderInfo),
    fork(watchSetContextComment),
    fork(watchSetContextCommentImmediately),
    fork(watchSetContextManualDeductions),
    fork(watchSetContextProcess),
    fork(watchSetContextTrailerKey),
    fork(watchStartInProgressOrderPoll),
    fork(watchFetchInProgressOrders),
    fork(watchMeasDeviceList),
    fork(watchLoadOrderInfo),
    fork(watchSelectOperatorOrder),
    fork(watchSetSplitAmount),
    fork(watchShowOrderInfo),
    fork(watchVehicleSelected),
    fork(watchSearch),
    fork(watchSetJobDirection),
    fork(watchUpdateTypableMasterDataItem),
    fork(watchUpdateTypableMasterDataItemImmediately),
    fork(watchCopyTruckToTrailer),
  ]);
}

export default ordersSaga;
