import React from 'react';
import { RouteComponentProps } from 'react-router';

import { connect, localeConnect } from 'localeConnect';
import { ApplicationState, ConnectedReduxProps } from 'store';
import { translate } from 'utils/translate';

import OperatorContainer from './OperatorContainer';
import OperatorQueryJobs from './OperatorQueryJobs';

interface PropsFromState {
  scaleKey: string;
}

interface PropsFromDispatch {}

/**
 * NOTE(mikkogy, 20200221) There's a bug in Eslint config that has been reported but not yet fixed
 * which complains about missing indentation when multiline binary expressions are used.
 * See: https://github.com/typescript-eslint/typescript-eslint/issues/398
 */
/*eslint-disable */
export type AllProps = PropsFromDispatch &
  PropsFromState &
  RouteComponentProps<{}> &
  ConnectedReduxProps;
/*eslint-enable */

const mapStateToProps = (state: ApplicationState) => ({
  scaleKey: state.currentScaleInfo.scaleKey,
});

const mapDispatchToProps = {};

class OperatorJobsWrapper extends React.PureComponent<AllProps, Record<string, never>> {
  public constructor(props: AllProps) {
    super(props);
  }

  public render() {
    return (
      <OperatorContainer
        title={translate('operatorJobs.title')}
        title2={translate('operatorJobs.title2')}
      >
        {this.props.scaleKey && <OperatorQueryJobs />}
      </OperatorContainer>
    );
  }
}

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(OperatorJobsWrapper);
