import { action } from 'typesafe-actions';
import {
  DetailedMasterDataType,
  Pagination,
  ServerPagination,
  AllowedMasterDataTypes,
} from '../common/types';
import { Deduction } from '../jobs/types';
import { GenericMasterData } from '../master-data/types';
import {
  ComponentGroup,
  Contexts,
  WeighingProcess,
  EditedDeductions,
  InspectorOrderTab,
  InProgressOrders,
  PendingMasterDataUpdate,
  OperatorOrder,
  OperatorOrderMode,
  OperatorOrderTab,
  Order,
  OrdersActionTypes,
  ListOrderQueryMode,
  SelectOrderCleanup,
} from './types';

export const createContext = (
  containerKey: string,
  orderKey: string,
  trailerKey: string | undefined,
  keepExistingTrailer: boolean,
) =>
  action(OrdersActionTypes.CREATE_CONTEXT, {
    containerKey,
    orderKey,
    process,
    trailerKey,
    keepExistingTrailer,
  });
export const createContextFailed = () => action(OrdersActionTypes.CREATE_CONTEXT_FAILED);
export const destroyContextHandled = () => action(OrdersActionTypes.DESTROY_CONTEXT_HANDLED);
export const orderSelected = (orderId: string) => action(OrdersActionTypes.SELECTED, orderId);
export const contextsChanged = (contexts: Contexts) =>
  action(OrdersActionTypes.CONTEXTS_CHANGED, contexts);
export const currentContextChanged = (contextId?: string) =>
  action(OrdersActionTypes.CURRENT_CONTEXT_CHANGED, contextId);
export const selectMeasDeviceGroup = () => action(OrdersActionTypes.SELECT_MEAS_DEVICE_GROUP);
export const endViewingOrder = () => action(OrdersActionTypes.END_VIEWING_ORDER);
export const editMasterData = (
  componentId: string,
  masterDataType: DetailedMasterDataType,
  masterDataTitle: string,
) => action(OrdersActionTypes.EDIT_MASTER_DATA, { componentId, masterDataType, masterDataTitle });
export const cancelEditingMasterData = () => action(OrdersActionTypes.CANCEL_EDITING_MASTER_DATA);
export const setContextComment = (contextId: string, comment: string, weighingJobKey: string) =>
  action(OrdersActionTypes.SET_CONTEXT_COMMENT, { contextId, comment, weighingJobKey });
export const setContextCommentImmediately = (
  contextId: string,
  comment: string,
  weighingJobKey: string,
) =>
  action(OrdersActionTypes.SET_CONTEXT_COMMENT_IMMEDIATELY, { contextId, comment, weighingJobKey });
export const setJobDirection = (orderKey: string, componentId: string, jobDirection: string) =>
  action(OrdersActionTypes.UPDATE_ORDER_JOB_DIRECTION, {
    orderKey,
    componentId,
    jobDirection,
  });
export const setPendingMasterDataUpdate = (
  componentId: string,
  masterDataType: DetailedMasterDataType,
  pendingUpdate: PendingMasterDataUpdate,
) =>
  action(OrdersActionTypes.SET_PENDING_MASTER_DATA_UPDATE, {
    componentId,
    masterDataType,
    pendingUpdate,
  });
export const doneEditingMasterData = () => action(OrdersActionTypes.DONE_EDITING_MASTER_DATA);
export const requestExternalIdUpdate = (orderKey: string, externalId: string) =>
  action(OrdersActionTypes.REQUEST_EXTERNAL_ID_UPDATE, { orderKey, externalId });
export const requestMasterDataUpdate = (
  orderKey: string,
  componentId: string,
  masterDataType: DetailedMasterDataType,
  masterData: GenericMasterData | null,
) =>
  action(OrdersActionTypes.REQUEST_MASTER_DATA_UPDATE, {
    componentId,
    masterDataType,
    orderKey,
    masterData,
  });
export const setCurrentContextOrderKey = (orderKey: string | undefined) =>
  action(OrdersActionTypes.SET_CURRENT_CONTEXT_ORDER_KEY, orderKey);
// Common
export const clearEditedMasterDataComponentId = () =>
  action(OrdersActionTypes.CLEAR_EDITED_MASTER_DATA_COMPONENT_ID);
export const setDefaultWeighingProcess = (process: WeighingProcess) =>
  action(OrdersActionTypes.SET_DEFAULT_WEIGHING_PROCESS, process);
export const setMultipartEditComponentId = (componentId: string) =>
  action(OrdersActionTypes.SET_MULTIPART_EDIT_COMPONENT_ID, componentId);
export const discardContextOrder = (contextId: string) =>
  action(OrdersActionTypes.DISCARD_CONTEXT_ORDER, contextId);
export const setContextProcess = (contextId: string, process: WeighingProcess) =>
  action(OrdersActionTypes.SET_CONTEXT_PROCESS, { contextId, process });
export const setContextTrailerKey = (contextId: string, trailerKey: string | undefined) =>
  action(OrdersActionTypes.SET_CONTEXT_TRAILER_KEY, { contextId, trailerKey });

export const addSplit = (contextId: string, componentGroup: ComponentGroup) =>
  action(OrdersActionTypes.ADD_SPLIT, { contextId, componentGroup });
export const deleteSplit = (contextId: string, componentId: string) =>
  action(OrdersActionTypes.DELETE_SPLIT, { contextId, componentId });
export const disableSplitLoad = (contextId: string, componentGroup: ComponentGroup) =>
  action(OrdersActionTypes.DISABLE_SPLIT_LOAD, { contextId, componentGroup });
export const enableSplitLoad = (contextId: string, componentGroup: ComponentGroup) =>
  action(OrdersActionTypes.ENABLE_SPLIT_LOAD, { contextId, componentGroup });
export const setSplitAmount = (contextId: string, componentId: string, splitAmount: number) =>
  action(OrdersActionTypes.SET_SPLIT_AMOUNT, { contextId, componentId, splitAmount });

// Load inspector
export const setEditedDeductions = (editedDeductions: EditedDeductions | undefined) =>
  action(OrdersActionTypes.SET_EDITED_DEDUCTIONS, editedDeductions);
// Operator
export const destroySelectedOperatorContext = () =>
  action(OrdersActionTypes.DESTROY_SELECTED_OPERATOR_CONTEXT);
export const listOrdersSuccess = (
  orders: Order[],
  pagination: ServerPagination,
  status: ListOrderQueryMode,
) => action(OrdersActionTypes.LIST_ORDERS_SUCCESS, { orders, pagination, status });
export const startInProgressOrderPoll = () =>
  action(OrdersActionTypes.START_IN_PROGRESS_ORDER_POLL);
export const stopInProgressOrderPoll = () => action(OrdersActionTypes.STOP_IN_PROGRESS_ORDER_POLL);
export const inProgressOrderSuccess = (orders: InProgressOrders) =>
  action(OrdersActionTypes.IN_PROGRESS_ORDER_SUCCESS, orders);
export const fetchInProgressOrders = () => action(OrdersActionTypes.FETCH_IN_PROGRESS_ORDERS);
export const selectOperatorOrder = (
  operatorOrder: OperatorOrder,
  selectOrderCleanup: SelectOrderCleanup,
) => action(OrdersActionTypes.SELECT_OPERATOR_ORDER, { operatorOrder, selectOrderCleanup });
export const selectedOperatorOrder = (operatorOrder: OperatorOrder) =>
  action(OrdersActionTypes.SELECTED_OPERATOR_ORDER, operatorOrder);
export const loadOrderInfoRequest = (orderKey: string) =>
  action(OrdersActionTypes.LOAD_ORDER_INFO_REQUEST, orderKey);
export const showOrderInfo = (
  orderKey: string,
  operatorOrderTab: OperatorOrderTab,
  mode: OperatorOrderMode,
  jobFilter?: string,
) => action(OrdersActionTypes.SHOW_ORDER_INFO, { orderKey, operatorOrderTab, mode, jobFilter });
export const setContextManualDeductions = (contextId: string, deductions: Deduction[]) =>
  action(OrdersActionTypes.SET_CONTEXT_MANUAL_DEDUCTIONS, { contextId, deductions });
export const reloadOrderInfo = (orderKey: string) =>
  action(OrdersActionTypes.RELOAD_ORDER_INFO, orderKey);
export const setInspectorOrderTab = (inspectorOrderTab: InspectorOrderTab) =>
  action(OrdersActionTypes.SET_INSPECTOR_ORDER_TAB, inspectorOrderTab);
export const setLoadingOperatorOrderKey = (orderKey: string) =>
  action(OrdersActionTypes.SET_LOADING_OPERATOR_ORDER_KEY, orderKey);
export const setOperatorJobFilter = (operatorJobFilter?: string) =>
  action(OrdersActionTypes.SET_OPERATOR_JOB_FILTER, operatorJobFilter);
export const setOperatorOrderTab = (operatorOrderTab: OperatorOrderTab) =>
  action(OrdersActionTypes.SET_OPERATOR_ORDER_TAB, operatorOrderTab);
export const listOrders = (pagination: Pagination, status: ListOrderQueryMode) =>
  action(OrdersActionTypes.LIST_ORDER, { pagination, status });
export const editTypableMasterData = (
  orderKey: string,
  componentId: string,
  masterDataType: AllowedMasterDataTypes,
  typableValue: string,
) =>
  action(OrdersActionTypes.UPDATE_ORDER_TYPABLE_MASTER_DATA, {
    orderKey,
    componentId,
    masterDataType,
    typableValue,
  });
export const editTypableMasterDataImmediately = (
  orderKey: string,
  componentId: string,
  masterDataType: AllowedMasterDataTypes,
  typableValue: string,
) =>
  action(OrdersActionTypes.UPDATE_ORDER_TYPABLE_MASTER_DATA_IMMEDIATELY, {
    orderKey,
    componentId,
    masterDataType,
    typableValue,
  });
export const copyTruckToTrailer = (contextId: string) =>
  action(OrdersActionTypes.COPY_TRUCK_TO_TRAILER, contextId);
export const searchOrder = (text: string, vehicleKey: string | undefined) =>
  action(OrdersActionTypes.SEARCH_REQUEST, { text, vehicleKey });
export const searchOrderSuccess = (results: Order[], text: string) =>
  action(OrdersActionTypes.SEARCH_ORDER_SUCCESS, { results, text });
