import React from 'react';

import { DeductionRow } from '../store/jobs/types';

import { areDeductionKgsValid } from '../store/jobs/utils';
import { translate } from '../utils/translate';

import { NonNegativeIntInput } from './NonNegativeIntInput';

interface Props {
  deduction: DeductionRow;
  setDeductionKgs: (kgs: number) => void;
}

const deductionKgsMin = 0;
// NOTE(mikkogy,20210217) upper limit is arbitrary but we need something to
// avoid excessively large values that can be problematic.
const deductionKgsMax = 300000;

const DeductionInput = (props: Props) => {
  const [inputKgs, setInputKgs] = React.useState(Math.abs(props.deduction.kgs));

  const setKgs = (kgs: number | undefined) => {
    if (kgs === undefined) return;
    setInputKgs(kgs);
    props.setDeductionKgs(kgs);
  };

  return (
    <NonNegativeIntInput
      allowUndefined={false}
      hideZero={true}
      isDisabled={false}
      isOutOfRangePrevented={false}
      showReset={false}
      label={translate('editDeductions.weightSelectionTitle')}
      initialValue={inputKgs}
      max={deductionKgsMax}
      min={deductionKgsMin}
      onChanged={setKgs}
      hasError={!areDeductionKgsValid(inputKgs)}
      updateInitialValue={true}
      useAutoFocus={false}
      useFullWidth={true}
    />
  );
};

export default DeductionInput;
