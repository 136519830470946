import { makeStyles } from '@mui/styles';
import React from 'react';
import { Box, Typography } from '@mui/material';

import theme from 'theme';
import { translate } from 'utils/translate';

const sharedContainerStyles = {
  textAlign: 'left',
  justifyContent: 'flex-end',
  display: 'flex',
  marginLeft: 'auto',
} as const;

const styles = makeStyles({
  containerForReceipt: {
    ...sharedContainerStyles,
    // eslint-disable-next-line
    width: '240px',
    // eslint-disable-next-line
    position: 'absolute',
    // eslint-disable-next-line
    right: '50px',
    // eslint-disable-next-line
    marginTop: '-40px',
    // eslint-disable-next-line
    backgroundColor: theme.palette.background.default,
    '@media print': {
      display: 'none',
    },
    '@media (max-width: 1440px)': {
      position: 'relative',
      marginTop: '0px',
      right: '0px',
    },
  },
  containerForWeighing: {
    ...sharedContainerStyles,
  },
  title: {
    fontWeight: 'bolder',
    fontSize: '16px',
  },
  newInstance: {
    color: '#0077C0',
    fontSize: '16px',
  },
  border: {
    fontSize: '16px',
    paddingLeft: '20px',
    borderLeft: '1px solid #4D4D4F',
  },
  info: {
    fontSize: '12px',
    marginTop: '15px',
    marginBottom: '0px',
  },
});

export enum ContinuousOrderInfoType {
  Weighing,
  Receipt,
}

interface ContinuousOrderInfoProps {
  infoType: ContinuousOrderInfoType;
  isNewInstance: boolean;
}

export const ContinuousOrderInfo = (props: ContinuousOrderInfoProps) => {
  const classes = styles();

  const containerClassToUse =
    props.infoType === ContinuousOrderInfoType.Weighing
      ? classes.containerForWeighing
      : classes.containerForReceipt;
  const borderClassToUse =
    props.infoType === ContinuousOrderInfoType.Weighing ? classes.border : '';

  return (
    <Box className={containerClassToUse}>
      <Box className={borderClassToUse}>
        <Typography variant="h5" className={classes.title}>
          {translate('operatorOrder.orderDetails.continuousOrder.title')}
        </Typography>
        {props.isNewInstance && (
          <Typography className={classes.newInstance} variant="h5">
            {translate('operatorOrder.orderDetails.continuousOrder.newOrder')}
          </Typography>
        )}
        <p className={classes.info}>
          {translate('operatorOrder.orderDetails.continuousOrder.info')}
        </p>
      </Box>
    </Box>
  );
};
