import React from 'react';

import { makeStyles } from '@mui/styles';
import { Button, Grid, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import { connect, localeConnect } from 'localeConnect';
import { ApplicationState } from 'store';

import { Job, JobContainer } from 'store/jobs/types';

import { MasterDataState } from 'store/master-data/types';

import { MeasDeviceGroup } from 'store/meas-devices/types';

import {
  requestMasterDataUpdate,
  setContextComment,
  setContextCommentImmediately,
} from 'store/orders/actions';

import { Component, Context, Order } from 'store/orders/types';

import {
  hasOrderComponentRequiredTypesLinked,
  secondaryContainer,
  secondaryContainerTareKgs,
} from 'store/orders/utils';

import { FormatUnit, undefinedMassKg } from 'store/weighing/types';

import {
  containerValidLoads,
  getMultipartRounds,
  parseJobContainers,
} from 'models/multipart.model';

import { VehicleWeighingModels } from 'models/vehicleweighing.model';

import theme, { fadedGreyColor } from 'theme';

import { BridgeEnabledFeatures } from 'store/scale-info/types';
import { translate } from 'utils/translate';
import SecondaryContainerTareFeature from '../SecondaryContainerTareFeature';
import { formatDateAndTime, formatMass } from '../utils';
import OperatorOrderComponentMasterData from './OperatorOrderComponentMasterData';

import OperatorTable, { OperatorTableColumn } from './OperatorTable';

import OperatorVehicleWeighing from './OperatorVehicleWeighing';

const useStyles = makeStyles({
  actionsContent: {
    textAlign: 'right',
  },
  actionsGrid: {
    marginTop: 'auto',
    paddingTop: '20px',
  },
  buttonError: {
    color: theme.palette.error.main,
  },
  deduction: {
    color: theme.palette.error.main,
  },
  roundDetails: {
    // eslint-disable-next-line
    border: `1px solid ${fadedGreyColor}`,
    // eslint-disable-next-line
    borderRadius: '3px',
    // eslint-disable-next-line
    padding: '10px',
    // eslint-disable-next-line
    marginTop: '20px',
    // eslint-disable-next-line
    marginLeft: 'auto',
    // eslint-disable-next-line
    marginRight: 'auto',
    // eslint-disable-next-line
    marginBottom: '20px',
    // eslint-disable-next-line
    textAlign: 'left',
    '@media (min-width: 960px)': {
      width: '420px',
    },
  },
  comment: {
    // eslint-disable-next-line
    marginTop: '30px',
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  masterData: {
    // eslint-disable-next-line
    marginTop: '20px',
    '& > div': {
      color: '#767676',
    },
  },
  netKgInfo: {
    fontSize: '1.5rem',
  },
  arrowContainer: {
    marginLeft: '30px',
  },
  reverseIcon: {
    transform: 'rotateY(180deg) translateY(7px)',
    marginRight: '30px',
  },
  roundsTable: {
    '& table': {
      minWidth: 'initial',
    },
  },
  selectable: {
    cursor: 'pointer',
  },
});

interface ParameterProps {
  actions: any;
  context: Context;
  doWeighing: () => void;
  discardWeighing: () => void;
  isReversible: boolean;
  isReversed: boolean;
  isSettingMasterDataAllowed: boolean;
  jobs: Job[];
  masterData: MasterDataState;
  measDeviceGroup?: MeasDeviceGroup;
  reverseTruckTrailer: () => void;
  setManualKgHandler: (containerKey: string, kgs: number) => void;
  weighingModels: VehicleWeighingModels;
}

interface PropsFromState {
  enabledFeatures: BridgeEnabledFeatures;
  masterData: MasterDataState;
  schema?: Record<string, unknown>;
}

interface PropsFromDispatch {
  requestMasterDataUpdate: typeof requestMasterDataUpdate;
  setContextComment: typeof setContextComment;
  setContextCommentImmediately: typeof setContextCommentImmediately;
}

const mapStateToProps = (state: ApplicationState) => ({
  enabledFeatures: state.currentScaleInfo.enabledFeatures,
  masterData: state.masterData,
  schema: state.currentScaleInfo.domainInfo.masterDataCombo.schema,
});

type AllProps = ParameterProps & PropsFromState & PropsFromDispatch;

const mapDispatchToProps = {
  requestMasterDataUpdate,
  setContextComment,
  setContextCommentImmediately,
};

interface ContainerProps {
  job: Job;
}

interface KgInfoProps {
  title: string;
  formattedKgs: string | JSX.Element;
  showArrow: boolean;
}

const KgInfo = (props: KgInfoProps) => {
  const classes = useStyles();
  return (
    <div>
      <span className={classes.netKgInfo}>
        {props.title}
        <span className={classes.arrowContainer}>
          {props.showArrow && <ArrowRightAltIcon className={classes.reverseIcon} />}
        </span>
        {props.formattedKgs}
      </span>
    </div>
  );
};

interface ContainerNetKgProps {
  container: JobContainer;
  job: Job;
  title: string;
}

function formatKgs(kgs: number | undefined) {
  if (kgs === undefined) return formatMass(undefinedMassKg, FormatUnit.WITHOUT_UNIT);
  return formatMass(kgs);
}

const ContainerNetKgs = (props: ContainerNetKgProps) => {
  const validLoads = containerValidLoads(props.job, props.container);
  const kgs = validLoads.length > 1 ? props.container.sumMassKg : undefined;
  return <KgInfo title={props.title} formattedKgs={formatKgs(kgs)} showArrow={true} />;
};

const TruckKgs = (props: ContainerProps) => {
  const { truck } = parseJobContainers(props.job);
  if (!truck) return null;
  return (
    <ContainerNetKgs
      container={truck}
      job={props.job}
      title={translate('operatorOrder.weighing.multipart.truck', { name: truck.name })}
    />
  );
};

const TrailerKgs = (props: ContainerProps) => {
  const { trailer } = parseJobContainers(props.job);
  if (!trailer) return null;
  return (
    <ContainerNetKgs
      container={trailer}
      job={props.job}
      title={translate('operatorOrder.weighing.multipart.trailer', { name: trailer.name })}
    />
  );
};

interface DeductionProps {
  componentId: string;
  order: Order;
}

const DeductionKgs = (props: DeductionProps) => {
  const classes = useStyles();
  const deductionContainerName = secondaryContainer(props.order, props.componentId)?.name ?? '';
  const deductionKgs = secondaryContainerTareKgs(props.order, props.componentId);
  if (deductionKgs === undefinedMassKg) return null;
  const deductionParameters = {
    kgs: formatKgs(-deductionKgs),
    containerName: deductionContainerName,
  };
  return (
    <SecondaryContainerTareFeature componentId={props.componentId} order={props.order}>
      <KgInfo
        title={translate('operatorOrder.weighing.containerDeductionTitle')}
        formattedKgs={
          <span className={classes.deduction}>
            {translate('operatorOrder.weighing.containerDeduction', deductionParameters)}
          </span>
        }
        showArrow={false}
      />
    </SecondaryContainerTareFeature>
  );
};

interface WeighingRoundProps {
  context: Context;
  enabledFeatures: BridgeEnabledFeatures;
  isSettingMasterDataAllowed: boolean;
  jobs: Job[];
  order: Order;
  selectedKey: string;
  setSelectedKey: (key: string) => void;
}

interface RoundTableItem {
  key: string;
  componentId: string;
  roundNumber: number;
  timestamp: number | undefined;
  truckKgs?: number;
  trailerKgs?: number;
}

const WeighingRounds = (props: WeighingRoundProps) => {
  const classes = useStyles();

  const { truck, trailer } = parseJobContainers(props.jobs[0]);
  const truckColumn = {
    key: 'truckKgs',
    title: truck ? truck.name : '',
    dataGetter: (item: RoundTableItem) => formatKgs(item.truckKgs),
  };
  const trailerColumn = {
    key: 'trailerKgs',
    title: trailer ? trailer.name : '',
    dataGetter: (item: RoundTableItem) => formatKgs(item.trailerKgs),
  };
  const columns = [
    {
      key: 'roundNumber',
      translationKey: 'operatorOrder.weighing.multipart.number',
      dataGetter: (item: RoundTableItem) => item.roundNumber,
    },
    {
      key: 'time',
      translationKey: 'operatorOrder.weighing.multipart.time',
      dataGetter: (item: RoundTableItem) => {
        if (item.timestamp === undefined) return '';
        return formatDateAndTime(item.timestamp);
      },
    },
    truck ? truckColumn : undefined,
    trailer ? trailerColumn : undefined,
    {
      key: 'edit',
      dataGetter: (item: RoundTableItem) => {
        if (item.roundNumber === 1) {
          return <div />;
        }
        const hasAllRequiredData =
          !item.componentId || hasOrderComponentRequiredTypesLinked(props.order, item.componentId);
        return (
          <div>
            <Button
              className={hasAllRequiredData ? '' : classes.buttonError}
              disabled={!props.isSettingMasterDataAllowed}
            >
              <EditIcon />
              {!hasAllRequiredData && <span>*</span>}
            </Button>
          </div>
        );
      },
    },
  ].filter((column) => column) as OperatorTableColumn[];

  const data = getMultipartRounds(props.jobs, props.order, props.enabledFeatures);
  const selectedIndex = data.findIndex((item) => item.key === props.selectedKey);

  return (
    <div className={classes.roundsTable}>
      <OperatorTable
        columns={columns}
        data={data}
        keyGetter={(item: RoundTableItem) => item.key}
        classGetter={(item: RoundTableItem) => {
          if (item.roundNumber > 1) {
            return classes.selectable;
          }
          return '';
        }}
        selectedIndex={selectedIndex}
        setSelectedIndex={(index: number) => {
          // NOTE(mikkogy,20210430) initial round is not selectable in UX sense.
          // We show only first round results.
          if (index === data.length - 1) return;
          const item = data[index];
          if (item) {
            props.setSelectedKey(item.key);
          } else {
            props.setSelectedKey('');
          }
        }}
      />
    </div>
  );
};

const OperatorMultipartWeighing = (props: AllProps) => {
  const classes = useStyles();

  const [selectedKey, _setSelectedKey] = React.useState('');
  const [localComment, setLocalComment] = React.useState('');

  function getSelectedJob(key: string) {
    return props.jobs.find((job) => job.key === key);
  }

  function setSelectedKey(key: string) {
    const job = getSelectedJob(key);
    setLocalComment(job?.comment ? job.comment : '');
    _setSelectedKey(key);
  }

  const jobs = [...props.jobs].reverse();

  const selectedJob = getSelectedJob(selectedKey);
  let selectedComponent: Component | undefined = undefined;
  if (selectedJob) {
    const order = props.context.order;
    if (order) {
      selectedComponent = order.components.find(
        (component) => component.id === selectedJob.order.componentId,
      );
    }
  }

  const commentBlurred = (event: any) => {
    if (!props.context || !selectedJob) {
      return;
    }
    const comment = event.target.value as string;
    setLocalComment(comment);
    props.setContextCommentImmediately(props.context.contextId, comment, selectedJob.key);
  };

  const commentChanged = (event: any) => {
    if (!props.context || !selectedJob) {
      return;
    }
    const comment = event.target.value as string;
    setLocalComment(comment);
    props.setContextComment(props.context.contextId, comment, selectedJob.key);
  };

  return (
    <div>
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12}>
                <OperatorVehicleWeighing
                  discardWeighing={props.discardWeighing}
                  doReverse={props.reverseTruckTrailer}
                  doWeighing={props.doWeighing}
                  isReversed={props.isReversed}
                  isReversible={props.isReversible}
                  model={props.weighingModels.models[0]}
                  isMeasDeviceGroupManual={
                    !!props.measDeviceGroup && props.measDeviceGroup.isManual
                  }
                  process={props.weighingModels.process}
                  setManualKgs={props.setManualKgHandler}
                />
              </Grid>
              <Grid item xs={12}>
                <WeighingRounds
                  context={props.context}
                  enabledFeatures={props.enabledFeatures}
                  isSettingMasterDataAllowed={props.isSettingMasterDataAllowed}
                  jobs={jobs}
                  order={props.context.order}
                  selectedKey={selectedKey}
                  setSelectedKey={setSelectedKey}
                />
              </Grid>
            </Grid>
          </Grid>
          {selectedJob && selectedComponent && (
            <Grid item xs={12} md={6}>
              <div className={classes.roundDetails}>
                <div>
                  <TruckKgs job={selectedJob} />
                  <TrailerKgs job={selectedJob} />
                  {selectedComponent && (
                    <DeductionKgs componentId={selectedComponent.id} order={props.context.order} />
                  )}
                </div>
                <div className={classes.masterData}>
                  <div>{translate('operatorOrder.weighing.multipart.data')}</div>
                  {props.context.order && (
                    <OperatorOrderComponentMasterData
                      componentId={selectedComponent.id}
                      excludedTypes={undefined}
                      includedTypes={undefined}
                      order={props.context.order}
                      dataLinks={selectedComponent.dataLinks || []}
                      isAllowedToUpdateOrder={props.isSettingMasterDataAllowed}
                      requiredTypes={selectedComponent.requiredDataTypes || []}
                    />
                  )}
                </div>
                <div className={classes.comment}>
                  <TextField
                    variant="standard"
                    value={localComment}
                    onBlur={commentBlurred}
                    onChange={commentChanged}
                    label={translate('operatorOrder.orderDetails.comment')}
                    disabled={!selectedJob}
                  />
                </div>
              </div>
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid item xs={12} className={classes.actionsGrid}>
            <div className={classes.actionsContent}>{props.actions}</div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(OperatorMultipartWeighing);
