import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
// `react-router-redux` is deprecated, so we use `connected-react-router`.
// This provides a Redux middleware which connects to our `react-router` instance.
import { routerMiddleware } from 'connected-react-router';
// We'll be using Redux Devtools. We can use the `composeWithDevTools()`
// directive so we can pass our middleware along with it
import { composeWithDevTools } from 'redux-devtools-extension';
// If you use react-router, don't forget to pass in your history type.
import { History } from 'history';
import { persistStore } from 'redux-persist';

// Import the state interface and our combined reducers/sagas.
import { ApplicationState, createRootReducer, rootSaga } from './store';
import { getSelectedLocale } from './store/selectors';
import { applyLocale } from './store/user/actions';

export default function configureStore(history: History, initialState: ApplicationState) {
  // create the composing function for our middlewares
  const composeEnhancers = composeWithDevTools({});
  // create the redux-saga middleware
  const sagaMiddleware = createSagaMiddleware();

  // We'll create our store with the combined reducers/sagas, and the initial Redux state that
  // we'll be passing from our entry point.
  const rootReducer = createRootReducer(history);
  const store = createStore(
    createRootReducer(history),
    //@ts-ignore
    initialState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware)),
  );

  // NOTE(anttipalola, 20190606) Allow for store code change without full reload
  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./store/index', () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  // Don't forget to run the root saga, and return the store object.
  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store, undefined, () => {
    const locale = getSelectedLocale(store.getState());
    store.dispatch(applyLocale(locale));
  });
  return { store, persistor };
}
