import { ApplicationState } from 'store';
import { findImages } from '../utils';

export function findContext(state: ApplicationState) {
  const emptyResult = { context: undefined, inspectorOrder: undefined, images: [] };
  if (!state.orders.currentContextOrderKey) {
    return emptyResult;
  }
  const orderKey = state.orders.currentContextOrderKey;
  const inspectorOrder = state.orders.inProgressOrders.orders.find(
    (inProgress) => inProgress.order.key === orderKey,
  );
  if (!inspectorOrder) {
    return emptyResult;
  }
  const contexts = state.orders.contexts;
  for (const key in contexts) {
    if (orderKey === contexts[key].order.key) {
      return {
        context: contexts[key],
        inspectorOrder,
        images: findImages(state, inspectorOrder.order.key),
      };
    }
  }
  return emptyResult;
}
