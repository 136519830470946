import { action } from 'typesafe-actions';
import {
  MeasDevice,
  MeasDeviceGroup,
  MeasDeviceActionTypes,
  TrafficControlGroupStates,
  TrafficControlStatus,
  TrafficControlMode,
  TrafficControlGroupState,
} from './types';

export const receiveListSuccess = (measDeviceGroups: MeasDeviceGroup, measDevices: MeasDevice[]) =>
  action(MeasDeviceActionTypes.RECEIVE_LIST_SUCCESS, { measDeviceGroups, measDevices });

export const clearSelectedMeasDevice = () =>
  action(MeasDeviceActionTypes.CLEAR_SELECTED_MEAS_DEVICE);
export const selectMeasDeviceGroup = (measDeviceGroupId: string) =>
  action(MeasDeviceActionTypes.SELECT_MEAS_DEVICE_GROUP, measDeviceGroupId);
export const measDeviceGroupSelected = (measDeviceGroupId: string) =>
  action(MeasDeviceActionTypes.SELECTED, measDeviceGroupId);
export const startWeighing = () => action(MeasDeviceActionTypes.START_WEIGHING);
export const startedWeighing = () => action(MeasDeviceActionTypes.STARTED_WEIGHING);
export const failedToStartWeighing = () => action(MeasDeviceActionTypes.FAILED_TO_START_WEIGHING);
export const clearWeighingStartError = () =>
  action(MeasDeviceActionTypes.CLEAR_WEIGHING_START_ERROR);

// Operator
export const activateContext = (contextId: string, measDeviceGroupId: string) =>
  action(MeasDeviceActionTypes.ACTIVATE_CONTEXT, {
    contextId,
    measDeviceGroupId,
  });
export const setManualKgs = (key: string, kgs: number) =>
  action(MeasDeviceActionTypes.SET_MANUAL_KGS, { key, kgs });
export const setPendingMeasDeviceKey = (measDeviceKey: string) =>
  action(MeasDeviceActionTypes.SET_PENDING_MEAS_DEVICE_KEY, measDeviceKey);
export const toggleMeasDeviceGroupReversing = (measDeviceGroupId: string) =>
  action(MeasDeviceActionTypes.TOGGLE_MEAS_DEVICE_GROUP_REVERSING, measDeviceGroupId);
export const receiveTrafficControlUpdate = (
  trafficControlStatuses: TrafficControlGroupStates,
  trafficControlConnectionOk: boolean,
) =>
  action(MeasDeviceActionTypes.RECEIVE_TRAFFIC_CONTROL_GROUP_UPDATE, {
    trafficControlStatuses,
    trafficControlConnectionOk,
  });
export const updateTrafficControlGroupMode = (
  trafficControlGroupId: string,
  mode: TrafficControlMode,
) =>
  action(MeasDeviceActionTypes.UPDATE_TRAFFIC_CONTROL_GROUP_MODE, {
    trafficControlGroupId,
    mode,
  });
export const updateTrafficControlGroupStatus = (
  trafficControlGroupId: string,
  status: TrafficControlStatus,
) =>
  action(MeasDeviceActionTypes.UPDATE_TRAFFIC_CONTROL_GROUP_STATUS, {
    trafficControlGroupId,
    status,
  });
export const updateTrafficControlGroupDesiredState = (
  trafficControlGroupId: string,
  status: TrafficControlGroupState,
) =>
  action(MeasDeviceActionTypes.UPDATE_TRAFFIC_CONTROL_GROUP_DESIRED_STATE, {
    trafficControlGroupId,
    status,
  });
export const resetTrafficControlGroupDesiredState = (trafficControlGroupId: string) =>
  action(MeasDeviceActionTypes.RESET_TRAFFIC_CONTROL_GROUP_DESIRED_STATE, {
    trafficControlGroupId,
  });
