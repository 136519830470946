import {
  ApiResponse,
  ContainerItem,
  ContainerType,
  Document,
  MasterDataItem,
} from '../common/types';
import { GenericMasterData } from '../master-data/types';

export enum JobStatus {
  OPEN = 'OPEN',
  COMPLETED = 'COMPLETED',
  COMPLETED_LOCKED = 'COMPLETED_LOCKED',
  DISCARDED = 'DISCARDED',
}

export enum LoadType {
  GROSS = 'GROSS',
  TARE = 'TARE',
  FIX = 'FIX',
  NET = 'NET',
}

export enum JobDirection {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
  UNDEFINED = 'UNDEFINED',
}

export enum InspectionStatus {
  NOT_INSPECTED = 'NOT_INSPECTED',
  INSPECTED = 'INSPECTED',
}

export enum DeductionOperation {
  DEDUCTION = 'DEDUCTION',
  ADDITION = 'ADDITION',
}

export interface DeductionRow {
  containerKey: string;
  containerType: ContainerType;
  isAddition: boolean;
  jobKey: string;
  kgs: number;
  material: GenericMasterData | undefined;
  rowKey: string;
  comment: string;
}

export interface Deduction {
  containerKey: string;
  kgs: number;
  materialKey: string;
  comment: string;
}

export interface Inspection {
  status: InspectionStatus;
  timestamp: number;
  userKey: string;
}

export interface JobContainer extends ContainerItem {
  containers?: JobContainer[];
  loadIndexes?: number[];
  sumMassKg: number;
}

export interface JobOrder {
  componentId: string;
  key: string;
}

export interface Load {
  loadType: LoadType;
  index: number;
  relatedIndex?: number;
  massKg: number;
  timestamp: number;
  recordId?: string;
}

export interface JobAttachment extends Document {
  description?: string;
  filename: string;
  mimeType: string;
}

export interface SplitInformation {
  splitId: string;
  splitAmount: number;
}

// Response object for getting Orders
export interface Job extends Document {
  attachments?: JobAttachment[];
  status: JobStatus;
  inspection?: Inspection;
  comment?: string;
  containers: JobContainer[];
  order: JobOrder;
  sumCount: number;
  sumMassKg: number;
  loads: Load[];
  receiptNumber?: number;
  linked: MasterDataItem[];
  measDeviceTools: MeasDeviceTool[];
  splitInformation: SplitInformation | undefined;
}

export interface MeasDeviceTool {
  toolKey: string;
}

export type JobsResponsePayload = ApiResponse & {
  weighingJobs: Job[];
};

export enum JobsActionTypes {
  FETCH_REQUEST = '@@jobs/FETCH_REQUEST',
  FETCH_SUCCESS = '@@jobs/FETCH_SUCCESS',
  FETCH_ERROR = '@@jobs/FETCH_ERROR',
  GENERATE_RECEIPT = '@@jobs/GENERATE_RECEIPT',
  RECEIPT_SUCCESS = '@@jobs/RECEIPT_SUCCESS',
  JOB_QUERY_REFRESH = '@@jobs/JOB_QUERY_REFRESH',
  JOB_QUERY_REQUEST = '@@jobs/JOB_QUERY_REQUEST',
  JOB_QUERY_SUCCESS = '@@jobs/JOB_QUERY_SUCCESS',
  LATEST_JOBS_QUERY_REQUEST = '@@jobs/LATEST_JOBS_QUERY_REQUEST',
  LATEST_JOBS_QUERY_SUCCESS = '@@jobs/LATEST_JOBS_QUERY_SUCCESS',
  START_LATEST_JOBS_POLL = '@@jobs/START_LATEST_JOBS_POLL',
  STOP_LATEST_JOBS_POLL = '@@jobs/STOP_LATEST_JOBS_POLL',
  STORE_OPERATOR_QUERY = '@@jobs/STORE_OPERATOR_QUERY',
  SET_DRIVER_RECEIPT_JOB_KEYS = '@@jobs/SET_DRIVER_RECEIPT_JOB_KEYS',
  SET_INSPECTION_STATUS = '@@jobs/SET_INSPECTION_STATUS',
}

export interface JobsState {
  readonly loading: boolean;
  readonly data: Job[];
  readonly errors?: string;
  readonly finishedJobKeys: string[]; // For filtering out local finished jobs before backend reacts
  readonly receiptDocuments: string[];
  readonly driverReceiptJobKeys: string[];
  readonly latestJobs?: JobQueryResponse;
  readonly queryJobs?: JobQueryResponse;
  readonly queryParams: OperatorQueryParams;
}

export interface JobQueryParams {
  organization?: string;
  page: number;
  size: number;
  startTime: number;
  endTime: number;
  scaleKeys?: [string];
  status?: JobStatus;
}

export interface JobQueryResponse {
  number: number;
  size: number;
  names: { [key: string]: string };
  results: Job[];
}

export interface OperatorQueryMasterData {
  key: string;
  name: string;
  role?: string;
}

export interface OperatorQueryParams {
  endTime: number;
  startTime: number;
  page: number;
  size: number;
  masterData: OperatorQueryMasterData[];
}
