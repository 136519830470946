import { translate } from 'utils/translate';
import {
  ContainerItem,
  ContainerTare,
  ContainerType,
  DocType,
  MasterDataItem,
} from '../common/types';
import { isSecondaryContainer } from '../master-data/utils';
import { UserDetails } from '../user/types';
import { getHasOrganizationRoleRight } from '../user/utils';
import { alwaysSupportedMasterDataTypes, masterDataKeyToType } from '../utils';
import { InspectionStatus, Job } from '../jobs/types';
import { Vehicle } from '../vehicles/types';
import { undefinedMassKg } from '../weighing/types';
import {
  ClosePolicy,
  Component,
  ComponentGroup,
  Context,
  WeighingProcess,
  InProgressOrder,
  InspectorOrderTab,
  Order,
  OrderStatus,
  NormalWeighingMainComponents,
  Split,
  SplitLoad,
} from './types';

// NOTE(mikkogy,20220623) if order, component or links are missing an empty array is returned.
export function getComponentDataLinks(
  order: Order | undefined,
  componentId: string | undefined,
): string[] {
  if (componentId === undefined || order?.components === undefined) return [];
  const component = order.components.find((component) => component.id === componentId);
  return component?.dataLinks ?? [];
}

export function getDataLinksByType(dataLinks: string[], dataType: string) {
  return dataLinks.filter((link) => masterDataKeyToType(link) === dataType);
}

export function getCurrentlySelectedMasterData(
  linkedData: MasterDataItem[],
  dataLinks: string[],
  dataType: string,
  subtype?: string,
) {
  if (linkedData.length === 0) return undefined;

  const linkKeys = dataLinks.filter((link) => masterDataKeyToType(link) === dataType);
  if (linkKeys.length === 0) {
    return undefined;
  }

  // NOTE(mikkogy,20220608) if multiple items are available we want to see the
  // first one in dataLinks. This should match what bridge links to weighing
  // jobs in case of multiple items.
  const sortedData = [...linkedData];
  sortedData.sort((a, b) => {
    const aIndex = linkKeys.findIndex((key) => key === a.key);
    const bIndex = linkKeys.findIndex((key) => key === b.key);
    if (aIndex < 0 && bIndex < 0) return 0;
    if (aIndex < 0) return 1;
    if (bIndex < 0) return -1;
    return aIndex - bIndex;
  });
  const link = sortedData.find(
    (item) => linkKeys.includes(item.key) && item.containerType === subtype,
  );
  return link ? link : undefined;
}

export enum MainJobMultipartBehavior {
  IGNORE_MULTIPART = 'IGNORE_MULTIPART',
  FIRST = 'FIRST',
}

export function getMainJob(
  context: Context | undefined,
  order: Order,
  jobs: Job[],
  weighingProcess: WeighingProcess,
  multipartBehavior: MainJobMultipartBehavior = MainJobMultipartBehavior.IGNORE_MULTIPART,
): Job | undefined {
  if (!context && weighingProcess !== WeighingProcess.NORMAL) {
    return undefined;
  }

  if (order.closePolicy === ClosePolicy.SERVER_MANUAL) {
    return undefined;
  }

  if (weighingProcess === WeighingProcess.MULTIPART) {
    if (multipartBehavior === MainJobMultipartBehavior.IGNORE_MULTIPART) {
      return undefined;
    } else if (multipartBehavior === MainJobMultipartBehavior.FIRST) {
      return jobs[0];
    }
    return undefined;
  }

  if (!context) {
    return jobs[jobs.length - 1];
  }

  return jobs[0];
}

export function getContextMainJob(
  context: Context | undefined,
  multipartBehavior: MainJobMultipartBehavior = MainJobMultipartBehavior.IGNORE_MULTIPART,
): Job | undefined {
  if (!context || !context.weighingJobs) return undefined;
  return getMainJob(
    context,
    context.order,
    context.weighingJobs,
    context.process,
    multipartBehavior,
  );
}

export function getContextMultipartJob(
  context: Context | undefined,
  multipartComponentId: string,
): Job | undefined {
  if (!context || !context.weighingJobs) return undefined;
  return context.weighingJobs.find((job) => job?.order?.componentId === multipartComponentId);
}

export function getNormalWeighingMainComponents(order: Order): NormalWeighingMainComponents {
  const areComponentGroupsUsed =
    order.components.find((component) => {
      return component.componentGroup !== undefined;
    }) !== undefined;

  function findByGroup(componentGroup: ComponentGroup) {
    return order.components.find((component) => component.componentGroup === componentGroup);
  }

  if (areComponentGroupsUsed) {
    const truckComponent = findByGroup(ComponentGroup.TRUCK);
    if (truckComponent === undefined) {
      throw new Error('order that uses component groups must have truck component');
    }
    return {
      truck: truckComponent,
      trailer: findByGroup(ComponentGroup.TRAILER),
    };
  }

  return {
    truck: order.components[0],
    trailer: order.components?.[1],
  };
}

export function getNormalWeighingActiveComponents(order: Order, splitLoad: SplitLoad) {
  const truckComponents: Component[] = [];
  const trailerComponents: Component[] = [];

  const splitComponentIds = Object.keys(splitLoad);
  splitComponentIds.forEach((componentId: string) => {
    const component = order.components.find((component) => component.id === componentId);
    if (component === undefined) return;
    // NOTE(mikkogy,20230112) missing component group ignored for now.
    // NormalWeighingMainComponents are assumed to be used later for that case.
    if (component.componentGroup === undefined) return;
    if (component.componentGroup === ComponentGroup.TRAILER) {
      trailerComponents.push(component);
    } else if (component.componentGroup === ComponentGroup.TRUCK) {
      truckComponents.push(component);
    } else {
      console.warn(`Unknown componentGroup "${component.componentGroup}" ignored`);
    }
  });

  // NOTE(mikkogy,20230112) when split load is not used at all or component
  // group information is missing and components are defaults we assume to find
  // them from NormalWeighingMainComponents.
  const components = getNormalWeighingMainComponents(order);
  if (truckComponents.length === 0 && components.truck !== undefined) {
    truckComponents.push(components.truck);
  }
  if (trailerComponents.length === 0 && components.trailer !== undefined) {
    trailerComponents.push(components.trailer);
  }

  return {
    truck: truckComponents,
    trailer: trailerComponents,
  };
}

export function getNormalWeighingActiveComponentLinkedData(
  order: Order,
  splitLoad: SplitLoad,
  masterDataType: string,
) {
  const components = getNormalWeighingActiveComponents(order, splitLoad);

  function unique(data: MasterDataItem[]) {
    const keys: Record<string, boolean> = {};
    return data.filter((item) => {
      if (keys[item.key]) return false;
      keys[item.key] = true;
      return true;
    });
  }

  function collect(data: MasterDataItem[], components: Component[]) {
    components.forEach((component) => {
      if (component.dataLinks === undefined) return;
      const links = getDataLinksByType(component.dataLinks, masterDataType);
      links.forEach((link) => {
        if (order.linkedData === undefined) return;
        const item = order.linkedData.find((item) => item.key === link);
        if (item === undefined) return;
        data.push(item);
      });
    });
  }

  const truckData: MasterDataItem[] = [];
  collect(truckData, components.truck);
  const trailerData: MasterDataItem[] = [];
  collect(trailerData, components.trailer);

  return {
    truck: unique(truckData),
    trailer: unique(trailerData),
  };
}

export const getOrderTruck = (order: Order): Vehicle | undefined => {
  const components = getNormalWeighingMainComponents(order);
  const orderContainerKeys =
    components.truck.dataLinks?.filter((key: string) => masterDataKeyToType(key) === 'container') ??
    [];
  const orderTruck: Vehicle | undefined = order.linkedData?.find(
    (item: MasterDataItem) =>
      item.docType === DocType.CONTAINER &&
      item.containerType === ContainerType.TRUCK &&
      orderContainerKeys.includes(item.key),
  ) as Vehicle | undefined;
  return orderTruck;
};

// NOTE(mikkogy,20210705) only considering order specific tare saving
// requirements. Other primary container tare rules have to be checked
// separately.
export const isOrderValidForTareSaving = (order: Order, scaleKey: string): boolean => {
  const isLocal =
    order.receivers.domains.length === 1 &&
    order.receivers.domains[0] === scaleKey &&
    order.areReceiversLocked === true &&
    order.noSync === true;
  return isLocal;
};

export const isUpdatingOrderAllowed = (
  order: Order,
  scaleKey: string | undefined,
  user: UserDetails | undefined,
) => {
  const areReceiversLocked = !!order.areReceiversLocked;
  const isOnlyReceiverScale =
    order.receivers.domains.length === 1 && order.receivers.domains[0] === scaleKey;
  const isEditingMasterDataAllowed =
    scaleKey !== undefined &&
    order.status === OrderStatus.OPEN &&
    isOnlyReceiverScale &&
    areReceiversLocked &&
    getHasOrganizationRoleRight(user, 'updateOrder');
  return isEditingMasterDataAllowed;
};

export const requiredTypes = (order: Order | undefined, componentId: string) => {
  const emptyResult: string[] = [];
  if (!order || !componentId) {
    return emptyResult;
  }
  const component = order.components.find((c) => c.id === componentId);
  if (!component) {
    return emptyResult;
  }
  const required = component.requiredDataTypes;
  return required || emptyResult;
};

export const linkedTypes = (order: Order | undefined, componentId: string) => {
  const emptyResult: string[] = [];
  if (!order) {
    return emptyResult;
  }
  const component = order.components.find((c) => c.id === componentId);
  if (!component || !component.dataLinks) {
    return emptyResult;
  }
  const links = component.dataLinks;
  return links.map((link) => masterDataKeyToType(link));
};

export const hasOrderComponentRequiredTypesLinked = (order: Order, componentId: string) => {
  const supportedTypes = alwaysSupportedMasterDataTypes();
  const component = order.components.find((c) => c.id === componentId);
  if (!component) {
    return true;
  }
  const required: any = requiredTypes(order, componentId);
  const linked: any = linkedTypes(order, componentId);
  return (
    supportedTypes.filter((t) => {
      const isRequired = required.includes(t);
      return !isRequired || (isRequired && linked.includes(t));
    }).length === supportedTypes.length
  );
};

const hasOrderRequiredTypesLinked = (order: Order) => {
  // NOTE(mikkogy,20201229) we can always check all components because if we do
  // not have truck or trailer, there will not be any required types. If there
  // are more components it's unexpected data we don't really need to support.
  return order.components.reduce((hasAll, component) => {
    return hasAll && hasOrderComponentRequiredTypesLinked(order, component.id);
  }, true);
};

export function hasContextRequiredMasterData(context: Context | undefined) {
  if (context?.order === undefined) {
    return true;
  }
  if (context.process === WeighingProcess.MULTIPART) {
    // NOTE(mikkogy,20230421) at the time of implementing multipart is not
    // interesting so we ignore the fact that there may be components that are
    // not used.
    return hasOrderRequiredTypesLinked(context.order);
  }
  const isTrailerInJob =
    context.weighingJobs?.[0]?.containers[0]?.containerType === ContainerType.TRUCK_TRAILER;
  const components = splitLoadComponents(context.order, context.splitLoad, isTrailerInJob);
  return components.reduce((hasAllRequiredLinked: boolean, component: Component) => {
    return (
      hasAllRequiredLinked && hasOrderComponentRequiredTypesLinked(context.order, component.id)
    );
  }, true);
}

export const isOrderAccepted = (order: Order, scaleKey: string) => {
  const realization = order.realization || {};
  const scaleRealization = realization[scaleKey];
  const receiverStatus =
    scaleRealization && scaleRealization.receiverStatus
      ? scaleRealization.receiverStatus
      : undefined;
  return !!receiverStatus;
};

// NOTE(mikkogy,20220927) we don't really expect not to have a timestamp for
// InProgressOrder but in case of a bug in bridge we don't want to rendering to
// fail on an exception.
export const firstLoadTimestamp = (order: InProgressOrder): number | undefined => {
  const timestamp = order.weighingJobs
    .filter((job) => {
      if (job?.loads?.[0]) return job;
      return undefined;
    })
    .map((job) => job.loads[0].timestamp)
    .filter((timestamp) => timestamp)
    .sort()[0];
  return timestamp;
};

export const filterInspectorInProgressOrders = (
  inProgressOrders: InProgressOrder[],
  inspectorOrderTab: InspectorOrderTab,
) => {
  function isInspected(order: InProgressOrder): boolean {
    return order.weighingJobs?.[0]?.inspection?.status === InspectionStatus.INSPECTED;
  }

  let orders;
  if (inspectorOrderTab === InspectorOrderTab.APPROVED) {
    orders = [...inProgressOrders.filter((order) => isInspected(order))];
  } else {
    orders = [...inProgressOrders.filter((order) => !isInspected(order))];
  }
  return orders;
};

export const secondaryContainer = (
  order: Order,
  componentId: string | undefined,
): ContainerItem | undefined => {
  if (!componentId) return undefined;
  const component = order.components.find((component) => component.id === componentId);
  if (!component) return undefined;

  const linked = (component?.dataLinks
    ?.map((link) => order?.linkedData?.find((linked) => linked?.key === link))
    .filter((linked) => linked) || []) as MasterDataItem[];

  const secondaryContainer = linked.find((linked) => isSecondaryContainer(linked)) as
    | ContainerItem
    | undefined;

  return secondaryContainer;
};

export const secondaryContainerTare = (
  order: Order,
  componentId: string | undefined,
): ContainerTare | undefined => {
  const container = secondaryContainer(order, componentId);
  return container?.tare;
};

export const secondaryContainerTareKgs = (
  order: Order,
  componentId: string | undefined,
): number => {
  const tare = secondaryContainerTare(order, componentId);
  if (!tare || tare.unfitForJobs) return undefinedMassKg;
  return tare.massKg;
};

export const formatOrderTitle = (name: string, closePolicy: ClosePolicy) => {
  if (closePolicy === ClosePolicy.SERVER_MANUAL) {
    return translate('order.common.templateOrderTitle', { name: name });
  }
  return name;
};

export interface SplitComponent {
  component: Component;
  split: Split | undefined;
}

function getGroupSplits(
  order: Order,
  splitLoad: SplitLoad | undefined,
  componentGroup: ComponentGroup,
): SplitLoad {
  const allSplits: SplitLoad = splitLoad ?? {};
  const groupSplits: typeof allSplits = {};
  Object.keys(allSplits).forEach((componentId) => {
    const component = order.components.find((component) => {
      return component.id === componentId;
    });
    if (!component) return;
    if (component.componentGroup === componentGroup) {
      groupSplits[componentId] = allSplits[componentId];
    }
  });
  return groupSplits;
}

interface SplitComponents {
  components: SplitComponent[];
  isSplitEnabled: boolean;
}

export function getSplitComponents(
  order: Order,
  splitLoad: SplitLoad | undefined,
  componentGroup: ComponentGroup,
): SplitComponents {
  const groupSplits = getGroupSplits(order, splitLoad, componentGroup);
  const isSplitEnabled = Object.keys(groupSplits).length > 0;
  if (!isSplitEnabled) {
    const defaultComponent = getDefaultComponent(order, componentGroup);
    if (defaultComponent === undefined) {
      return {
        components: [],
        isSplitEnabled,
      };
    }
    return {
      components: [
        {
          component: defaultComponent,
          split: undefined,
        },
      ],
      isSplitEnabled,
    };
  }

  const splitKeys = Object.keys(groupSplits);

  return {
    components: order.components
      .filter((component) => {
        return splitKeys.includes(component.id);
      })
      .map((component) => {
        return {
          component,
          split: groupSplits[component.id],
        };
      }),
    isSplitEnabled,
  };
}

function getDefaultComponent(order: Order, componentGroup: ComponentGroup) {
  const components = getNormalWeighingMainComponents(order);
  if (componentGroup === ComponentGroup.TRAILER) {
    return components.trailer;
  }
  return components.truck;
}

const splitLoadComponents = (
  order: Order,
  splitLoad: SplitLoad,
  isTrailerUsed: boolean,
): Component[] => {
  const components: Component[] = [];
  const truckSplit = getSplitComponents(order, splitLoad, ComponentGroup.TRUCK);
  truckSplit.components.forEach((split: SplitComponent) => components.push(split.component));
  if (isTrailerUsed) {
    const trailerSplit = getSplitComponents(order, splitLoad, ComponentGroup.TRAILER);
    trailerSplit.components.forEach((split: SplitComponent) => components.push(split.component));
  }
  return components;
};
