import React from 'react';

import { makeStyles } from '@mui/styles';

import { dotStatusColors } from 'theme';

const styles = makeStyles({
  statusIndicator: {
    position: 'relative',
    top: '2px',
    width: '16px',
    height: '16px',
    borderRadius: '8px',
    display: 'inline-block',
    marginRight: '5px',
  },
  ok: {
    backgroundColor: dotStatusColors.ok,
  },
  fail: {
    backgroundColor: dotStatusColors.fail,
  },
  inProgress: {
    backgroundColor: dotStatusColors.inProgress,
  },
});

export enum DotStatusColor {
  OK = 'OK',
  FAIL = 'FAIL',
  IN_PROGRESS = 'IN_PROGRESS',
}

interface DotStatusProps {
  color: DotStatusColor;
  text: string;
}

export const DotStatus = (props: DotStatusProps) => {
  const classes = styles();
  function getColor(color: DotStatusColor) {
    if (color === DotStatusColor.OK) return classes.ok;
    if (color === DotStatusColor.IN_PROGRESS) return classes.inProgress;
    return classes.fail;
  }
  const color = getColor(props.color);
  const className = `${classes.statusIndicator} ${color}`;
  return (
    <div>
      <span className={className} />
      {props.text}
    </div>
  );
};

export default DotStatus;
