import React from 'react';
import { Drawer } from '@mui/material';

interface AllProps {
  anchor: 'left' | 'right';
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  paperClass: string;
}

const TemporaryDrawer = (props: AllProps) => {
  return (
    <Drawer
      variant="temporary"
      anchor={props.anchor}
      open={props.isOpen}
      onClose={props.onClose}
      classes={{
        paper: props.paperClass,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      {props.children}
    </Drawer>
  );
};

export default TemporaryDrawer;
