import React, { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { connect, localeConnect } from 'localeConnect';
import { ApplicationState } from 'store';
import { DocType } from 'store/common/types';
import { joinUniqueMasterDataItemNames } from 'store/common/utils';

import {
  showOrderInfo,
  startInProgressOrderPoll,
  stopInProgressOrderPoll,
} from 'store/orders/actions';
import { jobsTrucksAndTrailers } from 'store/jobs/utils';
import { firstLoadTimestamp } from 'store/orders/utils';
import {
  InProgressOrder,
  InProgressOrders as InProgressOrderList,
  OperatorOrderMode,
  OperatorOrderTab,
} from 'store/orders/types';
import { RoutePaths } from 'routes';

import { operatorColors } from 'theme';
import { translate } from 'utils/translate';
import RegisterNumber from '../RegisterNumber';
import { formatDateAndTime } from '../utils';
import OperatorTable, {
  OperatorTableMenu,
  OperatorTableMenuItem,
  OperatorTableColumn,
} from './OperatorTable';

const useStyles = makeStyles({
  content: {
    marginBottom: '30px',
  },
  title: {
    textAlign: 'left',
  },
  titleCount: {
    color: operatorColors.secondary,
  },
});

interface PropsFromState {
  inProgressOrders: InProgressOrderList;
}

interface PropsFromDispatch {
  showOrderInfo: typeof showOrderInfo;
  startInProgressOrderPoll: typeof startInProgressOrderPoll;
  stopInProgressOrderPoll: typeof stopInProgressOrderPoll;
}

const mapStateToProps = (state: ApplicationState) => ({
  inProgressOrders: state.orders.inProgressOrders,
});

const mapDispatchToProps = {
  showOrderInfo,
  startInProgressOrderPoll,
  stopInProgressOrderPoll,
};

function registerNumbers(order: InProgressOrder) {
  return (
    <div>
      {jobsTrucksAndTrailers(order.weighingJobs).map((item) => (
        <RegisterNumber key={item.key} text={item.name} />
      ))}
    </div>
  );
}

function getMasterData(order: InProgressOrder, docType: DocType) {
  return joinUniqueMasterDataItemNames(order.order.linkedData || [], docType);
}

function getMaterial(order: InProgressOrder) {
  return getMasterData(order, DocType.MATERIAL);
}

function getCustomer(order: InProgressOrder) {
  return getMasterData(order, DocType.CUSTOMER);
}

function getTransportCo(order: InProgressOrder) {
  return getMasterData(order, DocType.TRANSPORT_CO);
}

function getTime(order: InProgressOrder) {
  const timestamp = firstLoadTimestamp(order);
  if (!timestamp) return '';
  return formatDateAndTime(timestamp);
}

type AllProps = PropsFromDispatch & PropsFromState;

const InProgressOrders = (props: AllProps) => {
  const classes = useStyles();
  const [anchorElState, setAnchorElState] = useState<{ [key: string]: any }>({});

  const { startInProgressOrderPoll, stopInProgressOrderPoll } = props;
  useEffect(() => {
    startInProgressOrderPoll();
    return () => {
      stopInProgressOrderPoll();
    };
  }, [startInProgressOrderPoll, stopInProgressOrderPoll]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, key: string) => {
    const anchorEl: { [key: string]: any } = {};
    anchorEl[key] = event.currentTarget;
    setAnchorElState(anchorEl);
  };

  const handleClose = () => {
    setAnchorElState({});
  };

  const orders = props.inProgressOrders.orders;
  const columns: OperatorTableColumn[] = [
    {
      key: 'registerNumber',
      translationKey: 'dashboard.inProgressOrders.registerNumber',
      dataGetter: (item: InProgressOrder) => registerNumbers(item),
      useLongTextStyle: false,
    },
    {
      key: 'material',
      translationKey: 'dashboard.inProgressOrders.material',
      dataGetter: (order: InProgressOrder) => getMaterial(order),
      useLongTextStyle: true,
    },
    {
      key: 'customer',
      translationKey: 'dashboard.inProgressOrders.customer',
      dataGetter: (order: InProgressOrder) => getCustomer(order),
      useLongTextStyle: true,
    },
    {
      key: 'transportCo',
      translationKey: 'dashboard.inProgressOrders.transportCo',
      dataGetter: (order: InProgressOrder) => getTransportCo(order),
      useLongTextStyle: true,
    },
    {
      key: 'started',
      translationKey: 'dashboard.inProgressOrders.started',
      dataGetter: (order: InProgressOrder) => getTime(order),
      useLongTextStyle: false,
    },
    {
      key: 'menu',
      dataGetter: (item: InProgressOrder) => (
        <OperatorTableMenu
          id={item.order.key}
          idPrefix="in-progress-menu"
          anchorEl={anchorElState[item.order.key]}
          handleClick={handleClick}
          handleClose={handleClose}
        >
          <OperatorTableMenuItem
            onClick={() => {
              props.showOrderInfo(
                item.order.key,
                OperatorOrderTab.Weighing,
                OperatorOrderMode.Weighing,
              );
            }}
            handleClose={handleClose}
            disabled={!item.order || !item.order.key}
            routePath={RoutePaths.OPERATOR_ORDER}
            text={translate('dashboard.inProgressOrders.doWeighing')}
          />
          <OperatorTableMenuItem
            onClick={() => {
              props.showOrderInfo(
                item.order.key,
                OperatorOrderTab.Order,
                OperatorOrderMode.Weighing,
              );
            }}
            handleClose={handleClose}
            disabled={false}
            routePath={RoutePaths.OPERATOR_ORDER}
            text={translate('dashboard.inProgressOrders.viewOrder')}
          />
        </OperatorTableMenu>
      ),
      useLongTextStyle: false,
    },
  ];

  function formatTruncated() {
    return props.inProgressOrders.isListTruncated ? '+' : '';
  }

  return (
    <div className={classes.content}>
      <Typography className={classes.title} variant="h5">
        <>
          {translate('dashboard.inProgressOrders.title')}
          <span className={classes.titleCount}> ({`${orders.length}${formatTruncated()}`})</span>
        </>
      </Typography>
      <OperatorTable
        columns={columns}
        data={orders}
        keyGetter={(order: InProgressOrder) => order.order.key}
      />
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(InProgressOrders);
