import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { CircularProgress } from '@mui/material';
import theme from '../theme';

export enum LoadingIndicatorPositioning {
  FIXED,
  ABSOLUTE,
}

interface LoadingIndicatorProps {
  isLoading: boolean;
  positioning?: LoadingIndicatorPositioning;
}

const spinnerSize = 90;

const useStyles = makeStyles({
  fixedPosition: {
    zIndex: theme.zIndex.snackbar + 1,
    position: 'fixed',
  },
  absolutePosition: {
    position: 'absolute',
  },
  progress: {
    top: '50%',
    left: '50%',
    marginTop: -spinnerSize / 2,
    marginLeft: -spinnerSize / 2,
  },
});

const LoadingIndicator = (props: LoadingIndicatorProps) => {
  const classes = useStyles();
  const { isLoading } = props;
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isLoading === false) {
      setIsVisible(false);
      return;
    }
    const timeOut = setTimeout(() => {
      setIsVisible(true);
    }, 500);
    return () => {
      clearTimeout(timeOut);
    };
  }, [isLoading]);

  return (
    <div>
      {isVisible && (
        <CircularProgress
          className={`${classes.progress} ${
            props.positioning === LoadingIndicatorPositioning.ABSOLUTE
              ? classes.absolutePosition
              : classes.fixedPosition
          }`}
          disableShrink={true}
          variant="indeterminate"
          size={spinnerSize}
          color="primary"
        />
      )}
    </div>
  );
};

export default LoadingIndicator;
