/* eslint-disable */
function ReceiptFormatter() {
  this.format = function(job, specification, options) {
    options = populateDefaultOptions(options);
    validateJob(job);
    validateOptions(options);
    validateSpecification(specification, options);
    var populatedLines = populateReceiptLines(job, specification, options);
    var replacedLines = replaceLines(populatedLines, specification, options);
    var localizedLines = localizeLines(replacedLines, options);
    var populatedDependencyLines = omitEmptyDependencies(localizedLines);
    var formattedLines = formatLines(populatedDependencyLines, options);
    var filteredLines = filterLines(formattedLines);
    var receipt = renderLines(filteredLines, options);
    return receipt;
  };

  function validateJob(job) {
    if (!job || "object" !== typeof job) {
      throw "job must be an object";
    }
  }

  function validateSpecification(specification, options) {
    if (!specification) throw "falsy specification";
    validateLines(specification.lines, options, "specification");
    validateReplacements(specification.replacements);
    if (specification.headerLine) {
      validateLine(specification.headerLine, options, true);
    }
    if (specification.footerLine) {
      validateLine(specification.footerLine, options, true);
    }
  }

  function validateLines(lines, options, containerType) {
    if (!lines) {
      throw (containerType + " does not contain lines");
    }
    if (!isArray(lines)) {
      throw ("invalid lines in " + containerType);
    }
    validateDependencyIdsUnique(lines);
    lines.forEach(function(line) {
      validateLine(line, options);
    });
  }

  function validateLine(line, options, isHeaderOrFooter) {
    validateOmit(line.omit);
    if (undefined === isHeaderOrFooter) isHeaderOrFooter = false;
    if (undefined !== line.type) {
      validateTypedLine(line, options);
      return;
    }
    if (undefined !== line.omitIfEmptyLine &&
        "boolean" !== typeof line.omitIfEmptyLine) {
      throw "omitIfEmptyLine must be boolean";
    }
    if (undefined !== line.blocks &&
        !isArray(line.blocks)) {
      throw "blocks of line must be an array if defined";
    }
    if (undefined !== line.elide &&
        "boolean" !== typeof line.elide) {
      throw "elide must be boolean";
    }
    validateRichTextTag(line.richTextTag);
    validateBlocks(line.blocks, line.elide, options, isHeaderOrFooter);
  }

  function validateDependencyIdsUnique(lines) {
    var ids = {};
    function parseLineIds(ids, line) {
      if (isArray(line.lines)) {
        line.lines.forEach(function(childLine) {
          parseLineIds(ids, childLine);
        });
      }
      if (!line.blocks) return;
      line.blocks.forEach(function(block) {
        if (!block.dependencyId) return;
        if (ids[block.dependencyId]) {
          throw ("dependency id " + block.dependencyId + " not unique");
        }
        ids[block.dependencyId] = true;
      });
    }
    lines.forEach(function(line) {
      parseLineIds(ids, line);
    });
  }

  function validateTypedLine(line, options) {
    function validateArray(array, type) {
      if (!isArray(array)) {
        throw (type + " type lines must contain an array");
      }
    }
    if ("FOR_EACH_CONTAINER" === line.type) {
      validateArray(line.lines, "FOR_EACH_CONTAINER");
    }
    else if ("FOR_EACH_LOAD" === line.type) {
      validateArray(line.lines, "FOR_EACH_LOAD");
      line.lines.forEach(function(child) {
        if (undefined !== child.type) {
          throw "lines with type not allowed inside FOR_EACH_LOAD";
        }
      });
      if (undefined !== line.maxDepth &&
          "number" !== typeof line.maxDepth) {
        throw "FOR_EACH_LOAD type line maxDepth must be number";
      }
      if (undefined !== line.maxDepth &&
          line.maxDepth <= 0) {
        throw "FOR_EACH_LOAD maxDepth must be positive if defined";
      }
    }
    else {
      throw ("invalid line type: " + line.type);
    }
    validateLines(line.lines, options, line.type);
  }

  function validateReplacements(replacements) {
    if (undefined === replacements) return;
    if (isArray(replacements)) {
      replacements.forEach(function(item) {
        if ("object" !== typeof item) {
          throw "replacements contains something else than objects";
        }
        function validateType(type, key) {
          if ("string" !== type &&
              "boolean" !== type &&
              "number" !== type) {
            throw "invalid type of " + key + " in replacement";
          }
        }
        validateType(typeof item.from, "from");
        validateType(typeof item.to, "to");
      });
    }
  }

  function validateRichTextTag(richTextTag) {
    if (undefined === richTextTag) return;

    if ("string" !== typeof richTextTag) {
      throw "rich text tag must be string";
    }

    if (richTextTag.indexOf("<") >= 0 ||
        richTextTag.indexOf(">") >= 0 ||
        richTextTag.indexOf("/") >= 0) {
      throw "rich text tag must not contain html tag characters";
    }
  }

  function validateOmit(omit) {
    if (omit === undefined) return;

    if (typeof omit !== 'object') {
      throw new 'invalid omit, object expected';
    }

    if (omit.type !== 'IF_EQUAL' && omit.type !== 'IF_NOT_EQUAL') {
      throw new 'invalid omit.type ' + omit.type;
    }

    if (typeof omit.value !== 'string') {
      throw new 'omit.value not string';
    }

    // NOTE(mikkogy,20220511) omit.reference is not validated at all. If it is
    // missing it is interpreted as undefined. JSON does not support explicit
    // undefined so the only way support undefined without possibility of
    // conflict with other types is to leave reference out in receipt spec. Null
    // could work but then both undefined and null would match which could cause
    // problems.
  }

  function validateBlocks(blocks, lineElide, options, isHeaderOrFooter) {
    if (undefined === blocks) return;
    blocks.forEach(function(block) {
      validateBlockAlignment(block, lineElide);
      validateBlockTextAlignment(block);
      if (isHeaderOrFooter) {
        if ("TEXT" !== block.type &&
            "HEADER_FOOTER_VALUE" !== block.type) {
          throw "invalid block type " + block.type + " in header or footer";
        }
      }
      else {
        if ("TEXT" !== block.type &&
            "JOB_PROPERTY_VALUE" !== block.type &&
            "JOB_PROPERTY_ARRAY_COUNT" !== block.type &&
            "CONTAINER_PROPERTY_VALUE" !== block.type &&
            "CONTAINER_PROPERTY_TITLE" !== block.type &&
            "CONTAINER_PROPERTY_ROLE_TITLE" !== block.type &&
            "CONTAINER_TITLE" !== block.type &&
            "LOAD_PROPERTY_VALUE" !== block.type &&
            "MASTER_DATA_PROPERTY_VALUE" !== block.type &&
            "MASTER_DATA_PROPERTY_TITLE" !== block.type &&
            "MASTER_DATA_PROPERTY_ROLE_TITLE" !== block.type) {
          throw "invalid block type " + block.type;
        }
      }
      if (!isHeaderOrFooter && "CONTAINER_TITLE" !== block.type &&
          block.type.indexOf("PROPERTY_ROLE_TITLE") < 0 &&
          "string" !== typeof block.value) {
        throw "block value must be a string";
      }
      if ("MASTER_DATA_PROPERTY_VALUE" === block.type &&
          block.value.indexOf("/") < 0) {
        throw "master data property value must contain / to refer to " +
              "property in master data document";
      }
      validateBlockElide(block);
      validateBlockLengths(block);
      validateBlockFormatting(block, options);
      validateReplacements(block.replacements);
      validateRichTextTag(block.richTextTag);
      validateBlockDependencies(block);
    });
  }

  function validateBlockAlignment(block, lineElide) {
      if (undefined !== block.alignment &&
          "ALIGN_LEFT" !== block.alignment &&
          "ALIGN_RIGHT" !== block.alignment) {
        throw "invalid block alignment";
      }
      if (false === lineElide &&
          "ALIGN_RIGHT" === block.alignment) {
        throw "line elide can't be false with right aligned block";
      }
  }

  function validateBlockTextAlignment(block) {
    if (undefined !== block.textAlignment &&
        "ALIGN_LEFT" !== block.textAlignment &&
        "ALIGN_CENTER" !== block.textAlignment &&
        "ALIGN_RIGHT" !== block.textAlignment) {
      throw "invalid block text alignment";
    }
  }

  function validateBlockElide(block) {
    if (undefined !== block.elide &&
        "ELIDE_LEFT" !== block.elide &&
        "ELIDE_CENTER" !== block.elide &&
        "ELIDE_RIGHT" !== block.elide &&
        "NO_ELIDE" !== block.elide) {
      throw "invalid block elide";
    }
    if ("NO_ELIDE" === block.elide &&
        undefined !== block.maxLength) {
      throw "block no elide is not acceptable with maxLength";
    }
    if (undefined !== block.elideString &&
        "string" !== typeof block.elideString) {
      throw "block elide string must be a string";
    }
    if (undefined !== block.elideString &&
          ("undefined" !== typeof block.maxLength &&
            ("number" !== typeof block.maxLength ||
             block.maxLength <= block.elideString.length))
        ) {
      throw "block elide string must be shorter than max length";
    }
  }

  function validateBlockLengths(block) {
    if (undefined !== block.minLength &&
        "number" !== typeof lengthToCharacterCount(block.minLength, 1)) {
      throw "block min length must be a number or a percentage string";
    }
    if (undefined !== block.maxLength &&
        "number" !== typeof lengthToCharacterCount(block.maxLength, 1)) {
      throw "block max length must be a number or a percentage string";
    }
    if (undefined !== block.minLength &&
        undefined !== block.maxLength &&
        block.minLength > block.maxLength) {
      throw "block min length must not be greater than max length";
    }
  }

  function validateBlockFormatting(block, options) {
    if (undefined === block.formatting) return;
    if ("object" !== typeof block.formatting) {
      throw "block formatting must be an object if defined";
    }
    var formatting = block.formatting;
    if ("MASS" === formatting.type) return;
    else if ("TIME" === formatting.type) {
      if (undefined !== formatting.format &&
          "string" !== typeof formatting.format) {
        throw "with formatting type TIME format must be a string if defined";
      }
    }
    else if ("FORMATTER" === formatting.type) {
      validateBlockFormatter(formatting, options);
    }
    else if (undefined !== formatting.type) {
      throw "unknown formatting type " + formatting.type;
    }
  }

  function validateBlockFormatter(formatting, options) {
    if ("string" !== typeof formatting.formatter) {
      throw "block formatter must be a string";
    }
    if ("function" !== typeof options.formatters[formatting.formatter]) {
      throw "formatter for block not found from options";
    }
  }

  function validateBlockDependencies(block) {
    if (undefined !== block.dependencyId &&
        ("string" !== typeof block.dependencyId ||
         block.dependencyId.length <= 0)) {
      throw "block dependency id must be a non-empty string if defined";
    }
    if (undefined !== block.omitIfEmptyDependency &&
        ("string" !== typeof block.omitIfEmptyDependency ||
         block.omitIfEmptyDependency.length <= 0)) {
      throw "block omit if empty dependency must be a non-empty string " +
            "if defined";
    }
    if (undefined !== block.dependencyId &&
        undefined !== block.omitIfEmptyDependency &&
        block.dependencyId !== block.omitIfEmptyDependency) {
      throw "block dependency id and omit if empty dependency must match " +
            "if both defined";
    }
  }

  function populateDefaultOptions(options) {
    var origOptions = options;
    options = copyObject(options);
    options.formatters = origOptions.formatters;
    if (undefined === options.lineLength) {
      options.lineLength = 50;
    }
    options.lineLength = Math.round(options.lineLength);
    if (undefined === options.formatters) {
      options.formatters = {};
    }
    options.moment = origOptions.moment;
    if (!options.moment) {
      throw "options does not contain moment which is used for date formatting";
    }
    options.massConverter = origOptions.massConverter;
    if (!options.massConverter) {
      throw "options does not contain mass converter";
    }
    if (undefined === options.domainConfiguration) {
      options.domainConfiguration = {};
    }
    if (!options.domainConfiguration ||
        "object" !== typeof options.domainConfiguration) {
      throw "domain configuration must be an object";
    }
    if (undefined === options.domainConfiguration.common) {
      options.domainConfiguration.common = {};
    }
    if (!options.domainConfiguration.common ||
        "object" !== typeof options.domainConfiguration.common) {
      throw "domain configuration common must be an object";
    }
    if (undefined === options.domainConfiguration.common.displayMassUnit) {
      options.domainConfiguration.common.displayMassUnit = "KG";
    }
    if (undefined === options.domainConfiguration.common.displayMassDecimals) {
      options.domainConfiguration.common.displayMassDecimals = 0;
    }
    if (undefined === options.domainConfiguration.common.receiptHeaders) {
      options.domainConfiguration.common.receiptHeaders = [];
    }
    if (undefined === options.domainConfiguration.common.receiptFooters) {
      options.domainConfiguration.common.receiptFooters = [];
    }
    if (undefined === options.localization) {
      options.localization = {};
    }
    return options;
  }

  function validateOptions(options) {
    if ("number" !== typeof options.lineLength) {
      throw "lineLength in options must be number";
    }
    if (options.lineLength <= 0) {
      throw "lineLength in options must be greater than 0";
    }
    if ("object" !== typeof options.formatters) {
      throw "formatters in options must be an object";
    }
    for (var key in options.formatters) {
      if ("function" !== typeof options.formatters[key]) {
        throw "formatter must be function in options";
      }
    }
    validateLocalization(options);
    validateDomainConfiguration(options.domainConfiguration);
  }

  function validateLocalization(options) {
    if ("object" !== typeof options.localization) {
      throw "localization in options must be an object if defined";
    }
    if (isArray(options.localization)) {
      throw "localization in options must not be an array";
    }
    for (var key in options.localization) {
      if ("string" !== typeof options.localization[key]) {
        throw "localization in options must contain strings only";
      }
    }
  }

  function validateDomainConfiguration(domainConfiguration) {
    if (!domainConfiguration || "object" !== typeof domainConfiguration) {
      throw "domain configuration must be an object";
    }
    if (!domainConfiguration.common ||
        "object" !== typeof domainConfiguration.common) {
      throw "domain configuration common must be an object";
    }
    var common = domainConfiguration.common;
    if (undefined === massUnits[common.displayMassUnit]) {
      throw "unknown domain configuration display mass unit " +
            common.displayMassUnit;
    }
    if ("number" !== typeof common.displayMassDecimals ||
        0 > common.displayMassDecimals ||
        3 < common.displayMassDecimals) {
      throw "domain configuration display mass unit must be a number " +
            "between 0 and 3";
    }
    function typedValidator(type) {
      return function validateReceiptRow(row) {
        if ("string" !== typeof row) {
          throw "receipt " + type + " row must be string";
        }
      };
    }
    function validateCommonReceiptArray(array, type) {
      if (!isArray(array)) {
        throw "receipt " + type + " must be an array";
      }
    }
    validateCommonReceiptArray(common.receiptHeaders, "headers");
    common.receiptHeaders.forEach(typedValidator("header"));
    validateCommonReceiptArray(common.receiptFooters, "footers");
    common.receiptFooters.forEach(typedValidator("footer"));
  }

  function populateReceiptLines(job, specification, options) {
    var lines = [];
    var context = { dependencies: {} };
    var common = options.domainConfiguration.common;
    function headerFooter(line) {
      if (!line) return function() {};
      return function(row) {
        var context = { row: row };
        populateReceiptLine(lines, line, null, options, context);
      };
    }
    common.receiptHeaders.forEach(headerFooter(specification.headerLine));
    populateReceiptLinesWithContext(lines, job, specification.lines, options,
                                    context);
    common.receiptFooters.forEach(headerFooter(specification.footerLine));
    return lines;
  }

  function populateReceiptLinesWithContext(lines, job, specLines, options,
                                           context) {
    specLines.forEach(function(specLine) {
      if ("FOR_EACH_CONTAINER" === specLine.type) {
        iterateContainers(lines, job, specLine, options, context);
        return;
      }
      else if ("FOR_EACH_LOAD" === specLine.type) {
        iterateLoads(lines, job, specLine, options, context);
        return;
      }
      populateReceiptLine(lines, specLine, job, options, context);
    });
  }

  function iterateContainers(lines, job, specLine, options, context) {
    var containers;
    if (context.container) {
      containers = context.container.containers;
    }
    else {
      containers = job.containers;
    }
    if (!containers || containers.length <= 0) return;
    containers.forEach(function(container, index) {
      if (shouldOmitLine(container, specLine.omit)) {
        return;
      }
      var nextContext = getNextContainerContext(container, context, options,
                                                index);
      populateReceiptLinesWithContext(lines, job, specLine.lines, options,
                                      nextContext);
    });
  }

  function iterateLoads(lines, job, specLine, options, context) {
    var loads = getLoadsForContext(context, job, specLine.maxDepth);
    loads.forEach(function(load) {
      if (shouldOmitLine(load, specLine.omit)) {
        return;
      }
      var nextContext = getNextLoadContext(context.container, context);
      nextContext.load = load;
      populateReceiptLinesWithContext(lines, job, specLine.lines, options,
                                      nextContext);
    });
  }

  function getNextContainerContext(container, context, options, index) {
    var containerSchema = {};
    if (!context.containerSchema) {
      containerSchema = getRootContainerSchema(container, options);
    }
    else {
      containerSchema = getNestedContainerSchema(container, context, options);
    }
    return { container: container, dependencies: context.dependencies,
             dependencyReplacements: {}, containerSchema: containerSchema,
             containerIndex: index };
  }

  function getNextLoadContext(container, context) {
    var containerSchema = context.containerSchema;
    var containerIndex = context.containerIndex;
    return { container: container, dependencies: context.dependencies,
             dependencyReplacements: {}, containerSchema: containerSchema,
             containerIndex: containerIndex };
  }

  function getRootContainerSchema(container, options) {
    if (!options.combo || !options.combo.properties ||
        !options.combo.properties.container) {
      return {};
    }
    var containerTypes = options.combo.properties.container.anyOf;
    for (var i = 0; i < containerTypes.length; i++) {
      for (var j = 0; j < containerTypes[i].allOf.length; j++) {
        if (!containerTypes[i].allOf[j]) continue;
        var comboContainer = containerTypes[i].allOf[j];
        if (!comboContainer.properties) continue;
        if (!comboContainer.properties.containerType) continue;
        var enumArray = comboContainer.properties.containerType.enum;
        if (!isArray(enumArray) || enumArray.length <= 0) continue;
        var type = enumArray[0];
        if (type === container.containerType) {
          return mergeSchema(containerTypes[i].allOf);
        }
      }
    }
    return {};
  }

  function mergeSchema(array) {
    var obj = { properties: {} };
    array.forEach(function(item) {
      var properties = {};
      if (item.properties) {
        properties = item.properties;
      }
      else if (item.allOf) {
        properties = mergeSchema(item.allOf).properties;
      }
      for (var key in properties) {
        if (obj.properties[key]) {
          mergeObjects(obj.properties[key], properties[key]);
          continue;
        }
        obj.properties[key] = copyObject(properties[key]);
      }
      if (!obj.title && item.title) {
        obj.title = item.title;
      }
      if (!obj.headerTemplate && item.headerTemplate) {
        obj.headerTemplate = item.headerTemplate;
      }
    });
    return obj;
  }

  // arrays are not merged
  function mergeObjects(original, other) {
    for (var i in other) {
      if (undefined === original[i]) {
        original[i] = copyObject(other[i]);
      }
      else if ("object" === typeof original[i] &&
               "object" === typeof other[i] &&
               !isArray(original[i]) &&
               !isArray(other[i])) {
        mergeObjects(original[i], other[i]);
      }
    }
  }

  function getNestedContainerSchema(container, context, options) {
    if (!context || !context.containerSchema ||
        !context.containerSchema.properties) {
      return {};
    }
    var containers = context.containerSchema.properties.containers;
    if (!containers || !containers.items) {
      return {};
    }
    if (isArray(containers.items)) {
      return getNestedContainerSchemaFromArray(containers.items,
                                               container.containerType);
    }
    else {
      if (containers.items.properties) {
        return copyObject(containers.items.properties);
      }
      else if (containers.items.allOf) {
        return mergeSchema(containers.items.allOf);
      }
      return {};
    }
  }

  function getNestedContainerSchemaFromArray(items, containerType) {
    var itemIndex = -1;
    items.forEach(function(item, index) {
      var merged = mergeSchema(item.allOf).properties;
      if (!merged || !merged.containerType) return;
      var enumArray = merged.containerType.enum;
      if (!isArray(enumArray) || enumArray.length <= 0) return;
      if (merged.containerType.enum[0] === containerType) {
        itemIndex = index;
      }
    });
    if (itemIndex < 0) return {};
    if (items[itemIndex].allOf) {
      return mergeSchema(items[itemIndex].allOf);
    }
    if (items[itemIndex].properties) {
      return mergeSchema([items[itemIndex]]);
    }
    return {};
  }

  function getLoadsForContext(context, job, maxDepth) {
    var allLoads = isArray(job.loads) ? job.loads : [];
    if (!context || !context.container) {
      return allLoads;
    }
    var loadIndexes = [];
    var container = context.container;
    maxDepth = undefined === maxDepth ? MAX_SAFE_INTEGER : maxDepth;

    function getLoadsFromContainer(container, maxDepth) {
      if (maxDepth <= 0) return;
      var indexes = container.loadIndexes;
      if (isArray(indexes)) {
        indexes.forEach(function(index) {
          if (index >= allLoads.length) return;
          loadIndexes.push(index);
        });
      }
      if (!isArray(container.containers)) return;
      container.containers.forEach(function(container) {
        getLoadsFromContainer(container, maxDepth - 1);
      });
    }
    getLoadsFromContainer(container, maxDepth);

    var loads = [];
    loadIndexes.sort();
    loadIndexes.forEach(function(loadIndex) {
      loads.push(allLoads[loadIndex]);
    });

    return loads;
  }

  function shouldOmitLine(context, omit) {
    if (!omit) return false;
    var value = getNestedObjectValue(context, omit.value);
    if (omit.type === 'IF_EQUAL' && value === omit.reference) {
      return true;
    }
    if (omit.type === 'IF_NOT_EQUAL' && value !== omit.reference) {
      return true;
    }
    return false;
  }

  function populateReceiptLine(lines, specLine, job, options,
                               context) {
    var omitContext = job;
    if (context.load) {
      omitContext = context.load;
    } else if (context.container) {
      omitContext = context.container;
    }
    if (shouldOmitLine(omitContext, specLine.omit)) {
      return;
    }
    var blocks = [];
    if (specLine.blocks) {
      specLine.blocks.forEach(function(block) {
        blocks.push(populateReceiptBlock(block, job, options, context));
      });
    }
    var omit = "boolean" === typeof specLine.omitIfEmptyLine ?
                         specLine.omitIfEmptyLine : true;
    var elide = "boolean" === typeof specLine.elide ?
                         specLine.elide : true;
    var richTextTag = specLine.richTextTag ? specLine.richTextTag : "";
    lines.push({ blocks: blocks, omitIfEmptyLine: omit, elide: elide,
             richTextTag: richTextTag });
  }

  function populateReceiptBlock(block, job, options, context) {
    var contextDependencies = replaceDependenciesInContext(block, context);
    var value = getLineValue(job, block, context, options);
    var alignment = block.alignment ? block.alignment : "ALIGN_LEFT";
    var textAlignment = block.textAlignment ?
                          block.textAlignment : "ALIGN_LEFT";
    var elideString = block.elideString ?
                        block.elideString : "";
    var elide = block.elide ? block.elide : "ELIDE_RIGHT";
    var maxLength = lengthToCharacterCount(block.maxLength,
                                           options.lineLength);
    var minLength = lengthToCharacterCount(block.minLength,
                                           options.lineLength);
    var richTextTag = block.richTextTag ? block.richTextTag : undefined;
    return {
      value: value
      , formatting: copyObject(block.formatting)
      , alignment: alignment
      , textAlignment: textAlignment
      , elide: elide
      , elideString: elideString
      , maxLength: maxLength
      , minLength: minLength
      , replacements: copyObject(block.replacements)
      , richTextTag: richTextTag
      , dependencyId: contextDependencies.dependencyId
      , omitIfEmptyDependency: contextDependencies.omitIfEmptyDependency
    };
  }

  function replaceDependenciesInContext(block, context) {
    var dependencyId = block.dependencyId;
    var omitIfEmptyDependency = block.omitIfEmptyDependency;
    if (context.dependencyReplacements) {
      if (dependencyId) {
        if (!context.dependencyReplacements[dependencyId]) {
          addDependencyReplacementToContext(context, dependencyId);
        }
        dependencyId = context.dependencyReplacements[dependencyId];
      }

      if (omitIfEmptyDependency) {
        if (!context.dependencyReplacements[omitIfEmptyDependency]) {
          addDependencyReplacementToContext(context, omitIfEmptyDependency);
        }
        omitIfEmptyDependency =
                context.dependencyReplacements[omitIfEmptyDependency];
      }
    }
    return { dependencyId: dependencyId,
             omitIfEmptyDependency: omitIfEmptyDependency };
  }

  function generateUniqueDependencyId(dependencies) {
    var candidate = "";
    while (!candidate || dependencies[candidate]) {
      candidate = "scoped_dependency_" + Math.random();
    }
    return candidate;
  }

  function addDependencyReplacementToContext(context, dependencyId) {
    if (undefined !== context.dependencies[dependencyId]) {
      throw "block dependency id not unique";
    }
    var uniqueDependencyId = generateUniqueDependencyId(context.dependencies);
    // true is used to indicate dependency id is already used
    context.dependencies[uniqueDependencyId] = true;
    context.dependencyReplacements[dependencyId] = uniqueDependencyId;
  }

  function lengthToCharacterCount(length, lineLength) {
    if ("string" === typeof length) {
      length = length.trim();
      if ("%" === length[length.length - 1]) {
        var numberLength = parseInt(length);
        if (!isNaN(numberLength)) {
          return Math.round(lineLength / 100 * numberLength);
        }
      }
    }
    return length;
  }

  function createArrayCountReducer(filterProps) {
    function reduceFunc(result, item) {
      var isCounted = true;
      var hasFilterProps = !!filterProps;
      var hasValidFilterProps = !hasFilterProps ||
        typeof filterProps === 'object';
      if (!hasValidFilterProps) {
        throw "invalid JOB_PROPERTY_ARRAY_COUNT filterProperties";
      }
      if (hasFilterProps) {
        for (var filterKey in filterProps) {
          if (!isArray(filterProps[filterKey])) {
            throw "invalid JOB_PROPERTY_ARRAY_COUNT filterProperties";
          }
          var isIncluded =
            filterProps[filterKey].indexOf(item[filterKey]);
          if (isIncluded < 0) {
            isCounted = false;
            break;
          }
        }
      }
      return result + (isCounted ? 1 : 0);
    }
    return reduceFunc;
  }

  function escapeHtml(str) {
    var map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#039;'
    };

    return str.replace(/[&<>"']/g, function(m) { return map[m]; });
  }

  function getLineValue(job, block, context, options) {
    var type = block.type;
    var valueKey = block.value;
    function safeValue(value) {
      if (undefined === value ||
          "object" === typeof value ||
          "function" === typeof value) {
        return "";
      }

      if (typeof value === "string") {
        return escapeHtml(value);
      }
      return value;
    }
    if ("TEXT" === type) return safeValue(valueKey);
    if ("JOB_PROPERTY_VALUE" === type) {
      return safeValue(getNestedObjectValue(job, valueKey));
    }
    if ("JOB_PROPERTY_ARRAY_COUNT" === type) {
      var reducer = createArrayCountReducer(block.filterProperties);
      var reduceResult =
        getNestedObjectValue(job, valueKey).reduce(reducer, 0);
      return safeValue(reduceResult);
    }
    if ("CONTAINER_TITLE" === type) {
      return safeValue(getContainerTitle(context, options));
    }
    if ("CONTAINER_PROPERTY_VALUE" === type) {
      if (!context || !context.container) return "";
      return safeValue(getNestedObjectValue(context.container, valueKey));
    }
    if ("LOAD_PROPERTY_VALUE" === type) {
      if (!context || !context.load) return "";
      return safeValue(getNestedObjectValue(context.load, valueKey));
    }
    if ("MASTER_DATA_PROPERTY_VALUE" === type) {
      return safeValue(getMasterDataPropertyValue(context, job, valueKey));
    }
    if ("MASTER_DATA_PROPERTY_TITLE" === type) {
      return safeValue(getMasterDataPropertyTitle(options, valueKey));
    }
    if ("MASTER_DATA_PROPERTY_ROLE_TITLE" === type) {
      return safeValue(getMasterDataPropertyRoleTitle(job, options, valueKey, context));
    }
    if ("CONTAINER_PROPERTY_TITLE" === type) {
      return safeValue(getContainerPropertyTitle(context, options, valueKey));
    }
    if ("CONTAINER_PROPERTY_ROLE_TITLE" === type) {
      return safeValue(getContainerPropertyRoleTitle(context, options));
    }
    if ("HEADER_FOOTER_VALUE" === type) {
      return safeValue(context.row);
    }
    return "";
  }

  function getNestedObjectValue(obj, path) {
    if (typeof obj !== "object") return "";
    var paths = path.split("/");
    if (paths.length <= 1) return obj[path];
    var remainingPath = "";
    for (var i = 1; i < paths.length; i++) {
      remainingPath += paths[i];
      if (i < paths.length - 1) remainingPath += "/";
    }
    return getNestedObjectValue(obj[paths[0]], remainingPath);
  }

  function getMasterDataPropertyValue(context, job, valueKey) {
    var keyParts = valueKey.split("/");
    if (keyParts.length < 2) return "";
    var regex = new RegExp("organization_" + UUID_PATTERN + "::" +
                           keyParts[0] + "_" + UUID_PATTERN);

    var key = getContextLinkKey(context, job, regex);
    if (!key) return "";

    var doc = getLinkedByKey(job, key);
    if (!doc) return "";

    var remainingKey = valueKey.substring(keyParts[0].length + 1);
    return getNestedObjectValue(doc, remainingKey);
  }

  function getContextLinkKey(context, job, regex) {
    if (context && context.container) {
      return getContainerLinkKey(context.container.effectiveLinks, regex);
    }
    else {
      return getOnlyMatchingLinkedKey(job, regex);
    }
  }

  function getContainerLinkKey(links, regex) {
    if (!links) return "";
    var key = "";
    for (var i = 0; i < links.length; i++) {
      if (links[i].match(regex)) {
        if (key) return "";
        key = links[i];
      }
    }
    return key;
  }

  function getOnlyMatchingLinkedKey(job, regex) {
    var key = "";
    for (var i = 0; i < job.linked.length; i++) {
      if (job.linked[i].key.match(regex)) {
        if (key) return "";
        key = job.linked[i].key;
      }
    }
    return key;
  }

  function getLinkedByKey(job, key) {
    for (var i = 0; i < job.linked.length; i++) {
      if (job.linked[i].key === key) {
        return job.linked[i];
      }
    }
    return null;
  }

  function getMasterDataPropertyTitle(options, key) {
    if (!options || !options.combo ||
        typeof options.combo.properties !== "object") {
      return "";
    }
    var properties = options.combo.properties;
    if (!properties[key] ||
        typeof properties[key] !== "object") {
      return "";
    }
    var data = properties[key];
    if (undefined === data.title && data.allOf) {
      var temp = {};
      data.allOf.forEach(function(item) {
        var copy = copyObject(item);
        // nested allOf is skipped because including it produces confusing
        // results as arrays are not merged by mergeObjects
        // here we are only interested in title which should not be in allOf
        copy.allOf = undefined;
        mergeObjects(temp, copy);
      });
      data = temp;
    }
    return data.title;
  }

  function getMasterDataPropertyRoleTitle(job, options, key, context) {
    if (!options || !options.combo ||
        typeof options.combo.properties !== "object") {
      return "";
    }
    var properties = options.combo.properties;
    if (!properties[key] ||
        typeof properties[key] !== "object") {
      return "";
    }
    var data = properties[key];
    var role = "";
    if (!context || !context.container || !context.container.effectiveLinks) {
      return "";
    }
    var links = context.container.effectiveLinks;
    for (var i = 0; i < links.length; i++) {
      var linked = getLinkedByKey(job, links[i]);
      if (linked.docType.toLowerCase() === key) {
        role = linked.effectiveRole;
      }
    }
    if (!role) return "";
    for (i = 0; i < data.roles.length; i++) {
      if (data.roles[i] === role) return data.roleTitles[i];
    }
    return "";
  }

  function getContainerPropertyTitle(context, options, key) {
    if (!context || !context.containerSchema ||
        typeof context.containerSchema.properties !== "object" ||
        typeof context.containerSchema.properties[key] !== "object") {
      return "";
    }
    return context.containerSchema.properties[key].title;
  }

  function getContainerPropertyRoleTitle(context, options) {
    if (!options || !options.combo ||
        typeof options.combo.properties !== "object") {
      return "";
    }
    var properties = options.combo.properties;
    if (!properties.container ||
        typeof properties.container !== "object") {
      return "";
    }
    var data = properties.container;
    if (!data.roles) return "";
    if (!context || !context.container) return "";
    var role = context.container.effectiveRole;
    for (var i = 0; i < data.roles.length; i++) {
      if (data.roles[i] === role) return data.roleTitles[i];
    }
    return "";
  }

  function getContainerTitle(context, options) {
    if (!context || !context.containerSchema ||
        typeof context.containerSchema !== "object") {
      if (options && options.combo && options.combo.properties &&
          options.combo.properties.container &&
          options.combo.properties.container.title) {
        return options.combo.properties.container.title;
      }
      return "";
    }
    var schema = context.containerSchema;
    if (schema.headerTemplate) return schema.headerTemplate;
    if (schema.title) return schema.title;
    return "";
  }

  function formatValue(value, block, options) {
    if ("object" !== typeof block.formatting) return value;
    var formatting = block.formatting;
    if ("MASS" === formatting.type) {
      value = formatMassValue(value, block, options);
    }
    else if ("TIME" === formatting.type) {
      value = options.moment(value).format(formatting.format);
    }
    if (undefined === formatting.formatter) return value;

    return options.formatters[formatting.formatter](value, block);
  }

  function formatMassValue(value, block, options) {
    if ("number" !== typeof value) return value;
    var common = options.domainConfiguration.common;
    var displayMassUnit = common.displayMassUnit;
    var displayMassDecimals = common.displayMassDecimals;
    var converted = options.massConverter.convert(value, "KG", displayMassUnit);
    var addSpace = !block || !block.formatting ||
      block.formatting.massFormatting !== 'COMPACT';
    return formatMass(converted, displayMassDecimals,
                      displayMassUnit, addSpace);
  }

  function replaceLines(lines, specification, options) {
    var replacedLines = [];
    var tempLines = copyObject(lines);
    tempLines.forEach(function(line) {
      var replaced = replaceLine(line, specification.replacements, options);
      replacedLines.push(replaced);
    });
    return replacedLines;
  }

  function replaceLine(line, globalReplacements, options) {
    var copy = copyObject(line);
    copy.blocks.forEach(function(block) {
      var isPrereplacement =
        block.formatting &&
        block.formatting.mode === "PRE_REPLACEMENT";
      if (isPrereplacement) {
        block.value = formatValue(block.value, block, options);
      }
      if (block.replacements) {
        block.value = replaceLineValue(block.value, block.replacements);
      }
      if (globalReplacements) {
        block.value = replaceLineValue(block.value, globalReplacements);
      }
      if (!isPrereplacement) {
        block.value = formatValue(block.value, block, options);
      }
    });
    return copy;
  }

  function replaceLineValue(value, replacements) {
    replacements.forEach(function(replacement) {
      if (replacement.from === value) {
        value = escapeHtml(replacement.to);
      }
    });
    return value;
  }

  function localizeLines(lines, options) {
    var localizedLines = lines.map(function(line) {
      return localizeLine(line, options);
    });
    return localizedLines;
  }

  function localizeLine(line, options) {
    var copy = copyObject(line);
    copy.blocks.forEach(function(block) {
      block.value = localizeValue(block.value, options);
    });
    return copy;
  }

  function localizeValue(value, options) {
    if (typeof value !== "string") return value;
    if (options.localization[value] !== undefined) {
      return options.localization[value];
    }
    return value;
  }

  function omitEmptyDependencies(lines) {
    lines = copyObject(lines);
    var dependencies = parseDependencies(lines);
    lines.forEach(function(line){
      omitLineEmptyDependencies(line, dependencies);
    });
    return lines;
  }

  function omitLineEmptyDependencies(line, dependencies) {
    if (!line.blocks) return;
    for (var i = 0; i < line.blocks.length; i++) {
      var block = line.blocks[i];
      if (!block.omitIfEmptyDependency) {
        continue;
      }
      if (undefined === dependencies[block.omitIfEmptyDependency]) {
        throw "dependency id " + block.omitIfEmptyDependency + " missing";
      }
      if (isEmptyDependency(dependencies[block.omitIfEmptyDependency])) {
        line.blocks[i] = undefined;
      }
    }
    line.blocks = line.blocks.filter(function(block) {
      return block;
    });
  }

  function isEmptyDependency(value) {
    if (undefined === value) return true;
    if ("" === value) return true;
    if (null === value) return true;
    return false;
  }

  function parseDependencies(lines) {
    var dependencies = {};
    lines.forEach(function(line) {
      if (!line.blocks) return;
      line.blocks.forEach(function(block) {
        if (!block.dependencyId) return;
        if (dependencies[block.dependencyId]) {
          throw ("dependency id " + block.dependencyId + " not unique");
        }
        dependencies[block.dependencyId] = block.value;
      });
    });
    return dependencies;
  }

  function filterLines(lines) {
    var filtered = [];
    var tempLines = copyObject(lines);
    tempLines.forEach(function(line) {
      if (line.value.length <= 0 && line.omitIfEmptyLine) {
        return;
      }
      filtered.push(line.value);
    });
    return filtered;
  }

  function formatLines(lines, options) {
    var formattedLines = [];
    lines.forEach(function(line) {
      formattedLines.push({
        value: formatLine(line, options),
        omitIfEmptyLine: line.omitIfEmptyLine
      });
    });
    return formattedLines;
  }

  function formatLine(line, options) {
    var lineState = {};
    lineState.leftIndex = 0;
    lineState.rightIndex = getLineInitialRightIndex(line, options);
    lineState.result = "";
    if ("undefined" === line.blocks || 0 === line.blocks.length) {
      return "";
    }
    lineState.remaining = calculateRemainingLength(lineState.rightIndex + 1,
                                                   line.blocks);
    lineState.richTextExtraLength = 0;
    line.blocks.forEach(function(block) {
      if (lineState.leftIndex >= lineState.rightIndex) return;
      formatLineBlock(block, lineState);
    });
    return formatLineResult(line, lineState.result, options,
                            lineState.richTextExtraLength);
  }

  function formatLineBlock(block, lineState) {
    var blockMax = getRemainingForBlock(block, lineState.remaining,
                                        lineState.leftIndex,
                                        lineState.rightIndex);
    var formatResult = formatBlockValue(block, blockMax);
    var value = formatResult.value;
    var richTextExtraLength = formatResult.richTextExtraLength;
    var richTextRemaining = lineState.remaining + richTextExtraLength;
    lineState.remaining = updateRemainingLength(richTextRemaining, value,
                                                block);
    if (block.alignment === "ALIGN_LEFT") {
      lineState.result = renderBlock(lineState.result, value, true,
                                     lineState.leftIndex, lineState.rightIndex,
                                     richTextExtraLength);
      lineState.richTextExtraLength += richTextExtraLength;
      lineState.leftIndex += value.length;
      lineState.rightIndex += richTextExtraLength;
      return;
    }
    else if (block.alignment === "ALIGN_RIGHT") {
      lineState.result = renderBlock(lineState.result, value, false,
                                     lineState.leftIndex, lineState.rightIndex,
                                     richTextExtraLength);
      lineState.richTextExtraLength += richTextExtraLength;
      lineState.rightIndex -= value.length - richTextExtraLength;
      return;
    }
    throw ("unknown alignment: " + block.alignment);
  }

  function formatLineResult(line, result, options, richTextExtraLength) {
    var elided = result;
    if (line.elide) {
      elided = result.substring(0, options.lineLength + richTextExtraLength);
    }
    if (!line.richTextTag) return elided;
    var tag = line.richTextTag;
    return applyRichTextTag(tag, elided);
  }

  function applyRichTextTag(tag, value) {
    if (!tag) return value;
    return "<" + tag + ">" + value + "</" + tag + ">";
  }

  function getLineInitialRightIndex(line, options) {
    return line.elide ?
              (options.lineLength - 1) : (MAX_SAFE_INTEGER - 1);
  }

  function getRemainingForBlock(block, remaining, leftIndex, rightIndex) {
    var blockRemaining = remaining;
    if (undefined !== block.minLength) blockRemaining = MAX_SAFE_INTEGER;
    if ("NO_ELIDE" === block.elide) blockRemaining = MAX_SAFE_INTEGER;
    return Math.min(blockRemaining, rightIndex - leftIndex + 1);
  }

  function updateRemainingLength(remaining, value, block) {
    if ("NO_ELIDE" === block.elide) return remaining;
    if (undefined !== block.minLength) return remaining;
    return remaining - value.length;
  }

  function calculateRemainingLength(lineLength, blocks) {
    var remainingLength = lineLength;
    blocks.forEach(function(block) {
      if (undefined !== block.minLength) {
        remainingLength -= block.minLength;
        return;
      }
      if ("NO_ELIDE" === block.elide) {
        remainingLength -= ("" + block.value).length;
        return;
      }
    });
    return remainingLength;
  }

  function formatBlockValue(block, remainingLength) {
    var value = "" + block.value;

    if (undefined !== block.maxLength && value.length > block.maxLength) {
      value = elideString(block.maxLength, value, block.elideString,
                          block.elide);
    }
    var remainingForCurrent = remainingLength - value.length;
    if (remainingForCurrent < 0) {
      value = elideString(remainingLength, value, block.elideString,
                          block.elide);
    }

    var blockOrigLength = value.length;
    value = applyRichTextTag(block.richTextTag, value);
    var richTextExtraLength = value.length - blockOrigLength;
    var minLength = block.minLength + richTextExtraLength;
    var maxLength = block.maxLength + richTextExtraLength;
    if (undefined !== block.minLength && remainingForCurrent > 0 &&
        value.length < minLength) {
      value = padBlockValue(value, block, richTextExtraLength,
                            remainingForCurrent);
    }

    return { value: value, richTextExtraLength: richTextExtraLength };
  }

  function elideString(maxLength, value, elideString, elide) {
    if (maxLength <= 0) return "";

    if ("ELIDE_CENTER" === elide) {
      return elideStringCenter(value, maxLength, elideString);
    }
    if ("ELIDE_RIGHT" === elide ||
        "NO_ELIDE" === elide) {
      // NO_ELIDE should be taken into account in maxLength already
      // if it has been elide will not make a difference
      return elideStringRight(value, maxLength, elideString);
    }
    if ("ELIDE_LEFT" === elide) {
      return elideStringLeft(value, maxLength, elideString);
    }

    throw ("unknown elide: " + elide);
  }

  function elideStringCenter(value, maxLength, elideString) {
    var remainingLength = maxLength - elideString.length;
    if (remainingLength <= 0) {
      elideString = "";
    }
    var removeLength = value.length - maxLength + elideString.length;
    var centerIndex = Math.floor(value.length / 2);
    var lastLeft = removeLength % 2 === 0 ?
                    centerIndex - removeLength / 2 :
                    centerIndex - Math.floor(removeLength / 2);
    var lastRight = removeLength % 2 === 0 ?
                    centerIndex + removeLength / 2 :
                    centerIndex + Math.ceil(removeLength / 2);
    return value.substring(0, lastLeft) + elideString +
           value.substring(lastRight, value.length);
  }

  function elideStringRight(value, maxLength, elideString) {
    var remainingLength = maxLength - elideString.length;
    if (remainingLength <= 0) {
      return value.substring(0, maxLength);
    }
    return value.substring(0, remainingLength) + elideString;
  }

  function elideStringLeft(value, maxLength, elideString) {
    var remainingLength = maxLength - elideString.length;
    if (remainingLength <= 0) {
      return value.substring(value.length - maxLength, value.length);
    }
    return elideString +
           value.substring(value.length - remainingLength, value.length);
  }

  function padBlockValue(value, block, richTextExtraLength, remaining) {
    var paddingLength = Math.min(value.length + remaining,
                     block.minLength + richTextExtraLength);
    if ("ALIGN_CENTER" === block.textAlignment) {
      return padCenterAlignedBlockValue(value, block, paddingLength);
    }
    var sidePaddingLength = paddingLength + richTextExtraLength - value.length;
    var sidePadding = Array(sidePaddingLength + 1).join(" ");
    if ("ALIGN_LEFT" === block.textAlignment) {
      return value + sidePadding;
    }
    if ("ALIGN_RIGHT" === block.textAlignment) {
      return sidePadding + value;
    }
    throw ("unknown text alignment: " + block.textAlignment);
  }

  function padCenterAlignedBlockValue(value, block, remaining) {
    var halfPaddingLength = (remaining - value.length) / 2;
    var leftPadding;
    var rightPadding = Array(Math.floor(halfPaddingLength) + 1).join(" ");
    if (halfPaddingLength !== Math.floor(halfPaddingLength)) {
      leftPadding = Array(Math.floor(halfPaddingLength) + 2).join(" ");
    }
    else {
      leftPadding = rightPadding;
    }
    return leftPadding + value + rightPadding;
  }

  function renderBlock(formatted, blockValue, applyLeft, leftIndex,
                       rightIndex, richTextExtraLength) {
    var originalLength = formatted.length;
    var blockOrigLength = blockValue.length - richTextExtraLength;
    if (formatted.length === leftIndex && applyLeft) {
      return appendBlockLeft(formatted, blockValue);
    }
    if (applyLeft) {
      return applyBlockLeft(formatted, blockValue, leftIndex, blockOrigLength);
    }
    if (!applyLeft) {
      return applyBlockRight(formatted, blockValue, rightIndex,
                             richTextExtraLength, blockOrigLength);
    }
    throw "internal error rendering block to line";
  }

  function appendBlockLeft(formatted, blockValue) {
    return formatted + blockValue;
  }

  function applyBlockLeft(formatted, blockValue, leftIndex,
                          blockOrigLength) {
    var before = formatted.substring(0, leftIndex);
    var after = formatted.substring(leftIndex + blockOrigLength);
    var result = before + blockValue + after;
    return result;
  }

  function applyBlockRight(formatted, blockValue, rightIndex,
                           richTextExtraLength, blockOrigLength) {
    if (formatted.length < rightIndex - 1) {
      var fillLength = rightIndex + richTextExtraLength - formatted.length + 1;
      formatted += Array(fillLength + 1).join(" ");
    }
    var beforeValueLength = rightIndex - blockOrigLength + 1;
    var before = formatted.substring(0, beforeValueLength);
    var after = formatted.substring(rightIndex + 1);
    var result = before + blockValue + after;
    return result;
  }

  function renderLines(lines, options) {
    var result = "";
    var lf = "<br>";
    var lineCount = 0;
    lines.forEach(function(line) {
      lineCount++;
      if (0 === result.length && 1 === lineCount) {
        result += line;
        return;
      }
      result = result + lf + line;
    });
    result = result.replace(/ /g, "&nbsp;");
    if (result.length > 0) result += lf;
    return result;
  }

  function copyObject(obj) {
    if (undefined === obj) return undefined;
    return JSON.parse(JSON.stringify(obj));
  }

  function isArray(array) {
    return array && "function" === typeof array.forEach;
  }

  var massUnits = {
    KG: "kg",
    METRIC_TON: "t",
    LONG_TON: "t",
    SHORT_TON: "t",
    LB: "lb"
  };

  function formatMass(value, decimals, unit, addSpace) {
    if ("KG" === unit || "LB" === unit) decimals = 0;
    var spaceStr = addSpace ? " " : "";
    return value.toFixed(decimals) + spaceStr + massUnits[unit];
  }

  // Number.MAX_SAFE_INTEGER is not available in QML
  var MAX_SAFE_INTEGER = Math.pow(2, 53) - 1;

  var UUID_PATTERN = "[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}";
}

module.exports = ReceiptFormatter;
