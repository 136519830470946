export enum DeepLinkActionTypes {
  CLEAR = '@@deep-link/CLEAR',
  FAILURE = '@@deep-link/FAILURE',
  LOAD = '@@deep-link/LOAD',
  SET_ORDER_KEY = '@@deep-link/SET_ORDER_KEY',
  SET_RETURN_LINK = '@@deep-link/SET_RETURN_LINK',
  START_LOADING = '@@deep-link/START_LOADING',
  SUCCESS = '@@deep-link/SUCCESS',
}

export interface DeepLinkState {
  readonly hasError: boolean;
  readonly inProgressDeepLinkId?: string;
  readonly isDeepLinkViewVisible: boolean;
  readonly orderKey?: string;
  readonly returnLink?: string;
}
