import React from 'react';

import { connect, localeConnect } from '../localeConnect';

import { ApplicationState } from '../store';

import { ContainerType } from '../store/common/types';

import { loadWeighedKgs } from '../store/jobs/utils';

import { Context, WeighingProcess } from '../store/orders/types';
import {
  getContextMainJob,
  getNormalWeighingMainComponents,
  secondaryContainerTareKgs,
} from '../store/orders/utils';

import { BridgeEnabledFeatures } from '../store/scale-info/types';

import { undefinedMassKg } from '../store/weighing/types';

import { filterValidLoads, isLoadFixOrFixed } from '../store/utils';

interface ParameterProps {
  areAdditionalConditionsSatisfied?: boolean;
  children: React.ReactNode;
  context: Context;
  isExistingTareRequired: boolean;
}

interface PropsFromState {
  enabledFeatures: BridgeEnabledFeatures;
}

/*eslint-disable */
export type AllProps = PropsFromState & ParameterProps;
/*eslint-enable */

const mapStateToProps = (state: ApplicationState) => ({
  enabledFeatures: state.currentScaleInfo.enabledFeatures,
});

const mapDispatchToProps = {};

export function isPresetTareApplicable(
  enabledFeatures: BridgeEnabledFeatures,
  context: Context,
  isExistingTareRequired: boolean,
  areAdditionalConditionsSatisfied?: boolean,
) {
  const areConditionsSatisfied =
    areAdditionalConditionsSatisfied === undefined || areAdditionalConditionsSatisfied === true;
  if (!areConditionsSatisfied) return false;
  if (!enabledFeatures.bridgePrimaryContainerTare) return false;
  const job = getContextMainJob(context);
  if (!job) return false;
  const container = job.containers[0];
  if (isExistingTareRequired && !context.presetTares[container?.key]) return false;
  if (container?.containerType !== ContainerType.TRUCK) return false;
  if (context.process !== WeighingProcess.NORMAL) return false;
  const allLoads = job.loads;
  const validLoads = filterValidLoads(allLoads);
  if (validLoads.length !== 1) return false;
  if (isLoadFixOrFixed(allLoads[allLoads.length - 1])) return false;
  return true;
}

export function hasMassError(context: Context, enabledFeatures: BridgeEnabledFeatures) {
  const job = getContextMainJob(context);
  if (!job) return true;
  const presetTare = context?.presetTares[job?.containers[0]?.key];
  if (!job || !presetTare) return true;
  const allLoads = job.loads || [];
  if (allLoads.length === 0) return true;
  const lastLoad = allLoads[allLoads.length - 1];
  const components = getNormalWeighingMainComponents(context.order);
  const secondaryTareKgs = secondaryContainerTareKgs(context.order, components.truck.id);
  const secondaryContainerApplicableTareKgs =
    enabledFeatures.bridgeSecondaryContainerTareDeduction && secondaryTareKgs !== undefinedMassKg
      ? secondaryTareKgs
      : 0;
  const hasError = !(
    presetTare?.massKg <
    loadWeighedKgs(lastLoad) - secondaryContainerApplicableTareKgs
  );
  return hasError;
}

function PrimaryContainerTareFeature(props: AllProps) {
  if (
    !isPresetTareApplicable(
      props.enabledFeatures,
      props.context,
      props.isExistingTareRequired,
      props.areAdditionalConditionsSatisfied,
    )
  )
    return null;
  return <div>{props.children}</div>;
}

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(PrimaryContainerTareFeature);
