import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Grid } from '@mui/material';
import { connect, localeConnect } from 'localeConnect';
import { ApplicationState } from 'store';
import { filterValidLoads } from 'store/utils';
import { ContainerType } from 'store/common/types';
import { Job } from 'store/jobs/types';
import {
  activateContext,
  setManualKgs,
  toggleMeasDeviceGroupReversing,
} from 'store/meas-devices/actions';
import { MeasDevice, MeasDeviceGroup, MeasDeviceGroupReversing } from 'store/meas-devices/types';
import { OperatorOrder, WeighingProcess } from 'store/orders/types';
import { getContextMainJob } from 'store/orders/utils';
import { getCurrentUserSettings } from 'store/selectors';
import { BridgeEnabledFeatures } from 'store/scale-info/types';
import { UserSpecificSettings } from 'store/settings/types';
import { UserDetails } from 'store/user/types';
import {
  discardPreviousLoadByContextId,
  doManualWeighingByContextId,
  doWeighingByContextId,
  finishContextById,
  finishWithContextTare,
  inactivateContextById,
  saveTareAndFinishContext,
} from 'store/weighing/actions';
import { undefinedMassKg, WeighingState } from 'store/weighing/types';
import { createOrderDetailsModel } from 'models/orderdetails.model';
import {
  contextToNormalVehicleWeighingModels,
  getCurrentMultipartJob,
  toMultipartVehicleWeighingModels,
  VehicleWeighingModels,
} from 'models/vehicleweighing.model';
import { setInspectionStatus } from 'store/jobs/actions';
import { separatorColor } from 'theme';
import { translate } from 'utils/translate';
import SettingsSaveTareDialog, {
  isShowingSaveTareDialogApplicable,
} from '../SettingsSaveTareDialog';
import OperatorMeasDevice, { MeasDeviceMode } from './OperatorMeasDevice';
import OperatorMeasDeviceSelect from './OperatorMeasDeviceSelect';
import OperatorMultipartWeighing from './OperatorMultipartWeighing';
import OperatorNormalWeighing from './OperatorNormalWeighing';
import OperatorInspectionStatus from './OperatorInspectionStatus';
import OperatorTrafficControlGroup from './OperatorTrafficControlGroup';

const useStyles = makeStyles({
  content: {
    minHeight: '200px',
  },
  headerContent: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    justifyContent: 'space-between',
    minHeight: '80px',
    paddingBottom: '15px',
    borderBottom: 'solid 2px',
    borderBottomColor: separatorColor,
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  deviceContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  device: {
    // eslint-disable-next-line
    marginTop: '20px',
    // eslint-disable-next-line
    display: 'flex',
    // eslint-disable-next-line
    overflow: 'hidden',
    '& .MuiGrid-item': {
      marginTop: '1px',
      marginBottom: '1px',
      textAlign: 'left',
      paddingLeft: '20px',
    },
  },
  weighing: {
    marginTop: '10px',
  },
});

interface ParameterProps {
  operatorOrder: OperatorOrder;
}

interface PropsFromState {
  enabledFeatures: BridgeEnabledFeatures;
  operatorTrailerKey?: string;
  manualKgs: Record<string, number>;
  measDeviceGroups: MeasDeviceGroup[] | undefined;
  measDeviceGroupReversing: MeasDeviceGroupReversing;
  measDevices: MeasDevice[];
  scaleKey: string;
  user?: UserDetails;
  userSettings: UserSpecificSettings;
  weighingState: WeighingState;
}

interface PropsFromDispatch {
  activateContext: typeof activateContext;
  discardPreviousLoadByContextId: typeof discardPreviousLoadByContextId;
  doManualWeighingByContextId: typeof doManualWeighingByContextId;
  doWeighingByContextId: typeof doWeighingByContextId;
  finishContextById: typeof finishContextById;
  finishWithContextTare: typeof finishWithContextTare;
  inactivateContextById: typeof inactivateContextById;
  saveTareAndFinishContext: typeof saveTareAndFinishContext;
  setInspectionStatus: typeof setInspectionStatus;
  setManualKgs: typeof setManualKgs;
  toggleMeasDeviceGroupReversing: typeof toggleMeasDeviceGroupReversing;
}

const mapStateToProps = (state: ApplicationState) => ({
  enabledFeatures: state.currentScaleInfo.enabledFeatures,
  operatorTrailerKey: state.weighing.operatorTrailerKey,
  manualKgs: state.measDevices.manualKgs,
  measDeviceGroups: state.measDevices.measDeviceGroups,
  measDeviceGroupReversing: state.measDevices.measDeviceGroupReversing,
  measDevices: state.measDevices.measDevices,
  scaleKey: state.currentScaleInfo.scaleKey,
  user: state.user.user.userData,
  userSettings: getCurrentUserSettings(state),
  weighingState: state.weighing,
});

const mapDispatchToProps = {
  activateContext,
  discardPreviousLoadByContextId,
  doManualWeighingByContextId,
  doWeighingByContextId,
  finishContextById,
  finishWithContextTare,
  inactivateContextById,
  saveTareAndFinishContext,
  setInspectionStatus,
  setManualKgs,
  toggleMeasDeviceGroupReversing,
};

type AllProps = ParameterProps & PropsFromDispatch & PropsFromState;

// NOTE(mikkogy,20210316) for manual weighing we need to store kgs to be able to
// restore them on discard. To store them locally we give them unique ids based
// job, meas device group, container and weighing round.
function getManualWeighingId(job: Job, measDeviceGroupId: string) {
  const validLoadCount = filterValidLoads(job.loads).length;
  const rootContainer = job.containers[0];
  const jobId = job.key.split('::')[2];
  let weighingIds: string[];
  if (rootContainer.containerType === ContainerType.TRUCK_TRAILER && rootContainer.containers) {
    const truckKey = rootContainer.containers[0].key;
    const trailerKey = rootContainer.containers[1].key;
    weighingIds = [`${truckKey}-1`, `${trailerKey}-1`, `${truckKey}-2`, `${trailerKey}-2`];
  } else {
    const truckKey = rootContainer.key;
    weighingIds = [`${truckKey}-1`, `${truckKey}-2`];
  }
  return `${jobId}-${measDeviceGroupId}-${weighingIds[validLoadCount]}`;
}

interface ActionsProps {
  finishContext: () => void;
  isDisabled: boolean;
}

const Actions = (props: ActionsProps) => {
  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        onClick={props.finishContext}
        disabled={props.isDisabled}
      >
        {translate('operatorOrder.weighing.finish')}
      </Button>
    </div>
  );
};

const OperatorWeighing = (props: AllProps) => {
  const classes = useStyles();
  const [isTareSavingDialogVisible, setIsTareSavingDialogVisible] = React.useState(false);

  const context =
    props.operatorOrder && props.operatorOrder.context ? props.operatorOrder.context : undefined;

  if (!props.measDeviceGroups) return null;

  const onMeasDeviceGroupSelected = (key: string) => {
    if (!context) {
      throw new Error('context must exist');
    }
    props.activateContext(context.contextId, key);
  };

  const onMeasDeviceGroupUnselected = () => {
    if (!context) {
      throw new Error('context must exist');
    }
    props.inactivateContextById(context.contextId);
  };

  let job: Job | null = null;
  if (context && context.weighingJobs && context.weighingJobs.length > 0) {
    if (
      context.process === WeighingProcess.NORMAL ||
      context.process === WeighingProcess.UNDEFINED
    ) {
      job = getContextMainJob(context) ?? null;
    } else if (context.process === WeighingProcess.MULTIPART) {
      if (context.weighingJobs) {
        job = getCurrentMultipartJob(context.weighingJobs);
      }
    } else {
      throw new Error(`Unknown process, ${context.process}`);
    }
  }
  const measDeviceStatuses = props.weighingState.status;
  const contextMeasDeviceKeys = context ? [...context.measDeviceKeys] : [];
  const inUseMeasDeviceKeys = context ? [...context.measDeviceKeys] : [];
  const isReversed = !!context && !!props.measDeviceGroupReversing[context.measDeviceGroupId];
  if (isReversed) {
    inUseMeasDeviceKeys.reverse();
  }
  let statuses = inUseMeasDeviceKeys.map((key) => {
    return (
      { ...measDeviceStatuses[key], key } || {
        key: key,
        canSum: false,
        isStable: false,
        massKg: undefinedMassKg,
      }
    );
  });

  const finishContext = () => {
    if (!context) {
      throw new Error('context must exist');
    }
    if (
      isShowingSaveTareDialogApplicable(
        context || undefined,
        props.userSettings,
        props.enabledFeatures,
      )
    ) {
      setIsTareSavingDialogVisible(true);
      return;
    }
    props.finishContextById(context.contextId, false);
  };

  const discardWeighing = () => {
    if (!context) {
      throw new Error('context must exist');
    }
    props.discardPreviousLoadByContextId(context.contextId);
  };

  let manualWeighingId = '';
  const orderDetailsModel = createOrderDetailsModel(
    context,
    context?.order,
    context?.process ?? props.operatorOrder?.process ?? WeighingProcess.UNDEFINED,
    context?.weighingJobs ?? [],
    props.scaleKey,
    props.user,
  );
  let weighingModels: VehicleWeighingModels;
  const measDeviceGroup = props.measDeviceGroups.find(
    (g) => context && g.id === context.measDeviceGroupId,
  );
  if (!context || !context.weighingJobs || !job) {
    // NOTE(mikkogy,20220715) weighing is not possible without context or job.
    // We probably should not be here. It is not really expected to end up here
    // because this is a weighing component.
    console.warn('Unexpectedly trying to render OperatorWeighing without context or job.');
    return <div />;
  }
  if (measDeviceGroup && measDeviceGroup.isManual) {
    manualWeighingId = getManualWeighingId(job, measDeviceGroup.id);
    const kgs = props.manualKgs[manualWeighingId] ?? undefinedMassKg;
    statuses = [
      {
        key: measDeviceGroup.id,
        canSum: true,
        isStable: true,
        massKg: kgs,
      },
    ];
  }
  if (context.process === WeighingProcess.NORMAL) {
    weighingModels = contextToNormalVehicleWeighingModels(context, statuses, props.enabledFeatures);
  } else if (context.process === WeighingProcess.MULTIPART) {
    weighingModels = toMultipartVehicleWeighingModels(context.weighingJobs, statuses);
  } else {
    throw new Error('should not get here');
  }

  const doWeighing = () => {
    if (!context) {
      throw new Error('context must exist');
    }
    if (inUseMeasDeviceKeys.length === 0) {
      throw new Error('measDevices must be selected');
    }
    if (measDeviceGroup && measDeviceGroup.isManual) {
      let kgs = 0;
      if (manualWeighingId && props.manualKgs[manualWeighingId] !== undefined) {
        kgs = props.manualKgs[manualWeighingId];
      }
      props.doManualWeighingByContextId(context.contextId, kgs);
    } else {
      props.doWeighingByContextId(context.contextId, inUseMeasDeviceKeys);
    }
  };

  const finishWithContextTare = () => {
    if (!context) {
      throw new Error('context must exist');
    }
    props.finishWithContextTare(context.contextId);
  };

  const saveTareAndFinish = () => {
    if (!context) {
      throw new Error('context must exist');
    }
    props.saveTareAndFinishContext(context.contextId);
  };

  const isReversible = inUseMeasDeviceKeys.length > 1;
  const reverseTruckTrailer = () => {
    if (!context) return;
    props.toggleMeasDeviceGroupReversing(context.measDeviceGroupId);
  };

  const setManualKgHandler = (containerKey: string, kgs: number) => {
    props.setManualKgs(manualWeighingId, kgs);
  };

  const trafficControlGroupId = measDeviceGroup?.trafficControlGroupId;

  return (
    <div className={classes.content}>
      <SettingsSaveTareDialog
        context={context || undefined}
        onAnswer={(saveTare: boolean) => {
          if (!context) return;
          props.finishContextById(context.contextId, saveTare);
        }}
        visible={isTareSavingDialogVisible}
      />

      <Box className={classes.headerContent}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={3} lg={2} xl={2}>
            {context && (
              <OperatorMeasDeviceSelect
                selectionTrailerKey={props.operatorTrailerKey}
                weighingContext={context}
                onSelect={onMeasDeviceGroupSelected}
                onUnselect={onMeasDeviceGroupUnselected}
              />
            )}
          </Grid>
          <Grid item sm={12} md={6} lg={6} xl={5}>
            <Box className={classes.deviceContainer}>
              {contextMeasDeviceKeys.map((key) => {
                const measDevice = props.measDevices.find((device) => device.key === key);
                if (!measDevice) {
                  return null;
                }
                return (
                  <Box key={key} className={classes.device}>
                    <OperatorMeasDevice measDevice={measDevice} mode={MeasDeviceMode.Compact} />
                  </Box>
                );
              })}
            </Box>
          </Grid>
          <Grid item sm={12} md={3} lg={4} xl={5}>
            {!!trafficControlGroupId && (
              <OperatorTrafficControlGroup
                alignRight={true}
                trafficControlGroupId={trafficControlGroupId}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <div className={classes.weighing}>
        {context && (
          <OperatorInspectionStatus
            weighingContext={context}
            setInspectionStatus={props.setInspectionStatus}
          />
        )}
        {context && context.process !== WeighingProcess.MULTIPART && job && (
          <OperatorNormalWeighing
            context={context}
            doWeighing={doWeighing}
            discardWeighing={discardWeighing}
            finishWithContextTare={finishWithContextTare}
            isReversible={isReversible}
            isReversed={isReversed}
            job={job}
            measDeviceGroup={measDeviceGroup}
            reverseTruckTrailer={reverseTruckTrailer}
            saveTareAndFinish={saveTareAndFinish}
            setManualKgHandler={setManualKgHandler}
            weighingModels={weighingModels}
            actions={
              context &&
              job && (
                <Actions
                  finishContext={finishContext}
                  isDisabled={
                    !weighingModels.isFinishingEnabled ||
                    !orderDetailsModel.hasAllRequiredMasterData
                  }
                />
              )
            }
          />
        )}
        {context && context.process === WeighingProcess.MULTIPART && job && (
          <OperatorMultipartWeighing
            context={context}
            doWeighing={doWeighing}
            discardWeighing={discardWeighing}
            isReversible={isReversible}
            isReversed={isReversed}
            isSettingMasterDataAllowed={!orderDetailsModel.isOrderUpdateDisabled}
            jobs={context.weighingJobs}
            measDeviceGroup={measDeviceGroup}
            reverseTruckTrailer={reverseTruckTrailer}
            setManualKgHandler={setManualKgHandler}
            weighingModels={weighingModels}
            actions={
              context &&
              job && (
                <Actions
                  finishContext={finishContext}
                  isDisabled={
                    !weighingModels.isFinishingEnabled ||
                    !orderDetailsModel.hasAllRequiredMasterData
                  }
                />
              )
            }
          />
        )}
      </div>
      {}
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(OperatorWeighing);
