import { all, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import { RoutePaths } from 'routes';
import { incomingEvent, sendMessage } from '../common/actions';
import {
  LiveDataTypes,
  RemoteMasterDataType,
  RemoteReceiveTypes,
  RemoteRequestIds,
  RemoteRequestTypes,
  ContainerType,
} from '../common/types';
import { loadHistoryItems, masterDataSelected } from '../master-data/actions';
import { MasterDataActionTypes } from '../master-data/types';
import { getSelectedVehicle, getWebsocketConnected } from '../selectors';

import { selectVehicle, updateSelectedVehicle, vehicleSelected } from './actions';

import { VehiclesActionTypes } from './types';

function* handleLoadHistoryItems(action: ReturnType<typeof loadHistoryItems>): any {
  const selectedVehicleKey = (yield select(getSelectedVehicle))?.key;
  if (!selectedVehicleKey) {
    return;
  }
  const payload = {
    msgType: RemoteRequestTypes.LOAD_MASTER_DATA_BY_KEYS,
    reqId: RemoteRequestIds.LOAD_MASTER_DATA_BY_KEYS_SELECTED_VEHICLE,
    payload: {
      masterDataType: RemoteMasterDataType.CONTAINER,
      subtype: ContainerType.TRUCK,
      masterDataKeys: [selectedVehicleKey],
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleSelect(action: ReturnType<typeof selectVehicle>) {
  console.log('Selected vehicle', action.payload.vehicle?.key);
  yield put(vehicleSelected(action.payload.vehicle, action.payload.trailerKey));
  yield put(
    masterDataSelected(
      { type: RemoteMasterDataType.CONTAINER, subtype: ContainerType.TRUCK },
      action.payload.vehicle,
    ),
  );
  yield put(replace(RoutePaths.ORDERS));
}

function* handleChange() {
  yield put(replace(RoutePaths.VEHICLES));
}

function* handleResponse(action: ReturnType<typeof incomingEvent>) {
  if (action.payload.msgType === RemoteReceiveTypes.MASTER_DATA_BY_KEYS) {
    if (action.payload.respId === RemoteRequestIds.LOAD_MASTER_DATA_BY_KEYS_SELECTED_VEHICLE) {
      yield put(updateSelectedVehicle(action.payload.payload.masterData));
    }
  }
}

function* watchSelectVehicle() {
  yield takeLatest(VehiclesActionTypes.SELECT_VEHICLE, handleSelect);
}

function* watchClearVehicle() {
  yield takeLatest(VehiclesActionTypes.CHANGE_VEHICLE, handleChange);
}

function* watchLoadHistoryItems() {
  yield takeLatest(MasterDataActionTypes.LOAD_HISTORY_ITEMS, handleLoadHistoryItems);
}

function* watchResponse() {
  yield takeLatest(LiveDataTypes.INCOMING_EVENT, handleResponse);
}

function* vehiclesSaga() {
  yield all([
    fork(watchLoadHistoryItems),
    fork(watchSelectVehicle),
    fork(watchSelectVehicle),
    fork(watchClearVehicle),
    fork(watchResponse),
  ]);
}

export default vehiclesSaga;
