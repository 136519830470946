import React from 'react';
import { makeStyles } from '@mui/styles';

import { Button, Card, Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { connect, localeConnect } from 'localeConnect';

import { Split, SplitLoadModel } from 'models/splitload.model';

import { ApplicationState } from 'store';

import { RemoteMasterDataType } from 'store/common/types';

import {
  deleteSplit,
  editMasterData,
  editTypableMasterData,
  editTypableMasterDataImmediately,
  setSplitAmount,
} from 'store/orders/actions';
import { ComponentGroup, Context, PendingMasterDataUpdates } from 'store/orders/types';

import { undefinedMassKg } from 'store/weighing/types';

import { fadedGreyColor, separatorFadedColor } from 'theme';
import { translate } from 'utils/translate';

import DeleteShareDialog from 'components/DeleteShareDialog';

import { formatMass } from '../utils';

import SplitAmountInput from '../SplitAmountInput';

import ComponentMasterData from './ComponentMasterData';

const useStyles = makeStyles({
  amountInputContainer: {
    // eslint-disable-next-line
    paddingLeft: '16px',
    '& .MuiInput-root:before': {
      border: 0,
    },
  },
  closeButton: {
    // eslint-disable-next-line
    minWidth: 0,
    // eslint-disable-next-line
    width: '32px',
    '& .MuiButton-startIcon': {
      margin: 0,
    },
  },
  splitCard: {
    '& .MuiTextField-root': {
      border: 0,
      marginTop: '0px',
    },
  },
  splitCardTop: {
    margin: '0px 9px 0px 9px',
    padding: '12px 0px 8px 0px',
    borderBottom: `1px solid ${separatorFadedColor}`,
  },
  splitMass: {
    color: fadedGreyColor,
  },
  vehicleShareTitle: {
    fontSize: '20px',
    fontWeight: 600,
  },
});

interface ParameterProps {
  context: Context;
  componentId: string;
  index: number;
  isEditingMasterDataAllowed: boolean;
  split: Split;
  splitLoadModel: SplitLoadModel;
}

interface PropsFromState {
  pendingMasterDataUpdates: PendingMasterDataUpdates;
  schema?: Record<string, unknown>;
}

interface PropsFromDispatch {
  deleteSplit: typeof deleteSplit;
  editMasterData: typeof editMasterData;
  editTypableMasterData: typeof editTypableMasterData;
  editTypableMasterDataImmediately: typeof editTypableMasterDataImmediately;
  setSplitAmount: typeof setSplitAmount;
}

const mapStateToProps = (state: ApplicationState) => ({
  pendingMasterDataUpdates: state.orders.pendingMasterDataUpdates,
  schema: state.currentScaleInfo.domainInfo.masterDataCombo.schema,
});

const mapDispatchToProps = {
  deleteSplit,
  editMasterData,
  editTypableMasterData,
  editTypableMasterDataImmediately,
  setSplitAmount,
};

type AllProps = ParameterProps & PropsFromDispatch & PropsFromState;

const OrderEditorSplit = (props: AllProps) => {
  const classes = useStyles();
  const [isDeleteSplitDialogVisible, setIsDeleteSplitDialogVisible] = React.useState(false);

  const splitTitle = (componentGroup: ComponentGroup, index: number) => {
    if (componentGroup === ComponentGroup.TRAILER) {
      return translate('orders.multipleShares.trailerShareNumber', { shareNumber: index + 1 });
    }
    return translate('orders.multipleShares.truckShareNumber', { shareNumber: index + 1 });
  };

  return (
    <div>
      {props.splitLoadModel.isSplitEnabled && (
        <Card className={classes.splitCard}>
          <div className={classes.splitCardTop}>
            <Grid container justifyContent="space-between">
              <Grid item>
                {props.splitLoadModel.componentGroup !== undefined && (
                  <Typography variant="h3" className={classes.vehicleShareTitle}>
                    {splitTitle(props.splitLoadModel.componentGroup, props.index)}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  className={classes.closeButton}
                  aria-label={translate('orders.multipleShares.delete')}
                  disabled={!props.split.isDeletingEnabled}
                  onClick={() => {
                    setIsDeleteSplitDialogVisible(true);
                  }}
                  startIcon={<CloseIcon />}
                ></Button>
              </Grid>
            </Grid>
            <div className={classes.amountInputContainer}>
              <SplitAmountInput
                hasError={props.splitLoadModel.areAllAmountsZero}
                isDisabled={false}
                split={props.split}
                label={translate('orders.multipleShares.amount')}
                onChanged={(value: number) => {
                  props.setSplitAmount(props.context.contextId, props.split.component.id, value);
                }}
              />
              <div className={classes.splitMass}>
                {formatMass(props.split.kgs ?? undefinedMassKg)}
              </div>
            </div>
          </div>
          <ComponentMasterData
            context={props.context}
            componentId={props.split.component.id}
            excludedTypes={[RemoteMasterDataType.CONTAINER]}
            includedTypes={undefined}
            isEditingMasterDataAllowed={props.isEditingMasterDataAllowed}
          />
        </Card>
      )}
      {!props.splitLoadModel.isSplitEnabled && (
        <ComponentMasterData
          context={props.context}
          componentId={props.split.component.id}
          excludedTypes={undefined}
          includedTypes={undefined}
          isEditingMasterDataAllowed={props.isEditingMasterDataAllowed}
        />
      )}
      <DeleteShareDialog
        shareNumber={props.index + 1}
        visible={isDeleteSplitDialogVisible}
        onConfirmed={() => {
          props.deleteSplit(props.context.contextId, props.split.component.id);
          setIsDeleteSplitDialogVisible(false);
        }}
        onNotConfirmed={() => setIsDeleteSplitDialogVisible(false)}
      />
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(OrderEditorSplit);
