import React from 'react';

import { makeStyles } from '@mui/styles';
import { Avatar, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import { ContainerType } from 'store/common/types';
import { Job } from 'store/jobs/types';
import { Context, WeighingProcess } from 'store/orders/types';
import { selectMeasDeviceGroup } from 'store/orders/actions';
import { getContextMainJob, hasContextRequiredMasterData } from 'store/orders/utils';
import { BridgeEnabledFeatures } from 'store/scale-info/types';
import { UserSpecificSettings } from 'store/settings/types';
import { filterValidLoads } from 'store/utils';

import { WeighingIcon } from 'components/CustomIcons';

import SettingsSaveTareDialog, {
  isShowingSaveTareDialogApplicable,
} from 'components/SettingsSaveTareDialog';
import { contextToNormalVehicleWeighingModels } from 'models/vehicleweighing.model';
import CommonOrderDetails from '../CommonOrderDetails';
import DriverPrimaryContainerTare from './DriverPrimaryContainerTare';
import DriverSaveTruckTare from './DriverSaveTruckTare';

const useStyles = makeStyles({
  addPhotoButton: {
    display: 'block',
    marginLeft: 'auto',
  },
  addPhotoContainer: {
    width: '100%',
  },
  weighButtonContainer: {
    // eslint-disable-next-line
    WebkitPosition: '-webkit-sticky',
    // eslint-disable-next-line
    position: 'sticky',
    // eslint-disable-next-line
    bottom: '-50px',
    // eslint-disable-next-line
    float: 'right',
    // eslint-disable-next-line
    transform: 'translate(-10px, -72px)',
    '& .Mui-disabled .MuiAvatar-colorDefault': {
      backgroundColor: 'rgba(0, 0, 0, 0.26)',
    },
  },
  actionAvatars: {
    width: '50px',
    height: '50px',
  },
  primaryContainerTare: {
    marginTop: '16px',
    textAlign: 'left',
  },
});

interface AllProps {
  confirmWeighing: (isSaveTareRequested: boolean) => void;
  context: Context;
  editOrder: (multipartComponentId: string | undefined) => void;
  enabledFeatures: BridgeEnabledFeatures;
  finishWithContextTare: () => void;
  saveTareAndFinish: () => void;
  scaleKey: string;
  selectMeasDeviceGroup: typeof selectMeasDeviceGroup;
  userSettings: UserSpecificSettings;
}

const DriverOrderDetails = (props: AllProps) => {
  const classes = useStyles();
  const [isTareSavingDialogVisible, setIsTareSavingDialogVisible] = React.useState(false);

  const jobs: Job[] = props.context?.weighingJobs ?? [];
  const mainJob = getContextMainJob(props.context);
  const hasWeighingJobTruckTrailer =
    mainJob?.containers[0].containerType === ContainerType.TRUCK_TRAILER;
  const expectedValidLoadCount = hasWeighingJobTruckTrailer ? 4 : 2;
  const areWeighingsDone =
    props.context.process === WeighingProcess.NORMAL &&
    filterValidLoads(mainJob?.loads).length >= expectedValidLoadCount;
  const isMultipartFull =
    props.context.process === WeighingProcess.MULTIPART &&
    filterValidLoads(jobs[jobs.length - 1].loads).length >= expectedValidLoadCount;

  const isWeighingButtonDisabled = () => {
    if (!areWeighingsDone) return false;
    const normalWeighingModel = contextToNormalVehicleWeighingModels(
      props.context,
      [],
      props.enabledFeatures,
    );
    return !hasContextRequiredMasterData(props.context) || !normalWeighingModel.isFinishingEnabled;
  };

  function onFinish() {
    if (
      isShowingSaveTareDialogApplicable(props.context, props.userSettings, props.enabledFeatures)
    ) {
      setIsTareSavingDialogVisible(true);
      return;
    }
    props.confirmWeighing(false);
  }

  return (
    <div>
      <SettingsSaveTareDialog
        context={props.context}
        onAnswer={(saveTare: boolean) => {
          props.confirmWeighing(saveTare);
        }}
        visible={isTareSavingDialogVisible}
      />
      <CommonOrderDetails
        context={props.context}
        editDeductions={() => {
          /* NOTE(mikkogy,20220221) irrelevant for driver. */
        }}
        editOrder={props.editOrder}
        isEditingDeductionsSupported={false}
        isRouteShown={true}
      />
      <DriverPrimaryContainerTare
        context={props.context}
        enabledFeatures={props.enabledFeatures}
        finishWithContextTare={props.finishWithContextTare}
        rootClass={classes.primaryContainerTare}
      />
      <DriverSaveTruckTare
        context={props.context}
        saveTareAndFinish={props.saveTareAndFinish}
        scaleKey={props.scaleKey}
        rootClass={classes.primaryContainerTare}
      />
      {!isMultipartFull && (
        <div className={classes.weighButtonContainer}>
          <Button
            disabled={isWeighingButtonDisabled()}
            onClick={areWeighingsDone ? onFinish : props.selectMeasDeviceGroup}
          >
            <Avatar className={classes.actionAvatars}>
              {areWeighingsDone ? <CheckIcon /> : <WeighingIcon />}
            </Avatar>
          </Button>
        </div>
      )}
      <div style={{ clear: 'both' }} />
    </div>
  );
};

export default DriverOrderDetails;
