import React, { useState } from 'react';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

import { makeStyles } from '@mui/styles';
import { AppBar, Button, Container, IconButton, Toolbar, Typography } from '@mui/material';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';

import { defaultRoot } from 'theme';

import { translate } from 'utils/translate';
import DeepLinkReturnLink from './DeepLinkReturnLink';

const useStyles = makeStyles({
  cameraContainer: {
    marginTop: '20px',
  },
  image: {
    width: '80%',
  },
  navTitle: {
    flexGrow: 1,
  },
});

interface ParameterProps {
  orderKey: string;
  addImage: (dataUri: string) => void;
  goBack: () => void;
  showDeepLinkReturnLink: boolean;
}

export const AddPhoto = (props: ParameterProps) => {
  const classes = useStyles(props);

  const [dataUri, setUri] = useState('');
  const [cameraOn, setCameraOn] = useState(false);
  const [cameraError, setCameraError] = useState('');

  const onTakePhoto = (dataUri: string) => {
    setUri(dataUri);
  };

  const onCameraStart = () => {
    setCameraOn(true);
  };

  const onCameraError = (e: any) => {
    setCameraOn(true);
    setCameraError(e);
  };

  const addImage = () => {
    if (props.orderKey) {
      props.addImage(dataUri);
    }
  };

  return (
    <div style={defaultRoot}>
      <AppBar position="relative">
        {props.showDeepLinkReturnLink && <DeepLinkReturnLink isContextIgnored={false} />}
        <Toolbar>
          <IconButton color="inherit" aria-label="Return" edge="start" onClick={props.goBack}>
            <BackIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.navTitle}>
            {translate('camera.title')}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container className={classes.cameraContainer}>
        {!cameraOn && <div>{translate('camera.loading')}</div>}
        {cameraError && <div>{translate('camera.error')}</div>}
        {!dataUri && !cameraError && (
          <Camera
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            idealResolution={{ width: 1920, height: 1080 }}
            imageType={IMAGE_TYPES.JPG}
            imageCompression={0.9}
            isImageMirror={false}
            onCameraStart={onCameraStart}
            onCameraError={onCameraError}
            onTakePhoto={(dataUri: string) => {
              onTakePhoto(dataUri);
            }}
          />
        )}
        {!!dataUri && <img alt="Taken" src={dataUri} className={classes.image} />}
        <Button
          fullWidth
          disabled={!dataUri}
          onClick={addImage}
          variant="contained"
          color="primary"
        >
          {translate('camera.save')}
        </Button>
      </Container>
    </div>
  );
};

export default AddPhoto;
