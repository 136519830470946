import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import theme from './theme';

import './index.css';

import configureStore from './configureStore';

declare global {
  interface Window {
    initialReduxState: any;
  }
}

const initialState = window.initialReduxState;

const history = createBrowserHistory();

const { store, persistor } = configureStore(history, initialState);

const rootElement = document.getElementById('root');
if (rootElement === null) {
  throw Error(
    'FATAL: No element found from document with id root, application cannot be rendered!',
  );
}
const root = createRoot(rootElement);
root.render(<App store={store} persistor={persistor} history={history} theme={theme} />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
