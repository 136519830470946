import moment from 'moment';

import { ContainerItem, ContainerType, DocType } from '../common/types';
import { SplitLoad } from '../orders/types';
import { filterValidLoads } from '../utils';
import {
  DeductionRow,
  Job,
  JobContainer,
  JobQueryParams,
  JobStatus,
  Load,
  LoadType,
  OperatorQueryParams,
} from './types';

export const getManualDeductions = (weighingJobs: Job[]) => {
  const deductionArray: DeductionRow[] = [];
  weighingJobs.forEach((deductionJob, index) => {
    if (index <= 0) {
      return;
    }
    const mainContainer = deductionJob.containers[0];
    let container: ContainerItem;
    if (mainContainer.containerType === ContainerType.TRUCK_TRAILER) {
      const activeSubContainer = mainContainer.containers?.find(
        (container) => container.sumMassKg !== 0,
      );
      if (!activeSubContainer) {
        return;
      }
      container = activeSubContainer;
    } else {
      container = mainContainer;
    }
    const containerKey = container.key;
    const linkedMaterials = deductionJob.linked.filter(
      (deductionJob) => deductionJob.docType === DocType.MATERIAL,
    );
    const deduction: DeductionRow = {
      containerKey,
      containerType: container.containerType,
      isAddition: deductionJob.loads[0].loadType === LoadType.TARE,
      jobKey: deductionJob.key,
      kgs: deductionJob.loads[0].massKg,
      material: linkedMaterials.length > 0 ? linkedMaterials[0] : undefined,
      rowKey: `deduction_row_${index}`,
      comment: deductionJob.comment ?? '',
    };
    deductionArray.push(deduction);
  });
  return deductionArray;
};

export const areDeductionKgsValid = (kgs: number) => {
  const deductionKgsMin = 0;
  // NOTE(mikkogy,20210217) upper limit is arbitrary but we need something to
  // avoid excessively large values that can be problematic.
  const deductionKgsMax = 300000;

  const absoluteKgs = Math.abs(kgs);
  const areAbsoluteKgsInRange = absoluteKgs >= deductionKgsMin && absoluteKgs <= deductionKgsMax;
  const areAbsoluteKgsDefined = absoluteKgs !== 0;
  return areAbsoluteKgsInRange && areAbsoluteKgsDefined;
};

export const hasDeductionError = (manualDeductions: DeductionRow[]) => {
  const hasAnyDeductionError = manualDeductions.some((deduction) => {
    return !areDeductionKgsValid(deduction.kgs);
  });

  return hasAnyDeductionError;
};

export const jobsTrucksAndTrailers = (jobs: Job[]) => {
  const result: JobContainer[] = [];

  if (!jobs) return result;

  const containerKeys: { [key: string]: boolean } = {};
  jobs.forEach((job) =>
    job.containers
      .filter((link) => {
        return (
          link.docType === DocType.CONTAINER &&
          (link.containerType === ContainerType.TRUCK_TRAILER ||
            link.containerType === ContainerType.TRUCK ||
            link.containerType === ContainerType.TRAILER)
        );
      })
      .map((item) => item as JobContainer)
      .forEach((container) => {
        if (container.containerType === ContainerType.TRUCK_TRAILER && container.containers) {
          container.containers.forEach((child) => {
            if (!containerKeys[child.key]) {
              result.push(child);
              containerKeys[child.key] = true;
            }
          });
        } else {
          if (!containerKeys[container.key]) {
            result.push(container);
            containerKeys[container.key] = true;
          }
        }
      }),
  );
  return result;
};

export function defaultOperatorQueryParams(): OperatorQueryParams {
  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 1);
  const startTime = moment(startDate.getTime()).startOf('day').toDate().getTime();
  const endTime = moment().endOf('day').toDate().getTime();
  return {
    endTime,
    startTime,
    page: 0,
    size: 10,
    masterData: [],
  };
}

export function defaultDriverQueryParams(): JobQueryParams {
  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 1);
  const startTime = moment(startDate.getTime()).startOf('day').toDate().getTime();
  const endTime = moment().endOf('day').toDate().getTime();
  const status: JobStatus = JobStatus.COMPLETED;
  return {
    endTime,
    startTime,
    page: 0,
    size: 10,
    status,
  };
}

export function getJobLoadMassByValidIndex(index: number, job?: Job) {
  const noResult = '-';
  if (!job || !job.loads) {
    return noResult;
  }
  const filteredLoads = filterValidLoads(job.loads);
  if (filteredLoads.length >= index + 1) {
    return `${Math.abs(filteredLoads[index].massKg)} kg`;
  } else return noResult;
}

// NOTE(mikkogy,20210430) not really relevant for FIX but supported anyway.
export function loadWeighedKgs(load: Load) {
  if (load.loadType === LoadType.TARE) {
    return -load.massKg;
  }
  return load.massKg;
}

export function splitLoadFromJobs(jobs: Job[]): SplitLoad | undefined {
  const splitLoad: SplitLoad = {};
  jobs.forEach((job) => {
    if (job.splitInformation === undefined) return;
    splitLoad[job.order.componentId] = {
      amount: job.splitInformation.splitAmount,
      kgs: job.sumMassKg,
    };
  });
  if (Object.keys(splitLoad).length > 0) {
    return splitLoad;
  }
  return undefined;
}

export function sortJobsByReceiptNumberDescending(jobs: Job[]) {
  function sortFunction(job1: Job, job2: Job) {
    if (!job1.receiptNumber) {
      return 1;
    }
    if (!job2.receiptNumber) {
      return -1;
    }
    if (job1.receiptNumber > job2.receiptNumber) {
      return -1;
    } else if (job2.receiptNumber > job1.receiptNumber) {
      return 1;
    }
    return 0;
  }
  return [...jobs.sort(sortFunction)];
}
