import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import { ContainerTare } from 'store/common/types';

import theme from 'theme';

import { translate } from 'utils/translate';
import { NonNegativeIntInput } from '../NonNegativeIntInput';

export interface DialogContainer {
  key?: string;
  name: string;
  tare?: ContainerTare;
}

interface DialogProps {
  initialContainer: DialogContainer;
  visible: boolean;
  saveDisabled: boolean;
  hasError: boolean;
  onSave: (container: DialogContainer) => void;
  onClose: () => void;
}

const createStyles = makeStyles({
  root: {
    // eslint-disable-next-line
    minWidth: '350px',
    '& .MuiFormGroup-root div': {
      marginBottom: '10px',
    },
  },
  error: {
    color: theme.palette.error.main,
    marginTop: '15px',
  },
});

const tareKgsMin = 0;
// NOTE(mikkogy,20210826) upper limit is arbitrary but we need something to
// avoid excessively large values that can be problematic.
const tareKgsMax = 100000;

function areTareKgsValid(kgs: number) {
  return kgs >= tareKgsMin && kgs <= tareKgsMax;
}

const OperatorGenericContainerDialog = (props: DialogProps) => {
  const classes = createStyles();
  const [currentContainer, setCurrentContainer] = React.useState({ ...props.initialContainer });
  const [isDisabledByTimer, setIsDisabledByTimer] = React.useState(false);

  React.useEffect(() => {
    if (props.visible) {
      setCurrentContainer({ ...props.initialContainer });
    }
  }, [props.visible, setCurrentContainer, props.initialContainer]);

  function doSave() {
    setIsDisabledByTimer(true);
    const container = { ...currentContainer };
    const tareMassKg = container?.tare?.massKg;
    if (tareMassKg === undefined || tareMassKg === 0) {
      delete container.tare;
    }
    props.onSave(container);
    setTimeout(() => setIsDisabledByTimer(false), 2000);
  }

  function doClose() {
    props.onClose();
  }

  return (
    <div>
      <Dialog open={props.visible} onClose={doClose}>
        <DialogTitle>
          {translate(
            `operatorGenericContainers.dialog.${currentContainer?.key ? 'update' : 'create'}Title`,
          )}
        </DialogTitle>
        <DialogContent className={classes.root}>
          <FormGroup>
            <TextField
              variant="standard"
              value={currentContainer.name}
              onChange={(e: any) =>
                setCurrentContainer({
                  ...currentContainer,
                  name: e.target.value,
                })
              }
              label={translate('operatorGenericContainers.name')}
            />
            <NonNegativeIntInput
              allowUndefined={false}
              hideZero={true}
              isDisabled={false}
              isOutOfRangePrevented={false}
              showReset={true}
              label={translate('operatorGenericContainers.tare')}
              initialValue={currentContainer?.tare?.massKg || 0}
              max={tareKgsMax}
              min={tareKgsMin}
              onChanged={(kgs: number | undefined) => {
                if (kgs === undefined) return;
                setCurrentContainer({
                  ...currentContainer,
                  tare: {
                    massKg: kgs,
                    timestamp: new Date().getTime(),
                  },
                });
              }}
              hasError={false}
              updateInitialValue={true}
              useAutoFocus={false}
              useFullWidth={false}
            />
          </FormGroup>
          {props.hasError && (
            <Typography variant="body1" className={classes.error}>
              {translate(
                `operatorGenericContainers.dialog.error${
                  currentContainer?.key ? 'Update' : 'Create'
                }`,
              )}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={doClose} color="primary" variant="outlined">
            {translate('operatorGenericContainers.dialog.cancel')}
          </Button>
          <Button
            onClick={doSave}
            disabled={
              props.saveDisabled ||
              isDisabledByTimer ||
              !areTareKgsValid(currentContainer?.tare?.massKg || 0) ||
              !currentContainer?.name
            }
            color="primary"
            variant="contained"
            autoFocus={true}
          >
            {translate(
              `operatorGenericContainers.dialog.${currentContainer?.key ? 'update' : 'create'}`,
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OperatorGenericContainerDialog;
