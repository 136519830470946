import { action } from 'typesafe-actions';
import { CommonActionTypes, LiveDataTypes, ToastContext, ToastType } from './types';

export const connectionRequest = () => action(LiveDataTypes.CONNECTION_REQUEST);
export const connectionSuccess = () => action(LiveDataTypes.CONNECTION_SUCCESS);
export const hello = () => action(LiveDataTypes.HELLO);
export const sendMessage = (payload: any) => action(LiveDataTypes.SEND_MESSAGE, payload);
export const incomingEvent = (payload: any) => action(LiveDataTypes.INCOMING_EVENT, payload);
export const connectionError = (error: string) => action(LiveDataTypes.CONNECTION_ERROR, error);
export const setIsReceivingData = (isReceivingData: boolean) =>
  action(LiveDataTypes.SET_IS_RECEIVING_DATA, isReceivingData);

export const disconnectRequest = () => action(LiveDataTypes.DISCONNECT_REQUEST);
export const disconnectSuccess = () => action(LiveDataTypes.DISCONNECT_SUCCESS);

export const disconnectSocketTasks = () => action(LiveDataTypes.DISCONNECT_SOCKET_TASKS);
export const disconnectSocketSaga = () => action(LiveDataTypes.DISCONNECT_SOCKET_SAGA);

export const newToast = (
  messageKey: string,
  type: ToastType = ToastType.DEFAULT,
  context: ToastContext = {},
) => action(CommonActionTypes.NEW_TOAST, { messageKey, context, type });
export const showToast = (messageKey: string, type: ToastType) =>
  action(CommonActionTypes.SHOW_TOAST, { messageKey, type });
export const hideToast = () => action(CommonActionTypes.HIDE_TOAST);

export const updateLastEventTime = () => action(LiveDataTypes.UPDATE_LAST_EVENT_TIME);
