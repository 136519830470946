import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Card, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  WeighingProcess,
  InProgressOrder,
  InProgressOrders,
  InspectorOrderStatus,
  InspectorOrderTab,
} from 'store/orders/types';
import { filterInspectorInProgressOrders } from 'store/orders/utils';
import { isAnyNetNegative, toNormalVehicleWeighingModels } from 'models/vehicleweighing.model';
import { BridgeEnabledFeatures } from 'store/scale-info/types';
import { translate } from 'utils/translate';

const useStyles = makeStyles({
  errorIcon: {
    transform: 'translate(0px, 7px)',
  },
  root: {
    marginTop: '-20px',
  },
  tabSelection: {
    '& .MuiTab-wrapper': {
      letterSpacing: '1.25px',
    },
  },
});

interface ParameterProps {
  inProgressOrders: InProgressOrders;
  enabledFeatures: BridgeEnabledFeatures;
  inspectorOrderTab: InspectorOrderTab;
  setSelectedTab: (inspectorOrderTab: InspectorOrderTab) => void;
}

type AllProps = ParameterProps;

const InspectorOrderTabSelection = (props: AllProps) => {
  const classes = useStyles();
  const handleChange = (event: any, inspectorOrderTab: InspectorOrderTab) => {
    props.setSelectedTab(inspectorOrderTab);
  };

  const openOrders = filterInspectorInProgressOrders(
    props.inProgressOrders.orders,
    InspectorOrderTab.OPEN,
  );
  const inspectedOrders = filterInspectorInProgressOrders(
    props.inProgressOrders.orders,
    InspectorOrderTab.APPROVED,
  );

  function hasError(inProgressOrders: InProgressOrder[]) {
    const hasError = inProgressOrders.reduce((hasError: boolean, inProgress: InProgressOrder) => {
      if (hasError) return true;
      const isMultipartWeighing = inProgress.process === WeighingProcess.MULTIPART;
      if (isMultipartWeighing) return false;
      const models = toNormalVehicleWeighingModels(
        inProgress.weighingJobs,
        [],
        inProgress.order,
        props.enabledFeatures,
        inProgress.splitLoad,
      );
      return isAnyNetNegative(models);
    }, false);
    return hasError;
  }

  const tabs = [
    {
      index: InspectorOrderTab.OPEN,
      id: InspectorOrderStatus.OPEN,
      count: openOrders.length,
      hasError: hasError(openOrders),
    },
    {
      index: InspectorOrderTab.APPROVED,
      id: InspectorOrderStatus.APPROVED,
      count: inspectedOrders.length,
      hasError: hasError(inspectedOrders),
    },
  ];

  return (
    <Card className={classes.root}>
      <Tabs
        className={classes.tabSelection}
        variant="fullWidth"
        value={props.inspectorOrderTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            label={
              <div>
                {`
                ${translate(`inspector.orders.statusOptions.${tab.id}`)}
                ${tab.count > 0 && tab.id === InspectorOrderStatus.OPEN ? `(${tab.count})` : ''}
                `}
                {tab.hasError ? (
                  <ErrorOutlineIcon className={classes.errorIcon} color={'error'} />
                ) : undefined}
              </div>
            }
            id={`inspector-query-tab-${tab.id}`}
            aria-controls={`inspector-query-tab-${tab.id}`}
          />
        ))}
      </Tabs>
    </Card>
  );
};

export default InspectorOrderTabSelection;
