import React from 'react';
import { FormControl, FormLabel, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { WeighingProcess } from 'store/orders/types';
import { translate } from 'utils/translate';

interface Props {
  onChange: (type: WeighingProcess) => void;
  disabled?: boolean;
  value: WeighingProcess;
}

const useStyles = makeStyles({
  radioGroup: {
    flexDirection: 'row',
  },
});

const OperatorWeighingType = (props: Props) => {
  const classes = useStyles();

  const onChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    props.onChange(e.target.value as WeighingProcess);
  };

  return (
    <FormControl variant="standard" component="fieldset">
      <FormLabel component="legend">
        {translate('operatorOrder.orderDetails.weighingType')}
      </FormLabel>
      <RadioGroup
        className={classes.radioGroup}
        aria-label="weighing-type"
        name="weighing-type"
        value={props.value}
        onChange={onChange}
      >
        <FormControlLabel
          value={WeighingProcess.NORMAL}
          control={<Radio color="primary" disabled={props.disabled} />}
          label={translate('operatorOrder.orderDetails.weighingNormal')}
        />
        <FormControlLabel
          value={WeighingProcess.MULTIPART}
          control={<Radio color="primary" disabled={props.disabled} />}
          label={translate('operatorOrder.orderDetails.weighingMultipart')}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default OperatorWeighingType;
