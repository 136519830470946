import { Document } from '../common/types';

export interface Scale extends Document {
  name: string;
  type: string;
}

export enum SitesActionTypes {
  FETCH_REQUEST = '@@sites/FETCH_REQUEST',
  FETCH_SUCCESS = '@@sites/FETCH_SUCCESS',
  FETCH_ERROR = '@@sites/FETCH_ERROR',
  SELECT_SITE = '@@sites/SELECT_SITE',
  SELECTED = '@@sites/SELECTED',
  CHANGE_SITE = '@@sites/CHANGE_SITE',
  START_SITES_POLL = '@@sites/START_SITES_POLL',
  STOP_SITES_POLL = '@@sites/STOP_SITES_POLL',
}

export interface SitesState {
  readonly loading: boolean;
  readonly data: Scale[];
  readonly errors?: string;
  readonly selectedKey?: string;
  readonly lastSelectedKeys: string[];
  readonly autoselectSite: boolean;
}
