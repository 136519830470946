import { action } from 'typesafe-actions';
import { Scale, SitesActionTypes } from './types';

export const fetchError = (error?: string) => action(SitesActionTypes.FETCH_ERROR, error);
export const fetchRequest = () => action(SitesActionTypes.FETCH_REQUEST);
export const startSitesPoll = () => action(SitesActionTypes.START_SITES_POLL);
export const stopSitesPoll = () => action(SitesActionTypes.STOP_SITES_POLL);
export const fetchSuccess = (data: Scale[]) => action(SitesActionTypes.FETCH_SUCCESS, data);
export const selectSite = (scaleKey: string) => action(SitesActionTypes.SELECT_SITE, scaleKey);
export const siteSelected = (scaleKey: string) => action(SitesActionTypes.SELECTED, scaleKey);
export const changeSite = (autoselect = false) => action(SitesActionTypes.CHANGE_SITE, autoselect);
