import {
  ApiResponse,
  ContainerItem,
  ContainerType,
  DetailedMasterDataType,
  Pagination,
  ServerPagination,
  MasterDataItem,
} from '../common/types';
import { Attachment } from '../images/types';
import { DeductionRow, Job, JobDirection } from '../jobs/types';
import { GenericMasterData } from '../master-data/types';
import { UserDetails } from '../user/types';

export const OrderSearchResults = 30;

export enum OrderStatus {
  OPEN = 'OPEN',
  COMPLETING = 'COMPLETING',
  DISCARDING = 'DISCARDING',
  COMPLETED = 'COMPLETED',
  DISCARDED = 'DISCARDED',
}

export enum ComponentGroup {
  TRUCK = 'TRUCK',
  TRAILER = 'TRAILER',
}

export interface Component {
  id: string;
  componentGroup: ComponentGroup | undefined;
  componentStatus: string;
  dataLinks?: string[];
  requiredDataTypes?: string[];
  jobDirection?: JobDirection;
}

export interface NormalWeighingMainComponents {
  truck: Component;
  trailer?: Component;
}

export interface NormalWeighingActiveComponents {
  truck: Component[];
  trailer: Component[];
}

export enum ClosePolicy {
  SCALE_MANUAL = 'SCALE_MANUAL',
  SERVER_MANUAL = 'SERVER_MANUAL',
}

export enum InspectorOrderStatus {
  OPEN = 'OPEN',
  APPROVED = 'APPROVED',
}

export enum JobType {
  BRIDGE = 'BRIDGE',
}

export interface Split {
  amount: number;
  kgs: number | undefined;
}

export interface SplitLoad {
  [id: string]: Split;
}

// Response object for getting Orders
export interface Order extends MasterDataItem {
  components: Component[];
  closePolicy: ClosePolicy;
  jobType: JobType;
  linkedData?: MasterDataItem[];
  externalId: string;
  noSync?: boolean;
  // NOTE(mikkogy,20210518) orderDescription is hacked to carry order key of
  // original order in temporary copies. Description is not used in the UI at
  // the time of implementing. If description is later needed on UI we need a
  // proper solution.
  orderDescription?: string;
  status: OrderStatus;
  realization: {
    [scaleKey: string]: {
      // TODO(mikkogy,20201217) really enum but so far values have not been
      // interesting in this application so let's not define them here.
      receiverStatus: string;
      jobs: Job[];
    };
  };
}

export enum OrdersActionTypes {
  ADD_SPLIT = '@@orders/ADD_SPLIT',
  CLEAR_EDITED_MASTER_DATA_COMPONENT_ID = '@@orders/CLEAR_EDITED_MASTER_DATA_COMPONENT_ID',
  CREATE_CONTEXT = '@@orders/CREATE_CONTEXT',
  CREATE_CONTEXT_FAILED = '@@orders/CREATE_CONTEXT_FAILED',
  CREATE_CONTEXT_FOR_CONTAINER = '@@orders/CREATE_CONTEXT_FOR_CONTAINER',
  CREATE_CONTEXT_FOR_EMPTY_ORDER = '@@orders/CREATE_CONTEXT_FOR_EMPTY_ORDER',
  DELETE_SPLIT = '@@orders/DELETE_SPLIT',
  DISABLE_SPLIT_LOAD = '@@orders/DISABLE_SPLIT_LOAD',
  DISCARD_CONTEXT_ORDER = '@@orders/DISCARD_CONTEXT_ORDER',
  DESTROY_CONTEXT_HANDLED = '@@orders/DESTROY_CONTEXT_HANDLED',
  DESTROY_SELECTED_OPERATOR_CONTEXT = '@@orders/DESTROY_SELECTED_OPERATOR_CONTEXT',
  ENABLE_SPLIT_LOAD = '@@orders/ENABLE_SPLIT_LOAD',
  SELECTED = '@@orders/SELECTED',
  CONTEXTS_CHANGED = '@@orders/CONTEXTS_CHANGED',
  CURRENT_CONTEXT_CHANGED = '@@orders/CURRENT_CONTEXT_CHANGED',
  SELECT_MEAS_DEVICE_GROUP = '@@orders/SELECT_MEAS_DEVICE_GROUP',
  END_VIEWING_ORDER = '@@orders/END_VIEWING_ORDER',
  EDIT_MASTER_DATA = '@@orders/EDIT_MASTER_DATA',
  CANCEL_EDITING_MASTER_DATA = '@@orders/CANCEL_EDITING_MASTER_DATA',
  SET_PENDING_MASTER_DATA_UPDATE = '@@orders/SET_PENDING_MASTER_DATA_UPDATE',
  DONE_EDITING_MASTER_DATA = '@@orders/DONE_EDITING_MASTER_DATA',
  REQUEST_EXTERNAL_ID_UPDATE = '@@orders/REQUEST_EXTERNAL_ID_UPDATE',
  REQUEST_MASTER_DATA_UPDATE = '@@orders/REQUEST_MASTER_DATA_UPDATE',
  SET_CONTEXT_COMMENT = '@@orders/SET_CONTEXT_COMMENT',
  SET_CONTEXT_COMMENT_IMMEDIATELY = '@@orders/SET_CONTEXT_COMMENT_IMMEDIATELY',
  SET_CONTEXT_MANUAL_DEDUCTIONS = '@@orders/SET_CONTEXT_MANUAL_DEDUCTIONS',
  SET_CONTEXT_PROCESS = '@@orders/SET_CONTEXT_PROCESS',
  SET_CONTEXT_TRAILER_KEY = '@@orders/SET_CONTEXT_TRAILER_KEY',
  SET_CURRENT_CONTEXT_ORDER_KEY = '@@orders/SET_CURRENT_CONTEXT_ORDER_KEY',
  SET_DEFAULT_WEIGHING_PROCESS = '@@orders/SET_DEFAULT_WEIGHING_PROCESS',
  SET_MULTIPART_EDIT_COMPONENT_ID = '@@orders/SET_MULTIPART_EDIT_COMPONENT_ID',
  SET_EDITED_DEDUCTIONS = '@@orders/SET_EDITED_DEDUCTIONS',
  SET_SPLIT_AMOUNT = '@@orders/SET_SPLIT_AMOUNT',
  UPDATE_ORDER_JOB_DIRECTION = '@@orders/UPDATE_ORDER_JOB_DIRECTION',
  UPDATE_ORDER_TYPABLE_MASTER_DATA = '@@orders/UPDATE_ORDER_TYPABLE_MASTER_DATA',
  UPDATE_ORDER_TYPABLE_MASTER_DATA_IMMEDIATELY = '@@orders/UPDATE_ORDER_TYPABLE_MASTER_DATA_IMMEDIATELY',
  COPY_TRUCK_TO_TRAILER = '@@orders/COPY_TRUCK_TO_TRAILER',
  SEARCH_REQUEST = '@@orders/SEARCH_REQUEST',
  SEARCH_ORDER_SUCCESS = '@@orders/SEARCH_ORDER_SUCCESS',

  LIST_ORDERS_SUCCESS = '@@orders/LIST_ORDERS_SUCCESS',
  RELOAD_ORDER_INFO = '@@orders/RELOAD_ORDER_INFO',
  START_IN_PROGRESS_ORDER_POLL = '@@orders/START_IN_PROGRESS_ORDER_POLL',
  STOP_IN_PROGRESS_ORDER_POLL = '@@orders/STOP_IN_PROGRESS_ORDER_POLL',
  IN_PROGRESS_ORDER_SUCCESS = '@@orders/IN_PROGRESS_ORDER_SUCCESS',
  FETCH_IN_PROGRESS_ORDERS = '@@orders/FETCH_IN_PROGRESS_ORDERS',
  SELECT_OPERATOR_ORDER = '@@orders/SELECT_OPERATOR_ORDER',
  SELECTED_OPERATOR_ORDER = '@@orders/SELECTED_OPERATOR_ORDER',
  SET_LOADING_OPERATOR_ORDER_KEY = '@@orders/SET_LOADING_OPERATOR_ORDER_KEY',
  LOAD_ORDER_INFO_REQUEST = '@@orders/LOAD_ORDER_INFO_REQUEST',
  SHOW_ORDER_INFO = '@@orders/SHOW_ORDER_INFO',
  SET_INSPECTOR_ORDER_TAB = '@@orders/SET_INSPECTOR_ORDER_TAB',
  SET_OPERATOR_JOB_FILTER = '@@orders/SET_OPERATOR_JOB_FILTER',
  SET_OPERATOR_ORDER_TAB = '@@orders/SET_OPERATOR_ORDER_TAB',
  LIST_ORDER = '@@orders/LIST_ORDER',
}

export interface PendingMasterDataUpdate {
  key: string;
  name: string;
}

export interface PendingMasterDataUpdates {
  [componentId: string]: {
    [s: string]: PendingMasterDataUpdate;
  };
}

export interface InProgressOrder {
  order: Order;
  isOrderTemporaryCopy: boolean;
  weighingJobs: Job[];
  process: WeighingProcess;
  splitLoad: SplitLoad | undefined;
}

export interface InProgressOrders {
  isListTruncated: boolean;
  orders: InProgressOrder[];
}

export interface ContextOwner {
  userKey: string;
  username: string;
}

export enum WeighingProcess {
  NORMAL = 'NORMAL',
  MULTIPART = 'MULTIPART',
  UNDEFINED = 'UNDEFINED',
}

export interface PresetTare {
  containerKey: string;
  containerType: ContainerType;
  massKg: number;
  timestamp: number;
}

export interface Context {
  contextId: string;
  isOrderTemporaryCopy: boolean;
  inspectionUsers: { [key: string]: UserDetails };
  measDeviceGroupId: string;
  measDeviceKeys: string[];
  orderKey: string;
  order: Order;
  owners?: ContextOwner[];
  presetTares: { [key: string]: PresetTare };
  process: WeighingProcess;
  splitLoad: SplitLoad;
  weighingJobs: Job[] | undefined;
  attachments: { [key: string]: Attachment } | undefined;
}

export interface OperatorOrder {
  context: Context | undefined;
  order: Order | undefined;
  process: WeighingProcess;
  weighingJobs: Job[];
}

export enum ListOrderQueryMode {
  ALL = 'ALL',
  COMPLETED = 'COMPLETED',
  DISCARDED = 'DISCARDED',
  OPEN_BY_ACCEPTANCE = 'OPEN_BY_ACCEPTANCE',
  OPEN_BY_TIME = 'OPEN_BY_TIME',
}

export enum SelectOrderCleanup {
  DESTROY_PREVIOUS = 'DESTROY_PREVIOUS',
  IGNORE = 'IGNORE',
}

export interface OrderResults {
  orders: Order[];
  status: ListOrderQueryMode;
  pagination: ServerPagination;
}

export interface ListOrderQueryParams {
  pagination: Pagination;
  status: ListOrderQueryMode;
}

export interface Contexts {
  [key: string]: Context;
}

export enum OperatorOrderTab {
  Order = 0,
  Weighing = 1,
}

export enum InspectorOrderTab {
  OPEN = 0,
  APPROVED = 1,
}

export interface EditedDeductions {
  comment: string;
  originalComment: string;
  commentJobKey: string;
  container: ContainerItem;
  contextId: string;
  isDiscardChangesDialogOpen: boolean;
  deductions: DeductionRow[];
  disabledMaterialKeys: string[];
  mainMaterial: GenericMasterData | undefined;
}

export interface OrderSearch {
  isLoading: boolean;
  searchResults: Order[];
  text: string;
}

export interface OrderState {
  readonly loading: boolean;
  readonly defaultWeighingProcess: WeighingProcess;
  readonly multipartEditOrderComponentId: string;
  readonly operatorSelectionResults: OrderResults;
  readonly operatorQueryParams: ListOrderQueryParams;
  readonly operatorQueryResults: OrderResults;
  readonly errors?: string;
  readonly previousOrder?: Order;
  readonly contexts: Contexts;
  readonly currentContextId?: string;
  readonly currentContextOrderKey?: string;
  readonly editedDeductions: EditedDeductions | undefined;
  readonly editedMasterDataComponentId: string;
  readonly editedMasterDataType?: DetailedMasterDataType;
  readonly editedMasterDataTitle: string;
  readonly pendingMasterDataUpdates: PendingMasterDataUpdates;
  readonly inspectorOrderTab: InspectorOrderTab;
  readonly inProgressOrders: InProgressOrders;
  readonly isWaitingForFirstInProgressOrders: boolean;
  readonly loadingOperatorOrderKey: string;
  readonly selectedOperatorOrder?: OperatorOrder;
  readonly operatorJobFilter?: string;
  readonly operatorOrderTab: OperatorOrderTab;
  readonly search: OrderSearch;
}

export type OrdersResponsePayload = ApiResponse & {
  orders: Order[];
  pagination: ServerPagination;
  vehicleKey: string;
};

export type ContextStatusResponsePayload = ApiResponse & {
  readonly status: Contexts;
};

export interface OrderInfoResponsePayload {
  order: Order;
  process: WeighingProcess;
  weighingJobs: Job[];
}

export enum OperatorOrderMode {
  Viewing = 0,
  Weighing = 1,
}
