import React from 'react';

import { connect, localeConnect } from '../localeConnect';

import { ApplicationState } from '../store';

import { Order } from '../store/orders/types';
import { secondaryContainerTare } from '../store/orders/utils';

import { BridgeEnabledFeatures } from '../store/scale-info/types';

interface ParameterProps {
  children: React.ReactNode;
  order: Order;
  componentId: string;
}

interface PropsFromState {
  enabledFeatures: BridgeEnabledFeatures;
}

/*eslint-disable */
export type AllProps = PropsFromState & ParameterProps;
/*eslint-enable */

const mapStateToProps = (state: ApplicationState) => ({
  enabledFeatures: state.currentScaleInfo.enabledFeatures,
});

const mapDispatchToProps = {};

export function isComponentSecondaryContainerTareApplicable(
  enabledFeatures: BridgeEnabledFeatures,
  order: Order,
  componentId: string,
) {
  if (!enabledFeatures.bridgeSecondaryContainerTareDeduction) return false;
  const tare = secondaryContainerTare(order, componentId);
  return !!tare && !tare.unfitForJobs;
}

function SecondaryContainerTareFeature(props: AllProps) {
  if (
    !isComponentSecondaryContainerTareApplicable(
      props.enabledFeatures,
      props.order,
      props.componentId,
    )
  ) {
    return null;
  }
  return <div>{props.children}</div>;
}

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(SecondaryContainerTareFeature);
