import React from 'react';

import { makeStyles } from '@mui/styles';

import { OrderStatus } from 'store/orders/types';

import { translate } from 'utils/translate';
import DotStatus, { DotStatusColor } from './DotStatus';

function getStatusColor(orderStatus: OrderStatus) {
  if (orderStatus === OrderStatus.COMPLETED) return DotStatusColor.OK;
  if (orderStatus === OrderStatus.DISCARDED) return DotStatusColor.FAIL;
  return DotStatusColor.IN_PROGRESS;
}

interface OrderStatusItemProps {
  status: OrderStatus;
}

const useStyles = makeStyles({
  container: {
    position: 'relative',
  },
  label: {
    position: 'absolute',
    fontSize: '1rem',
    minWidth: '200px',
    transform: 'scale(0.75)',
    transformOrigin: 'top left',
    top: 0,
  },
  status: {
    position: 'relative',
    top: '20px',
  },
});

const OrderStatusItem = (props: OrderStatusItemProps) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <label className={`${classes.label} MuiFormLabel-root`}>
        {translate('operatorOrder.orders.status')}
      </label>
      <div className={classes.status}>
        <DotStatus
          color={getStatusColor(props.status)}
          text={translate(`operatorOrder.orders.statuses.${props.status}`)}
        />
      </div>
    </div>
  );
};

export default OrderStatusItem;
