import { ContainerType } from '../store/common/types';
import { Job } from '../store/jobs/types';
import { jobsTrucksAndTrailers } from '../store/jobs/utils';
import { Context, Order, WeighingProcess } from '../store/orders/types';
import { hasContextRequiredMasterData, isUpdatingOrderAllowed } from '../store/orders/utils';
import { UserDetails } from '../store/user/types';
import { filterValidLoads } from '../store/utils';

export interface OrderDetailsModel {
  hasAllRequiredMasterData: boolean;
  isOrderUpdateDisabled: boolean;
  process: WeighingProcess;
  isProcessChangeDisabled: boolean;
  isTrailerUsed: boolean;
  isTrailerChangeDisabled: boolean;
}

export function createOrderDetailsModel(
  context: Context | undefined,
  order: Order | undefined,
  process: WeighingProcess,
  jobs: Job[],
  scaleKey: string | undefined,
  userDetails: UserDetails | undefined,
): OrderDetailsModel {
  const isAllowedToUpdateOrder =
    !!order && !!context && isUpdatingOrderAllowed(order, scaleKey, userDetails);
  const hasContextJobs = !!context?.weighingJobs;
  const isProcessChangeEnabled =
    hasContextJobs &&
    context &&
    !context.isOrderTemporaryCopy &&
    !context?.weighingJobs?.some((job) => job.loads?.length > 0);

  const isTrailerInJob = jobs?.[0]?.containers[0]?.containerType === ContainerType.TRUCK_TRAILER;

  const actualProcess = context ? context.process : process;

  const isTrailerChangeDisabled = !isTrailerChangeAllowed(context);

  const hasAllRequiredMasterData = hasContextRequiredMasterData(context);

  return {
    hasAllRequiredMasterData,
    isOrderUpdateDisabled: !isAllowedToUpdateOrder,
    process: actualProcess,
    isProcessChangeDisabled: !isProcessChangeEnabled,
    isTrailerUsed: isTrailerInJob,
    isTrailerChangeDisabled,
  };
}

function isTrailerChangeAllowed(context: Context | undefined) {
  if (!context || !context.weighingJobs) return false;
  const contextJobs = context.weighingJobs;
  const maxValidLoadCount = contextJobs
    .map((job) => filterValidLoads(job?.loads ?? []).length)
    .reduce((max, loadCount) => Math.max(max, loadCount), 0);
  const maxTrailerLoadCount = contextJobs
    .map((job) => jobsTrucksAndTrailers([job])?.[1]?.loadIndexes?.length ?? 0)
    .reduce((max, loadCount) => Math.max(max, loadCount), 0);
  return maxValidLoadCount <= 1 && maxTrailerLoadCount === 0;
}
