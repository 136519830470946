import React from 'react';

import { Button, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/EditOutlined';

import { connect, localeConnect } from 'localeConnect';

import {
  NormalWeighingContainerModel,
  createContainerModels,
} from 'models/normalweighingcontainer.model';
import { getCustomerName, getMaterialName } from 'models/order.model';
import { Split } from 'models/splitload.model';

import { ApplicationState } from 'store';
import { ContainerItem, ContainerType, RemoteMasterDataType } from 'store/common/types';
import { JobDirection } from 'store/jobs/types';
import { Context, Order } from 'store/orders/types';
import {
  getContextMainJob,
  getNormalWeighingMainComponents,
  linkedTypes,
} from 'store/orders/utils';
import { BridgeEnabledFeatures, OrganizationEnabledFeatures } from 'store/scale-info/types';
import { UserDetails } from 'store/user/types';
import { undefinedMassKg } from 'store/weighing/types';

import theme, { breakLongText, separatorFadedColor } from 'theme';

import { translate } from 'utils/translate';

import CardDetailItem, { CardDetailTitle, CardDetailValue } from '../CardDetailItem';
import { formatMass } from '../utils';

const useStyles = makeStyles({
  amount: {
    color: '#4d4d4f',
    fontSize: '12px',
  },
  comment: {
    // eslint-disable-next-line
    maxWidth: 'calc(100% - 1em)',
    // eslint-disable-next-line
    overflow: 'hidden',
    // eslint-disable-next-line
    position: 'relative',
    // eslint-disable-next-line
    lineHeight: '1.15em',
    // eslint-disable-next-line
    maxHeight: '2.3em',
    // eslint-disable-next-line
    textAlign: 'justify',
    // eslint-disable-next-line
    marginRight: '-1em',
    // eslint-disable-next-line
    paddingRight: '1em',
    // eslint-disable-next-line
    marginBottom: '0.5em',
    '&&:before': {
      content: '"..."',
      position: 'absolute',
      right: 0,
      bottom: 0,
    },
    '&&:after': {
      content: '""',
      position: 'absolute',
      right: 0,
      width: '1em',
      height: '1em',
      marginTop: '0.2em',
      background: theme.palette.common.white,
    },
  },
  cardData: {
    marginTop: '10px',
    marginLeft: '16px',
    overflowWrap: 'break-word',
    wordBreak: 'normal',
  },
  cardHeader: {
    fontWeight: 500,
    ...breakLongText,
  },
  containerTitle: {
    fontWeight: 600,
  },
  leftMost: {
    '&.MuiGrid-root.MuiGrid-item': {
      paddingLeft: 0,
    },
  },
  editButton: {
    color: 'black',
  },
  infoSection: {
    marginBottom: '20px',
  },
  splitRow: {
    borderBottom: `1px solid ${separatorFadedColor}`,
    paddingTop: '10px',
    paddingBottom: '10px',
  },
});

type EditDeductionsCallback = (container: ContainerItem) => void;

interface ParameterProps {
  context: Context;
  editDeductions: EditDeductionsCallback;
  editOrder: () => void;
}

interface PropsFromState {
  enabledFeatures: BridgeEnabledFeatures;
  organizationEnabledFeatures: OrganizationEnabledFeatures;
  scaleKey: string;
  userDetails: UserDetails | undefined;
}

interface PropsFromDispatch {}

export type AllProps = PropsFromDispatch & PropsFromState & ParameterProps;

const mapStateToProps = (state: ApplicationState) => ({
  enabledFeatures: state.currentScaleInfo.enabledFeatures,
  organizationEnabledFeatures: state.currentScaleInfo.organizationEnabledFeatures,
  scaleKey: state.currentScaleInfo.scaleKey,
  userDetails: state.user.user.userData,
});

const mapDispatchToProps = {};

interface AmountProps {
  amount: number | undefined;
  kgs: number | undefined;
}

const Amount = (props: AmountProps) => {
  const classes = useStyles();
  function formatAmount(num: number | undefined) {
    if (num === undefined) return '-';
    return num;
  }
  return (
    <div>
      <div>{formatAmount(props.amount)}</div>
      <div className={classes.amount}>{formatMass(props.kgs ?? undefinedMassKg)}</div>
    </div>
  );
};

const NormalOrderInfo = (props: AllProps) => {
  const classes = useStyles();
  if (!props.context.weighingJobs || !props.userDetails) return null;

  const components = getNormalWeighingMainComponents(props.context.order);

  const mainJob = getContextMainJob(props.context);

  const containerModels = createContainerModels(
    props.context,
    props.enabledFeatures,
    props.organizationEnabledFeatures,
    props.scaleKey,
    props.userDetails,
  );

  function getContainerTitle(containerType: ContainerType) {
    if (containerType === ContainerType.TRAILER) {
      return translate('orderDetails.trailerMasterData');
    }
    return translate('orderDetails.truckMasterData');
  }

  function regularInfo(model: NormalWeighingContainerModel) {
    const split = model.splitLoadModel.splits[0];
    if (split === undefined) {
      return null;
    }
    const isCustomerRequired = split.requiredTypes.includes(RemoteMasterDataType.CUSTOMER);
    const isCustomerIncluded = linkedTypes(props.context.order, split.component.id).includes(
      RemoteMasterDataType.CUSTOMER,
    );
    const isMaterialRequired = split.requiredTypes.includes(RemoteMasterDataType.MATERIAL);
    const isMaterialIncluded = linkedTypes(props.context.order, split.component.id).includes(
      RemoteMasterDataType.MATERIAL,
    );
    return (
      <Grid container spacing={2}>
        <Grid item xs={5} className={classes.leftMost}>
          <CardDetailItem
            isRequired={isCustomerRequired}
            isRequiredHighlighted={!isCustomerIncluded}
            title={translate(
              isCustomerRequired ? 'orderDetails.customerRequired' : 'orderDetails.customer',
            )}
            value={getCustomerName(props.context.order, split.component.id)}
          />
        </Grid>
        <Grid item xs={5}>
          <CardDetailItem
            isRequired={isMaterialRequired}
            isRequiredHighlighted={!isMaterialIncluded}
            title={translate(
              isMaterialRequired ? 'orderDetails.materialRequired' : 'orderDetails.material',
            )}
            value={getMaterialName(props.context.order, split.component.id)}
          />
        </Grid>
      </Grid>
    );
  }

  function splitLoadInfo(model: NormalWeighingContainerModel) {
    function getValue(
      split: Split,
      masterDataType: RemoteMasterDataType,
      nameGetter: (order: Order, componentId: string) => string,
    ) {
      return (
        <CardDetailValue
          isRequired={split.requiredTypes.includes(masterDataType)}
          isRequiredHighlighted={
            !linkedTypes(props.context.order, split.component.id).includes(masterDataType)
          }
          value={nameGetter(props.context.order, split.component.id)}
        />
      );
    }

    return (
      <div>
        <Grid container columns={24} spacing={2} className={classes.splitRow}>
          <Grid item xs={9} className={classes.leftMost}>
            <CardDetailTitle
              isRequired={false}
              isRequiredHighlighted={false}
              title={translate('orderDetails.customer')}
            />
          </Grid>
          <Grid item xs={9}>
            <CardDetailTitle
              isRequired={false}
              isRequiredHighlighted={false}
              title={translate('orderDetails.material')}
            />
          </Grid>
          <Grid item xs={6}>
            <CardDetailTitle
              isRequired={true}
              isRequiredHighlighted={false}
              title={translate('orderDetails.splitAmountRequired')}
            />
          </Grid>
        </Grid>
        {model.splitLoadModel.splits.map((split) => (
          <Grid
            container
            key={split.component.id}
            columns={24}
            spacing={2}
            className={classes.splitRow}
          >
            <Grid item xs={9} className={classes.leftMost}>
              {getValue(split, RemoteMasterDataType.CUSTOMER, getCustomerName)}
            </Grid>
            <Grid item xs={9}>
              {getValue(split, RemoteMasterDataType.MATERIAL, getMaterialName)}
            </Grid>
            <Grid item xs={6}>
              <CardDetailValue
                isRequired={true}
                isRequiredHighlighted={model.splitLoadModel.isAmountMissing}
                value={<Amount amount={split.amount} kgs={split.kgs} />}
              />
            </Grid>
          </Grid>
        ))}
      </div>
    );
  }

  return (
    <Card>
      <CardHeader
        title={props.context.order.externalId}
        className={classes.cardHeader}
        action={
          <Button aria-label="Edit" className={classes.editButton} onClick={props.editOrder}>
            <EditIcon />
          </Button>
        }
      />
      <Divider variant="middle" component={'hr'} />
      <CardContent>
        {containerModels.map((model) => {
          if (model.splitLoadModel.splits.length === 0) {
            return null;
          }
          return (
            <div key={model.componentId} className={classes.infoSection}>
              <div>
                <div className={classes.containerTitle}>
                  {getContainerTitle(model.container.containerType)}
                </div>
                <div className={classes.cardData}>
                  {model.splitLoadModel.isSplitEnabled && splitLoadInfo(model)}
                  {!model.splitLoadModel.isSplitEnabled && regularInfo(model)}
                </div>
              </div>
            </div>
          );
        })}
        <Grid container direction="column" spacing={2} className={classes.infoSection}>
          <Grid item>
            <Typography variant={'subtitle1'}>{translate('jobDirection.title')}</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.comment}>
              {translate(
                `jobDirection.values.JOB_DIRECTION_ENUM_${
                  components.truck?.jobDirection ?? JobDirection.UNDEFINED
                }`,
              )}
            </Typography>
          </Grid>
        </Grid>
        {mainJob?.comment && (
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant={'subtitle1'}>{translate('orderDetails.comment')}</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.comment}>{mainJob.comment}</Typography>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(NormalOrderInfo);
