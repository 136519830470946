import React from 'react';
import { Store } from 'redux';
import { Provider } from 'react-redux';
import { History } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { Persistor } from 'redux-persist/es/types';

import { Theme } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { ApplicationState } from './store';
import { RoutesComponent } from './components/Routes';

import './i18n';
import './App.css';
import 'normalize.css/normalize.css';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

// Any additional component props go here.
interface MainProps {
  store: Store<ApplicationState>;
  history: History;
  theme: Theme;
  persistor: Persistor;
}

export const AppComponent: React.FC<MainProps> = ({ store, history, theme, persistor }) => {
  return (
    <div className="App">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <ConnectedRouter history={history}>
                <RoutesComponent />
              </ConnectedRouter>
            </PersistGate>
          </Provider>
          <p className="App-print-hide">
            Version: {process.env.REACT_APP_PUNTARI_VERSION} Build: {process.env.REACT_APP_GIT_SHA}
          </p>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};
(async () => {
  console.debug('Hot reloaded');
})();

export default AppComponent;
