import { BridgeEnabledFeatures } from '../store/scale-info/types';
import MasterDataVisibleFeature from './MasterDataVisibleFeature';

interface Props {
  children: React.ReactNode;
}

export default function MasterDataExternalIdVisibleFeature(props: Props) {
  return (
    <MasterDataVisibleFeature
      isFeatureEnabled={(enabledFeatures: BridgeEnabledFeatures) =>
        enabledFeatures.bridgeIsMasterDataExternalIdVisible
      }
    >
      {props.children}
    </MasterDataVisibleFeature>
  );
}
