import { createTheme } from '@mui/material/styles';

export const fadedGreyColor = '#767676';
export const darkGreyColor = '#4b4b4b';
export const darkerGreyColor = '#414241';
export const separatorColor = '#ebeced';
export const separatorFadedColor = '#e0e0e0';

export const mobileContentMaxWidth = '500px';

const okGreenColor = '#63e300';
const failStatusColor = '#c41a00';

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: ['Arial', 'Roboto', '"Helvetica Neue"', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#006fb9',
    },
    secondary: {
      main: '#008d37',
    },
    error: {
      main: '#d9534f',
    },
    background: {
      default: '#f2f8f9',
    },
    text: {
      primary: '#000',
      disabled: fadedGreyColor,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h6: {
          fontSize: '1.2rem',
          fontWeight: 600,
        },
        subtitle1: {
          fontSize: '1.14rem',
          fontWeight: 600,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: '#006fb9',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          textAlign: 'left',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontWeight: 'bold',
          fontSize: '20px',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          height: '2px',
        },
      },
    },
  },
});

export const defaultRoot = {
  backgroundColor: theme.palette.background.default,
  minHeight: '100vh',
};
export const paperRoot = { backgroundColor: theme.palette.background.paper, minHeight: '100vh' };

export const dotStatusColors = {
  ok: okGreenColor,
  fail: failStatusColor,
  inProgress: '#f0f100',
};

export const inspectionStatusColors = {
  ok: theme.palette.secondary.main,
  fail: failStatusColor,
};

export const receiptColors = {
  subtitle: '#aaaaaa',
};

export const trafficLightColors = {
  stop: failStatusColor,
  pass: okGreenColor,
  gray: darkGreyColor,
  inactiveBorder: separatorFadedColor,
};

export const operatorPaginationStyles = {
  pagination: {
    '& table button': {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
    '& table td.MuiTableCell-root': {
      border: '0px',
      padding: '0px',
    },
    '& table div.MuiTablePagination-toolbar': {
      justifyContent: 'flex-end !important',
    },
  },
};

export const operatorSearchFilter = {
  spacing: '10px',
};

export const operatorColors = {
  secondary: '#979797',
};

export const breakLongText = {
  overflowWrap: 'anywhere' as const,
  // NOTE(xsammak,20220307) Using legacy "wordBreak: break-word" as
  // Safari/Webkit/IE does not yet support "overflowWrap: anywhere" option.
  // When all browsers support it, it can be changed to "wordBreak: normal".
  wordBreak: 'break-word' as const,
};

export const vehicleIconContainerMargins = {
  marginLeft: 'auto' as const,
  marginRight: 'auto' as const,
};

export const vehicleIconStyle = {
  // eslint-disable-next-line
  overflow: 'hidden',
  // eslint-disable-next-line
  height: 0,
  // eslint-disable-next-line
  position: 'relative',
  // NOTE(lindenlas, 20220310) scaling an svg image and preserving ascpect ratio is a bit complicated.
  // This paddingTop using percent is a hack that works correctly for this vehicle icon purpose.
  // eslint-disable-next-line
  paddingTop: '43.75%',
  '& .MuiSvgIcon-root': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
} as const;

export default theme;
