import React from 'react';

import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { connect, localeConnect } from 'localeConnect';
import { ApplicationState } from 'store';
import { MeasDevice, MeasDeviceAvailability, MeasDeviceGroup } from 'store/meas-devices/types';
import { Context, Contexts } from 'store/orders/types';

import { inactivateContextById } from 'store/weighing/actions';

import theme, { separatorColor } from 'theme';
import { translate } from 'utils/translate';
import OperatorMeasDevice from './OperatorMeasDevice';
import OperatorTrafficControlGroup from './OperatorTrafficControlGroup';
import DotStatus, { DotStatusColor } from './DotStatus';

const paddingTop = '5px';
const mediumMargin = '10px';
const largeMargin = '20px';

const useStyles = makeStyles({
  measDevice: {
    marginBottom: '40px',
  },
  trafficControlGroup: {
    marginBottom: mediumMargin,
    marginLeft: largeMargin,
    marginRight: largeMargin,
  },
  groupHeader: {
    margin: `0px ${mediumMargin} ${mediumMargin} ${mediumMargin}`,
    display: 'flex',
    borderBottom: 'solid 2px',
    borderBottomColor: separatorColor,
    flexDirection: 'column',
    paddingBottom: '10px',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  groupName: {
    paddingTop: paddingTop,
    display: 'flex',
  },
  groupAvailability: {
    display: 'flex',
    alignItems: 'center',
  },
  details: {
    marginTop: '24px',
    paddingTop: paddingTop,
    paddingBottom: '5px',
    width: '250px',
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  detailsContent: {
    // eslint-disable-next-line
    textAlign: 'left',
    // eslint-disable-next-line
    marginLeft: mediumMargin,
    '& > div': {
      marginBottom: mediumMargin,
    },
    '& .MuiGrid-item': {
      marginTop: '5px',
      marginBottom: '5px',
      paddingLeft: '15px',
    },
  },
  measDeviceWrapper: {
    margin: `${mediumMargin} ${mediumMargin} 15px 0px`,
  },
  title: {
    fontSize: '17px',
    paddingTop: paddingTop,
    wordBreak: 'break-all',
  },
});

const headerItemStyles = makeStyles({
  headerItem: {
    paddingTop: paddingTop,
    display: 'flex',
    alignItems: 'flex-start',
    wordBreak: 'break-all',
  },
});

interface PropsFromState {
  contexts: Contexts;
  measDevices: MeasDevice[];
  measDeviceGroups: MeasDeviceGroup[] | undefined;
}

interface PropsFromDispatch {
  inactivateContextById: typeof inactivateContextById;
}

const mapStateToProps = (state: ApplicationState) => ({
  measDevices: state.measDevices.measDevices,
  measDeviceGroups: state.measDevices.measDeviceGroups,
  contexts: state.orders.contexts,
});

const mapDispatchToProps = {
  inactivateContextById,
};

type AllProps = PropsFromDispatch & PropsFromState;

function Users(context: Context | undefined) {
  const classes = headerItemStyles();
  if (!context?.owners) return null;

  return (
    <Box className={classes.headerItem}>
      <>
        {translate('dashboard.measDevices.usersLabel')}
        <span style={{ fontWeight: 600, marginLeft: 5 }}>
          {context.owners.map((owner: any) => owner.username).join(', ')}
        </span>
      </>
    </Box>
  );
}

function FreeMeasDevices(context: Context | undefined, free: (contextId: string) => void) {
  const classes = headerItemStyles();
  if (!context?.owners) return null;

  return (
    <div className={classes.headerItem}>
      <Button
        size={'medium'}
        color="primary"
        variant="outlined"
        onClick={() => {
          if (context !== undefined) {
            free(context.contextId);
          }
        }}
      >
        {translate('dashboard.measDevices.freeMeasDevices')}
      </Button>
    </div>
  );
}

interface GroupProps {
  group: MeasDeviceGroup;
  contexts: Contexts;
  inactivateContextById: (contextId: string) => void;
  measDevices: MeasDevice[];
}

function DashboardGroup(props: GroupProps) {
  const classes = useStyles();
  let context: Context | undefined = undefined;
  const { contexts, group, inactivateContextById, measDevices } = props;
  if (contexts && group) {
    Object.keys(contexts).forEach((contextKey) => {
      const mapContext = contexts[contextKey];
      if (mapContext.measDeviceGroupId === group.id) {
        context = mapContext;
      }
    });
  }
  return (
    <Box className={classes.measDevice}>
      <Box>
        <Paper className={classes.details}>
          <Box className={classes.groupHeader}>
            <Box className={classes.groupName}>
              <Box className={classes.groupAvailability}>
                <DotStatus
                  color={
                    group.availability === MeasDeviceAvailability.Available
                      ? DotStatusColor.OK
                      : DotStatusColor.FAIL
                  }
                  text={''}
                />
              </Box>
              <Typography className={classes.title} variant="h5">
                {group.name}
              </Typography>
            </Box>
            {Users(context)}
            {FreeMeasDevices(context, inactivateContextById)}
          </Box>
          {!!group.trafficControlGroupId && (
            <Box className={classes.trafficControlGroup}>
              <OperatorTrafficControlGroup
                alignRight={false}
                trafficControlGroupId={group.trafficControlGroupId}
              />
            </Box>
          )}
          <Box className={classes.detailsContent}>
            {group.measDeviceKeys.map((measDeviceKey) => {
              const measDevice = measDevices.find((device) => device.key === measDeviceKey);
              if (!measDevice) return null;
              return (
                <div key={measDevice.key}>
                  <Paper className={classes.measDeviceWrapper}>
                    <OperatorMeasDevice measDevice={measDevice} />
                  </Paper>
                </div>
              );
            })}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

class DashboardMeasDeviceGroups extends React.PureComponent<AllProps, Record<string, never>> {
  public render() {
    const props = this.props;
    if (!props.measDeviceGroups) return null;
    const filteredGroups = props.measDeviceGroups.filter((g) => !g.isManual);

    return (
      <div>
        <Typography align={'left'} variant="h5">
          {translate('dashboard.scales')}
        </Typography>
        {props.measDevices.length === 0 && (
          <div>{translate('dashboard.measDevices.noMeasDevices')}</div>
        )}
        <Grid container>
          {filteredGroups
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((group) => (
              <Grid item xs={12} sm={6} md={4} lg={12} xl={12} key={group.id}>
                <DashboardGroup
                  group={group}
                  contexts={props.contexts}
                  inactivateContextById={props.inactivateContextById}
                  measDevices={props.measDevices}
                />
              </Grid>
            ))}
        </Grid>
      </div>
    );
  }
}

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardMeasDeviceGroups);
