import assert from 'assert';

export const truncateLongTextWithEllipsis = (
  text: string | undefined,
  maxLength: number,
): string | undefined => {
  if (!text) return undefined;

  assert(maxLength > 6);
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength - 3) + '...';
};
