import React from 'react';

import { makeStyles } from '@mui/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  TextField,
  InputAdornment,
  CircularProgress,
  IconButton,
} from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';
import { Search } from '@mui/icons-material';

import OrderModel from 'models/order.model';
import theme from 'theme';

import CoolOffButton from 'components/CoolOffButton';
import { Order, OrderSearch } from 'store/orders/types';
import { joinUniqueMasterDataItemNames } from 'store/common/utils';
import { DocType } from 'store/common/types';
import HighlightedText from 'components/HighlightedText';
import { formatOrderTitle } from 'store/orders/utils';
import { translate } from 'utils/translate';

interface SelectOrderProps {
  selectOrder: (orderKey: string) => void;
  createOrder: () => void;
  filterText: string;
  isCreatingOrderEnabled: boolean;
  orderSearch: OrderSearch;
  handleFiltering: (filter: string) => void;
  scaleName: string;
}

const useStyles = makeStyles({
  createOrderButton: {
    backgroundColor: 'transparent',
    borderColor: 'rgba(0, 0, 0, .12)',
    color: theme.palette.primary.main,
  },
  searchField: {
    width: '100%',
  },
  orderItemText: {
    overflowWrap: 'break-word',
    wordBreak: 'normal',
  },
});

const filterOutCopiedTemplates = (orders: OrderModel[]) => {
  // NOTE(mikkogy,20210518) orderDescription is used to hack original order
  // key to copy order that are used when doing weighings for SERVER_MANUAL
  // closePolicy orders. If orderDescription is changed to be visible in the
  // UI we need to implement something less hacky.
  const filters = orders.map((order) => order.orderDescription).filter((filter) => !!filter);
  if (filters.length === 0) return orders;
  return orders.filter((order) => !filters.includes(order.key));
};

function orderComponentFromOrder(order: Order): OrderModel {
  const linked = order.linkedData || [];
  const unknownName = translate('masterData.unknownItemName');
  const customerName = joinUniqueMasterDataItemNames(linked, DocType.CUSTOMER);
  const materialName = joinUniqueMasterDataItemNames(linked, DocType.MATERIAL);

  return new OrderModel(
    order.key,
    order.externalId,
    customerName,
    materialName,
    order.closePolicy,
    order.orderDescription,
    unknownName,
  );
}

const SelectOrder = (props: SelectOrderProps) => {
  const classes = useStyles();
  const title = `${translate('orders.title')} (${props.scaleName})`;
  const filterText = props.filterText;

  function setFilterText(text: string) {
    props.handleFiltering(text.trimStart());
  }
  const orderModel = props.orderSearch.searchResults.map(orderComponentFromOrder);

  const availableOrders = filterOutCopiedTemplates(orderModel);

  function generateSecondaryDetails(orderModel: OrderModel) {
    const notSelectedPart = <span>{orderModel.notSelectedName}</span>;

    const customerPart = orderModel.customer ? (
      <HighlightedText searchTerm={filterText} fullText={orderModel.customer} />
    ) : (
      notSelectedPart
    );

    const materialPart = orderModel.material ? (
      <HighlightedText searchTerm={filterText} fullText={orderModel.material} />
    ) : (
      notSelectedPart
    );

    return (
      <span>
        <span>{customerPart}</span> <span>{`:`}</span> <span>{materialPart}</span>{' '}
      </span>
    );
  }

  const showSearchIcon = !props.orderSearch?.isLoading && filterText === '';

  return (
    <div>
      <Card>
        <CardHeader title={title} />
        <Divider variant="middle" component={'hr'} />
        {props.isCreatingOrderEnabled && (
          <CardContent>
            <CoolOffButton
              className={classes.createOrderButton}
              variant="outlined"
              color="primary"
              fullWidth
              onClickAccepted={props.createOrder}
            >
              {translate('orders.createOrder')}
            </CoolOffButton>
          </CardContent>
        )}
        <CardContent>
          <TextField
            onChange={(e) => setFilterText(e.target.value)}
            variant="outlined"
            className={classes.searchField}
            label={translate('orders.searchOrder')}
            value={filterText}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {showSearchIcon && <Search />}
                  {props.orderSearch?.isLoading && <CircularProgress size={20} />}
                  {filterText !== '' && (
                    <IconButton onClick={() => setFilterText('')}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <List component={'ul'}>
            {availableOrders.map((order: OrderModel) => (
              <ListItem
                button
                component={'li'}
                key={order.key}
                onClick={() => props.selectOrder(order.key)}
              >
                <ListItemText
                  className={classes.orderItemText}
                  primary={
                    <HighlightedText
                      searchTerm={filterText}
                      fullText={formatOrderTitle(order.name, order.closePolicy)}
                    />
                  }
                  secondary={generateSecondaryDetails(order)}
                />
              </ListItem>
            ))}
          </List>
        </CardContent>
        {!props.orderSearch?.isLoading && availableOrders.length === 0 && (
          <CardContent>
            <Typography variant="body1">{translate('orders.noOrdersForVehicle')}</Typography>
          </CardContent>
        )}
      </Card>
    </div>
  );
};

export default SelectOrder;
