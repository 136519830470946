import React from 'react';

import BackIcon from '@mui/icons-material/ArrowBackIosNew';
import { AppBar, Toolbar, Typography, IconButton, List } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { translate } from '../utils/translate';
import RoleSelect from './RoleSelect';
import ConnectedChangeSiteButton from './ConnectedChangeSiteButton';
import ConnectedLogoutButton from './ConnectedLogoutButton';
import ExpandingSiteInfo from './ExpandingSiteInfo';
import SettingsDialogButton from './SettingsDialogButton';
import TemporaryDrawer from './TemporaryDrawer';
import { useNavBarStyles } from './utils';
import { NavDivider, NavToolbar } from './NavDrawerContent';

interface ParameterProps {
  roleSelectionVisible: boolean;
  showSiteInfo: boolean;
  title: string | JSX.Element;
  onGoBack: (() => void) | undefined;
}

const SimpleNavBar = (props: ParameterProps) => {
  const classes = useNavBarStyles();
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const goBack = () => {
    if (props.onGoBack) {
      props.onGoBack();
    }
  };

  return (
    <div>
      <AppBar position="relative" className={classes.appBar}>
        <Toolbar>
          {props.onGoBack && (
            <IconButton
              color="inherit"
              aria-label={translate('navigation.return')}
              edge="start"
              onClick={goBack}
            >
              <BackIcon />
            </IconButton>
          )}
          {typeof props.title === 'string' && (
            <Typography variant="h6" noWrap className={classes.title}>
              {props.title}
            </Typography>
          )}
          {typeof props.title !== 'string' && props.title}

          <IconButton
            color="inherit"
            aria-label={translate('navigation.openDrawer')}
            edge="end"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
        {props.showSiteInfo && (
          <div className={classes.siteInfoOuterContainer}>
            <div className={classes.siteInfoInnerContainer}>
              <div className={classes.siteInfo}>
                <ExpandingSiteInfo isForcingInstructionsEnabled={false} isRouteShown={false} />
              </div>
            </div>
          </div>
        )}
      </AppBar>
      <TemporaryDrawer
        anchor="right"
        isOpen={isSidebarOpen}
        onClose={handleDrawerToggle}
        paperClass={classes.drawerPaper}
      >
        <NavToolbar />
        <NavDivider variant={'fullWidth'} />
        <List component={'ul'}>
          <ConnectedChangeSiteButton iconVisible={false} onClick={handleDrawerToggle} />
        </List>
        <List component={'ul'} className={classes.extraActions}>
          <SettingsDialogButton
            iconVisible={true}
            onOpen={handleDrawerToggle}
            onClose={undefined}
          />
          <NavDivider variant={'middle'} />
          {props.roleSelectionVisible && <RoleSelect />}
          <ConnectedLogoutButton iconVisible={true} onClick={handleDrawerToggle} />
        </List>
      </TemporaryDrawer>
    </div>
  );
};

export default SimpleNavBar;
