import React from 'react';

import { makeStyles } from '@mui/styles';
import { Box, Button, Grid, Typography } from '@mui/material';

import { connect, localeConnect } from 'localeConnect';
import { ApplicationState } from 'store';
import { setPendingMeasDeviceKey } from 'store/meas-devices/actions';
import { MeasDevice, MeasDeviceAvailability } from 'store/meas-devices/types';
import { selectOperatorOrder, setOperatorOrderTab } from 'store/orders/actions';
import { requestZeroing } from 'store/weighing/actions';
import { undefinedMassKg, WeighingState } from 'store/weighing/types';

import { darkerGreyColor, separatorColor } from 'theme';
import { translate } from 'utils/translate';
import { formatMass } from '../utils';
import DotStatus, { DotStatusColor } from './DotStatus';

const sharedLabelStyles = {
  color: darkerGreyColor,
  fontWeight: 300,
};

const sharedTitleStyles = {
  fontWeight: 600,
  textAlign: 'left',
  wordBreak: 'break-all',
} as const;

const itemStyles = makeStyles({
  item: {
    fontWeight: 'bold',
    fontSize: '17px',
    marginBottom: '5px',
  },
  label: {
    ...sharedLabelStyles,
    fontSize: '12px',
  },
  compactLabel: {
    ...sharedLabelStyles,
    fontSize: '13px',
  },
});

const measDeviceStyles = makeStyles({
  rootBasic: {
    textAlign: 'left',
    width: '100%',
  },
  rootFull: {},
  weighButtonLink: {
    textDecoration: 'none',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: 'solid 2px',
    borderBottomColor: separatorColor,
    marginRight: '6px',
    paddingBottom: '10px',
  },
  title: {
    ...sharedTitleStyles,
    display: 'flex',
    fontSize: '12px',
  },
  compactTitle: {
    ...sharedTitleStyles,
    fontSize: '17px',
  },
});

export enum MeasDeviceMode {
  Full,
  Compact,
}

interface ParameterProps {
  measDevice: MeasDevice;
  mode?: MeasDeviceMode;
}

interface PropsFromState {
  weighingState: WeighingState;
}

interface PropsFromDispatch {
  setPendingMeasDeviceKey: typeof setPendingMeasDeviceKey;
  requestZeroing: typeof requestZeroing;
  selectOperatorOrder: typeof selectOperatorOrder;
  setOperatorOrderTab: typeof setOperatorOrderTab;
}

const mapStateToProps = (state: ApplicationState) => ({
  weighingState: state.weighing,
});

const mapDispatchToProps = {
  requestZeroing,
  setPendingMeasDeviceKey,
  selectOperatorOrder,
  setOperatorOrderTab,
};

function getCurrentMass(weighingState: WeighingState, measDeviceKey: string) {
  const measDeviceState = weighingState.status[measDeviceKey];
  const kgs = measDeviceState ? measDeviceState.massKg : undefinedMassKg;
  return formatMass(kgs);
}

function getIsStable(weighingState: WeighingState, measDeviceKey: string) {
  const measDeviceState = weighingState.status[measDeviceKey];
  return measDeviceState ? measDeviceState.isStable : false;
}

export interface AvailabilityItemProps {
  measDeviceAvailability: MeasDeviceAvailability;
}

const Availability = (props: AvailabilityItemProps) => {
  const isAvailable = props.measDeviceAvailability === MeasDeviceAvailability.Available;
  return (
    <DotStatus
      color={isAvailable ? DotStatusColor.OK : DotStatusColor.FAIL}
      text={translate(`measDevices.availability.${props.measDeviceAvailability}`)}
    />
  );
};

function StyledItem(item: any) {
  const classes = itemStyles();
  return <div className={classes.item}>{item}</div>;
}

export const StyledAvailability = (props: AvailabilityItemProps) => {
  const availabilityItem = <Availability measDeviceAvailability={props.measDeviceAvailability} />;
  return StyledItem(availabilityItem);
};

function LabeledItem(mode: MeasDeviceMode, item: any, label: string) {
  const classes = itemStyles();
  return (
    <div>
      {StyledItem(item)}
      <div className={mode === MeasDeviceMode.Full ? classes.label : classes.compactLabel}>
        {label}
      </div>
    </div>
  );
}

const AvailabilityItem = (props: AvailabilityItemProps) => {
  const item = <Availability measDeviceAvailability={props.measDeviceAvailability} />;
  return LabeledItem(MeasDeviceMode.Full, item, translate('dashboard.measDevices.status'));
};

type AllProps = ParameterProps & PropsFromDispatch & PropsFromState;

const OperatorMeasDevice = (props: AllProps) => {
  const classes = measDeviceStyles();
  const mode = props.mode ?? MeasDeviceMode.Full;

  function isStableItem(measDevice: MeasDevice) {
    if (measDevice.availability === MeasDeviceAvailability.Error) {
      return LabeledItem(mode, '-', translate('dashboard.measDevices.stable'));
    }
    const isStable = getIsStable(props.weighingState, measDevice.key);
    const isStableKey = `dashboard.measDevices.${isStable ? 'yes' : 'no'}`;
    return LabeledItem(mode, translate(isStableKey), translate('dashboard.measDevices.stable'));
  }

  const readingItem = (measDevice: MeasDevice) => {
    const mass = getCurrentMass(props.weighingState, measDevice.key);
    return LabeledItem(mode, mass, translate('dashboard.measDevices.reading'));
  };

  const latestWeighingItem = (measDevice: MeasDevice) => {
    const mass = formatMass(measDevice.latestWeighingKg);
    return LabeledItem(mode, mass, translate('dashboard.measDevices.latestWeighing'));
  };

  const zeroScaleItem = (measDevice: MeasDevice) => {
    const handler = () => props.requestZeroing([measDevice.key]);
    return (
      <Button size={'small'} color="primary" variant="outlined" onClick={handler}>
        {translate('dashboard.measDevices.zeroScale')}
      </Button>
    );
  };

  const measDevice = props.measDevice;

  const widthProps = () => {
    if (mode === MeasDeviceMode.Full) {
      return { xs: 6 as const };
    }
    return { xs: 12 as const, sm: 4 as const, md: 3 as const, lg: 3 as const, xl: 3 as const };
  };

  const widthNameProps = () => {
    if (mode === MeasDeviceMode.Full) {
      return { xs: 12 as const };
    }
    return { xs: 12 as const, sm: 12 as const, md: 4 as const, lg: 4 as const, xl: 4 as const };
  };

  const widthStableProps = () => {
    if (mode === MeasDeviceMode.Full) {
      return { xs: 6 as const };
    }
    return { xs: 6 as const, sm: 4 as const, md: 2 as const, lg: 2 as const, xl: 2 as const };
  };
  return (
    <div className={mode === MeasDeviceMode.Full ? classes.rootFull : classes.rootBasic}>
      <Grid container>
        <Grid item {...widthNameProps()}>
          <div className={mode === MeasDeviceMode.Full ? classes.titleContainer : ''}>
            <Typography
              className={mode === MeasDeviceMode.Full ? classes.title : classes.compactTitle}
              variant="subtitle1"
            >
              {measDevice.name}
            </Typography>
            {mode === MeasDeviceMode.Full && <Box>{zeroScaleItem(measDevice)}</Box>}
          </div>
        </Grid>
        <Grid item {...widthProps()}>
          {readingItem(measDevice)}
        </Grid>
        <Grid item {...widthProps()}>
          {latestWeighingItem(measDevice)}
        </Grid>
        {mode === MeasDeviceMode.Full && (
          <Grid item {...widthProps()}>
            <AvailabilityItem measDeviceAvailability={measDevice.availability} />
          </Grid>
        )}
        <Grid item {...widthStableProps()}>
          {isStableItem(measDevice)}
        </Grid>
      </Grid>
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(OperatorMeasDevice);
