import { ApiResponse } from '../common/types';

export enum MeasDeviceActionTypes {
  ACTIVATE_CONTEXT = '@@meas-devices/ACTIVATE_CONTEXT',
  RECEIVE_LIST_SUCCESS = '@@meas-devices/RECEIVE_LIST_SUCCESS',
  SELECT_MEAS_DEVICE_GROUP = '@@meas-devices/SELECT_MEAS_DEVICE_GROUP',
  SELECTED = '@@meas-devices/SELECTED',
  START_WEIGHING = '@@meas-devices/START_WEIGHING',
  STARTED_WEIGHING = '@@meas-devices/STARTED_WEIGHING',
  FAILED_TO_START_WEIGHING = '@@meas-devices/FAILED_TO_START_WEIGHING',
  CLEAR_SELECTED_MEAS_DEVICE = '@@meas-devices/CLEAR_SELECTED_MEAS_DEVICE',
  CLEAR_WEIGHING_START_ERROR = '@@meas-devices/CLEAR_WEIGHING_START_ERROR',
  SET_MANUAL_KGS = '@@meas-devices/SET_MANUAL_KGS',
  SET_PENDING_MEAS_DEVICE_KEY = '@@meas-devices/SET_PENDING_MEAS_DEVICE_KEY',
  TOGGLE_MEAS_DEVICE_GROUP_REVERSING = '@@meas-devices/TOGGLE_MEAS_DEVICE_GROUP_REVERSING',
  RECEIVE_TRAFFIC_CONTROL_GROUP_UPDATE = '@@meas-devices/RECEIVE_TRAFFIC_CONTROL_GROUP_UPDATE',
  UPDATE_TRAFFIC_CONTROL_GROUP_STATUS = '@@meas-devices/UPDATE_TRAFFIC_CONTROL_GROUP_STATUS',
  UPDATE_TRAFFIC_CONTROL_GROUP_MODE = '@@meas-devices/UPDATE_TRAFFIC_CONTROL_GROUP_MODE',
  UPDATE_TRAFFIC_CONTROL_GROUP_DESIRED_STATE = '@@meas-devices/UPDATE_TRAFFIC_CONTROL_GROUP_DESIRED_STATE',
  RESET_TRAFFIC_CONTROL_GROUP_DESIRED_STATE = '@@meas-devices/RESET_TRAFFIC_CONTROL_GROUP_DESIRED_STATE',
}

export enum MeasDeviceAvailability {
  Available = 'Available',
  InUse = 'InUse',
  Error = 'Error',
}

export interface MeasDevice {
  readonly key: string;
  readonly latestWeighingKg: number;
  readonly name: string;
  readonly availability: MeasDeviceAvailability;
}

export interface MeasDeviceGroup {
  readonly id: string;
  readonly name: string;
  readonly availability: MeasDeviceAvailability;
  readonly measDeviceKeys: string[];
  readonly isHiddenFromDriverUi: boolean;
  readonly isManual: boolean;
  readonly trafficControlGroupId?: string;
}

export interface MeasDeviceGroupReversing {
  [key: string]: boolean;
}

export interface MeasDeviceState {
  readonly loading: boolean;
  readonly manualKgs: Record<string, number>;
  readonly measDeviceGroups: MeasDeviceGroup[] | undefined;
  readonly measDevices: MeasDevice[];
  readonly hasWeighingStartError: boolean;
  readonly errors?: string;
  readonly selectedGroupId?: string;
  readonly pendingMeasDeviceKey: string;
  readonly measDeviceGroupReversing: MeasDeviceGroupReversing;
  readonly trafficControlGroupStatuses: TrafficControlGroupStates;
  readonly trafficControlGroupDesiredStates: TrafficControlGroupStates;
  readonly trafficControlConnectionOk: boolean;
}

export interface TrafficControlGroupStates {
  [key: string]: TrafficControlGroupState;
}
export interface TrafficControlGroupState {
  readonly status: TrafficControlStatus;
  readonly mode: TrafficControlMode;
  readonly type: TrafficControlGroupType;
}

export enum TrafficControlStatus {
  Pass = 'PASS',
  Stop = 'STOP',
}

export enum TrafficControlMode {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL',
}

export enum TrafficControlGroupType {
  Barrier = 'BARRIER',
  Light = 'LIGHT',
}

export type MeasDevicesResponsePayload = ApiResponse & {
  measDevices: MeasDevice[];
};
