import { Md5 } from 'ts-md5';
import { ContainerType } from '../common/types';
import { SiteInfo } from './types';

export interface SchemaEntry {
  title: string;
  typeName: string;
  subtype: string | undefined;
  isTypable: boolean;
}

const isTypeBlacklisted = (type: string) => {
  const blackList = [
    // NOTE(mikkogy,20200807) extension_df7b1320-eb18-477b-a7fa-1b70accc4b1a is
    // site info which is not relevant in orders.
    'extension_df7b1320-eb18-477b-a7fa-1b70accc4b1a',
    // NOTE(mjohans,20210224) customer_group has an active role in customer list
    // restriction and we don't want to show it to the user
    'customer_group',
  ];
  return blackList.includes(type);
};

// NOTE(mikkogy,20200807) typing schema was considered too much of a hassle.
// Please consider typing if more information is needed from it.
export const findTypesAndTitles = (schema: Record<string, unknown>) => {
  const ret: SchemaEntry[] = [];
  if (!schema) return [];
  const properties = (schema as any).properties;
  Object.keys(properties).forEach((key) => {
    const type: any = properties[key] ? properties[key] : {};
    if (key === 'container') {
      // NOTE(mikkogy,20210719) finding out from schema if GENERIC container
      // is supported or not is tedious and error prone. As for now it is
      // always supported by schema we will simply assume it is and if it will
      // be disabled at some point there may be new details to consider anyway.
      ret.push({
        title: 'CONTAINER_TITLE',
        typeName: 'container',
        subtype: ContainerType.GENERIC,
        isTypable: false,
      });
      return;
    }
    if (isTypeBlacklisted(key)) {
      return;
    }
    const allOf = type.allOf;
    if (type.title) {
      ret.push({
        title: type.title as string,
        typeName: key,
        subtype: undefined,
        isTypable: false,
      });
    } else if (allOf) {
      for (const i in allOf) {
        if (allOf[i].title) {
          ret.push({
            title: allOf[i].title as string,
            typeName: key,
            subtype: undefined,
            isTypable: allOf[i].isTypable,
          });
          break;
        }
      }
    }
  });
  return ret;
};

// NOTE(mikkogy,20230118) by default all types are included and none excluded.
export const filterTypes = (
  types: SchemaEntry[],
  includedTypes: string[] | undefined,
  excludedTypes: string[] | undefined,
): SchemaEntry[] => {
  const excludeFilter = excludedTypes
    ? (type: SchemaEntry) => !excludedTypes?.includes(type.typeName)
    : () => true;
  const includeFilter = includedTypes
    ? (type: SchemaEntry) => includedTypes?.includes(type.typeName)
    : () => true;
  return types.filter(excludeFilter).filter(includeFilter);
};

export const calculateLocationHash = (siteInfo: SiteInfo) => {
  return Md5.hashStr(siteInfo.locationUrl ?? '');
};

export const calculateInstructionHash = (siteInfo: SiteInfo) => {
  return Md5.hashStr(
    (siteInfo.instructions ?? '').concat(siteInfo.showInstructionsAsUrl?.toString() ?? ''),
  );
};

export const checkIfHashHasBeenUpdated = (currentHash?: string, lastShownHash?: string) => {
  if (!currentHash || !lastShownHash) {
    return false;
  } else if (currentHash !== lastShownHash) {
    return true;
  }
  return false;
};
