import { Reducer } from 'redux';

import { OrdersActionTypes } from '../orders/types';

import { SitesActionTypes } from '../sites/types';

import { UserActionTypes } from '../user/types';

import { WeighingActionTypes } from '../weighing/types';

import { DeepLinkActionTypes, DeepLinkState } from './types';

export const initialState: DeepLinkState = {
  hasError: false,
  inProgressDeepLinkId: undefined,
  isDeepLinkViewVisible: false,
  orderKey: undefined,
  returnLink: undefined,
};

const reducer: Reducer<DeepLinkState> = (state = initialState, action) => {
  switch (action.type) {
    case DeepLinkActionTypes.CLEAR: {
      return {
        ...state,
        ...initialState,
      };
    }
    case DeepLinkActionTypes.SUCCESS: {
      return {
        ...state,
        hasError: false,
        isDeepLinkViewVisible: false,
        returnLink: action.payload,
      };
    }
    case DeepLinkActionTypes.FAILURE: {
      return {
        ...state,
        hasError: true,
        inProgressDeepLinkId: undefined,
        isDeepLinkViewVisible: true,
        orderKey: undefined,
      };
    }
    case DeepLinkActionTypes.SET_ORDER_KEY: {
      return {
        ...state,
        orderKey: action.payload,
      };
    }
    case DeepLinkActionTypes.SET_RETURN_LINK: {
      return {
        ...state,
        returnLink: action.payload,
      };
    }
    case DeepLinkActionTypes.START_LOADING: {
      return {
        ...state,
        hasError: false,
        inProgressDeepLinkId: action.payload,
        isDeepLinkViewVisible: true,
      };
    }
    case SitesActionTypes.CHANGE_SITE: {
      return {
        ...state,
        orderKey: undefined,
      };
    }
    case OrdersActionTypes.CREATE_CONTEXT: {
      let clearProps = {};
      if (action.payload.orderKey !== state.orderKey) {
        // NOTE(mikkogy,20220321) when creating context for another order than
        // current deep link order the existing deep link information will be
        // out of date.
        clearProps = { ...initialState };
      }
      return {
        ...state,
        ...clearProps,
      };
    }
    case OrdersActionTypes.DISCARD_CONTEXT_ORDER:
    case UserActionTypes.LOGOUT:
    case WeighingActionTypes.DESTROY_CONTEXT: {
      return {
        ...state,
        orderKey: undefined,
        returnLink: undefined,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as deepLinkReducer };
