import { ContainerType, DocType } from './common/types';
import { Job, OperatorQueryParams } from './jobs/types';
import { defaultUserSpecificSettings, UserSpecificSettings } from './settings/types';
import { Vehicle } from './vehicles/types';
import { TrafficControlGroupStates } from './meas-devices/types';
import { ApplicationState } from './index';

const getRealToken = (state: ApplicationState) => {
  return state.user.user.token;
};

/**
 * Get token to interact with Tamtron cloud server
 * @param state Passed in by redux saga `select()`
 */
export const getToken = (state: ApplicationState) => {
  if (state.currentScaleInfo.isDummyScale) {
    return state.user.user.userData;
  }
  return getRealToken(state);
};

/**
 * Get prepared authorization token header to interact with Tamtron cloud server.
 * @param state Passed in by redux saga `select()`
 */
export const getTokenHeader = (state: ApplicationState) => {
  const token = getRealToken(state);
  if (!token) return undefined;
  return { 'X-AUTH-TOKEN': token };
};

export const getSelectedUiRole = (state: ApplicationState) => state.user.selectedUiRole;

export const getUser = (state: ApplicationState) => state.user.user;

export const getSelectedLocale = (state: ApplicationState) => state.user.selectedLocale;

export const getAutoselectSite = (state: ApplicationState) => state.sites.autoselectSite;

export const getSite = (state: ApplicationState): string | undefined => state.sites.selectedKey;

export const getBridgeWebsocketAddress = (state: ApplicationState) => state.common.websocketAddress;

export const getWebsocketConnected = (state: ApplicationState) => state.common.connected;

export const getLastEventTime = (state: ApplicationState) => state.common.lastEventTime;

export const getAddImageJobKey = (state: ApplicationState) => {
  return state.images.addImageJobKey;
};

export const getSelectedVehicle = (state: ApplicationState) => {
  return state.vehicles.selectedVehicle;
};

export const getCurrentContextOrderKey = (state: ApplicationState) => {
  return state.orders.currentContextOrderKey;
};

export const getDefaultWeighingProcess = (state: ApplicationState) => {
  return state.orders.defaultWeighingProcess;
};

export const getMasterDataModifyInfo = (state: ApplicationState) => {
  return state.masterData.modifyInfo;
};

export const getIsDummyScale = (state: ApplicationState) => {
  return state.currentScaleInfo.isDummyScale;
};

export const getCurrentWsScaleKey = (state: ApplicationState): string => {
  return state.currentScaleInfo.scaleKey;
};

export const getScaleInfo = (state: ApplicationState) => {
  return state.scaleInfo;
};

export const getDomainInfo = (state: ApplicationState) => {
  return state.currentScaleInfo.domainInfo;
};

export const getContexts = (state: ApplicationState) => {
  return state.orders.contexts;
};

export const getDeepLinkState = (state: ApplicationState) => {
  return state.deepLink;
};

export const getLastOrder = (state: ApplicationState) => {
  return state.orders.previousOrder;
};

export const getLoadingOperatorOrderKey = (state: ApplicationState) => {
  return state.orders.loadingOperatorOrderKey;
};

export const getCurrentContextWeighingJobs = (state: ApplicationState) => {
  const contextId = state.orders.currentContextId;
  if (
    contextId &&
    state.orders.contexts[contextId] &&
    state.orders.contexts[contextId].weighingJobs
  ) {
    return state.orders.contexts[contextId].weighingJobs;
  }
  return [];
};

export const getPendingOrderMasterDataUpdates = (state: ApplicationState) => {
  return state.orders.pendingMasterDataUpdates;
};

export const getMeasDeviceGroupId = (state: ApplicationState) => {
  return state.measDevices.selectedGroupId;
};

export const getMeasDeviceGroups = (state: ApplicationState) => {
  return state.measDevices.measDeviceGroups;
};

export const areJobsLoaded = (state: ApplicationState) => {
  return state.jobs.loading === false;
};

export const getQueryParams = (state: ApplicationState): OperatorQueryParams => {
  return state.jobs.queryParams;
};

export const getDriverReceiptJobKeys = (state: ApplicationState) => {
  return state.jobs.driverReceiptJobKeys;
};

export const getDriverReceiptJobs = (state: ApplicationState) => {
  const driverReceiptKeys = getDriverReceiptJobKeys(state);
  if (driverReceiptKeys) {
    return driverReceiptKeys
      .map((key) => {
        return state.jobs.data.find((j) => j.key === key);
        // NOTE(mikkogy,20210603) at the time of implementing TypeScript did not
        // understand the array can't contain falsy values after filter hence the
        // type assertion.
      })
      .filter((receipt) => receipt) as Job[];
  }
  return [];
};

export const getTrailerKey = (state: ApplicationState) => {
  return state.vehicles.trailerKey;
};

export const getVehicleForCurrentOrder = (state: ApplicationState) => {
  const selectedOrder = getLastOrder(state);
  if (selectedOrder && selectedOrder.linkedData) {
    return selectedOrder.linkedData.find(
      (d) => d.docType === DocType.CONTAINER && d.containerType !== ContainerType.GENERIC,
    ) as Vehicle;
  }
  return undefined;
};

export const getVersionMismatchReloadTime = (state: ApplicationState) => {
  return state.currentScaleInfo.versionMismatchReloadTime;
};

export const getEnabledFeatures = (state: ApplicationState) => {
  return state.currentScaleInfo.enabledFeatures;
};

export const getCurrentContextId = (state: ApplicationState) => {
  return state.orders.currentContextId;
};

export const getLatestStatusTime = (state: ApplicationState) => {
  return state.weighing.latestStatusTime;
};

export const getMasterDataTypes = (state: ApplicationState) => state.masterData.dataTypes;

export const getOperatorFinishingOrderKey = (state: ApplicationState) => {
  return state.weighing.operatorFinishingOrderKey;
};

export const getOperatorTrailerKey = (state: ApplicationState) => {
  return state.weighing.operatorTrailerKey;
};

export const getSelectedOperatorOrder = (state: ApplicationState) => {
  return state.orders.selectedOperatorOrder;
};

export const getImages = (state: ApplicationState) => {
  return state.images;
};

export const getCurrentUserSettings = (state: ApplicationState): UserSpecificSettings => {
  const user = getUser(state);
  const defaultSettings = defaultUserSpecificSettings();
  if (!user) return defaultSettings;
  const userKey = user?.userData?.key;
  if (!userKey) return defaultSettings;
  const userSettings = state.settings.userSettings[userKey];
  if (userSettings) return userSettings;
  return defaultSettings;
};

export const getCurrentTrafficControlGroupStatuses = (
  state: ApplicationState,
): TrafficControlGroupStates => {
  return state.measDevices.trafficControlGroupStatuses;
};
