import React from 'react';

import { makeStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';

import { createOrderDetailsModel } from 'models/orderdetails.model';

import { Context } from 'store/orders/types';
import { getContextMainJob } from 'store/orders/utils';

import { BridgeEnabledFeatures } from 'store/scale-info/types';

import { UserDetails } from 'store/user/types';

import theme from 'theme';
import { translate } from 'utils/translate';
import CardDetailItem from '../CardDetailItem';
import ConfirmDialog from '../ConfirmDialog';
import PrimaryContainerTareFeature, { hasMassError } from '../PrimaryContainerTareFeature';

import { formatDateAndTime, formatMass } from '../utils';

const useStyles = makeStyles({
  content: {
    lineHeight: '2.0',
  },
  detailItemContainer: {
    '& div': {
      marginLeft: '0px',
      marginRight: '0px',
    },
  },
  error: {
    color: theme.palette.error.main,
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: 1.33,
    marginRight: '30px',
  },
});

interface Props {
  context: Context;
  enabledFeatures: BridgeEnabledFeatures;
  finishWithContextTare: () => void;
  rootClass?: string;
  scaleKey: string;
  userDetails: UserDetails | undefined;
}

export default function OperatorPrimaryContainerTare(props: Props) {
  const classes = useStyles();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
  const job = getContextMainJob(props.context);
  if (!job) return null;
  const presetTare = props.context?.presetTares[job.containers[0]?.key];
  if (!presetTare) return null;

  const orderDetailsModel = createOrderDetailsModel(
    props.context,
    props.context.order,
    props.context.process,
    props.context.weighingJobs ?? [],
    props.scaleKey,
    props.userDetails,
  );

  const hasRequiredTypes = orderDetailsModel.hasAllRequiredMasterData;

  const hasError = hasMassError(props.context, props.enabledFeatures) || !hasRequiredTypes;
  return (
    <PrimaryContainerTareFeature context={props.context} isExistingTareRequired={true}>
      <div>
        <div className={props.rootClass}>
          <div>
            <Typography className={classes.title} variant="caption">
              {translate('operatorOrder.weighing.presetTare.title')}
            </Typography>
          </div>
          <div className={`${classes.detailItemContainer} ${hasError ? classes.error : ''}`}>
            <CardDetailItem
              title={translate('operatorOrder.weighing.presetTare.mass')}
              value={formatMass(presetTare.massKg)}
            />
          </div>
          <div className={classes.detailItemContainer}>
            <CardDetailItem
              title={translate('operatorOrder.weighing.presetTare.time')}
              value={formatDateAndTime(presetTare.timestamp)}
            />
          </div>
          <div>
            <Button
              color="primary"
              variant="contained"
              disabled={hasError}
              onClick={() => setIsConfirmDialogOpen(true)}
            >
              {translate('operatorOrder.weighing.presetTare.finishWithTare')}
            </Button>
          </div>
        </div>
        {isConfirmDialogOpen && (
          <ConfirmDialog
            title={translate('operatorOrder.weighing.presetTare.confirmTitle')}
            question={translate('operatorOrder.weighing.presetTare.confirmQuestion')}
            visible={isConfirmDialogOpen}
            onConfirmed={props.finishWithContextTare}
            onNotConfirmed={() => setIsConfirmDialogOpen(false)}
          />
        )}
      </div>
    </PrimaryContainerTareFeature>
  );
}
