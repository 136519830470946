import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import theme from 'theme';
import { translate } from 'utils/translate';

interface DialogProps {
  initialName?: string;
  title: string;
  visible: boolean;
  createDisabled: boolean;
  hasError: boolean;
  onCreate: (name: string) => void;
  onClose: (latestName: string) => void;
}

const createStyles = makeStyles({
  root: {
    minWidth: '350px',
  },
  error: {
    color: theme.palette.error.main,
    marginTop: '15px',
  },
});

const OperatorCreateMasterDataDialog = (props: DialogProps) => {
  const classes = createStyles();
  const [itemName, setItemName] = React.useState('');
  const [isDisabledByTimer, setIsDisabledByTimer] = React.useState(false);

  React.useEffect(() => {
    if (props.visible) {
      setItemName(props.initialName ?? '');
    }
  }, [props.visible, setItemName, props.initialName]);

  function doCreate() {
    setIsDisabledByTimer(true);
    props.onCreate(itemName);
    setTimeout(() => setIsDisabledByTimer(false), 2000);
  }

  function doClose() {
    props.onClose(itemName);
  }

  return (
    <div>
      <Dialog open={props.visible} onClose={doClose}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent className={classes.root}>
          <TextField
            variant="standard"
            value={itemName}
            onChange={(e: any) => setItemName(e.target.value)}
            label={translate('operatorOrder.orderDetails.createMasterData.name')}
          />
          {props.hasError && (
            <Typography variant="body1" className={classes.error}>
              {translate('operatorOrder.orderDetails.createMasterData.error')}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={doClose} color="primary" variant="outlined">
            {translate('operatorOrder.orderDetails.createMasterData.cancel')}
          </Button>
          <Button
            onClick={doCreate}
            disabled={props.createDisabled || isDisabledByTimer}
            color="primary"
            variant="contained"
            autoFocus={true}
          >
            {translate('operatorOrder.orderDetails.createMasterData.create')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OperatorCreateMasterDataDialog;
