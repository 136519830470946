import React from 'react';

import { InputAdornment, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import { translate } from 'utils/translate';

const useStyles = makeStyles({
  defaultDisplayValue: {
    fontStyle: 'italic',
  },
});

interface Props {
  disabled: boolean;
  onClick: () => void;
  selectedValue: string | undefined;
}

const MaterialSelect = (props: Props) => {
  const classes = useStyles();

  const handleClick = () => {
    if (!props.disabled) {
      props.onClick();
    }
  };

  return (
    <TextField
      variant="standard"
      className={props.selectedValue ? '' : classes.defaultDisplayValue}
      disabled={props.disabled}
      fullWidth
      placeholder={translate('orderDetails.material')}
      onClick={handleClick}
      value={props.selectedValue}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default MaterialSelect;
