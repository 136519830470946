import React from 'react';

import { makeStyles } from '@mui/styles';
import { Button, Card, CardContent, CardHeader, Divider } from '@mui/material';

import { Context } from 'store/orders/types';
import { isOrderValidForTareSaving } from 'store/orders/utils';

import ConfirmDialog from 'components/ConfirmDialog';
import PrimaryContainerTareFeature from 'components/PrimaryContainerTareFeature';

import theme from 'theme';
import { translate } from 'utils/translate';

const useStyles = makeStyles({
  button: {
    marginTop: '20px',
  },
  cardHeader: {
    fontWeight: 500,
  },
  content: {
    lineHeight: '2.0',
  },
  error: {
    color: theme.palette.error.main,
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: 1.33,
    marginRight: '30px',
  },
});

interface Props {
  context: Context;
  saveTareAndFinish: () => void;
  scaleKey: string;
  rootClass?: string;
}

export default function DriverSaveTruckTare(props: Props) {
  const classes = useStyles();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
  return (
    <PrimaryContainerTareFeature
      areAdditionalConditionsSatisfied={isOrderValidForTareSaving(
        props.context.order,
        props.scaleKey,
      )}
      context={props.context}
      isExistingTareRequired={false}
    >
      <Card className={props.rootClass}>
        <CardHeader
          title={translate('orderDetails.saveTare.title')}
          className={classes.cardHeader}
        />
        <Divider variant="middle" component={'hr'} />
        <CardContent>
          <div>
            <div className={classes.content}>
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={() => setIsConfirmDialogOpen(true)}
              >
                {translate('orderDetails.saveTare.finishAndSave')}
              </Button>
            </div>
          </div>
          {isConfirmDialogOpen && (
            <ConfirmDialog
              title={translate('orderDetails.saveTare.confirmTitle')}
              question={translate('orderDetails.saveTare.confirmQuestion')}
              visible={isConfirmDialogOpen}
              onConfirmed={() => {
                props.saveTareAndFinish();
                setIsConfirmDialogOpen(false);
              }}
              onNotConfirmed={() => setIsConfirmDialogOpen(false)}
            />
          )}
        </CardContent>
      </Card>
    </PrimaryContainerTareFeature>
  );
}
