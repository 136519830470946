import { makeStyles } from '@mui/styles';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Context } from 'store/orders/types';

import { InspectionStatus } from 'store/jobs/types';
import theme, { darkGreyColor } from 'theme';
import { setInspectionStatus } from 'store/jobs/actions';
import { getContextMainJob, MainJobMultipartBehavior } from 'store/orders/utils';
import { formatUserDisplayName } from 'store/user/utils';

import { translate } from 'utils/translate';
import { formatDateAndTime } from '../utils';

const useStyles = makeStyles({
  inspectionStatus: {
    '& .MuiCheckbox-root': {
      color: theme.palette.primary.main,
    },
    // eslint-disable-next-line
    float: 'right',
  },
  inspectionSelection: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    margin: '0',
    float: 'right',
  },
  approver: {
    '& .MuiTypography-caption': {
      fontWeight: 'bold',
      color: darkGreyColor,
    },
  },
});

interface ParameterProps {
  weighingContext: Context;
}

interface PropsFromDispatch {
  setInspectionStatus: typeof setInspectionStatus;
}

type AllProps = ParameterProps & PropsFromDispatch;

const OperatorInspectionStatus = (props: AllProps) => {
  const classes = useStyles();
  const context = props.weighingContext;
  const inspection = context?.weighingJobs?.[0]?.inspection;
  const inspectionUser = inspection ? context?.inspectionUsers?.[inspection.userKey] : undefined;

  const setInspectionStatus = (event: any) => {
    const mainJob = getContextMainJob(context, MainJobMultipartBehavior.FIRST);
    if (!context || !mainJob) {
      throw new Error('context and job must exist');
    }
    const inspectionStatusValue = event.target.checked
      ? InspectionStatus.INSPECTED
      : InspectionStatus.NOT_INSPECTED;

    props.setInspectionStatus(context.contextId, inspectionStatusValue, mainJob.key);
  };

  return (
    <div className={classes.inspectionStatus}>
      <FormControlLabel
        className={classes.inspectionSelection}
        control={
          <Checkbox
            color="primary"
            checked={inspection?.status === InspectionStatus.INSPECTED}
            disabled={!context}
            onChange={setInspectionStatus}
          />
        }
        label={translate('inspection.status.INSPECTED')}
      />
      {inspection?.status === InspectionStatus.INSPECTED && (
        <div className={classes.approver}>
          <Typography align="right" component="div" variant="caption">
            {formatUserDisplayName(inspectionUser)}
          </Typography>
          <Typography align="right" component="div" variant="caption">
            {formatDateAndTime(inspection.timestamp)}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default OperatorInspectionStatus;
