import React from 'react';
import { Redirect } from 'react-router';
import { Container } from '@mui/material';
import { connect, localeConnect } from 'localeConnect';
import { defaultRoot } from 'theme';
import { createOrderDetailsModel } from 'models/orderdetails.model';
import { ApplicationState } from 'store';
import { Context } from 'store/orders/types';
import { UserDetails } from 'store/user/types';
import { RoutePaths } from 'routes';

import { scrollToTop } from '../utils';

import LoadingIndicator from '../LoadingIndicator';

import { narrowContainerStyle } from './utils';

import EditOrderNavBar from './EditOrderNavBar';
import OrderEditor from './OrderEditor';

interface ParameterProps {
  currentContext: Context;
  history: any;
}

interface PropsFromState {
  currentScaleKey: string;
  loading: boolean;
  user?: UserDetails;
}

interface PropsFromDispatch {}

/**
 * NOTE(mikkogy, 20191030) There's a bug in Eslint config that has been reported but not yet fixed
 * which complains about missing indentation when multiline binary expressions are used.
 * See: https://github.com/typescript-eslint/typescript-eslint/issues/398
 */
/*eslint-disable */
export type AllProps = PropsFromDispatch & PropsFromState & ParameterProps;
/*eslint-enable */

const mapStateToProps = (state: ApplicationState) => ({
  currentScaleKey: state.currentScaleInfo.scaleKey,
  loading: state.orders.loading,
  user: state.user.user.userData,
});

const mapDispatchToProps = {};

class EditOrderComponent extends React.PureComponent<AllProps, Record<string, never>> {
  componentDidMount(): void {
    scrollToTop();
  }

  public render() {
    if (!this.props.currentContext.order) {
      return <Redirect to={RoutePaths.ORDERS} />;
    }
    const orderDetailsModel = createOrderDetailsModel(
      this.props.currentContext,
      this.props.currentContext.order,
      this.props.currentContext.process,
      this.props.currentContext.weighingJobs ?? [],
      this.props.currentScaleKey,
      this.props.user,
    );

    return (
      <div style={defaultRoot}>
        {!this.props.currentContext.order && <Redirect to={RoutePaths.ORDERS} />}
        <EditOrderNavBar history={this.props.history} showDeepLinkReturnLink={true} />
        <Container style={narrowContainerStyle()}>
          <LoadingIndicator isLoading={this.props.loading} />
          <OrderEditor
            currentContext={this.props.currentContext}
            orderDetailsModel={orderDetailsModel}
          />
        </Container>
      </div>
    );
  }
}

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(EditOrderComponent);
