import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { translate } from '../utils/translate';

export enum SaveTareAnswer {
  SAVE = 'SAVE',
  DONT_SAVE = 'DONT_SAVE',
  DONT_SAVE_DONT_ASK_AGAIN = 'DONT_SAVE_DONT_ASK_AGAIN',
}

interface DialogProps {
  containerName: string;
  tareMass: string;
  visible: boolean;
  onAnswer: (answer: SaveTareAnswer) => void;
}

const SaveTareDialogComponent = (props: DialogProps) => {
  return (
    <div>
      <Dialog open={props.visible}>
        <DialogTitle>{translate('saveTareDialog.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translate('saveTareDialog.description', {
              tareMass: props.tareMass,
              containerName: props.containerName,
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => props.onAnswer(SaveTareAnswer.DONT_SAVE_DONT_ASK_AGAIN)}
            color="primary"
          >
            {translate('saveTareDialog.dontSaveDontAskAgain')}
          </Button>
          <Button onClick={() => props.onAnswer(SaveTareAnswer.DONT_SAVE)} color="primary">
            {translate('saveTareDialog.dontSave')}
          </Button>
          <Button
            onClick={() => props.onAnswer(SaveTareAnswer.SAVE)}
            color="primary"
            autoFocus={true}
          >
            {translate('saveTareDialog.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SaveTareDialogComponent;
