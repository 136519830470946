import { all, call, delay, fork, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';

import { AnyAction } from 'redux';
import { RoutePaths } from 'routes';
import { incomingEvent, newToast, sendMessage } from '../common/actions';
import {
  LiveDataTypes,
  RemoteReceiveTypes,
  RemoteRequestIds,
  RemoteRequestTypes,
} from '../common/types';
import {
  getCurrentContextId,
  getCurrentTrafficControlGroupStatuses,
  getMeasDeviceGroupId,
  getWebsocketConnected,
} from '../selectors';
import {
  activateContext,
  failedToStartWeighing,
  measDeviceGroupSelected,
  receiveListSuccess,
  receiveTrafficControlUpdate,
  selectMeasDeviceGroup,
  startedWeighing,
  startWeighing,
  updateTrafficControlGroupStatus,
  updateTrafficControlGroupMode,
  updateTrafficControlGroupDesiredState,
  resetTrafficControlGroupDesiredState,
} from './actions';
import {
  MeasDeviceActionTypes,
  MeasDevicesResponsePayload,
  TrafficControlGroupStates,
} from './types';

// TODO(mikkogy,20190830) refactor all req ids to one place to avoid conflicts.
const activateContextReqId = '99125';

function* handleMeasDevicesResponse(response: MeasDevicesResponsePayload) {
  const measDeviceGroups = response.measDeviceGroups;
  const measDevices = response.measDevices;
  yield put(receiveListSuccess(measDeviceGroups, measDevices));
}

function* handleResponse(action: ReturnType<typeof incomingEvent>): any {
  if (action.payload.msgType === RemoteReceiveTypes.MEAS_DEVICE_LIST) {
    const response: MeasDevicesResponsePayload = action.payload.payload;
    yield call(handleMeasDevicesResponse, response);
  } else if (action.payload.msgType === RemoteReceiveTypes.ACK) {
    if (action.payload.respId === activateContextReqId) {
      const measDeviceGroupId = yield select(getMeasDeviceGroupId);
      if (measDeviceGroupId) {
        const routePath = window.location.pathname;
        yield put(startedWeighing());
        if (routePath === RoutePaths.MEAS_DEVICES) {
          // NOTE(mikkogy,20200828) not supposed to be possible to navigate back
          // to meas device selection so we need to replace instead of push.
          yield put(replace(RoutePaths.WEIGHING));
        } else {
          yield put(push(RoutePaths.WEIGHING));
        }
      }
    }
  } else if (action.payload.msgType === RemoteReceiveTypes.NACK) {
    if (action.payload.respId === activateContextReqId) {
      yield put(failedToStartWeighing());
    } else if (action.payload.respId === RemoteRequestIds.UPDATE_TRAFFIC_CONTROL_GROUP_STATUS) {
      yield put(newToast('trafficControl.statusUpdateFailed'));
    }
  } else if (action.payload.msgType === RemoteReceiveTypes.TRAFFIC_CONTROL_GROUP_STATUS) {
    const trafficControlGroupStatusUpdate: TrafficControlGroupStates =
      action.payload.payload.trafficControlGroups;

    const trafficControlConnectionOk: boolean = action.payload.payload.trafficControlConnectionOk;

    yield put(
      receiveTrafficControlUpdate(trafficControlGroupStatusUpdate, trafficControlConnectionOk),
    );
  }
}

function* handleSelect(action: ReturnType<typeof selectMeasDeviceGroup>) {
  console.log('Selected meas device group', action.payload);
  yield put(measDeviceGroupSelected(action.payload));
}

function* doActivateContext(contextId: string, measDeviceGroupId: string): any {
  const payload = {
    msgType: RemoteRequestTypes.ACTIVATE_CONTEXT,
    reqId: activateContextReqId,
    payload: {
      contextId,
      measDeviceGroupId,
    },
  };
  const connected = yield select(getWebsocketConnected);

  // NOTE(mikkogy,20190839) if the connection to websocket is not yet up, wait for it.
  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleActivateContext(action: ReturnType<typeof activateContext>) {
  const payload = action.payload;
  yield doActivateContext(payload.contextId, payload.measDeviceGroupId);
}

function* handleStartWeighing(action: ReturnType<typeof startWeighing>): any {
  const contextId = yield select(getCurrentContextId);
  const measDeviceGroupId = yield select(getMeasDeviceGroupId);
  yield doActivateContext(contextId, measDeviceGroupId);
}

function* handleUpdateTrafficControlGroupStatus(
  action: ReturnType<typeof updateTrafficControlGroupStatus>,
): any {
  const payload = {
    msgType: RemoteRequestTypes.UPDATE_TRAFFIC_CONTROL_GROUP_STATUS,
    reqId: RemoteRequestIds.UPDATE_TRAFFIC_CONTROL_GROUP_STATUS,
    payload: {
      trafficControlGroupId: action.payload.trafficControlGroupId,
      status: action.payload.status,
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleUpdateTrafficControlGroupMode(
  action: ReturnType<typeof updateTrafficControlGroupMode>,
): any {
  const payload = {
    msgType: RemoteRequestTypes.UPDATE_TRAFFIC_CONTROL_GROUP_MODE,
    reqId: RemoteRequestIds.UPDATE_TRAFFIC_CONTROL_GROUP_MODE,
    payload: {
      trafficControlGroupId: action.payload.trafficControlGroupId,
      mode: action.payload.mode,
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }
  const currentStatuses = yield select(getCurrentTrafficControlGroupStatuses);
  const currentGroupStatus = currentStatuses[action.payload.trafficControlGroupId];
  yield put(
    updateTrafficControlGroupDesiredState(action.payload.trafficControlGroupId, {
      ...currentGroupStatus,
      mode: action.payload.mode,
    }),
  );

  yield put(sendMessage(payload));

  const { update, timedOut } = yield race({
    update: take(
      (action: AnyAction) =>
        action.type === LiveDataTypes.INCOMING_EVENT &&
        action.payload.msgType === RemoteReceiveTypes.TRAFFIC_CONTROL_GROUP_STATUS,
    ),
    timedOut: delay(5000),
  });

  if (timedOut) {
    console.log('timed out, resetting desired state');
    yield put(resetTrafficControlGroupDesiredState(action.payload.trafficControlGroupId));
  } else if (update) {
    console.log('received update, state will be updated');
  }
}

function* watchMeasDeviceDataReceived() {
  yield takeLatest(LiveDataTypes.INCOMING_EVENT, handleResponse);
}

function* watchSelect() {
  yield takeLatest(MeasDeviceActionTypes.SELECT_MEAS_DEVICE_GROUP, handleSelect);
}

function* watchActivateContext() {
  yield takeLatest(MeasDeviceActionTypes.ACTIVATE_CONTEXT, handleActivateContext);
}

function* watchStartWeighing() {
  yield takeLatest(MeasDeviceActionTypes.START_WEIGHING, handleStartWeighing);
}

function* watchUpdateTrafficControlGroupStatus() {
  yield takeLatest(
    MeasDeviceActionTypes.UPDATE_TRAFFIC_CONTROL_GROUP_STATUS,
    handleUpdateTrafficControlGroupStatus,
  );
}

function* watchUpdateTrafficControlGroupMode() {
  yield takeLatest(
    MeasDeviceActionTypes.UPDATE_TRAFFIC_CONTROL_GROUP_MODE,
    handleUpdateTrafficControlGroupMode,
  );
}

function* measDevicesSaga() {
  yield all([
    fork(watchActivateContext),
    fork(watchMeasDeviceDataReceived),
    fork(watchSelect),
    fork(watchStartWeighing),
    fork(watchUpdateTrafficControlGroupStatus),
    fork(watchUpdateTrafficControlGroupMode),
  ]);
}

export default measDevicesSaga;
