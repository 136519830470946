import { Order } from '../store/orders/types';

import { secondaryContainer, secondaryContainerTareKgs } from '../store/orders/utils';

import { BridgeEnabledFeatures } from '../store/scale-info/types';

import { undefinedMassKg } from '../store/weighing/types';

export interface NormalWeighingDeduction {
  manualDeductionKgs: number | undefined;
  tareContainerName: string | undefined;
  tareDeductionKgs: number | undefined;
}

export function toNormalWeighingDeduction(
  manualDeductionKgs: number,
  order: Order,
  componentId: string | undefined,
  enabledFeatures: BridgeEnabledFeatures,
): NormalWeighingDeduction {
  const secondaryContainerName = secondaryContainer(order, componentId)?.name;
  const secondaryContainerKgs = secondaryContainerTareKgs(order, componentId);
  const secondaryContainerCalculationKgs =
    secondaryContainerKgs === undefinedMassKg ? 0 : secondaryContainerKgs;
  const hasManualDeduction = manualDeductionKgs !== 0 && manualDeductionKgs !== undefinedMassKg;
  const hasSecondaryContainerTareDeduction =
    enabledFeatures.bridgeSecondaryContainerTareDeduction && secondaryContainerCalculationKgs !== 0;
  return {
    manualDeductionKgs: hasManualDeduction ? manualDeductionKgs : undefined,
    tareContainerName: hasSecondaryContainerTareDeduction ? secondaryContainerName : undefined,
    tareDeductionKgs: hasSecondaryContainerTareDeduction ? secondaryContainerKgs : undefined,
  };
}
