import { RouterState } from 'connected-react-router';
import React from 'react';

import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { makeStyles } from '@mui/styles';
import { v4 as uuid } from 'uuid';

import { Redirect, RouteComponentProps } from 'react-router';
import { connect, localeConnect } from 'localeConnect';
import { RoutePaths } from 'routes';
import { ApplicationState, ConnectedReduxProps } from 'store';
import { Deduction, DeductionOperation, DeductionRow } from 'store/jobs/types';
import { GenericMasterData, MasterDataState } from 'store/master-data/types';
import { toMasterDataStateType } from 'store/master-data/utils';
import {
  setContextCommentImmediately,
  setContextManualDeductions,
  setEditedDeductions,
} from 'store/orders/actions';
import { clearMainMaterials } from 'models/vehicleweighing.model';
import { ContainerType, DetailedMasterDataType, RemoteMasterDataType } from 'store/common/types';
import { Context, EditedDeductions, Order } from 'store/orders/types';
import { getNormalWeighingActiveComponentLinkedData } from 'store/orders/utils';
import { BridgeEnabledFeatures } from 'store/scale-info/types';
import { UiRoles } from 'store/user/types';
import { effectiveUiRole } from 'store/utils';

import { Trailer, Truck } from 'components/CustomIcons';

import { hasDeductionError } from 'store/jobs/utils';

import theme, { breakLongText, vehicleIconContainerMargins, vehicleIconStyle } from 'theme';

import ConfirmDialog from 'components/ConfirmDialog';
import DeductionInput from 'components/DeductionInput';
import DeductionOperationSelect from 'components/DeductionOperationSelect';

import DeductionHint from 'components/DeductionHint';
import DisabledWrap from 'components/DisabledWrap';
import { newToast } from 'store/common/actions';
import { masterDataSelected } from 'store/master-data/actions';
import { translate } from 'utils/translate';
import MasterDataSearch from '../MasterDataSearch';
import { findContext } from './utils';
import InspectorContainer from './InspectorContainer';
import MaterialSelect from './MaterialSelect';

interface Props {
  containerType: ContainerType;
  editedDeductions: EditedDeductions | undefined;
  enabledFeatures: BridgeEnabledFeatures;
  masterData: MasterDataState;
  context: Context | undefined;
  router: RouterState;
  history: any;
}

interface PropsFromDispatch {
  newToast: typeof newToast;
  setContextCommentImmediately: typeof setContextCommentImmediately;
  setContextManualDeductions: typeof setContextManualDeductions;
  setEditedDeductions: typeof setEditedDeductions;
  masterDataSelected: typeof masterDataSelected;
}

/*eslint-disable */
export type AllProps = PropsFromDispatch & Props & RouteComponentProps<{}> & ConnectedReduxProps;
/*eslint-enable */

function getCurrentContextInfo(state: ApplicationState) {
  if (
    effectiveUiRole(
      state.user.selectedUiRole,
      state.user.user,
      state.currentScaleInfo.enabledFeatures,
    ) !== UiRoles.INSPECTOR
  ) {
    throw new Error('not inspector');
  }
  const inspectorContext = findContext(state);
  if (inspectorContext.context) {
    return {
      context: inspectorContext.context,
    };
  }
  return {};
}

const mapStateToProps = (state: ApplicationState) => ({
  ...getCurrentContextInfo(state),
  editedDeductions: state.orders.editedDeductions,
  enabledFeatures: state.currentScaleInfo.enabledFeatures,
  masterData: state.masterData,
});

const mapDispatchToProps = {
  newToast,
  setContextCommentImmediately,
  setContextManualDeductions,
  setEditedDeductions,
  masterDataSelected,
};

interface LocalState {
  sidebarOpen: boolean;
  selectedDeductionRow: DeductionRow | undefined;
  wasClosedByBackPress: boolean;
}

interface ContentProps {
  addRow: () => void;
  removeRow: (rowKey: string) => void;
  comment: string;
  containerType: ContainerType;
  deductions: DeductionRow[];
  disabledMaterialKeys: string[];
  mainMaterial: GenericMasterData | undefined;
  masterData: MasterDataState;
  newToast: typeof newToast;
  order: Order | undefined;
  selectDeductionRow: (deductionRow: DeductionRow | undefined) => void;
  selectedDeductionRow: DeductionRow | undefined;
  setDeductionOperation: (rowKey: string, operation: DeductionOperation) => void;
  setKgs: (rowKey: string, kgs: number) => void;
  setMaterial: (rowKey: string, material: GenericMasterData | undefined) => void;
  setComment: (comment: string) => void;
  setDeductionComment: (rowKey: string, comment: string) => void;
  masterDataSelected: typeof masterDataSelected;
}

const useStyles = makeStyles({
  gridItem: {
    padding: '10px',
  },
  deductionItem: {
    justifyContent: 'space-between',
    paddingTop: '18px',
    marginBottom: '15px',
    alignItems: 'end',
    ...breakLongText,
  },
  deductionElement: {
    paddingBottom: 0,
    paddingLeft: '20px',
    paddingTop: 0,
    paddingRight: '20px',
  },
  deductionMaterialSelect: {
    paddingTop: '20px',
    paddingBottom: '5px',
  },
  deductionAdd: {
    textAlign: 'left',
  },
  deductionRemove: {
    paddingBottom: '10px',
    paddingLeft: '20px',
    paddingTop: '10px',
    paddingRight: '20px',
    textAlign: 'right',
  },
  containerTypeTitle: {
    ...theme.typography.overline,
    color: '#595959',
    paddingBottom: '10px',
    paddingTop: '10px',
    paddingRight: '10px',
  },
  titleContent: {
    alignSelf: 'center',
    display: 'flex',
    textAlign: 'left',
  },
  vehicleIcon: vehicleIconStyle,
  vehicleIconContainer: {
    maxWidth: '330px',
    ...vehicleIconContainerMargins,
  },
});

const type: DetailedMasterDataType = { type: RemoteMasterDataType.MATERIAL };

function DeductionsContent(props: ContentProps) {
  const classes = useStyles();

  const stateType = toMasterDataStateType(RemoteMasterDataType.MATERIAL);
  const typeData = props.masterData.dataTypes[stateType];
  const applicableDeductions = props.deductions.filter(
    (deduction) => deduction.containerType === props.containerType,
  );

  const materialSelect = (deduction: DeductionRow) => {
    const selectedMaterialName = deduction.isAddition
      ? props.mainMaterial?.name
      : deduction.material?.name;
    return (
      <MaterialSelect
        disabled={deduction.isAddition}
        onClick={() => props.selectDeductionRow(deduction)}
        selectedValue={selectedMaterialName}
      />
    );
  };

  const operationSelect = (deduction: DeductionRow) => {
    return (
      <DeductionOperationSelect
        value={deduction.isAddition ? DeductionOperation.ADDITION : DeductionOperation.DEDUCTION}
        onChange={(event: any) => {
          const operation = event.target.value as DeductionOperation;
          props.setDeductionOperation(deduction.rowKey, operation);
        }}
        disabled={!deduction.isAddition && !!deduction.material}
      />
    );
  };

  return (
    <div>
      <div>
        <Grid container>
          <Grid item xs={12} className={classes.titleContent}>
            <Typography variant={'h5'}>{translate('editDeductions.title')}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.titleContent}>
            <span className={classes.containerTypeTitle}>
              {translate(`weighing.containerTypes.${props.containerType}`)}
            </span>
            <DeductionHint hasError={hasDeductionError(applicableDeductions)} />
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.gridItem}>
                  <div className={classes.vehicleIconContainer}>
                    <div className={classes.vehicleIcon}>
                      {props.containerType === ContainerType.TRUCK ? <Truck /> : null}
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.gridItem}>
                  <div className={classes.vehicleIconContainer}>
                    <div className={classes.vehicleIcon}>
                      {props.containerType === ContainerType.TRAILER ? <Trailer /> : null}
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {!props.selectedDeductionRow && (
        <div>
          {applicableDeductions.map((deduction) => (
            <Paper key={deduction.rowKey}>
              <Grid container key={deduction.rowKey} className={classes.deductionItem}>
                <Grid item xs={6} className={classes.deductionElement}>
                  {!deduction.isAddition && !!deduction.material ? (
                    <DisabledWrap
                      onClick={() =>
                        props.newToast('editDeductions.notification.unableToUpdateOperation')
                      }
                    >
                      {operationSelect(deduction)}
                    </DisabledWrap>
                  ) : (
                    operationSelect(deduction)
                  )}
                </Grid>
                <Grid item xs={6} className={classes.deductionElement}>
                  <DeductionInput
                    deduction={deduction}
                    setDeductionKgs={(kgs: number) => props.setKgs(deduction.rowKey, kgs)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={`${classes.deductionMaterialSelect} ${classes.deductionElement}`}
                >
                  {deduction.isAddition ? (
                    <DisabledWrap
                      onClick={() =>
                        props.newToast('editDeductions.notification.unableToUpdateMaterial')
                      }
                    >
                      {materialSelect(deduction)}
                    </DisabledWrap>
                  ) : (
                    materialSelect(deduction)
                  )}
                </Grid>
                {!!deduction.material && (
                  <Grid item xs={12} className={classes.deductionElement}>
                    <TextField
                      variant="standard"
                      key={deduction.rowKey}
                      fullWidth
                      defaultValue={deduction.comment}
                      label={translate('editDeductions.comment')}
                      onChange={(e) => props.setDeductionComment(deduction.rowKey, e.target.value)}
                    />
                  </Grid>
                )}
                <Grid item xs={12} className={classes.deductionRemove}>
                  <Button color="primary" onClick={() => props.removeRow(deduction.rowKey)}>
                    {translate('editDeductions.actions.deleteRow')}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          ))}
          <Grid container className={classes.deductionItem}>
            <Grid item xs={12} className={classes.deductionAdd}>
              <Button color="primary" onClick={() => props.addRow()}>
                <>
                  <AddIcon />
                  {translate('editDeductions.actions.addRow')}
                </>
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <TextField
              variant="standard"
              fullWidth
              defaultValue={props.comment}
              label={translate('editDeductions.comment')}
              onChange={(e) => props.setComment(e.target.value)}
            />
          </Grid>
        </div>
      )}
      {!!props.selectedDeductionRow && (
        <div>
          <MasterDataSearch
            disabledKeys={props.disabledMaterialKeys}
            requireSelection={false}
            select={(item: GenericMasterData | undefined) => {
              if (!props.selectedDeductionRow?.rowKey) return;
              props.setMaterial(props.selectedDeductionRow.rowKey, item);
              props.selectDeductionRow(undefined);
              if (!item) return;
              props.masterDataSelected(type, item);
            }}
            selectedItem={props.selectedDeductionRow.material}
            type={type}
            typeState={typeData}
          />
        </div>
      )}
    </div>
  );
}

export class EditDeductionsComponent extends React.PureComponent<AllProps, LocalState> {
  /**
   * NOTE(lindenlas, 20220302) this is used to check if setting react state makes sense.
   * If component is or will be unmounted, setting react state will produce a warning.
   * This could happen e.g. with browser back button.
   * This value cannot be stored in state, as this value is needed before
   * render() knows new state value after changing the value.
   */
  private isComponentUnmounting = false;
  constructor(props: AllProps) {
    super(props);
    this.backPressed = this.backPressed.bind(this);
    this.doClose = this.doClose.bind(this);
    this.cancelExiting = this.cancelExiting.bind(this);

    this.state = {
      sidebarOpen: false,
      wasClosedByBackPress: false,
      selectedDeductionRow: undefined,
    };
  }

  componentDidMount() {
    window.addEventListener('popstate', this.backPressed);
  }

  componentWillUnmount() {
    this.isComponentUnmounting = true;
    // NOTE(mikkogy,20201022) handler can't be removed immediately because
    // componentWillUnmount is called before the handler.
    setTimeout(() => {
      window.removeEventListener('popstate', this.backPressed);
    }, 50);
  }

  handleDrawerToggle = () => {
    this.setState({ ...this.state, sidebarOpen: !this.state.sidebarOpen });
  };

  backPressed(e: any) {
    this.onClose(true);
  }

  doClose(fromBackPress: boolean) {
    if (fromBackPress) {
      this.props.history.push(RoutePaths.ORDERS);
    } else {
      this.props.history.replace(RoutePaths.ORDERS);
    }
    this.props.setEditedDeductions(undefined);
  }

  cancelExiting() {
    if (!this.props.editedDeductions) return;
    this.props.setEditedDeductions({
      ...this.props.editedDeductions,
      isDiscardChangesDialogOpen: false,
    });
    if (this.state.wasClosedByBackPress) {
      this.props.history.push(RoutePaths.EDIT_DEDUCTIONS);
    }
  }

  onClose = (fromBackPress: boolean) => {
    if (this.state.selectedDeductionRow) {
      this.setState({
        selectedDeductionRow: undefined,
      });
      return;
    }
    if (this.props.editedDeductions === undefined || this.props.context === undefined) {
      return;
    }
    const hasErrors = hasDeductionError(this.props.editedDeductions.deductions);
    this.props.setEditedDeductions({
      ...this.props.editedDeductions,
      isDiscardChangesDialogOpen: hasErrors,
    });
    if (!this.isComponentUnmounting) {
      this.setState({
        wasClosedByBackPress: fromBackPress,
      });
    }
    if (hasErrors && fromBackPress) {
      this.props.history.push(RoutePaths.EDIT_DEDUCTIONS);
    }
    if (!hasErrors) {
      const linkedMaterials = getNormalWeighingActiveComponentLinkedData(
        this.props.context.order,
        this.props.context.splitLoad,
        RemoteMasterDataType.MATERIAL,
      );
      const materials =
        this.props.containerType === ContainerType.TRUCK
          ? linkedMaterials.truck
          : linkedMaterials.trailer;
      const clearedResult = clearMainMaterials(
        this.props.editedDeductions.deductions,
        materials,
        this.props.editedDeductions.container.key,
      );
      const confirmedDeductions = clearedResult.deductions.map((deduction) => {
        const absoluteKgs = Math.abs(deduction.kgs);
        const actualKgs = deduction.isAddition ? -absoluteKgs : absoluteKgs;
        const confirmedDeduction: Deduction = {
          containerKey: deduction.containerKey,
          kgs: actualKgs,
          materialKey: deduction.material?.key ?? '',
          // NOTE(renttom,20220714) don't store comment if material is not selected
          comment: deduction.material?.key ? deduction.comment : '',
        };
        return confirmedDeduction;
      });
      if (clearedResult.hasMaterialChanged) {
        this.props.newToast('orders.materialUsedInDeductionsChanged');
      }
      this.props.setContextManualDeductions(
        this.props.editedDeductions.contextId,
        confirmedDeductions,
      );
      if (this.props.editedDeductions.comment !== this.props.editedDeductions.originalComment) {
        this.props.setContextCommentImmediately(
          this.props.editedDeductions.contextId,
          this.props.editedDeductions.comment,
          this.props.editedDeductions.commentJobKey,
        );
      }
      this.doClose(fromBackPress);
    }
  };

  containerName = () => {
    if (!this.props.editedDeductions) return '';
    return this.props.editedDeductions.container.name;
  };

  public render() {
    if (
      this.props.editedDeductions === undefined ||
      this.props.context === undefined ||
      this.props.editedDeductions.contextId !== this.props.context.contextId
    ) {
      return <Redirect to={RoutePaths.ORDERS} />;
    }
    const exitOnInvalidDeductions = {
      confirmText: translate('editDeductions.discardDialog.confirmAction'),
      dontConfirmText: translate('editDeductions.discardDialog.dontConfirmAction'),
      title: translate('editDeductions.discardDialog.title'),
      question: translate('editDeductions.discardDialog.content'),
      visible: this.props.editedDeductions.isDiscardChangesDialogOpen || false,
      onNotConfirmed: () => this.doClose(false),
      onConfirmed: () => this.cancelExiting(),
    };

    return (
      <InspectorContainer
        showSiteInfo={false}
        title={this.containerName()}
        onGoBack={this.props.editedDeductions.contextId ? () => this.onClose(false) : undefined}
      >
        <ConfirmDialog {...exitOnInvalidDeductions} />
        <DeductionsContent
          addRow={() => {
            if (!this.props.editedDeductions) return;
            const deductions = [...this.props.editedDeductions.deductions];
            const rowId = uuid();
            deductions.push({
              containerKey: this.props.editedDeductions.container.key,
              containerType: this.props.editedDeductions.container.containerType,
              isAddition: false,
              jobKey: '',
              kgs: 0,
              material: undefined,
              rowKey: rowId,
              comment: '',
            });
            this.props.setEditedDeductions({
              ...this.props.editedDeductions,
              deductions,
            });
          }}
          removeRow={(rowKey: string) => {
            if (!this.props.editedDeductions) return;
            const deductions = [
              ...this.props.editedDeductions.deductions.filter(
                (deduction) => deduction.rowKey !== rowKey,
              ),
            ];
            this.props.setEditedDeductions({
              ...this.props.editedDeductions,
              deductions,
            });
          }}
          setDeductionOperation={(rowKey, operation) => {
            if (!this.props.editedDeductions) return;
            function kgsSign(kgs: number, selectedOperation: DeductionOperation) {
              const absKgs = Math.abs(kgs);
              if (selectedOperation === DeductionOperation.ADDITION) {
                return -absKgs;
              }
              return absKgs;
            }
            const deductions = [
              ...this.props.editedDeductions.deductions.map((deduction) => {
                if (deduction.rowKey !== rowKey) return deduction;
                const kgs = kgsSign(deduction.kgs, operation);
                return {
                  ...deduction,
                  kgs,
                  isAddition: operation === DeductionOperation.ADDITION,
                };
              }),
            ];
            this.props.setEditedDeductions({
              ...this.props.editedDeductions,
              deductions,
            });
          }}
          setKgs={(rowKey, kgs) => {
            if (!this.props.editedDeductions) return;
            const deductions = [
              ...this.props.editedDeductions.deductions.map((deduction) => {
                if (deduction.rowKey !== rowKey) return deduction;
                return {
                  ...deduction,
                  kgs,
                };
              }),
            ];
            this.props.setEditedDeductions({
              ...this.props.editedDeductions,
              deductions,
            });
          }}
          setMaterial={(rowKey, material) => {
            if (!this.props.editedDeductions) return;
            const deductions = [
              ...this.props.editedDeductions.deductions.map((deduction) => {
                if (deduction.rowKey !== rowKey) return deduction;
                return {
                  ...deduction,
                  material,
                };
              }),
            ];
            this.props.setEditedDeductions({
              ...this.props.editedDeductions,
              deductions,
            });
          }}
          setComment={(comment) => {
            if (!this.props.editedDeductions) return;
            this.props.setEditedDeductions({
              ...this.props.editedDeductions,
              comment,
            });
          }}
          setDeductionComment={(rowKey, comment) => {
            if (!this.props.editedDeductions) return;

            const deductions = [
              ...this.props.editedDeductions.deductions.map((deduction) => {
                if (deduction.rowKey !== rowKey) return deduction;
                return {
                  ...deduction,
                  comment,
                };
              }),
            ];

            this.props.setEditedDeductions({
              ...this.props.editedDeductions,
              deductions,
            });
          }}
          comment={this.props.editedDeductions.comment}
          containerType={this.props.editedDeductions.container.containerType}
          deductions={this.props.editedDeductions.deductions}
          disabledMaterialKeys={this.props.editedDeductions.disabledMaterialKeys}
          mainMaterial={this.props.editedDeductions.mainMaterial}
          masterData={this.props.masterData}
          order={this.props.context.order}
          selectedDeductionRow={this.state.selectedDeductionRow}
          selectDeductionRow={(deductionRow: DeductionRow | undefined) => {
            const newSelection = deductionRow ? { ...deductionRow } : undefined;
            this.setState({
              selectedDeductionRow: newSelection,
            });
          }}
          newToast={(toastKey) => this.props.newToast(toastKey)}
          masterDataSelected={this.props.masterDataSelected}
        />
      </InspectorContainer>
    );
  }
}

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(EditDeductionsComponent);
