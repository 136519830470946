import React from 'react';
import { FormControl, FormLabel, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { JobDirection } from 'store/jobs/types';
import { translate } from 'utils/translate';

interface Props {
  handleDirectionChange: (direction: JobDirection) => void;
  disabled: boolean;
  value: JobDirection;
}

const useStyles = makeStyles({
  radioGroup: {
    flexDirection: 'row',
  },
});

const JobDirectionRadioForm = (props: Props) => {
  const classes = useStyles();

  const radioItem = (direction: JobDirection) => (
    <FormControlLabel
      key={direction}
      value={direction}
      control={<Radio color="primary" disabled={props.disabled} />}
      label={translate(`jobDirection.values.JOB_DIRECTION_ENUM_${direction}`)}
    />
  );

  return (
    <FormControl variant="standard" component="fieldset">
      <FormLabel component="legend">{translate('jobDirection.title')}</FormLabel>
      <RadioGroup
        className={classes.radioGroup}
        aria-label="job-direction"
        name="job-direction"
        value={props.value}
        onChange={(event) => props.handleDirectionChange(event.target.value as JobDirection)}
      >
        {Object.keys(JobDirection).map((direction) => radioItem(direction as JobDirection))}
      </RadioGroup>
    </FormControl>
  );
};

export default JobDirectionRadioForm;
