import React from 'react';
import { RouterState } from 'connected-react-router';
import { RouteComponentProps } from 'react-router';

import { connect, localeConnect } from 'localeConnect';
import { ApplicationState, ConnectedReduxProps } from 'store';
import { jobsTrucksAndTrailers } from 'store/jobs/utils';
import { ContainerItem, ContainerType, RemoteMasterDataType } from 'store/common/types';
import {
  removeImage,
  setImageDescription,
  setImageDescriptionImmediately,
} from 'store/images/actions';
import { CacheImage } from 'store/images/types';
import { DeductionRow } from 'store/jobs/types';
import { setMultipartEditComponentId, setEditedDeductions } from 'store/orders/actions';
import { destroyContextWithId } from 'store/weighing/actions';
import {
  getContextMainJob,
  getNormalWeighingActiveComponentLinkedData,
  MainJobMultipartBehavior,
} from 'store/orders/utils';
import { Context, WeighingProcess } from 'store/orders/types';
import { BridgeEnabledFeatures } from 'store/scale-info/types';
import { contextToNormalVehicleWeighingModels, NetContainer } from 'models/vehicleweighing.model';
import { RoutePaths } from 'routes';
import ConfirmDialog from 'components/ConfirmDialog';
import { setInspectionStatus } from 'store/jobs/actions';
import { translate } from 'utils/translate';
import { hasUploadsInProgress } from '../utils';
import { findContext } from './utils';

import InspectorContainer from './InspectorContainer';
import InspectorNavBarVehicle from './InspectorNavBarVehicle';
import InspectorOrderSelection from './InspectorOrderSelection';
import InspectorOrderDetails from './InspectorOrderDetails';

interface PropsFromState {
  context?: Context;
  enabledFeatures: BridgeEnabledFeatures;
  router: RouterState;
  images: CacheImage[];
}

interface PropsFromDispatch {
  destroyContextWithId: typeof destroyContextWithId;
  removeImage: typeof removeImage;
  setEditedDeductions: typeof setEditedDeductions;
  setImageDescription: typeof setImageDescription;
  setImageDescriptionImmediately: typeof setImageDescriptionImmediately;
  setInspectionStatus: typeof setInspectionStatus;
  setMultipartEditComponentId: typeof setMultipartEditComponentId;
}

/**
 * NOTE(mikkogy,20201016) There's a bug in Eslint config that has been reported but not yet fixed
 * which complains about missing indentation when multiline binary expressions are used.
 * See: https://github.com/typescript-eslint/typescript-eslint/issues/398
 */
/*eslint-disable */
export type AllProps = PropsFromDispatch &
  PropsFromState &
  RouteComponentProps<{}> &
  ConnectedReduxProps;
/*eslint-enable */

interface LocalState {
  sidebarOpen: boolean;
  isPhotoDiscardDialogOpen: boolean;
  wasClosedByBackPress: boolean;
}

const mapStateToProps = (state: ApplicationState) => {
  const inspectorContext = findContext(state);
  return {
    images: inspectorContext.images,
    context: inspectorContext.context,
    enabledFeatures: state.currentScaleInfo.enabledFeatures,
  };
};

const mapDispatchToProps = {
  destroyContextWithId,
  removeImage,
  setEditedDeductions,
  setImageDescription,
  setImageDescriptionImmediately,
  setInspectionStatus,
  setMultipartEditComponentId,
};

interface ContentProps {
  context: Context | undefined;
  enabledFeatures: BridgeEnabledFeatures;
  history: any;
  setMultipartEditComponentId: typeof setMultipartEditComponentId;
  setInspectionStatus: typeof setInspectionStatus;
  setEditedDeductions: typeof setEditedDeductions;
  setImageDescription: typeof setImageDescription;
  setImageDescriptionImmediately: typeof setImageDescriptionImmediately;
}

const OrderContent = (props: ContentProps) => {
  const startEditing = (multipartComponentId: string | undefined) => {
    props.setMultipartEditComponentId(multipartComponentId ?? '');
    props.history.push(RoutePaths.EDIT_ORDER);
  };

  const setEditedDeductionsContainer = (container: ContainerItem) => {
    const mainJob = getContextMainJob(props.context);
    if (!mainJob) return;
    if (!props.context?.weighingJobs) return;
    if (props.context.process !== WeighingProcess.NORMAL) return;
    const model = contextToNormalVehicleWeighingModels(props.context, [], props.enabledFeatures);
    const deductions: DeductionRow[] = model?.net?.reduce(
      (deductions: DeductionRow[], net: NetContainer) => {
        net?.manualDeductions?.forEach((deduction) => deductions.push(deduction));
        return deductions;
      },
      [],
    );
    const comment = mainJob?.comment ?? '';
    const linkedMaterials = getNormalWeighingActiveComponentLinkedData(
      props.context.order,
      props.context.splitLoad,
      RemoteMasterDataType.MATERIAL,
    );
    const containerLinkedMaterials =
      container.containerType === ContainerType.TRUCK
        ? linkedMaterials.truck
        : linkedMaterials.trailer;
    const editedDeductions = {
      comment,
      originalComment: comment,
      commentJobKey: mainJob.key,
      container: container,
      contextId: props.context.contextId,
      isDiscardChangesDialogOpen: false,
      deductions: deductions,
      disabledMaterialKeys: containerLinkedMaterials.map((material) => material.key),
      mainMaterial: containerLinkedMaterials.length === 1 ? containerLinkedMaterials[0] : undefined,
    };
    props.setEditedDeductions(editedDeductions);
  };

  const editDeductions = (container: ContainerItem) => {
    setEditedDeductionsContainer(container);
    props.history.replace(RoutePaths.EDIT_DEDUCTIONS);
  };

  if (props.context?.order && getContextMainJob(props.context, MainJobMultipartBehavior.FIRST)) {
    return (
      <InspectorOrderDetails
        context={props.context}
        editOrder={startEditing}
        editDeductions={editDeductions}
        enabledFeatures={props.enabledFeatures}
        setImageDescription={(key: string, description: string, useForce: boolean) => {
          if (useForce) {
            props.setImageDescriptionImmediately(key, description);
          } else {
            props.setImageDescription(key, description);
          }
        }}
        setInspectionStatus={props.setInspectionStatus}
      />
    );
  } else {
    return <InspectorOrderSelection onSelected={() => props.history.push(RoutePaths.ORDERS)} />;
  }
};

export class InspectorOrdersComponent extends React.PureComponent<AllProps, LocalState> {
  /**
   * NOTE(lindenlas, 20220302) this is used to check if setting react state makes sense.
   * If component is or will be unmounted, setting react state will produce a warning.
   * This could happen e.g. with browser back button.
   * This value cannot be stored in state, as this value is needed before
   * render() knows new state value after changing the value.
   */
  private isComponentUnmounting = false;
  constructor(props: AllProps) {
    super(props);
    this.backPressed = this.backPressed.bind(this);
    this.doClose = this.doClose.bind(this);
    this.cancelExiting = this.cancelExiting.bind(this);
    this.state = {
      sidebarOpen: false,
      isPhotoDiscardDialogOpen: false,
      wasClosedByBackPress: false,
    };
  }

  componentDidMount() {
    // NOTE(mikkogy,20220221) as inspector orders are shown now we can get rid
    // of edited deductions if any. If there is anything in store it is
    // certainly outdated.
    this.props.setEditedDeductions(undefined);
    window.addEventListener('popstate', this.backPressed);
  }

  componentWillUnmount() {
    this.isComponentUnmounting = true;
    // NOTE(mikkogy,20201022) handler can't be removed immediately because
    // componentWillUnmount is called before the handler.
    setTimeout(() => {
      window.removeEventListener('popstate', this.backPressed);
    }, 50);
  }

  handleDrawerToggle = () => {
    this.setState({ ...this.state, sidebarOpen: !this.state.sidebarOpen });
  };

  backPressed(e: any) {
    this.onClose(true);
  }

  doClose(fromBackPress: boolean) {
    if (this.props.context) {
      this.props.destroyContextWithId(this.props.context.contextId);
    }
    if (!this.isComponentUnmounting) {
      this.setState({ isPhotoDiscardDialogOpen: false });
    }
    if (!fromBackPress) {
      this.props.history.goBack();
    }
  }

  cancelExiting() {
    this.setState({ isPhotoDiscardDialogOpen: false });
    if (this.state.wasClosedByBackPress) {
      this.props.history.push(RoutePaths.ORDERS);
    }
  }

  onClose = (fromBackPress: boolean) => {
    const hasUploads = hasUploadsInProgress(this.props.context, this.props.images);
    if (!this.isComponentUnmounting) {
      this.setState({
        isPhotoDiscardDialogOpen: hasUploads,
        wasClosedByBackPress: fromBackPress,
      });
    }
    if (!hasUploads) {
      this.doClose(fromBackPress);
    }
  };

  containerName = () => {
    const mainJob = getContextMainJob(this.props.context, MainJobMultipartBehavior.FIRST);
    const selectOrderTitle = translate('orders.selectOrder');
    if (!mainJob) {
      return selectOrderTitle;
    }
    const containers = jobsTrucksAndTrailers([mainJob]);
    if (this.props.context !== undefined && containers && containers.length > 0) {
      return <InspectorNavBarVehicle context={this.props.context} />;
    }
    return selectOrderTitle;
  };

  public render() {
    const exitOnUploadInProgressProps = {
      title: translate('images.uploadCancel.title'),
      question: translate('images.uploadCancel.question'),
      visible: this.state.isPhotoDiscardDialogOpen,
      onConfirmed: () => this.doClose(false),
      onNotConfirmed: this.cancelExiting,
    };

    const orderContentProps: ContentProps = {
      context: this.props.context,
      enabledFeatures: this.props.enabledFeatures,
      history: this.props.history,
      setMultipartEditComponentId: this.props.setMultipartEditComponentId,
      setInspectionStatus: this.props.setInspectionStatus,
      setEditedDeductions: this.props.setEditedDeductions,
      setImageDescription: this.props.setImageDescription,
      setImageDescriptionImmediately: this.props.setImageDescriptionImmediately,
    };

    return (
      <InspectorContainer
        showSiteInfo={!!this.props.context}
        title={this.containerName()}
        onGoBack={this.props.context ? () => this.onClose(false) : undefined}
      >
        <ConfirmDialog {...exitOnUploadInProgressProps} />
        <OrderContent {...orderContentProps} />
      </InspectorContainer>
    );
  }
}

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(InspectorOrdersComponent);
