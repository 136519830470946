import { all, call, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { RoutePaths } from 'routes';
import { contextToNormalVehicleWeighingModels } from 'models/vehicleweighing.model';
import { incomingEvent, newToast, sendMessage } from '../common/actions';
import {
  LiveDataTypes,
  MasterResponse,
  RemoteRequestIds,
  RemoteRequestTypes,
  RemoteReceiveTypes,
  ToastType,
} from '../common/types';
import { setDriverReceiptJobKeys } from '../jobs/actions';
import {
  getContexts,
  getCurrentContextId,
  getOperatorFinishingOrderKey,
  getSelectedOperatorOrder,
  getWebsocketConnected,
  getSelectedUiRole,
  getUser,
  getEnabledFeatures,
} from '../selectors';
import { destroyContextHandled, showOrderInfo } from '../orders/actions';

import { WeighingProcess, OperatorOrderMode, OperatorOrderTab } from '../orders/types';
import {
  MainJobMultipartBehavior,
  getContextMainJob,
  hasContextRequiredMasterData,
} from '../orders/utils';
import { Job } from '../jobs/types';
import { UiRoles } from '../user/types';
import { effectiveUiRole } from '../utils';
import { clearSelectedMeasDevice } from '../meas-devices/actions';
import { BridgeEnabledFeatures } from '../scale-info/types';
import {
  confirmWeighing,
  destroyContextSucceeded,
  destroyContextWithId,
  discardPreviousLoad,
  discardPreviousLoadByContextId,
  doManualWeighingByContextId,
  doWeighing,
  doWeighingByContextId,
  finishContext,
  finishContextById,
  finishWithContextTare,
  inactivateContext,
  inactivateContextById,
  inactivateContextFailed,
  inactivateContextSucceeded,
  statusSuccess,
  weighingSuccess,
  requestZeroing,
  setOperatorFinishingOrderKey,
} from './actions';
import { Status, WeighingActionTypes } from './types';

function* handleZeroing(action: ReturnType<typeof requestZeroing>): any {
  const payload = {
    msgType: RemoteRequestTypes.ZERO,
    reqId: RemoteRequestIds.ZERO,
    payload: {
      measDeviceKeys: action.payload,
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* doHandleDiscardPreviousLoad(contextId: string): any {
  const payload = {
    msgType: RemoteRequestTypes.DISCARD_PREVIOUS_LOAD,
    reqId: RemoteRequestIds.DISCARD_PREVIOUS_LOAD,
    payload: {
      contextId,
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleDiscardPreviousLoad(action: ReturnType<typeof discardPreviousLoad>): any {
  const contextId = yield select(getCurrentContextId);
  yield call(doHandleDiscardPreviousLoad, contextId);
}

function* handleDiscardPreviousLoadByContextId(
  action: ReturnType<typeof discardPreviousLoadByContextId>,
) {
  yield call(doHandleDiscardPreviousLoad, action.payload);
}

function* sendDoWeighing(contextId: string, measDeviceKeys: string[]): any {
  const payload = {
    msgType: RemoteRequestTypes.DO_WEIGHING,
    reqId: RemoteRequestIds.DO_WEIGHING,
    payload: {
      contextId,
      measDeviceKeys,
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleDoWeighing(action: ReturnType<typeof doWeighing>): any {
  const contextId = yield select(getCurrentContextId);
  const measDeviceKeys = action.payload;
  yield call(sendDoWeighing, contextId, measDeviceKeys);
}

function* handleDoManualWeighingByContextId(
  action: ReturnType<typeof doManualWeighingByContextId>,
): any {
  const payload = {
    msgType: RemoteRequestTypes.DO_MANUAL_WEIGHING,
    reqId: RemoteRequestIds.DO_MANUAL_WEIGHING,
    payload: {
      contextId: action.payload.contextId,
      kgs: action.payload.kgs,
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleDoWeighingByContextId(action: ReturnType<typeof doWeighingByContextId>) {
  const { contextId, measDeviceKeys } = action.payload;
  yield call(sendDoWeighing, contextId, measDeviceKeys);
}

function* handleDestroyContext(): any {
  const contextId = yield select(getCurrentContextId);
  if (!contextId) {
    // NOTE(mikkogy,20210617) no context to destroy. It's easier to skip request
    // here than to check in every place that context exists.
    return;
  }
  const payload = {
    msgType: RemoteRequestTypes.DESTROY_CONTEXT,
    reqId: RemoteRequestIds.DESTROY_CONTEXT,
    payload: {
      contextId,
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
  yield put(destroyContextHandled());
}

function* handleDestroyContextWithId(action: ReturnType<typeof destroyContextWithId>): any {
  const payload = {
    msgType: RemoteRequestTypes.DESTROY_CONTEXT,
    reqId: RemoteRequestIds.DESTROY_CONTEXT_WITH_ID,
    payload: {
      contextId: action.payload,
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
  yield put(destroyContextHandled());
}

function* doHandleFinishContext(contextId: string, isSaveTareRequested: boolean): any {
  const payload = {
    msgType: RemoteRequestTypes.FINISH_CONTEXT,
    reqId: RemoteRequestIds.FINISH_CONTEXT,
    payload: {
      contextId,
      isSaveTareRequested,
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleFinishContext(action: ReturnType<typeof finishContext>): any {
  const contextId = yield select(getCurrentContextId);
  const contexts = yield select(getContexts);
  const context = contexts[contextId];
  const mainJob = getContextMainJob(context, MainJobMultipartBehavior.FIRST);
  if (!mainJob) return;
  // NOTE(mikkogy,20230117) we may get more jobs due to multipart, deductions or
  // split load. Bridge will inform in the reply so we don't have to care.
  const jobKeys: string[] = [mainJob.key];
  yield put(setDriverReceiptJobKeys(jobKeys));
  yield call(doHandleFinishContext, contextId, action.payload);
  yield put(replace(RoutePaths.RECEIPT));
}

function* handleFinishContextById(action: ReturnType<typeof finishContextById>): any {
  const contexts = yield select(getContexts);
  const contextId = action.payload.contextId;
  const context = contexts[contextId];
  let orderKey = '';
  if (context) {
    orderKey = context.orderKey;
  }
  yield put(setOperatorFinishingOrderKey(orderKey));
  yield call(doHandleFinishContext, contextId, action.payload.isSaveTareRequested);
}

function* handleFinishWithContextTare(action: ReturnType<typeof finishWithContextTare>): any {
  const contexts = yield select(getContexts);
  const contextId = action.payload;
  const context = contexts[contextId];
  if (!context) {
    return;
  }
  const uiRole = effectiveUiRole(
    yield select(getSelectedUiRole),
    yield select(getUser),
    yield select(getEnabledFeatures),
  );
  if (uiRole === UiRoles.DRIVER) {
    const jobKeys = context.weighingJobs.map((job: Job) => job.key);
    yield put(setDriverReceiptJobKeys(jobKeys));
    yield put(replace(RoutePaths.RECEIPT));
  } else if (uiRole === UiRoles.OPERATOR) {
    const orderKey = context.orderKey;
    yield put(setOperatorFinishingOrderKey(orderKey));
  } else {
    throw new Error('Unexpected finish with context tare, not DRIVER or OPERATOR');
  }
  const payload = {
    msgType: RemoteRequestTypes.FINISH_WITH_CONTEXT_TARE,
    reqId: RemoteRequestIds.FINISH_WITH_CONTEXT_TARE,
    payload: {
      contextId,
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleSaveTareAndFinishContext(action: ReturnType<typeof finishWithContextTare>): any {
  const contextId = action.payload;
  const payload = {
    msgType: RemoteRequestTypes.SAVE_TARE_AND_FINISH,
    reqId: RemoteRequestIds.SAVE_TARE_AND_FINISH,
    payload: {
      contextId,
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* doInactivateContext(contextId: string): any {
  const payload = {
    msgType: RemoteRequestTypes.INACTIVATE_CONTEXT,
    reqId: RemoteRequestIds.INACTIVATE_CONTEXT,
    payload: {
      contextId,
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleInactivateContextById(action: ReturnType<typeof inactivateContextById>) {
  yield doInactivateContext(action.payload);
}

function* handleInactivateContext(): any {
  const contextId = yield select(getCurrentContextId);
  yield doInactivateContext(contextId);
}

function* toOrders() {
  yield put(replace(RoutePaths.ORDERS));
}

function* handleOperatorContextFinishAck(jobKeys: string[]): any {
  const orderKey = yield select(getOperatorFinishingOrderKey);
  yield put(setOperatorFinishingOrderKey(''));
  const contextId = yield select(getCurrentContextId);
  const contexts = yield select(getContexts);
  const context = !!contexts && !!contextId ? contexts[contextId] : undefined;
  let jobFilter: string | undefined = undefined;
  if (context && jobKeys.length === 1) {
    jobFilter = jobKeys[0];
  }
  yield put(
    showOrderInfo(orderKey, OperatorOrderTab.Weighing, OperatorOrderMode.Viewing, jobFilter),
  );
}

function* handleMessage(action: ReturnType<typeof incomingEvent>): any {
  const statusType = RemoteReceiveTypes.STATUS;
  if (action.payload.msgType === statusType) {
    const response: Status = action.payload.payload;
    yield put(statusSuccess(response));
  }
  const uiRole = effectiveUiRole(
    yield select(getSelectedUiRole),
    yield select(getUser),
    yield select(getEnabledFeatures),
  );
  if (action.payload.msgType === RemoteReceiveTypes.ACK) {
    const response: MasterResponse = action.payload;
    if (response.respId === RemoteRequestIds.DO_WEIGHING) {
      yield put(weighingSuccess());
    } else if (response.respId === RemoteRequestIds.DESTROY_CONTEXT) {
      yield put(destroyContextSucceeded());
      yield call(toOrders);
    } else if (response.respId === RemoteRequestIds.INACTIVATE_CONTEXT) {
      yield put(inactivateContextSucceeded());
      if (uiRole === UiRoles.DRIVER) {
        yield call(toOrders);
      }
    } else if (response.respId === RemoteRequestIds.SAVE_TARE_AND_FINISH) {
      let messageKey = 'orderDetails.saveTare.successMessage';
      if (uiRole === UiRoles.DRIVER) {
        yield call(toOrders);
      } else if (uiRole === UiRoles.OPERATOR) {
        messageKey = 'operatorOrder.weighing.saveTare.successMessage';
        yield put(replace(RoutePaths.DASHBOARD));
      }
      yield put(newToast(messageKey, ToastType.SUCCESS));
      yield put(destroyContextHandled());
    }
  }
  if (action.payload.msgType === RemoteReceiveTypes.NACK) {
    const response: MasterResponse = action.payload;
    if (response.respId === RemoteRequestIds.INACTIVATE_CONTEXT) {
      yield put(inactivateContextFailed());
    }
  }
  if (action.payload.msgType === RemoteReceiveTypes.FINISH_CONTEXT_SUCCEEDED) {
    const jobKeys: string[] = action.payload.payload.jobKeys;
    if (uiRole === UiRoles.DRIVER) {
      yield put(setDriverReceiptJobKeys(jobKeys));
      yield put(replace(RoutePaths.RECEIPT));
    } else if (uiRole === UiRoles.OPERATOR) {
      yield call(handleOperatorContextFinishAck, jobKeys);
    }
    yield put(destroyContextHandled());
  }
  if (action.payload.msgType === RemoteReceiveTypes.WEIGHING_FAILED) {
    const contextId = yield select(getCurrentContextId);
    const operatorOrder = yield select(getSelectedOperatorOrder);
    const uiRole = effectiveUiRole(
      yield select(getSelectedUiRole),
      yield select(getUser),
      yield select(getEnabledFeatures),
    );
    const shouldShowDriverToast =
      uiRole === UiRoles.DRIVER && !!contextId && contextId === action.payload.payload.contextId;
    const operatorContext =
      operatorOrder && operatorOrder.context ? operatorOrder.context : undefined;
    const operatorContextId = operatorContext ? operatorContext.contextId : undefined;
    const shouldShowOperatorToast =
      uiRole === UiRoles.OPERATOR &&
      !!operatorContextId &&
      operatorContextId === action.payload.payload.contextId;
    if (shouldShowDriverToast || shouldShowOperatorToast) {
      yield put(newToast('weighing.weighingFailed'));
    }
  }
}

function* handleConfirmWeighing(action: ReturnType<typeof confirmWeighing>): any {
  // NOTE(anttipalola, 20190617) Weighing confirmation step is UI based with no backend logic
  yield put(clearSelectedMeasDevice());
  // Check if this was the first weighing, go back to orders if it was
  // or to receipt if it was the second
  const currentContextId = yield select(getCurrentContextId);
  const contexts = yield select(getContexts);
  const context = contexts[currentContextId];
  if (context && context.order) {
    if (context.process === WeighingProcess.NORMAL) {
      const enabledFeatures: BridgeEnabledFeatures = yield select(getEnabledFeatures);
      const model = contextToNormalVehicleWeighingModels(context, [], enabledFeatures);
      if (hasContextRequiredMasterData(context) && model.isFinishingEnabled) {
        yield put(finishContext(action.payload));
      } else {
        yield put(inactivateContext());
      }
    } else {
      yield put(inactivateContext());
    }
  }
}

function* watchDiscardPreviousLoad() {
  yield takeEvery(WeighingActionTypes.DISCARD_PREVIOUS_LOAD, handleDiscardPreviousLoad);
}

function* watchDiscardPreviousLoadByContextId() {
  yield takeEvery(
    WeighingActionTypes.DISCARD_PREVIOUS_LOAD_BY_CONTEXT_ID,
    handleDiscardPreviousLoadByContextId,
  );
}

function* watchDoWeighing() {
  yield takeEvery(WeighingActionTypes.DO_WEIGHING, handleDoWeighing);
}

function* watchDoManualWeighingByContextId() {
  yield takeEvery(
    WeighingActionTypes.DO_MANUAL_WEIGHING_BY_CONTEXT_ID,
    handleDoManualWeighingByContextId,
  );
}

function* watchDoWeighingByContextId() {
  yield takeEvery(WeighingActionTypes.DO_WEIGHING_BY_CONTEXT_ID, handleDoWeighingByContextId);
}

function* watchConfirmWeighing() {
  yield takeEvery(WeighingActionTypes.CONFIRM_WEIGHING, handleConfirmWeighing);
}

function* watchZeroing() {
  yield takeEvery(WeighingActionTypes.REQUEST_ZERO, handleZeroing);
}

function* watchDestroyContext() {
  yield takeEvery(WeighingActionTypes.DESTROY_CONTEXT, handleDestroyContext);
}

function* watchDestroyContextWithId() {
  yield takeEvery(WeighingActionTypes.DESTROY_CONTEXT_WITH_ID, handleDestroyContextWithId);
}

function* watchFinishContext() {
  yield takeEvery(WeighingActionTypes.FINISH_CONTEXT, handleFinishContext);
}

function* watchFinishContextById() {
  yield takeEvery(WeighingActionTypes.FINISH_CONTEXT_BY_ID, handleFinishContextById);
}

function* watchFinishWithContextTare() {
  yield takeEvery(WeighingActionTypes.FINISH_WITH_CONTEXT_TARE, handleFinishWithContextTare);
}

function* watchSaveTareAndFinishContext() {
  yield takeEvery(WeighingActionTypes.SAVE_TARE_AND_FINISH_CONTEXT, handleSaveTareAndFinishContext);
}

function* watchInactivateContext() {
  yield takeEvery(WeighingActionTypes.INACTIVATE_CONTEXT, handleInactivateContext);
}

function* watchInactivateContextById() {
  yield takeEvery(WeighingActionTypes.INACTIVATE_CONTEXT_BY_ID, handleInactivateContextById);
}

function* watchStatus() {
  yield takeLatest(LiveDataTypes.INCOMING_EVENT, handleMessage);
}

function* weighingSaga() {
  yield all([
    fork(watchDiscardPreviousLoad),
    fork(watchDiscardPreviousLoadByContextId),
    fork(watchDoManualWeighingByContextId),
    fork(watchDoWeighing),
    fork(watchDoWeighingByContextId),
    fork(watchStatus),
    fork(watchDestroyContext),
    fork(watchDestroyContextWithId),
    fork(watchFinishContext),
    fork(watchFinishContextById),
    fork(watchFinishWithContextTare),
    fork(watchInactivateContext),
    fork(watchInactivateContextById),
    fork(watchConfirmWeighing),
    fork(watchSaveTareAndFinishContext),
    fork(watchZeroing),
  ]);
}

export default weighingSaga;
