import {
  ContainerType,
  DetailedMasterDataType,
  MasterDataItem,
  ServerPagination,
} from '../common/types';

export const VisibleMasterDataSearchResults = 20;

export enum MasterDataActionTypes {
  CLOUD_SEARCH_REQUEST = '@@master-data/CLOUD_SEARCH_REQUEST',
  CLOUD_SEARCH_SUCCESS = '@@master-data/CLOUD_SEARCH_SUCCESS',
  CREATE_MASTER_DATA = '@@master-data/CREATE_MASTER_DATA',
  FETCH_REQUEST = '@@master-data/FETCH_REQUEST',
  FETCH_SUCCESS = '@@master-data/FETCH_SUCCESS',
  DATA_SELECTED = '@@master-data/DATA_SELECTED',
  LOAD_HISTORY_ITEMS = '@@master-data/LOAD_HISTORY_ITEMS',
  SEARCH_REQUEST = '@@master-data/SEARCH_REQUEST',
  SEARCH_SUCCESS = '@@master-data/SEARCH_SUCCESS',
  SET_MAX_SEARCH_HISTORY_COUNT = '@@master-data/SET_MAX_SEARCH_HISTORY_COUNT',
  SET_MODIFY_ERROR_REFERENCE = '@@master-data/SET_MODIFY_ERROR_REFERENCE',
  SET_MODIFY_MASTER_DATA_SUCCESSFUL = '@@master-data/SET_MODIFY_MASTER_DATA_SUCCESSFUL',
  UPDATE_MASTER_DATA = '@@master-data/UPDATE_MASTER_DATA',
  CREATE_SUBSCRIPTION_TO_MASTER_DATA = '@@master-data/CREATE_SUBSCRIPTION_TO_MASTER_DATA',
  REMOVE_SUBSCRIPTION_FROM_MASTER_DATA = '@@master-data/REMOVE_SUBSCRIPTION_FROM_MASTER_DATA',
  UPDATE_HISTORY_ITEMS = '@@master-data/UPDATE_HISTORY_ITEMS',
}

export interface MasterDataOrderReference {
  componentId: string;
  orderKey: string;
}

export interface MasterDataModifyInfo {
  orderReference?: MasterDataOrderReference;
  requestReference: string;
  isSuccessfullyCompleted: boolean;
}

export enum MasterDataUpdateType {
  LIST = 'LIST',
  SEARCH = 'SEARCH',
}

export interface MasterDataList {
  loading: boolean;
  readonly data: MasterDataItem[];
  readonly pagination: ServerPagination;
}

export interface MasterDataSearch {
  isLoading: boolean;
  searchResults: MasterDataItem[];
  text: string;
}

export interface MasterDataTypeState {
  onePageList: MasterDataList;
  lastSelected: GenericMasterData[];
  // TODO(mikkogy,20220131) optional to avoid migration. Once we are ready to
  // remove allDataList (possibly also onePageList) migration should be done and
  // we can add an empty array if searchResults are missing.
  search?: MasterDataSearch;
}

export interface SchemaDataType {
  [type: string]: MasterDataTypeState;
}

export interface MasterDataState {
  dataTypes: SchemaDataType;
  maxSearchHistoryCount: number;
  stringSearchResults: MasterDataCloudSearchResultMap;
  readonly modifyErrorReference: string;
  readonly modifyInfo: MasterDataModifyInfo;
  subscriptions: {
    [key: string]: { updateType: MasterDataUpdateType; types: DetailedMasterDataType[] };
  };
}

export interface MasterDataCloudSearchParams {
  searchString: string;
  acceptType: string;
  acceptRole: string | undefined;
  ignoredTypes: string[];
}

export interface MasterDataCloudSearchResultItem extends MasterDataItem {
  structure?: { containerType: ContainerType };
}

export interface MasterDataCloudSearchResultMap {
  [key: string]: MasterDataCloudSearchResults;
}

export interface MasterDataCloudSearchResults {
  searchString: string;
  acceptType: string;
  results: MasterDataItem[];
}

export interface GenericMasterData {
  name: string;
  key: string;
  externalId?: string;
}

export interface CreateMasterDataProperties {
  type: string;
  subtype: ContainerType;
  name: string;
  tareMassKg?: number;
}

export interface UpdateMasterDataProperties {
  key: string;
  name: string;
  tareMassKg?: number;
}
