import React, { useEffect } from 'react';

import { Button } from '@mui/material';

import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/EditOutlined';

import { v4 as uuid } from 'uuid';
import { connect, localeConnect } from 'localeConnect';
import { ApplicationState } from 'store';
import { ContainerItem, ContainerType, Pagination, RemoteMasterDataType } from 'store/common/types';

import {
  createMasterData,
  fetchRequest,
  subscribeToMasterDataTypes,
  unsubscribeToMasterDataTypes,
  updateMasterData,
} from 'store/master-data/actions';
import {
  CreateMasterDataProperties,
  MasterDataList,
  MasterDataModifyInfo,
  MasterDataUpdateType,
  UpdateMasterDataProperties,
} from 'store/master-data/types';
import { toMasterDataStateType } from 'store/master-data/utils';

import { UserDetails } from 'store/user/types';
import { getHasOrganizationRoleRight } from 'store/user/utils';

import { defaultPagination } from 'store/utils';

import { operatorPaginationStyles } from 'theme';
import { translate } from 'utils/translate';
import PaginationComponent from '../Pagination';
import { formatMass } from '../utils';
import OperatorGenericContainerDialog, { DialogContainer } from './OperatorGenericContainerDialog';
import OperatorTable, { OperatorTableColumn } from './OperatorTable';

const useStyles = makeStyles({
  containerTable: {
    marginBottom: '30px',
  },
  ...operatorPaginationStyles,
});

interface PropsFromState {
  dataList: MasterDataList;
  masterDataModifyInfo: MasterDataModifyInfo;
  modifyErrorReference: string;
  userDetails: UserDetails | undefined;
}

interface PropsFromDispatch {
  createMasterData: typeof createMasterData;
  fetchRequest: typeof fetchRequest;
  updateMasterData: typeof updateMasterData;
  subscribeToMasterDataTypes: typeof subscribeToMasterDataTypes;
  unsubscribeToMasterDataTypes: typeof unsubscribeToMasterDataTypes;
}

/*eslint-disable */
export type AllProps = PropsFromDispatch & PropsFromState;
/*eslint-enable */

const mapStateToProps = (state: ApplicationState) => ({
  dataList: state.masterData.dataTypes?.[toMasterDataStateType('container', ContainerType.GENERIC)]
    ?.onePageList || {
    loading: false,
    data: [],
    pagination: { pageNumber: 1, pageSize: 0, itemCount: 0 },
  },
  masterDataModifyInfo: state.masterData.modifyInfo,
  modifyErrorReference: state.masterData.modifyErrorReference,
  userDetails: state.user.user?.userData,
});

const mapDispatchToProps = {
  createMasterData,
  fetchRequest,
  updateMasterData,
  subscribeToMasterDataTypes,
  unsubscribeToMasterDataTypes,
};

const OperatorGenericContainerTable = (props: AllProps) => {
  const { fetchRequest } = props;
  const doQuery = React.useCallback(
    (pagination: Pagination) => {
      fetchRequest(
        { type: RemoteMasterDataType.CONTAINER, subtype: ContainerType.GENERIC },
        pagination,
      );
    },
    [fetchRequest],
  );

  useEffect(() => {
    const componentUuid = uuid();
    props.subscribeToMasterDataTypes(componentUuid, MasterDataUpdateType.LIST, [
      { type: RemoteMasterDataType.CONTAINER, subtype: ContainerType.GENERIC },
    ]);
    // returned function will be called on component unmount
    return () => {
      props.unsubscribeToMasterDataTypes(componentUuid);
    };
    // eslint-disable-next-line
  }, []);

  const generateModifyContainerReference = () => {
    return '' + new Date().getTime();
  };
  const [modifyContainerReference, setModifyContainerReference] = React.useState(
    generateModifyContainerReference(),
  );

  const dataList = props.dataList;
  React.useEffect(() => {
    const pagination = { ...defaultPagination(), pageNumber: 1 };
    doQuery(pagination);
  }, [doQuery]);

  React.useEffect(() => {
    if (
      props.masterDataModifyInfo.isSuccessfullyCompleted &&
      modifyContainerReference === props.masterDataModifyInfo.requestReference
    ) {
      setModifyContainerReference(generateModifyContainerReference());
      setIsDialogVisible(false);
      const pagination = {
        pageSize: dataList.pagination.pageSize,
        pageNumber: dataList.pagination.pageNumber,
      };
      doQuery(pagination);
    }
  }, [
    props.masterDataModifyInfo.requestReference,
    props.masterDataModifyInfo.isSuccessfullyCompleted,
    modifyContainerReference,
    dataList.pagination.pageNumber,
    dataList.pagination.pageSize,
    doQuery,
  ]);

  const newContainer = {
    name: '',
  };

  const [isDialogVisible, setIsDialogVisible] = React.useState(false);
  const [dialogInitialContainer, setDialogInitialContainer] = React.useState(newContainer);

  const classes = useStyles();

  const hasModifyError = props.modifyErrorReference === modifyContainerReference;

  const createContainer = () => {
    setDialogInitialContainer(newContainer);
    setIsDialogVisible(true);
  };

  const editContainer = (key: string) => {
    const container = dataList.data.find((item) => item.key === key);
    if (!container) throw new Error('trying to edit container that does not exist');
    setDialogInitialContainer(container);
    setIsDialogVisible(true);
  };

  const hasUpdateRight = getHasOrganizationRoleRight(props.userDetails, 'updateMasterData');
  const columns: OperatorTableColumn[] = [
    {
      key: 'name',
      translationKey: 'operatorGenericContainers.name',
      dataGetter: (container: ContainerItem) => container.name,
      useLongTextStyle: false,
    },
    {
      key: 'tare',
      translationKey: 'operatorGenericContainers.tare',
      dataGetter: (container: ContainerItem) => {
        const tare = container.tare;
        if (!tare || tare.unfitForJobs) return '';
        return formatMass(tare.massKg);
      },
      useLongTextStyle: false,
    },
    {
      key: 'menu',
      dataGetter: (container: ContainerItem) => {
        if (!hasUpdateRight) return null;
        return (
          <Button aria-label="Edit" onClick={() => editContainer(container.key)}>
            <EditIcon />
          </Button>
        );
      },
      useLongTextStyle: false,
    },
  ];

  function onSave(container: DialogContainer) {
    if (container.name === undefined) throw new Error('container must have a name');
    const newModifyContainerReference = generateModifyContainerReference();
    if (container.key === undefined) {
      const newContainer: CreateMasterDataProperties = {
        type: 'container',
        subtype: ContainerType.GENERIC,
        name: container.name,
      };
      if (container.tare !== undefined) {
        newContainer.tareMassKg = container.tare.massKg;
      }
      props.createMasterData(newContainer, newModifyContainerReference, undefined);
    } else {
      const updatedContainer: UpdateMasterDataProperties = {
        key: container.key,
        name: container.name,
      };
      if (container.tare !== undefined) {
        updatedContainer.tareMassKg = container.tare.massKg;
      }
      props.updateMasterData(updatedContainer, newModifyContainerReference);
    }
    setModifyContainerReference(newModifyContainerReference);
  }

  return (
    <div>
      <OperatorGenericContainerDialog
        initialContainer={dialogInitialContainer}
        visible={isDialogVisible}
        saveDisabled={
          modifyContainerReference === props.masterDataModifyInfo.requestReference &&
          !hasModifyError
        }
        hasError={hasModifyError}
        onSave={onSave}
        onClose={() => {
          setIsDialogVisible(false);
        }}
      />
      <Button color="primary" onClick={createContainer}>
        <>
          <AddIcon />
          {translate('operatorGenericContainers.create')}
        </>
      </Button>
      <div className={classes.pagination}>
        <PaginationComponent
          pagination={dataList.pagination}
          showPage={(pageNumber: number) => {
            const pagination = { pageSize: dataList.pagination.pageSize, pageNumber };
            doQuery(pagination);
          }}
        />
      </div>
      <div>
        {dataList.data.length > 0 && (
          <div className={classes.containerTable}>
            <OperatorTable
              columns={columns}
              data={dataList.data}
              keyGetter={(container: ContainerItem) => container.key}
            />
          </div>
        )}
        {dataList.data.length === 0 && (
          <div>{translate('operatorGenericContainers.noResults')}</div>
        )}
      </div>
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(OperatorGenericContainerTable);
