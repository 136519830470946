import React from 'react';
import { connect, localeConnect } from 'localeConnect';
import { Order, PendingMasterDataUpdates } from 'store/orders/types';
import { ApplicationState } from 'store';
import { setPendingMasterDataUpdate } from 'store/orders/actions';
import { GenericMasterData, MasterDataState } from 'store/master-data/types';
import { DetailedMasterDataType } from 'store/common/types';
import {
  subscribeToMasterDataTypes,
  unsubscribeToMasterDataTypes,
} from 'store/master-data/actions';
import { getCurrentlySelectedMasterData } from 'store/orders/utils';
import { parseStateType, toMasterDataStateType } from 'store/master-data/utils';

import MasterDataSearch from './MasterDataSearch';

interface ParameterProps {}

interface PropsFromState {
  componentId: string;
  pendingMasterDataUpdates: PendingMasterDataUpdates;
  masterData: MasterDataState;
  order?: Order;
  selectedType?: DetailedMasterDataType;
}

interface PropsFromDispatch {
  setPendingMasterDataUpdate: typeof setPendingMasterDataUpdate;
  subscribeToMasterDataTypes: typeof subscribeToMasterDataTypes;
  unsubscribeToMasterDataTypes: typeof unsubscribeToMasterDataTypes;
}

const mapStateToProps = (state: ApplicationState) => ({
  componentId: state.orders.editedMasterDataComponentId,
  masterData: state.masterData,
  pendingMasterDataUpdates: state.orders.pendingMasterDataUpdates,
  order: state.orders.currentContextId
    ? state.orders.contexts[state.orders.currentContextId].order
    : undefined,
  selectedType: state.orders.editedMasterDataType,
});

const mapDispatchToProps = {
  setPendingMasterDataUpdate,
  subscribeToMasterDataTypes,
  unsubscribeToMasterDataTypes,
};

/*eslint-disable */
export type AllProps = PropsFromDispatch &
    PropsFromState &
    ParameterProps;
/*eslint-enable */

function OrderMasterDataSearch(props: AllProps) {
  function getLinkedItem(selectedType?: DetailedMasterDataType) {
    if (!props.order) return undefined;
    const componentId = props.componentId;
    const component = props.order.components.find((c) => c.id === componentId);
    if (!props.order.linkedData || !component || !component.dataLinks || !selectedType)
      return undefined;
    return getCurrentlySelectedMasterData(
      props.order.linkedData,
      component.dataLinks,
      selectedType.type,
      selectedType.subtype,
    );
  }

  if (!props.selectedType) return null;

  const stateType = props.selectedType
    ? toMasterDataStateType(props.selectedType.type, props.selectedType.subtype)
    : '';

  function createListProps() {
    if (!props.selectedType) throw new Error('type must be selected');
    const componentUpdates = props.pendingMasterDataUpdates[props.componentId];
    const stateType = toMasterDataStateType(props.selectedType.type, props.selectedType.subtype);
    const pending = componentUpdates ? componentUpdates[stateType] : undefined;
    return {
      pendingUpdate: pending,
    };
  }
  const listProps = createListProps();

  const typeData = props.masterData.dataTypes[stateType];

  function handleItemClick(item: GenericMasterData) {
    const masterDataType = parseStateType(stateType);
    props.setPendingMasterDataUpdate(props.componentId, masterDataType, item);
  }

  function getPending() {
    const pendingUpdate = listProps?.pendingUpdate;
    if (pendingUpdate) return pendingUpdate;
    return getLinkedItem(props.selectedType);
  }

  return (
    <MasterDataSearch
      disabledKeys={[]}
      requireSelection={false}
      select={(item: GenericMasterData | undefined) => {
        if (item) {
          handleItemClick(item);
        } else {
          const masterDataType = parseStateType(stateType);
          props.setPendingMasterDataUpdate(props.componentId, masterDataType, {
            key: '',
            name: '',
          });
        }
      }}
      selectedItem={getPending()}
      type={props.selectedType}
      typeState={typeData}
    />
  );
}

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(OrderMasterDataSearch);
