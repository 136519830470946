import { makeStyles } from '@mui/styles';

import moment from 'moment';

import { FormatUnit, undefinedMassKg } from '../store/weighing/types';
import theme, { mobileContentMaxWidth } from '../theme';

// TODO(mikkogy,20190606) do mass conversions.
export const formatMass = (massKg: number, withUnit = FormatUnit.WITH_UNIT) => {
  if (massKg === undefinedMassKg || massKg === undefined) {
    return '-' + (withUnit === FormatUnit.WITH_UNIT ? ' kg' : '');
  }
  return withUnit === FormatUnit.WITH_UNIT ? `${massKg} kg` : `${massKg}`;
};

export const formatDateAndTime = (timestamp: number) => {
  const momentInstance = moment(timestamp);
  return `${momentInstance.format('l')} ${momentInstance.format('LT')}`;
};

export const navBarStyle: any = {
  title: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: 250,
  },
  appBar: {},
  backButtonContainer: {
    width: '40px',
    height: '40px',
  },
  extraActions: {
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
  },
  siteInfo: {
    width: '100%',
  },
  siteInfoInnerContainer: {
    position: 'absolute',
    width: 'calc(100% - 48px)',
  },
  siteInfoOuterContainer: {
    position: 'relative',
    height: '62px',
    width: '100%',
    maxWidth: mobileContentMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '24px',
    padding: '0 24px 0 24px',
    zIndex: theme.zIndex.appBar + 1,
  },
};

export const useNavBarStyles = makeStyles({ ...navBarStyle });

export const scrollToTop = () => {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 50);
};
