import React from 'react';

import { Button, Container, Grid } from '@mui/material';

import { makeStyles } from '@mui/styles';

import { connect, localeConnect } from 'localeConnect';

import { ApplicationState } from 'store';

import { clear as clearDeepLink } from 'store/deep-link/actions';

import { destroyContext } from 'store/weighing/actions';

import { mobileContentMaxWidth } from 'theme';
import { translate } from 'utils/translate';

interface ParameterProps {
  isContextIgnored: boolean;
}

interface PropsFromState {
  currentOrderKey: string | undefined;
  orderKey: string | undefined;
  returnLink: string | undefined;
}

interface PropsFromDispatch {
  clearDeepLink: typeof clearDeepLink;
  destroyContext: typeof destroyContext;
}

const mapStateToProps = ({ deepLink, orders }: ApplicationState) => ({
  currentOrderKey: orders.currentContextOrderKey,
  orderKey: deepLink.orderKey,
  returnLink: deepLink.returnLink,
});

const mapDispatchToProps = {
  clearDeepLink,
  destroyContext,
};

const useStyles = makeStyles({
  root: {
    // eslint-disable-next-line
    width: '100%',
    // eslint-disable-next-line
    padding: '10px',
    // eslint-disable-next-line
    background: '#4d4d4f',
    // eslint-disable-next-line
    color: 'white',
    '& .MuiButton-root': {
      color: 'white',
      border: '1px solid white',
    },
  },
  container: {
    maxWidth: mobileContentMaxWidth,
  },
  info: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
  },
});

type AllProps = ParameterProps & PropsFromDispatch & PropsFromState;

const DeepLinkReturnLink = (props: AllProps) => {
  const classes = useStyles();
  const hasCorrectContext = props.currentOrderKey && props.orderKey === props.currentOrderKey;
  if (!props.returnLink || (!props.isContextIgnored && !hasCorrectContext)) {
    return null;
  }
  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={9}>
            <span className={classes.info}>{translate('deepLink.returnLink')}</span>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="outlined"
              onClick={() => {
                if (props.returnLink) {
                  props.clearDeepLink();
                  props.destroyContext();
                  window.location.replace(props.returnLink);
                }
              }}
            >
              {translate('deepLink.return')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
const exported = localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(DeepLinkReturnLink);

export default exported;
