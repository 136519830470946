import React, { useCallback, useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import { AppBar, IconButton, Typography, Toolbar } from '@mui/material';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';

import { connect, localeConnect } from 'localeConnect';
import theme from 'theme';
import { RoutePaths } from 'routes';
import { ApplicationState } from 'store';
import { cancelEditingMasterData, doneEditingMasterData } from 'store/orders/actions';
import { DetailedMasterDataType } from 'store/common/types';

import { translate } from 'utils/translate';
import DeepLinkReturnLink from './DeepLinkReturnLink';

const useStyles = makeStyles({
  title: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  appBar: {},
});

interface ParameterProps {
  history: any;
  showDeepLinkReturnLink: boolean;
}

interface PropsFromState {
  editedMasterDataType?: DetailedMasterDataType;
  editedMasterDataTitle: string;
}

interface PropsFromDispatch {
  cancelEditingMasterData: typeof cancelEditingMasterData;
  doneEditingMasterData: typeof doneEditingMasterData;
}

type AllProps = ParameterProps & PropsFromDispatch & PropsFromState;

const mapStateToProps = ({ vehicles, orders }: ApplicationState) => ({
  editedMasterDataType: orders.editedMasterDataType,
  editedMasterDataTitle: orders.editedMasterDataTitle,
});

const mapDispatchToProps = {
  cancelEditingMasterData,
  doneEditingMasterData,
};

const EditOrderNavBar = (props: AllProps) => {
  const classes = useStyles();

  const { editedMasterDataType, doneEditingMasterData, cancelEditingMasterData, history } = props;
  const handleBackFromEditing = useCallback(
    (wasFromBackButton: boolean) => {
      if (editedMasterDataType) {
        doneEditingMasterData();
        if (wasFromBackButton) {
          history.push(RoutePaths.EDIT_ORDER);
        }
        return;
      }
      cancelEditingMasterData();
      if (!wasFromBackButton) {
        history.goBack();
      }
    },
    [editedMasterDataType, doneEditingMasterData, cancelEditingMasterData, history],
  );

  const backPressed = useCallback(
    (e: any) => {
      e.stopPropagation();
      handleBackFromEditing(true);
    },
    [handleBackFromEditing],
  );

  useEffect(() => {
    window.addEventListener('popstate', backPressed);
    return () => {
      // NOTE(eetuk,20221122) handler can't be removed immediately because
      // cleanup is called before the handler.
      setTimeout(() => {
        window.removeEventListener('popstate', backPressed);
      }, 50);
    };
  }, [backPressed]);

  const getMasterDataTitle = () => {
    if (!props.editedMasterDataType) {
      return translate('orders.editTitle');
    }
    return props.editedMasterDataTitle.endsWith('_TITLE')
      ? translate(`masterData.${props.editedMasterDataTitle}`)
      : props.editedMasterDataTitle;
  };

  return (
    <div>
      <AppBar position="relative" className={classes.appBar}>
        {props.showDeepLinkReturnLink && <DeepLinkReturnLink isContextIgnored={false} />}
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label={translate('navigation.return')}
            edge="start"
            onClick={() => {
              handleBackFromEditing(false);
            }}
          >
            <BackIcon />
          </IconButton>

          <Typography variant="h6" noWrap className={classes.title}>
            {getMasterDataTitle()}
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(EditOrderNavBar);
