import React from 'react';

import { Split } from '../models/splitload.model';

import { NonNegativeIntInput } from './NonNegativeIntInput';

export interface SplitAmountProps {
  hasError: boolean;
  isDisabled: boolean;
  onChanged: (value: number) => void;
  label: string;
  split: Split;
}

const SplitAmountInput = function (props: SplitAmountProps) {
  return (
    <NonNegativeIntInput
      allowUndefined={false}
      hasError={props.hasError || props.split.amount === undefined || props.split.amount < 0}
      hideZero={false}
      isDisabled={props.isDisabled}
      isOutOfRangePrevented={true}
      initialValue={props.split?.amount}
      label={props.label}
      max={1000000}
      min={0}
      onChanged={(value: number | undefined) => {
        if (value === undefined) return;
        props.onChanged(value);
      }}
      showReset={false}
      updateInitialValue={false}
      useAutoFocus={false}
      useFullWidth={true}
    />
  );
};

export default SplitAmountInput;
