import React from 'react';
import { makeStyles } from '@mui/styles';
import { connect, localeConnect } from '../../localeConnect';
import {
  ContainerType,
  DetailedMasterDataType,
  MasterDataItem,
  RemoteMasterDataType,
} from '../../store/common/types';
import { Order, PendingMasterDataUpdates } from '../../store/orders/types';
import {
  getCurrentlySelectedMasterData,
  secondaryContainerTareKgs,
} from '../../store/orders/utils';

import { GenericMasterData, MasterDataState } from '../../store/master-data/types';
import {
  getTranslatedAndSortedSchemaEntries,
  shouldTypableBeUpdated,
  toMasterDataStateType,
} from '../../store/master-data/utils';
import { BridgeEnabledFeatures } from '../../store/scale-info/types';
import { SchemaEntry, filterTypes, findTypesAndTitles } from '../../store/scale-info/utils';
import { FormatUnit, undefinedMassKg } from '../../store/weighing/types';
import { formatMass } from '../utils';
import theme, { fadedGreyColor } from '../../theme';
import SecondaryContainerTareFeature from '../SecondaryContainerTareFeature';
import MasterDataLocalityVisibleFeature from '../MasterDataLocalityVisibleFeature';
import MasterDataExternalIdVisibleFeature from '../MasterDataExternalIdVisibleFeature';
import { ApplicationState } from '../../store';
import {
  editTypableMasterData,
  editTypableMasterDataImmediately,
  requestMasterDataUpdate,
} from '../../store/orders/actions';
import {
  searchMasterData,
  subscribeToMasterDataTypes,
  unsubscribeToMasterDataTypes,
} from '../../store/master-data/actions';
import { translate } from '../../utils/translate';
import OperatorMasterDataSelect from './OperatorMasterDataSelect';

interface SelectionProps {
  componentId: string;
  dataLinks: string[];
  enabledFeatures: BridgeEnabledFeatures;
  isAllowedToUpdateOrder: boolean;
  isRequired: boolean;
  handleMasterDataChange: (type: DetailedMasterDataType, item: GenericMasterData | null) => void;
  pendingMasterDataUpdates: PendingMasterDataUpdates;
  handleTypableMasterDataUpdate: (typablevalue: string, force: boolean) => void;
  linkedData: MasterDataItem[];
  masterData: MasterDataState;
  searchMasterData: typeof searchMasterData;
  order: Order | undefined;
  schemaEntry: SchemaEntry;
}

const useStyles = makeStyles({
  deduction: {
    color: theme.palette.error.main,
  },
  formSection: {
    marginBottom: '15px',
  },
  label: {
    marginRight: '20px',
  },
  detailsLabel: {
    fontSize: '0.75rem',
    color: fadedGreyColor,
  },
  detailsValue: {
    fontSize: '0.75rem',
    marginLeft: '10px',
    color: theme.palette.common.black,
    wordBreak: 'break-all',
  },
});

const notSelectedMarker = '__NOT_SELECTED_MARKER__';

function formatKgs(kgs: number | undefined) {
  if (kgs === undefined) return formatMass(undefinedMassKg, FormatUnit.WITHOUT_UNIT);
  return formatMass(kgs);
}

const SelectionForData = (props: SelectionProps) => {
  const classes = useStyles();

  if (!props.order) {
    return null;
  }

  const stateType = toMasterDataStateType(props.schemaEntry.typeName, props.schemaEntry.subtype);
  if (props.order.status === 'OPEN' && !props.masterData) {
    return null;
  }

  const dataTypes =
    props.masterData && props.masterData.dataTypes ? props.masterData.dataTypes : undefined;
  const typeState = dataTypes?.[stateType];
  const typeSearch = typeState?.search;
  function getData(dataTypes: any, stateType: string) {
    if (!typeSearch || !typeSearch.searchResults) {
      const retVal: GenericMasterData[] = [];
      return retVal;
    }
    return typeSearch.searchResults as GenericMasterData[];
  }
  const selectables = [
    {
      key: `${notSelectedMarker}${props.schemaEntry.typeName}`,
      name: translate('masterData.unknownItemName'),
      isNone: true,
    },
    ...getData(dataTypes, stateType),
  ];

  function labelText(key: string) {
    return key.endsWith('_TITLE') ? translate(`masterData.${key}`) : key;
  }

  const currentValue = getCurrentlySelectedMasterData(
    props.linkedData,
    props.dataLinks,
    props.schemaEntry.typeName,
    props.schemaEntry.subtype,
  );
  const isContainer =
    !!currentValue &&
    props.schemaEntry.typeName === RemoteMasterDataType.CONTAINER &&
    props.schemaEntry.subtype === ContainerType.GENERIC;
  const tareKgs = secondaryContainerTareKgs(props.order, props.componentId);
  const hasTare = isContainer && tareKgs !== undefinedMassKg;
  const currentItemExternalId = currentValue?.externalId;
  const currentItemLocality = currentValue?.card?.adr?.locality;

  return (
    <div>
      <OperatorMasterDataSelect
        componentId={props.componentId}
        enabledFeatures={props.enabledFeatures}
        lastSelected={typeState?.lastSelected || []}
        masterData={selectables}
        onSelect={(item: GenericMasterData) => {
          let result: GenericMasterData | null = item;
          const type = {
            type: props.schemaEntry.typeName,
            subtype: props.schemaEntry.subtype,
          };
          if (item.key.startsWith(notSelectedMarker)) {
            result = null;
          }
          props.handleMasterDataChange(type, result);
        }}
        disabled={!props.isAllowedToUpdateOrder}
        disabledKeys={[]}
        label={labelText(props.schemaEntry.title)}
        value={currentValue}
        isRequired={props.isRequired}
        isSearching={typeSearch?.isLoading || false}
        isTypable={props.schemaEntry.isTypable}
        searchMasterData={(text) => {
          props.searchMasterData(
            {
              type: props.schemaEntry.typeName,
              subtype: props.schemaEntry.subtype,
            },
            text,
          );
        }}
        typableFieldShouldBeUpdated={shouldTypableBeUpdated(
          props.pendingMasterDataUpdates,
          props.componentId,
          props.schemaEntry.typeName,
        )}
        updateTypableMasterData={(typableValue: string, force: boolean) =>
          props.handleTypableMasterDataUpdate(typableValue, force)
        }
        shouldBeFocused={false}
      />
      {hasTare && (
        <SecondaryContainerTareFeature componentId={props.componentId} order={props.order}>
          <span className={classes.label}>
            {translate('operatorOrder.orderDetails.containerDeductionTitle')}
          </span>
          <span className={classes.deduction}>
            {formatKgs(-secondaryContainerTareKgs(props.order, props.componentId))}
          </span>
        </SecondaryContainerTareFeature>
      )}

      {!!currentItemExternalId && (
        <MasterDataExternalIdVisibleFeature>
          <div>
            <span className={classes.detailsLabel}>
              {translate('masterData.EXTERNAL_ID_TITLE')}
            </span>
            &nbsp;
            <span className={classes.detailsValue}>{currentItemExternalId}</span>
          </div>
        </MasterDataExternalIdVisibleFeature>
      )}

      {!!currentItemLocality && (
        <MasterDataLocalityVisibleFeature>
          <div>
            <span className={classes.detailsLabel}>{translate('masterData.LOCALITY_TITLE')}</span>
            &nbsp;
            <span className={classes.detailsValue}>{currentItemLocality}</span>
          </div>
        </MasterDataLocalityVisibleFeature>
      )}
    </div>
  );
};

interface ParameterProps {
  componentId: string;
  excludedTypes: string[] | undefined;
  includedTypes: string[] | undefined;
  order: Order;
  dataLinks: string[];
  isAllowedToUpdateOrder: boolean;
  requiredTypes: string[];
}

interface PropsFromState {
  enabledFeatures: BridgeEnabledFeatures;
  selectedType?: DetailedMasterDataType;
  pendingMasterDataUpdates: PendingMasterDataUpdates;
  masterData: MasterDataState;
  schema: Record<string, unknown> | undefined;
}

interface PropsFromDispatch {
  requestMasterDataUpdate: typeof requestMasterDataUpdate;
  searchMasterData: typeof searchMasterData;
  subscribeToMasterDataTypes: typeof subscribeToMasterDataTypes;
  unsubscribeToMasterDataTypes: typeof unsubscribeToMasterDataTypes;
  editTypableMasterData: typeof editTypableMasterData;
  editTypableMasterDataImmediately: typeof editTypableMasterDataImmediately;
}

const mapStateToProps = (state: ApplicationState) => ({
  enabledFeatures: state.currentScaleInfo.enabledFeatures,
  selectedType: state.orders.editedMasterDataType,
  pendingMasterDataUpdates: state.orders.pendingMasterDataUpdates,
  masterData: state.masterData,
  schema: state.currentScaleInfo.domainInfo.masterDataCombo.schema,
});

const mapDispatchToProps = {
  requestMasterDataUpdate,
  searchMasterData,
  subscribeToMasterDataTypes,
  unsubscribeToMasterDataTypes,
  editTypableMasterData,
  editTypableMasterDataImmediately,
};

export type AllProps = PropsFromDispatch & PropsFromState & ParameterProps;

const OperatorOrderComponentMasterData = (props: AllProps) => {
  const classes = useStyles();

  if (!props.schema) return null;
  const types = filterTypes(
    getTranslatedAndSortedSchemaEntries(findTypesAndTitles(props.schema)),
    props.includedTypes,
    props.excludedTypes,
  );

  const selections = types.map((type) => {
    const isRequired = props.requiredTypes.includes(type.typeName);
    const selectionProps = {
      componentId: props.componentId,
      enabledFeatures: props.enabledFeatures,
      schemaEntry: type,
      isRequired,
      dataLinks: props.dataLinks,
      isAllowedToUpdateOrder: props.isAllowedToUpdateOrder,
      masterData: props.masterData,
      linkedData: props.order?.linkedData ?? [],
      order: props.order,
    } as SelectionProps;
    return selectionProps;
  });

  return (
    <div>
      {selections.map((selectionProps) => {
        return (
          <div
            id={selectionProps.schemaEntry.title}
            key={selectionProps.schemaEntry.typeName}
            className={classes.formSection}
          >
            <SelectionForData
              componentId={selectionProps.componentId}
              dataLinks={selectionProps.dataLinks}
              enabledFeatures={selectionProps.enabledFeatures}
              isAllowedToUpdateOrder={selectionProps.isAllowedToUpdateOrder}
              isRequired={selectionProps.isRequired}
              handleMasterDataChange={(
                type: DetailedMasterDataType,
                item: GenericMasterData | null,
              ) => {
                if (!selectionProps.order) return;
                props.requestMasterDataUpdate(
                  selectionProps.order?.key,
                  selectionProps.componentId,
                  type,
                  item,
                );
              }}
              pendingMasterDataUpdates={props.pendingMasterDataUpdates}
              handleTypableMasterDataUpdate={(typableValue: string, force: boolean) => {
                if (force) {
                  props.editTypableMasterDataImmediately(
                    props.order.key,
                    selectionProps.componentId,
                    selectionProps.schemaEntry.typeName,
                    typableValue,
                  );
                } else {
                  props.editTypableMasterData(
                    props.order.key,
                    selectionProps.componentId,
                    selectionProps.schemaEntry.typeName,
                    typableValue,
                  );
                }
              }}
              linkedData={selectionProps.linkedData}
              masterData={selectionProps.masterData}
              searchMasterData={props.searchMasterData}
              schemaEntry={selectionProps.schemaEntry}
              order={selectionProps.order}
            />
          </div>
        );
      })}
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(OperatorOrderComponentMasterData);
