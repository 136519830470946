import { Reducer } from 'redux';
import { toMasterDataStateType } from '../master-data/utils';
import { WeighingActionTypes } from '../weighing/types';
import { SitesActionTypes } from '../sites/types';
import { UserActionTypes } from '../user/types';
import {
  Contexts,
  WeighingProcess,
  InspectorOrderTab,
  OperatorOrderTab,
  ListOrderQueryMode,
  OrdersActionTypes,
  OrderState,
} from './types';

export const initialState: OrderState = {
  multipartEditOrderComponentId: '',
  operatorSelectionResults: {
    orders: [],
    status: ListOrderQueryMode.OPEN_BY_ACCEPTANCE,
    pagination: {
      itemCount: 0,
      pageNumber: 1,
      pageSize: 10,
    },
  },
  operatorQueryParams: {
    status: ListOrderQueryMode.ALL,
    pagination: {
      pageNumber: 1,
      pageSize: 10,
    },
  },
  operatorQueryResults: {
    orders: [],
    status: ListOrderQueryMode.OPEN_BY_ACCEPTANCE,
    pagination: {
      itemCount: 0,
      pageNumber: 1,
      pageSize: 10,
    },
  },
  errors: undefined,
  previousOrder: undefined, // NOTE(anttipalola,20190615) keep info after selection cleared
  loading: false,
  contexts: {},
  currentContextId: undefined,
  currentContextOrderKey: undefined,
  defaultWeighingProcess: WeighingProcess.NORMAL,
  editedDeductions: undefined,
  editedMasterDataComponentId: '',
  editedMasterDataType: undefined,
  editedMasterDataTitle: '',
  pendingMasterDataUpdates: {},
  inspectorOrderTab: InspectorOrderTab.OPEN,
  inProgressOrders: { isListTruncated: false, orders: [] },
  isWaitingForFirstInProgressOrders: false,
  loadingOperatorOrderKey: '',
  selectedOperatorOrder: undefined,
  operatorJobFilter: undefined,
  operatorOrderTab: OperatorOrderTab.Order,
  search: {
    isLoading: false,
    searchResults: [],
    text: '',
  },
};

const getContextInfo = (contextId: string | undefined, contexts: Contexts, state: OrderState) => {
  const context = contextId ? contexts[contextId] : undefined;
  const order = context ? context.order : undefined;
  return {
    contexts: contexts,
    currentContextId: contextId,
    previousOrder: order ? order : state.previousOrder,
  };
};

const reducer: Reducer<OrderState> = (state = initialState, action) => {
  switch (action.type) {
    case OrdersActionTypes.CREATE_CONTEXT: {
      return { ...state, loading: true, currentContextOrderKey: undefined };
    }
    case OrdersActionTypes.CREATE_CONTEXT_FOR_CONTAINER: {
      return { ...state, loading: true };
    }
    case OrdersActionTypes.LIST_ORDERS_SUCCESS: {
      return {
        ...state,
        operatorQueryResults: action.payload,
      };
    }
    case OrdersActionTypes.LIST_ORDER: {
      return {
        ...state,
        operatorQueryParams: action.payload,
      };
    }
    case OrdersActionTypes.SELECTED: {
      console.log('---SELECTED---');
      return {
        ...state,
        loading: false,
        editedMasterDataType: undefined,
      };
    }
    case OrdersActionTypes.EDIT_MASTER_DATA: {
      const pendingUpdates = action.payload ? { ...state.pendingMasterDataUpdates } : {};
      return {
        ...state,
        editedMasterDataComponentId: action.payload.componentId,
        editedMasterDataType: action.payload.masterDataType,
        editedMasterDataTitle: action.payload.masterDataTitle ? action.payload.masterDataTitle : '',
        pendingMasterDataUpdates: pendingUpdates,
      };
    }
    case OrdersActionTypes.DONE_EDITING_MASTER_DATA: {
      return {
        ...state,
        editedMasterDataType: undefined,
        editedMasterDataTitle: '',
      };
    }
    case OrdersActionTypes.CANCEL_EDITING_MASTER_DATA: {
      return {
        ...state,
        editedMasterDataType: undefined,
        editedMasterDataTitle: '',
        pendingMasterDataUpdates: {},
      };
    }
    case OrdersActionTypes.CLEAR_EDITED_MASTER_DATA_COMPONENT_ID: {
      return {
        ...state,
        editedMasterDataComponentId: '',
      };
    }
    case OrdersActionTypes.SET_EDITED_DEDUCTIONS: {
      return {
        ...state,
        editedDeductions: action.payload,
      };
    }
    case OrdersActionTypes.SET_PENDING_MASTER_DATA_UPDATE: {
      const updates = { ...state.pendingMasterDataUpdates };
      if (!updates[action.payload.componentId]) {
        updates[action.payload.componentId] = {};
      }
      const stateType = toMasterDataStateType(
        action.payload.masterDataType.type,
        action.payload.masterDataType.subtype,
      );
      updates[action.payload.componentId][stateType] = action.payload.pendingUpdate;
      return { ...state, pendingMasterDataUpdates: updates };
    }
    case OrdersActionTypes.CONTEXTS_CHANGED: {
      return {
        ...state,
        loading: false,
        ...getContextInfo(state.currentContextId, action.payload, state),
      };
    }
    case OrdersActionTypes.CURRENT_CONTEXT_CHANGED: {
      return {
        ...state,
        loading: false,
        ...getContextInfo(action.payload, state.contexts, state),
      };
    }
    case OrdersActionTypes.DESTROY_CONTEXT_HANDLED: {
      return {
        ...state,
        ...getContextInfo(undefined, action.payload, state),
        currentContextOrderKey: undefined,
      };
    }
    case WeighingActionTypes.CONFIRM_WEIGHING: {
      return {
        ...state,
      };
    }
    case WeighingActionTypes.REQUEST_ZERO: {
      return {
        ...state,
      };
    }
    case WeighingActionTypes.FINISH_CONTEXT: {
      const orderKey = state.currentContextId
        ? state.contexts[state.currentContextId]
          ? state.contexts[state.currentContextId].order.key
          : undefined
        : undefined;
      if (orderKey != null) {
        return {
          ...state,
          currentContextOrderKey: undefined,
        };
      } else {
        console.error('Unable to finish order because order was not found on current context');
        return state;
      }
    }
    case OrdersActionTypes.SET_CURRENT_CONTEXT_ORDER_KEY: {
      return {
        ...state,
        currentContextOrderKey: action.payload,
      };
    }
    case OrdersActionTypes.SET_MULTIPART_EDIT_COMPONENT_ID: {
      return {
        ...state,
        multipartEditOrderComponentId: action.payload,
      };
    }
    case OrdersActionTypes.SET_DEFAULT_WEIGHING_PROCESS: {
      return {
        ...state,
        defaultWeighingProcess: action.payload,
      };
    }
    case OrdersActionTypes.IN_PROGRESS_ORDER_SUCCESS: {
      return {
        ...state,
        inProgressOrders: action.payload,
        isWaitingForFirstInProgressOrders: false,
      };
    }
    case OrdersActionTypes.START_IN_PROGRESS_ORDER_POLL: {
      return {
        ...state,
        isWaitingForFirstInProgressOrders: true,
      };
    }
    case OrdersActionTypes.SET_LOADING_OPERATOR_ORDER_KEY: {
      return {
        ...state,
        loadingOperatorOrderKey: action.payload,
      };
    }
    case OrdersActionTypes.SELECTED_OPERATOR_ORDER: {
      return {
        ...state,
        selectedOperatorOrder: action.payload,
      };
    }
    case OrdersActionTypes.SET_INSPECTOR_ORDER_TAB: {
      return {
        ...state,
        inspectorOrderTab: action.payload,
      };
    }
    case OrdersActionTypes.SET_OPERATOR_JOB_FILTER: {
      return {
        ...state,
        operatorJobFilter: action.payload,
      };
    }
    case OrdersActionTypes.SET_OPERATOR_ORDER_TAB: {
      return {
        ...state,
        operatorOrderTab: action.payload,
      };
    }
    case OrdersActionTypes.SEARCH_REQUEST: {
      const newSearchState = {
        isLoading: true,
        searchResults: [],
        text: action.payload.text,
      };
      return {
        ...state,
        search: newSearchState,
      };
    }
    case OrdersActionTypes.SEARCH_ORDER_SUCCESS: {
      const searchResults = action.payload.results;
      const newSearchState = {
        isLoading: false,
        searchResults: searchResults,
        text: action.payload.text,
      };
      return {
        ...state,
        search: newSearchState,
      };
    }
    case SitesActionTypes.CHANGE_SITE:
    case UserActionTypes.LOGOUT: {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
};

export { reducer as ordersReducer };
