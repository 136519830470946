import { Reducer } from 'redux';
import { ContainerType, DetailedMasterDataType, RemoteMasterDataType } from '../common/types';
import { SitesActionTypes } from '../sites/types';
import { UserActionTypes } from '../user/types';
import {
  GenericMasterData,
  MasterDataActionTypes,
  MasterDataState,
  MasterDataTypeState,
} from './types';
import { initialPagination, toMasterDataStateType, updateLastUsed } from './utils';

const initialTypeState = {
  onePageList: {
    loading: false,
    data: [],
    pagination: initialPagination(),
  },
  lastSelected: [],
  search: {
    isLoading: false,
    searchResults: [],
  },
};

function createInitialTypeState(): MasterDataTypeState {
  return JSON.parse(JSON.stringify(initialTypeState)) as MasterDataTypeState;
}

// NOTE(mjohans,20200623) we must have defaults for basic data types
// as older code base does not check for undefined. As store is now
// generic, new code must check if indexed property exists
export const initialState: MasterDataState = {
  dataTypes: {
    [toMasterDataStateType(RemoteMasterDataType.CONTAINER, ContainerType.GENERIC)]:
      createInitialTypeState(),
    [toMasterDataStateType(RemoteMasterDataType.CONTAINER, ContainerType.TRUCK)]:
      createInitialTypeState(),
    [toMasterDataStateType(RemoteMasterDataType.CUSTOMER)]: createInitialTypeState(),
    [toMasterDataStateType(RemoteMasterDataType.MATERIAL)]: createInitialTypeState(),
  },
  maxSearchHistoryCount: 3,
  stringSearchResults: {},
  modifyErrorReference: '',
  modifyInfo: {
    orderReference: undefined,
    requestReference: '',
    isSuccessfullyCompleted: false,
  },
  subscriptions: {},
};

const reducer: Reducer<MasterDataState> = (state = initialState, action) => {
  switch (action.type) {
    case MasterDataActionTypes.SET_MODIFY_ERROR_REFERENCE: {
      return {
        ...state,
        modifyErrorReference: action.payload,
      };
    }
    case MasterDataActionTypes.FETCH_REQUEST: {
      const newState: MasterDataState = { ...state };
      const masterDataType = action.payload.masterDataType;
      const stateType = toMasterDataStateType(masterDataType.type, masterDataType.subtype);
      if (!newState.dataTypes[stateType]) {
        newState.dataTypes[stateType] = createInitialTypeState();
      } else {
        newState.dataTypes[stateType] = { ...newState.dataTypes[stateType] };
      }
      newState.dataTypes[stateType].onePageList = {
        ...createInitialTypeState(),
        ...newState.dataTypes[stateType].onePageList,
        loading: true,
      };
      return newState;
    }
    case MasterDataActionTypes.FETCH_SUCCESS: {
      const newState = { ...state };
      const masterDataType = action.payload.masterDataType;
      const stateType = toMasterDataStateType(masterDataType.type, masterDataType.subtype);
      if (!newState.dataTypes[stateType]) {
        newState.dataTypes[stateType] = createInitialTypeState();
      }
      newState.dataTypes[stateType] = {
        ...newState.dataTypes[stateType],
        onePageList: {
          loading: false,
          data: action.payload.data,
          pagination: action.payload.pagination,
        },
      };
      return newState;
    }
    case MasterDataActionTypes.CLOUD_SEARCH_SUCCESS: {
      const newState = { ...state };
      newState.stringSearchResults = {
        ...newState.stringSearchResults,
      };
      newState.stringSearchResults[action.payload.acceptType] = action.payload;
      return newState;
    }
    case MasterDataActionTypes.DATA_SELECTED: {
      const newState = { ...state };
      const masterDataType = action.payload.masterDataType;
      const stateType = toMasterDataStateType(masterDataType.type, masterDataType.subtype);
      if (!newState.dataTypes[stateType]) {
        newState.dataTypes[stateType] = createInitialTypeState();
      }
      const typeData = newState.dataTypes[stateType];
      const oldItems = [...typeData.lastSelected] || [];
      const lastSelected = updateLastUsed(
        oldItems,
        action.payload.item,
        newState.maxSearchHistoryCount,
      );
      newState.dataTypes = { ...newState.dataTypes };
      newState.dataTypes[stateType] = {
        ...newState.dataTypes[stateType],
        lastSelected: lastSelected,
      };
      return newState;
    }
    case SitesActionTypes.CHANGE_SITE:
    case UserActionTypes.LOGOUT: {
      const newState = {
        ...state,
        stringSearchResults: {},
      };
      for (const type in newState.dataTypes) {
        newState.dataTypes[type] = {
          onePageList: {
            data: [],
            loading: false,
            pagination: initialPagination(),
          },
          lastSelected: [...(newState?.dataTypes?.[type]?.lastSelected || [])],
        };
      }
      return { ...newState, subscriptions: {} };
    }
    case MasterDataActionTypes.CREATE_MASTER_DATA: {
      return {
        ...state,
        modifyInfo: {
          orderReference: action.payload.orderReference,
          requestReference: action.payload.requestReference,
          isSuccessfullyCompleted: false,
        },
      };
    }
    case MasterDataActionTypes.SEARCH_REQUEST: {
      const newState = { ...state };
      const stateType = toMasterDataStateType(
        action.payload.type.type,
        action.payload.type.subtype,
      );
      if (!newState.dataTypes[stateType]) {
        newState.dataTypes[stateType] = createInitialTypeState();
      }
      const byType = newState.dataTypes[stateType];
      const search = byType?.search || {
        isLoading: true,
        searchResults: [],
        text: action.payload.text,
      };
      newState.dataTypes[stateType] = {
        ...newState.dataTypes[stateType],
        search: {
          ...search,
          isLoading: true,
          text: action.payload.text,
        },
      };
      return newState;
    }
    case MasterDataActionTypes.SEARCH_SUCCESS: {
      const newState = { ...state };
      const stateType = action.payload.stateType;
      if (!newState.dataTypes[stateType]) {
        newState.dataTypes[stateType] = createInitialTypeState();
      }
      newState.dataTypes[stateType] = {
        ...newState.dataTypes[stateType],
        search: {
          isLoading: false,
          searchResults: action.payload.results,
          text: action.payload.text,
        },
      };
      return newState;
    }
    case MasterDataActionTypes.SET_MAX_SEARCH_HISTORY_COUNT: {
      const newState = { ...state };
      Object.keys(newState.dataTypes).forEach((stateType) => {
        const typeData = newState.dataTypes[stateType];
        const oldItems = [...typeData.lastSelected];
        if (!oldItems) return;
        const lastSelected = updateLastUsed(oldItems, oldItems[0], action.payload);
        newState.dataTypes[stateType] = {
          ...newState.dataTypes[stateType],
          lastSelected: lastSelected,
        };
      });
      newState.dataTypes = { ...newState.dataTypes };
      return {
        ...newState,
        maxSearchHistoryCount: action.payload,
      };
    }
    case MasterDataActionTypes.UPDATE_HISTORY_ITEMS: {
      const newState: MasterDataState = { ...state };
      const masterData: GenericMasterData[] = action.payload.masterData;
      const type = action.payload.type;
      const validKeys = masterData.map((item: GenericMasterData) => item.key);
      const stateType = toMasterDataStateType(type.type, type.subtype);
      if (!newState.dataTypes[stateType]) {
        newState.dataTypes[stateType] = createInitialTypeState();
      }
      const byType = newState.dataTypes[stateType] as MasterDataTypeState;
      const newLastSelectedKeys = byType.lastSelected
        .filter((item) => {
          return validKeys.includes(item.key);
        })
        .map((item) => item.key);
      const newLastSelected = newLastSelectedKeys
        .map((key) => {
          return masterData.find((item) => item.key === key);
        })
        .filter((item) => !!item) as GenericMasterData[];
      newState.dataTypes[stateType] = {
        ...newState.dataTypes[stateType],
        lastSelected: newLastSelected,
      };
      return newState;
    }
    case MasterDataActionTypes.UPDATE_MASTER_DATA: {
      return {
        ...state,
        modifyInfo: {
          orderReference: undefined,
          requestReference: action.payload.requestReference,
          isSuccessfullyCompleted: false,
        },
      };
    }
    case MasterDataActionTypes.SET_MODIFY_MASTER_DATA_SUCCESSFUL: {
      return {
        ...state,
        modifyInfo: {
          ...state.modifyInfo,
          isSuccessfullyCompleted: true,
        },
      };
    }
    case MasterDataActionTypes.CREATE_SUBSCRIPTION_TO_MASTER_DATA: {
      console.log('adding subscription to ', action.payload);
      const dataTypes = action.payload.dataTypes as DetailedMasterDataType[];
      const subscriber = action.payload.subscriber as string;
      const subscribers = { ...state.subscriptions };
      subscribers[subscriber] = { updateType: action.payload.updateType, types: dataTypes };

      return { ...state, subscriptions: subscribers };
    }
    case MasterDataActionTypes.REMOVE_SUBSCRIPTION_FROM_MASTER_DATA: {
      console.log('removing subscription from ', action.payload);
      const unsubscriber = action.payload;
      const subscribers = { ...state.subscriptions };
      delete subscribers[unsubscriber];

      return { ...state, subscriptions: subscribers };
    }
    default: {
      return state;
    }
  }
};

export { reducer as masterDataReducer };
