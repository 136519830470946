import { ClosePolicy, Order } from '../store/orders/types';
import { getCurrentlySelectedMasterData } from '../store/orders/utils';
import { RemoteMasterDataType } from '../store/common/types';
import { translate } from '../utils/translate';

function getData(
  order: Order,
  masterDataType: RemoteMasterDataType,
  componentId: string | undefined,
  subtype: string | undefined = undefined,
) {
  if (order?.linkedData === undefined || componentId === undefined) {
    return undefined;
  }
  const component = order.components.find((component) => component.id === componentId);
  if (!component || !component.dataLinks) {
    return undefined;
  }
  return getCurrentlySelectedMasterData(
    order.linkedData,
    component.dataLinks,
    masterDataType,
    subtype,
  );
}

function getName(
  order: Order,
  masterDataType: RemoteMasterDataType,
  componentId: string | undefined,
  subtype: string | undefined = undefined,
) {
  const data = getData(order, masterDataType, componentId, subtype);
  const unknownName = translate('masterData.unknownItemName');
  return !!data ? data.name : unknownName;
}

export const getMaterialData = (order: Order, componentId: string | undefined) => {
  return getData(order, RemoteMasterDataType.MATERIAL, componentId);
};

export const getCustomerName = (order: Order, componentId: string | undefined) => {
  return getName(order, RemoteMasterDataType.CUSTOMER, componentId);
};

export const getMaterialName = (order: Order, componentId: string | undefined) => {
  return getName(order, RemoteMasterDataType.MATERIAL, componentId);
};

class OrderModel {
  get key(): string {
    return this._key;
  }

  get name(): string {
    return this._name;
  }

  get closePolicy(): ClosePolicy {
    return this._closePolicy;
  }

  get customer(): string | undefined {
    return this._customer !== '' ? this._customer : undefined;
  }

  get material(): string | undefined {
    return this._material !== '' ? this._material : undefined;
  }

  get orderDescription(): string | undefined {
    return this._orderDescription;
  }

  get searchableProps(): string[] {
    return [this._name, this._customer, this._material];
  }

  get notSelectedName(): string {
    return this._notSelectedName;
  }
  private readonly _key: string;
  private readonly _name: string;
  private readonly _closePolicy: ClosePolicy;
  private readonly _customer: string;
  private readonly _material: string;
  private readonly _orderDescription: string | undefined;
  private readonly _notSelectedName: string;

  constructor(
    key: string,
    name: string,
    customer: string,
    material: string,
    closePolicy: ClosePolicy,
    orderDescription: string | undefined,
    notSelectedName: string,
  ) {
    this._key = key;
    this._name = name;
    this._customer = customer;
    this._material = material;
    this._closePolicy = closePolicy;
    this._orderDescription = orderDescription;
    this._notSelectedName = notSelectedName;
  }
}

export default OrderModel;
