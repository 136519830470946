import { connect, localeConnect } from 'localeConnect';

import { changeSite } from 'store/sites/actions';
import { destroyContext } from 'store/weighing/actions';

import { ChangeSiteButton } from 'components/NavDrawerContent';

interface ParameterProps {
  iconVisible: boolean;
  onClick: (() => void) | undefined;
}

interface PropsFromDispatch {
  changeSite: typeof changeSite;
  destroyContext: typeof destroyContext;
}

type AllProps = PropsFromDispatch & ParameterProps;

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  changeSite,
  destroyContext,
};

const ConnectedChangeSiteButton = (props: AllProps) => {
  function onClick() {
    props.destroyContext();
    props.changeSite();
    if (props.onClick !== undefined) {
      props.onClick();
    }
  }

  return <ChangeSiteButton iconVisible={props.iconVisible} onClick={onClick} />;
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
const exported = localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedChangeSiteButton);

export default exported;
