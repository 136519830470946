import React from 'react';

import { makeStyles } from '@mui/styles';

import { Checkbox, FormControlLabel } from '@mui/material';

import { connect, localeConnect } from '../localeConnect';

import { ApplicationState } from '../store';
import { getCurrentUserSettings } from '../store/selectors';
import { setMaxSearchHistoryCount } from '../store/master-data/actions';
import { saveUserSettings } from '../store/settings/actions';
import { UserSpecificSettings } from '../store/settings/types';

import { translate } from '../utils/translate';
import { NonNegativeIntInput } from './NonNegativeIntInput';

const useStyles = makeStyles({
  checkboxContainer: {
    '& .MuiCheckbox-root': {
      marginRight: '30px',
    },
  },
  masterDataCountContainer: {
    '& input': {
      width: '45px',
    },
    '& .MuiInput-root': {
      marginRight: '22px',
    },
    // eslint-disable-next-line
    marginBottom: '20px',
    // eslint-disable-next-line
    marginLeft: '11px',
    // eslint-disable-next-line
    width: '100%',
  },
});

interface PropsFromState {
  maxSearchHistoryCount: number;
  userKey?: string;
  userSettings: UserSpecificSettings;
}

interface PropsFromDispatch {
  setMaxSearchHistoryCount: typeof setMaxSearchHistoryCount;
  saveUserSettings: typeof saveUserSettings;
}

/*eslint-disable */
type AllProps = PropsFromDispatch & PropsFromState;
/*eslint-enable */

const mapStateToProps = (state: ApplicationState) => ({
  maxSearchHistoryCount: state.masterData.maxSearchHistoryCount,
  userKey: state.user?.user?.userData?.key,
  userSettings: getCurrentUserSettings(state),
});

const mapDispatchToProps = {
  saveUserSettings,
  setMaxSearchHistoryCount,
};

const maxMasterDataSearchHistoryItems = 30;
const minMasterDataSearchHistoryItems = 0;

const SettingsComponent = (props: AllProps) => {
  const classes = useStyles();
  if (!props.userKey) throw new Error('No user in settings. Not logged in?');

  function update(propertyUpdater: (userSettings: UserSpecificSettings) => UserSpecificSettings) {
    if (!props.userKey) return;
    props.saveUserSettings(props.userKey, propertyUpdater(props.userSettings));
  }

  function onSkipSavingTruckTareChanged(isChecked: boolean) {
    if (!props.userKey) return;
    update((userSettings: UserSpecificSettings) => {
      return { ...userSettings, skipSavingTruckTare: isChecked };
    });
  }

  function setMaxHistoryCount(count: number | undefined) {
    if (
      count !== undefined &&
      count >= minMasterDataSearchHistoryItems &&
      count <= maxMasterDataSearchHistoryItems
    ) {
      props.setMaxSearchHistoryCount(count);
    }
  }

  return (
    <div>
      <div className={classes.masterDataCountContainer}>
        <FormControlLabel
          control={
            <NonNegativeIntInput
              allowUndefined={false}
              hideZero={false}
              isDisabled={false}
              isOutOfRangePrevented={false}
              showReset={false}
              label={''}
              initialValue={props.maxSearchHistoryCount}
              max={maxMasterDataSearchHistoryItems}
              min={minMasterDataSearchHistoryItems}
              onChanged={setMaxHistoryCount}
              hasError={false}
              updateInitialValue={true}
              useAutoFocus={false}
              useFullWidth={false}
            />
          }
          label={translate('settings.masterDataHistoryCount', {
            min: minMasterDataSearchHistoryItems,
            max: maxMasterDataSearchHistoryItems,
          })}
        />
      </div>
      <div className={classes.checkboxContainer}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={props.userSettings.skipSavingTruckTare}
              onChange={(e) => onSkipSavingTruckTareChanged(e.target.checked)}
            />
          }
          label={translate('settings.skipSavingTruckTare')}
        />
      </div>
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsComponent);
