import { action } from 'typesafe-actions';

import { CacheImage, ImagesActionTypes } from './types';

export const addImage = (orderKey: string, jobKey: string, data: string) =>
  action(ImagesActionTypes.ADD, { orderKey, jobKey, data });
export const removeImage = (contextId: string, image: CacheImage, jobKey: string) =>
  action(ImagesActionTypes.REMOVE, { contextId, image, jobKey });
export const removeLocalImage = (orderKey: string, attachmentKey: string) =>
  action(ImagesActionTypes.REMOVE_LOCAL_IMAGE, { orderKey, attachmentKey });
export const removeUploadFailedImage = (cacheImage: CacheImage) =>
  action(ImagesActionTypes.REMOVE_UPLOAD_FAILED_IMAGE, cacheImage);

export const saveToStore = (orderKey: string, cacheImage: CacheImage) =>
  action(ImagesActionTypes.SAVE_TO_STORE, { orderKey, cacheImage });
export const removeByOrderKeys = (orderKeys: string[]) =>
  action(ImagesActionTypes.REMOVE_BY_ORDER_KEYS, orderKeys);
export const setImageDescription = (attachmentKey: string, description: string) =>
  action(ImagesActionTypes.SET_IMAGE_DESCRIPTION, { attachmentKey, description });
export const setImageDescriptionImmediately = (attachmentKey: string, description: string) =>
  action(ImagesActionTypes.SET_IMAGE_DESCRIPTION_IMMEDIATELY, { attachmentKey, description });

export const addImageToJob = (jobKey: string) => action(ImagesActionTypes.ADD_IMAGE_TO_JOB, jobKey);
export const setAddImageJobKey = (jobKey: string | undefined) =>
  action(ImagesActionTypes.SET_ADD_IMAGE_JOB_KEY, jobKey);
