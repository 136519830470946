import React from 'react';
import { NavLink } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import { List, ListItem, ListItemIcon } from '@mui/material';

import AssignmentIcon from '@mui/icons-material/Assignment';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import HomeIcon from '@mui/icons-material/Home';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import MenuIcon from '@mui/icons-material/Menu';

import { connect, localeConnect } from 'localeConnect';
import { ApplicationState } from 'store';

import { selectOperatorOrder, setOperatorOrderTab } from 'store/orders/actions';
import { OperatorOrderTab, SelectOrderCleanup, WeighingProcess } from 'store/orders/types';

import { BridgeEnabledFeatures } from 'store/scale-info/types';

import { RoutePaths } from 'routes';

import theme from 'theme';
import { translate } from 'utils/translate';

const useStyles = makeStyles({
  menuItemText: {
    marginLeft: '15px',
    marginRight: '20px',
  },
  toolbar: theme.mixins.toolbar,
  toolbarContent: {
    '& .MuiListItemIcon-root': {
      padding: 0,
      minWidth: '24px !important',
      color: '#fff',
    },
    '& .MuiListItem-gutters': {
      paddingLeft: '23px',
      paddingRight: 0,
    },
    '& a': {
      color: '#fff',
      textDecoration: 'none',
    },
  },
});

interface ParameterProps {
  handleDrawerToggle: () => void;
  isOpen: boolean;
}

interface PropsFromState {
  enabledFeatures: BridgeEnabledFeatures;
}

interface PropsFromDispatch {
  selectOperatorOrder: typeof selectOperatorOrder;
  setOperatorOrderTab: typeof setOperatorOrderTab;
}

const mapStateToProps = (state: ApplicationState) => ({
  enabledFeatures: state.currentScaleInfo.enabledFeatures,
});

const mapDispatchToProps = {
  selectOperatorOrder,
  setOperatorOrderTab,
};

type AllProps = ParameterProps & PropsFromDispatch & PropsFromState;

const OperatorDrawerContent = (props: AllProps) => {
  const classes = useStyles();

  return (
    <div>
      <List className={classes.toolbarContent}>
        <ListItem onClick={props.handleDrawerToggle} button>
          <ListItemIcon>
            <MenuIcon />
          </ListItemIcon>
        </ListItem>
      </List>
      <div className={classes.toolbar} />
      <List className={classes.toolbarContent}>
        <NavLink to={RoutePaths.DASHBOARD}>
          <ListItem button>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            {props.isOpen && (
              <div className={classes.menuItemText}>{translate('operatorMenu.home')}</div>
            )}
          </ListItem>
        </NavLink>
        <NavLink
          to={RoutePaths.OPERATOR_ORDER}
          onClick={() => {
            props.selectOperatorOrder(
              {
                context: undefined,
                order: undefined,
                process: WeighingProcess.UNDEFINED,
                weighingJobs: [],
              },
              SelectOrderCleanup.DESTROY_PREVIOUS,
            );
            props.setOperatorOrderTab(OperatorOrderTab.Order);
          }}
        >
          <ListItem button>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            {props.isOpen && (
              <div className={classes.menuItemText}>{translate('operatorMenu.order')}</div>
            )}
          </ListItem>
        </NavLink>
        <NavLink to={RoutePaths.OPERATOR_JOBS}>
          <ListItem button>
            <ListItemIcon>
              <FitnessCenterIcon />
            </ListItemIcon>
            {props.isOpen && (
              <div className={classes.menuItemText}>{translate('operatorMenu.jobs')}</div>
            )}
          </ListItem>
        </NavLink>
        <NavLink to={RoutePaths.OPERATOR_ORDERS}>
          <ListItem button>
            <ListItemIcon>
              <ImportExportIcon />
            </ListItemIcon>
            {props.isOpen && (
              <div className={classes.menuItemText}>{translate('operatorMenu.orders')}</div>
            )}
          </ListItem>
        </NavLink>
        {props.enabledFeatures.bridgeSecondaryContainerTareDeduction && (
          <NavLink to={RoutePaths.OPERATOR_GENERIC_CONTAINERS}>
            <ListItem button>
              <ListItemIcon>
                <BorderAllIcon />
              </ListItemIcon>
              {props.isOpen && (
                <div className={classes.menuItemText}>
                  {translate('operatorMenu.genericContainers')}
                </div>
              )}
            </ListItem>
          </NavLink>
        )}
      </List>
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(OperatorDrawerContent);
