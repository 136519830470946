import React from 'react';

import { Divider, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import BusinessIcon from '@mui/icons-material/Business';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';

import { translate } from '../utils/translate';
import { useNavBarStyles as useStyles } from './utils';

export const NavToolbar = () => {
  const classes = useStyles();
  return <div className={classes.toolbar} />;
};

interface NavDividerProps {
  variant: 'middle' | 'fullWidth';
}

export const NavDivider = (props: NavDividerProps) => {
  return <Divider component={'hr'} variant={props.variant} />;
};

interface ParameterProps {
  iconVisible: boolean;
  onClick: () => void;
}

export const ChangeSiteButton = (props: ParameterProps) => {
  return (
    <ListItem component={'li'} button key="changeSite" onClick={props.onClick}>
      {props.iconVisible && (
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
      )}
      <ListItemText primary={translate('navigation.changeSite')} />
    </ListItem>
  );
};

export const LogoutButton = (props: ParameterProps) => {
  return (
    <ListItem component={'li'} button key="logout" onClick={props.onClick}>
      {props.iconVisible && (
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
      )}
      <ListItemText primary={translate('navigation.logout')} />
    </ListItem>
  );
};

export const SettingsButton = (props: ParameterProps) => {
  return (
    <ListItem component={'li'} button key="settings" onClick={props.onClick}>
      {props.iconVisible && (
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
      )}
      <ListItemText primary={translate('navigation.settings')} />
    </ListItem>
  );
};
