import React, { useCallback, useEffect, useState } from 'react';
import { RouterState } from 'connected-react-router';
import { Redirect, RouteComponentProps } from 'react-router';

import { AppBar, Button, Container, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/EditOutlined';
import PhoneIcon from '@mui/icons-material/Phone';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';

import { connect, localeConnect } from 'localeConnect';
import theme, { mobileContentMaxWidth } from 'theme';
import { RoutePaths } from 'routes';
import { ContainerItem, ContainerType } from 'store/common/types';
import { getCurrentUserSettings, getMeasDeviceGroupId, getSelectedVehicle } from 'store/selectors';
import { clearSelectedMeasDevice } from 'store/meas-devices/actions';
import {
  confirmWeighing,
  destroyContext,
  doWeighing,
  inactivateContext,
  requestZeroing,
} from 'store/weighing/actions';
import { StatusMap, undefinedMassKg } from 'store/weighing/types';
import { ApplicationState, ConnectedReduxProps } from 'store';
import { setMultipartEditComponentId } from 'store/orders/actions';
import { Context, Contexts, WeighingProcess, OrderState } from 'store/orders/types';
import { getContextMainJob, MainJobMultipartBehavior } from 'store/orders/utils';
import { BridgeEnabledFeatures, SiteInfo } from 'store/scale-info/types';
import { Job } from 'store/jobs/types';
import { jobsTrucksAndTrailers, loadWeighedKgs } from 'store/jobs/utils';
import { UserSpecificSettings } from 'store/settings/types';
import { filterValidLoads } from 'store/utils';
import VehicleModel from 'models/vehicle.model';
import { scrollToTop } from 'components/utils';

import {
  VehicleWeighingModels,
  WeighingContainer,
  WeighingDirection,
  contextToNormalVehicleWeighingModels,
  toMultipartVehicleWeighingModels,
} from 'models/vehicleweighing.model';

import SettingsSaveTareDialog, {
  isShowingSaveTareDialogApplicable,
} from 'components/SettingsSaveTareDialog';
import { translate } from 'utils/translate';
import DeepLinkReturnLink from '../DeepLinkReturnLink';
import WeighingStatusComponent from './WeighingStatus';

const useStyles = makeStyles({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100vh',
  },
  title: {
    flexGrow: 1,
  },
  container: {
    marginTop: '20px',
    maxWidth: mobileContentMaxWidth,
    height: '85%',
    alignContent: 'center',
  },
  outergrid: {
    height: '100%',
  },
  outergridSpacingItem: {
    flexGrow: 1,
  },
  controlbutton: {
    minWidth: '125px',
    bottom: 0,
  },
});

interface PropsFromState {
  context?: Context;
  contexts: Contexts;
  currentContextId?: string;
  enabledFeatures: BridgeEnabledFeatures;
  router: RouterState;
  selectedVehicle?: VehicleModel;
  selectedMeasDeviceGroupId?: string;
  siteInfo: SiteInfo;
  status: StatusMap;
  inProgress: boolean;
  userSettings: UserSpecificSettings;
}

interface PropsFromDispatch {
  confirmWeighing: typeof confirmWeighing;
  destroyContext: typeof destroyContext;
  doWeighing: typeof doWeighing;
  clearSelectedMeasDevice: typeof clearSelectedMeasDevice;
  requestZeroing: typeof requestZeroing;
  inactivateContext: typeof inactivateContext;
  setMultipartEditComponentId: typeof setMultipartEditComponentId;
}

/*eslint-disable */
type AllProps = PropsFromDispatch & PropsFromState & RouteComponentProps<{}> & ConnectedReduxProps;
/*eslint-enable */

function getVehicle(state: ApplicationState) {
  const vehicle = getSelectedVehicle(state);
  if (vehicle == null) {
    return undefined;
  }
  return VehicleModel.vehicleModelFromVehicle(vehicle);
}

function getContext(state: OrderState) {
  return state.currentContextId && state.contexts
    ? state.contexts[state.currentContextId]
    : undefined;
}

const mapStateToProps = (state: ApplicationState) => ({
  context: getContext(state.orders),
  contexts: state.orders.contexts,
  currentContextId: state.orders.currentContextId,
  enabledFeatures: state.currentScaleInfo.enabledFeatures,
  status: state.weighing.status,
  selectedVehicle: getVehicle(state),
  selectedMeasDeviceGroupId: getMeasDeviceGroupId(state),
  siteInfo: state.currentScaleInfo.siteInfo,
  inProgress: state.weighing.inProgress,
  userSettings: getCurrentUserSettings(state),
});

const mapDispatchToProps = {
  confirmWeighing,
  destroyContext,
  doWeighing,
  clearSelectedMeasDevice,
  inactivateContext: inactivateContext,
  requestZeroing: requestZeroing,
  setMultipartEditComponentId,
};

function createModel(
  currentContext: Context | undefined,
  statusMap: StatusMap,
  enabledFeatures: BridgeEnabledFeatures,
): VehicleWeighingModels | undefined {
  if (!currentContext || !currentContext.weighingJobs) return undefined;
  const measDeviceKeys = currentContext.measDeviceKeys;
  const statuses = measDeviceKeys.map((key) => {
    if (statusMap[key]) {
      return { ...statusMap[key], key };
    }
    return {
      key: key,
      canSum: false,
      isStable: false,
      massKg: undefinedMassKg,
    };
  });
  let model: VehicleWeighingModels | undefined = undefined;
  if (currentContext) {
    if (currentContext.process === WeighingProcess.MULTIPART) {
      model = toMultipartVehicleWeighingModels(currentContext.weighingJobs, statuses);
    } else if (currentContext.process === WeighingProcess.NORMAL) {
      model = contextToNormalVehicleWeighingModels(currentContext, statuses, enabledFeatures);
    }
  }
  return model;
}

function getActiveMultipartRoundComponentId(context: Context, activeRound: number): string {
  let componentId = '';
  // NOTE(mikkogy,20210617) component id is related to editing master data so we
  // don't need to support first round exceptional logic to find component.
  if (activeRound < 2) {
    return '';
  }
  if (context?.weighingJobs) {
    if (context.weighingJobs.length > activeRound - 2) {
      componentId = context.weighingJobs[activeRound - 2].order.componentId;
    }
  }
  return componentId;
}

function hasWeighed(
  initialActiveRound: number,
  initialActiveContainerKeys: string[],
  model?: VehicleWeighingModels,
) {
  if (!model) return false;
  const vehicleModel = model?.models.find((model) => model.isActive);
  if (!vehicleModel) {
    return initialActiveRound > 0;
  }
  const activeContainerKeys = vehicleModel.containers
    .filter((container) => container.isActive)
    .map((container) => container.key);

  return (
    initialActiveRound !== vehicleModel.round ||
    JSON.stringify(initialActiveContainerKeys) !== JSON.stringify(activeContainerKeys)
  );
}

interface ContainerKgDisplay {
  key: string;
  containerType: ContainerType;
  direction?: WeighingDirection;
  isWrongDirection: boolean;
  kgs: number;
}

function getAfterFirstRoundKgDisplays(
  containers: WeighingContainer[],
  jobContainers: ContainerItem[],
  currentContext: Context | undefined,
) {
  return containers.map((container) => {
    const jobContainer = jobContainers.find((jobContainer) => jobContainer.key === container.key);
    let kgs = undefinedMassKg;
    const mainJob = getContextMainJob(currentContext, MainJobMultipartBehavior.FIRST);
    if (jobContainer?.loadIndexes && mainJob?.loads) {
      const load = mainJob.loads[jobContainer?.loadIndexes[0]];
      if (load) {
        kgs = loadWeighedKgs(load);
      }
    }
    return {
      key: container.key,
      containerType: container.containerType,
      isWrongDirection: false,
      kgs,
    };
  });
}

function getContainerDisplayWarningText(
  display: ContainerKgDisplay,
  hasDifferentDirections: boolean,
) {
  if (display.isWrongDirection && display.direction === WeighingDirection.UNDEFINED) {
    return translate('weighing.zeroNet');
  }

  return display.isWrongDirection || hasDifferentDirections
    ? translate('weighing.directionChanges')
    : '';
}

const WeighingComponent = (props: AllProps) => {
  const classes = useStyles();
  const [activeContainerKeysState, setActiveContainerKeys] = useState<string[]>([]);
  const [activeRound, setActiveRound] = useState(0);
  const [isAutoConfirmInProgress, setIsAutoConfirmInProgress] = useState(false);
  const [isTareSavingDialogVisible, setIsTareSavingDialogVisible] = useState(false);
  const [measDeviceGroupId, setMeasDeviceGroupId] = useState('');

  const backPressed = useCallback(() => {
    window.removeEventListener('popstate', backPressed);
  }, []);

  useEffect(() => {
    window.addEventListener('popstate', backPressed);
    scrollToTop();
    return () => {
      // NOTE(eetuk,20221122) handler can't be removed immediately because
      // cleanup is called before the handler.
      setTimeout(() => {
        window.removeEventListener('popstate', backPressed);
      }, 50);
    };
  }, [backPressed]);

  useEffect(() => {
    const currentContext = props.context;
    if (currentContext && currentContext.measDeviceKeys.length > 0 && activeRound === 0) {
      const model = createModel(currentContext, props.status, props.enabledFeatures);
      if (!model) throw new Error('model must exist');
      const vehicleModel = model?.models.find((model) => model.isActive);
      if (!vehicleModel) throw new Error('vehicle model must exist');
      const activeContainerKeys = vehicleModel.containers
        .filter((container) => container.isActive)
        .map((container) => container.key);
      setActiveContainerKeys(activeContainerKeys);
      setActiveRound(vehicleModel.round);
      setMeasDeviceGroupId(currentContext.measDeviceGroupId);
      return;
    }
    if (activeRound > 0) {
      if (isAutoConfirmInProgress) {
        return;
      }
      const model = createModel(currentContext, props.status, props.enabledFeatures);
      const isDone = hasWeighed(activeRound, activeContainerKeysState, model);
      if (isDone) {
        const confirm = () => {
          if (
            isShowingSaveTareDialogApplicable(
              currentContext,
              props.userSettings,
              props.enabledFeatures,
            )
          ) {
            setIsTareSavingDialogVisible(true);
            return;
          }
          props.confirmWeighing(false);
        };
        setIsAutoConfirmInProgress(true);
        setTimeout(() => confirm(), 2000);
      }
    }
  }, [props, activeRound, activeContainerKeysState, isAutoConfirmInProgress]);

  const doWeighing = (measDeviceKeys: string[]) => {
    props.doWeighing(measDeviceKeys);
  };

  const abortWeighing = () => {
    props.inactivateContext();
    props.clearSelectedMeasDevice();
    props.history.goBack();
  };

  const requestZeroing = (measDeviceKeys: string[]) => {
    props.requestZeroing(measDeviceKeys);
  };

  const startEditing = () => {
    let componentId = '';
    const currentContext = props.context;
    if (currentContext && currentContext.process === WeighingProcess.MULTIPART) {
      componentId = getActiveMultipartRoundComponentId(currentContext, activeRound);
    }
    props.setMultipartEditComponentId(componentId);
    props.history.push(RoutePaths.EDIT_ORDER);
  };

  const isZeroingVisible = process.env.REACT_APP_SHOW_ZEROING === 'true';

  if (!props.selectedVehicle) {
    console.error('No vehicle in weighing');
    return <Redirect to={RoutePaths.VEHICLES} />;
  }
  const currentContext = props.context;
  if (!currentContext) {
    console.error('No context in weighing');
    return <Redirect to={RoutePaths.ORDERS} />;
  }
  if (!currentContext.weighingJobs) {
    console.error('No jobs in weighing');
    return <Redirect to={RoutePaths.ORDERS} />;
  }
  if (
    currentContext.measDeviceKeys &&
    currentContext.measDeviceKeys.length <= 0 &&
    !props.selectedMeasDeviceGroupId
  ) {
    console.error("Can't do weighing without meas device");
    return <Redirect to={RoutePaths.ORDERS} />;
  }

  if (activeRound > 0 && currentContext.measDeviceKeys.length <= 0) {
    console.warn(
      "Inactivated and lost meas device(s) or changed meas device group, can't weigh anymore.",
    );
    return <Redirect to={RoutePaths.ORDERS} />;
  }
  if (activeRound > 0 && currentContext.measDeviceGroupId !== measDeviceGroupId) {
    console.warn(
      'Operator has changed meas device group, nothing to do here for driver at this point.',
    );
    // NOTE(mikkogy,20210608) when there are multiple owners destroying will
    // simply exit the context, not really destroy it.
    props.destroyContext();
    return <Redirect to={RoutePaths.ORDERS} />;
  }

  const measDeviceKeys = currentContext.measDeviceKeys;
  const model = createModel(currentContext, props.status, props.enabledFeatures);
  const isWeighingDone = hasWeighed(activeRound, activeContainerKeysState, model);

  const vehicleModel = model?.models.find((model) => model.round === activeRound);
  const activeVehicleModel = model?.models.find((model) => model.isActive);
  let displayVehicleModel = vehicleModel;
  let weighingDisplays: ContainerKgDisplay[] = [];
  if (
    activeRound > 1 &&
    !vehicleModel &&
    activeVehicleModel &&
    isWeighingDone &&
    currentContext.process === WeighingProcess.MULTIPART &&
    activeVehicleModel.round > activeRound
  ) {
    // NOTE(mikkogy,20210604) in multipart we do not have previous round
    // available but we can get it by ignoring latest valid job.
    const jobs: Job[] = currentContext.weighingJobs.filter(
      (job) => filterValidLoads(job?.loads || []).length > 0,
    );
    if (jobs.length < 2) {
      throw new Error('logic error, must have more than one job in this branch');
    }
    const model = toMultipartVehicleWeighingModels(jobs.slice(0, -1), []);
    displayVehicleModel = model?.models.find((model) => model.round === activeRound);
  }
  if (activeRound === 1 && activeVehicleModel && activeVehicleModel.round === 2) {
    // NOTE(mikkogy,20210607) special case, round 1 completed but since we
    // have only one job with valid loads, we can't just ignore the whole job.
    const containers = activeVehicleModel?.containers || [];
    const jobContainers = jobsTrucksAndTrailers(currentContext.weighingJobs);
    weighingDisplays = getAfterFirstRoundKgDisplays(containers, jobContainers, currentContext);
  } else {
    weighingDisplays = displayVehicleModel?.containers || [];
  }

  const directions = weighingDisplays.map((display) => display.direction);
  const hasDifferentDirections =
    directions.includes(WeighingDirection.ADD) && directions.includes(WeighingDirection.REDUCE);

  const canSum = !!vehicleModel?.isWeighingEnabled;

  const phoneNumber =
    props.siteInfo && props.siteInfo.phoneNumber ? props.siteInfo.phoneNumber : '';

  return (
    <div className={classes.root}>
      <SettingsSaveTareDialog
        context={currentContext}
        onAnswer={(saveTare: boolean) => {
          props.confirmWeighing(saveTare);
        }}
        visible={isTareSavingDialogVisible}
      />
      <AppBar position="relative">
        <DeepLinkReturnLink isContextIgnored={false} />
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label={translate('weighing.return')}
            edge="start"
            onClick={abortWeighing}
          >
            <BackIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            {translate('weighing.weighing')}
          </Typography>

          <IconButton
            color="inherit"
            aria-label={translate('weighing.editOrder')}
            disabled={
              isWeighingDone ||
              (currentContext.process === WeighingProcess.MULTIPART &&
                !getActiveMultipartRoundComponentId(currentContext, activeRound))
            }
            onClick={startEditing}
          >
            <EditIcon />
          </IconButton>

          <IconButton
            color="inherit"
            aria-label={translate('weighing.call')}
            edge="end"
            disabled={!phoneNumber}
            href={`tel:${phoneNumber}`}
          >
            <PhoneIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container className={classes.container}>
        <Grid container spacing={2} direction={'column'} className={classes.outergrid}>
          {weighingDisplays.map(
            (display) =>
              activeContainerKeysState.includes(display.key) && (
                <Grid key={display.key} item>
                  <WeighingStatusComponent
                    title={translate(`weighing.containerTypes.${display.containerType}`)}
                    locked={isWeighingDone}
                    massKg={display.kgs}
                    inProgress={props.inProgress}
                    warningText={getContainerDisplayWarningText(display, hasDifferentDirections)}
                  />
                </Grid>
              ),
          )}
          <Grid item>
            <Grid container spacing={1} direction="row" justifyContent="space-between">
              <Grid item>
                <Button
                  className={classes.controlbutton}
                  color="primary"
                  variant="contained"
                  onClick={() => requestZeroing(measDeviceKeys)}
                  disabled={!isZeroingVisible || isWeighingDone}
                >
                  {translate('weighing.zero')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={classes.controlbutton}
                  color="primary"
                  variant="contained"
                  onClick={() => doWeighing(measDeviceKeys)}
                  disabled={isWeighingDone || !canSum}
                >
                  {translate('weighing.weigh')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.outergridSpacingItem} />
        </Grid>
      </Container>
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(WeighingComponent);
