import React from 'react';

import { Button } from '@mui/material';

const clickCoolOffMs = 20 * 1000;

interface Props {
  [key: string]: any;
  children: React.ReactNode;
  onClickAccepted: () => void;
}

// A button that skips repeated clicks. Usable for example for create requests
// when it would be complicated to check if request has not been processed and
// we do not expect failures.
const CoolOffButton = (props: Props) => {
  const [clickedTime, setClickedTime] = React.useState(0);

  function handleClick() {
    if (clickedTime > new Date().getTime() - clickCoolOffMs) {
      // NOTE(mikkogy,20210917) ignore repeated presses from users who need to
      // calm down.
      return;
    }
    setClickedTime(new Date().getTime());
    props.onClickAccepted();
  }
  const buttonProps: any = { ...props };
  delete buttonProps.children;
  delete buttonProps.onClickAccepted;
  return (
    <Button {...buttonProps} onClick={handleClick}>
      {props.children}
    </Button>
  );
};

export default CoolOffButton;
