import { all, call, cancel, delay, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import { RoutePaths } from 'routes';
import { callApi } from 'utils/api';
import * as LiveDataActions from '../common/actions';
import { getAutoselectSite, getTokenHeader, getUser } from '../selectors';
import { destroyToken } from '../user/actions';
import { updateStoreUserDomains } from '../user/sagas';
import { isUserInDomain } from '../user/utils';
import { UserDomain } from '../user/types';
import { Scale, SitesActionTypes } from './types';
import {
  fetchError,
  fetchRequest,
  fetchSuccess,
  selectSite,
  siteSelected,
  stopSitesPoll,
} from './actions';

const API_ENDPOINT = `${process.env.REACT_APP_PILVILINNA_URL || 'http://localhost/'}api/v1/`;

function* fetchScales(): any {
  function logError(err: any) {
    console.warn('site fetch failed', err);
  }
  try {
    const tokenHeader = yield select(getTokenHeader);
    if (!tokenHeader) {
      yield put(destroyToken());
      return;
    }
    const user = yield select(getUser);
    const orgId = user.userData.organization;
    const res = yield call(
      callApi,
      'get',
      API_ENDPOINT,
      `export/organizations/${orgId}/scales/by_type/BRIDGE`,
      null,
      tokenHeader,
    );

    if (res.error) {
      logError(res.error);
      yield put(fetchError(res.error));
      // NOTE(lindenlas,20220928) in case of error we want to destroy token.
      // Token could be expired and would cause next requests to fail.
      yield put(destroyToken());
    } else {
      const userDomains: UserDomain[] = yield call(updateStoreUserDomains);
      const userBelongsToOrganizationDomain = isUserInDomain(userDomains, orgId);
      const sites = res as Scale[];
      const bridgeSites = sites.filter((s: Scale) => {
        const userBelongsToScaleDomain = isUserInDomain(userDomains, s.key);
        return s.type === 'BRIDGE' && (userBelongsToScaleDomain || userBelongsToOrganizationDomain);
      });
      yield put(fetchSuccess(bridgeSites));
      const autoselectSite = yield select(getAutoselectSite);
      if (autoselectSite && bridgeSites.length === 1) {
        yield put(selectSite(bridgeSites[0].key));
      }
    }
  } catch (err: any) {
    logError(err);
    yield put(fetchError(err));
  }
}

function* handleSelect(action: ReturnType<typeof selectSite>) {
  console.log('Selected site', action.payload);
  yield put(siteSelected(action.payload));
  yield put(stopSitesPoll());
}

function* handleChange() {
  yield put(LiveDataActions.disconnectRequest());
  yield put(replace(RoutePaths.SITES));
}

function* watchFetchRequest() {
  yield takeLatest(SitesActionTypes.FETCH_REQUEST, fetchScales);
}

function* pollSites() {
  try {
    console.log('Starting polling sites');
    while (true) {
      yield put(fetchRequest());
      yield delay(5 * 1000);
    }
  } finally {
    console.log('Stopped polling sites');
  }
}

function* watchStartSitesPoll(): any {
  while (yield take(SitesActionTypes.START_SITES_POLL)) {
    const task = yield fork(pollSites);
    yield take(SitesActionTypes.STOP_SITES_POLL);
    yield cancel(task);
  }
}

function* watchSelectSite() {
  yield takeLatest(SitesActionTypes.SELECT_SITE, handleSelect);
}

function* watchClearSite() {
  yield takeLatest(SitesActionTypes.CHANGE_SITE, handleChange);
}

function* sitesSaga() {
  yield all([
    fork(watchSelectSite),
    fork(watchFetchRequest),
    fork(watchClearSite),
    fork(watchStartSitesPoll),
  ]);
}

export default sitesSaga;
