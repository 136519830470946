import React from 'react';

import { makeStyles } from '@mui/styles';
import { Button, List, Popover, Typography } from '@mui/material';

import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { ApplicationState } from 'store';
import { User } from 'store/user/types';
import { formatUserDisplayName } from 'store/user/utils';

import { connect, localeConnect } from 'localeConnect';

import ConnectedChangeSiteButton from '../ConnectedChangeSiteButton';
import ConnectedLogoutButton from '../ConnectedLogoutButton';
import RoleSelect from '../RoleSelect';
import SettingsDialogButton from '../SettingsDialogButton';

const useStyles = makeStyles({
  content: {
    width: '250px',
  },
  mainButton: {
    color: '#006eaa',
    float: 'right',
  },
  siteName: {
    clear: 'both',
  },
});

interface PropsFromState {
  scaleName: string;
  user: User;
}

interface PropsFromDispatch {}

const mapStateToProps = ({ currentScaleInfo, user }: ApplicationState) => ({
  scaleName: currentScaleInfo.scaleName,
  user: user.user,
});

const mapDispatchToProps = {};

type AllProps = PropsFromDispatch & PropsFromState;

const AccountPopover = (props: AllProps) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  function handleClick(event: React.MouseEvent<{ value: unknown }>) {
    setAnchorEl(event.currentTarget as any);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div>
      <Button aria-describedby={id} onClick={handleClick} className={classes.mainButton}>
        {formatUserDisplayName(props.user.userData)}
        {open ? <ExpandLess /> : <ExpandMore />}
      </Button>
      <div className={classes.siteName}>
        <Typography variant="body1">{props.scaleName}</Typography>
      </div>
      <Popover
        id="accountPopover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List component={'ul'} className={classes.content}>
          <RoleSelect />
          <SettingsDialogButton iconVisible={true} onOpen={undefined} onClose={handleClose} />
          <ConnectedChangeSiteButton iconVisible={true} onClick={undefined} />
          <ConnectedLogoutButton iconVisible={true} onClick={handleClose} />
        </List>
      </Popover>
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(AccountPopover);
