import { Reducer } from 'redux';
import { SitesActionTypes } from '../sites/types';
import { UserActionTypes } from '../user/types';
import {
  ScaleInfoActionTypes,
  ScaleInfoState,
  SiteInfo,
  ShownSiteInfoType,
  CurrentScaleInfoState,
} from './types';
import { calculateInstructionHash, calculateLocationHash } from './utils';

const initialCurrentScaleInfoState: CurrentScaleInfoState = {
  domainInfo: {
    domainConfiguration: {},
    masterDataCombo: {
      schemaKey: '',
      schemaVersion: '',
    },
    receiptSpec: { docType: '' },
  },
  enabledFeatures: {
    bridgePrimaryContainerTare: false,
    bridgeSecondaryContainerTareDeduction: false,
    bridgeIsMasterDataExternalIdVisible: false,
    bridgeIsMasterDataLocalityVisible: false,
    bridgeInspector: false,
    bridgePhotosAsDriver: false,
  },
  isDummyScale: false,
  isEnabledFeaturesLoaded: false,
  isVersionMismatchShown: false,
  organizationEnabledFeatures: {
    bridgeSplitLoads: false,
  },
  siteInfo: {},
  scaleKey: '',
  scaleName: '',
  versionMismatchReloadTime: 0,
};

export const initialScaleInfoState: ScaleInfoState = {
  siteInfoHashes: {},
};

const scaleInfoReducer: Reducer<ScaleInfoState> = (state = initialScaleInfoState, action) => {
  switch (action.type) {
    case ScaleInfoActionTypes.SITE_INFO_SUCCESS:
      const siteInfo = action.payload.siteInfo as SiteInfo;

      const newLocationHash = calculateLocationHash(siteInfo);

      const newInstructionsHash = calculateInstructionHash(siteInfo);

      const scaleKey = action.payload.scaleKey;
      const currentSiteInfoHashes = state.siteInfoHashes[scaleKey];

      const newSiteUpdate = {
        ...currentSiteInfoHashes,
        instructionsHash: newInstructionsHash,
        locationUrlHash: newLocationHash,
      };

      // If current site is new we don't want to show location or instruction as updated so we store first known hashes as shown
      if (!newSiteUpdate.shownLocationUrlHash) {
        newSiteUpdate.shownLocationUrlHash = newSiteUpdate.locationUrlHash;
      }
      if (!newSiteUpdate.shownInstructionHash) {
        newSiteUpdate.shownInstructionHash = newSiteUpdate.instructionsHash;
      }

      const newSiteInfoHashes = { ...state.siteInfoHashes };
      newSiteInfoHashes[scaleKey] = newSiteUpdate;

      return {
        ...state,
        siteInfoHashes: newSiteInfoHashes,
      };
    case ScaleInfoActionTypes.MARK_SITE_INFO_SHOWN: {
      const shownHashType = action.payload.shownSiteInfoType as ShownSiteInfoType;
      if (!shownHashType) return state;

      const siteKey = action.payload.scaleKey;
      if (!state.siteInfoHashes[siteKey]) {
        console.log('site info not found from state', state.siteInfoHashes[siteKey]);
        return state;
      }
      const currentSiteInfoHashes = state.siteInfoHashes[siteKey];

      const newSiteInfoHashes = { ...currentSiteInfoHashes };

      if (shownHashType === ShownSiteInfoType.INSTRUCTIONS) {
        newSiteInfoHashes.shownInstructionHash = currentSiteInfoHashes.instructionsHash;
      } else if (shownHashType === ShownSiteInfoType.LOCATION) {
        newSiteInfoHashes.shownLocationUrlHash = currentSiteInfoHashes.locationUrlHash;
      }

      const updatedSiteInfoHashes = { ...state.siteInfoHashes };

      updatedSiteInfoHashes[siteKey] = newSiteInfoHashes;

      return {
        ...state,
        siteInfoHashes: updatedSiteInfoHashes,
      };
    }
    default: {
      return state;
    }
  }
};

const currentScaleInfoReducer: Reducer<CurrentScaleInfoState> = (
  state = initialCurrentScaleInfoState,
  action,
) => {
  switch (action.type) {
    case ScaleInfoActionTypes.DOMAIN_INFO: {
      return {
        ...state,
        domainInfo: action.payload,
      };
    }
    case ScaleInfoActionTypes.SCALE_INFO_SUCCESS:
      console.log('Received scale info', action.payload);
      return {
        ...state,
        enabledFeatures: action.payload.enabledFeatures,
        scaleKey: action.payload.scaleKey,
        scaleName: action.payload.scaleName,
        isDummyScale: action.payload.swVersion.indexOf('dummy') !== -1,
        isEnabledFeaturesLoaded: true,
        organizationEnabledFeatures: action.payload.organizationEnabledFeatures,
      };
    case ScaleInfoActionTypes.SET_IS_VERSION_MISMATCH_SHOWN:
      return {
        ...state,
        isVersionMismatchShown: action.payload,
      };
    case ScaleInfoActionTypes.SITE_INFO_SUCCESS:
      const siteInfo = action.payload.siteInfo as SiteInfo;
      return {
        ...state,
        siteInfo: siteInfo,
      };
    case ScaleInfoActionTypes.STORE_VERSION_MISMATCH_RELOAD_TIME:
      return {
        ...state,
        versionMismatchReloadTime: action.payload,
      };
    case SitesActionTypes.CHANGE_SITE:
    case UserActionTypes.LOGOUT: {
      return {
        ...initialCurrentScaleInfoState,
      };
    }
    default: {
      return state;
    }
  }
};

export { scaleInfoReducer, currentScaleInfoReducer };
