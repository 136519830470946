import { action } from 'typesafe-actions';
import { DeepLinkActionTypes } from './types';

export const clear = () => action(DeepLinkActionTypes.CLEAR);
export const deepLinkFailure = () => action(DeepLinkActionTypes.FAILURE);
export const deepLinkSuccess = (returnLink: string | undefined) =>
  action(DeepLinkActionTypes.SUCCESS, returnLink);
export const loadDeepLink = (linkId: string) => action(DeepLinkActionTypes.LOAD, linkId);
export const setOrderKey = (orderKey: string) =>
  action(DeepLinkActionTypes.SET_ORDER_KEY, orderKey);
export const setReturnLink = (returnLink: string | undefined) =>
  action(DeepLinkActionTypes.SET_RETURN_LINK, returnLink);
export const startLoading = (linkId: string) => action(DeepLinkActionTypes.START_LOADING, linkId);
