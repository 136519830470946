import { ApplicationState } from 'store';

export function findContext(state: ApplicationState) {
  if (state.orders.currentContextId) {
    if (state.orders.contexts[state.orders.currentContextId]) {
      return state.orders.contexts[state.orders.currentContextId];
    }
  }
  return undefined;
}
