import React from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';

interface InputProps {
  allowUndefined: boolean;
  hideZero: boolean;
  showReset: boolean;
  // NOTE(mikkogy,20220531) no need to check value is between min and max. This
  // is intented for other errors.
  hasError: boolean;
  // NOTE(mikkogy,20220531) for convenience negative values are accepted but
  // their abolute value is actually used. This is very handy with kgs where
  // sign is used to indicate impact on sum.
  initialValue: number | undefined;
  isDisabled: boolean;
  isOutOfRangePrevented: boolean;
  label: string;
  max: number;
  min: number;
  onChanged: (value: number | undefined) => void;
  updateInitialValue: boolean;
  useAutoFocus: boolean;
  useFullWidth: boolean;
}

export const NonNegativeIntInput = (props: InputProps) => {
  const [inputValue, setInputValue] = React.useState<number | undefined>(
    props.initialValue === undefined ? undefined : Math.abs(props.initialValue),
  );

  React.useEffect(() => {
    if (!props.updateInitialValue) {
      return;
    }
    const absInitial = props.initialValue === undefined ? undefined : Math.abs(props.initialValue);
    if (absInitial !== inputValue) {
      setInputValue(absInitial);
    }
  }, [props.initialValue, inputValue, setInputValue, props.updateInitialValue]);

  const onChanged = (value: number | undefined) => {
    setInputValue(value);
    props.onChanged(value);
  };

  function getVisibleValue() {
    if (props.hideZero && inputValue === 0) return '';
    if (inputValue === undefined) return '';
    return inputValue;
  }

  return (
    <TextField
      variant="standard"
      autoFocus={props.useAutoFocus}
      disabled={props.isDisabled}
      fullWidth={props.useFullWidth}
      label={props.label}
      value={getVisibleValue()}
      type="number"
      onChange={(e) => {
        const value = e.target.value;
        if (value === '' && props.allowUndefined) {
          onChanged(undefined);
          return;
        }
        let intValue = 0;
        if (value.length > 0) {
          intValue = parseInt(value, 10);
        }
        if (isNaN(intValue)) return;
        if (props.isOutOfRangePrevented) {
          if (intValue < props.min) return;
          if (intValue > props.max) return;
        }
        // NOTE(mikkogy,20210217) clear preceeding zero.
        e.target.value = '' + intValue;
        onChanged(intValue);
      }}
      inputProps={{
        min: props.min,
        max: props.max,
        step: 1,
        type: 'number',
        pattern: '[0-9]{6}',
      }}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        endAdornment: props.showReset && (
          <InputAdornment position="end">
            <IconButton onClick={() => onChanged(props.allowUndefined ? undefined : 0)}>
              <CancelIcon color="primary" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      required
      error={
        props.hasError ||
        inputValue === undefined ||
        inputValue < props.min ||
        inputValue > props.max
      }
      onWheel={(event) => {
        event.currentTarget.querySelector('input')?.blur();
      }}
    />
  );
};
