import React from 'react';

import { makeStyles } from '@mui/styles';
import { Button, Grid } from '@mui/material';

import { connect, localeConnect } from 'localeConnect';

import { ApplicationState } from 'store';
import {
  addImageToJob,
  removeImage,
  setImageDescription,
  setImageDescriptionImmediately,
} from 'store/images/actions';
import { CacheImage, CacheImageType } from 'store/images/types';
import { Context } from 'store/orders/types';
import { BridgeEnabledFeatures } from 'store/scale-info/types';
import { UiRoles } from 'store/user/types';
import { effectiveUiRole } from 'store/utils';

import theme from 'theme';

import { translate } from 'utils/translate';
import { findImages, hasUploadsInProgress } from './utils';

import Photos from './Photos';

interface ParameterProps {
  context: Context;
  imageFilter: ((image: CacheImage) => boolean) | undefined;
  jobKey: string;
}

interface PropsFromState {
  effectiveRole: UiRoles;
  enabledFeatures: BridgeEnabledFeatures;
  state: ApplicationState;
  userKey: string | undefined;
}

interface PropsFromDispatch {
  addImageToJob: typeof addImageToJob;
  removeImage: typeof removeImage;
  setImageDescription: typeof setImageDescription;
  setImageDescriptionImmediately: typeof setImageDescriptionImmediately;
}

const mapStateToProps = (state: ApplicationState) => ({
  effectiveRole: effectiveUiRole(
    state.user.selectedUiRole,
    state.user.user,
    state.currentScaleInfo.enabledFeatures,
  ),
  enabledFeatures: state.currentScaleInfo.enabledFeatures,
  state: state,
  userKey: state.user?.user?.userData?.key,
});

const mapDispatchToProps = {
  addImageToJob,
  removeImage,
  setImageDescription,
  setImageDescriptionImmediately,
};

const useStyles = makeStyles(() => ({
  addPhotoButton: {
    backgroundColor: '#0077C0',
    color: theme.palette.common.white,
  },
  finishContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  photosGrid: {
    // eslint-disable-next-line
    display: 'block',
    // eslint-disable-next-line
    flexWrap: 'initial',
    '& .MuiGrid-container': {
      flexWrap: 'initial',
    },
  },
}));

type AllProps = ParameterProps & PropsFromDispatch & PropsFromState;

const ConnectedPhotos = (props: AllProps) => {
  const classes = useStyles();

  const jobImages = findImages(props.state, props.context.order.key).filter((image) => {
    if (!props.imageFilter) return true;
    return props.imageFilter(image);
  });

  const images = jobImages.filter((image) => {
    if (props.effectiveRole === UiRoles.INSPECTOR) return true;
    if (image.type === CacheImageType.UPLOAD) return true;
    const attachments = props.context?.attachments;
    if (!attachments) return false;
    const contextAttachment = attachments[image.attachmentKey];
    if (!contextAttachment) return false;
    // NOTE(lindenlas,20230508) When user doesn't have rights to download
    // images, we want to hide irrelevant attachments as they would show
    // as placeholder images.
    return (
      !!contextAttachment.bridgeSpecificPropOwnerKey &&
      contextAttachment.bridgeSpecificPropOwnerKey === props.userKey
    );
  });

  const hasUploads = hasUploadsInProgress(props.context, jobImages);

  React.useEffect(() => {
    const handleBeforeUnloadEvent = (event: BeforeUnloadEvent) => {
      if (!hasUploads) {
        return undefined;
      }
      event.preventDefault();
      // NOTE(lindenlas,20230508) Before unload event message cannot be customized.
      event.returnValue = '';
      return '';
    };
    window.addEventListener('beforeunload', handleBeforeUnloadEvent);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnloadEvent);
    };
  }, [hasUploads]);

  const arePhotosEnabled =
    props.effectiveRole === UiRoles.INSPECTOR ||
    (props.enabledFeatures.bridgePhotosAsDriver && props.effectiveRole === UiRoles.DRIVER);

  if (!arePhotosEnabled) return <div />;

  const onAddPhotoClick = () => {
    props.addImageToJob(props.jobKey);
  };

  const setImageDescription = (attachmentKey: string, description: string, useForce: boolean) => {
    if (useForce) {
      props.setImageDescriptionImmediately(attachmentKey, description);
    } else {
      props.setImageDescription(attachmentKey, description);
    }
  };

  return (
    <Grid container spacing={2} className={classes.photosGrid}>
      <Grid item>
        <Photos
          images={images}
          removeImage={(image: CacheImage) =>
            props.removeImage(props.context.contextId, image, props.jobKey)
          }
          setImageDescription={setImageDescription}
        />
      </Grid>
      <Grid item className={classes.finishContainer}>
        <Button variant="contained" className={classes.addPhotoButton} onClick={onAddPhotoClick}>
          {translate('orders.addPhoto')}
        </Button>
      </Grid>
    </Grid>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedPhotos);
