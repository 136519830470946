import React from 'react';
import { makeStyles } from '@mui/styles';
import theme, { breakLongText } from '../theme';

interface DetailProps {
  isRequired?: boolean;
  isRequiredHighlighted?: boolean;
  title: string;
  value: string | JSX.Element;
}

const useStyles = makeStyles({
  root: {
    textAlign: 'left',
    margin: 'auto',
  },
  detailValue: {
    ...theme.typography.subtitle1,
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: 'normal',
    ...breakLongText,
  },
  detailTitle: {
    ...theme.typography.overline,
    fontSize: '12px',
    lineHeight: 'normal',
    color: '#595959',
  },
  required: {
    '&, & div': {
      color: theme.palette.error.main,
    },
  },
});

function requiredClass(
  className: string,
  isRequired: boolean | undefined,
  isRequiredHighlighted: boolean | undefined,
) {
  return isRequired && isRequiredHighlighted ? className : '';
}

interface TitleProps {
  title: string;
  isRequired: boolean | undefined;
  isRequiredHighlighted: boolean | undefined;
}

export const CardDetailTitle = (props: TitleProps) => {
  const classes = useStyles();
  const required = requiredClass(classes.required, props.isRequired, props.isRequiredHighlighted);
  return <div className={`${classes.detailTitle} ${required}`}>{props.title}</div>;
};

interface ValueProps {
  value: string | JSX.Element;
  isRequired: boolean | undefined;
  isRequiredHighlighted: boolean | undefined;
}

export const CardDetailValue = (props: ValueProps) => {
  const classes = useStyles();
  const required = requiredClass(classes.required, props.isRequired, props.isRequiredHighlighted);
  return <div className={`${classes.detailValue} ${required}`}>{props.value}</div>;
};

const CardDetailItem = (props: DetailProps) => {
  const classes = useStyles();
  return (
    <div
      className={`${classes.root} ${requiredClass(
        classes.required,
        props.isRequired,
        props.isRequiredHighlighted,
      )}`}
    >
      <CardDetailTitle title={props.title} isRequired={false} isRequiredHighlighted={false} />
      <CardDetailValue value={props.value} isRequired={false} isRequiredHighlighted={false} />
    </div>
  );
};

export default CardDetailItem;
