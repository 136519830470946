import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
  throttle,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { RoutePaths } from 'routes';
import { User } from 'store/user/types';
import { incomingEvent, newToast, sendMessage } from '../common/actions';

import {
  LiveDataTypes,
  RemoteRequestIds,
  RemoteRequestTypes,
  RemoteReceiveTypes,
} from '../common/types';

import { Context, OrdersActionTypes } from '../orders/types';

import {
  getContexts,
  getCurrentContextId,
  getCurrentContextOrderKey,
  getImages,
  getWebsocketConnected,
  getUser,
} from '../selectors';
import { hasUserOperatorRights } from '../utils';
import {
  addImage,
  addImageToJob,
  removeByOrderKeys,
  removeImage,
  removeLocalImage,
  removeUploadFailedImage,
  saveToStore,
  setAddImageJobKey,
  setImageDescription,
} from './actions';

import {
  Attachment,
  CacheImage,
  CacheImageType,
  ImagesActionTypes,
  ImagesState,
  OrderImageMap,
} from './types';

const downloadRetryIntervalMs = 20 * 1000;
const uploadRetryIntervalMs = 20 * 1000;
const blobDataRetryIntervalMs = 30 * 1000;
const obsoleteMs = 3 * 24 * 60 * 60 * 1000;

const rawBase64Ratio = 3 / 4;

// NOTE(lindenlas,20230612) Maximum local storage size for browsers is about 5.2 MB,
// but we want to keep small margin if there are some other information that uses storage.
const maxCacheImageStorageSize = convertBase64MbSizeToRawBytesSize(4.8);

export function convertBase64MbSizeToRawBytesSize(sizeInMb: number): number {
  return Math.round(sizeInMb * 1024 * 1024 * rawBase64Ratio);
}

// NOTE(mikkogy,20201023) when changing make sure the value does not cause
// padding in base64 response or handle receiving properly which will involve
// manipulating next chunk. Padding character in base64 is '='.
const dataChunkBase64SizeBytes = 123 * 1024;
const maxFinalChunkIncrease = 66 * 1024;

function formatUploadReqId(localId: string) {
  return `${RemoteRequestIds.CREATE_UPLOAD}_${localId}`;
}

function formatDownloadReqId(localId: string) {
  return `${RemoteRequestIds.CREATE_DOWNLOAD}_${localId}`;
}

// NOTE(mikkogy,20201022) uri follows format: data:image/png;base64,<base64data>.

function mimeTypeFromDataUri(data: string) {
  const beginning = data.split(';')[0];
  if (!beginning) {
    return '';
  }
  const mimeParts = beginning.split(':');
  if (mimeParts.length !== 2) {
    return '';
  }
  return mimeParts[1];
}

function suffixFromDataUri(data: string) {
  const beginning = data.split(';')[0];
  const suffixParts = beginning.split('/');
  if (suffixParts.length !== 2) {
    return 'data';
  }
  return suffixParts[1];
}

function actualDataFromDataUri(data: string) {
  const actualDataParts = data.split(',');
  if (actualDataParts.length !== 2) {
    return '';
  }
  return actualDataParts[1];
}

function rawSizeBytesFromDataUri(data: string) {
  const actualData = actualDataFromDataUri(data);
  if (!actualData) {
    return -1;
  }
  let paddingBytes = 0;
  if (actualData.endsWith('==')) {
    paddingBytes = 2;
  } else if (actualData.endsWith('=')) {
    paddingBytes = 1;
  }
  // NOTE(mikkogy,20201022) there may be padding in base64 that doesn't really
  // exist in the raw form and should be ignored.
  return actualData.length * rawBase64Ratio - paddingBytes;
}

function formatFilename(date: Date, suffix: string) {
  return `IMG_${date.getTime()}.${suffix}`;
}

function* createUpload(cacheImage: CacheImage) {
  if (!cacheImage.uploadMeta?.contextId || !cacheImage.uploadMeta.jobKey) {
    console.error(`Invalid uploadMeta ${cacheImage.uploadMeta}`);
    return;
  }
  const payload = {
    msgType: RemoteRequestTypes.CREATE_UPLOAD,
    reqId: formatUploadReqId(cacheImage.localId),
    payload: {
      contextId: cacheImage.uploadMeta.contextId,
      filename: cacheImage.meta.filename,
      mimeType: cacheImage.meta.mimeType,
      sizeBytes: cacheImage.rawSizeBytes,
      encoding: 'base64',
      jobKey: cacheImage.uploadMeta.jobKey,
    },
  };
  const connected: boolean = yield select(getWebsocketConnected);

  // If the connection to websocket is not yet up, wait for it
  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* createDownload(cacheImage: CacheImage) {
  const payload = {
    msgType: RemoteRequestTypes.CREATE_DOWNLOAD,
    reqId: formatDownloadReqId(cacheImage.localId),
    payload: {
      resourceId: cacheImage.attachmentKey,
      encoding: 'base64',
    },
  };
  const connected: boolean = yield select(getWebsocketConnected);

  // If the connection to websocket is not yet up, wait for it
  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function getNextDataChunk(cacheImage: CacheImage): string {
  const startIndex: number = cacheImage.progressRawBytes / rawBase64Ratio;
  const actualData: string = actualDataFromDataUri(cacheImage.data);
  const remainingDataSize: number = actualData.length - (startIndex + dataChunkBase64SizeBytes);
  // NOTE(mikkogy,20201022) end may include padding which doesn't really work
  // out if send separately. It would also be very inefficient to send last few
  // bytes in a separate chunk.
  if (remainingDataSize > maxFinalChunkIncrease) {
    const endIndex: number = startIndex + dataChunkBase64SizeBytes;
    return actualData.substring(startIndex, endIndex);
  } else {
    return actualData.substring(startIndex);
  }
}

function* uploadImageData(cacheImage: CacheImage) {
  const currentContextOrderKey: string | undefined = yield select(getCurrentContextOrderKey);
  if (!currentContextOrderKey || currentContextOrderKey !== cacheImage.orderKey) {
    // NOTE(lindenlas,20230612) Image is no longer relevant to user so it's better not to use bandwith.
    // Most likely context has been destroyed so upload would not succeed anyway.
    return;
  }
  if (!cacheImage.transferId) {
    console.error('trying to upload data of image without transferId');
    return;
  }
  const payload = {
    msgType: RemoteRequestTypes.BLOB_DATA,
    reqId: RemoteRequestIds.BLOB_DATA,
    payload: {
      blobId: cacheImage.transferId,
      offsetBytes: cacheImage.progressRawBytes,
      data: getNextDataChunk(cacheImage),
    },
  };
  const connected: boolean = yield select(getWebsocketConnected);

  // If the connection to websocket is not yet up, wait for it
  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* downloadImageData(cacheImage: CacheImage) {
  const currentContextOrderKey: string | undefined = yield select(getCurrentContextOrderKey);
  if (!currentContextOrderKey || currentContextOrderKey !== cacheImage.orderKey) {
    // NOTE(lindenlas,20230612) Image is no longer relevant to user so it's better not to use bandwith.
    return;
  }
  if (!cacheImage.transferId) {
    console.error('trying to download data of image without transferId');
    return;
  }
  const maxRawBytes = dataChunkBase64SizeBytes * rawBase64Ratio;
  const remainingRawBytes = cacheImage.rawSizeBytes - cacheImage.progressRawBytes;
  let requestBytes = Math.min(maxRawBytes, remainingRawBytes);
  if (
    remainingRawBytes > requestBytes &&
    remainingRawBytes - maxFinalChunkIncrease < requestBytes
  ) {
    requestBytes = remainingRawBytes;
  }
  const payload = {
    msgType: RemoteRequestTypes.REQUEST_BLOB_DATA,
    reqId: RemoteRequestIds.REQUEST_BLOB_DATA,
    payload: {
      blobId: cacheImage.transferId,
      offsetBytes: cacheImage.progressRawBytes,
      sizeBytes: requestBytes,
    },
  };
  const connected: boolean = yield select(getWebsocketConnected);

  // If the connection to websocket is not yet up, wait for it
  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* blobSucceeded(transferId: string) {
  if (!transferId) {
    console.error('trying to send blob succeeded of image without transferId');
    return;
  }
  const payload = {
    msgType: RemoteRequestTypes.BLOB_SUCCEEDED,
    reqId: RemoteRequestIds.BLOB_SUCCEEDED,
    payload: {
      blobId: transferId,
    },
  };
  const connected: boolean = yield select(getWebsocketConnected);

  // If the connection to websocket is not yet up, wait for it
  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function updateCacheImageAccessTime(image: CacheImage) {
  image.accessTime = new Date().getTime();
}

interface CollectedImages {
  obsoleteOrderKeys: string[];
  toBeRemoved: CacheImage[];
}
function collectStoreImages(
  images: OrderImageMap,
  currentOrderKey: string | undefined,
): CollectedImages {
  const collected: CollectedImages = {
    obsoleteOrderKeys: [],
    toBeRemoved: [],
  };
  let storedImagesSize = 0;
  const relevantImages: CacheImage[] = [];

  function getTime(image: CacheImage) {
    return Math.max(image.accessTime, image.createTime);
  }

  for (const key in images) {
    const imageArray = images[key];
    if (!imageArray) {
      continue;
    }
    if (imageArray.length === 0) {
      collected.obsoleteOrderKeys.push(key);
      continue;
    }
    const timestamps = imageArray
      .filter((image: CacheImage) => image.orderKey !== currentOrderKey)
      .map(getTime)
      .sort((a: number, b: number) => a - b);
    if (timestamps[timestamps.length - 1] < new Date().getTime() - obsoleteMs) {
      collected.obsoleteOrderKeys.push(key);
      continue;
    }
    for (const image of imageArray) {
      storedImagesSize += image.rawSizeBytes;
      relevantImages.push(image);
    }
  }
  relevantImages.sort((a, b) => getTime(a) - getTime(b));
  relevantImages.forEach((image) => {
    if (image.orderKey === currentOrderKey) {
      return;
    }
    if (storedImagesSize < maxCacheImageStorageSize) {
      return;
    }
    storedImagesSize -= image.rawSizeBytes;
    collected.toBeRemoved.push(image);
  });
  return collected;
}

function* cleanupObsolete() {
  const currentContextOrderKey: string | undefined = yield select(getCurrentContextOrderKey);
  const imagesState: ImagesState = yield select(getImages);
  const images = imagesState.orderImages.images;
  const storeImages = collectStoreImages(images, currentContextOrderKey);
  if (storeImages.obsoleteOrderKeys.length > 0) {
    yield put(removeByOrderKeys(storeImages.obsoleteOrderKeys));
  }

  for (const image of storeImages.toBeRemoved) {
    console.log(
      `Local storage is full, cleaning up older cache image with key ${image.attachmentKey}`,
    );
    yield put(removeLocalImage(image.orderKey, image.attachmentKey));
  }
}

enum DownloadImageProcessingType {
  ContinueOld,
  CreateNew,
  FromWaitToDownload,
}
interface Download {
  image: CacheImage;
  processingType: DownloadImageProcessingType;
}
function findDownload(imageArray: CacheImage[]): Download | undefined {
  // NOTE(lindenlas,20230530) by doing only one download at a time we avoid
  // swamping Bridge app with download requests when opening an order that was
  // previously inspected on another device or cache was wiped.
  for (const image of imageArray) {
    if (image.type === CacheImageType.DOWNLOAD) {
      if (image.transferId) {
        if (image.accessTime + blobDataRetryIntervalMs < new Date().getTime()) {
          return {
            image,
            processingType: DownloadImageProcessingType.ContinueOld,
          };
        }
        return undefined;
      }
    }
  }
  for (const image of imageArray) {
    if (image.type === CacheImageType.DOWNLOAD) {
      if (!image.transferId) {
        if (image.accessTime + downloadRetryIntervalMs < new Date().getTime()) {
          return {
            image,
            processingType: DownloadImageProcessingType.CreateNew,
          };
        }
        return undefined;
      }
    }
  }
  for (const image of imageArray) {
    if (image.type === CacheImageType.WAIT_FOR_DOWNLOAD) {
      return {
        image,
        processingType: DownloadImageProcessingType.FromWaitToDownload,
      };
    }
  }
  return undefined;
}

enum UploadImageProcessingType {
  ContinueOld,
  CreateNew,
}
interface Upload {
  image: CacheImage;
  processingType: UploadImageProcessingType;
}
function findUpload(imageArray: CacheImage[]): Upload | undefined {
  for (const image of imageArray) {
    if (image.type === CacheImageType.UPLOAD) {
      if (image.transferId) {
        if (image.accessTime + blobDataRetryIntervalMs < new Date().getTime()) {
          return {
            image,
            processingType: UploadImageProcessingType.ContinueOld,
          };
        }
        return undefined;
      }
    }
  }
  for (const image of imageArray) {
    if (image.type === CacheImageType.UPLOAD) {
      if (!image.transferId) {
        if (image.accessTime + uploadRetryIntervalMs < new Date().getTime()) {
          return {
            image,
            processingType: UploadImageProcessingType.CreateNew,
          };
        }
        return undefined;
      }
    }
  }
  return undefined;
}

function* handleMaintenance() {
  yield call(cleanupObsolete);
  const user: User = yield select(getUser);
  if (!user || !user.isAuthed) {
    return;
  }
  const imageState: ImagesState = yield select(getImages);
  const currentContextOrderKey: string | undefined = yield select(getCurrentContextOrderKey);
  if (!currentContextOrderKey) {
    return;
  }
  const imageArrayMaybe: CacheImage[] | undefined =
    imageState.orderImages.images[currentContextOrderKey];
  if (!imageArrayMaybe || imageArrayMaybe.length === 0) {
    return;
  }
  const contextIdMaybe: string | undefined = yield select(getCurrentContextId);
  if (!contextIdMaybe) {
    return;
  }
  const contextId: string = contextIdMaybe;
  const imageArray: CacheImage[] = imageArrayMaybe;

  const uploadImage = findUpload(imageArray);
  if (uploadImage !== undefined) {
    const image = uploadImage.image;
    if (contextId === image.uploadMeta?.contextId) {
      updateCacheImageAccessTime(image);
      if (uploadImage.processingType === UploadImageProcessingType.ContinueOld) {
        yield put(saveToStore(image.orderKey, image));
        yield call(uploadImageData, image);
      }
      if (uploadImage.processingType === UploadImageProcessingType.CreateNew) {
        yield put(saveToStore(image.orderKey, image));
        yield call(createUpload, image);
      }
    }
  }

  const downloadImage = findDownload(imageArray);
  if (downloadImage !== undefined) {
    const image = downloadImage.image;
    updateCacheImageAccessTime(image);
    if (downloadImage.processingType === DownloadImageProcessingType.ContinueOld) {
      yield put(saveToStore(image.orderKey, image));
      yield call(downloadImageData, image);
    }
    if (downloadImage.processingType === DownloadImageProcessingType.CreateNew) {
      yield put(saveToStore(image.orderKey, image));
      yield call(createDownload, image);
    }
    if (downloadImage.processingType === DownloadImageProcessingType.FromWaitToDownload) {
      image.type = CacheImageType.DOWNLOAD;
      yield put(saveToStore(image.orderKey, image));
      yield call(createDownload, image);
    }
  }

  for (const i in imageArray) {
    const image = imageArray[i];
    if (image.type === CacheImageType.UPLOAD) {
      if (contextId !== image.uploadMeta?.contextId) {
        console.warn(
          `image to be uploaded found but no matching context, contextId ${contextId}`,
          image.uploadMeta,
        );
        yield put(removeUploadFailedImage(image));
      }
    }
  }

  // NOTE(lindenlas,20230612) As a result of creating new upload or download, localstorage
  // could get full, so it is essential to do cleanup also in the end of maintenance.
  // Otherwise we will get errors when processing a big image near max limit.
  yield call(cleanupObsolete);
}

function* findCacheImage(isCorrect: (cacheImage: CacheImage) => boolean) {
  const imageState: ImagesState = yield select(getImages);
  for (const orderKey in imageState.orderImages.images) {
    const imageArray: CacheImage[] | undefined = imageState.orderImages.images[orderKey];
    if (!imageArray) {
      return undefined;
    }
    for (const i in imageArray) {
      const image = imageArray[i];
      if (isCorrect(image)) {
        return image;
      }
    }
  }
  return undefined;
}

function getBlobFinder(action: ReturnType<typeof incomingEvent>) {
  return (cachedImage: CacheImage) => {
    return action.payload.payload.blobId === cachedImage.transferId;
  };
}

function* deleteRemovedImages(orderKey: string, attachments: Attachment[]) {
  const imageState: ImagesState = yield select(getImages);
  const images = imageState.orderImages.images;
  const imageArray: CacheImage[] | undefined = images[orderKey];
  if (!imageArray) {
    return undefined;
  }
  for (const i in imageArray) {
    const image = imageArray[i];
    // NOTE(mikkogy,20201112) uploads are not included in context status so they
    // must be ignored here.
    if (image.type !== CacheImageType.UPLOAD) {
      const jobAttachments = attachments.filter((a) => a.key === image.attachmentKey);
      if (jobAttachments.length === 0) {
        yield put(removeLocalImage(orderKey, image.attachmentKey));
      }
    }
  }
}

function* checkContextAttachments(status: { [key: string]: Context }) {
  let wasAttachmentAdded = false;
  const user: User = yield select(getUser);
  const userKey = user?.userData?.key;
  if (!userKey) return;
  const currentContextId: string | undefined = yield select(getCurrentContextId);
  for (const contextId in status) {
    const context = status[contextId];
    const jobs = context.weighingJobs;
    if (!jobs || jobs.length === 0) {
      continue;
    }

    const attachments: Attachment[] = [];

    for (const att in context.attachments) {
      const atts = context.attachments[att];
      attachments.push(atts);
    }

    for (const i in attachments) {
      const attachment = attachments[i];
      const find = (cachedImage: CacheImage) => {
        return attachment.key === cachedImage.attachmentKey;
      };
      const inCache: CacheImage | undefined = yield call(findCacheImage, find);
      const contextAttachment: Attachment | undefined = context.attachments?.[attachment.key];
      if (inCache) {
        if (
          contextAttachment &&
          JSON.stringify(inCache.meta) !== JSON.stringify(contextAttachment)
        ) {
          inCache.meta = contextAttachment;
          yield put(saveToStore(context.order.key, inCache));
        }
      } else {
        if (
          !hasUserOperatorRights(user) &&
          userKey !== contextAttachment?.bridgeSpecificPropOwnerKey
        ) {
          // NOTE(mikkogy,20210909) unauthorized download should be skipped as
          // it will not work anyway.
          continue;
        }
        if (currentContextId !== contextId) {
          // NOTE(mikkogy,20210909) skip downloading attachments not related to
          // current DRIVER/INSPECTOR context to avoid downloading useless
          // images. Otherwise photos taken by all users would be downloaded
          // when running UI as operator.
          // TODO(mikkogy,20210909) this is not sufficient for OPERATOR as shown
          // context is selected differently. Later on when photos are shown in
          // OPERATOR UI effective UI role needs to be checked.
          continue;
        }
        const createDate = new Date();
        const createTime = createDate.getTime();
        const contextAttachmentDescription = contextAttachment
          ? contextAttachment.description
          : undefined;
        const cacheImage: CacheImage = {
          data: `data:${attachment.mimeType};base64,`,
          type: CacheImageType.WAIT_FOR_DOWNLOAD,
          rawSizeBytes: attachment.sizeBytes,
          accessTime: 0,
          createTime,
          attachmentKey: attachment.key,
          transferId: '',
          progressRawBytes: 0,
          localId: attachment.key,
          orderKey: context.order.key,
          meta: {
            ...attachment,
            timestamp: contextAttachment?.timestamp || createTime,
            description: contextAttachmentDescription,
          },
          uploadMeta: undefined,
        };
        yield put(saveToStore(context.order.key, cacheImage));
        wasAttachmentAdded = true;
      }
    }
    yield call(deleteRemovedImages, context.order.key, attachments);
  }
  if (wasAttachmentAdded) {
    yield call(handleMaintenance);
  }
}

function* handleAdd(action: ReturnType<typeof addImage>) {
  const mimeType = mimeTypeFromDataUri(action.payload.data);
  const suffix = suffixFromDataUri(action.payload.data);
  const rawSizeBytes = rawSizeBytesFromDataUri(action.payload.data);
  const contextId: string | undefined = yield select(getCurrentContextId);
  if (!mimeType || !suffix || rawSizeBytes <= 0 || !contextId) {
    console.error(
      `invalid image add attempt to cache, mime ${mimeType}, suffix ${suffix},
       raw size ${rawSizeBytes}, contextId ${contextId}`,
    );
    return;
  }
  const createDate = new Date();
  const createTime = createDate.getTime();
  const cacheImage: CacheImage = {
    data: action.payload.data,
    type: CacheImageType.UPLOAD,
    rawSizeBytes: rawSizeBytes,
    accessTime: 0,
    createTime,
    attachmentKey: '',
    transferId: '',
    progressRawBytes: 0,
    localId: `${createTime}_${Math.random()}`,
    orderKey: action.payload.orderKey,
    meta: {
      filename: formatFilename(createDate, suffix),
      mimeType,
      description: '',
      timestamp: createTime,
    },
    uploadMeta: {
      contextId: contextId,
      jobKey: action.payload.jobKey,
    },
  };
  yield put(saveToStore(action.payload.orderKey, cacheImage));
  yield call(handleMaintenance);
}

function* handleAddImageToJob(action: ReturnType<typeof addImageToJob>) {
  yield put(setAddImageJobKey(action.payload));
  yield put(push(RoutePaths.PHOTO));
}

function* handleCurrentContextChanged() {
  yield call(checkContextAttachments, yield select(getContexts));
}

function* handleRemove(action: ReturnType<typeof removeImage>) {
  const payload = {
    msgType: RemoteRequestTypes.REMOVE_ATTACHMENT,
    reqId: RemoteRequestIds.REMOVE_ATTACHMENT,
    payload: {
      attachmentKey: action.payload.image.attachmentKey,
      contextId: action.payload.contextId,
      jobKey: action.payload.jobKey,
    },
  };
  const connected: boolean = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleBlobFailed(transfer?: CacheImage) {
  if (transfer) {
    transfer.transferId = '';
    if (transfer.type === CacheImageType.UPLOAD) {
      yield put(removeUploadFailedImage(transfer));
      return;
    } else if (transfer.type === CacheImageType.DOWNLOAD) {
      transfer.data = transfer.data.split(',')[0] + ',';
    }
    transfer.progressRawBytes = 0;
    updateCacheImageAccessTime(transfer);
    yield put(saveToStore(transfer.orderKey, transfer));
  } else {
    console.warn(`Received ${RemoteReceiveTypes.BLOB_FAILED} but no such image. \
This may happen when trying to create download and it fails. There is a serious \
issue with document handling but we try to keep UI operational.`);
  }
}

function* handleResponse(action: ReturnType<typeof incomingEvent>) {
  const msgType: RemoteReceiveTypes = action.payload.msgType;
  const payload = action.payload;
  const msgPayload = payload ? payload.payload : undefined;
  if (msgType === RemoteReceiveTypes.UPLOAD_CREATED) {
    const find = (cachedImage: CacheImage) => {
      return payload.respId === formatUploadReqId(cachedImage.localId);
    };
    const upload: CacheImage | undefined = yield call(findCacheImage, find);
    if (!upload) {
      console.error(`received ${RemoteReceiveTypes.UPLOAD_CREATED} but no such image`);
      return;
    }
    updateCacheImageAccessTime(upload);
    upload.attachmentKey = msgPayload.blobId;
    upload.transferId = msgPayload.blobId;
    yield put(saveToStore(upload.orderKey, upload));
    yield call(uploadImageData, upload);
  } else if (msgType === RemoteReceiveTypes.DOWNLOAD_CREATED) {
    const find = (cachedImage: CacheImage) => {
      return payload.respId === formatDownloadReqId(cachedImage.localId);
    };
    const download: CacheImage | undefined = yield call(findCacheImage, find);
    if (!download) {
      console.error(`received ${RemoteReceiveTypes.DOWNLOAD_CREATED} but no such image`);
      return;
    }
    updateCacheImageAccessTime(download);
    download.rawSizeBytes = msgPayload.sizeBytes;
    download.transferId = msgPayload.blobId;
    yield put(saveToStore(download.orderKey, download));
    yield call(downloadImageData, download);
  } else if (msgType === RemoteReceiveTypes.BLOB_FAILED) {
    const find = getBlobFinder(action);
    const transfer: CacheImage | undefined = yield call(findCacheImage, find);
    yield call(handleBlobFailed, transfer);
  } else if (msgType === RemoteReceiveTypes.BLOB_DATA_RECEIVED) {
    const find = getBlobFinder(action);
    const upload: CacheImage | undefined = yield call(findCacheImage, find);
    if (!upload) {
      console.error(`received ${RemoteReceiveTypes.BLOB_DATA_RECEIVED} but no such image`);
      return;
    }
    updateCacheImageAccessTime(upload);
    upload.progressRawBytes = msgPayload.bytesReceived;
    yield put(saveToStore(upload.orderKey, upload));
    yield call(uploadImageData, upload);
  } else if (msgType === RemoteReceiveTypes.BLOB_SUCCEEDED) {
    const find = getBlobFinder(action);
    const upload: CacheImage | undefined = yield call(findCacheImage, find);
    if (!upload) {
      console.error(`received ${RemoteReceiveTypes.BLOB_SUCCEEDED} but no such image`);
      return;
    }
    updateCacheImageAccessTime(upload);
    upload.progressRawBytes = 0;
    upload.type = CacheImageType.STORED;
    upload.localId = upload.attachmentKey;
    yield put(saveToStore(upload.orderKey, upload));
    yield call(handleMaintenance);
  } else if (msgType === RemoteReceiveTypes.BLOB_DATA) {
    const find = getBlobFinder(action);
    const download: CacheImage | undefined = yield call(findCacheImage, find);
    if (!download) {
      console.error(`received ${RemoteReceiveTypes.BLOB_DATA} but no such image`);
      return;
    }
    if (msgPayload.offsetBytes !== download.progressRawBytes) {
      console.error(
        'ignoring wrong received BLOB_DATA, current offset',
        download.progressRawBytes,
        ', blob offset: ',
        msgPayload.offsetBytes,
      );
      return;
    }
    updateCacheImageAccessTime(download);
    const data = msgPayload.data;
    const rawBytesReceived = data.length * rawBase64Ratio;
    download.progressRawBytes += rawBytesReceived;
    download.data += data;
    if (download.progressRawBytes >= download.rawSizeBytes) {
      download.type = CacheImageType.STORED;
      const transferId = download.transferId;
      download.transferId = '';
      yield put(saveToStore(download.orderKey, download));
      yield call(blobSucceeded, transferId);
      yield call(handleMaintenance);
    } else {
      if (download.data.endsWith('=')) {
        download.data = download.data.split(',')[0] + ',';
        download.progressRawBytes = 0;
        console.error(
          'received padding in download, should not have requested byte count that requires padding',
        );
        return;
      }
      yield put(saveToStore(download.orderKey, download));
    }
    if (download.progressRawBytes > 0 && download.progressRawBytes < download.rawSizeBytes) {
      yield call(downloadImageData, download);
    }
  } else if (msgType === RemoteReceiveTypes.CONTEXT_STATUS) {
    yield call(checkContextAttachments, msgPayload.status);
  }
}

function* handleSetImageDescription(action: ReturnType<typeof setImageDescription>) {
  const payload = {
    msgType: RemoteRequestTypes.UPDATE_ATTACHMENT_PROPERTIES,
    reqId: RemoteRequestIds.UPDATE_ATTACHMENT_PROPERTIES,
    payload: {
      attachmentKey: action.payload.attachmentKey,
      properties: {
        description: action.payload.description,
      },
    },
  };
  const connected: boolean = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleRemoveUploadFailed(action: ReturnType<typeof removeUploadFailedImage>) {
  yield put(newToast('images.uploadFailed'));
}

function* handleTimedMaintenance() {
  while (true) {
    try {
      const user: User = yield select(getUser);
      if (user && user.isAuthed) {
        yield call(handleMaintenance);
      }
      yield delay(10 * 1000);
    } finally {
    }
  }
}

function* watchAdd() {
  yield takeEvery(ImagesActionTypes.ADD, handleAdd);
}

function* watchAddImageToJob() {
  yield takeEvery(ImagesActionTypes.ADD_IMAGE_TO_JOB, handleAddImageToJob);
}

function* watchCurrentContextChanged() {
  yield takeEvery(OrdersActionTypes.CURRENT_CONTEXT_CHANGED, handleCurrentContextChanged);
}

function* watchRemove() {
  yield takeEvery(ImagesActionTypes.REMOVE, handleRemove);
}

function* watchResponse() {
  yield takeLatest(LiveDataTypes.INCOMING_EVENT, handleResponse);
}

function* watchSetImageDescription() {
  yield throttle(10000, ImagesActionTypes.SET_IMAGE_DESCRIPTION, handleSetImageDescription);
}

function* watchSetImageDescriptionImmediately() {
  yield takeLatest(ImagesActionTypes.SET_IMAGE_DESCRIPTION_IMMEDIATELY, handleSetImageDescription);
}

function* watchRemoveUploadFailed() {
  yield takeLatest(ImagesActionTypes.REMOVE_UPLOAD_FAILED_IMAGE, handleRemoveUploadFailed);
}

function* imagesSaga() {
  yield all([
    fork(watchAdd),
    fork(watchAddImageToJob),
    fork(watchCurrentContextChanged),
    fork(watchRemove),
    fork(handleTimedMaintenance),
    fork(watchResponse),
    fork(watchSetImageDescription),
    fork(watchSetImageDescriptionImmediately),
    fork(watchRemoveUploadFailed),
  ]);
}

// NOTE(mikkogy,20201029) for test access only.
export const internal = {
  checkContextAttachments,
  cleanupObsolete,
  createDownload,
  createUpload,
  deleteRemovedImages,
  downloadImageData,
  findCacheImage,
  handleBlobFailed,
  handleMaintenance,
  uploadImageData,
};

export default imagesSaga;
