import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';

import { connect, localeConnect } from 'localeConnect';
import { ApplicationState, ConnectedReduxProps } from 'store';
import { Context } from 'store/orders/types';
import { UiRoles } from 'store/user/types';
import { effectiveUiRole } from 'store/utils';
import { RoutePaths } from 'routes';

import EditOrder from '../EditOrder';
import { findContext as findInspectorContext } from './utils';

interface Props {
  currentContext: Context | undefined;
  history: any;
}

interface PropsFromDispatch {}

/*eslint-disable */
export type AllProps = PropsFromDispatch & Props & RouteComponentProps<{}> & ConnectedReduxProps;
/*eslint-enable */

function getCurrentContext(state: ApplicationState) {
  if (
    effectiveUiRole(
      state.user.selectedUiRole,
      state.user.user,
      state.currentScaleInfo.enabledFeatures,
    ) !== UiRoles.INSPECTOR
  ) {
    throw new Error('not inspector');
  }
  const inspectorContext = findInspectorContext(state);
  if (inspectorContext.context) {
    return inspectorContext.context;
  }
  return undefined;
}

const mapStateToProps = (state: ApplicationState) => ({
  currentContext: getCurrentContext(state),
});

const mapDispatchToProps = {};

type StateProps = Record<string, never>;

export class InspectorEditOrderComponent extends React.PureComponent<AllProps, StateProps> {
  public render() {
    const props = this.props;
    if (props.currentContext === undefined) {
      return <Redirect to={RoutePaths.ORDERS} />;
    }
    return <EditOrder currentContext={props.currentContext} history={props.history} />;
  }
}

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(InspectorEditOrderComponent);
