import React from 'react';
import { RouterState } from 'connected-react-router';
import { Redirect, RouteComponentProps } from 'react-router';
import { connect, localeConnect } from 'localeConnect';
import { defaultRoot } from 'theme';
import { createOrderDetailsModel } from 'models/orderdetails.model';
import { ApplicationState, ConnectedReduxProps } from 'store';
import {
  clearWeighingStartError,
  selectMeasDeviceGroup,
  startWeighing,
} from 'store/meas-devices/actions';
import { MeasDevice, MeasDeviceGroup, MeasDeviceState } from 'store/meas-devices/types';
import { getAcceptableGroupSizes } from 'store/meas-devices/utils';
import { Contexts } from 'store/orders/types';
import { UserDetails } from 'store/user/types';
import { RoutePaths } from 'routes';
import { scrollToTop } from 'components/utils';
import LoadingIndicator from 'components/LoadingIndicator';
import { jobsTrucksAndTrailers } from 'store/jobs/utils';
import { ContainerType } from 'store/common/types';
import SelectMeasDeviceGroup from './SelectMeasDeviceGroup';

interface PropsFromState extends MeasDeviceState {
  router: RouterState;
  measDevices: MeasDevice[];
  measDeviceGroups: MeasDeviceGroup[] | undefined;
  contexts: Contexts;
  currentContextId?: string;
  scaleKey: string;
  userDetails: UserDetails | undefined;
  trailerKey?: string;
}

interface PropsFromDispatch {
  clearWeighingStartError: typeof clearWeighingStartError;
  selectMeasDeviceGroup: typeof selectMeasDeviceGroup;
  startWeighing: typeof startWeighing;
}

/**
 * NOTE(mikkogy, 20190809) There's a bug in Eslint config that has been reported but not yet fixed
 * which complains about missing indentation when multiline binary expressions are used.
 * See: https://github.com/typescript-eslint/typescript-eslint/issues/398
 */
/*eslint-disable */
export type AllProps = PropsFromDispatch &
  PropsFromState &
  RouteComponentProps<{}> &
  ConnectedReduxProps;
/*eslint-enable */

const mapStateToProps = (state: ApplicationState) => ({
  loading: state.measDevices.loading,
  errors: state.measDevices.errors,
  hasWeighingStartError: state.measDevices.hasWeighingStartError,
  measDeviceGroups: state.measDevices.measDeviceGroups,
  measDevices: state.measDevices.measDevices,
  selectedGroupId: state.measDevices.selectedGroupId,
  contexts: state.orders.contexts,
  currentContextId: state.orders.currentContextId,
  scaleKey: state.currentScaleInfo.scaleKey,
  userDetails: state.user.user.userData,
  trailerKey: state.vehicles.trailerKey,
});

const mapDispatchToProps = {
  clearWeighingStartError,
  selectMeasDeviceGroup,
  startWeighing,
};

function measDeviceContent(props: AllProps, abortMeasDeviceSelection: any) {
  const contexts = props.contexts;
  const currentContextId = props.currentContextId;
  const currentContext = contexts && currentContextId ? contexts[currentContextId] : null;
  if (!currentContext) {
    console.error("No context in meas devices, cant's select");
    return <Redirect to={RoutePaths.ORDERS} />;
  }
  if (currentContext.measDeviceKeys && currentContext.measDeviceKeys.length > 0) {
    console.error("Can't open meas devices when already selected");
    return <Redirect to={RoutePaths.WEIGHING} />;
  }
  const jobs = currentContext.weighingJobs;
  if (!jobs) {
    console.error("No jobs in context, cant's select meas device");
    return <Redirect to={RoutePaths.ORDERS} />;
  }
  const acceptableGroupSizes = getAcceptableGroupSizes(currentContext, props.scaleKey);

  const orderDetailsModel = createOrderDetailsModel(
    currentContext,
    currentContext.order,
    currentContext.process,
    jobs,
    props.scaleKey,
    props.userDetails,
  );

  const selectedVehicleName = jobsTrucksAndTrailers(jobs)?.find(
    (container) => container.containerType === ContainerType.TRUCK,
  )?.name;

  if (!props.measDeviceGroups) return null;

  return (
    <SelectMeasDeviceGroup
      acceptableGroupSizes={acceptableGroupSizes}
      selectMeasDeviceGroup={(id: string) => {
        props.clearWeighingStartError();
        props.selectMeasDeviceGroup(id);
      }}
      measDeviceGroups={props.measDeviceGroups}
      selectedMeasDeviceGroupId={props.selectedGroupId}
      startWeighing={() => {
        props.clearWeighingStartError();
        props.startWeighing();
      }}
      abortMeasDeviceSelection={abortMeasDeviceSelection}
      isLoading={props.loading}
      hasWeighingStartError={props.hasWeighingStartError}
      isTrailerInUse={orderDetailsModel.isTrailerUsed}
      vehicleName={selectedVehicleName}
    />
  );
}

export class MeasDevicesComponent extends React.PureComponent<AllProps, Record<string, never>> {
  componentDidMount(): void {
    this.props.clearWeighingStartError();
    scrollToTop();
  }

  abortMeasDeviceSelection = () => {
    this.props.history.goBack();
  };

  public render() {
    if (!this.props.measDeviceGroups) return null;
    return (
      <div style={defaultRoot}>
        <LoadingIndicator isLoading={this.props.loading} />
        {measDeviceContent(this.props, this.abortMeasDeviceSelection)}
      </div>
    );
  }
}

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(MeasDevicesComponent);
