import { makeStyles } from '@mui/styles';
import { Button, Chip, Grid, Typography } from '@mui/material';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixtyTwoTone';
import CheckIcon from '@mui/icons-material/Check';
import { Context } from 'store/orders/types';

import { InspectionStatus } from 'store/jobs/types';
import { setInspectionStatus } from 'store/jobs/actions';
import { getContextMainJob, MainJobMultipartBehavior } from 'store/orders/utils';
import theme, { darkGreyColor, inspectionStatusColors } from 'theme';
import { formatUserDisplayName } from 'store/user/utils';

import { formatDateAndTime } from 'components/utils';
import { translate } from 'utils/translate';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inspectionStatusText: {
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    backgroundColor: inspectionStatusColors.ok,
  },
  inspectionStatusFailText: {
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    backgroundColor: inspectionStatusColors.fail,
  },
  approver: {
    '& .MuiTypography-caption': {
      fontWeight: 'bold',
      color: darkGreyColor,
      wordBreak: 'break-word',
    },
  },
});

interface ParameterProps {
  hasError: boolean;
  weighingContext: Context;
}

interface PropsFromDispatch {
  setInspectionStatus: typeof setInspectionStatus;
}

type AllProps = ParameterProps & PropsFromDispatch;

const InspectorInspectionStatus = (props: AllProps) => {
  const classes = useStyles();
  const context = props.weighingContext;
  function getMainJob() {
    return getContextMainJob(context, MainJobMultipartBehavior.FIRST);
  }
  const inspection = getMainJob()?.inspection;
  const inspectionUser = inspection ? context?.inspectionUsers?.[inspection.userKey] : undefined;

  const inspectionButton = (inspectionStatus: InspectionStatus | undefined) => {
    const isApproved = inspectionStatus === InspectionStatus.INSPECTED;
    const buttonIcon = isApproved ? <ThreeSixtyIcon /> : <CheckIcon />;
    const buttonText = isApproved
      ? translate('inspection.actions.undo')
      : translate('inspection.actions.approve');

    const handleInspectionButtonClick = () => {
      const mainJob = getMainJob();
      if (!context || !mainJob) {
        throw new Error('context must exist and contain job');
      }

      const statusAfterButtonClick = isApproved
        ? InspectionStatus.NOT_INSPECTED
        : InspectionStatus.INSPECTED;

      props.setInspectionStatus(context.contextId, statusAfterButtonClick, mainJob.key);
    };
    return (
      <Button color="primary" onClick={handleInspectionButtonClick} startIcon={buttonIcon}>
        {buttonText}
      </Button>
    );
  };

  return (
    <div>
      <Grid container className={classes.container}>
        <Grid item>{inspectionButton(inspection?.status)}</Grid>
        {props.hasError ? (
          <Grid item>
            <Chip
              className={classes.inspectionStatusFailText}
              label={translate(`inspection.needsAttentionLabel`)}
            />
          </Grid>
        ) : (
          inspection?.status === InspectionStatus.INSPECTED && (
            <Grid item>
              <Chip
                className={classes.inspectionStatusText}
                label={translate(`inspection.status.${inspection.status}`)}
              />
            </Grid>
          )
        )}
      </Grid>
      {inspection?.status === InspectionStatus.INSPECTED && (
        <div className={classes.approver}>
          <Typography align="right" component="div" variant="caption">
            {formatUserDisplayName(inspectionUser)}
          </Typography>
          <Typography align="right" component="div" variant="caption">
            {formatDateAndTime(inspection.timestamp)}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default InspectorInspectionStatus;
