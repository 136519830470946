import React from 'react';

import { List } from '@mui/material';

import { connect, localeConnect } from 'localeConnect';

import { ApplicationState } from 'store';

import { MasterDataItem, AllowedMasterDataTypes } from 'store/common/types';
import { getTranslatedAndSortedSchemaEntries } from 'store/master-data/utils';
import {
  editMasterData,
  editTypableMasterData,
  editTypableMasterDataImmediately,
} from 'store/orders/actions';
import { Context, PendingMasterDataUpdates } from 'store/orders/types';
import { getCurrentlySelectedMasterData, linkedTypes, requiredTypes } from 'store/orders/utils';

import { filterTypes, findTypesAndTitles, SchemaEntry } from 'store/scale-info/utils';

import { translate } from 'utils/translate';

import { SchemaListItem, TypableSchemaListItem } from './SchemaListItem';

interface ParameterProps {
  context: Context;
  componentId: string;
  excludedTypes: string[] | undefined;
  includedTypes: string[] | undefined;
  isEditingMasterDataAllowed: boolean;
}

interface PropsFromState {
  pendingMasterDataUpdates: PendingMasterDataUpdates;
  schema?: Record<string, unknown>;
}

interface PropsFromDispatch {
  editMasterData: typeof editMasterData;
  editTypableMasterData: typeof editTypableMasterData;
  editTypableMasterDataImmediately: typeof editTypableMasterDataImmediately;
}

const mapStateToProps = (state: ApplicationState) => ({
  pendingMasterDataUpdates: state.orders.pendingMasterDataUpdates,
  schema: state.currentScaleInfo.domainInfo.masterDataCombo.schema,
});

const mapDispatchToProps = {
  editMasterData,
  editTypableMasterData,
  editTypableMasterDataImmediately,
};

type AllProps = ParameterProps & PropsFromDispatch & PropsFromState;

const ComponentMasterData = (props: AllProps) => {
  const schemaEntries = props.schema ? findTypesAndTitles(props.schema) : [];
  const translatedAndSortedSchemaEntries = getTranslatedAndSortedSchemaEntries(schemaEntries);
  const types = filterTypes(
    translatedAndSortedSchemaEntries,
    props.includedTypes,
    props.excludedTypes,
  );

  const getNameForData = (item: MasterDataItem) => {
    return item.name;
  };

  const order = props.context.order;
  const findComponent = (componentId: string) => {
    if (!order.linkedData || order.linkedData.length === 0) {
      return undefined;
    }

    return order.components.find((c) => c.id === componentId);
  };

  const getCurrentSelectedItemName = (schemaEntry: SchemaEntry, componentId: string) => {
    const emptyReturn = translate('masterData.unknownItemName');

    const component = findComponent(componentId);
    if (!component || !component.dataLinks || !order.linkedData) {
      return emptyReturn;
    }

    const linked = getCurrentlySelectedMasterData(
      order.linkedData,
      component.dataLinks,
      schemaEntry.typeName,
      schemaEntry.subtype,
    );
    return !!linked ? getNameForData(linked) : emptyReturn;
  };

  const getCurrentSelectedItemData = (schemaEntry: SchemaEntry, componentId: string) => {
    const component = findComponent(componentId);
    if (!component || !component.dataLinks || !order.linkedData) {
      return undefined;
    }

    const linked = getCurrentlySelectedMasterData(
      order.linkedData,
      component.dataLinks,
      schemaEntry.typeName,
      schemaEntry.subtype,
    );
    return linked;
  };

  const selectType = (componentId: string, schemaEntry: SchemaEntry) => {
    props.editMasterData(
      componentId,
      { type: schemaEntry.typeName, subtype: schemaEntry.subtype },
      schemaEntry.title,
    );
  };

  function getComponentLinkedTypes(componentId: string) {
    return linkedTypes(props.context.order, componentId);
  }

  const componentLinkedTypes = getComponentLinkedTypes(props.componentId);

  const componentRequiredTypes = requiredTypes(props.context.order, props.componentId);

  return (
    <div>
      <List component={'ul'}>
        {types
          .filter((entry) => !entry.isTypable)
          .map((entry) => (
            <SchemaListItem
              key={entry.typeName}
              entry={entry}
              requiredTypes={componentRequiredTypes}
              linkedTypes={componentLinkedTypes}
              componentId={props.componentId}
              isEditingMasterDataAllowed={props.isEditingMasterDataAllowed}
              getCurrentSelectedItemName={getCurrentSelectedItemName}
              getCurrentSelectedItemData={getCurrentSelectedItemData}
              selectType={selectType}
              subtype={entry.subtype}
            />
          ))}
      </List>
      {types
        .filter((entry) => entry.isTypable)
        .map((entry) => (
          <TypableSchemaListItem
            order={order}
            key={entry.typeName}
            entry={entry}
            requiredTypes={componentRequiredTypes}
            linkedTypes={componentLinkedTypes}
            componentId={props.componentId}
            pendingMasterDataUpdates={props.pendingMasterDataUpdates}
            isEditingMasterDataAllowed={props.isEditingMasterDataAllowed}
            getCurrentSelectedItemName={getCurrentSelectedItemName}
            getCurrentSelectedItemData={getCurrentSelectedItemData}
            editTypableMasterData={(
              componentId: string,
              masterDataType: AllowedMasterDataTypes,
              typableValue: string,
              force: boolean,
            ) => {
              if (force) {
                props.editTypableMasterDataImmediately(
                  props.context.order.key,
                  componentId,
                  masterDataType,
                  typableValue,
                );
              } else {
                props.editTypableMasterData(
                  props.context.order.key,
                  componentId,
                  masterDataType,
                  typableValue,
                );
              }
            }}
            subtype={entry.subtype}
          />
        ))}
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentMasterData);
