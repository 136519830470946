import React, { useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  List,
  MenuItem,
  Popover,
  TextField,
} from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HistoryIcon from '@mui/icons-material/History';

import { GenericMasterData, VisibleMasterDataSearchResults } from 'store/master-data/types';
import { BridgeEnabledFeatures } from 'store/scale-info/types';

import theme, { darkGreyColor } from 'theme';

import { translate } from 'utils/translate';
import HighlightedText from '../HighlightedText';

const listItemVerticalPadding = 6;
const listItemContentHeight = 24;
const listItemHeight = listItemContentHeight + 2 * listItemVerticalPadding;
// NOTE(mikkogy,20200911) extra was figured out by trial and error. It is likely
// to break if list item style is changed.
const downScrollExtra = 16;

interface SearchResultProps {
  enabledFeatures: BridgeEnabledFeatures;
  isLastSelected: boolean;
  masterDataItem: GenericMasterData;
  searchTerm: string;
}

function SearchResult(props: SearchResultProps) {
  const classes = useStyles();
  const name = props.masterDataItem.name;
  const externalId = props.enabledFeatures.bridgeIsMasterDataExternalIdVisible
    ? props.masterDataItem.externalId
    : undefined;

  return (
    <div>
      <span>
        <span className={classes.historyIconContainer}>
          {props.isLastSelected && <HistoryIcon htmlColor={darkGreyColor} />}
        </span>
        <span className={classes.searchResultText}>
          <HighlightedText searchTerm={props.searchTerm} fullText={name} />
          {externalId && (
            <span>
              <span>&nbsp;[</span>
              <HighlightedText searchTerm={props.searchTerm} fullText={externalId} />
              <span>]</span>
            </span>
          )}
        </span>
      </span>
    </div>
  );
}

const requiredLabelStyles = {
  '& .Mui-required:not(.MuiFormLabel-filled)': {
    color: theme.palette.error.main,
  },
};

const useStyles = makeStyles({
  compactRoot: {
    display: 'inline',
  },
  dropDown: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  historyIconContainer: {
    marginRight: '16px',
    position: 'relative',
    width: '24px',
    height: '24px',
    display: 'inline-block',
    transform: 'translate(0px, 2px)',
  },
  filter: {
    // eslint-disable-next-line
    width: '100%',
    '& .MuiIconButton-root': {
      padding: '2px',
    },
  },
  listItem: {
    // NOTE(mikkogy,20211110) using !important to force size which we rely on
    // in scrolling.
    height: `${listItemHeight}px !important`,
    paddingTop: `${listItemVerticalPadding}px !important`,
    paddingBottom: `${listItemVerticalPadding}px !important`,
    width: '100%',
  },
  manyResultsText: {
    color: darkGreyColor,
    fontSize: '12px',
  },
  popover: {
    '& .MuiPopover-paper': {
      overflowY: 'hidden',
    },
  },
  popoverContent: {
    minWidth: '400px',
    maxHeight: 'calc(100vh - 90px)',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  textField: {
    '@media (min-width: 960px)': {
      minWidth: '400px',
    },
    '@media (max-width: 959px)': {
      minWidth: '100%',
    },
    ...requiredLabelStyles,
  },
  textFieldCompact: {
    minWidth: '100px',
    marginRight: '20px',
    ...requiredLabelStyles,
  },
  noneMasterDataItem: {
    fontStyle: 'italic',
  },
  searchResultText: {
    display: 'inline-block',
    maxWidth: 'calc(100vw - 105px)',
    textOverflow: 'ellipsis',
    transform: 'translate(0px, 2px)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});

export interface StyledGenericMasterData extends GenericMasterData {
  isNone?: boolean;
}

interface ParameterProps {
  componentId: string;
  enabledFeatures: BridgeEnabledFeatures;
  lastSelected: GenericMasterData[];
  masterData: StyledGenericMasterData[];
  onFilterChanged?: (filter: string) => void;
  onSelect: (item: GenericMasterData) => void;
  label: string;
  value?: GenericMasterData;
  disabled: boolean;
  disabledKeys: string[];
  useCompactStyle?: boolean;
  isRequired?: boolean;
  isSearching: boolean;
  isTypable: boolean;
  placeHolder?: string;
  updateTypableMasterData: (typableValue: string, force: boolean) => void;
  typableFieldShouldBeUpdated: boolean;
  searchMasterData: (text: string) => void;
  shouldBeFocused: boolean;
}

type AllProps = ParameterProps;

const OperatorMasterDataSelect = (props: AllProps) => {
  const classes = useStyles();
  const [shouldClearOnExit, setShouldClearOnExit] = React.useState(false);
  const [filterText, doSetFilterText] = React.useState('');

  const [anchorEl, doSetAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  function setAnchorEl(target: any) {
    props.searchMasterData(filterText);
    doSetAnchorEl(target);
  }

  const [scrollableListRef] = React.useState(React.createRef());

  function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (props.disabled) {
      return;
    }
    setAnchorEl(event.currentTarget as any);
  }
  const textFieldVisibleValue = props.value ? props.value.name : props.placeHolder || '';

  function handleChange(event: React.ChangeEvent): void {
    if (props.disabled) {
      return;
    }

    const currentTarget = event.currentTarget as any;

    setAnchorEl(currentTarget);
    const trimmed = currentTarget.value.trimEnd();
    // NOTE(mikkogy,20220225) text field will contain current visible value and
    // user can start just typing. We don't want the visible value as search
    // text because there's no point to search the same thing that is already
    // selected.
    const textFieldValue = trimmed.replace(textFieldVisibleValue, '');
    // NOTE(mikkogy,20220225) we may have an existing relevant filter. By
    // appending user can continue typing or use space to open search without
    // clearing the previous filter.
    setFilterText((filterText + textFieldValue).trim());
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function setFilterText(text: string) {
    doSetFilterText(text.trimStart());
    props.searchMasterData(text.trim());
  }

  const masterData = props.masterData;

  function setKeySelectionIndex(index: number) {
    const scrollHeight = index * listItemHeight;
    const current = (scrollableListRef as any).current;
    if (current) {
      if (current.scrollTop > scrollHeight) {
        current.scrollTo(0, scrollHeight);
      } else if (current.scrollTop + current.clientHeight < scrollHeight + listItemHeight) {
        current.scrollTo(0, scrollHeight - current.clientHeight + listItemHeight + downScrollExtra);
      }
    }
    setKeySelectionIndexDo(index);
  }

  const updateKeySelectionIndex = () => {
    setKeySelectionIndex(findKeySelectionIndex());
  };

  function updateFilter(filter: string) {
    // NOTE(mikkogy,20200910) reset key selection so if currently selected does
    // not match we still have a valid initial value.
    setKeySelectionIndex(0);
    setFilterText(filter);
    if (props.onFilterChanged) {
      props.onFilterChanged(filter);
    }
    updateKeySelectionIndex();
  }

  function clearFilterDelayed() {
    setShouldClearOnExit(true);
  }

  function handleKeyDown(e: React.KeyboardEvent) {
    const data = listMasterData;
    if (e.key === 'ArrowUp') {
      const nextKey = keySelectionIndex > 0 ? keySelectionIndex - 1 : 0;
      setKeySelectionIndex(nextKey);
    } else if (e.key === 'ArrowDown') {
      const nextKey = keySelectionIndex < data.length - 1 ? keySelectionIndex + 1 : data.length - 1;
      setKeySelectionIndex(nextKey);
    } else if (e.key === 'Enter') {
      if (data.length > 0) {
        const selectedItem = data[keySelectionIndex];
        if (!selectedItem) return;
        props.onSelect(selectedItem);
        handleClose();
        clearFilterDelayed();
      }
    } else if (e.key === 'Escape') {
      handleClose();
    }
  }

  function handleItemClick(item: GenericMasterData) {
    props.onSelect(item);
    handleClose();
    clearFilterDelayed();
  }
  const [beingEdited, setBeingEdited] = React.useState(false);
  const currentValueKey = props.value?.key ?? '';
  const [initialKey, setInitialKey] = React.useState(currentValueKey);
  const currentValueName = props.value?.name ?? '';
  const [typableValue, setTypableValue] = React.useState(currentValueName);
  const [componentId, setComponentId] = React.useState(props.componentId);

  useEffect(() => {
    if (
      (!beingEdited && (props.typableFieldShouldBeUpdated || currentValueKey !== initialKey)) ||
      componentId !== props.componentId
    ) {
      setTypableValue(currentValueName);
      setInitialKey(currentValueKey);
      setComponentId(props.componentId);
    }
  }, [
    beingEdited,
    props.masterData,
    props.typableFieldShouldBeUpdated,
    initialKey,
    currentValueKey,
    currentValueName,
    props.componentId,
    componentId,
  ]);

  const maxResultCount = VisibleMasterDataSearchResults;
  const lastSelected = props.lastSelected;
  const lastSelectedKeys = lastSelected.map((item) => item.key);

  function getHistory() {
    if (filterText) return [];
    return lastSelected;
  }

  function getHistoryFilteredResults() {
    const withoutNone = masterData.filter((item) => !item.isNone);
    if (filterText) return withoutNone;
    return withoutNone.filter((result) => !lastSelectedKeys.includes(result.key));
  }

  function getNoneList() {
    return masterData.filter((item) => item.isNone);
  }

  const listMasterData = [
    ...getNoneList(),
    ...getHistory(),
    ...getHistoryFilteredResults(),
  ] as StyledGenericMasterData[];

  function findKeySelectionIndex() {
    let result = 0;
    listMasterData.forEach((item: GenericMasterData, index) => {
      if (props.value && props.value.key === item.key) {
        result = index;
      }
    });
    return result;
  }

  const [keySelectionIndex, setKeySelectionIndexDo] = React.useState(findKeySelectionIndex());

  function isItemDisabled(item: StyledGenericMasterData): boolean {
    if (!props.disabledKeys) return false;
    return props.disabledKeys.find((key: string) => key === item.key) !== undefined;
  }

  return (
    <div className={props.useCompactStyle ? classes.compactRoot : ''}>
      {props.isTypable ? (
        <div>
          <TextField
            variant="standard"
            label={props.label}
            disabled={props.disabled}
            className={props.useCompactStyle ? classes.textFieldCompact : classes.textField}
            value={typableValue}
            onBlur={() => {
              setBeingEdited(false);
              props.updateTypableMasterData(typableValue, true);
            }}
            onChange={(event: any) => {
              const value = event.target.value;
              props.updateTypableMasterData(value, false);
              setTypableValue(value);
            }}
            onFocus={() => setBeingEdited(true)}
          />
        </div>
      ) : (
        <div>
          <TextField
            variant="standard"
            required={!!props.isRequired}
            autoFocus={!!props.shouldBeFocused}
            onChange={handleChange}
            onClick={handleClick}
            className={props.useCompactStyle ? classes.textFieldCompact : classes.textField}
            disabled={props.disabled}
            value={textFieldVisibleValue}
            label={props.label}
            multiline={!props.useCompactStyle}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ArrowDropDownIcon className={classes.dropDown} />
                </InputAdornment>
              ),
            }}
          />
          <Popover
            id="masterDataPopover"
            className={classes.popover}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            tabIndex={-1}
            onKeyDown={handleKeyDown}
            onBlur={(e) => {
              // NOTE(mikkogy,20200911) we do not seem to be able to catch Esc press
              // from Popover and it appears we can observe if it loses focus to a
              // child (relatedTarget) or elsewhere. In the latter case we can close
              // the popover.
              if (!e.nativeEvent.relatedTarget) {
                handleClose();
              }
            }}
            TransitionProps={{
              onEnter: () => {
                updateKeySelectionIndex();
              },
              onExited: () => {
                if (shouldClearOnExit) {
                  setShouldClearOnExit(false);
                  setFilterText('');
                  updateKeySelectionIndex();
                }
              },
            }}
          >
            <MenuItem>
              <TextField
                variant="standard"
                autoFocus={true}
                className={classes.filter}
                key="filter"
                value={filterText}
                label={translate('search.label')}
                onChange={(e) => updateFilter(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {props.isSearching && <CircularProgress size={20} />}
                      <IconButton onClick={() => updateFilter('')}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MenuItem>
            {masterData.length > maxResultCount && (
              <div className={`MuiListItem-gutters ${classes.manyResultsText}`}>
                {translate('search.manyResults', { count: maxResultCount })}
              </div>
            )}
            <List
              component={'ul'}
              className={classes.popoverContent}
              role="listbox"
              tabIndex={-1}
              ref={scrollableListRef as any}
            >
              {listMasterData.map((entry: StyledGenericMasterData, index) => (
                <MenuItem
                  className={`${index === keySelectionIndex ? 'Mui-focusVisible' : ''} ${
                    classes.listItem
                  }`}
                  disabled={isItemDisabled(entry)}
                  key={entry.key}
                  value={entry.key}
                  onClick={() => handleItemClick(entry)}
                  role="option"
                  selected={props.value && props.value.key === entry.key}
                  tabIndex={props.value && props.value.key === entry.key ? 0 : -1}
                >
                  <span className={entry.isNone ? classes.noneMasterDataItem : ''}>
                    <SearchResult
                      enabledFeatures={props.enabledFeatures}
                      isLastSelected={lastSelectedKeys.includes(entry.key)}
                      masterDataItem={entry}
                      searchTerm={entry.isNone ? '' : filterText}
                    />
                  </span>
                </MenuItem>
              ))}
            </List>
            {listMasterData.length === 0 && (
              <MenuItem disabled={true}>{translate('search.noResults')}</MenuItem>
            )}
          </Popover>
        </div>
      )}
    </div>
  );
};

export default OperatorMasterDataSelect;
