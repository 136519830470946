/* eslint-disable */
function MassConverter() {
  this.isMassUnitSupported = isMassUnitSupported;

  this.convert = function(value, fromUnit, toUnit) {
    if ("number" !== typeof value) {
      throw "value must be a number";
    }
    if (undefined === fromUnit) fromUnit = "KG";
    if (undefined === toUnit) toUnit = "KG";
    validateUnit(fromUnit);
    validateUnit(toUnit);
    return value / fromKg[fromUnit] * fromKg[toUnit];
  };

  var fromKg = {
    KG: 1,
    METRIC_TON: 0.001,
    LONG_TON: 0.00098421,
    SHORT_TON: 0.0011023,
    LB: 2.2046
  };

  function isMassUnitSupported(massUnit) {
    if ("string" !== typeof massUnit) return false;
    return fromKg[massUnit] !== undefined;
  }

  function validateUnit(unit) {
    if (!isMassUnitSupported(unit)) {
      throw "mass unit " + unit + " is not supported";
    }
  }
}

module.exports = MassConverter;
