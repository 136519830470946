import React, { useEffect } from 'react';
import { Route, Switch, Redirect, NavLink } from 'react-router-dom';

import { Button, CircularProgress, IconButton, Paper, Snackbar, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

import { connect, localeConnect } from '../localeConnect';
import { SupportedLanguage } from '../models/languages.model';
import { ApplicationState } from '../store';
import { RoutePaths } from '../routes';
import { hideToast } from '../store/common/actions';
import { Toast, ToastType } from '../store/common/types';
import { BridgeEnabledFeatures } from '../store/scale-info/types';
import { fetchRequest, selectLocale } from '../store/user/actions';
import { UiRoles, User } from '../store/user/types';
import { effectiveUiRole } from '../store/utils';
import theme from '../theme';
import { translate } from '../utils/translate';
import DeepLink from './DeepLink';
import Login from './Login';
import Sites from './Sites';
import DriverEditOrder from './mobile/driver/DriverEditOrder';
import DriverOrders from './mobile/driver/DriverOrders';
import JobHistory from './mobile/driver/JobHistory';
import MeasDevices from './mobile/driver/MeasDevices';
import Receipt from './mobile/driver/Receipt';
import Vehicles from './mobile/driver/Vehicles';
import Weighing from './mobile/driver/Weighing';
import InspectorEditOrder from './mobile/inspector/InspectorEditOrder';
import InspectorOrders from './mobile/inspector/InspectorOrders';
import EditDeductions from './mobile/inspector/EditDeductions';
import OperatorDashboard from './operator/OperatorDashboard';
import OperatorGenericContainers from './operator/OperatorGenericContainers';
import OperatorJobsWrapper from './operator/OperatorJobsWrapper';
import OperatorOrder from './operator/OperatorOrder';
import OperatorOrders from './operator/OperatorOrders';
import CommonAddPhoto from './mobile/CommonAddPhoto';

interface SnackbarProps {
  onClose: () => void;
  isOpen: boolean;
  messageKey: string;
  toastAutoHideMs: number;
  type: ToastType;
}

const MessageSnackbar = (props: SnackbarProps) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      className={'App-print-hide'}
      open={props.isOpen}
      autoHideDuration={props.toastAutoHideMs}
      onClose={props.onClose}
      message={props.messageKey ? translate(props.messageKey) : ''}
    >
      {props.type !== ToastType.DEFAULT ? (
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={props.type}
          action={
            <>
              <IconButton size="small" aria-label="close" color="inherit" onClick={props.onClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        >
          {props.messageKey ? translate(props.messageKey) : ''}
        </MuiAlert>
      ) : undefined}
    </Snackbar>
  );
};

const useStyles = makeStyles({
  infoText: {
    display: 'inline',
  },
  progress: {
    color: theme.palette.error.main,
    marginLeft: '10px',
  },
  progressWrapper: {
    transform: 'translateY(5px)',
  },
  siteErrorContainer: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    maxWidth: '70vw',
    padding: '10px',
    backgroundColor: '#efefef',
    color: theme.palette.error.main,
  },
});

interface PrivateRoute {
  path: RoutePaths;
  component: React.ComponentType<any>;
}

interface PrivateRoutesProps {
  isLoggedIn: boolean;
  isSiteSelected: boolean;
  routes: PrivateRoute[];
}

const privateRoutes = (props: PrivateRoutesProps): JSX.Element[] => {
  if (!props.isLoggedIn) {
    return [<Redirect key={RoutePaths.LOGIN} to={RoutePaths.LOGIN} />];
  }

  // NOTE(lindenlas,20230330) User must have site selected when navigating to private routes.
  // If user is logged in, but tries to navigate to view that requires selected site,
  // we always want to redirect the user back to site selection.
  if (!props.isSiteSelected) {
    return [<Redirect key={RoutePaths.SITES} to={RoutePaths.SITES} />];
  }

  return props.routes.map((privateRoute) => (
    <Route
      key={privateRoute.path}
      exact
      path={privateRoute.path}
      component={privateRoute.component}
    />
  ));
};

interface PropsFromState {
  enabledFeatures: BridgeEnabledFeatures;
  isAuthed: boolean;
  isDeepLinkViewVisible: boolean;
  selectedUiRole: UiRoles;
  user: User;
  isLocaleApplied: boolean;
  isReceivingData: boolean;
  isSiteSelected: boolean;
  isVersionMismatchShown: boolean;
  currentScaleName: string;
  toast: Toast;
}

interface PropsFromDispatch {
  fetchRequest: typeof fetchRequest;
  hideToast: typeof hideToast;
  selectLocale: typeof selectLocale;
}

export type AllProps = PropsFromState & PropsFromDispatch;

const mapStateToProps = ({
  currentScaleInfo,
  common,
  deepLink,
  sites,
  user,
}: ApplicationState) => ({
  enabledFeatures: currentScaleInfo.enabledFeatures,
  isAuthed: user.user.isAuthed,
  isDeepLinkViewVisible: deepLink.isDeepLinkViewVisible,
  isReceivingData: common.isReceivingData,
  isSiteSelected: !!sites.selectedKey,
  isVersionMismatchShown: currentScaleInfo.isVersionMismatchShown,
  user: user.user,
  isLocaleApplied: user.isLocaleApplied,
  selectedUiRole: user.selectedUiRole,
  currentScaleName: currentScaleInfo.scaleName,
  toast: common.toast,
});

const mapDispatchToProps = {
  fetchRequest,
  hideToast,
  selectLocale,
};

export const Routes = (props: AllProps) => {
  const classes = useStyles();

  const { isAuthed, isSiteSelected, fetchRequest } = props;
  useEffect(() => {
    if (isAuthed) {
      fetchRequest();
    }
  }, [isAuthed, fetchRequest]);

  if (!props.isLocaleApplied) {
    return null;
  }
  const uiRole = effectiveUiRole(props.selectedUiRole, props.user, props.enabledFeatures);
  const onClose = props.hideToast;
  const isNaviEnabled = process.env.REACT_APP_SHOW_NAVI === 'true' && uiRole !== UiRoles.OPERATOR;
  const toastAutoHideMsStr = `${process.env.REACT_APP_TOAST_AUTOHIDE_MS || '10000'}`;
  const toastAutoHideMs = parseInt(toastAutoHideMsStr);
  const isDeepLinkViewVisible = props.isDeepLinkViewVisible;

  const handleLanguageChange = (lang: string) => {
    props.selectLocale(lang);
  };

  const siteConnectionErrorMessage = (scaleName: string | undefined) => {
    if (!scaleName) {
      return translate('errorSiteConnection');
    }
    return translate('errorNamedSiteConnection', { scaleName });
  };

  function driverRoutes() {
    const routes: PrivateRoute[] = [
      {
        path: RoutePaths.VEHICLES,
        component: Vehicles,
      },
      {
        path: RoutePaths.ORDERS,
        component: DriverOrders,
      },
      {
        path: RoutePaths.WEIGHING,
        component: Weighing,
      },
      {
        path: RoutePaths.MEAS_DEVICES,
        component: MeasDevices,
      },
      {
        path: RoutePaths.RECEIPT,
        component: Receipt,
      },
      {
        path: RoutePaths.EDIT_ORDER,
        component: DriverEditOrder,
      },
      {
        path: RoutePaths.RECEIPT_HISTORY,
        component: JobHistory,
      },
      {
        path: RoutePaths.PHOTO,
        component: CommonAddPhoto,
      },
    ];
    return routes;
  }

  function inspectorRoutes() {
    const routes: PrivateRoute[] = [
      {
        path: RoutePaths.ORDERS,
        component: InspectorOrders,
      },
      {
        path: RoutePaths.EDIT_DEDUCTIONS,
        component: EditDeductions,
      },
      {
        path: RoutePaths.EDIT_ORDER,
        component: InspectorEditOrder,
      },
      {
        path: RoutePaths.PHOTO,
        component: CommonAddPhoto,
      },
    ];
    return routes;
  }

  function operatorRoutes() {
    const routes: PrivateRoute[] = [
      {
        path: RoutePaths.DASHBOARD,
        component: OperatorDashboard,
      },
      {
        path: RoutePaths.OPERATOR_ORDER,
        component: OperatorOrder,
      },
      {
        path: RoutePaths.OPERATOR_JOBS,
        component: OperatorJobsWrapper,
      },
      {
        path: RoutePaths.OPERATOR_ORDERS,
        component: OperatorOrders,
      },
    ];
    if (props.enabledFeatures.bridgeSecondaryContainerTareDeduction) {
      routes.push({
        path: RoutePaths.OPERATOR_GENERIC_CONTAINERS,
        component: OperatorGenericContainers,
      });
    }
    return routes;
  }

  return (
    <div>
      {isNaviEnabled && (
        <nav className="main-nav">
          <ul>
            <li>
              <NavLink to={RoutePaths.VEHICLES}>Vehicles</NavLink>
            </li>
            <li>
              <NavLink to={RoutePaths.ORDERS}>Orders</NavLink>
            </li>
            <li>
              <NavLink to={RoutePaths.WEIGHING}>Weighing</NavLink>
            </li>
            <li>
              <NavLink to={RoutePaths.MEAS_DEVICES}>Meas devices</NavLink>
            </li>
            <li>
              <NavLink to={RoutePaths.RECEIPT}>Receipt</NavLink>
            </li>
            <li>
              <NavLink to={RoutePaths.LOGIN}>Start</NavLink>
            </li>
            <li>
              <NavLink to={RoutePaths.RECEIPT_HISTORY}>Receipt history</NavLink>
            </li>
            <li>
              <NavLink to={RoutePaths.SITES}>Sites</NavLink>
            </li>
          </ul>
          <div>
            {Object.values(SupportedLanguage).map((language) => (
              <Button key={language} onClick={(e) => handleLanguageChange(language)}>
                {translate('languageSelection', { lng: language })}
              </Button>
            ))}
          </div>
        </nav>
      )}
      {isDeepLinkViewVisible && (
        <Switch>
          <Route exact path={`${RoutePaths.DEEP_LINK}/:linkId`} component={DeepLink} />
          <Route exact path={RoutePaths.DEEP_LINK} component={DeepLink} />
          <Redirect to={RoutePaths.DEEP_LINK} />
        </Switch>
      )}
      {!isDeepLinkViewVisible && uiRole === UiRoles.DRIVER && (
        <Switch>
          <Route exact path="/">
            <Redirect to={RoutePaths.LOGIN} />
          </Route>
          <Route exact path={RoutePaths.LOGIN} component={Login} />
          <Route exact path={`${RoutePaths.DEEP_LINK}/:linkId`} component={DeepLink} />
          {isAuthed && !isSiteSelected && <Route exact path={RoutePaths.SITES} component={Sites} />}
          {privateRoutes({
            isLoggedIn: isAuthed,
            isSiteSelected: isSiteSelected,
            routes: driverRoutes(),
          })}
          <Redirect to={RoutePaths.LOGIN} />
        </Switch>
      )}
      {!isDeepLinkViewVisible && uiRole === UiRoles.INSPECTOR && (
        <Switch>
          <Route exact path="/">
            <Redirect to={RoutePaths.LOGIN} />
          </Route>
          <Route exact path={RoutePaths.LOGIN} component={Login} />
          <Route exact path={`${RoutePaths.DEEP_LINK}/:linkId`} component={DeepLink} />
          {isAuthed && !isSiteSelected && <Route exact path={RoutePaths.SITES} component={Sites} />}
          {privateRoutes({
            isLoggedIn: isAuthed,
            isSiteSelected: isSiteSelected,
            routes: inspectorRoutes(),
          })}
          <Redirect to={RoutePaths.LOGIN} />
        </Switch>
      )}
      {!isDeepLinkViewVisible && uiRole === UiRoles.OPERATOR && (
        <Switch>
          <Route exact path="/">
            <Redirect to={RoutePaths.LOGIN} />
          </Route>
          <Route exact path={RoutePaths.LOGIN} component={Login} />
          <Route exact path={`${RoutePaths.DEEP_LINK}/:linkId`} component={DeepLink} />
          {isAuthed && !isSiteSelected && <Route path={RoutePaths.SITES} component={Sites} />}
          {privateRoutes({
            isLoggedIn: isAuthed,
            isSiteSelected: isSiteSelected,
            routes: operatorRoutes(),
          })}
          <Redirect to={RoutePaths.LOGIN} />
        </Switch>
      )}
      {!props.isReceivingData && props.isSiteSelected && (
        <Paper className={`App-print-hide ${classes.siteErrorContainer}`}>
          <Typography variant="body1" className={classes.infoText}>
            {siteConnectionErrorMessage(props.currentScaleName)}
          </Typography>
          <CircularProgress className={classes.progress} size={16} />
        </Paper>
      )}
      {props.isReceivingData && props.isVersionMismatchShown && (
        <Paper className={`App-print-hide ${classes.siteErrorContainer}`}>
          <Typography variant="body1" className={classes.infoText}>
            {translate('errorVersionMismatch')}
          </Typography>
        </Paper>
      )}
      <MessageSnackbar
        type={props.toast.type}
        onClose={onClose}
        isOpen={props.toast.isOpen}
        toastAutoHideMs={toastAutoHideMs}
        messageKey={props.toast.messageKey}
      />
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
const RoutesComponent = localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(Routes);

export { RoutesComponent };
