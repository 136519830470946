import React from 'react';

import { Button } from '@mui/material';

import CommentIcon from '@mui/icons-material/Comment';

import { joinUniqueMasterDataItemNames } from 'store/common/utils';
import { Job, JobStatus } from 'store/jobs/types';
import { jobsTrucksAndTrailers } from 'store/jobs/utils';
import { MeasDevice } from 'store/meas-devices/types';
import { showOrderInfo as showOrderInfoAction } from 'store/orders/actions';
import { OperatorOrderMode, OperatorOrderTab } from 'store/orders/types';
import { parseDocumentKey } from 'store/utils';

import { RoutePaths } from 'routes';
import { operatorColors } from 'theme';
import { translate } from 'utils/translate';
import { formatDateAndTime, formatMass } from '../utils';

import LongTextDialog from '../LongTextDialog';
import RegisterNumber from '../RegisterNumber';
import { OperatorTableMenu, OperatorTableMenuItem } from './OperatorTable';

export const OperatorJobTableStyles = {
  iconButton: {
    color: operatorColors.secondary,
  },
  mass: {
    fontWeight: 'bold' as const,
  },
  menuLink: {
    color: 'initial',
    fontWeight: 'normal' as const,
    textDecoration: 'none',
  },
};

function registerNumbers(job: Job) {
  return (
    <div>
      {jobsTrucksAndTrailers([job]).map((item) => (
        <RegisterNumber key={item.key} text={item.name} />
      ))}
    </div>
  );
}

function joinedLinkedNames(job: Job, docType: string) {
  return joinUniqueMasterDataItemNames(job.linked, docType);
}

function findMeasDeviceNames(measDevices: MeasDevice[], job: Job) {
  const names = job.measDeviceTools
    .map((tool) => {
      try {
        const keyParts = parseDocumentKey(tool.toolKey);
        const measDeviceKey = `${keyParts.organizationId}::${keyParts.scope}`;
        const deviceList = measDevices.filter((device) => device.key === measDeviceKey);
        return deviceList.length > 0 ? deviceList[0].name : '';
      } catch (err) {
        console.error(err);
        return '';
      }
    })
    .map((name) => (name ? name : translate('operatorJobsTable.unknownMeasDevice')));
  return names.length > 0 ? names.join(', ') : '';
}

export const OperatorJobTableColumns = (
  anchorEl: { [key: string]: Element },
  isNoteOpen: { [key: string]: boolean },
  handleClick: (event: React.MouseEvent<HTMLButtonElement>, key: string) => void,
  handleClose: () => void,
  showOrderInfo: typeof showOrderInfoAction,
) => {
  return {
    timestamp: {
      key: 'timestamp',
      translationKey: 'operatorJobsTable.timestamp',
      dataGetter: (item: Job) => {
        return formatDateAndTime(item.timestamp);
      },
      useLongTextStyle: false,
    },
    status: {
      key: 'status',
      translationKey: 'operatorJobsTable.status',
      dataGetter: (item: Job) => translate(`operatorJobsTable.statuses.${item.status}`),
      useLongTextStyle: false,
    },
    receipt: {
      key: 'receipt',
      translationKey: 'operatorJobsTable.receiptNumber',
      dataGetter: (item: Job) => item.receiptNumber,
      useLongTextStyle: false,
    },
    registerNumber: {
      key: 'registerNumber',
      translationKey: 'operatorJobsTable.registerNumber',
      dataGetter: (item: Job) => registerNumbers(item),
      useLongTextStyle: false,
    },
    customer: {
      key: 'customer',
      translationKey: 'operatorJobsTable.customer',
      dataGetter: (item: Job) => joinedLinkedNames(item, 'CUSTOMER'),
      useLongTextStyle: true,
    },
    transportCo: {
      key: 'transportCo',
      translationKey: 'operatorJobsTable.transportCo',
      dataGetter: (item: Job) => joinedLinkedNames(item, 'TRANSPORT_CO'),
      useLongTextStyle: true,
    },
    material: {
      key: 'material',
      translationKey: 'operatorJobsTable.material',
      dataGetter: (item: Job) => joinedLinkedNames(item, 'MATERIAL'),
      useLongTextStyle: true,
    },
    orderNumber: (names: { [key: string]: string }) => {
      return {
        key: 'orderNumber',
        translationKey: 'operatorJobsTable.orderNumber',
        dataGetter: (item: Job) => {
          const orderInfo = item.order;
          if (orderInfo) {
            const orderKey = orderInfo.key;
            const name = names[orderKey];
            return name ? name : '';
          }
          return '';
        },
        useLongTextStyle: true,
      };
    },
    note: (
      iconButtonClass: string,
      handleNoteClickOpen: (key: string) => void,
      handleNoteClose: () => void,
    ) => {
      return {
        key: 'note',
        translationKey: 'operatorJobsTable.note',
        dataGetter: (item: Job) =>
          item.comment ? (
            <div>
              <Button onClick={() => handleNoteClickOpen(item.key)}>
                <CommentIcon className={iconButtonClass} />
              </Button>
              <LongTextDialog
                title={translate('operatorJobsTable.note')}
                textContent={item.comment}
                open={!!isNoteOpen[item.key]}
                onClose={handleNoteClose}
              />
            </div>
          ) : (
            ''
          ),
        useLongTextStyle: false,
      };
    },
    sumMass: (className: string) => {
      return {
        key: 'sumMass',
        translationKey: 'operatorJobsTable.sumMassKg',
        dataGetter: (item: Job) => <div className={className}>{formatMass(item.sumMassKg)}</div>,
        useLongTextStyle: false,
      };
    },
    measDevice: (measDevices: MeasDevice[]) => {
      return {
        key: 'measDevice',
        translationKey: 'operatorJobsTable.measDevice',
        dataGetter: (item: Job) => findMeasDeviceNames(measDevices, item),
        useLongTextStyle: false,
      };
    },
    menu: {
      key: 'menu',
      dataGetter: (item: Job) => (
        <OperatorTableMenu
          id={item.key}
          idPrefix="jobs-menu"
          anchorEl={anchorEl[item.key]}
          handleClick={handleClick}
          handleClose={handleClose}
        >
          <OperatorTableMenuItem
            onClick={() => {
              showOrderInfo(
                item.order.key,
                OperatorOrderTab.Weighing,
                OperatorOrderMode.Viewing,
                item.key,
              );
            }}
            handleClose={handleClose}
            disabled={!item.order || !item.order.key || item.status === JobStatus.DISCARDED}
            routePath={RoutePaths.OPERATOR_ORDER}
            text={translate('operatorJobsTable.viewReceipt')}
          />
          <OperatorTableMenuItem
            onClick={() => {
              showOrderInfo(item.order.key, OperatorOrderTab.Order, OperatorOrderMode.Viewing);
            }}
            handleClose={handleClose}
            disabled={false}
            routePath={RoutePaths.OPERATOR_ORDER}
            text={translate('operatorJobsTable.viewOrder')}
          />
        </OperatorTableMenu>
      ),
      useLongTextStyle: false,
    },
  };
};
