import { Reducer } from 'redux';
import { WeighingActionTypes, WeighingState } from './types';

export const initialState: WeighingState = {
  latestStatusTime: 0,
  status: {},
  inProgress: false,
  operatorFinishingOrderKey: '',
  operatorTrailerKey: undefined,
};

const reducer: Reducer<WeighingState> = (state = initialState, action) => {
  switch (action.type) {
    case WeighingActionTypes.STATUS_SUCCESS:
      return {
        ...state,
        latestStatusTime: new Date().getTime(),
        status: action.payload.status,
      };
    case WeighingActionTypes.DO_WEIGHING:
      return { ...state, inProgress: true };
    case WeighingActionTypes.INACTIVATE_CONTEXT:
      return { ...state, loading: true };
    case WeighingActionTypes.FINISH_CONTEXT:
      return { ...state, loading: false };
    case WeighingActionTypes.SET_OPERATOR_FINISHING_ORDER_KEY:
      return { ...state, operatorFinishingOrderKey: action.payload };
    case WeighingActionTypes.SET_OPERATOR_TRAILER_KEY:
      return { ...state, operatorTrailerKey: action.payload };
    case WeighingActionTypes.INACTIVATE_CONTEXT_FAILED:
      return { ...state, loading: false, inProgress: false };
    case WeighingActionTypes.INACTIVATE_CONTEXT_SUCCEEDED:
      return { ...state, loading: false, inProgress: false };
    case WeighingActionTypes.WEIGHING_SUCCESS:
      return { ...state, loading: false, inProgress: false };
    default: {
      return state;
    }
  }
};

export { reducer as weighingReducer };
