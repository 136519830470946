import React from 'react';

import { makeStyles } from '@mui/styles';
import { IconButton, Table, TableFooter, TablePagination, TableRow } from '@mui/material';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { ServerPagination } from '../store/common/types';
import { translate } from '../utils/translate';

const useStyles = makeStyles({
  pagination: {
    '& .MuiIconButton-root:not(.Mui-disabled)': {
      color: '#1a7bc3',
    },
    '& .MuiTablePagination-spacer': {
      flex: 'none',
    },
    '& .MuiTableCell-footer, & .MuiToolbar-root, &.MuiTableCell-root:last-child': {
      // eslint-disable-next-line
      paddingLeft: 0,
      // eslint-disable-next-line
      paddingRight: 0,
      '& div': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    // eslint-disable-next-line
    paddingLeft: 0,
    // eslint-disable-next-line
    paddingRight: 0,
  },
});

export interface PaginationProps {
  showPage: (pageNumber: number) => void;
  pagination: ServerPagination;
}

function createTablePaginationActions(props: PaginationProps) {
  function TablePaginationActions() {
    const pagination = props.pagination;
    const page: number = pagination.pageNumber;
    const count: number = pagination.itemCount;
    const rowsPerPage: number = pagination.pageSize;
    const currentPageItemCount: number | undefined = pagination.currentPageItemCount;

    function onChangePage(event: any, page: number) {
      props.showPage(page);
    }

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onChangePage(event, 1);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onChangePage(event, Math.max(1, Math.ceil(count / rowsPerPage)));
    };

    const hasCurrentCount = count < 0 && typeof currentPageItemCount === 'number';
    const firstDisabled = page <= 1;
    const previousDisabled = page <= 1;
    const definedCurrentPageItemCount = currentPageItemCount ? currentPageItemCount : 0;
    const nextDisabled = hasCurrentCount
      ? definedCurrentPageItemCount < rowsPerPage
      : page >= Math.ceil(count / rowsPerPage);
    const lastDisabled = page >= Math.ceil(count / rowsPerPage);

    return (
      <div>
        <IconButton onClick={handleFirstPageButtonClick} disabled={firstDisabled}>
          {<FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={previousDisabled}
          aria-label="previous page"
        >
          {<KeyboardArrowLeft />}
        </IconButton>
        <IconButton onClick={handleNextButtonClick} disabled={nextDisabled}>
          {<KeyboardArrowRight />}
        </IconButton>
        {count > 0 && (
          <IconButton onClick={handleLastPageButtonClick} disabled={lastDisabled}>
            {<LastPageIcon />}
          </IconButton>
        )}
      </div>
    );
  }
  return TablePaginationActions;
}

export default function Pagination(props: PaginationProps) {
  const styles = useStyles();
  const itemCount = props.pagination.itemCount;
  return (
    <Table>
      <TableFooter>
        <TableRow>
          {(itemCount === -1 || itemCount > 0) && (
            <TablePagination
              className={styles.pagination}
              labelDisplayedRows={({ from, to, count }) => {
                if (count >= 0) {
                  return translate('pagination.displayedRows', { from, to, count });
                }
                return props.pagination.pageNumber;
              }}
              rowsPerPageOptions={[]}
              count={itemCount}
              rowsPerPage={props.pagination.pageSize}
              page={itemCount === -1 ? 0 : props.pagination.pageNumber - 1}
              SelectProps={{
                native: true,
              }}
              onPageChange={(event: any, page: number) => props.showPage(page + 1)}
              ActionsComponent={createTablePaginationActions(props)}
            />
          )}
        </TableRow>
      </TableFooter>
    </Table>
  );
}
