import { makeStyles } from '@mui/styles';
import React from 'react';
import { Box } from '@mui/material';
import { TrafficControlStatus } from 'store/meas-devices/types';
import { trafficLightColors } from 'theme';

const styles = makeStyles({
  container: {
    padding: '5px',
  },
  statusIndicator: {
    position: 'relative',
    width: '36px',
    height: '36px',
    borderRadius: '18px',
    display: 'flex',
    padding: '6px',
    margin: '0 0 6px 0',
    border: '1px solid',
    borderColor: trafficLightColors.gray,
  },
  pass: {
    backgroundColor: trafficLightColors.pass,
  },
  stop: {
    backgroundColor: trafficLightColors.stop,
  },
  gray: {
    backgroundColor: trafficLightColors.gray,
  },
  rectangle: {
    width: '56px',
    height: '96px',
    padding: '8px 8px',
    borderRadius: '5px',
    border: 'solid 2px #000',
    position: 'relative',
    backgroundColor: trafficLightColors.gray,
  },
  inactiveLight: {
    backgroundColor: trafficLightColors.gray,
    borderColor: trafficLightColors.inactiveBorder,
  },
});

interface TrafficLightsProps {
  trafficControlGroupId: string;
  status: TrafficControlStatus;
}

export const TrafficLights = (props: TrafficLightsProps) => {
  const classes = styles();

  const upperLightClass =
    props.status === TrafficControlStatus.Stop
      ? `${classes.statusIndicator} ${classes.stop}`
      : `${classes.statusIndicator} ${classes.stop} ${classes.inactiveLight}`;

  const lowerLightClass =
    props.status === TrafficControlStatus.Pass
      ? `${classes.statusIndicator} ${classes.pass}`
      : `${classes.statusIndicator} ${classes.pass} ${classes.inactiveLight}`;

  return (
    <Box className={classes.container}>
      <Box className={classes.rectangle}>
        <Box className={upperLightClass} />
        <Box className={lowerLightClass} />
      </Box>
    </Box>
  );
};
