import { connect, localeConnect } from 'localeConnect';

import { createOrderDetailsModel } from 'models/orderdetails.model';

import { ApplicationState } from 'store';
import { jobsTrucksAndTrailers } from 'store/jobs/utils';
import { getDefaultTrailerName } from 'store/master-data/utils';
import { Context } from 'store/orders/types';
import { getContextMainJob, MainJobMultipartBehavior } from 'store/orders/utils';
import { UserDetails } from 'store/user/types';

import NavBarVehicle from '../NavBarVehicle';

interface ParameterProps {
  context: Context;
}

interface PropsFromState {
  scaleKey: string;
  userDetails: UserDetails | undefined;
}

interface PropsFromDispatch {}

type AllProps = PropsFromDispatch & PropsFromState & ParameterProps;

const mapStateToProps = ({ currentScaleInfo, user }: ApplicationState) => ({
  scaleKey: currentScaleInfo.scaleKey,
  userDetails: user.user.userData,
});

const mapDispatchToProps = {};

const InspectorNavBarVehicle = (props: AllProps) => {
  const mainJob = getContextMainJob(props.context, MainJobMultipartBehavior.FIRST);
  if (mainJob === undefined) {
    return null;
  }

  const containers = jobsTrucksAndTrailers([mainJob]);
  if (containers.length === 0) {
    return null;
  }

  function getOrderDetailsModel() {
    const jobs = props.context.weighingJobs;
    if (jobs === undefined) {
      return undefined;
    }
    return createOrderDetailsModel(
      props.context,
      props.context.order,
      props.context.process,
      jobs,
      props.scaleKey,
      props.userDetails,
    );
  }
  const orderDetailsModel = getOrderDetailsModel();

  const isTrailerUsed = orderDetailsModel?.isTrailerUsed ?? false;
  const trailerName = getDefaultTrailerName(isTrailerUsed);

  return <NavBarVehicle vehicleName={containers[0].name} trailerName={trailerName} />;
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(InspectorNavBarVehicle);
