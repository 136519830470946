import React from 'react';

import { Button } from '@mui/material';

import { Context } from 'store/orders/types';
import { isOrderValidForTareSaving } from 'store/orders/utils';

import { translate } from 'utils/translate';
import ConfirmDialog from '../ConfirmDialog';
import PrimaryContainerTareFeature from '../PrimaryContainerTareFeature';

interface Props {
  context: Context;
  saveTareAndFinish: () => void;
  scaleKey: string;
  rootClass?: string;
}

export default function OperatorSaveTruckTare(props: Props) {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
  return (
    <PrimaryContainerTareFeature
      areAdditionalConditionsSatisfied={isOrderValidForTareSaving(
        props.context.order,
        props.scaleKey,
      )}
      context={props.context}
      isExistingTareRequired={false}
    >
      <Button
        className={props.rootClass}
        color="primary"
        variant="contained"
        onClick={() => setIsConfirmDialogOpen(true)}
      >
        {translate('operatorOrder.weighing.saveTare.finishAndSave')}
      </Button>
      {isConfirmDialogOpen && (
        <ConfirmDialog
          title={translate('operatorOrder.weighing.saveTare.confirmTitle')}
          question={translate('operatorOrder.weighing.saveTare.confirmQuestion')}
          visible={isConfirmDialogOpen}
          onConfirmed={() => {
            props.saveTareAndFinish();
            setIsConfirmDialogOpen(false);
          }}
          onNotConfirmed={() => setIsConfirmDialogOpen(false)}
        />
      )}
    </PrimaryContainerTareFeature>
  );
}
