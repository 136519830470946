import React from 'react';

import { makeStyles } from '@mui/styles';
import { Button, Grid, Typography } from '@mui/material';

import SwapIcon from '@mui/icons-material/SwapHoriz';
import { ContainerType } from 'store/common/types';
import { WeighingProcess } from 'store/orders/types';
import { FormatUnit, undefinedMassKg } from 'store/weighing/types';

import { VehicleWeighingModel, WeighingDirection } from 'models/vehicleweighing.model';
import { separatorFadedColor, vehicleIconContainerMargins, vehicleIconStyle } from 'theme';
import { translate } from 'utils/translate';
import { formatMass } from '../utils';

import { Trailer, Truck } from '../CustomIcons';

import { NonNegativeIntInput } from '../NonNegativeIntInput';

import OperatorWeighingHint from './OperatorWeighingHint';

const useStyles = makeStyles({
  button: {
    width: '100%',
  },
  gridItem: {
    padding: '10px',
  },
  input: {
    '& .MuiTextField-root': {
      '& .MuiInputAdornment-root': {
        marginTop: '-3px',
      },
      '& input': {
        paddingLeft: '57px',
        paddingTop: '0px',
        paddingBottom: '3px',
        fontSize: '18px',
        textAlign: 'center',
      },
    },
  },
  reversed: {
    transform: 'scaleX(-1)',
  },
  kgContainer: {
    paddingTop: '12px',
    fontSize: '26px',
    textAlign: 'center',
  },
  kgs: {
    backgroundColor: separatorFadedColor,
    fontWeight: 'normal',
    borderRadius: '0 0 5px 5px',
  },
  kgsActive: {
    backgroundColor: '#f7f7f7',
    fontWeight: 600,
    borderRadius: '0 0 5px 5px',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: 2.0,
    marginRight: '30px',
  },
  titleContainer: {
    textAlign: 'left',
  },
  vehicleIcon: vehicleIconStyle,
  vehicleIconContainer: {
    maxWidth: '330px',
    ...vehicleIconContainerMargins,
  },
});

interface ParameterProps {
  discardWeighing: () => void;
  doReverse: () => void;
  doWeighing: () => void;
  ignoreWrongDirection?: boolean;
  isMeasDeviceGroupManual: boolean;
  isReversed: boolean;
  isReversible: boolean;
  model: VehicleWeighingModel;
  additionalInfo?: JSX.Element;
  process: WeighingProcess;
  setManualKgs?: (containerKey: string, kgs: number) => void;
}

type AllProps = ParameterProps;

interface VehicleContainer {
  key: string;
  kgs: number;
  isActive: boolean;
  containerType: ContainerType;
  additionalInfo?: JSX.Element;
}

interface VehicleContainerProps {
  containers: VehicleContainer[];
  containerWidth: 3 | 6 | 12;
  isReversed: boolean;
  title?: string;
  button?: JSX.Element;
  isMeasDeviceGroupManual: boolean;
  setManualKgs?: (containerKey: string, kgs: number) => void;
}

interface ReverseProps {
  doReverse?: () => void;
  isReverseEnabled: boolean;
}

export const ReverseButton = (props: ReverseProps) => {
  const reverseHandler = () => {
    if (props.doReverse) props.doReverse();
  };

  return (
    <Button color="primary" onClick={reverseHandler} disabled={props.isReverseEnabled}>
      <>
        <SwapIcon />
        {translate('operatorOrder.weighing.reverseTruckTrailer')}
      </>
    </Button>
  );
};

const minManualKgs = 0;
const maxManualKgs = 200000;

export const OperatorVehicles = (props: VehicleContainerProps) => {
  const classes = useStyles();
  const containerWidth = props.containerWidth;
  const containers = [...props.containers];
  if (props.isReversed) {
    containers.reverse();
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} className={classes.titleContainer}>
        {props.title && (
          <Typography className={classes.title} variant="caption">
            {props.title}
          </Typography>
        )}
        {props.button && props.button}
      </Grid>

      <Grid container>
        {containers.map((container) => (
          <Grid key={container.key} item xs={12} sm={12} md={containerWidth}>
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.gridItem}>
                  <div className={classes.vehicleIconContainer}>
                    <div
                      className={`${classes.vehicleIcon} ${
                        props.isReversed ? classes.reversed : ''
                      }`}
                    >
                      {container.containerType === ContainerType.TRUCK ? <Truck /> : <Trailer />}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={`${classes.gridItem} ${classes.kgContainer}`}>
                  <div className={container.isActive ? classes.kgsActive : classes.kgs}>
                    {container.isActive && props.isMeasDeviceGroupManual && (
                      <div className={classes.input}>
                        <NonNegativeIntInput
                          allowUndefined={true}
                          hideZero={false}
                          isDisabled={false}
                          isOutOfRangePrevented={false}
                          showReset={true}
                          initialValue={
                            container.kgs === undefinedMassKg ? undefined : container.kgs
                          }
                          label={translate('operatorOrder.weighing.manualInputHint')}
                          max={maxManualKgs}
                          min={minManualKgs}
                          onChanged={(kgs) => {
                            if (!props.setManualKgs) return;
                            props.setManualKgs(container.key, kgs ?? undefinedMassKg);
                          }}
                          hasError={false}
                          updateInitialValue={true}
                          useAutoFocus={true}
                          useFullWidth={true}
                        />
                      </div>
                    )}
                    {(!container.isActive || !props.isMeasDeviceGroupManual) &&
                      formatMass(container.kgs, FormatUnit.WITHOUT_UNIT)}
                  </div>
                </div>
              </Grid>
              {container.additionalInfo && (
                <Grid item xs={12}>
                  {container.additionalInfo}
                </Grid>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

function getWeighingHintText(
  isWrongDirection: boolean,
  hasUndefinedDirection: boolean,
  hasDifferentDirections: boolean,
) {
  if (isWrongDirection && hasUndefinedDirection) {
    return translate('operatorOrder.weighing.zeroNet');
  }

  return isWrongDirection || hasDifferentDirections
    ? translate('operatorOrder.weighing.directionChanges')
    : '';
}

const OperatorVehicleWeighing = (props: AllProps) => {
  const classes = useStyles();
  const model = props.model;
  const containers = [...model.containers];

  const containerWidth = containers.length === 2 ? 6 : 12;

  const isWrongDirection = containers.reduce(
    (isWrongDirection, container) => isWrongDirection || container.isWrongDirection,
    false,
  );
  const directions = containers.map((container) => container.direction);
  const hasDifferentDirections =
    directions.includes(WeighingDirection.ADD) && directions.includes(WeighingDirection.REDUCE);
  const hasUndefinedDirection = directions.includes(WeighingDirection.UNDEFINED);

  const titleBase =
    props.process === WeighingProcess.MULTIPART
      ? 'operatorOrder.weighing.multipartRoundTitle'
      : 'operatorOrder.weighing.roundTitle';

  return (
    <div>
      <Grid container>
        <OperatorVehicles
          containers={containers}
          containerWidth={containerWidth}
          button={
            props.isReversible ? (
              <ReverseButton
                doReverse={props.doReverse}
                isReverseEnabled={!model.isWeighingEnabled}
              />
            ) : undefined
          }
          isMeasDeviceGroupManual={props.isMeasDeviceGroupManual}
          setManualKgs={props.setManualKgs}
          isReversed={false}
          title={translate(titleBase, { round: model.round })}
        />
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <div className={classes.gridItem}>
                <Button
                  className={classes.button}
                  variant="outlined"
                  disabled={!model.isDiscardingEnabled}
                  onClick={props.discardWeighing}
                >
                  {translate('operatorOrder.weighing.discard')}
                </Button>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.gridItem}>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  disabled={
                    !model.isWeighingEnabled ||
                    containers.reduce<boolean>((hasError, container) => {
                      if (hasError) return true;
                      return (
                        container.isActive &&
                        props.isMeasDeviceGroupManual &&
                        (container.kgs === undefined ||
                          container.kgs < minManualKgs ||
                          container.kgs > maxManualKgs)
                      );
                    }, false)
                  }
                  onClick={props.doWeighing}
                >
                  {props.isMeasDeviceGroupManual
                    ? translate('operatorOrder.weighing.confirmManualWeighing')
                    : translate('operatorOrder.weighing.weigh')}
                </Button>
              </div>
            </Grid>
            <Grid item xs={6}>
              {props.additionalInfo}
            </Grid>
            <Grid item xs={6}>
              {!props.ignoreWrongDirection && (isWrongDirection || hasDifferentDirections) && (
                <OperatorWeighingHint
                  text={getWeighingHintText(
                    isWrongDirection,
                    hasUndefinedDirection,
                    hasDifferentDirections,
                  )}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default OperatorVehicleWeighing;
