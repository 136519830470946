import { ApplicationState } from 'store';
import { CacheImage, CacheImageType } from 'store/images/types';
import { Context } from 'store/orders/types';
import { mobileContentMaxWidth } from 'theme';

export function findImages(state: ApplicationState, orderKey: string): CacheImage[] {
  const orderImages = state.images.orderImages.images[orderKey];
  if (!orderImages) {
    return [];
  }
  // NOTE(mikkogy,20201023) to render images as their type in store changes we
  // need to change the array reference.
  const images = [...orderImages];
  const keyMap: Record<string, boolean> = {};
  // NOTE(mikkogy,20210909) image local id creation logic has been changed in
  // store which may result in temporary duplicates in store if downloads were
  // in progress during update. Filtering can be removed later and it was
  // preferred over migration because it was much faster to implement.
  const uniqueImages = [...images].filter((image) => {
    if (keyMap[image.attachmentKey]) return false;
    keyMap[image.attachmentKey] = true;
    return true;
  });
  uniqueImages.sort((a, b) => a.meta.timestamp - b.meta.timestamp);
  return uniqueImages;
}

export function hasUploadsInProgress(context: Context | undefined, images: CacheImage[]): boolean {
  return !!context && images.filter((i) => i.type === CacheImageType.UPLOAD).length > 0;
}

export const narrowContainerStyle = () => {
  return { marginTop: '20px', maxWidth: mobileContentMaxWidth };
};
