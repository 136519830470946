import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, ClassNameMap } from '@mui/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Divider,
  Grid,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import moment from 'moment';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Redirect, RouteComponentProps } from 'react-router';
import { connect, localeConnect } from 'localeConnect';
import { jobQueryRequest } from 'store/jobs/actions';
import { JobQueryResponse, OperatorQueryParams, JobQueryParams, Job } from 'store/jobs/types';
import { defaultDriverQueryParams } from 'store/jobs/utils';
import { ApplicationState } from 'store';
import { DocType } from 'store/common/types';
import { DomainInfo } from 'store/scale-info/types';
import theme, { mobileContentMaxWidth } from 'theme';
import { formatReceiptNumber, isShareSupported, shareReceipt } from 'utils/receiptUtils';
import { navBarStyle, formatMass } from 'components/utils';
import ReceiptItem from 'components/ReceiptItem';
import Pagination from 'components/Pagination';
import { RoutePaths } from 'routes';
import TemporaryDrawer from 'components/TemporaryDrawer';
import { NavDivider } from 'components/NavDrawerContent';
import { FormatUnit } from 'store/weighing/types';
import { translate } from 'utils/translate';

const useStyles = makeStyles({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
  },
  title: {
    flexGrow: 1,
  },
  container: {
    margin: 'auto',
    maxWidth: mobileContentMaxWidth,
    alignContent: 'center',
    backgroundColor: '#FFFFFF',
  },
  listItemRight: {
    color: '#646464',
  },
  listItemLeft: {
    color: '#000000',
  },
  listItemHeader: {
    fontWeight: 'bold',
  },
  spacer: {
    height: '30px',
  },
  drawerPaper: {
    width: 250,
  },
  marginFill: {
    height: '0.1px',
  },
  receiptItem: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  ...(navBarStyle as any),
});

interface PropsFromDispatch {
  jobQueryRequest: typeof jobQueryRequest;
}

interface PropsFromState {
  queryJobs: JobQueryResponse | undefined;
  queryParams: OperatorQueryParams;
  siteKey: string | undefined;
  domainInfo: DomainInfo;
}

enum RenderContent {
  LIST,
  DETAILS,
  EMPTY,
}

/*eslint-disable */
export type AllProps = PropsFromDispatch & PropsFromState & RouteComponentProps<{}>;
/*eslint-enable */

const mapStateToProps = (state: ApplicationState) => ({
  queryJobs: state.jobs.queryJobs,
  siteKey: state.sites.selectedKey,
  domainInfo: state.currentScaleInfo.domainInfo,
});

const mapDispatchToProps = {
  jobQueryRequest,
};

function formatTimestamp(time: any) {
  if (!time) return '';

  return moment(time).format('L');
}

function firstLinked(job: Job, docType: DocType) {
  const links = job.linked.filter((link: { docType: DocType }) => link.docType === docType);
  return links.length > 0 ? links[0].name : '';
}

function firstLinkedCustomer(job: Job) {
  return firstLinked(job, DocType.CUSTOMER);
}

function firstLinkedMaterial(job: Job) {
  return firstLinked(job, DocType.MATERIAL);
}

function receiptInformation(job: Job | undefined, classes: ClassNameMap<string>) {
  if (!job) return null;

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Typography variant="body1" className={classes.listItemLeft}>
          {' '}
          {firstLinkedCustomer(job)}
        </Typography>
        <Typography variant="body1" className={classes.listItemLeft}>
          {' '}
          {firstLinkedMaterial(job)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" align="right" className={classes.listItemRight}>
          {' '}
          {formatMass(job.sumMassKg, FormatUnit.WITH_UNIT)}
        </Typography>
        <Typography variant="body1" align="right" className={classes.listItemRight}>
          {' '}
          {formatTimestamp(job.timestamp)}
        </Typography>
      </Grid>
    </Grid>
  );
}

function receiptHeader(job: Job | undefined, classes: ClassNameMap<string>) {
  if (!job) return null;

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Typography variant="body1" className={classes.listItemHeader}>
          {' '}
          {job.containers[0].name}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" className={classes.listItemHeader}>
          {' '}
          {formatReceiptNumber(job.receiptNumber)}
        </Typography>
      </Grid>
    </Grid>
  );
}

interface DrawerProps {
  onShareClick: () => void;
}

const JobHistoryDrawer = (props: DrawerProps) => (
  <div role="presentation">
    <NavDivider variant={'fullWidth'} />
    <List component={'ul'}>
      <ListItem
        component={'li'}
        button
        key="share"
        onClick={props.onShareClick}
        disabled={!isShareSupported()}
      >
        <ListItemText primary={translate('receipt.shareReceipt')} />
      </ListItem>
    </List>
  </div>
);

interface NavBarProps {
  onBackClick: () => void;
  onSidebarToggle: () => void;
  onShareClick: () => void;
  isDetailsOpen: boolean;
  isSidebarOpen: boolean;
  classes: {
    appBar: string;
    title: string;
    drawerPaper: string;
  };
  job: Job | null;
}

const JobHistoryNavBar = (props: NavBarProps) => (
  <div>
    <AppBar position="relative" className={props.classes.appBar}>
      <Toolbar>
        <IconButton color="inherit" arial-label="" edge="start" onClick={props.onBackClick}>
          <BackIcon />
        </IconButton>
        <Typography variant="h6" noWrap className={props.classes.title}>
          {props.isDetailsOpen
            ? props.job
              ? formatReceiptNumber(props.job.receiptNumber)
              : ''
            : translate('jobHistory.title')}
        </Typography>
        {props.isDetailsOpen && (
          <IconButton color="inherit" aria-label="" edge="end" onClick={props.onSidebarToggle}>
            <MoreVertIcon />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
    <nav aria-label="Navigation">
      <TemporaryDrawer
        anchor="right"
        isOpen={props.isSidebarOpen}
        onClose={props.onSidebarToggle}
        paperClass={props.classes.drawerPaper}
      >
        <JobHistoryDrawer onShareClick={props.onShareClick} />
      </TemporaryDrawer>
    </nav>
  </div>
);

export const JobHistory = (props: AllProps) => {
  const classes = useStyles();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [selectedJobState, setSelectedJobState] = useState<Job | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const queryParams = defaultDriverQueryParams();
  if (props.queryJobs) {
    queryParams.page = props.queryJobs.number;
    queryParams.size = props.queryJobs.size;
  }

  const { jobQueryRequest } = props;
  const doQuery = useCallback(
    (params: JobQueryParams) => {
      jobQueryRequest(params);
    },
    [jobQueryRequest],
  );

  const setSelectedJob = (job: Job) => {
    setIsDetailsOpen(true);
    setSelectedJobState(job);
  };

  const siteKey = props.siteKey;
  useEffect(() => {
    if (siteKey) {
      doQuery(queryParams);
    }
    // NOTE(mikkogy,20221214) we only want initial query when component is
    // rendered the first time with siteKey. Later doQuery is expected to be
    // called when needed. When there's no site key we don't have site selected
    // and request would not make any sense.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!siteKey) {
    return <Redirect to={RoutePaths.SITES} />;
  }

  const queryJobs = props.queryJobs ?? { results: [], number: 0, size: 0, names: {} };

  const pagination = {
    currentPageItemCount: queryJobs.results.length,
    itemCount: -1,
    pageNumber: queryJobs.number + 1,
    pageSize: queryJobs.size,
  };

  const onBackClick = () => {
    if (isDetailsOpen) {
      setIsDetailsOpen(false);
      setIsSidebarOpen(false);
      setSelectedJobState(null);
    } else {
      props.history.goBack();
    }
  };

  const onSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const onShareClick = () => {
    const selectedJob = selectedJobState;

    if (!selectedJob) {
      return;
    }
    shareReceipt(selectedJob, props.domainInfo);
    setIsSidebarOpen(false);
  };

  const navBarProps = {
    isDetailsOpen: isDetailsOpen,
    isSidebarOpen: isSidebarOpen,
    classes: {
      appBar: classes.appBar,
      title: classes.title,
      drawerPaper: classes.drawerPaper,
    },
    job: selectedJobState,
    onBackClick: onBackClick,
    onShareClick: onShareClick,
    onSidebarToggle: onSidebarToggle,
  };

  const listOrEmpty = queryJobs.results.length > 0 ? RenderContent.LIST : RenderContent.EMPTY;
  const whatToRender = isDetailsOpen ? RenderContent.DETAILS : listOrEmpty;

  return (
    <div className={classes.root}>
      <JobHistoryNavBar {...navBarProps} />
      {whatToRender === RenderContent.LIST && (
        <CardContent className={classes.container}>
          <List component={'ul'}>
            {queryJobs.results.map((job: Job) => {
              if (!job) {
                return null;
              }

              return (
                <div key={'div_' + job.key}>
                  <ListItem component="li" button key={job.key}>
                    {/* Typography disabled as to prevent <div>
                        as descentant of <p> error */}
                    <ListItemText
                      primary={receiptHeader(job, classes)}
                      secondary={receiptInformation(job, classes)}
                      onClick={() => {
                        setSelectedJob(job);
                      }}
                      disableTypography
                    />
                  </ListItem>
                  <Divider component="li" />
                </div>
              );
            })}
          </List>
          <Pagination
            pagination={pagination}
            showPage={(pageNumber: number) => {
              const params = { ...queryParams };
              params.page = pageNumber - 1;
              doQuery(params);
            }}
          />
        </CardContent>
      )}
      {whatToRender === RenderContent.DETAILS && (
        <div className={classes.receiptItem}>
          {selectedJobState && (
            <ReceiptItem
              job={selectedJobState}
              domainInfo={props.domainInfo}
              isInHistoryMode={true}
              blockColor={theme.palette.background.default}
              receiptNumber={undefined}
              receiptTotalCount={undefined}
            />
          )}
        </div>
      )}
      {whatToRender === RenderContent.EMPTY && (
        <Typography>
          <br />
          {translate('jobHistory.noData')}
        </Typography>
      )}
      <div className={classes.marginFill} />
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(mapStateToProps, mapDispatchToProps)(JobHistory);
