import { Reducer } from 'redux';
import { SitesActionTypes } from '../sites/types';
import { UiRoleActionTypes, UserActionTypes } from '../user/types';
import { JobsActionTypes, JobsState } from './types';
import { defaultOperatorQueryParams } from './utils';

export const getInitialState = (): JobsState => {
  return {
    data: [],
    errors: undefined,
    loading: false,
    finishedJobKeys: [],
    receiptDocuments: [],
    latestJobs: undefined,
    queryJobs: undefined,
    queryParams: defaultOperatorQueryParams(),
    driverReceiptJobKeys: [],
  };
};

const reducer: Reducer<JobsState> = (state = getInitialState(), action) => {
  switch (action.type) {
    case JobsActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true, receiptDocuments: [] };
    }
    case JobsActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, data: action.payload };
    }
    case JobsActionTypes.RECEIPT_SUCCESS: {
      return { ...state, receiptDocuments: [...state.receiptDocuments, action.payload] };
    }
    case JobsActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    case JobsActionTypes.STORE_OPERATOR_QUERY: {
      return { ...state, queryParams: action.payload };
    }
    case JobsActionTypes.JOB_QUERY_SUCCESS: {
      return { ...state, queryJobs: action.payload };
    }
    case JobsActionTypes.LATEST_JOBS_QUERY_SUCCESS: {
      return { ...state, latestJobs: action.payload };
    }
    case JobsActionTypes.SET_DRIVER_RECEIPT_JOB_KEYS: {
      return { ...state, driverReceiptJobKeys: action.payload };
    }
    case SitesActionTypes.CHANGE_SITE:
    case UserActionTypes.LOGOUT: {
      return { ...getInitialState() };
    }
    case UiRoleActionTypes.ROLE_CHANGED: {
      return { ...state, queryJobs: undefined };
    }
    default: {
      return state;
    }
  }
};

export { reducer as jobsReducer };
