import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { translate } from '../utils/translate';

interface DialogProps {
  confirmText?: string;
  dontConfirmText?: string;
  title: string;
  question: string;
  visible: boolean;
  onConfirmed: () => void;
  onNotConfirmed: () => void;
}

const ConfirmDialogComponent = (props: DialogProps) => {
  // NOTE(lindenlas, 20220224) we want to default all falsy values to default values
  // as empty strings in dialog button texts do not make sense.
  const dontConfirmText = props.dontConfirmText || translate('confirmdialog.no');
  const confirmText = props.confirmText || translate('confirmdialog.yes');
  return (
    <div>
      <Dialog open={props.visible}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.question}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onNotConfirmed} variant="outlined" color="primary">
            {dontConfirmText}
          </Button>
          <Button onClick={props.onConfirmed} variant="contained" color="primary" autoFocus={true}>
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmDialogComponent;
