import { Vehicle } from '../store/vehicles/types';
import { ContainerType, MasterDataItem } from '../store/common/types';

class VehicleModel {
  get key(): string {
    return this._key;
  }
  get trailer(): string | undefined {
    return this._trailer;
  }
  get truck(): string | undefined {
    return this._truck;
  }
  private readonly _key: string;
  private readonly _truck?: string;
  private readonly _trailer?: string;

  constructor(key: string, truck?: string, trailer?: string) {
    this._key = key;
    this._truck = truck;
    this._trailer = trailer;
  }

  public static vehicleModelFromVehicle(vehicle: Vehicle): VehicleModel {
    let truck;
    let trailer;
    let truckTrailer;
    if (vehicle.containers && vehicle.containerType === ContainerType.TRUCK_TRAILER) {
      truck = vehicle.containers.find(
        (c: MasterDataItem) => c.containerType === ContainerType.TRUCK,
      );
      trailer = vehicle.containers.find(
        (c: MasterDataItem) => c.containerType === ContainerType.TRAILER,
      );
      truckTrailer = vehicle;
    } else if (vehicle.containerType === ContainerType.TRUCK) {
      truck = vehicle;
    }

    if (truckTrailer) {
      if (truck && trailer) {
        return new VehicleModel(vehicle.key, truck.name, trailer.name);
      }
      // NOTE(mikkogy,20191122) unusable container.
      return new VehicleModel(vehicle.key);
    } else if (truck) {
      return new VehicleModel(vehicle.key, vehicle.name);
    } else {
      // NOTE(mikkogy,20191122) unusable container.
      return new VehicleModel(vehicle.key);
    }
  }

  public static truckFilter(vehicle: VehicleModel) {
    // NOTE(mikkogy,20191122) for now only trucks are wanted as
    // truck trailers are typically generated on demand using a
    // known truck and a generated trailer. Later on known trailers
    // may be used too.
    return vehicle.truck !== undefined && vehicle.trailer === undefined;
  }
}

export default VehicleModel;
