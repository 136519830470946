import React from 'react';
import { NavLink } from 'react-router-dom';

import { createStyles, makeStyles, WithStyles, withStyles } from '@mui/styles';
import {
  Button,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material/';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import { operatorColors, breakLongText } from 'theme';
import { translate } from 'utils/translate';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  rootNoSelection: {
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  table: {
    minWidth: 650,
  },
  focused: {
    outline: `2px solid ${theme.palette.primary.main}`,
  },
  tableCellLongText: {
    minWidth: '100px',
    ...breakLongText,
  },
  tableCell: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
}));

export interface OperatorTableColumn {
  key: string;
  title?: string;
  // NOTE(mikkogy,20210504) translationKey takes precedence over title if both
  // are present.
  translationKey?: string;
  dataGetter: (item: any) => any;
  useLongTextStyle: boolean;
}

const menuStyleProps = {
  iconButton: {
    color: operatorColors.secondary,
  },
  link: {
    color: 'initial',
    fontWeight: 'normal' as const,
    textDecoration: 'none',
  },
};

const menuStyles = makeStyles(menuStyleProps);

const menuPropStyles = createStyles(menuStyleProps);

type PropsFromMenuStyles = WithStyles<typeof menuPropStyles>;

interface MenuButtonProps {
  idPrefix: string;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>, key: string) => void;
  id: string;
}

function OperatorTableMenuButton(props: MenuButtonProps) {
  const classes = menuStyles();
  return (
    <Button
      aria-controls={props.idPrefix}
      aria-haspopup="true"
      onClick={(event) => props.handleClick(event, props.id)}
    >
      <MoreVertIcon className={classes.iconButton} />
    </Button>
  );
}

export interface OperatorTableMenuItemProps {
  onClick: () => void;
  disabled: boolean;
  handleClose: () => void;
  routePath: string;
  text: string;
}

class TableMenuItem extends React.Component<OperatorTableMenuItemProps & PropsFromMenuStyles> {
  public render() {
    return (
      <MenuItem onClick={this.props.handleClose} disabled={this.props.disabled}>
        <NavLink
          className={this.props.classes.link}
          to={this.props.routePath}
          onClick={this.props.onClick}
        >
          <div>{this.props.text}</div>
        </NavLink>
      </MenuItem>
    );
  }
}

export const OperatorTableMenuItem = withStyles(menuPropStyles)(TableMenuItem);

export interface OperatorMenuProps {
  children: React.ReactNode[];
  id: string;
  idPrefix: string;
  anchorEl: Element;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>, key: string) => void;
  handleClose: () => void;
}

export function OperatorTableMenu(props: OperatorMenuProps) {
  return (
    <div>
      <OperatorTableMenuButton
        idPrefix={props.idPrefix}
        handleClick={props.handleClick}
        id={props.id}
      />
      <Menu
        id={`${props.idPrefix}-${props.id}`}
        anchorEl={props.anchorEl}
        keepMounted
        open={Boolean(props.anchorEl)}
        onClose={props.handleClose}
      >
        {props.children}
      </Menu>
    </div>
  );
}

interface Props {
  columns: OperatorTableColumn[];
  data: { [key: string]: any }[];
  keyGetter: (item: any) => string;
  classGetter?: (item: any) => string;
  selectedIndex?: number;
  setSelectedIndex?: (index: number) => void;
}

function OperatorTable(props: Props) {
  const classes = useStyles();

  const { selectedIndex, setSelectedIndex } = props;

  function handleKeyDown(e: any) {
    if (selectedIndex === undefined || setSelectedIndex === undefined) return;
    if (e.key === 'ArrowUp') {
      const nextKey = selectedIndex > 0 ? selectedIndex - 1 : 0;
      setSelectedIndex(nextKey);
    } else if (e.key === 'ArrowDown') {
      const nextKey =
        selectedIndex < props.data.length - 1 ? selectedIndex + 1 : props.data.length - 1;
      setSelectedIndex(nextKey);
    }
  }

  return (
    <Paper
      className={`${classes.root} ${!!setSelectedIndex ? '' : classes.rootNoSelection}`}
      onKeyDown={handleKeyDown}
    >
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {props.columns.map((column: OperatorTableColumn) => (
              <TableCell key={column.key} className={classes.tableCell}>
                {column.translationKey ? translate(column.translationKey) : column.title ?? ''}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row, index) => (
            <TableRow
              key={props.keyGetter(row)}
              className={`${index === selectedIndex ? classes.focused : ''} ${
                props.classGetter ? props.classGetter(row) : ''
              }`}
              onClick={() => {
                if (setSelectedIndex === undefined) return;
                setSelectedIndex(index);
              }}
            >
              {props.columns.map((column: OperatorTableColumn) => (
                <TableCell
                  key={props.keyGetter(row) + column.key}
                  className={`${classes.tableCell} ${
                    column.useLongTextStyle ? classes.tableCellLongText : ''
                  }`}
                >
                  {column.dataGetter(row)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default OperatorTable;
