import React from 'react';

import { makeStyles } from '@mui/styles';
import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';
import theme from '../theme';
import { truncateLongTextWithEllipsis } from '../utils/stringUtils';

const truckSvgId = 'Tamtron_x2F_SVG_x2F_Truck';
const trailerSvgId = 'Tamtron_x2F_SVG_x2F_Trailer';

const WeighingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g>
        <path
          d="M16.2,9.6l1.5,10.1H6.3L7.8,9.6H16.2
        M17.2,7.1H6.8c-0.7,0-1.3,0.5-1.4,1.2L3.7,20.8c0,0.8,0.5,1.4,1.3,1.5
        c0,0,0.1,0,0.1,0h13.9c0.8,0,1.4-0.6,1.4-1.4c0,0,0-0.1,0-0.1L18.6,8.4C18.5,7.7,17.9,7.1,17.2,7.1L17.2,7.1z"
        />
        <path
          d="M14.8,3.6c0,0.9-0.4,1.7-1.2,2.2c0,0-0.1,0-0.1,0.1l0,0c-0.3,0.2-0.4,0.5-0.4,0.9v1.6h-2.2V6.8
	        c0-0.3-0.1-0.7-0.4-0.9l0,0c0,0,0,0-0.1-0.1C9.7,5.4,9.2,4.5,9.2,3.6c0-1.5,1.2-2.8,2.8-2.8S14.8,2.1,14.8,3.6z"
        />
      </g>
    </SvgIcon>
  );
};

const useVehicleStyles = makeStyles({
  // eslint-disable-next-line
  vehicle: {
    fill: 'none',
    stroke: '#4D4D4F',
  },
  // eslint-disable-next-line
  selectedVehicle: {
    animation: '$myEffect 3000ms',
    animationIterationCount: 'infinite',
  },
  '@keyframes myEffect': {
    '0%': {
      opacity: 1,
      strokeWidth: '1px',
      stroke: '#4D4D4F',
    },
    '50%': {
      opacity: 1,
      strokeWidth: '1px',
      stroke: theme.palette.primary.main,
    },
    '100%': {
      opacity: 1,
      strokeWidth: '1px',
      stroke: '#4D4D4F',
    },
  },
});

interface TruckProps extends SvgIconProps {
  vehicleName: string | undefined;
  isHighlighted: boolean | undefined;
}

interface TrailerProps extends SvgIconProps {
  isHighlighted: boolean | undefined;
}

interface InternalTruckProps extends SvgIconProps {
  vehicleName?: string;
  isHighlighted?: boolean;
}

interface InternalTrailerProps extends SvgIconProps {
  isHighlighted?: boolean;
}

const HighlightableTruck = (props: TruckProps) => {
  return InternalTruck(props);
};

const Truck = (props: SvgIconProps) => {
  const internalProps = { ...props } as InternalTrailerProps;
  return InternalTruck(internalProps);
};

const Trailer = (props: SvgIconProps) => {
  const internalProps = { ...props } as InternalTrailerProps;
  return InternalTrailer(internalProps);
};

const HighlightableTrailer = (props: TrailerProps) => {
  return InternalTrailer(props);
};

const InternalTruck = (props: InternalTruckProps) => {
  const classes = useVehicleStyles();
  const classesToUse = props.isHighlighted
    ? classes.vehicle + ' ' + classes.selectedVehicle
    : classes.vehicle;

  // cut vehicle name if it's too long. legit licence plates should fit into 14 characters
  const vehicleName = truncateLongTextWithEllipsis(props.vehicleName, 14);

  const svgProps = { ...props };
  delete svgProps.vehicleName;
  delete svgProps.isHighlighted;

  return (
    <SvgIcon {...svgProps} viewBox="80 0 130 130">
      <g id={truckSvgId} className={classesToUse}>
        {props.vehicleName ? (
          <text textAnchor="middle" x="175" y="50" fill={'currentColor'}>
            {vehicleName}
          </text>
        ) : null}
        <g id="tamtron_truck1" transform="translate(1.000000, 1.000000)">
          <polyline id="Path" points="11.4,57.1 11.4,99.4 31.2,99.4 32.4,98.4 		" />
          <path
            id="Path_1_"
            d="M64.4,57.1c0,0,0.5-26.9,0-35.8C63.9,12.4,63.1,0,63.1,0S38.1,0.3,27.4,1.3S13.2,4.4,12.7,5.7
            c-0.5,1.3-6,15.6-7.6,25S1.8,43.6,1,53.1c-0.1,1.9-0.3,4.6-0.3,7.7C0.5,73.1,1,91.3,1,91.3l1.3,8.2h9"
          />
          <polyline id="Path_2_" points="0.5,60.7 8.2,60.7 8.2,68.5 5.7,72.3 0.5,72.3 		" />
          <path
            id="Path_3_"
            d="M225.4,92.8c1.2,0.8,2.2,1.5,3.2,2.4c1.3,1.3,2.3,8.5,2.6,9.8c0.1,1.2-0.1,2.3-0.5,3.4v2.1h6.7
            v-2.1c-0.4-1-0.6-2.2-0.5-3.4c0.3-1.3,1.3-8.5,2.6-9.8c1-0.9,2.1-1.7,3.2-2.4"
          />
          <path
            id="Path_4_"
            d="M3.3,39.6l6.3-1c0,0,2.1-12.2,3.6-18.2s3.1-9.3,2.3-10.6c-0.8-1.3-3.5-0.5-4-1.3"
          />
          <polyline id="Path_5_" points="61.3,75.4 64.4,70 64.4,57.1 55.4,57.1 		" />
          <path
            id="Path_6_"
            d="M11.4,57.2l43.8-0.1v-46c0-1.4-1.2-2.6-2.6-2.6c-4.2,0-11.8-0.1-16.8,0
            c-6.9,0.3-14.7-0.5-17,2.3S13,29.8,12.5,33.7C12,37.5,11.4,55.6,11.4,57.2L11.4,57.2"
          />
          <path
            id="Path_7_"
            d="M41.8,48.2h8.9c0.5,0,0.9,0.4,0.9,0.9v0.8c0,0.5-0.4,0.9-0.9,0.9h-8.9c-0.5,0-0.9-0.4-0.9-0.9
            v-0.8C40.9,48.6,41.3,48.2,41.8,48.2z"
          />
          <polyline id="Path_8_" points="34.1,92.3 36.5,92.2 39.3,91.7 		" />
          <line id="Path_9_" x1="243" y1="92" x2="225" y2="92" />
          <line id="Path_10_" x1="287.7" y1="81.9" x2="45.3" y2="81.9" />
          <polyline id="Path_11_" points="264.5,81.9 287.1,81.9 287.1,81.9 287.1,92 279,92 		" />
          <polyline id="Path_12_" points="36.8,83.1 36.8,83.1 44.9,81.9 54.4,81.9 		" />
          <polyline id="Path_13_" points="47.3,81.9 74.1,81.9 78.7,81.9 98.2,81.9 139.6,81.9 		" />
          <line id="Path_14_" x1="189.5" y1="92" x2="141.4" y2="92" />
          <line id="Path_15_" x1="106.1" y1="92" x2="74.4" y2="92" />
          <path
            id="Path_16_"
            d="M11.2,99.4h19.9l1.3-1c0,0,3-12.9,5.9-18.9c0,0,1.9-3,10.9-3.6l12.1-0.5l3.1-5.4V57"
          />
          <ellipse id="Oval" cx="56.7" cy="102.2" rx="20.4" ry="20.5" />
          <ellipse id="Oval_1_" cx="56.7" cy="102.2" rx="11.4" ry="11.5" />
          <ellipse id="Oval_2_" cx="56.7" cy="102.2" rx="7.7" ry="7.7" />
          <ellipse id="Oval_3_" cx="123.7" cy="102.2" rx="20.4" ry="20.5" />
          <ellipse id="Oval_4_" cx="123.7" cy="102.2" rx="11.4" ry="11.5" />
          <ellipse id="Oval_5_" cx="123.7" cy="102.2" rx="7.7" ry="7.7" />
          <path
            id="Path_17_"
            d="M227.7,102.2c0,11.3-9.1,20.5-20.4,20.5s-20.4-9.1-20.4-20.5c0-10.4,7.8-19.2,18.1-20.4
            c1.5-0.1,3.1-0.1,4.6,0c6.4,0.8,12.2,4.5,15.4,10.1c0.1,0.3,0.3,0.5,0.5,0.8C226.9,95.8,227.7,99,227.7,102.2z"
          />
          <ellipse id="Oval_6_" cx="207.3" cy="102.2" rx="11.4" ry="11.5" />
          <ellipse id="Oval_7_" cx="207.3" cy="102.2" rx="7.7" ry="7.7" />
          <path
            id="Path_18_"
            d="M281.2,102.2c0,11.3-9.1,20.5-20.4,20.6c-11.3,0-20.4-9.1-20.6-20.5c0-3.4,0.8-6.7,2.3-9.5
            c0.1-0.3,0.3-0.5,0.5-0.8c1.7-2.8,4-5.3,6.7-7l0,0c2.6-1.7,5.7-2.7,8.7-3.1c1.5-0.1,3.1-0.1,4.6,0
            C273.3,83,281.2,91.8,281.2,102.2L281.2,102.2z"
          />
          <ellipse id="Oval_8_" cx="260.7" cy="102.2" rx="11.4" ry="11.5" />
          <ellipse id="Oval_9_" cx="260.7" cy="102.2" rx="7.7" ry="7.7" />
          <path
            id="Path_19_"
            d="M48.5,33.3l-26.9,6.2c-1.5,0.4-3.2-0.6-3.6-2.2c-0.1-0.4-0.1-0.8,0-1.2l3-20.4
            c0.1-1.4,1.4-2.6,2.8-2.6l23.8-0.9c1.7-0.1,3,1.2,3.1,2.8v0.1v15.2C50.7,31.7,49.8,32.9,48.5,33.3z"
          />
          <rect id="Rectangle" x="74" y="6" width="207" height="69" />
        </g>
      </g>
    </SvgIcon>
  );
};

const InternalTrailer = (props: InternalTrailerProps) => {
  const classes = useVehicleStyles();
  const classesToUse = props.isHighlighted
    ? classes.vehicle + ' ' + classes.selectedVehicle
    : classes.vehicle;

  const svgProps = { ...props };
  delete svgProps.isHighlighted;

  return (
    <SvgIcon {...svgProps} viewBox="80 0 130 130">
      <g id={trailerSvgId} className={classesToUse}>
        <g id="tamtron_trailer1" transform="translate(2.000000, 82.000000)">
          <line id="Path" x1="61.5" y1="0.7" x2="41.6" y2="0.7" />
          <path
            id="Path_1_"
            d="M43,11.6L43,11.6c1.2,0.8,2.2,1.5,3.2,2.3c1.3,1.3,2.3,8.5,2.6,9.8c0.1,1.2-0.1,2.3-0.5,3.3v2.1
            H55v-2.1c-0.4-1-0.6-2.2-0.5-3.3c0.3-1.3,1.3-8.5,2.6-9.8c1-0.8,2.1-1.5,3.1-2.3l0.1-0.1l0,0"
          />
          <line id="Path_2_" x1="102.5" y1="0.7" x2="80.6" y2="0.7" />
          <polyline id="Path_3_" points="7.1,10.8 0.6,10.8 0.6,0.7 22.5,0.7 		" />
          <polyline id="Path_4_" points="0,0.7 62.5,0.7 148,0.7 210,0.7 		" />
          <line id="Path_5_" x1="191.5" y1="11" x2="96" y2="10.8" />
          <line id="Path_6_" x1="60.5" y1="10.8" x2="42.5" y2="10.8" />
          <circle id="Oval" cx="78.3" cy="21" r="20.5" />
          <circle id="Oval_1_" cx="78.3" cy="21" r="11.5" />
          <circle id="Oval_2_" cx="78.3" cy="21" r="7.7" />
          <circle id="Oval_3_" cx="24.7" cy="21" r="20.5" />
          <circle id="Oval_4_" cx="24.7" cy="21" r="11.5" />
          <circle id="Oval_5_" cx="24.7" cy="21" r="7.7" />
        </g>
        <g
          id="tamtron_trailer1-copy"
          // eslint-disable-next-line
          transform="translate(238.500000, 103.000000) scale(-1, 1) translate(-238.500000, -103.000000) translate(187.000000, 82.000000)"
        >
          <line id="Path_7_" x1="61.5" y1="0.7" x2="41.6" y2="0.7" />
          <path
            id="Path_8_"
            d="M43,11.6L43,11.6c1.2,0.8,2.2,1.5,3.2,2.3c1.3,1.3,2.3,8.5,2.6,9.8c0.1,1.2-0.1,2.3-0.5,3.3v2.1
            H55v-2.1c-0.4-1-0.6-2.2-0.5-3.3c0.3-1.3,1.3-8.5,2.6-9.8c1-0.8,2.1-1.5,3.1-2.3l0.1-0.1l0,0"
          />
          <line id="Path_9_" x1="102.5" y1="0.7" x2="80.6" y2="0.7" />
          <polyline id="Path_10_" points="7.1,10.8 0.6,10.8 0.6,0.7 22.5,0.7 		" />
          <polyline id="Path_11_" points="0,0.7 30.5,0.7 72.2,0.7 102.5,0.7 		" />
          <line id="Path_12_" x1="60.5" y1="10.8" x2="42.5" y2="10.8" />
          <circle id="Oval_6_" cx="78.3" cy="21" r="20.5" />
          <circle id="Oval_7_" cx="78.3" cy="21" r="11.5" />
          <circle id="Oval_8_" cx="78.3" cy="21" r="7.7" />
          <circle id="Oval_9_" cx="24.7" cy="21" r="20.5" />
          <circle id="Oval_10_" cx="24.7" cy="21" r="11.5" />
          <circle id="Oval_11_" cx="24.7" cy="21" r="7.7" />
        </g>
        <rect id="Rectangle" x="8" y="7" width="276" height="69" />
      </g>
    </SvgIcon>
  );
};

const resetAnimationsForElement = (elementId: string): void => {
  const element = document.getElementById(elementId);
  // NOTE(lindenlas,20211111) element might not have animation to reset
  // or getAnimations is not supported by browser.
  // In this case we should not go through the elementAnimations.
  // This also handles null/undefined cases as in that case type is not a function
  if (typeof element?.getAnimations !== 'function') {
    return;
  }

  element.getAnimations().forEach((a) => (a.currentTime = 0));
};

export function resetTruckAndTrailerAnimations(): void {
  resetAnimationsForElement(truckSvgId);
  resetAnimationsForElement(trailerSvgId);
}

export { Trailer, HighlightableTrailer, Truck, HighlightableTruck, WeighingIcon };
