import { makeStyles } from '@mui/styles';
import { highlightText, HighlightedTextPart } from '../models/highlighttext.model';

interface ParameterProps {
  searchTerm: string;
  fullText: string;
}

type AllProps = ParameterProps;

const useStyles = makeStyles({
  highlightedPart: {
    fontWeight: 'bold',
  },
});

const HighlightedText = (props: AllProps) => {
  const classes = useStyles();
  if (!props.searchTerm) {
    return <span>{props.fullText}</span>;
  }

  const highlightedParts = highlightText(props.searchTerm, props.fullText);

  return (
    <span>
      {highlightedParts.map((part: HighlightedTextPart, index: number) => (
        <span key={index} className={part.isHighlighted ? classes.highlightedPart : ''}>
          {part.text}
        </span>
      ))}
    </span>
  );
};

export default HighlightedText;
