import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { newToast } from '../../store/common/actions';
import { Job } from '../../store/jobs/types';
import { DomainInfo } from '../../store/scale-info/types';
import { connect, localeConnect } from '../../localeConnect';
import theme from '../../theme';
import { formatReceiptCount, isShareSupported, shareReceipt } from '../../utils/receiptUtils';
import { translate } from '../../utils/translate';
import DisabledWrap from '../DisabledWrap';
import ReceiptItem from '../ReceiptItem';
import { ApplicationState } from '../../store';

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '10px',
  },
});

interface ParameterProps {
  job: Job;
  receiptNumber: number;
  receiptTotalCount: number;
}

interface PropsFromState {
  domainInfo: DomainInfo;
}

interface PropsFromDispatch {
  newToast: typeof newToast;
}

const mapStateToProps = (state: ApplicationState) => ({
  domainInfo: state.currentScaleInfo.domainInfo,
});

const mapDispatchToProps = {
  newToast,
};

type AllProps = PropsFromDispatch & PropsFromState & ParameterProps;

const ReceiptContent = (props: AllProps) => {
  const classes = useStyles();
  const blockColor = theme.palette.background.default;
  const shareReceiptButton = () => {
    return (
      <Button
        color="primary"
        variant="contained"
        onClick={() => shareReceipt(props.job, props.domainInfo)}
        disabled={!isShareSupported()}
      >
        {`${translate('receipt.shareReceipt')} ${formatReceiptCount(
          props.receiptNumber,
          props.receiptTotalCount,
        )}`}
      </Button>
    );
  };
  return (
    <div key={props.job.key}>
      <div className={`App-print-hide ${classes.buttonContainer}`}>
        {isShareSupported() ? (
          shareReceiptButton()
        ) : (
          <DisabledWrap onClick={() => props.newToast('receipt.shareNotSupported')}>
            {shareReceiptButton()}
          </DisabledWrap>
        )}
      </div>
      <div>
        <ReceiptItem
          domainInfo={props.domainInfo}
          job={props.job}
          blockColor={blockColor}
          receiptNumber={props.receiptNumber}
          receiptTotalCount={props.receiptTotalCount}
        />
      </div>
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(ReceiptContent);
