import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { connect, localeConnect } from 'localeConnect';
import { RoutePaths } from 'routes';
import { ApplicationState, ConnectedReduxProps } from 'store';
import { addImage, setAddImageJobKey } from 'store/images/actions';
import { BridgeEnabledFeatures } from 'store/scale-info/types';
import { UiRoles } from 'store/user/types';
import { effectiveUiRole } from 'store/utils';
import AddPhoto from './AddPhoto';
import { findContext as findDriverContext } from './driver/utils';
import { findContext as findInspectorContext } from './inspector/utils';

interface PropsFromState {
  role: UiRoles;
  orderKey: string | undefined;
  jobKey: string | undefined;
  enabledFeatures: BridgeEnabledFeatures | undefined;
}

interface PropsFromDispatch {
  addImage: typeof addImage;
  setAddImageJobKey: typeof setAddImageJobKey;
}

/*eslint-disable */
export type AllProps = PropsFromState &
  PropsFromDispatch &
  RouteComponentProps<{}> &
  ConnectedReduxProps;
/*eslint-enable */

const mapStateToProps = (state: ApplicationState) => {
  const role = effectiveUiRole(
    state.user.selectedUiRole,
    state.user.user,
    state.currentScaleInfo.enabledFeatures,
  );
  if (role === UiRoles.DRIVER) {
    return {
      role: role,
      orderKey: findDriverContext(state)?.order?.key,
      enabledFeatures: state.currentScaleInfo.enabledFeatures,
      jobKey: state.images.addImageJobKey,
    };
  }
  if (role === UiRoles.INSPECTOR) {
    return {
      role: role,
      orderKey: findInspectorContext(state)?.inspectorOrder?.order.key,
      enabledFeatures: state.currentScaleInfo.enabledFeatures,
      jobKey: state.images.addImageJobKey,
    };
  }
};

export const mapDispatchToProps = {
  addImage,
  setAddImageJobKey,
};

export const CommonPhotoComponent = (props: AllProps) => {
  React.useEffect(() => {
    return function cleanup() {
      props.setAddImageJobKey(undefined);
    };
  });

  const goBack = () => {
    props.history.goBack();
  };

  if (!props.orderKey) {
    console.error("Can't add photo without order key");
    return <Redirect to={RoutePaths.ORDERS} />;
  } else if (!props.jobKey) {
    console.error("Can't add photo without job key");
    return <Redirect to={RoutePaths.ORDERS} />;
  } else if (props.role === UiRoles.DRIVER && !props.enabledFeatures?.bridgePhotosAsDriver) {
    console.error('Adding photos not permitted');
    return <Redirect to={RoutePaths.ORDERS} />;
  }

  const addImage = (dataUri: string) => {
    if (props.orderKey && props.jobKey) {
      props.addImage(props.orderKey, props.jobKey, dataUri);
    }
    goBack();
  };

  return (
    <AddPhoto
      orderKey={props.orderKey}
      addImage={addImage}
      goBack={goBack}
      showDeepLinkReturnLink={true}
    />
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(CommonPhotoComponent);
