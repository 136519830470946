import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
  throttle,
} from 'redux-saga/effects';

import { callApi } from 'utils/api';
import { incomingEvent, sendMessage } from '../common/actions';
import {
  DetailedMasterDataType,
  LiveDataTypes,
  MasterDataItem,
  Pagination,
  RemoteMasterDataType,
  RemoteReceiveTypes,
  RemoteRequestIds,
  RemoteRequestTypes,
} from '../common/types';
import { getHasOrganizationRoleRight } from '../user/utils';
import {
  getMasterDataModifyInfo,
  getMasterDataTypes,
  getTokenHeader,
  getUser,
  getWebsocketConnected,
} from '../selectors';
import {
  formatUniqueCreateMasterDataRequestId,
  formatUniqueUpdateMasterDataRequestId,
} from '../common/utils';
import { requestMasterDataUpdate } from '../orders/actions';
import { defaultPagination } from '../utils';
import { ApplicationState } from '../index';
import {
  cloudSearchSuccess,
  createMasterData,
  fetchRequest,
  fetchSuccess,
  loadHistoryItems,
  searchCloudMasterData,
  searchMasterData,
  searchSuccess,
  setModifyErrorReference,
  setModifyMasterDataSuccessful,
  updateHistoryItems,
  updateMasterData,
} from './actions';
import {
  MasterDataActionTypes,
  MasterDataUpdateType,
  VisibleMasterDataSearchResults,
} from './types';
import { parseStateType, toMasterDataStateType } from './utils';

const API_ENDPOINT = `${process.env.REACT_APP_PILVILINNA_URL || 'http://localhost/'}api/v1/`;

export const getSubscriptions = (state: ApplicationState) => state.masterData.subscriptions;

function* handleCloudSearch(action: ReturnType<typeof searchCloudMasterData>): any {
  function logError(err: any) {
    console.warn('master data search failed', err);
  }
  try {
    const tokenHeader = yield select(getTokenHeader);
    if (!tokenHeader) {
      logError('no token');
      return;
    }
    const user = yield select(getUser);
    if (!getHasOrganizationRoleRight(user.userData, 'listMasterData')) {
      return;
    }
    const orgId = user.userData.organization;
    const res = yield call(
      callApi,
      'post',
      API_ENDPOINT,
      `export/organizations/${orgId}/master_data_search`,
      action.payload,
      tokenHeader,
    );

    if (res.error) {
      logError(res.error);
    } else {
      const results = res[action.payload.acceptType];
      yield put(
        cloudSearchSuccess({
          searchString: action.payload.searchString,
          acceptType: action.payload.acceptType,
          results: results ? results : [],
        }),
      );
    }
  } catch (err) {
    logError(err);
  }
}

function* handleCreateMasterData(action: ReturnType<typeof createMasterData>): any {
  const payload = {
    msgType: RemoteRequestTypes.CREATE_MASTER_DATA,
    reqId: formatUniqueCreateMasterDataRequestId(action.payload.requestReference),
    payload: {
      properties: action.payload.properties,
    },
  };
  const connected = yield select(getWebsocketConnected);

  // If the connection to websocket is not yet up, wait for it
  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleFetch(action: ReturnType<typeof fetchRequest>): any {
  const reqId = RemoteRequestIds.LIST_MASTER_DATA_ONE_PAGE;
  const payload = {
    msgType: RemoteRequestTypes.LIST_MASTER_DATA,
    reqId,
    payload: {
      masterDataType: action.payload.masterDataType.type,
      subtype: action.payload.masterDataType.subtype,
      pagination: action.payload.pagination,
    },
  };
  const connected = yield select(getWebsocketConnected);

  // If the connection to websocket is not yet up, wait for it
  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleLoadHistoryItems(action: ReturnType<typeof loadHistoryItems>): any {
  const stateTypes = yield select(getMasterDataTypes);
  const types = Object.keys(stateTypes);
  for (const i in types) {
    const type = types[i];
    const lastSelected = stateTypes?.[type]?.lastSelected;
    if (lastSelected && lastSelected.length > 0) {
      yield call(
        loadMasterDataByKeys,
        type,
        lastSelected.map((item: MasterDataItem) => item.key),
      );
    }
  }
}

function* loadMasterDataByKeys(stateType: string, masterDataKeys: string[]): any {
  const type = parseStateType(stateType);
  const payload = {
    msgType: RemoteRequestTypes.LOAD_MASTER_DATA_BY_KEYS,
    reqId: RemoteRequestIds.LOAD_MASTER_DATA_BY_KEYS,
    payload: {
      masterDataType: type.type,
      subtype: type.subtype,
      masterDataKeys,
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* handleMasterDataUpdated(
  type: string,
  subtype: string | undefined,
  itemCount: number | undefined,
): any {
  const subscriptions = yield select(getSubscriptions);

  const updateNeeds = Object.values<{
    types: DetailedMasterDataType[];
    updateType: MasterDataUpdateType;
  }>(subscriptions).filter((subscription) =>
    subscription.types.some(
      (subscriptionData) => subscriptionData.type === type && subscriptionData.subtype === subtype,
    ),
  );

  if (updateNeeds.length > 0) {
    const stateType = toMasterDataStateType(type, subtype);
    const masterdataState = (yield select(getMasterDataTypes))[stateType];
    if (updateNeeds.some((need) => need.updateType === MasterDataUpdateType.SEARCH)) {
      const type = parseStateType(stateType);
      const text = masterdataState?.search?.text;
      if (typeof text === 'string') {
        yield call(handleSearch, searchMasterData(type, text));
      }
    }

    if (updateNeeds.some((need) => need.updateType === MasterDataUpdateType.LIST)) {
      const storedServerPagination = masterdataState.onePageList?.pagination;
      let pagination: Pagination;
      if (storedServerPagination?.pageSize > 0) {
        pagination = {
          pageNumber: storedServerPagination.pageNumber,
          pageSize: storedServerPagination.pageSize,
        } as Pagination;
      } else {
        pagination = defaultPagination();
      }

      if (itemCount !== undefined) {
        // set last page if requested page would not exist anymore. If there are no items set page to 1
        if (itemCount === 0) {
          pagination.pageNumber = 1;
        } else if (pagination.pageNumber * pagination.pageSize > itemCount) {
          pagination.pageNumber = Math.ceil(itemCount / pagination.pageSize);
        }
      }
      if (pagination.pageNumber === 0) {
        pagination.pageNumber = 1;
      }
      yield createSendFetchRequest(type, subtype, pagination);
    }
  }
}

function* handleSearch(action: ReturnType<typeof searchMasterData>): any {
  const payload = {
    msgType: RemoteRequestTypes.SEARCH_MASTER_DATA,
    reqId: RemoteRequestIds.SEARCH_MASTER_DATA,
    payload: {
      masterDataType: action.payload.type.type,
      maxResultCount: VisibleMasterDataSearchResults + 1,
      subtype: action.payload.type.subtype,
      text: action.payload.text,
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function createSendFetchRequest(type: string, subtype: string | undefined, pagination: Pagination) {
  return put(fetchRequest({ type: type, subtype: subtype }, pagination));
}

function* handleResponse(action: ReturnType<typeof incomingEvent>): any {
  const listType = RemoteReceiveTypes.MASTER_DATA_LIST;
  if (action.payload.msgType === listType) {
    const masterDataType: RemoteMasterDataType = action.payload.payload.masterDataType;
    const subtype = action.payload.payload.subtype;
    const completeType = { type: masterDataType, subtype };
    const data: MasterDataItem[] = action.payload.payload.masterData;
    yield put(fetchSuccess(completeType, data, action.payload.payload.pagination));
  } else if (action.payload.msgType === RemoteReceiveTypes.MASTER_DATA_CREATED) {
    const masterDataType = action.payload.payload.type;
    const subtype = action.payload.payload.subtype;
    const createMasterDataInfo = yield select(getMasterDataModifyInfo);
    const respId = action.payload.respId;
    if (respId === formatUniqueCreateMasterDataRequestId(createMasterDataInfo.requestReference)) {
      const orderReference = createMasterDataInfo.orderReference;
      if (orderReference) {
        const document = action.payload.payload;
        yield put(
          requestMasterDataUpdate(
            orderReference.orderKey,
            orderReference.componentId,
            { type: masterDataType, subtype: subtype },
            document,
          ),
        );
      }
      yield put(setModifyMasterDataSuccessful());
    }
  } else if (action.payload.msgType === RemoteReceiveTypes.MASTER_DATA_BY_KEYS) {
    if (action.payload.respId === RemoteRequestIds.LOAD_MASTER_DATA_BY_KEYS) {
      const { masterDataType, subtype } = action.payload.payload.request;
      const type: DetailedMasterDataType = { type: masterDataType, subtype };
      const masterData = action.payload.payload.masterData;
      yield put(updateHistoryItems(type, masterData));
    }
  } else if (action.payload.msgType === RemoteReceiveTypes.MASTER_DATA_SEARCH_RESULTS) {
    const request = action.payload.payload.request;
    const masterDataType = request.masterDataType;
    const subtype = request.subtype;
    const stateType = toMasterDataStateType(masterDataType, subtype);
    const text = action.payload.payload.request.text;
    const masterDataState = (yield select(getMasterDataTypes))?.[stateType];
    if (masterDataState?.search?.text === text) {
      yield put(searchSuccess(stateType, action.payload.payload.masterData, text));
    }
  } else if (action.payload.msgType === RemoteReceiveTypes.ACK) {
    const createMasterDataInfo = yield select(getMasterDataModifyInfo);
    const respId = action.payload.respId;
    if (respId === formatUniqueUpdateMasterDataRequestId(createMasterDataInfo.requestReference)) {
      yield put(setModifyMasterDataSuccessful());
    }
  } else if (action.payload.msgType === RemoteReceiveTypes.NACK) {
    const modifyMasterDataInfo = yield select(getMasterDataModifyInfo);
    const respId = action.payload.respId;
    if (
      respId === formatUniqueCreateMasterDataRequestId(modifyMasterDataInfo.requestReference) ||
      respId === formatUniqueUpdateMasterDataRequestId(modifyMasterDataInfo.requestReference)
    ) {
      yield put(setModifyErrorReference(modifyMasterDataInfo.requestReference));
    }
  } else if (action.payload.msgType === RemoteReceiveTypes.MASTER_DATA_UPDATED) {
    const type = action.payload.payload.masterDataType;
    const subtype =
      action.payload.payload.subtype === '' ? undefined : action.payload.payload.subtype;
    const itemCount = action.payload.payload.itemCount;
    yield call(handleMasterDataUpdated, type, subtype, itemCount);
  }
}

function* handleUpdateMasterData(action: ReturnType<typeof updateMasterData>): any {
  const payload = {
    msgType: RemoteRequestTypes.UPDATE_MASTER_DATA,
    reqId: formatUniqueUpdateMasterDataRequestId(action.payload.requestReference),
    payload: {
      properties: action.payload.properties,
    },
  };
  const connected = yield select(getWebsocketConnected);

  if (!connected) {
    yield take(LiveDataTypes.CONNECTION_SUCCESS);
  }

  yield put(sendMessage(payload));
}

function* watchCreateMasterData() {
  yield takeEvery(MasterDataActionTypes.CREATE_MASTER_DATA, handleCreateMasterData);
}

function* watchFetchRequest() {
  yield takeEvery(MasterDataActionTypes.FETCH_REQUEST, handleFetch);
}

function* watchLoadHistoryItems() {
  yield takeLatest(MasterDataActionTypes.LOAD_HISTORY_ITEMS, handleLoadHistoryItems);
}

function* watchMasterDataReceived() {
  yield takeLatest(LiveDataTypes.INCOMING_EVENT, handleResponse);
}

function* watchCloudSearch() {
  yield throttle(1000, MasterDataActionTypes.CLOUD_SEARCH_REQUEST, handleCloudSearch);
}

function* delaySearch(action: ReturnType<typeof searchMasterData>) {
  yield delay(500);
  yield call(handleSearch, action);
}

function* watchSearch(): any {
  let task;
  while (true) {
    const action = yield take(MasterDataActionTypes.SEARCH_REQUEST);
    if (task) {
      yield cancel(task);
    }
    task = yield fork(delaySearch, action);
  }
}

function* watchUpdateMasterData() {
  yield takeEvery(MasterDataActionTypes.UPDATE_MASTER_DATA, handleUpdateMasterData);
}

function* masterDataSaga() {
  yield all([
    fork(watchCloudSearch),
    fork(watchCreateMasterData),
    fork(watchFetchRequest),
    fork(watchLoadHistoryItems),
    fork(watchMasterDataReceived),
    fork(watchSearch),
    fork(watchUpdateMasterData),
  ]);
}

export const internal = {
  handleMasterDataUpdated,
  handleSearch,
};

export default masterDataSaga;
