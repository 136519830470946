import React, { useState } from 'react';

import { Button, Checkbox, FormControl, FormControlLabel, Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { connect, localeConnect } from 'localeConnect';
import { createOrderDetailsModel } from 'models/orderdetails.model';
import { SplitLoadModel, createSplitLoadModel } from 'models/splitload.model';
import { ApplicationState } from 'store';
import { ContainerType, RemoteMasterDataType } from 'store/common/types';
import { createMasterData, searchMasterData } from 'store/master-data/actions';
import {
  copyTruckToTrailer,
  discardContextOrder,
  requestExternalIdUpdate,
  requestMasterDataUpdate,
  setContextComment,
  setContextCommentImmediately,
  setContextProcess,
  setContextTrailerKey,
  setDefaultWeighingProcess,
  setJobDirection,
} from 'store/orders/actions';
import { Job, JobDirection } from 'store/jobs/types';
import { splitLoadFromJobs } from 'store/jobs/utils';
import {
  ClosePolicy,
  ComponentGroup,
  OperatorOrder,
  SplitLoad,
  WeighingProcess,
} from 'store/orders/types';
import { getContextMainJob, getMainJob, getNormalWeighingMainComponents } from 'store/orders/utils';
import { BridgeEnabledFeatures, OrganizationEnabledFeatures } from 'store/scale-info/types';
import { setOperatorTrailerKey } from 'store/weighing/actions';
import { UserDetails } from 'store/user/types';

import { GenericMasterData, MasterDataModifyInfo, MasterDataState } from 'store/master-data/types';
import { toMasterDataStateType } from 'store/master-data/utils';

import { fadedGreyColor } from 'theme';

import { translate } from 'utils/translate';
import DiscardConfirmDialog from '../DiscardConfirmDialog';
import EnableMultipartWhenMultipleSharesDialog from '../EnableMultipartWhenMultipleSharesDialog';

import JobDirectionRadioForm from './JobDirectionRadioForm';
import OperatorCreateMasterDataDialog from './OperatorCreateMasterDataDialog';
import OperatorMasterDataSelect from './OperatorMasterDataSelect';
import OperatorOrderMasterData from './OperatorOrderMasterData';
import OperatorWeighingType from './OperatorWeighingType';
import OrderStatusItem from './OrderStatusItem';
import { ContinuousOrderInfo, ContinuousOrderInfoType } from './ContinuousOrderInfo';

const useStyles = makeStyles({
  comment: {
    // eslint-disable-next-line
    width: '100%',
    // eslint-disable-next-line
    marginBottom: '15px',
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  truckAndTrailerContainers: {
    // eslint-disable-next-line
    display: 'flex',
    // eslint-disable-next-line
    flexDirection: 'row',
    '@media (max-width: 1024px)': {
      // eslint-disable-next-line
      flexDirection: 'column',
      maxWidth: '435px !important',
    },
  },
  containerData: {
    // eslint-disable-next-line
    border: `1px solid ${fadedGreyColor}`,
    // eslint-disable-next-line
    borderRadius: '20px',
    // eslint-disable-next-line
    padding: '10px',
    // eslint-disable-next-line
    marginBottom: '20px',
    '& > div': {
      color: '#767676',
    },
    '@media (min-width: 960px)': {
      width: '435px',
      // eslint-disable-next-line
      marginRight: '20px',
    },
  },
  externalId: {
    marginRight: '30px',
    marginBottom: '15px',
  },
  noContainerData: {
    display: 'none',
  },
  content: {
    minHeight: '200px',
  },
  form: {
    display: 'block',
  },
  formSection: {
    marginBottom: '15px',
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
});

interface ParameterProps {
  operatorOrder: OperatorOrder;
}

interface PropsFromState {
  enabledFeatures: BridgeEnabledFeatures;
  organizationEnabledFeatures: OrganizationEnabledFeatures;
  scaleKey: string;
  schema?: Record<string, unknown>;
  masterData: MasterDataState;
  masterDataModifyInfo: MasterDataModifyInfo;
  user?: UserDetails;
}

interface PropsFromDispatch {
  createMasterData: typeof createMasterData;
  copyTruckToTrailer: typeof copyTruckToTrailer;
  discardContextOrder: typeof discardContextOrder;
  requestExternalIdUpdate: typeof requestExternalIdUpdate;
  requestMasterDataUpdate: typeof requestMasterDataUpdate;
  searchMasterData: typeof searchMasterData;
  setOperatorTrailerKey: typeof setOperatorTrailerKey;
  setContextComment: typeof setContextComment;
  setContextCommentImmediately: typeof setContextCommentImmediately;
  setContextProcess: typeof setContextProcess;
  setContextTrailerKey: typeof setContextTrailerKey;
  setDefaultWeighingProcess: typeof setDefaultWeighingProcess;
  setJobDirection: typeof setJobDirection;
}

const mapStateToProps = ({
  currentScaleInfo,
  weighing,
  masterData,
  orders,
  user,
}: ApplicationState) => ({
  enabledFeatures: currentScaleInfo.enabledFeatures,
  organizationEnabledFeatures: currentScaleInfo.organizationEnabledFeatures,
  scaleKey: currentScaleInfo.scaleKey,
  schema: currentScaleInfo.domainInfo.masterDataCombo.schema,
  masterData: masterData,
  masterDataModifyInfo: masterData.modifyInfo,
  user: user.user.userData,
});

const mapDispatchToProps = {
  createMasterData,
  copyTruckToTrailer,
  discardContextOrder,
  requestExternalIdUpdate,
  requestMasterDataUpdate,
  searchMasterData,
  setOperatorTrailerKey,
  setContextComment,
  setContextCommentImmediately,
  setContextProcess,
  setContextTrailerKey,
  setDefaultWeighingProcess,
  setJobDirection,
};

type AllProps = ParameterProps & PropsFromDispatch & PropsFromState;

const OperatorOrderDetails = (props: AllProps) => {
  const classes = useStyles();

  const order = props.operatorOrder.order ?? undefined;
  const orderKey = order ? order.key : '';

  const generateCreateTruckReference = () => {
    return '' + new Date().getTime();
  };
  const [createTruckReference, setCreateTruckReference] = React.useState(
    generateCreateTruckReference(),
  );
  const [
    isEnableMultipartWhenMultipleSharesDialogVisible,
    setIsEnableMultipartWhenMultipleSharesDialogVisible,
  ] = React.useState(false);

  const [isCreateTruckOpen, setIsCreateTruckOpen] = React.useState(false);
  const [discardContextId, setDiscardContextId] = React.useState('');
  const openCreateTruckDialog = () => {
    setCreateTruckReference(generateCreateTruckReference());
    setIsCreateTruckOpen(true);
  };

  const [defaultNewTruckName, setDefaultNewTruckName] = React.useState('');
  const [selectedTruckName, setSelectedTruckName] = React.useState('');

  React.useEffect(() => {
    if (
      orderKey &&
      props.masterDataModifyInfo.isSuccessfullyCompleted &&
      createTruckReference === props.masterDataModifyInfo.requestReference
    ) {
      setCreateTruckReference(generateCreateTruckReference());
      setIsCreateTruckOpen(false);
      setDefaultNewTruckName('');
    }
  }, [
    orderKey,
    props.masterDataModifyInfo.requestReference,
    props.masterDataModifyInfo.isSuccessfullyCompleted,
    createTruckReference,
  ]);

  const [currentExternalId, setCurrentExternalId] = useState(order ? order.externalId : '');

  const truckStateType = toMasterDataStateType(RemoteMasterDataType.CONTAINER, ContainerType.TRUCK);
  const vehicleStateType = props.masterData?.dataTypes?.[truckStateType];
  const vehicleSearch = vehicleStateType?.search;
  const vehicles = vehicleSearch?.searchResults || [];
  const isSearchingVehicles: boolean = vehicleSearch?.isLoading || false;

  const linkedData = order && order.linkedData ? order.linkedData : [];
  const weighingJobs = props.operatorOrder ? props.operatorOrder.weighingJobs : [];
  const context =
    props.operatorOrder && props.operatorOrder.context ? props.operatorOrder.context : undefined;
  function getWeighingJob() {
    const mainJob = getContextMainJob(context);
    if (mainJob) {
      return mainJob;
    }
    if (props.operatorOrder.weighingJobs) {
      if (props.operatorOrder.weighingJobs.length > 0) {
        return props.operatorOrder.weighingJobs[0];
      }
    }
    return undefined;
  }
  const process = context
    ? context.process
    : props.operatorOrder?.process ?? WeighingProcess.UNDEFINED;
  const orderDetailsModel = createOrderDetailsModel(
    context,
    order,
    process,
    weighingJobs,
    props.scaleKey,
    props.user,
  );
  const weighingJob = getWeighingJob();

  const components = order ? getNormalWeighingMainComponents(order) : undefined;

  function jobLoadCount(job: Job) {
    return job.loads ? job.loads.length : 0;
  }
  const hasLoads = weighingJobs.reduce((count, job) => count + jobLoadCount(job), 0) > 0;

  const truckLinks = linkedData.filter(
    (link) => link.key.indexOf('container') >= 0 && link.containerType === ContainerType.TRUCK,
  );
  const vehicle = truckLinks.length > 0 ? truckLinks[0] : undefined;

  const handleExternalIdChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (!order) {
      return;
    }
    const externalId = event.target.value as string;
    setCurrentExternalId(externalId);
    if (externalId) {
      props.requestExternalIdUpdate(order.key, externalId);
    }
  };

  const handleTruckChange = (item: GenericMasterData) => {
    if (!order || !components) {
      return;
    }
    const truckType = { type: RemoteMasterDataType.CONTAINER, subtype: ContainerType.TRUCK };
    props.requestMasterDataUpdate(order.key, components.truck.id, truckType, item);
  };

  const commentBlurred = (event: any) => {
    if (!context || !weighingJob) {
      return;
    }
    props.setContextCommentImmediately(context.contextId, event.target.value, weighingJob.key);
  };

  const commentChanged = (event: any) => {
    if (!context || !weighingJob) {
      return;
    }
    props.setContextComment(context.contextId, event.target.value, weighingJob.key);
  };

  const jobDirectionChanged = (componentId: string, direction: JobDirection) => {
    if (!context || !order) {
      return;
    }
    props.setJobDirection(order.key, componentId, direction);
  };

  const handleIsOperatorUsingTrailerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!context) return;
    const isUsingTrailer = event.target.checked as boolean;
    const trailerKey = isUsingTrailer ? '' : undefined;
    props.setContextTrailerKey(context.contextId, trailerKey);
    props.setOperatorTrailerKey(trailerKey);
  };

  const createTruckConfirmed = (name: string) => {
    if (!order || !components) {
      throw new Error('order must exist');
    }
    const newCreateTruckReference = generateCreateTruckReference();
    props.createMasterData(
      { type: RemoteMasterDataType.CONTAINER, subtype: ContainerType.TRUCK, name },
      newCreateTruckReference,
      { orderKey: order.key, componentId: components.truck.id },
    );
    setCreateTruckReference(newCreateTruckReference);
  };

  const hasCreateError = props.masterData.modifyErrorReference === createTruckReference;

  function handleCreateDialogClosed(latestName: string) {
    setDefaultNewTruckName(latestName);
    setIsCreateTruckOpen(false);
  }

  const isContinuousOrder =
    (context?.isOrderTemporaryCopy ?? false) || order?.closePolicy === ClosePolicy.SERVER_MANUAL;

  const splitJobSplitLoad: SplitLoad | undefined = !isContinuousOrder
    ? splitLoadFromJobs(weighingJobs)
    : undefined;
  const splitLoad: SplitLoad | undefined = context?.splitLoad ?? splitJobSplitLoad ?? undefined;

  const splitLoadModels: SplitLoadModel[] = [];
  if (order !== undefined) {
    splitLoadModels.push(
      createSplitLoadModel({
        order: order,
        isOrderTemporaryCopy: isContinuousOrder,
        componentGroup: ComponentGroup.TRUCK,
        splitLoad,
        organizationEnabledFeatures: props.organizationEnabledFeatures,
        scaleKey: props.scaleKey,
        userDetails: props.user,
      }),
    );
  }
  if (order !== undefined && orderDetailsModel.isTrailerUsed) {
    splitLoadModels.push(
      createSplitLoadModel({
        order: order,
        isOrderTemporaryCopy: isContinuousOrder,
        componentGroup: ComponentGroup.TRAILER,
        splitLoad,
        organizationEnabledFeatures: props.organizationEnabledFeatures,
        scaleKey: props.scaleKey,
        userDetails: props.user,
      }),
    );
  }

  function handleProcessChange(process: WeighingProcess) {
    const isSplitEnabled = splitLoadModels.reduce(
      (isEnabled, model) => isEnabled || model.isSplitEnabled,
      false,
    );
    if (process === WeighingProcess.MULTIPART && isSplitEnabled) {
      setIsEnableMultipartWhenMultipleSharesDialogVisible(true);
      return;
    }
    doHandleProcessChange(process);
  }

  function doHandleProcessChange(process: WeighingProcess) {
    if (!context) return;
    props.setContextProcess(context.contextId, process);
    props.setDefaultWeighingProcess(process);
  }

  const isCopyFromTruckToTrailerVisible =
    context &&
    Object.keys(context.splitLoad).length === 0 &&
    order?.components &&
    order?.components.length > 1 &&
    orderDetailsModel.isTrailerUsed &&
    !orderDetailsModel.isOrderUpdateDisabled;

  // NOTE(mikkogy,20220829) when viewing SERVER_MANUAL a.k.a. continuous order
  // and its receipts we don't have a single comment. Instead there may be
  // multiple jobs so comment field is irrelevant. In case of continuous order
  // in progress we are actually working on a copy and then we have a context
  // and single comment (if any).
  const isCommentFieldVisible =
    (context || (order && order.closePolicy !== ClosePolicy.SERVER_MANUAL)) &&
    weighingJobs.length > 0;
  const commentWeighingJob = order ? getMainJob(context, order, weighingJobs, process) : undefined;

  const truckComponent = components?.truck;
  const truckComponentId = truckComponent?.id ?? '';
  const trailerComponent = components?.trailer;

  const showOrderMasterData = order && orderDetailsModel.process === WeighingProcess.NORMAL;

  return (
    <div className={classes.content}>
      <FormControl variant="standard" className={classes.form}>
        <Grid container>
          <Grid item xs={8} lg={10} container className={classes.container}>
            <Grid container className={classes.formSection}>
              <Grid item className={classes.externalId}>
                <TextField
                  variant="standard"
                  required
                  error={!currentExternalId}
                  defaultValue={order ? order.externalId : ''}
                  disabled={orderDetailsModel.isOrderUpdateDisabled}
                  onChange={handleExternalIdChange}
                  label={translate('operatorOrder.orderDetails.externalId')}
                />
              </Grid>
              {order && (
                <Grid className={classes.formSection} item>
                  <OrderStatusItem status={order.status} />
                </Grid>
              )}
              <Grid item xs={10}>
                <div className={classes.formSection}>
                  <OperatorWeighingType
                    value={orderDetailsModel.process}
                    onChange={handleProcessChange}
                    disabled={orderDetailsModel.isProcessChangeDisabled}
                  />
                </div>
              </Grid>

              <Grid>
                <div className={classes.formSection}>
                  <Grid container>
                    <Grid className={classes.formSection}>
                      <OperatorMasterDataSelect
                        componentId={truckComponentId}
                        enabledFeatures={props.enabledFeatures}
                        lastSelected={vehicleStateType?.lastSelected || []}
                        masterData={vehicles}
                        onSelect={(item: GenericMasterData) => {
                          setDefaultNewTruckName('');
                          setSelectedTruckName(item.name);
                          handleTruckChange(item);
                        }}
                        disabled={orderDetailsModel.isOrderUpdateDisabled}
                        disabledKeys={[]}
                        label={translate('operatorOrder.orderDetails.vehicle')}
                        value={vehicle}
                        useCompactStyle={true}
                        onFilterChanged={(filter) => setDefaultNewTruckName(filter)}
                        isRequired={true}
                        isSearching={isSearchingVehicles}
                        isTypable={false}
                        searchMasterData={(text) => {
                          props.searchMasterData(
                            {
                              type: RemoteMasterDataType.CONTAINER,
                              subtype: ContainerType.TRUCK,
                            },
                            text,
                          );
                        }}
                        typableFieldShouldBeUpdated={false}
                        updateTypableMasterData={() => {
                          throw Error('should not get here');
                        }}
                        shouldBeFocused={selectedTruckName || vehicle ? false : true}
                      />
                    </Grid>
                    <Grid className={classes.formSection}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={orderDetailsModel.isTrailerUsed}
                            onChange={handleIsOperatorUsingTrailerChange}
                            disabled={orderDetailsModel.isTrailerChangeDisabled}
                          />
                        }
                        label={translate('operatorOrder.orderDetails.useTrailer')}
                      />
                    </Grid>
                    <Grid className={classes.formSection}>
                      {!!context && !hasLoads && !orderDetailsModel.isOrderUpdateDisabled && (
                        <Button
                          color="primary"
                          variant="outlined"
                          disabled={orderDetailsModel.isOrderUpdateDisabled}
                          onClick={() => openCreateTruckDialog()}
                        >
                          {translate('operatorOrder.orderDetails.createTruck')}
                        </Button>
                      )}
                      <OperatorCreateMasterDataDialog
                        title={translate('operatorOrder.orderDetails.createTruck')}
                        createDisabled={
                          createTruckReference === props.masterDataModifyInfo.requestReference &&
                          !hasCreateError
                        }
                        visible={isCreateTruckOpen}
                        initialName={defaultNewTruckName}
                        hasError={hasCreateError}
                        onCreate={(name: string) => createTruckConfirmed(name)}
                        onClose={handleCreateDialogClosed}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.formSection}>
                  <JobDirectionRadioForm
                    handleDirectionChange={(direction) => {
                      if (!order) {
                        throw new Error('order must be found');
                      }
                      if (!truckComponent) {
                        throw new Error('component must be found');
                      }
                      jobDirectionChanged(truckComponent.id, direction);
                    }}
                    disabled={orderDetailsModel.isOrderUpdateDisabled}
                    value={truckComponent?.jobDirection || JobDirection.UNDEFINED}
                  />
                </div>
                {showOrderMasterData && (
                  <div>
                    <div className={classes.truckAndTrailerContainers}>
                      <div className={classes.containerData}>
                        <div>{translate('operatorOrder.orderDetails.truckMasterData')}</div>
                        <OperatorOrderMasterData
                          componentGroup={ComponentGroup.TRUCK}
                          contextId={context?.contextId}
                          isAllowedToUpdateOrder={!orderDetailsModel.isOrderUpdateDisabled}
                          order={order}
                          isOrderTemporaryCopy={context?.isOrderTemporaryCopy ?? false}
                          splitLoad={splitLoad}
                        />
                      </div>
                      <div
                        className={
                          orderDetailsModel.isTrailerUsed
                            ? classes.containerData
                            : classes.noContainerData
                        }
                      >
                        <div>{translate('operatorOrder.orderDetails.trailerMasterData')}</div>
                        <OperatorOrderMasterData
                          componentGroup={ComponentGroup.TRAILER}
                          contextId={context?.contextId}
                          isAllowedToUpdateOrder={
                            !orderDetailsModel.isOrderUpdateDisabled && !!trailerComponent
                          }
                          order={order}
                          isOrderTemporaryCopy={context?.isOrderTemporaryCopy ?? false}
                          splitLoad={splitLoad}
                        />
                      </div>
                    </div>
                    {isCopyFromTruckToTrailerVisible && (
                      <div className={classes.comment}>
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={orderDetailsModel.isOrderUpdateDisabled}
                          onClick={() => {
                            if (!context) return;
                            props.copyTruckToTrailer(context.contextId);
                          }}
                        >
                          {translate('orders.copyFromTruckToTrailer')}
                        </Button>
                      </div>
                    )}
                    {commentWeighingJob && isCommentFieldVisible && (
                      <div className={classes.comment}>
                        <TextField
                          variant="standard"
                          defaultValue={commentWeighingJob.comment}
                          onBlur={commentBlurred}
                          onChange={commentChanged}
                          label={translate('operatorOrder.orderDetails.comment')}
                          disabled={!context}
                        />
                      </div>
                    )}
                  </div>
                )}
                {context && (
                  <div>
                    <Button
                      color="primary"
                      variant="outlined"
                      disabled={false}
                      onClick={() => {
                        setDiscardContextId(context.contextId);
                      }}
                    >
                      {translate('operatorOrder.orderDetails.discard')}
                    </Button>
                  </div>
                )}
                {context && order && discardContextId === context.contextId && (
                  <DiscardConfirmDialog
                    orderExternalId={order.externalId}
                    visible={true}
                    onConfirmed={() => {
                      if (!context) return;
                      props.discardContextOrder(context.contextId);
                      setDiscardContextId('');
                    }}
                    onNotConfirmed={() => {
                      setDiscardContextId('');
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          {isContinuousOrder && (
            <Grid item xs={4} lg={2}>
              <ContinuousOrderInfo
                isNewInstance={!!context}
                infoType={ContinuousOrderInfoType.Weighing}
              />
            </Grid>
          )}
        </Grid>
      </FormControl>
      <EnableMultipartWhenMultipleSharesDialog
        visible={isEnableMultipartWhenMultipleSharesDialogVisible}
        onConfirmed={() => {
          doHandleProcessChange(WeighingProcess.MULTIPART);
          setIsEnableMultipartWhenMultipleSharesDialogVisible(false);
        }}
        onNotConfirmed={() => setIsEnableMultipartWhenMultipleSharesDialogVisible(false)}
      />
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(OperatorOrderDetails);
