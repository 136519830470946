import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';

import { connect, localeConnect } from 'localeConnect';

import { ApplicationState, ConnectedReduxProps } from 'store';
import { Job } from 'store/jobs/types';
import { getSelectedVehicle } from 'store/selectors';
import { Context } from 'store/orders/types';
import { UiRoles, UserDetails } from 'store/user/types';
import { effectiveUiRole } from 'store/utils';
import { RoutePaths } from 'routes';
import VehicleModel from 'models/vehicle.model';

import EditOrder from '../EditOrder';

interface Props {
  context: Context | undefined;
  history: any;
  scaleKey: string;
  trailerKey?: string;
  userDetails: UserDetails | undefined;
  weighingJob?: Job;
}

interface PropsFromDispatch {}

/*eslint-disable */
export type AllProps = PropsFromDispatch & Props & RouteComponentProps<{}> & ConnectedReduxProps;
/*eslint-enable */

function getVehicle(state: ApplicationState) {
  const vehicle = getSelectedVehicle(state);
  if (vehicle == null) {
    console.error('No vehicle set');
    return undefined;
  }
  return VehicleModel.vehicleModelFromVehicle(vehicle);
}

function getCurrentContext(state: ApplicationState): Context | undefined {
  if (
    effectiveUiRole(
      state.user.selectedUiRole,
      state.user.user,
      state.currentScaleInfo.enabledFeatures,
    ) !== UiRoles.DRIVER
  ) {
    throw new Error('not driver');
  }
  if (state.orders.currentContextId) {
    if (!getVehicle(state)) {
      return undefined;
    }
    const context = state.orders.contexts[state.orders.currentContextId];
    if (context) {
      return state.orders.contexts[state.orders.currentContextId];
    }
  }
  return undefined;
}

const mapStateToProps = (state: ApplicationState) => ({
  context: getCurrentContext(state),
  scaleKey: state.currentScaleInfo.scaleKey,
  trailerKey: state.vehicles.trailerKey,
  userDetails: state.user.user.userData,
});

const mapDispatchToProps = {};

export class DriverEditOrderComponent extends React.PureComponent<AllProps, Record<string, never>> {
  public render() {
    const props = this.props;
    if (props.context === undefined || !props.userDetails) {
      return <Redirect to={RoutePaths.ORDERS} />;
    }
    return <EditOrder currentContext={props.context} history={props.history} />;
  }
}

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(DriverEditOrderComponent);
