import React, { useCallback, useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { Divider, Paper, Typography } from '@mui/material';

import { connect, localeConnect } from '../localeConnect';
import { DomainInfo } from '../store/scale-info/types';

import { Job } from '../store/jobs/types';
import { formatReceipt } from '../store/utils';
import theme, { receiptColors } from '../theme';
import { translate } from '../utils/translate';
import { formatReceiptCount } from '../utils/receiptUtils';

interface ReceiptProps {
  domainInfo: DomainInfo;
  job: Job;
  blockColor?: string;
  isInHistoryMode?: boolean;
  receiptNumber: number | undefined;
  receiptTotalCount: number | undefined;
}

interface ReceiptVw {
  title: string;
  subTitle: string;
  content: string;
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

const historyVw: ReceiptVw = {
  title: '5.9vw',
  subTitle: '5.5vw',
  content: '2.81vw',
};

const normalVw: ReceiptVw = {
  title: '3.0vw',
  subTitle: '2.5vw',
  content: '2.16vw',
};

const blockWidth = 20;

// NOTE(mikkogy,20191004) ugly magic constants that seem reasonable with the
// styles used at the time of writing. This kind of simple solution seems to
// work better than react-textfit which sometimes does not scale and layout is
// often broken with narrow layouts, for example phones. If styles are changed
// it is likely that these numbers need to be changed too.
const windowWidthToFontSizeDivider = 45;
const initialFontSize = 15;
const initialHistoryFontSize = 9;
const maxFontSize = 18;
const titleFontSizeIncrease = 10;
const subTitleFontSizeIncrease = 6;
const minCalculationWindowWidth = 810;
const minHistoryModeCalculationWidth = 650;

export const receiptWrapperStyle: any = {
  maxWidth: '600px',
  margin: 'auto',
};

const printFontSize = '7pt';

const useStyles = makeStyles({
  count: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    letterSpacing: '5px',
  },
  countContainer: {
    // eslint-disable-next-line
    position: 'relative',
    '@media print': {
      fontSize: `${printFontSize} !important`,
    },
  },
  header: {
    // eslint-disable-next-line
    breakInside: 'avoid',
    // eslint-disable-next-line
    breakBefore: 'auto',
    // eslint-disable-next-line
    breakAfter: 'auto',
    '@media print': {
      '& p.MuiTypography-h6 span': {
        fontSize: `${printFontSize} !important`,
      },
      '& p.MuiTypography-subtitle2 span': {
        fontSize: `${printFontSize} !important`,
      },
    },
  },
  paper: {
    // eslint-disable-next-line
    display: 'block',
    // eslint-disable-next-line
    marginBottom: '30px',
    // eslint-disable-next-line
    paddingBottom: '20px',
    '@media print': {
      marginBottom: printFontSize,
      paddingBottom: 0,
    },
  },
  root: {
    display: 'block',
    maxWidth: '90%',
    margin: 'auto',
  },
  wrapper: {
    ...receiptWrapperStyle,
    // eslint-disable-next-line
    display: 'block',
    '@media print': {
      breakInside: 'avoid',
      breakBefore: 'auto',
      breakAfter: 'auto',
      margin: 0,
      marginBottom: '20pt',
    },
  },
  receiptTitle: {
    // eslint-disable-next-line
    maxWidth: '60%',
    // eslint-disable-next-line
    fontFamily: 'RobotoMono,courier',
    // eslint-disable-next-line
    marginLeft: 'auto',
    // eslint-disable-next-line
    marginRight: 'auto',
    // eslint-disable-next-line
    marginBottom: '20px',
    // eslint-disable-next-line
    paddingTop: '15px',
    '@media print': {
      marginBottom: 0,
      paddingTop: 0,
    },
  },
  receiptSubtitle: {
    // eslint-disable-next-line
    maxWidth: '40%',
    // eslint-disable-next-line
    fontFamily: 'RobotoMono,courier',
    // eslint-disable-next-line
    marginLeft: 'auto',
    // eslint-disable-next-line
    marginRight: 'auto',
    // eslint-disable-next-line
    marginBottom: '20px',
    // eslint-disable-next-line
    color: receiptColors.subtitle,
    '@media print': {
      marginBottom: 0,
    },
  },
  receiptWrapper: {
    // eslint-disable-next-line
    fontFamily: 'RobotoMono,courier',
    // eslint-disable-next-line
    fontSize: '14px',
    // eslint-disable-next-line
    color: '#141414',
    // eslint-disable-next-line
    margin: '10px 0 0 0',
    '@media print': {
      // eslint-disable-next-line
      display: 'inline',
      // eslint-disable-next-line
      breakInside: 'avoid',
      // eslint-disable-next-line
      breakBefore: 'avoid',
      // eslint-disable-next-line
      breakAfter: 'avoid',
      // eslint-disable-next-line
      margin: 0,
      '& div': {
        display: 'inline-block',
        breakInside: 'avoid',
        breakBefore: 'avoid',
        breakAfter: 'avoid',
      },
    },
  },
  receiptContent: {
    // eslint-disable-next-line
    whiteSpace: 'nowrap',
    // eslint-disable-next-line
    textAlign: 'left',
    // eslint-disable-next-line
    margin: 'auto',
    // eslint-disable-next-line
    display: 'inline-block',
    // eslint-disable-next-line
    width: '100%',
    '& p': {
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
    '@media (min-width: 810px)': {
      padding: '0 4% 10px 4%',
    },
    '@media (max-width: 809px)': {
      padding: '0 3.5vw 30px 3.5vw',
    },
    '@media print': {
      '& p': {
        paddingLeft: printFontSize,
        paddingRight: printFontSize,
      },
      // eslint-disable-next-line
      display: 'inline',
      // eslint-disable-next-line
      padding: '0 1pt 0 1pt',
    },
  },
  receiptHtml: {
    '@media print': {
      display: 'inline',
      fontSize: `${printFontSize} !important`,
    },
    // eslint-disable-next-line
    display: 'inline-block',
    // eslint-disable-next-line
    width: '100%',
    '& p': {
      width: '100%',
      overflowWrap: 'break-word',
      whiteSpace: 'break-spaces',
    },
  },
  dashedDivider: {
    // eslint-disable-next-line
    borderBottom: '2px dashed #777',
    // eslint-disable-next-line
    background: 'none',
    // eslint-disable-next-line
    padding: '10px',
    '@media print': {
      padding: 0,
    },
  },
  blockContainer: {
    display: 'block',
  },
  block: {
    backgroundColor: theme.palette.primary.main,
    width: `${blockWidth}px`,
    height: `${blockWidth}px`,
    borderRadius: `${blockWidth / 2}px`,
    top: `-${blockWidth / 2}px`,
    position: 'relative',
  },
  leftBlock: {
    left: `-${blockWidth / 2}px`,
    float: 'left',
    boxShadow: 'inset -2px 0px 1px rgba(0,0,0,0.1)',
  },
  rightBlock: {
    right: `-${blockWidth / 2}px`,
    float: 'right',
    boxShadow: 'inset 2px 0px 1px rgba(0,0,0,0.1)',
  },
  hiddenFontCalculator: {
    position: 'relative !important' as any,
    color: 'transparent !important' as any,
  },
});

type AllProps = ReceiptProps;

export const ReceiptItem = (props: AllProps) => {
  const classes = useStyles();
  const [fontSizeState, setFontSizeState] = useState(
    props.isInHistoryMode ? initialHistoryFontSize : initialFontSize,
  );
  const [useVwState, setUseVwState] = useState(props.isInHistoryMode ? true : false);

  const { isInHistoryMode } = props;

  const updateWindowDimensions = useCallback(() => {
    const fontSize = Math.min(
      Math.floor(window.innerWidth / windowWidthToFontSizeDivider),
      maxFontSize,
    );
    const useVw =
      (!isInHistoryMode && window.innerWidth < minCalculationWindowWidth) ||
      (isInHistoryMode === true && window.innerWidth < minHistoryModeCalculationWidth);
    setFontSizeState(fontSize);
    setUseVwState(useVw);
  }, [isInHistoryMode]);

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, [updateWindowDimensions]);

  const blockStyle = {
    backgroundColor: props.blockColor,
  };

  const receiptVw = props.isInHistoryMode ? historyVw : normalVw;

  return (
    <span className={classes.root}>
      <span className={classes.wrapper}>
        <Paper square={true} component="span" className={classes.paper}>
          <div className={classes.header}>
            {props.receiptNumber !== undefined && props.receiptTotalCount !== undefined && (
              <div className={classes.countContainer}>
                <span className={classes.count}>
                  {`${formatReceiptCount(props.receiptNumber, props.receiptTotalCount)}`}
                </span>
              </div>
            )}
            <Typography variant={'h6'} className={classes.receiptTitle} component="p">
              <span
                style={{
                  fontSize: useVwState ? receiptVw.title : fontSizeState + titleFontSizeIncrease,
                }}
              >
                {translate('receipt.receiptName')}
              </span>
            </Typography>
            <Typography variant={'subtitle2'} className={classes.receiptSubtitle} component="p">
              <span
                style={{
                  fontSize: useVwState
                    ? receiptVw.subTitle
                    : fontSizeState + subTitleFontSizeIncrease,
                }}
              >
                One Scalex
              </span>
            </Typography>
            <Divider component={'hr'} variant={'fullWidth'} className={classes.dashedDivider} />
          </div>

          <div className={classes.blockContainer}>
            <div style={blockStyle} className={`${classes.block} ${classes.leftBlock}`} />
            <div style={blockStyle} className={`${classes.block} ${classes.rightBlock}`} />
          </div>

          <span className={classes.receiptWrapper}>
            <span
              className={classes.receiptContent}
              style={{ fontSize: useVwState ? receiptVw.content : fontSizeState }}
            >
              <span
                className={classes.receiptHtml}
                dangerouslySetInnerHTML={{
                  __html: formatReceipt(props.job, props.domainInfo),
                }}
              />
            </span>
          </span>
        </Paper>
      </span>
    </span>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(ReceiptItem);
