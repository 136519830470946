import moment from 'moment';

import receiptFormat from '../lib/receipt.json';
import MassConverter from '../lib/mass-converter';
import ReceiptFormatter from '../lib/receipt-formatter';
import { DomainInfo, BridgeEnabledFeatures } from './scale-info/types';
import { Job, Load, LoadType } from './jobs/types';
import { RemoteMasterDataType } from './common/types';
import { UiRoles, User } from './user/types';
import { DocumentKeyParts } from './types';

const fallbackReceiptSpec = receiptFormat;

export const storeEnhancer =
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__();

export const isLoadFixOrFixed = (load: Load) => {
  return load.loadType === LoadType.FIX || load.relatedIndex !== undefined;
};

export const filterValidLoads = (loads?: Load[]) => {
  if (!loads || loads.length === 0) {
    return [];
  }
  return loads.filter((load) => {
    return !isLoadFixOrFixed(load);
  });
};

export const formatReceipt = (job: Job, domainInfo: DomainInfo) => {
  const options = {
    domainConfiguration: domainInfo.domainConfiguration,
    moment: moment,
    massConverter: new MassConverter(),
    combo: domainInfo.masterDataCombo,
    lineLength: 50,
  };

  const receiptSpec = domainInfo.receiptSpec.docType ? domainInfo.receiptSpec : fallbackReceiptSpec;
  const content = new ReceiptFormatter().format(job, receiptSpec, options);
  return `<p>${content.replace(/<br>/g, '</p><p>')}</p>`
    .replace(/<p><\/p>/g, '<p> </p>')
    .replace(/&nbsp;/g, ' ');
};

export const parseDocumentKey = (key: string) => {
  const parts = key.split('::');
  if (parts.length !== 2 && parts.length !== 3) {
    throw new Error(`invalid document key part count ${parts.length} in key ${key}`);
  }
  const parsed: DocumentKeyParts = {
    organizationId: parts[0],
    scope: parts.length === 3 ? parts[1] : undefined,
    id: parts[parts.length - 1],
  };
  return parsed;
};

export const masterDataKeyToType = (key: string) => {
  const uuidLength = 36;
  try {
    const parts = parseDocumentKey(key);
    if (parts.scope !== undefined) {
      return '';
    }
    const id = parts.id;
    if (id.length <= uuidLength + 1) {
      return '';
    }
    return id.substring(0, id.length - uuidLength - 1);
  } catch (err) {
    console.error(err);
    return '';
  }
};

// NOTE(mjohans,20200623) always supported data type MUST have
// default values when initializing store. So if you edit this, remember
// to initialize type also. Otherwise older code will break as it won't
// check for undefined
export const alwaysSupportedMasterDataTypes = () => {
  return [RemoteMasterDataType.CUSTOMER, RemoteMasterDataType.MATERIAL];
};

export const defaultPagination = () => {
  const pageSizeStr = `${process.env.REACT_APP_DEFAULT_PAGE_SIZE || '50'}`;
  return { pageNumber: 1, pageSize: parseInt(pageSizeStr) };
};

export const isUiRoleEnabled = (
  user: User,
  role: UiRoles,
  enabledFeatures: BridgeEnabledFeatures,
) => {
  if (!user.isAuthed || !user.userData) {
    return false;
  }
  if (role === UiRoles.DRIVER) {
    return true;
  }

  if (role === UiRoles.INSPECTOR && !enabledFeatures.bridgeInspector) {
    return false;
  }
  const isOperatorEnabled = process.env.REACT_APP_ENABLE_OPERATOR === 'true';
  if (role === UiRoles.OPERATOR && !isOperatorEnabled) {
    return false;
  }
  return hasUserOperatorRights(user);
};

export const hasUserOperatorRights = (user: User) => {
  if (!user.isAuthed || !user.userData) {
    return false;
  }
  // TODO(mikkogy,20200130) implement better role deduction from rights. At the
  // time of writing we didn't have a good way to specify which users are
  // operators. Platform default role such as ACCOUNT_MANAGER should not be used
  // as it will make using custom roles and having operator role impossible.
  const required = ['createMasterData', 'showScaleStatuses'];
  const rights = user.userData.organizationRoleRights;
  const missing = required.filter((right) => rights[right] !== true);
  return missing.length === 0;
};

export const effectiveUiRole = (
  uiRole: UiRoles,
  user: User,
  enabledFeatures: BridgeEnabledFeatures,
) => {
  if (isUiRoleEnabled(user, uiRole, enabledFeatures)) {
    return uiRole;
  }
  return UiRoles.DRIVER;
};
