import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { translate } from '../utils/translate';

interface DialogProps {
  orderExternalId: string;
  visible: boolean;
  onConfirmed: () => void;
  onNotConfirmed: () => void;
}

const DiscardConfirmDialogComponent = (props: DialogProps) => {
  return (
    <div>
      <Dialog open={props.visible}>
        <DialogTitle>{translate('discardDialog.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translate('discardDialog.question', { externalId: props.orderExternalId })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onNotConfirmed} color="primary">
            {translate('confirmdialog.no')}
          </Button>
          <Button onClick={props.onConfirmed} color="primary" autoFocus={true}>
            {translate('confirmdialog.yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DiscardConfirmDialogComponent;
