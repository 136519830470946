import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';

import { ListItem, ListItemText, TextField } from '@mui/material';

import EditIcon from '@mui/icons-material/EditOutlined';

import { MasterDataItem, AllowedMasterDataTypes } from 'store/common/types';
import { getTranslatedTypeTitle, shouldTypableBeUpdated } from 'store/master-data/utils';
import { Order, PendingMasterDataUpdates } from 'store/orders/types';
import { getCurrentlySelectedMasterData } from 'store/orders/utils';
import { SchemaEntry } from 'store/scale-info/utils';

import theme, { fadedGreyColor, separatorFadedColor } from 'theme';
import { translate } from 'utils/translate';

import MasterDataExternalIdVisibleFeature from '../MasterDataExternalIdVisibleFeature';
import MasterDataLocalityVisibleFeature from '../MasterDataLocalityVisibleFeature';

const useStyles = makeStyles({
  typableField: {
    // eslint-disable-next-line
    width: '100%',
    // eslint-disable-next-line
    marginTop: '20px',
    // eslint-disable-next-line
    border: 'solid 1px rgba(0, 0, 0, 0.32)',
    // eslint-disable-next-line
    borderRadius: '5px',
    // eslint-disable-next-line
    padding: '10px 16px 16px 10px',
    '& .MuiInput-underline:before': {
      borderBottom: 0,
    },
    '& .MuiFormLabel-root': {
      paddingLeft: '16px',
    },
  },
  icon: {
    color: '#747789',
  },
  item: {
    marginLeft: '9px',
    marginRight: '9px',
    borderBottom: `solid 1px ${separatorFadedColor}`,
    overflowWrap: 'break-word',
    wordBreak: 'normal',
  },
  listItem: {
    '& .MuiTypography-body1': {
      fontSize: '0.75rem',
      color: fadedGreyColor,
    },
    '& .MuiTypography-body2': {
      fontSize: '1rem',
      color: theme.palette.common.black,
    },
  },
  detailsLabel: {
    fontSize: '0.75rem',
    color: fadedGreyColor,
  },
  detailsValue: {
    fontSize: '0.75rem',
    marginLeft: '10px',
    color: theme.palette.common.black,
    wordBreak: 'break-all',
  },
  required: {
    '&, & .MuiTypography-colorTextSecondary': {
      color: theme.palette.error.main,
    },
  },
});

interface SchemaListItemProps {
  entry: SchemaEntry;
  isEditingMasterDataAllowed: boolean;
  requiredTypes: string[];
  linkedTypes: string[];
  componentId: string;
  getCurrentSelectedItemName: (schemaEntry: SchemaEntry, componentId: string) => string;
  getCurrentSelectedItemData: (
    schemaEntry: SchemaEntry,
    componentId: string,
  ) => MasterDataItem | undefined;
  selectType: (componentId: string, schemaEntry: SchemaEntry) => void;
  subtype: string | undefined;
}

interface TypableSchemaListItemProps {
  order: Order;
  entry: SchemaEntry;
  isEditingMasterDataAllowed: boolean;
  requiredTypes: string[];
  linkedTypes: string[];
  componentId: string;
  pendingMasterDataUpdates: PendingMasterDataUpdates;
  getCurrentSelectedItemName: (schemaEntry: SchemaEntry, componentId: string) => string;
  getCurrentSelectedItemData: (
    schemaEntry: SchemaEntry,
    componentId: string,
  ) => MasterDataItem | undefined;
  editTypableMasterData: (
    componentId: string,
    masterDataType: AllowedMasterDataTypes,
    typableValue: string,
    force: boolean,
  ) => void;
  subtype: string | undefined;
}

export const SchemaListItem = (props: SchemaListItemProps) => {
  const classes = useStyles();
  const additionalClass =
    props.requiredTypes.includes(props.entry.typeName) &&
    !props.linkedTypes.includes(props.entry.typeName)
      ? classes.required
      : '';

  const currentValue = props.getCurrentSelectedItemData(props.entry, props.componentId);

  const currentItemExternalId = currentValue?.externalId;
  const currentItemLocality = currentValue?.card?.adr?.locality;
  const hasAdditionalInformationToShow = !!currentItemExternalId || !!currentItemLocality;

  return (
    <div className={`${classes.item} ${additionalClass}`}>
      <ListItem
        button
        component={'li'}
        key={props.entry.typeName}
        disabled={!props.isEditingMasterDataAllowed}
        onClick={() => props.selectType(props.componentId, props.entry)}
        className={classes.listItem}
      >
        <ListItemText
          primary={getTranslatedTypeTitle(props.entry)}
          secondary={props.getCurrentSelectedItemName(props.entry, props.componentId)}
        />
        {props.isEditingMasterDataAllowed && <EditIcon className={classes.icon} />}
      </ListItem>
      {hasAdditionalInformationToShow && (
        <ul>
          {!!currentItemExternalId && (
            <MasterDataExternalIdVisibleFeature>
              <li>
                <span className={classes.detailsLabel}>
                  {translate('masterData.EXTERNAL_ID_TITLE')}
                </span>
                &nbsp;
                <span className={classes.detailsValue}>{currentItemExternalId}</span>
              </li>
            </MasterDataExternalIdVisibleFeature>
          )}
          {!!currentItemLocality && (
            <MasterDataLocalityVisibleFeature>
              <li>
                <span className={classes.detailsLabel}>
                  {translate('masterData.LOCALITY_TITLE')}
                </span>
                &nbsp;
                <span className={classes.detailsValue}>{currentItemLocality}</span>
              </li>
            </MasterDataLocalityVisibleFeature>
          )}
        </ul>
      )}
    </div>
  );
};

export const TypableSchemaListItem = (props: TypableSchemaListItemProps) => {
  const classes = useStyles();
  const additionalClass =
    props.requiredTypes.includes(props.entry.typeName) &&
    !props.linkedTypes.includes(props.entry.typeName)
      ? classes.required
      : '';

  const component = props.order.components.find((c) => c.id === props.componentId);

  const currentTypableMasterData = getCurrentlySelectedMasterData(
    props.order.linkedData ?? [],
    component?.dataLinks ?? [],
    props.entry.typeName,
    props.entry.subtype,
  );

  const [beingEdited, setBeingEdited] = React.useState(false);
  const currentTypableKey = currentTypableMasterData?.key ?? '';
  const [initialTypableKey, setInitialTypableKey] = React.useState(currentTypableKey);
  const currentTypableName = currentTypableMasterData?.name ?? '';
  const [typableValue, setTypableValue] = React.useState(currentTypableName);

  useEffect(() => {
    const needsToBeUpdated =
      shouldTypableBeUpdated(
        props.pendingMasterDataUpdates,
        props.componentId,
        props.entry.typeName,
      ) || initialTypableKey !== currentTypableKey;
    if (!beingEdited && needsToBeUpdated) {
      setInitialTypableKey(currentTypableKey);
      setTypableValue(currentTypableName);
    }
  }, [
    beingEdited,
    initialTypableKey,
    currentTypableKey,
    currentTypableName,
    props.componentId,
    props.entry.typeName,
    props.order.linkedData,
    props.pendingMasterDataUpdates,
  ]);

  return (
    <div className={`${classes.item} ${additionalClass}`}>
      <TextField
        variant="standard"
        label={getTranslatedTypeTitle(props.entry)}
        className={classes.typableField}
        disabled={!props.isEditingMasterDataAllowed}
        value={typableValue}
        onFocus={() => setBeingEdited(true)}
        onBlur={(event: any) => {
          setBeingEdited(false);
          props.editTypableMasterData(
            props.componentId,
            props.entry.typeName,
            event.target.value,
            true,
          );
        }}
        onChange={(event: any) => {
          setTypableValue(event.target.value);
          props.editTypableMasterData(
            props.componentId,
            props.entry.typeName,
            event.target.value,
            false,
          );
        }}
      />
    </div>
  );
};
