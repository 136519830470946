export enum SettingsActionTypes {
  SAVE_USER_SETTINGS = '@@settings/SAVE_USER_SETTINGS',
}

export interface UserSpecificSettings {
  readonly skipSavingTruckTare: boolean;
}

export function defaultUserSpecificSettings(): UserSpecificSettings {
  return {
    skipSavingTruckTare: false,
  };
}

export interface SettingsState {
  readonly userSettings: { [userKey: string]: UserSpecificSettings };
}
