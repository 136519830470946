import { connect, localeConnect } from 'localeConnect';

import { logoutRequest } from 'store/user/actions';
import { destroyContext } from 'store/weighing/actions';

import { LogoutButton } from 'components/NavDrawerContent';

interface ParameterProps {
  iconVisible: boolean;
  onClick: () => void;
}

interface PropsFromDispatch {
  destroyContext: typeof destroyContext;
  logoutRequest: typeof logoutRequest;
}

type AllProps = PropsFromDispatch & ParameterProps;

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  destroyContext,
  logoutRequest,
};

const ConnectedLogoutButton = (props: AllProps) => {
  function onClick() {
    props.destroyContext();
    props.logoutRequest();
    props.onClick();
  }

  return <LogoutButton iconVisible={props.iconVisible} onClick={onClick} />;
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
const exported = localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedLogoutButton);

export default exported;
