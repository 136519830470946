import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';

import { makeStyles } from '@mui/styles';
import { Button, Grid, Typography } from '@mui/material';

import { History } from '@mui/icons-material';

import { connect, localeConnect } from '../localeConnect';

import { ApplicationState, ConnectedReduxProps } from '../store';
import { startSitesPoll, stopSitesPoll, selectSite } from '../store/sites/actions';
import { Scale, SitesState } from '../store/sites/types';
import { mobileContentMaxWidth, paperRoot } from '../theme';

import { KeyItem } from '../store/common/types';

import { translate } from '../utils/translate';
import KeyItemRadioForm from './KeyItemRadioForm';
import LoadingIndicator from './LoadingIndicator';
import SimpleNavBar from './SimpleNavBar';

const useStyles = makeStyles({
  root: paperRoot,
  container: {
    padding: '30px',
    maxWidth: mobileContentMaxWidth,
    margin: 'auto',
    textAlign: 'left',
  },
  continue: {
    marginTop: '40px',
    float: 'right',
  },
  useTrailerForm: {
    marginTop: '20px',
  },
  containerTypeLabel: {
    color: '#868686',
  },
  containerPaper: {
    padding: '20px',
  },
});

interface PropsFromState extends SitesState {}

interface PropsFromDispatch {
  startSitesPoll: typeof startSitesPoll;
  stopSitesPoll: typeof stopSitesPoll;
  selectSite: typeof selectSite;
}

/*eslint-disable */
export type AllProps = PropsFromDispatch &
  PropsFromState &
  RouteComponentProps<{}> &
  ConnectedReduxProps;
/*eslint-enable */

const mapStateToProps = ({ sites }: ApplicationState) => ({
  loading: sites.loading,
  errors: sites.errors,
  data: sites.data,
  selectedKey: sites.selectedKey,
  lastSelectedKeys: sites.lastSelectedKeys,
});

const mapDispatchToProps = {
  startSitesPoll,
  stopSitesPoll,
  selectSite,
};

function labelWithIcon(text: string, showIcon: boolean) {
  return (
    <Grid container direction="row">
      <Grid item>
        <Typography>{text}</Typography>
      </Grid>
      <Grid item>{showIcon && <History htmlColor="#747789" />}</Grid>
    </Grid>
  );
}

export const Sites = (props: AllProps) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(props.selectedKey);

  const { startSitesPoll, stopSitesPoll } = props;
  useEffect(() => {
    startSitesPoll();
    return () => {
      stopSitesPoll();
    };
  }, [startSitesPoll, stopSitesPoll]);

  const confirm = () => {
    if (selected) {
      props.selectSite(selected);
    } else {
      console.warn('Select site first');
    }
  };

  const isLastUsed = (key: string) => {
    return props.lastSelectedKeys.includes(key);
  };

  const sites = [...props.data] || [];
  sites.sort((a: Scale, b: Scale) => {
    const isALastUsed = isLastUsed(a.key);
    const isBLastUsed = isLastUsed(b.key);
    if (isALastUsed && !isBLastUsed) {
      return -1;
    }
    if (!isALastUsed && isBLastUsed) {
      return 1;
    }
    return a.name.localeCompare(b.name);
  });

  const handleChange = (key: string) => {
    const matchingList = sites.filter((i) => i.key === key);
    if (matchingList.length > 0) {
      setSelected(matchingList[0].key);
    }
  };

  return (
    <div className={classes.root}>
      <SimpleNavBar
        roleSelectionVisible={false}
        showSiteInfo={false}
        title={translate('sites.title')}
        onGoBack={undefined}
      />
      <LoadingIndicator isLoading={props.loading} />
      <div className={classes.container}>
        {sites.length ? (
          <div>
            <KeyItemRadioForm
              handleChange={handleChange}
              items={sites}
              labelCallback={(keyItem: KeyItem) => {
                type siteElement = typeof sites[0];
                const site = keyItem as siteElement;
                return labelWithIcon(site.name, isLastUsed(site.key));
              }}
              selectedKey={selected ?? ''}
            />
            <Button
              variant="contained"
              color="primary"
              disabled={!selected}
              className={classes.continue}
              onClick={() => confirm()}
            >
              {translate('sites.continue')}
            </Button>
          </div>
        ) : (
          <div>
            {props.loading || props.errors
              ? translate('sites.noSites')
              : translate('errorUserNotInDomain')}
          </div>
        )}
      </div>
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(mapStateToProps, mapDispatchToProps)(Sites);
