import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  List,
  MenuItem,
  TextField,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import HistoryIcon from '@mui/icons-material/History';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { v4 as uuid } from 'uuid';

import { connect, localeConnect } from 'localeConnect';
import { ApplicationState } from 'store';
import { finishContext } from 'store/weighing/actions';
import { setDefaultWeighingProcess, setPendingMasterDataUpdate } from 'store/orders/actions';
import { BridgeEnabledFeatures } from 'store/scale-info/types';
import {
  GenericMasterData,
  MasterDataTypeState,
  MasterDataUpdateType,
  VisibleMasterDataSearchResults,
} from 'store/master-data/types';
import { DetailedMasterDataType } from 'store/common/types';
import {
  searchMasterData,
  subscribeToMasterDataTypes,
  unsubscribeToMasterDataTypes,
} from 'store/master-data/actions';

import { darkGreyColor } from 'theme';
import { translate } from 'utils/translate';
import HighlightedText from '../HighlightedText';

const useSearchResultStyles = makeStyles({
  container: {
    color: darkGreyColor,
    fontSize: '20px',
    overflowWrap: 'break-word',
    wordBreak: 'normal',
    whiteSpace: 'break-spaces',
  },
  externalId: {
    fontSize: '12px',
  },
});

interface SearchResultProps {
  masterDataItem: GenericMasterData;
  enabledFeatures: BridgeEnabledFeatures;
  isLastSelected: boolean;
  searchTerm: string;
}

function SearchResult(props: SearchResultProps) {
  const classes = useSearchResultStyles();
  const hasExternalId = !!props.masterDataItem.externalId;
  const isExternalIdVisible =
    hasExternalId && props.enabledFeatures.bridgeIsMasterDataExternalIdVisible;
  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={1}>
          {props.isLastSelected && <HistoryIcon htmlColor={darkGreyColor} />}
        </Grid>
        <Grid item xs={11}>
          <HighlightedText searchTerm={props.searchTerm} fullText={props.masterDataItem.name} />
          {isExternalIdVisible && (
            <div className={classes.externalId}>
              <HighlightedText
                searchTerm={props.searchTerm}
                fullText={props.masterDataItem.externalId ?? ''}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles({
  filter: {
    // eslint-disable-next-line
    marginTop: '20px',
    // eslint-disable-next-line
    marginBottom: '20px',
    // eslint-disable-next-line
    width: '100%',
    '& .MuiTextField-root': {
      backgroundColor: 'white',
      width: '100%',
    },
  },
  listItem: {
    // eslint-disable-next-line
    textAlign: 'left',
    // eslint-disable-next-line
    width: '100%',
    '& span': {
      width: '100%',
    },
  },
  manyResultsText: {
    color: darkGreyColor,
    fontSize: '12px',
    marginTop: '5px',
  },
  selection: {
    color: darkGreyColor,
    fontSize: '20px',
    overflowWrap: 'break-word',
    wordBreak: 'normal',
  },
});

interface ParameterProps {
  disabledKeys: string[];
  select: (item: GenericMasterData | undefined) => void;
  selectedItem?: GenericMasterData;
  requireSelection: boolean;
  type: DetailedMasterDataType;
  typeState: MasterDataTypeState;
}

interface PropsFromState {
  enabledFeatures: BridgeEnabledFeatures;
}

interface PropsFromDispatch {
  searchMasterData: typeof searchMasterData;
  setPendingMasterDataUpdate: typeof setPendingMasterDataUpdate;
  subscribeToMasterDataTypes: typeof subscribeToMasterDataTypes;
  unsubscribeToMasterDataTypes: typeof unsubscribeToMasterDataTypes;
}

const mapStateToProps = (state: ApplicationState) => ({
  enabledFeatures: state.currentScaleInfo.enabledFeatures,
});

const mapDispatchToProps = {
  finishContext,
  searchMasterData,
  setDefaultWeighingProcess,
  setPendingMasterDataUpdate,
  subscribeToMasterDataTypes,
  unsubscribeToMasterDataTypes,
};

/*eslint-disable */
export type AllProps = PropsFromDispatch & PropsFromState & ParameterProps;
/*eslint-enable */

function MasterDataSearch(props: AllProps) {
  const lastSelected = props.typeState?.lastSelected || [];
  const lastSelectedKeys = lastSelected.map((item) => item.key);
  const classes = useStyles();

  const [keepSearchVisible, setKeepSearchVisible] = React.useState<boolean>(false);
  const [filterText, doSetFilterText] = React.useState<string>('');
  function setFilterText(text: string) {
    doSetFilterText(text.trimStart());
    props.searchMasterData(props.type, text.trim());
    setKeepSearchVisible(true);
  }

  const type = props.type;
  const searchMasterData = props.searchMasterData;
  React.useEffect(() => {
    searchMasterData(type, '');
    doSetFilterText('');
    setKeepSearchVisible(false);
  }, [type, searchMasterData, doSetFilterText, setKeepSearchVisible]);

  function getHistory() {
    if (filterText) return [];
    return lastSelected;
  }

  const search = props.typeState?.search;
  const searchResults = search?.searchResults || [];

  function getHistoryFilteredResults() {
    if (filterText) return searchResults;
    return searchResults.filter((result) => !lastSelectedKeys.includes(result.key));
  }

  const isSearching = search ? search.isLoading : true;
  const masterData = [...getHistory(), ...getHistoryFilteredResults()];
  const maxResultCount = VisibleMasterDataSearchResults;

  function handleItemClick(item: GenericMasterData) {
    props.select(item);
  }

  function getPendingName() {
    return props.selectedItem?.name || translate('orderDetails.masterData.noSelection');
  }

  return (
    <Box>
      <div>
        <div className={classes.selection}>
          <span>{getPendingName()}</span>
          {!props.requireSelection && props.selectedItem?.key && (
            <IconButton color="primary" size="small" onClick={() => props.select(undefined)}>
              <ClearIcon />
            </IconButton>
          )}
        </div>

        {(keepSearchVisible || filterText !== '' || searchResults.length >= maxResultCount) && (
          <div className={classes.filter}>
            <TextField
              variant="standard"
              autoFocus={true}
              color="primary"
              key="filter"
              value={filterText}
              label={translate('search.label')}
              onChange={(e) => setFilterText(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isSearching && <CircularProgress size={20} />}
                    <IconButton onClick={() => setFilterText('')}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {searchResults.length > maxResultCount && (
              <div className={classes.manyResultsText}>
                {translate('search.manyResults', { count: maxResultCount })}
              </div>
            )}
          </div>
        )}
        <List component={'ul'} role="listbox">
          {!isSearching &&
            masterData.map((entry: GenericMasterData) => (
              <MenuItem
                className={classes.listItem}
                disabled={props.disabledKeys.includes(entry.key)}
                key={entry.key}
                value={entry.key}
                onClick={() => handleItemClick(entry)}
                role="option"
                selected={props.selectedItem?.key === entry.key}
              >
                <span>
                  <SearchResult
                    enabledFeatures={props.enabledFeatures}
                    isLastSelected={lastSelectedKeys.includes(entry.key)}
                    masterDataItem={entry}
                    searchTerm={filterText}
                  />
                </span>
              </MenuItem>
            ))}
        </List>
        {masterData.length === 0 && (
          <MenuItem disabled={true}>{translate('search.noResults')}</MenuItem>
        )}
      </div>
    </Box>
  );
}

export class MasterDataSearchComponent extends React.PureComponent<AllProps> {
  private uuid: string = uuid();
  componentDidMount(): void {
    this.props.subscribeToMasterDataTypes(this.uuid, MasterDataUpdateType.SEARCH, [
      this.props.type,
    ]);
  }

  componentWillUnmount() {
    this.props.unsubscribeToMasterDataTypes(this.uuid);
  }

  public render() {
    return <MasterDataSearch {...this.props}></MasterDataSearch>;
  }
}

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(MasterDataSearchComponent);
