import React from 'react';

import { makeStyles } from '@mui/styles';
import { Button, Grid } from '@mui/material';

import { connect, localeConnect } from 'localeConnect';

import { ApplicationState } from 'store';

import { ContainerType } from 'store/common/types';

import { Job } from 'store/jobs/types';

import { MeasDeviceGroup } from 'store/meas-devices/types';

import { setContextCommentImmediately, setContextManualDeductions } from 'store/orders/actions';
import { ComponentGroup, Context, WeighingProcess, Order } from 'store/orders/types';

import { BridgeEnabledFeatures, OrganizationEnabledFeatures } from 'store/scale-info/types';

import { UserDetails } from 'store/user/types';

import { undefinedMassKg } from 'store/weighing/types';

import { createSplitLoadModelFromContext, SplitLoadModel } from 'models/splitload.model';
import { NetContainer, VehicleWeighingModels } from 'models/vehicleweighing.model';

import { translate } from 'utils/translate';
import CardDetailItem from '../CardDetailItem';
import DeductionInfo from '../DeductionInfo';
import OperatorVehicleWeighing, { OperatorVehicles } from './OperatorVehicleWeighing';

import DeductionsDialog from './DeductionsDialog';

import OperatorPrimaryContainerTare from './OperatorPrimaryContainerTare';
import OperatorSaveTruckTare from './OperatorSaveTruckTare';

const useStyles = makeStyles({
  actionsContent: {
    marginTop: 'auto',
  },
  actionsGrid: {
    // eslint-disable-next-line
    height: '100%',
    // eslint-disable-next-line
    marginTop: '10px',
    // eslint-disable-next-line
    paddingBottom: '10px',
    '& button': {
      paddingTop: '7px',
      paddingBottom: '8px',
    },
  },
  tareContainer: {
    // eslint-disable-next-line
    height: '100%',
    '& > div': {
      height: '100%',
    },
    '& > div > div': {
      height: '100%',
    },
  },
  finishAction: {
    marginTop: 'auto',
    textAlign: 'right',
    paddingRight: '10px',
  },
  deductionInfo: {
    textAlign: 'left',
    paddingLeft: '10px',
  },
  commentContainer: {
    paddingLeft: '10px',
    paddingBottom: '20px',
  },
  presetTare: {
    height: '100%',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  saveTare: {
    marginBottom: '20px',
  },
  weighing: {
    marginTop: '50px',
  },
});

const toVehicleContainer = (
  net: NetContainer,
  deductionClassName: string,
  order: Order,
  enabledFeatures: BridgeEnabledFeatures,
  splitLoadModel: SplitLoadModel,
) => {
  return {
    containerType: net.containerType,
    kgs: net.weighedKgs,
    key: net.key,
    additionalInfo: (
      <DeductionInfo
        deductionClassName={deductionClassName}
        net={net}
        order={order}
        enabledFeatures={enabledFeatures}
        splitLoadModel={splitLoadModel}
      />
    ),
    isActive: net.deductionKgs !== undefinedMassKg,
  };
};

interface ParameterProps {
  actions: any;
  context: Context;
  doWeighing: () => void;
  discardWeighing: () => void;
  finishWithContextTare: () => void;
  isReversible: boolean;
  isReversed: boolean;
  job: Job;
  measDeviceGroup?: MeasDeviceGroup;
  reverseTruckTrailer: () => void;
  saveTareAndFinish: () => void;
  setManualKgHandler: (containerKey: string, kgs: number) => void;
  weighingModels: VehicleWeighingModels;
}

interface PropsFromState {
  enabledFeatures: BridgeEnabledFeatures;
  organizationEnabledFeatures: OrganizationEnabledFeatures;
  scaleKey: string;
  userDetails: UserDetails | undefined;
}

interface PropsFromDispatch {
  setContextCommentImmediately: typeof setContextCommentImmediately;
  setContextManualDeductions: typeof setContextManualDeductions;
}

type AllProps = ParameterProps & PropsFromDispatch & PropsFromState;

const mapStateToProps = (state: ApplicationState) => ({
  enabledFeatures: state.currentScaleInfo.enabledFeatures,
  organizationEnabledFeatures: state.currentScaleInfo.organizationEnabledFeatures,
  scaleKey: state.currentScaleInfo.scaleKey,
  userDetails: state.user.user.userData,
});

const mapDispatchToProps = {
  setContextCommentImmediately,
  setContextManualDeductions,
};

const OperatorNormalWeighing = (props: AllProps) => {
  const classes = useStyles();

  const [isDeductionsDialogOpen, setIsDeductionsDialogOpen] = React.useState(false);

  const canOpenDeductions = props.context.process === WeighingProcess.NORMAL;

  function getSplitLoadModel(containerType: ContainerType) {
    const componentGroup =
      containerType === ContainerType.TRAILER ? ComponentGroup.TRAILER : ComponentGroup.TRUCK;
    return createSplitLoadModelFromContext({
      context: props.context,
      componentGroup,
      organizationEnabledFeatures: props.organizationEnabledFeatures,
      scaleKey: props.scaleKey,
      userDetails: props.userDetails,
    });
  }

  return (
    <div>
      <div>
        <Grid container>
          <Grid item xs={12} sm={12} md={4}>
            <OperatorVehicleWeighing
              discardWeighing={props.discardWeighing}
              doReverse={props.reverseTruckTrailer}
              doWeighing={props.doWeighing}
              ignoreWrongDirection={true}
              isReversed={props.isReversed}
              isReversible={props.isReversible}
              model={props.weighingModels.models[0]}
              isMeasDeviceGroupManual={!!props.measDeviceGroup && props.measDeviceGroup.isManual}
              process={props.weighingModels.process}
              setManualKgs={props.setManualKgHandler}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            {props.weighingModels.models.length > 1 && (
              <OperatorVehicleWeighing
                discardWeighing={props.discardWeighing}
                doReverse={props.reverseTruckTrailer}
                doWeighing={props.doWeighing}
                isReversed={props.isReversed}
                isReversible={props.isReversible}
                model={props.weighingModels.models[1]}
                isMeasDeviceGroupManual={!!props.measDeviceGroup && props.measDeviceGroup.isManual}
                process={props.weighingModels.process}
                setManualKgs={props.setManualKgHandler}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            {props.job && (
              <div>
                <OperatorVehicles
                  containers={props.weighingModels.net.map((n) =>
                    toVehicleContainer(
                      n,
                      classes.deductionInfo,
                      props.context.order,
                      props.enabledFeatures,
                      getSplitLoadModel(n.containerType),
                    ),
                  )}
                  isMeasDeviceGroupManual={false}
                  isReversed={props.isReversed}
                  containerWidth={props.weighingModels.net.length === 2 ? 6 : 12}
                  title={translate('operatorOrder.weighing.weighedKgsTitle')}
                  button={
                    <Button
                      color="primary"
                      disabled={!canOpenDeductions}
                      onClick={() => setIsDeductionsDialogOpen(true)}
                    >
                      {translate('operatorOrder.weighing.addDeductionOrAddition')}
                    </Button>
                  }
                />
                {!!props.job.comment && (
                  <div className={classes.commentContainer}>
                    <CardDetailItem
                      title={translate('operatorOrder.orderDetails.comment')}
                      value={props.job.comment ?? ''}
                    />
                  </div>
                )}
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container className={classes.actionsGrid}>
              <Grid item xs={8} sm={8} md={8} className={classes.tareContainer}>
                {props.context && (
                  <OperatorPrimaryContainerTare
                    context={props.context}
                    enabledFeatures={props.enabledFeatures}
                    finishWithContextTare={props.finishWithContextTare}
                    rootClass={classes.presetTare}
                    scaleKey={props.scaleKey}
                    userDetails={props.userDetails}
                  />
                )}
              </Grid>
              <Grid item xs={4} sm={4} md={4} className={classes.finishAction}>
                {props.context && (
                  <OperatorSaveTruckTare
                    context={props.context}
                    saveTareAndFinish={props.saveTareAndFinish}
                    scaleKey={props.scaleKey}
                    rootClass={classes.saveTare}
                  />
                )}
                <div className={classes.actionsContent}>{props.actions}</div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {
        /* NOTE(mikkogy,20210217) conditionally rendering dialog enables
          using React state and not having to update dialog contents. */
        props.job && isDeductionsDialogOpen && (
          <DeductionsDialog
            net={props.weighingModels.net}
            initialComment={props.job.comment ? props.job.comment : ''}
            context={props.context}
            visible={isDeductionsDialogOpen}
            onDeductionsConfirmed={(manualDeductions, comment) => {
              props.setContextManualDeductions(props.context.contextId, manualDeductions);
              if (props.job && comment !== props.job.comment) {
                props.setContextCommentImmediately(props.context.contextId, comment, props.job.key);
              }
              setIsDeductionsDialogOpen(false);
            }}
            onNotConfirmed={() => setIsDeductionsDialogOpen(false)}
          />
        )
      }
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(OperatorNormalWeighing);
