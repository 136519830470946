import React from 'react';
import { makeStyles } from '@mui/styles';

import { operatorColors } from '../theme';

interface RegisterNumberProps {
  text: string;
  onClick?: () => void;
}

const useStyles = makeStyles({
  registerNumber: {
    border: `2px solid ${operatorColors.secondary}`,
    borderRadius: '5px',
    display: 'inline',
    padding: '5px',
    marginRight: '5px',
    whiteSpace: 'nowrap',
  },
});

const RegisterNumber = (props: RegisterNumberProps) => {
  const classes = useStyles();
  return (
    <div className={classes.registerNumber} onClick={props.onClick}>
      {props.text}
    </div>
  );
};

export default RegisterNumber;
