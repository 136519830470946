import React, { HTMLAttributes } from 'react';

import {
  Box,
  FormControl,
  ListItem,
  Paper,
  PaperProps,
  TextField,
  Autocomplete,
  AutocompleteRenderInputParams,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect, localeConnect } from 'localeConnect';
import { ApplicationState } from 'store';
import { createContext, searchOrder, showOrderInfo } from 'store/orders/actions';
import {
  ClosePolicy,
  JobType,
  OperatorOrderMode,
  OperatorOrderTab,
  Order,
  OrderSearch,
  OrderSearchResults,
  OrderStatus,
} from 'store/orders/types';

import { formatOrderTitle } from 'store/orders/utils';
import { DocType } from 'store/common/types';
import { translate } from 'utils/translate';
import CoolOffButton from '../CoolOffButton';
import HighlightedText from '../HighlightedText';

const useStyles = makeStyles({
  create: {
    marginTop: '20px',
  },
  orderSelect: {
    // eslint-disable-next-line
    minWidth: '350px',
    '@media (min-width: 960px)': {
      minWidth: '500px',
    },
  },
  list: {
    '@media (min-width: 960px)': {
      maxHeight: 'calc(100vh - 380px)',
    },
  },
  disabledOption: {
    fontSize: '12px',
  },
});

interface PropsFromState {
  operatorTrailerKey: string | undefined;
  selectionOrders: Order[];
  searchResults: OrderSearch;
}

interface PropsFromDispatch {
  createContext: typeof createContext;
  showOrderInfo: typeof showOrderInfo;
  searchOrder: typeof searchOrder;
}

const mapStateToProps = (state: ApplicationState) => ({
  operatorTrailerKey: state.weighing.operatorTrailerKey,
  selectionOrders: state.orders.operatorSelectionResults.orders,
  searchResults: state.orders.search,
});

const mapDispatchToProps = {
  createContext,
  showOrderInfo,
  searchOrder,
};

type AllProps = PropsFromDispatch & PropsFromState;

const moreResultsAvailableKey = '8cb0f310-6ffe-4313-aa49-19b07af45f4e';

const orderListWithMoreAvailable = (orders: Order[], maxResultCount: number): Order[] => {
  const moreResultsAvailableText = translate('search.manyResults', { count: maxResultCount });
  const moreResultsAvailableOrderItem = {
    closePolicy: ClosePolicy.SCALE_MANUAL,
    components: [],
    docType: DocType.ORDER,
    externalId: moreResultsAvailableText,
    jobType: JobType.BRIDGE,
    key: moreResultsAvailableKey,
    realization: {},
    status: OrderStatus.OPEN,
    name: moreResultsAvailableText,
  } as Order;
  return [moreResultsAvailableOrderItem].concat(orders.slice(0, maxResultCount));
};

const OperatorOrderSelection = (props: AllProps) => {
  const classes = useStyles();

  React.useEffect(() => {
    props.searchOrder('', undefined);
    // eslint-disable-next-line
  }, []);

  const handleOrderSelected = (orderKey: string) => {
    props.showOrderInfo(orderKey, OperatorOrderTab.Order, OperatorOrderMode.Weighing);
  };

  const maxResultCount = OrderSearchResults;
  const orderSearchResults = props.searchResults.searchResults;
  const isMoreResultsAvailable = orderSearchResults.length > maxResultCount;
  const orderOptionsToBeShown = isMoreResultsAvailable
    ? orderListWithMoreAvailable(orderSearchResults, maxResultCount)
    : orderSearchResults;

  const CustomPaper = (props: JSX.IntrinsicAttributes & PaperProps) => {
    return <Paper elevation={8} {...props} />;
  };

  const isOptionDisabled = (order: Order) => {
    return isMoreResultsAvailable && moreResultsAvailableKey === order.key;
  };

  const OrderOption = (liProps: HTMLAttributes<HTMLLIElement>, order: Order) => {
    return (
      <ListItem
        {...liProps}
        key={order.key}
        className={
          isOptionDisabled(order)
            ? `${liProps.className} ${classes.disabledOption}`
            : liProps.className
        }
      >
        <HighlightedText
          searchTerm={props.searchResults.text}
          fullText={formatOrderTitle(order.externalId, order.closePolicy)}
        />
      </ListItem>
    );
  };

  const SearchInputField = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        variant={'standard'}
        label={translate('operatorOrder.orderSelect.select')}
        InputProps={{
          ...params.InputProps,
        }}
      />
    );
  };

  return (
    <Box>
      <FormControl variant="standard">
        <Autocomplete
          className={classes.orderSelect}
          classes={{
            listbox: classes.list,
          }}
          clearOnBlur={false}
          filterOptions={(orders) => orders}
          onChange={(event, selectedOrder) => {
            handleOrderSelected(selectedOrder?.key.toString() ?? '');
          }}
          onInputChange={(event, searchString) => {
            props.searchOrder(searchString, undefined);
          }}
          options={orderOptionsToBeShown}
          getOptionLabel={(order) => formatOrderTitle(order.externalId, order.closePolicy)}
          PaperComponent={CustomPaper}
          renderOption={(props, order) => OrderOption(props, order)}
          loading={props.searchResults.isLoading}
          loadingText={translate('search.loading')}
          noOptionsText={translate('search.noResults')}
          renderInput={(params) => SearchInputField(params)}
          size={'medium'}
          getOptionDisabled={(order) => isOptionDisabled(order)}
        />
      </FormControl>
      <div className={classes.create}>
        <CoolOffButton
          variant="outlined"
          color="primary"
          onClickAccepted={() => props.createContext('', '', props.operatorTrailerKey, true)}
        >
          {translate('operatorOrder.orderSelect.create')}
        </CoolOffButton>
      </div>
    </Box>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(OperatorOrderSelection);
