import React from 'react';
import { Button, FormGroup, FormControlLabel, Grid, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { connect, localeConnect } from 'localeConnect';
import { ComponentGroup, Order, SplitLoad } from 'store/orders/types';

import { ApplicationState } from 'store';

import { RemoteMasterDataType } from 'store/common/types';

import { fadedGreyColor } from 'theme';

import { createSplitLoadModel } from 'models/splitload.model';

import { OrganizationEnabledFeatures } from 'store/scale-info/types';

import {
  addSplit,
  deleteSplit,
  disableSplitLoad,
  enableSplitLoad,
  setSplitAmount,
} from 'store/orders/actions';
import { UserDetails } from 'store/user/types';
import { translate } from 'utils/translate';
import { formatMass } from '../utils';

import DeleteShareDialog from '../DeleteShareDialog';
import DisableMultipleSharesDialog from '../DisableMultipleSharesDialog';
import SplitAmountInput from '../SplitAmountInput';
import OperatorOrderComponentMasterData from './OperatorOrderComponentMasterData';

const useStyles = makeStyles({
  amountInput: {
    marginBottom: '15px',
  },
  addShare: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  shareComponent: {
    border: `1px solid ${fadedGreyColor}`,
    borderRadius: '16px',
    color: fadedGreyColor,
    marginBottom: '10px',
    padding: '12px 9px 12px 9px',
  },
  sharedContainerSelection: {
    paddingBottom: '16px',
  },
  sharesSwitchContainer: {
    paddingTop: '19px',
  },
});

interface ParameterProps {
  componentGroup: ComponentGroup;
  contextId: string | undefined;
  isAllowedToUpdateOrder: boolean;
  order: Order;
  isOrderTemporaryCopy: boolean;
  splitLoad: SplitLoad | undefined;
}

interface PropsFromState {
  organizationEnabledFeatures: OrganizationEnabledFeatures;
  scaleKey: string;
  userDetails: UserDetails | undefined;
}

interface PropsFromDispatch {
  addSplit: typeof addSplit;
  deleteSplit: typeof deleteSplit;
  disableSplitLoad: typeof disableSplitLoad;
  enableSplitLoad: typeof enableSplitLoad;
  setSplitAmount: typeof setSplitAmount;
}

const mapStateToProps = (state: ApplicationState) => ({
  organizationEnabledFeatures: state.currentScaleInfo.organizationEnabledFeatures,
  scaleKey: state.currentScaleInfo.scaleKey,
  selectedType: state.orders.editedMasterDataType,
  userDetails: state.user.user.userData,
});

const mapDispatchToProps = {
  addSplit,
  deleteSplit,
  disableSplitLoad,
  enableSplitLoad,
  setSplitAmount,
};

export type AllProps = PropsFromDispatch & PropsFromState & ParameterProps;

const OperatorOrderMasterData = (props: AllProps) => {
  const classes = useStyles();

  const [deleteSplitShareNumber, setDeleteSplitShareNumber] = React.useState(1);
  const [isDisableMultipleSharesDialogVisible, setIsDisableMultipleSharesDialogVisible] =
    React.useState(false);
  const [deleteSplitComponentId, setDeleteSplitComponentId] = React.useState<string | undefined>(
    undefined,
  );

  const setMultipleSharesEnabled = (isEnabled: boolean) => {
    if (!props.contextId) {
      return;
    }
    if (isEnabled) {
      props.enableSplitLoad(props.contextId, props.componentGroup);
    } else {
      props.disableSplitLoad(props.contextId, props.componentGroup);
    }
  };

  const multipleSharesChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isEnabled = e.target.checked;
    if (!isEnabled && splitLoadModel.splits.length > 1) {
      setIsDisableMultipleSharesDialogVisible(true);
      return;
    }
    setMultipleSharesEnabled(isEnabled);
  };

  const splitLoadModel = createSplitLoadModel({
    order: props.order,
    isOrderTemporaryCopy: props.isOrderTemporaryCopy,
    componentGroup: props.componentGroup,
    splitLoad: props.splitLoad,
    organizationEnabledFeatures: props.organizationEnabledFeatures,
    scaleKey: props.scaleKey,
    userDetails: props.userDetails,
  });

  return (
    <div>
      {splitLoadModel.isSplitAvailable && (
        <FormGroup className={classes.sharesSwitchContainer}>
          <FormControlLabel
            control={
              <Switch
                checked={splitLoadModel.isSplitEnabled}
                disabled={!props.contextId}
                onChange={multipleSharesChanged}
              />
            }
            label={translate('operatorOrder.orderDetails.multipleShares.multipleShares')}
          />
        </FormGroup>
      )}
      {splitLoadModel.isSplitEnabled && splitLoadModel.splits.length > 0 && (
        <div className={classes.sharedContainerSelection}>
          <OperatorOrderComponentMasterData
            order={props.order}
            excludedTypes={undefined}
            includedTypes={[RemoteMasterDataType.CONTAINER]}
            componentId={splitLoadModel.splits[0].component.id}
            dataLinks={splitLoadModel.splits[0].component.dataLinks ?? []}
            isAllowedToUpdateOrder={props.isAllowedToUpdateOrder}
            requiredTypes={splitLoadModel.splits[0].requiredTypes}
          />
        </div>
      )}
      {splitLoadModel.splits.map((split, index) => (
        <div
          key={split.component.id}
          className={splitLoadModel.isSplitEnabled ? classes.shareComponent : ''}
        >
          {splitLoadModel.isSplitEnabled && (
            <Grid container justifyContent="space-between">
              <Grid item>
                {translate('operatorOrder.orderDetails.multipleShares.shareNumber', {
                  shareNumber: index + 1,
                })}
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  disabled={!split.isDeletingEnabled}
                  onClick={() => {
                    setDeleteSplitShareNumber(index + 1);
                    setDeleteSplitComponentId(split.component.id);
                  }}
                >
                  {translate('operatorOrder.orderDetails.multipleShares.delete')}
                </Button>
              </Grid>
            </Grid>
          )}
          {splitLoadModel.isSplitEnabled && (
            <div className={classes.amountInput}>
              <SplitAmountInput
                hasError={splitLoadModel.areAllAmountsZero}
                split={split}
                label={translate('operatorOrder.orderDetails.multipleShares.amount')}
                isDisabled={props.contextId === undefined}
                onChanged={(value: number) => {
                  if (!props.contextId) return;
                  props.setSplitAmount(props.contextId, split.component.id, value);
                }}
              />
              {split.kgs !== undefined && (
                <div>
                  {translate('operatorOrder.orderDetails.multipleShares.weight', {
                    weight: formatMass(split.kgs),
                  })}
                </div>
              )}
            </div>
          )}
          <OperatorOrderComponentMasterData
            order={props.order}
            excludedTypes={
              splitLoadModel.isSplitEnabled ? [RemoteMasterDataType.CONTAINER] : undefined
            }
            includedTypes={undefined}
            componentId={split.component.id}
            dataLinks={split.component.dataLinks ?? []}
            isAllowedToUpdateOrder={props.isAllowedToUpdateOrder}
            requiredTypes={split.requiredTypes}
          />
        </div>
      ))}
      {splitLoadModel.isSplitEnabled && (
        <Button
          className={classes.addShare}
          variant="contained"
          disabled={!splitLoadModel.isAddingEnabled}
          onClick={() => {
            if (!props.contextId) return;
            props.addSplit(props.contextId, props.componentGroup);
          }}
        >
          {translate('operatorOrder.orderDetails.multipleShares.addShare')}
        </Button>
      )}
      <DisableMultipleSharesDialog
        visible={isDisableMultipleSharesDialogVisible}
        onConfirmed={() => {
          setMultipleSharesEnabled(false);
          setIsDisableMultipleSharesDialogVisible(false);
        }}
        onNotConfirmed={() => setIsDisableMultipleSharesDialogVisible(false)}
      />
      <DeleteShareDialog
        shareNumber={deleteSplitShareNumber}
        visible={deleteSplitComponentId !== undefined}
        onConfirmed={() => {
          if (props.contextId === undefined || deleteSplitComponentId === undefined) return;
          props.deleteSplit(props.contextId, deleteSplitComponentId);
          setDeleteSplitComponentId(undefined);
        }}
        onNotConfirmed={() => setDeleteSplitComponentId(undefined)}
      />
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(OperatorOrderMasterData);
