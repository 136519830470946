import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { translate } from '../utils/translate';
import Settings from './Settings';

interface DialogProps {
  visible: boolean;
  onClose: () => void;
}

const SettingsDialogComponent = (props: DialogProps) => {
  return (
    <div>
      <Dialog open={props.visible}>
        <DialogTitle>{translate('settingsDialog.title')}</DialogTitle>
        <DialogContent>
          <Settings />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            {translate('settingsDialog.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SettingsDialogComponent;
