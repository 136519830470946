import { Reducer } from 'redux';
import { UserActionTypes } from 'store/user/types';
import { SitesActionTypes } from 'store/sites/types';
import { CacheImage, ImagesActionTypes, ImagesState } from './types';

export const initialState: ImagesState = {
  addImageJobKey: undefined,
  orderImages: { images: {} },
  schemaVersion: '0.1',
  transferIdToOrderKeyMap: {},
};

function removeImage(
  state: ImagesState,
  orderKey: string,
  imageFilter: (image: CacheImage) => boolean,
): ImagesState {
  const images = { ...state.orderImages.images };
  if (images[orderKey]) {
    images[orderKey] = images[orderKey].filter(imageFilter);
  }
  return { ...state, orderImages: { images } };
}

const reducer: Reducer<ImagesState> = (state = initialState, action) => {
  switch (action.type) {
    case ImagesActionTypes.SAVE_TO_STORE: {
      const images = { ...state.orderImages.images };
      const payload = action.payload;
      let existingIndex = -1;
      const cacheImage: CacheImage = { ...payload.cacheImage };
      const orderKey = payload.orderKey;
      if (!images[payload.orderKey]) {
        images[orderKey] = [];
      } else {
        existingIndex = images[orderKey].findIndex((image) => image.localId === cacheImage.localId);
      }
      if (existingIndex >= 0) {
        images[orderKey][existingIndex] = cacheImage;
      } else {
        images[orderKey].push(payload.cacheImage);
      }
      return { ...state, orderImages: { images } };
    }
    case ImagesActionTypes.REMOVE_LOCAL_IMAGE: {
      const orderKey = action.payload.orderKey;
      const attachmentKey = action.payload.attachmentKey;
      return removeImage(state, orderKey, (image: CacheImage) => {
        return image.attachmentKey !== attachmentKey;
      });
    }
    case ImagesActionTypes.REMOVE_UPLOAD_FAILED_IMAGE: {
      const cacheImage: CacheImage = action.payload;
      const orderKey = cacheImage.orderKey;
      return removeImage(state, orderKey, (image: CacheImage) => {
        return image.localId !== cacheImage.localId;
      });
    }
    case ImagesActionTypes.REMOVE_BY_ORDER_KEYS: {
      const images = { ...state.orderImages.images };
      action.payload.forEach((key: string) => {
        console.log(`Deleted obsolete order (${key}) from image cache`);
        delete images[key];
      });
      return { ...state, orderImages: { images } };
    }
    case ImagesActionTypes.SET_ADD_IMAGE_JOB_KEY: {
      return { ...state, addImageJobKey: action.payload };
    }
    case SitesActionTypes.CHANGE_SITE:
    case UserActionTypes.LOGOUT:
      return {
        ...state,
        orderImages: initialState.orderImages,
      };
    default: {
      return state;
    }
  }
};

export { reducer as imagesReducer };
