import React from 'react';

import { makeStyles } from '@mui/styles';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import theme from 'theme';
import { formatMass } from 'components/utils';
import { FormatUnit } from 'store/weighing/types';

interface WeighingStatusProps {
  title: string;
  locked: boolean;
  massKg: number;
  inProgress: boolean;
  warningText: string;
}

const useStyles = makeStyles({
  progress: {
    position: 'relative',
    left: 0,
    bottom: 0,
  },
  card: {
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '0px',
    },
  },
  cardContent: {
    '& > div': {
      paddingBottom: '12px',
    },
  },
  weightText: (props: WeighingStatusProps) => ({
    color: props.locked ? theme.palette.secondary.main : theme.palette.common.black,
    fontWeight: 600,
  }),
  unitText: {
    color: theme.palette.text.disabled,
  },
  done: {
    fontSize: 40,
  },
  warning: {
    color: theme.palette.error.main,
  },
});

const WeighingStatusComponent = (props: WeighingStatusProps) => {
  const classes = useStyles(props);

  return (
    <Card className={classes.card}>
      <CardHeader title={props.title} />
      <Divider variant="middle" component="hr" />
      <CardContent className={classes.cardContent}>
        <Grid container justifyContent="space-between">
          <Grid item>
            {props.inProgress && <CircularProgress className={classes.progress} />}
            {props.locked && <DoneIcon color="secondary" className={classes.done} />}
          </Grid>
          <Grid item>
            <Typography
              display="inline"
              variant={'h3'}
              component={'div'}
              className={classes.weightText}
            >
              {`${formatMass(props.massKg, FormatUnit.WITHOUT_UNIT)}`}
            </Typography>
            <Typography
              display="inline"
              variant={'h3'}
              component={'div'}
              className={classes.unitText}
            >
              &nbsp;{`kg`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item className={classes.warning}>
            {props.warningText}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default WeighingStatusComponent;
