import React from 'react';
import { RouteComponentProps } from 'react-router';

import { Grid } from '@mui/material';

import { connect, localeConnect } from 'localeConnect';
import { ApplicationState, ConnectedReduxProps } from 'store';
import { UserDetails } from 'store/user/types';
import { getHasOrganizationRoleRight } from 'store/user/utils';

import { translate } from 'utils/translate';
import DashboardLatestJobs from './DashboardLatestJobs';
import DashboardMeasDeviceGroups from './DashboardMeasDeviceGroups';
import InProgressOrders from './InProgressOrders';
import OperatorContainer from './OperatorContainer';

interface PropsFromState {
  userDetails: UserDetails | undefined;
}

interface PropsFromDispatch {}

/**
 * NOTE(mikkogy, 20200130) There's a bug in Eslint config that has been reported but not yet fixed
 * which complains about missing indentation when multiline binary expressions are used.
 * See: https://github.com/typescript-eslint/typescript-eslint/issues/398
 */
/*eslint-disable */
export type AllProps = PropsFromDispatch &
  PropsFromState &
  RouteComponentProps<{}> &
  ConnectedReduxProps;
/*eslint-enable */

const mapStateToProps = (state: ApplicationState) => ({
  userDetails: state.user.user.userData,
});

const mapDispatchToProps = {};

const OperatorDashboard = (props: AllProps) => {
  return (
    <OperatorContainer title={translate('dashboard.title')} title2={translate('dashboard.title2')}>
      <Grid container spacing={3}>
        <Grid item sm={12} md={12} lg={9}>
          <InProgressOrders />
          {getHasOrganizationRoleRight(props.userDetails, 'listOrgJobs') && <DashboardLatestJobs />}
        </Grid>
        <Grid item sm={12} md={12} lg={3}>
          <DashboardMeasDeviceGroups />
        </Grid>
      </Grid>
    </OperatorContainer>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(OperatorDashboard);
