export async function callApi(
  method: string,
  url: string,
  path: string,
  data?: any,
  authorization?: any,
) {
  const headers = {
    ...{
      // eslint-disable-next-line
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    ...authorization,
  };

  const res = await fetch(url + path, {
    method,
    credentials: 'include',
    headers: headers,
    body: data ? JSON.stringify(data) : null,
  });
  return await res.json();
}
