import { ContainerType } from '../store/common/types';
import { Job } from '../store/jobs/types';
import {
  Component,
  ComponentGroup,
  Context,
  Order,
  Split as ModelSplit,
  SplitLoad,
} from '../store/orders/types';
import {
  getSplitComponents,
  isUpdatingOrderAllowed,
  requiredTypes,
  SplitComponent,
} from '../store/orders/utils';
import { OrganizationEnabledFeatures } from '../store/scale-info/types';
import { UserDetails } from '../store/user/types';
import { undefinedMassKg } from '../store/weighing/types';

export interface Split {
  isDeletingEnabled: boolean;
  component: Component;
  amount: number | undefined;
  kgs: number | undefined;
  requiredTypes: string[];
}

export interface SplitLoadModel {
  componentGroup: ComponentGroup;
  splits: Split[];
  areAllAmountsZero: boolean;
  isAddingEnabled: boolean;
  isAmountMissing: boolean;
  isSplitAvailable: boolean;
  isSplitEnabled: boolean;
}

interface SplitLoadModelProps {
  order: Order;
  isOrderTemporaryCopy: boolean;
  componentGroup: ComponentGroup;
  splitLoad: SplitLoad | undefined;
  organizationEnabledFeatures: OrganizationEnabledFeatures;
  scaleKey: string;
  userDetails: UserDetails | undefined;
}

interface ContextSplitLoadModelProps {
  context: Context;
  componentGroup: ComponentGroup;
  organizationEnabledFeatures: OrganizationEnabledFeatures;
  scaleKey: string;
  userDetails: UserDetails | undefined;
}

// NOTE(mikkogy,20230110) dummy model to simplify UI. This way we can always
// render UI based on SplitLoadModel. All split load related features are simply
// off.
export function createSplitLoadModelFromSingleComponent(
  order: Order,
  componentId: string,
): SplitLoadModel {
  const component = order.components.find((component) => {
    return component.id === componentId;
  });

  function getSplits() {
    if (component === undefined) return [];
    return [
      {
        isDeletingEnabled: false,
        component,
        amount: undefined,
        kgs: undefined,
        requiredTypes: requiredTypes(order, componentId),
      },
    ];
  }

  const splits = getSplits();

  return {
    componentGroup: component?.componentGroup ?? ComponentGroup.TRUCK,
    splits,
    areAllAmountsZero: false,
    isAddingEnabled: false,
    isAmountMissing: false,
    isSplitAvailable: false,
    isSplitEnabled: false,
  };
}

export function createSplitLoadModel(props: SplitLoadModelProps): SplitLoadModel {
  const {
    order,
    isOrderTemporaryCopy,
    componentGroup,
    splitLoad,
    organizationEnabledFeatures,
    scaleKey,
    userDetails,
  } = props;
  function getRequiredTypes(componentId: string) {
    return requiredTypes(order, componentId);
  }

  const isComponentGroupMissing = order.components.reduce(
    (isMissing: boolean, component: Component) =>
      isMissing || component.componentGroup === undefined,
    false,
  );

  function amountFromSplit(split: ModelSplit | undefined) {
    if (!split || split.amount === undefined) {
      return undefined;
    }
    if (split.amount < 0) {
      return undefined;
    }
    return split.amount;
  }

  function kgsFromSplit(split: ModelSplit | undefined) {
    if (!split || split.kgs === undefined || split.kgs === undefinedMassKg) {
      return undefined;
    }
    return split.kgs;
  }

  const areOrderUpdatesAllowed = isUpdatingOrderAllowed(order, scaleKey, userDetails);

  const splitComponents = getSplitComponents(order, splitLoad, componentGroup);
  const splits = splitComponents.components.map(
    (splitComponent: SplitComponent): Split => ({
      component: splitComponent.component,
      isDeletingEnabled:
        splitComponents.components.length > 1 && areOrderUpdatesAllowed && !isOrderTemporaryCopy,
      amount: amountFromSplit(splitComponent.split),
      kgs: kgsFromSplit(splitComponent.split),
      requiredTypes: getRequiredTypes(splitComponent.component.id),
    }),
  );
  const isSplitEnabled = splitComponents.isSplitEnabled;
  const splitAmounts = splits.map((split: Split) => split.amount);
  const isAnyAmountUndefined = splitAmounts.reduce(
    (hasUndefined: boolean, amount: number | undefined) => {
      return hasUndefined || amount === undefined;
    },
    false,
  );
  const areAllAmountsZero =
    splitAmounts.length > 0 &&
    splitAmounts.reduce(
      (areAllZero: boolean, amount: number | undefined) => areAllZero && amount === 0,
      true,
    );
  const isAmountMissing = isSplitEnabled && (isAnyAmountUndefined || areAllAmountsZero);
  const isAddingEnabled = isSplitEnabled && areOrderUpdatesAllowed && !isOrderTemporaryCopy;

  const isSplitAvailable =
    (isSplitEnabled || organizationEnabledFeatures.bridgeSplitLoads) &&
    !isComponentGroupMissing &&
    splits.length > 0;

  return {
    componentGroup,
    splits,
    areAllAmountsZero,
    isAddingEnabled,
    isAmountMissing,
    isSplitEnabled,
    isSplitAvailable,
  };
}

export function createSplitLoadModelFromContext(props: ContextSplitLoadModelProps): SplitLoadModel {
  return createSplitLoadModel({
    order: props.context.order,
    isOrderTemporaryCopy: props.context.isOrderTemporaryCopy,
    componentGroup: props.componentGroup,
    organizationEnabledFeatures: props.organizationEnabledFeatures,
    scaleKey: props.scaleKey,
    splitLoad: props.context.splitLoad,
    userDetails: props.userDetails,
  });
}

// NOTE(mikkogy,20230110) helper function with simpler parameters only to check
// if amount is missing which is important information for weighing related
// models. It would be possible to create SplitLoadModels in those models but
// passing irrelevant parameters around makes it ugly. Here we can create dummy
// parameters near implementation which makes it easy to keep them up to date.
// We could also check SplitLoad directly but using full blown model makes it
// easier to prevent this helper and model creation getting out of sync.
export const isAmountMissing = (order: Order, splitLoad: SplitLoad | undefined, mainJob: Job) => {
  const dummyModels = createDummyModels(order, splitLoad);
  const hasTrailer = mainJob.containers[0]?.containerType === ContainerType.TRUCK_TRAILER;
  return dummyModels.truck.isAmountMissing || (hasTrailer && dummyModels.trailer.isAmountMissing);
};

interface DummyModels {
  truck: SplitLoadModel;
  trailer: SplitLoadModel;
}

function createDummyModels(order: Order, splitLoad: SplitLoad | undefined): DummyModels {
  const dummyFeatures = { bridgeSplitLoads: false };
  const dummyScaleKey = 'this is not a real scale key and will not match';
  const dummyUserDetails = {
    username: 'invalid',
    domainData: undefined,
    contactCard: {
      familyName: '',
      givenName: '',
      org: {
        organizationName: '',
      },
    },
    locale: '',
    key: '',
    organization: '',
    organizationRoleRights: {},
  };
  const isOrderTemporaryCopy = true;
  const truckModel = createSplitLoadModel({
    order,
    isOrderTemporaryCopy,
    componentGroup: ComponentGroup.TRUCK,
    splitLoad,
    organizationEnabledFeatures: dummyFeatures,
    scaleKey: dummyScaleKey,
    userDetails: dummyUserDetails,
  });
  const trailerModel = createSplitLoadModel({
    order,
    isOrderTemporaryCopy,
    componentGroup: ComponentGroup.TRAILER,
    splitLoad,
    organizationEnabledFeatures: dummyFeatures,
    scaleKey: dummyScaleKey,
    userDetails: dummyUserDetails,
  });
  return {
    truck: truckModel,
    trailer: trailerModel,
  };
}
