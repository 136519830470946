import { action } from 'typesafe-actions';
import {
  DetailedMasterDataType,
  MasterDataItem,
  Pagination,
  ServerPagination,
} from '../common/types';
import {
  CreateMasterDataProperties,
  GenericMasterData,
  MasterDataActionTypes,
  MasterDataOrderReference,
  MasterDataCloudSearchParams,
  MasterDataCloudSearchResults,
  MasterDataUpdateType,
  UpdateMasterDataProperties,
} from './types';

export const loadHistoryItems = () => action(MasterDataActionTypes.LOAD_HISTORY_ITEMS);

export const updateHistoryItems = (type: DetailedMasterDataType, masterData: GenericMasterData[]) =>
  action(MasterDataActionTypes.UPDATE_HISTORY_ITEMS, { type, masterData });

export const fetchRequest = (masterDataType: DetailedMasterDataType, pagination: Pagination) =>
  action(MasterDataActionTypes.FETCH_REQUEST, { masterDataType, pagination });

export const fetchSuccess = (
  masterDataType: DetailedMasterDataType,
  data: MasterDataItem[],
  pagination: ServerPagination,
) =>
  action(MasterDataActionTypes.FETCH_SUCCESS, {
    masterDataType,
    data,
    pagination,
  });

export const setMaxSearchHistoryCount = (maxSearchHistoryCount: number) =>
  action(MasterDataActionTypes.SET_MAX_SEARCH_HISTORY_COUNT, maxSearchHistoryCount);

export const masterDataSelected = (
  masterDataType: DetailedMasterDataType,
  item: GenericMasterData,
) => action(MasterDataActionTypes.DATA_SELECTED, { masterDataType, item });

// operator
export const createMasterData = (
  properties: CreateMasterDataProperties,
  requestReference: string,
  orderReference: MasterDataOrderReference | undefined,
) =>
  action(MasterDataActionTypes.CREATE_MASTER_DATA, {
    properties,
    requestReference,
    orderReference,
  });
export const searchCloudMasterData = (params: MasterDataCloudSearchParams) =>
  action(MasterDataActionTypes.CLOUD_SEARCH_REQUEST, params);
export const cloudSearchSuccess = (results: MasterDataCloudSearchResults) =>
  action(MasterDataActionTypes.CLOUD_SEARCH_SUCCESS, results);

export const searchMasterData = (type: DetailedMasterDataType, text: string) =>
  action(MasterDataActionTypes.SEARCH_REQUEST, { type, text });
export const searchSuccess = (
  stateType: string,
  results: MasterDataCloudSearchResults,
  text: string,
) => action(MasterDataActionTypes.SEARCH_SUCCESS, { stateType, results, text });

export const setModifyMasterDataSuccessful = () =>
  action(MasterDataActionTypes.SET_MODIFY_MASTER_DATA_SUCCESSFUL);
export const setModifyErrorReference = (modifyErrorReference: string) =>
  action(MasterDataActionTypes.SET_MODIFY_ERROR_REFERENCE, modifyErrorReference);
export const updateMasterData = (
  properties: UpdateMasterDataProperties,
  requestReference: string,
) =>
  action(MasterDataActionTypes.UPDATE_MASTER_DATA, {
    properties,
    requestReference,
  });

export const subscribeToMasterDataTypes = (
  subscriber: string,
  updateType: MasterDataUpdateType,
  dataTypes: DetailedMasterDataType[],
) =>
  action(MasterDataActionTypes.CREATE_SUBSCRIPTION_TO_MASTER_DATA, {
    dataTypes,
    subscriber,
    updateType,
  });
export const unsubscribeToMasterDataTypes = (unsubscriber: string) =>
  action(MasterDataActionTypes.REMOVE_SUBSCRIPTION_FROM_MASTER_DATA, unsubscriber);
