import React, { useState, useEffect } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';

import { Button, Container, Fab, Grid, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import OutlinedCheckIcon from '@mui/icons-material/CheckCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import ShareIcon from '@mui/icons-material/Share';

import { connect, localeConnect } from 'localeConnect';
import theme from 'theme';
import { ApplicationState, ConnectedReduxProps } from 'store';
import { newToast } from 'store/common/actions';
import { clear as clearDeepLink } from 'store/deep-link/actions';
import { DomainInfo } from 'store/scale-info/types';
import { Job } from 'store/jobs/types';
import { sortJobsByReceiptNumberDescending } from 'store/jobs/utils';
import { endViewingOrder } from 'store/orders/actions';
import { fetchRequest } from 'store/jobs/actions';
import { getDriverReceiptJobs, areJobsLoaded } from 'store/selectors';
import { RoutePaths } from 'routes';
import { isPdfDownloadSupported, isShareSupported, shareReceipt } from 'utils/receiptUtils';
import ReceiptItem, { receiptWrapperStyle } from 'components/ReceiptItem';

import DisabledWrap from 'components/DisabledWrap';
import { translate } from 'utils/translate';
import DeepLinkReturnLink from '../DeepLinkReturnLink';

const useStyles = makeStyles({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    minHeight: '100vh',
    maxWidth: '100%',
  },
  container: {
    maxWidth: '90%',
  },
  checkMain: {
    width: '64px',
    height: '64px',
  },
  checkButton: {
    position: 'relative',
    bottom: '80px',
  },
  checkButtonContainer: {
    height: '0px',
  },
  deepLinkReturnLink: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    paddingBottom: '20px',
  },
  downloadButton: {
    // eslint-disable-next-line
    width: '50%',
    // eslint-disable-next-line
    display: 'inline-block',
    '& a': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
    },
  },
  downloadButtonContainer: {
    ...receiptWrapperStyle,
  },
  fakeLinkButton: {
    color: theme.palette.common.white,
    textDecoration: 'underline',
    textTransform: 'none',
  },
  receiptContainer: {
    marginBottom: '40px',
  },
});

interface PropsFromState {
  readonly deepLinkReturnLink: string | undefined;
  readonly domainInfo: DomainInfo;
  readonly jobs: Job[];
  readonly areJobsLoaded: boolean;
  readonly receiptDocuments: string[];
  readonly driverReceiptJobKeys: string[];
}

interface PropsFromDispatch {
  clearDeepLink: typeof clearDeepLink;
  fetchJobs: typeof fetchRequest;
  endViewingOrder: typeof endViewingOrder;
  newToast: typeof newToast;
}

/**
 * NOTE(anttipalola, 20190611) There's a bug in Eslint config that has been reported but not yet fixed
 * which complains about missing indentation when multiline binary expressions are used.
 * See: https://github.com/typescript-eslint/typescript-eslint/issues/398
 */
/*eslint-disable */
export type AllProps = PropsFromDispatch &
  PropsFromState &
  RouteComponentProps<{}> &
  ConnectedReduxProps;
/*eslint-enable */

/* TODO(anttipalola, 20190617) Render check will run on every state change: Add reselect or other
 * memoizing technique/library to getJobForOrder to prevent it.
 */
const mapStateToProps = (state: ApplicationState) => ({
  areJobsLoaded: areJobsLoaded(state),
  deepLinkReturnLink: state.deepLink.returnLink,
  domainInfo: state.currentScaleInfo.domainInfo,
  jobs: getDriverReceiptJobs(state),
  receiptDocuments: state.jobs.receiptDocuments,
  driverReceiptJobKeys: state.jobs.driverReceiptJobKeys,
});

const mapDispatchToProps = {
  clearDeepLink,
  fetchJobs: fetchRequest,
  endViewingOrder,
  newToast,
};

const receiptContent = (domainInfo: DomainInfo, job: Job, index: number, receiptCount: number) => {
  const receiptNumber = receiptCount > 1 ? index + 1 : undefined;
  const shownCount = receiptCount > 1 ? receiptCount : undefined;
  return (
    <ReceiptItem
      domainInfo={domainInfo}
      job={job}
      receiptNumber={receiptNumber}
      receiptTotalCount={shownCount}
    />
  );
};

const useLoadingStyles = makeStyles(() => ({
  '@keyframes receiptRedirectLinkFadeIn': {
    '0%': {
      visibility: 'hidden',
      height: '0px',
    },
    '99%': {
      visibility: 'hidden',
      height: '0px',
    },
    '100%': {
      visibility: 'visible',
      height: '20px',
    },
  },
  // eslint-disable-next-line
  container: {
    animation: '$receiptRedirectLinkFadeIn 20s',
    color: theme.palette.common.white,
  },
  // eslint-disable-next-line
  link: {
    textDecorationColor: theme.palette.common.white,
  },
}));

// NOTE(mikkogy,20200807) to avoid timing issues we always enable manually
// returning to orders if loading takes a long time. Job query will on some
// systems always first return without the expected job causing a wrong redirect
// which is much more severe than staying in receipt view but never showing it
// in the rare case that user is not allowed to access the weighing job.
function LoadingContent() {
  const classes = useLoadingStyles();
  return (
    <div>
      <Typography variant="h4">{translate('loading')}</Typography>
      <NavLink to={RoutePaths.ORDERS} className={classes.link}>
        <div className={classes.container}>{translate('receipt.loadingBackToOrders')}</div>
      </NavLink>
    </div>
  );
}

interface ShareProps {
  classes: any;
  domainInfo: DomainInfo;
  job: Job;
  newToast: typeof newToast;
  receiptDocument: string;
}

function ShareContent(props: ShareProps) {
  const { classes, job, receiptDocument } = props;
  const [isOpen, setIsOpen] = useState(false);
  const base64Content = job && receiptDocument ? receiptDocument : '';
  const receiptFilename = `${
    job && job.receiptNumber !== undefined ? job.receiptNumber : 'receipt'
  }.pdf`;
  return (
    <div className={classes.downloadButtonContainer}>
      {!isOpen && (
        <Button className={classes.fakeLinkButton} onClick={() => setIsOpen(true)}>
          {translate('receipt.shareOrSend')}
        </Button>
      )}
      {isOpen && (
        <div>
          <span className={classes.downloadButton}>
            {isPdfDownloadSupported() && (
              <IconButton
                color="inherit"
                aria-label={translate('receipt.downloadPdf')}
                href={'data:application/pdf;base64,' + base64Content}
                download={receiptFilename}
              >
                <PdfIcon />
              </IconButton>
            )}
            {!isPdfDownloadSupported() && (
              <DisabledWrap onClick={() => props.newToast('receipt.downloadPdfNotSupported')}>
                <IconButton
                  color="inherit"
                  aria-label={translate('receipt.downloadPdf')}
                  disabled={true}
                >
                  <PdfIcon />
                </IconButton>
              </DisabledWrap>
            )}
            <div>{translate('receipt.downloadPdf')}</div>
          </span>
          <span className={classes.downloadButton}>
            {isShareSupported() && (
              <IconButton
                color="inherit"
                aria-label={translate('receipt.share')}
                onClick={() => shareReceipt(job as Job, props.domainInfo)}
              >
                <ShareIcon />
              </IconButton>
            )}
            {!isShareSupported() && (
              <DisabledWrap onClick={() => props.newToast('receipt.shareNotSupported')}>
                <IconButton color="inherit" aria-label={translate('receipt.share')} disabled={true}>
                  <ShareIcon />
                </IconButton>
              </DisabledWrap>
            )}
            <div>{translate('receipt.share')}</div>
          </span>
        </div>
      )}
    </div>
  );
}

const readyContent = (props: AllProps, classes: any) => {
  return (
    <Container className={classes.container}>
      <Grid item>
        <OutlinedCheckIcon className={classes.checkMain} />
      </Grid>
      <Grid item>
        <Typography variant={'h4'}>
          <strong>{translate('receipt.title')}</strong>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant={'h5'}>{translate('receipt.subtitle')}</Typography>
      </Grid>
      {sortJobsByReceiptNumberDescending(props.jobs).map((job, index) => (
        <div className={classes.receiptContainer} key={job.key}>
          {job && (
            <Grid item>
              <ShareContent
                classes={classes}
                domainInfo={props.domainInfo}
                job={job}
                newToast={props.newToast}
                receiptDocument={props.receiptDocuments[index]}
              />
            </Grid>
          )}
          <Grid item>{job && receiptContent(props.domainInfo, job, index, props.jobs.length)}</Grid>
        </div>
      ))}
      <div className={classes.checkButtonContainer}>
        <Fab
          color="secondary"
          aria-label="okay"
          className={classes.checkButton}
          onClick={() => {
            props.endViewingOrder();
            if (props.deepLinkReturnLink) {
              props.clearDeepLink();
              window.location.replace(props.deepLinkReturnLink);
            }
          }}
        >
          <CheckIcon />
        </Fab>
      </div>
      {props.deepLinkReturnLink && (
        <div className={classes.deepLinkReturnLink}>{translate('receipt.deepLinkReturnInfo')}</div>
      )}
    </Container>
  );
};

const ReceiptComponent = (props: AllProps) => {
  const classes = useStyles();

  const { fetchJobs } = props;
  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  if (props.driverReceiptJobKeys.length === 0) {
    return <Redirect to={RoutePaths.ORDERS} />;
  }

  return (
    <div className={classes.root}>
      <DeepLinkReturnLink isContextIgnored={true} />
      {(!props.areJobsLoaded ||
        props.jobs.length < 1 ||
        props.jobs.length !== props.receiptDocuments.length) && <LoadingContent />}
      {props.areJobsLoaded &&
        props.jobs.length >= 1 &&
        props.jobs.length === props.receiptDocuments.length &&
        readyContent(props, classes)}
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(ReceiptComponent);
