import { Reducer } from 'redux';
import { OrdersActionTypes } from '../orders/types';
import { SitesActionTypes } from '../sites/types';
import { UserActionTypes } from '../user/types';
import { MeasDeviceState, MeasDeviceActionTypes, TrafficControlGroupStates } from './types';

export const initialState: MeasDeviceState = {
  manualKgs: {},
  measDeviceGroupReversing: {},
  measDeviceGroups: undefined,
  measDevices: [],
  errors: undefined,
  hasWeighingStartError: false,
  loading: false,
  selectedGroupId: undefined,
  pendingMeasDeviceKey: '',
  trafficControlGroupStatuses: {},
  trafficControlGroupDesiredStates: {},
  trafficControlConnectionOk: false,
};

const reducer: Reducer<MeasDeviceState> = (state = initialState, action) => {
  switch (action.type) {
    case MeasDeviceActionTypes.RECEIVE_LIST_SUCCESS: {
      return {
        ...state,
        measDeviceGroups: action.payload.measDeviceGroups,
        measDevices: action.payload.measDevices,
      };
    }
    case MeasDeviceActionTypes.SELECTED: {
      return { ...state, loading: false, selectedGroupId: action.payload };
    }
    case MeasDeviceActionTypes.CLEAR_SELECTED_MEAS_DEVICE: {
      return { ...state, loading: false, selectedGroupId: undefined };
    }
    case MeasDeviceActionTypes.START_WEIGHING: {
      return { ...state, hasWeighingStateError: false, loading: true };
    }
    case MeasDeviceActionTypes.STARTED_WEIGHING: {
      return { ...state, loading: false };
    }
    case MeasDeviceActionTypes.FAILED_TO_START_WEIGHING: {
      return { ...state, hasWeighingStartError: true, loading: false };
    }
    case MeasDeviceActionTypes.CLEAR_WEIGHING_START_ERROR: {
      return { ...state, hasWeighingStartError: false };
    }
    case MeasDeviceActionTypes.SET_MANUAL_KGS: {
      const manualKgs = { ...state.manualKgs };
      manualKgs[action.payload.key] = action.payload.kgs;
      return { ...state, manualKgs: manualKgs };
    }
    case MeasDeviceActionTypes.SET_PENDING_MEAS_DEVICE_KEY: {
      return { ...state, pendingMeasDeviceKey: action.payload };
    }
    case UserActionTypes.LOGOUT:
    case SitesActionTypes.CHANGE_SITE: {
      return { ...initialState };
    }
    case OrdersActionTypes.CREATE_CONTEXT:
    case OrdersActionTypes.CREATE_CONTEXT_FOR_EMPTY_ORDER:
    case OrdersActionTypes.SET_CURRENT_CONTEXT_ORDER_KEY: {
      return { ...state, manualKgs: {} };
    }
    case MeasDeviceActionTypes.TOGGLE_MEAS_DEVICE_GROUP_REVERSING: {
      const groupId = action.payload as string;
      const measDeviceGroupReversing = state.measDeviceGroupReversing;
      if (measDeviceGroupReversing[groupId] === undefined) {
        measDeviceGroupReversing[groupId] = true;
      } else {
        measDeviceGroupReversing[groupId] = !measDeviceGroupReversing[groupId];
      }
      return { ...state, measDeviceGroupReversing };
    }
    case MeasDeviceActionTypes.RECEIVE_TRAFFIC_CONTROL_GROUP_UPDATE: {
      const trafficControlGroupStatus: TrafficControlGroupStates =
        action.payload.trafficControlStatuses;
      const trafficControlConnectionOk = action.payload.trafficControlConnectionOk;
      return {
        ...state,
        trafficControlGroupStatuses: trafficControlGroupStatus,
        trafficControlGroupDesiredStates: trafficControlGroupStatus,
        trafficControlConnectionOk: trafficControlConnectionOk,
      };
    }
    case MeasDeviceActionTypes.UPDATE_TRAFFIC_CONTROL_GROUP_DESIRED_STATE: {
      const newDesiredStates = { ...state.trafficControlGroupStatuses };
      newDesiredStates[action.payload.trafficControlGroupId] = action.payload.status;
      return { ...state, trafficControlGroupDesiredStates: newDesiredStates };
    }
    case MeasDeviceActionTypes.RESET_TRAFFIC_CONTROL_GROUP_DESIRED_STATE: {
      const newDesiredStates = { ...state.trafficControlGroupStatuses };
      newDesiredStates[action.payload.trafficControlGroupId] =
        state.trafficControlGroupStatuses[action.payload.trafficControlGroupId];
      return { ...state, trafficControlGroupDesiredStates: newDesiredStates };
    }
    default: {
      return state;
    }
  }
};

export { reducer as measDevicesReducer };
