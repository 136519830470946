import React from 'react';
import { Box, Button, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect, localeConnect } from 'localeConnect';
import {
  TrafficControlGroupStates,
  TrafficControlMode,
  TrafficControlStatus,
} from 'store/meas-devices/types';
import { ApplicationState } from 'store';
import {
  updateTrafficControlGroupMode,
  updateTrafficControlGroupStatus,
} from 'store/meas-devices/actions';

import { translate } from 'utils/translate';
import { TrafficLights } from './TrafficLights';

const useStyles = makeStyles({
  trafficControlGroupContainer: {
    // eslint-disable-next-line
    alignItems: 'flex-start',
    // eslint-disable-next-line
    display: 'flex',
    // eslint-disable-next-line
    justifyContent: 'center',
    // eslint-disable-next-line
    width: '200px',
    // eslint-disable-next-line
    minWidth: '200px',
    '& .MuiGrid-item': {
      marginTop: '1px',
      marginBottom: '5px',
      textAlign: 'left',
    },
    '& .MuiButton-label': {
      fontSize: '12px',
    },
    '& .MuiTypography-body1': {
      fontSize: '12px',
    },
    '& .MuiFormControlLabel-root': {
      marginLeft: '-5px',
    },
  },
  trafficControlGroupRight: {
    '@media (min-width: 960px)': {
      marginLeft: 'auto',
    },
  },
  trafficControlHeader: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
  trafficControlMode: {
    display: 'block',
    width: '200px',
  },
  trafficLights: {
    paddingLeft: '5px',
    paddingRight: '10px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
});

interface ParameterProps {
  alignRight: boolean;
  trafficControlGroupId: string;
}

interface PropsFromState {
  trafficControlGroupStatuses: TrafficControlGroupStates;
  trafficControlGroupDesiredStates: TrafficControlGroupStates;
  trafficControlConnectionOk: boolean;
}

interface PropsFromDispatch {
  updateTrafficControlGroupStatus: typeof updateTrafficControlGroupStatus;
  updateTrafficControlGroupMode: typeof updateTrafficControlGroupMode;
}

const mapStateToProps = (state: ApplicationState) => ({
  trafficControlGroupStatuses: state.measDevices.trafficControlGroupStatuses,
  trafficControlGroupDesiredStates: state.measDevices.trafficControlGroupDesiredStates,
  trafficControlConnectionOk: state.measDevices.trafficControlConnectionOk,
});

const mapDispatchToProps = {
  updateTrafficControlGroupStatus,
  updateTrafficControlGroupMode,
};

type AllProps = PropsFromDispatch & PropsFromState & ParameterProps;

const OperatorTrafficControlGroup = (props: AllProps) => {
  const classes = useStyles();

  const trafficControlGroup = props.trafficControlGroupStatuses[props.trafficControlGroupId];

  const currentMode = trafficControlGroup?.mode;
  const currentStatus = trafficControlGroup?.status;
  const currentDesiredMode =
    props.trafficControlGroupDesiredStates[props.trafficControlGroupId]?.mode;
  const toggleStatus = () => {
    if (currentMode !== TrafficControlMode.Manual) return;

    const newStatus =
      currentStatus === TrafficControlStatus.Pass
        ? TrafficControlStatus.Stop
        : TrafficControlStatus.Pass;

    props.updateTrafficControlGroupStatus(props.trafficControlGroupId, newStatus);
  };

  const toggleMode = () => {
    if (!props.trafficControlConnectionOk) return;
    const newMode =
      currentMode === TrafficControlMode.Automatic
        ? TrafficControlMode.Manual
        : TrafficControlMode.Automatic;
    props.updateTrafficControlGroupMode(props.trafficControlGroupId, newMode);
  };
  const alignRight = props.alignRight;
  return (
    <Box
      className={`${classes.trafficControlGroupContainer} ${
        alignRight ? classes.trafficControlGroupRight : ''
      }`}
    >
      {trafficControlGroup ? (
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <Box onClick={() => toggleStatus()}>
              <TrafficLights
                trafficControlGroupId={props.trafficControlGroupId}
                status={trafficControlGroup.status}
              />
            </Box>
          </Grid>
          <Grid container spacing={0} item xs={8}>
            <Grid item>
              <Typography className={classes.trafficControlHeader} variant="body1">
                {translate('trafficControl.title')}
              </Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                labelPlacement={'end'}
                onChange={toggleMode}
                disabled={!props.trafficControlConnectionOk || currentDesiredMode !== currentMode}
                control={
                  <Switch
                    size={'small'}
                    color="primary"
                    checked={currentDesiredMode === TrafficControlMode.Automatic}
                  />
                }
                label={translate('trafficControl.automaticMode')}
              />
            </Grid>
            <Grid item>
              <Box width={'100px'}>
                <Button
                  size={'small'}
                  fullWidth={true}
                  variant={'contained'}
                  color="primary"
                  disabled={
                    currentMode !== TrafficControlMode.Manual || !props.trafficControlConnectionOk
                  }
                  onClick={() => toggleStatus()}
                >
                  {translate('trafficControl.changeStatus')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
};
const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(OperatorTrafficControlGroup);
