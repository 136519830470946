import { MasterDataItem, RemoteRequestIds } from './types';

export function formatUniqueCreateMasterDataRequestId(uniqueReference: string) {
  return `${RemoteRequestIds.CREATE_MASTER_DATA}_${uniqueReference}`;
}

export function formatUniqueUpdateMasterDataRequestId(uniqueReference: string) {
  return `${RemoteRequestIds.UPDATE_MASTER_DATA}_${uniqueReference}`;
}

export function filterUniqueMasterDataItems(items: MasterDataItem[]) {
  const keys: { [key: string]: boolean } = {};
  const result: MasterDataItem[] = [];
  for (const i in items) {
    const item = items[i];
    if (keys[item.key]) {
      continue;
    }
    keys[item.key] = true;
    result.push(item);
  }
  return result;
}

export function joinUniqueMasterDataItemNames(items: MasterDataItem[], docType: string) {
  const itemsOfType = items.filter((link) => link.docType === docType);
  const uniqueLinks = filterUniqueMasterDataItems(itemsOfType);
  if (uniqueLinks.length === 0) {
    return '';
  }
  return uniqueLinks.reduce((base, link) => {
    if (!base) {
      return link.name;
    }
    return `${base}, ${link.name}`;
  }, '');
}
