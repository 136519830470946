import { connect } from 'react-redux';

import { ApplicationState } from './store';

//@ts-ignore
export function localeConnect<T>(mapStateToProps, ...connectArgs): T {
  const customMapper = (state: ApplicationState) => {
    return {
      ...mapStateToProps(state),
      // NOTE(mikkogy,20220401) uuid is used to avoid conflicting property names
      // in mapStateToProps.
      'locale77468ed5-d239-47f6-b10e-455a6352abd4': state.user.selectedLocale,
    };
  };
  //@ts-ignore
  return connect(customMapper, ...connectArgs);
}

export { connect };
