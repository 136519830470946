import { ContainerType } from '../store/common/types';

import { Job, JobContainer, Load, LoadType } from '../store/jobs/types';
import { loadWeighedKgs } from '../store/jobs/utils';
import { Order } from '../store/orders/types';
import { secondaryContainer, secondaryContainerTareKgs } from '../store/orders/utils';
import { FormatUnit, undefinedMassKg } from '../store/weighing/types';

import { filterValidLoads, masterDataKeyToType } from '../store/utils';
import { formatMass } from '../components/utils';
import { BridgeEnabledFeatures } from '../store/scale-info/types';
import { translate } from '../utils/translate';

export const MULTIPART_INITIAL_ROUND = 'initial';

export function containerValidLoads(job: Job, container: JobContainer | undefined) {
  if (!container || !container.loadIndexes) {
    return [];
  }
  const loads: Load[] = [];
  container.loadIndexes.forEach((loadIndex) => {
    loads.push(job.loads[loadIndex]);
  });
  return filterValidLoads(loads);
}

function containerValidLoad(job: Job, container: JobContainer | undefined, index: number) {
  const validLoads = containerValidLoads(job, container);
  if (validLoads.length > index) {
    return validLoads[index];
  }
  return undefined;
}

function kgsFromContainer(job: Job, container: JobContainer | undefined, index: number) {
  const validLoad = containerValidLoad(job, container, index);
  if (validLoad) {
    return loadWeighedKgs(validLoad);
  }
  return undefined;
}

function containerLoadType(job: Job, container: JobContainer | undefined, index: number) {
  const validLoad = containerValidLoad(job, container, index);
  if (validLoad) {
    return validLoad.loadType;
  }
  return undefined;
}

function attachments(job: Job) {
  return job.attachments?.map((a) => a.key) || [];
}

export function parseJobContainers(job: Job) {
  let truck: JobContainer | undefined;
  let trailer: JobContainer | undefined;
  const rootContainer = job.containers[0];
  if (rootContainer) {
    if (rootContainer.containerType === ContainerType.TRUCK_TRAILER) {
      if (rootContainer.containers && rootContainer.containers[0] && rootContainer.containers[1]) {
        truck = rootContainer.containers[0];
        trailer = rootContainer.containers[1];
      }
    } else if (rootContainer.containerType === ContainerType.TRUCK) {
      truck = rootContainer;
    }
  }
  return { truck, trailer };
}

export interface MultipartRound {
  key: string;
  componentId: string;
  comment: string | undefined;
  materialName: string;
  roundNumber: number;
  timestamp: number | undefined;
  isActive: boolean;
  loadType: LoadType | undefined;
  netKgs: number | undefined;
  truckKgs: number | undefined;
  trailerKgs: number | undefined;
  secondaryContainerName: string | undefined;
  secondaryContainerTareKgs: number;
  attachments: string[];
}

export function getMultipartRounds(
  jobs: Job[],
  order: Order,
  enabledFeatures: BridgeEnabledFeatures,
): MultipartRound[] {
  const { trailer } = parseJobContainers(jobs[0]);

  const initialRoundData = () => {
    const job = jobs[jobs.length - 1];
    const { truck, trailer } = parseJobContainers(job);
    return {
      key: MULTIPART_INITIAL_ROUND,
      componentId: '',
      comment: undefined,
      materialName: '',
      roundNumber: 0,
      timestamp: job.timestamp,
      isActive: false,
      loadType: containerLoadType(job, truck, 0),
      netKgs: undefined,
      truckKgs: kgsFromContainer(job, truck, 0),
      trailerKgs: kgsFromContainer(job, trailer, 0),
      secondaryContainerName: undefined,
      secondaryContainerTareKgs: undefinedMassKg,
      attachments: attachments(job),
    };
  };

  let data = [
    ...jobs.map((job, index) => {
      const timestamp =
        job.loads && job.loads.length > 1 ? job.loads[job.loads.length - 1].timestamp : undefined;
      const { truck, trailer } = parseJobContainers(job);
      const loadType = containerLoadType(job, truck, 1);
      const truckKgs = kgsFromContainer(job, truck, 1);
      const trailerKgs = kgsFromContainer(job, trailer, 1);
      const component = order.components.find(
        (component) => job?.order?.componentId === component.id,
      );
      const componentSecondaryContainer = secondaryContainer(order, component?.id ?? '');
      const componentSecondaryContainerKgs = secondaryContainerTareKgs(order, component?.id ?? '');
      let netKgs: number | undefined = undefined;
      if (truck !== undefined && truckKgs !== undefined) {
        netKgs = truck.sumMassKg || 0;
        if (trailer !== undefined && trailerKgs !== undefined) {
          netKgs += trailer.sumMassKg || 0;
        }
        if (
          componentSecondaryContainerKgs !== undefinedMassKg &&
          enabledFeatures.bridgeSecondaryContainerTareDeduction
        ) {
          netKgs -= componentSecondaryContainerKgs;
        }
      }
      let materialName = '';
      if (order?.linkedData && component?.dataLinks) {
        const materialLink = component.dataLinks.find(
          (link) => masterDataKeyToType(link) === 'material',
        );
        if (materialLink) {
          const material = order.linkedData.find((linked) => linked.key === materialLink);
          if (material) {
            materialName = material.name;
          }
        }
      }
      return {
        key: job.key,
        componentId: job.order.componentId,
        comment: job.comment,
        materialName,
        roundNumber: 0,
        timestamp,
        isActive: false,
        loadType,
        netKgs,
        truckKgs,
        trailerKgs,
        secondaryContainerName: componentSecondaryContainer?.name,
        secondaryContainerTareKgs: componentSecondaryContainerKgs,
        attachments: attachments(job),
      };
    }),
    initialRoundData(),
  ];
  let noKgItemFound = false;
  // NOTE(mikkogy,20210430) we want to show one round that has no kgs but not
  // multiple.
  data = data
    .reverse()
    .filter((data) => {
      if (data.truckKgs === undefined || (!!trailer && data.trailerKgs === undefined)) {
        const previous = noKgItemFound;
        noKgItemFound = true;
        return !previous;
      }
      return true;
    })
    .reverse();
  let isActiveFound = false;
  data.forEach((item, index) => {
    item.roundNumber = data.length - index;
    if (!isActiveFound && (item.truckKgs === undefined || item.trailerKgs === undefined)) {
      isActiveFound = true;
      item.isActive = true;
    }
  });
  return data;
}

export function sumRoundKgs(round: MultipartRound, withUnit = FormatUnit.WITH_UNIT) {
  const roundSumKgs = 0 + (round.truckKgs || 0) + (round.trailerKgs || 0);
  return formatMass(roundSumKgs, withUnit);
}

export function roundKgs(round: MultipartRound, withUnit = FormatUnit.WITH_UNIT) {
  const undefinedResult = '-';
  if (round.loadType === undefined) return undefinedResult;
  if (round.roundNumber === 1) return sumRoundKgs(round, withUnit);
  if (round.netKgs === undefined) return undefinedResult;
  return formatMass(round.netKgs, withUnit);
}

export function roundLoadType(round: MultipartRound) {
  if (round.roundNumber > 1) {
    return translate(`loadType.${LoadType.NET}`);
  }
  if (!round.loadType) {
    return '';
  }
  return translate(`loadType.${round.loadType}`);
}

export function roundMaterial(round: MultipartRound) {
  if (!round.materialName) {
    return translate('masterData.unknownItemName');
  }
  return round.materialName;
}
