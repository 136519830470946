import { action } from 'typesafe-actions';
import {
  Job,
  JobsActionTypes,
  JobQueryParams,
  JobQueryResponse,
  OperatorQueryParams,
  InspectionStatus,
} from './types';

export const clearQueryJobs = () => action(JobsActionTypes.JOB_QUERY_SUCCESS, undefined);
export const fetchRequest = () => action(JobsActionTypes.FETCH_REQUEST);
export const fetchSuccess = (data: Job[]) => action(JobsActionTypes.FETCH_SUCCESS, data);
export const fetchError = (message: string) => action(JobsActionTypes.FETCH_ERROR, message);

export const setDriverReceiptJobKeys = (jobKeys: string[]) => {
  if (jobKeys.length === 0) throw new Error('empty driver receipt keys array does not make sense');
  return action(JobsActionTypes.SET_DRIVER_RECEIPT_JOB_KEYS, jobKeys);
};
export const generateReceipt = (receipt: string) =>
  action(JobsActionTypes.GENERATE_RECEIPT, receipt);
export const receiptSuccess = (receipt: string) => action(JobsActionTypes.RECEIPT_SUCCESS, receipt);

// Operator
export const storeOperatorQuery = (params: OperatorQueryParams) =>
  action(JobsActionTypes.STORE_OPERATOR_QUERY, params);
export const setInspectionStatus = (
  contextId: string,
  inspectionStatus: InspectionStatus,
  weighingJobKey: string,
) =>
  action(JobsActionTypes.SET_INSPECTION_STATUS, {
    contextId,
    inspectionStatus,
    weighingJobKey,
  });
export const jobQueryRefresh = () => action(JobsActionTypes.JOB_QUERY_REFRESH);
export const jobQueryRequest = (params: JobQueryParams) =>
  action(JobsActionTypes.JOB_QUERY_REQUEST, params);
export const jobQuerySuccess = (results: JobQueryResponse) =>
  action(JobsActionTypes.JOB_QUERY_SUCCESS, results);
export const latestJobsQueryRequest = () => action(JobsActionTypes.LATEST_JOBS_QUERY_REQUEST);
export const latestJobsQuerySuccess = (results: JobQueryResponse) =>
  action(JobsActionTypes.LATEST_JOBS_QUERY_SUCCESS, results);
export const startLatestJobsPoll = () => action(JobsActionTypes.START_LATEST_JOBS_POLL);
export const stopLatestJobsPoll = () => action(JobsActionTypes.STOP_LATEST_JOBS_POLL);
