import React, { useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import { List, Typography } from '@mui/material';

import { connect, localeConnect } from 'localeConnect';
import { ApplicationState } from 'store';
import {
  createContext,
  setInspectorOrderTab,
  startInProgressOrderPoll,
  stopInProgressOrderPoll,
} from 'store/orders/actions';
import { User } from 'store/user/types';
import { InProgressOrder, InProgressOrders, InspectorOrderTab } from 'store/orders/types';
import { filterInspectorInProgressOrders } from 'store/orders/utils';
import { BridgeEnabledFeatures } from 'store/scale-info/types';
import LoadingIndicator from 'components/LoadingIndicator';
import { mobileContentMaxWidth } from 'theme';
import { translate } from 'utils/translate';
import InspectorOrderTabSelection from './InspectorOrderTabSelection';
import InspectorOrderCollapsableItem from './InspectorOrderCollapsableItem';

const useStyles = makeStyles({
  container: {
    // eslint-disable-next-line
    maxWidth: mobileContentMaxWidth,
    // eslint-disable-next-line
    margin: 'auto',
    // eslint-disable-next-line
    textAlign: 'left',
    '& .MuiListItem-root': {
      display: 'block',
    },
    '& .MuiListItemText-root': {
      flex: 'none',
      marginRight: '10px',
    },
  },
  noResultsTitle: {
    padding: '10px',
    textAlign: 'center',
  },
});

interface ParameterProps {
  onSelected: () => void;
}

interface PropsFromState {
  enabledFeatures: BridgeEnabledFeatures;
  inspectorOrderTab: InspectorOrderTab;
  inProgressOrders: InProgressOrders;
  userName: string;
  loading: boolean;
  schema?: Record<string, unknown>;
}

interface PropsFromDispatch {
  createContext: typeof createContext;
  setInspectorOrderTab: typeof setInspectorOrderTab;
  startInProgressOrderPoll: typeof startInProgressOrderPoll;
  stopInProgressOrderPoll: typeof stopInProgressOrderPoll;
}

/*eslint-disable */
export type AllProps = PropsFromDispatch & ParameterProps & PropsFromState;
/*eslint-enable */

function getUserName(user: User) {
  if (user && user.userData) {
    return `${user.userData.contactCard.givenName} ${user.userData.contactCard.familyName}`;
  } else return 'Unknown';
}
const mapStateToProps = ({ orders, user, currentScaleInfo }: ApplicationState) => ({
  enabledFeatures: currentScaleInfo.enabledFeatures,
  inspectorOrderTab: orders.inspectorOrderTab,
  inProgressOrders: orders.inProgressOrders,
  loading: orders.isWaitingForFirstInProgressOrders,
  userName: getUserName(user.user),
  schema: currentScaleInfo.domainInfo.masterDataCombo.schema,
});

const mapDispatchToProps = {
  createContext,
  setInspectorOrderTab,
  startInProgressOrderPoll,
  stopInProgressOrderPoll,
};

const InspectorOrderSelection = (props: AllProps) => {
  const classes = useStyles();

  const { startInProgressOrderPoll, stopInProgressOrderPoll } = props;
  useEffect(() => {
    startInProgressOrderPoll();
    return () => {
      stopInProgressOrderPoll();
    };
  }, [startInProgressOrderPoll, stopInProgressOrderPoll]);

  const doSelect = (orderKey: string) => {
    props.createContext('', orderKey, undefined, true);
    props.onSelected();
  };

  const getDataForSelectedTab = (): InProgressOrder[] => {
    return filterInspectorInProgressOrders(props.inProgressOrders.orders, props.inspectorOrderTab);
  };
  const data = getDataForSelectedTab();

  return (
    <div>
      <LoadingIndicator isLoading={props.loading} />
      <InspectorOrderTabSelection
        inProgressOrders={props.inProgressOrders}
        enabledFeatures={props.enabledFeatures}
        inspectorOrderTab={props.inspectorOrderTab}
        setSelectedTab={props.setInspectorOrderTab}
      />
      <div className={classes.container}>
        {data.length === 0 && !props.loading && (
          <Typography variant="h6" className={classes.noResultsTitle}>
            {translate('inspector.orders.noResults')}
          </Typography>
        )}
        <List>
          {data.map((inProgressOrder: InProgressOrder) => {
            if (!inProgressOrder.order.linkedData) {
              return null;
            }
            const orderKey = inProgressOrder.order.key;
            return (
              <InspectorOrderCollapsableItem
                key={orderKey}
                order={inProgressOrder.order}
                onClick={() => doSelect(orderKey)}
                inProgressOrder={inProgressOrder}
              />
            );
          })}
        </List>
      </div>
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(InspectorOrderSelection);
