export enum WeighingActionTypes {
  DO_MANUAL_WEIGHING_BY_CONTEXT_ID = '@@weighing/DO_MANUAL_WEIGHING_BY_CONTEXT_ID',
  DO_WEIGHING = '@@weighing/DO_WEIGHING',
  DO_WEIGHING_BY_CONTEXT_ID = '@@weighing/DO_WEIGHING_BY_CONTEXT_ID',
  STATUS_SUCCESS = '@@weighing/STATUS_SUCCESS',
  DESTROY_CONTEXT = '@@weighing/DESTROY_CONTEXT',
  DESTROY_CONTEXT_WITH_ID = '@@weighing/DESTROY_CONTEXT_WITH_ID',
  DESTROY_CONTEXT_SUCCEEDED = '@@weighing/DESTROY_CONTEXT_SUCCEEDED',
  FINISH_CONTEXT = '@@weighing/FINISH_CONTEXT',
  FINISH_CONTEXT_BY_ID = '@@weighing/FINISH_CONTEXT_BY_ID',
  FINISH_WITH_CONTEXT_TARE = '@@weighing/FINISH_WITH_CONTEXT_TARE',
  INACTIVATE_CONTEXT = '@@weighing/INACTIVATE_CONTEXT',
  INACTIVATE_CONTEXT_BY_ID = '@@weighing/INACTIVATE_CONTEXT_BY_ID',
  INACTIVATE_CONTEXT_SUCCEEDED = '@@weighing/INACTIVATE_CONTEXT_SUCCEEDED',
  INACTIVATE_CONTEXT_FAILED = '@@weighing/INACTIVATE_CONTEXT_FAILED',
  WEIGHING_SUCCESS = '@@weighing/WEIGHING_SUCCESS',
  CONFIRM_WEIGHING = '@@weighing/CONFIRM_WEIGHING',
  REQUEST_ZERO = '@@weighing/REQUEST_ZERO',
  DISCARD_PREVIOUS_LOAD = '@@weighing/DISCARD_PREVIOUS_LOAD',
  DISCARD_PREVIOUS_LOAD_BY_CONTEXT_ID = '@@weighing/DISCARD_PREVIOUS_LOAD_BY_CONTEXT_ID',
  SAVE_TARE_AND_FINISH_CONTEXT = '@@weighing/SAVE_TARE_AND_FINISH_CONTEXT',
  SET_OPERATOR_FINISHING_ORDER_KEY = '@@weighing/SET_OPERATOR_FINISHING_ORDER_KEY',
  SET_OPERATOR_TRAILER_KEY = '@@weighing/SET_OPERATOR_TRAILER_KEY',
}

export interface Status {
  readonly canSum: boolean;
  readonly isStable: boolean;
  readonly massKg: number;
}

export interface StatusMap {
  [key: string]: Status;
}

export interface WeighingState {
  readonly status: StatusMap;
  readonly inProgress: boolean;
  readonly latestStatusTime: number;
  readonly operatorFinishingOrderKey: string;
  readonly operatorTrailerKey?: string;
}

export enum FormatUnit {
  WITHOUT_UNIT = 'WITHOUT_UNIT',
  WITH_UNIT = 'WITH_UNIT',
}

const undefinedMassKg = -2147483648;
export { undefinedMassKg };
