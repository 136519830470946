import React from 'react';
import { RouteComponentProps } from 'react-router';

import { makeStyles } from '@mui/styles';
import { Tab, Tabs } from '@mui/material';

import { connect, localeConnect } from 'localeConnect';
import { ApplicationState, ConnectedReduxProps } from 'store';

import { Pagination } from 'store/common/types';

import { listOrders } from 'store/orders/actions';
import { ListOrderQueryParams, ListOrderQueryMode } from 'store/orders/types';

import theme from 'theme';
import { translate } from 'utils/translate';
import OperatorContainer from './OperatorContainer';
import OperatorQueryOrders from './OperatorQueryOrders';

const useStyles = makeStyles({
  tabs: {
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiTab-wrapper': {
      textTransform: 'none',
      fontSize: '1.1rem',
    },
  },
});

interface PropsFromState {
  scaleKey: string;
  queryParams: ListOrderQueryParams;
}

interface PropsFromDispatch {
  listOrders: typeof listOrders;
}

/**
 * NOTE(mikkogy, 20200508) There's a bug in Eslint config that has been reported but not yet fixed
 * which complains about missing indentation when multiline binary expressions are used.
 * See: https://github.com/typescript-eslint/typescript-eslint/issues/398
 */
/*eslint-disable */
export type AllProps = PropsFromDispatch &
  PropsFromState &
  RouteComponentProps<{}> &
  ConnectedReduxProps;
/*eslint-enable */

const mapStateToProps = (state: ApplicationState) => ({
  queryParams: state.orders.operatorQueryParams,
});

const mapDispatchToProps = {
  listOrders: listOrders,
};

const tabs = [
  {
    id: 'all',
    status: ListOrderQueryMode.ALL,
  },
  {
    id: 'open',
    status: ListOrderQueryMode.OPEN_BY_TIME,
  },
  {
    id: 'completed',
    status: ListOrderQueryMode.COMPLETED,
  },
  {
    id: 'discarded',
    status: ListOrderQueryMode.DISCARDED,
  },
];

function tabValueToStatus(value: number) {
  if (value >= tabs.length) {
    return tabs[0].status;
  }
  return tabs[value].status;
}

function statusToTabValue(status: ListOrderQueryMode) {
  for (let i = 0; i < tabs.length; i++) {
    if (tabs[i].status === status) {
      return i;
    }
  }
  return 0;
}

interface HeaderProps {
  queryParams: ListOrderQueryParams;
  listOrders: (pagination: Pagination, status: ListOrderQueryMode) => void;
}

const Header = (props: HeaderProps) => {
  const classes = useStyles();
  return (
    <Tabs
      value={statusToTabValue(props.queryParams.status)}
      onChange={(event: React.SyntheticEvent<Element, Event>, newValue: number) => {
        const params = { ...props.queryParams };
        params.status = tabValueToStatus(newValue);
        params.pagination.pageNumber = 1;
        props.listOrders(params.pagination, params.status);
      }}
      aria-label="operator order tabs"
      className={classes.tabs}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.id}
          label={translate(`operatorOrder.orders.statusOptions.${tab.id}`)}
          id={`operator-query-tab-${tab.id}`}
          aria-controls={`operator-query-tab-${tab.id}`}
        />
      ))}
    </Tabs>
  );
};

class OperatorOrdersWrapper extends React.PureComponent<AllProps, Record<string, never>> {
  public constructor(props: AllProps) {
    super(props);
  }

  public render() {
    return (
      <OperatorContainer
        title={translate('operatorOrder.orders.title')}
        title2={translate('operatorOrder.orders.title2')}
        header={<Header queryParams={this.props.queryParams} listOrders={this.props.listOrders} />}
      >
        <OperatorQueryOrders />
      </OperatorContainer>
    );
  }
}

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(OperatorOrdersWrapper);
