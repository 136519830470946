import React, { useEffect } from 'react';
import { Box, Collapse, Grid, Typography } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';

import { vehicleIconContainerMargins, vehicleIconStyle } from 'theme';
import { translate } from 'utils/translate';
import {
  resetTruckAndTrailerAnimations,
  HighlightableTruck,
  HighlightableTrailer,
} from '../CustomIcons';

const useStyles = makeStyles({
  vehicleContainer: {
    marginBottom: '10px',
  },
  vehicleIconContainer: {
    minWidth: '330px',
    minHeight: '68px',
    width: '100%',
    height: '100%',
    ...vehicleIconContainerMargins,
  },
  vehicleIcon: vehicleIconStyle,
});

interface SelectedVehicleProps {
  isTrailerInUse: boolean;
  isCollapsable: boolean;
  isCollapsed: boolean;
  vehicleName: string | undefined;
  isHighlightTruck: boolean;
  isHighlightTrailer: boolean;
}
const SelectedVehicle = (props: SelectedVehicleProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(!props.isCollapsed);

  // start animations over when either of highlights changes
  useEffect(() => {
    resetTruckAndTrailerAnimations();
  }, [props.isHighlightTruck, props.isHighlightTrailer]);

  const handleExpandToggle = () => {
    if (!props.isCollapsable) return;
    setExpanded(!expanded);
  };
  const isTrailerInUse = props.isTrailerInUse;

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={11} className={classes.vehicleContainer} onClick={handleExpandToggle}>
        <Typography variant="h6">{translate('operatorOrder.orderDetails.vehicle')}</Typography>
      </Grid>
      <Grid item xs={1} className={classes.vehicleContainer} onClick={handleExpandToggle}>
        {props.isCollapsable ? expanded ? <ExpandLess /> : <ExpandMore /> : <Box />}
      </Grid>
      <Grid item xs={11}>
        <Collapse
          in={expanded}
          timeout="auto"
          unmountOnExit
          className={classes.vehicleIconContainer}
        >
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={6}>
              <Box className={classes.vehicleIcon}>
                <HighlightableTruck
                  vehicleName={props.vehicleName}
                  isHighlighted={props.isHighlightTruck}
                />
              </Box>
            </Grid>
            {isTrailerInUse ? (
              <Grid item xs={6}>
                <Box className={classes.vehicleIcon}>
                  <HighlightableTrailer isHighlighted={props.isHighlightTrailer} />
                </Box>
              </Grid>
            ) : (
              <Box />
            )}
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default SelectedVehicle;
