import React, { ChangeEvent } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { RouterState } from 'connected-react-router';
import { useTranslation } from 'react-i18next';

import { styled, makeStyles } from '@mui/styles';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  FilledInput,
  Input,
  Button,
  FormHelperText,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { connect, localeConnect } from '../localeConnect';
import { SupportedLanguage } from '../models/languages.model';
import { ApplicationState, ConnectedReduxProps } from '../store';
import { selectLocale, loginRequest } from '../store/user/actions';
import { UiRoles, UserState } from '../store/user/types';
import { mobileContentMaxWidth, paperRoot } from '../theme';

import { BridgeEnabledFeatures } from '../store/scale-info/types';
import { effectiveUiRole } from '../store/utils';
import { RoutePaths } from '../routes';
import { SitesState } from '../store/sites/types';
import { translate } from '../utils/translate';

const LoginButton = styled(Button)({
  marginTop: '30px',
});

const useStyles = makeStyles({
  root: paperRoot,
  container: {
    padding: '30px',
    textAlign: 'left',
    maxWidth: mobileContentMaxWidth,
    margin: 'auto',
  },
  subtitle: {
    fontWeight: 300,
  },
  passwordField: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  header: {
    marginBottom: '60px',
  },
  changeLanguageButtonContainer: {
    marginTop: '50px',
  },
});

// Separate state props + dispatch props to their own interfaces.
interface PropsFromState extends UserState {
  router: RouterState;
  i18n: any;
  enabledFeatures: BridgeEnabledFeatures;
  sites: SitesState;
}

// We can use `typeof` here to map our dispatch types to the props, like so.
interface PropsFromDispatch {
  selectLocale: typeof selectLocale;
  loginRequest: typeof loginRequest;
}

// Combine both state + dispatch props - as well as any props we want to pass - in a union type.
/*eslint-disable */
type AllProps = PropsFromDispatch &
  PropsFromState &
  RouteComponentProps<Record<string, never>> &
  ConnectedReduxProps;
/*eslint-enable */

// It's usually good practice to only include one context at a time in a connected component.
// Although if necessary, you can always include multiple contexts. Just make sure to
// separate them from each other to prevent prop conflicts.
const mapStateToProps = ({ user, currentScaleInfo, sites }: ApplicationState) => ({
  loading: user.loading,
  errors: user.errors,
  selectedUiRole: user.selectedUiRole,
  user: user.user,
  enabledFeatures: currentScaleInfo.enabledFeatures,
  sites: sites,
});

// mapDispatchToProps is especially useful for constraining our actions to the connected component.
// You can access these via `this.props`.
const mapDispatchToProps = {
  selectLocale,
  loginRequest,
};

const Login = (props: AllProps) => {
  const [localState, setValue] = React.useState({
    username: '',
    password: '',
    showPassword: false,
  });

  const { t } = useTranslation();

  const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newState = {
      ...localState,
      [event.currentTarget.name]: event.currentTarget.value,
    };
    setValue(newState);
  };
  const handleClickShowPassword = () => {
    setValue({ ...localState, showPassword: !localState.showPassword });
  };
  const handleLanguageChange = (lang: string) => {
    props.selectLocale(lang);
  };

  const classes = useStyles();

  if (props.user.isAuthed) {
    console.warn('Already logged in.');
    if (!props.sites.selectedKey) {
      return <Redirect to={RoutePaths.SITES} />;
    }
    if (
      effectiveUiRole(props.selectedUiRole, props.user, props.enabledFeatures) === UiRoles.OPERATOR
    ) {
      return <Redirect to={RoutePaths.DASHBOARD} />;
    }
    return <Redirect to={RoutePaths.ORDERS} />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <img alt="Tamtron" src="/static/images/tamtron-logo.png" />
        <h1 className={classes.header}>
          {t('welcomeTitle')}, <br />
          <span className={classes.subtitle}>{t('welcomeSubtitle')}</span>
        </h1>
        <p />
        <div className="login-container">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              props.loginRequest({
                username: localState.username,
                password: localState.password,
              });
            }}
          >
            <TextField
              fullWidth
              variant="filled"
              label={t('inputLabel.username')}
              name="username"
              value={localState.username}
              onChange={onChange}
            >
              <Input type="text" name="username" required autoComplete="off" />
            </TextField>
            <FormControl fullWidth variant="filled" className={classes.passwordField}>
              <InputLabel htmlFor="password-field">{t('inputLabel.password')}</InputLabel>
              <FilledInput
                id="password-field"
                name="password"
                type={localState.showPassword ? 'text' : 'password'}
                value={localState.password}
                onChange={onChange}
                required
                autoComplete="off"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {localState.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            {props.errors && (
              <FormHelperText component="div" error={true}>
                {t('loginError.prefix')} {t(`loginError.${props.errors}`)}
              </FormHelperText>
            )}

            <LoginButton variant="contained" color="primary" type="submit" disabled={props.loading}>
              {t('buttonLabel.login')}
            </LoginButton>
          </form>
        </div>
        <div className={classes.changeLanguageButtonContainer}>
          {Object.values(SupportedLanguage).map((language) => (
            <Button key={language} onClick={(e) => handleLanguageChange(language)}>
              {translate('languageSelection', { lng: language })}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(mapStateToProps, mapDispatchToProps)(Login);
