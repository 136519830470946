export interface UserDetails {
  username: string;
  domainData: any;
  contactCard: {
    familyName: string;
    givenName: string;
    org: {
      organizationName: string;
    };
  };
  locale: string;
  key: string;
  organization: string;
  organizationRoleRights: { [s: string]: boolean };
}

export interface UserDomain {
  docType: string;
  id: string;
  key: string;
  name: string;
}

export interface OrganizationDetails {
  defaultLocale: string;
  domainData: any;
  key: string;
}

// Outgoing login (form) data
export interface LoginData {
  username: string;
  password: string;
}

// User data fetched from Tamtron cloud
export interface User {
  domains: UserDomain[];
  username?: string;
  token?: string;
  isAuthed: boolean;
  organizationData?: OrganizationDetails;
  userData?: UserDetails;
  loginData?: LoginData;
}

export enum UserActionTypes {
  APPLIED_LOCALE = '@@user/APPLIED_LOCALE',
  APPLY_LOCALE = '@@user/APPLY_LOCALE',
  DESTROY_TOKEN = '@@user/DESTROY_TOKEN',
  LOGIN_REQUEST = '@@user/LOGIN_REQUEST',
  LOGIN_SUCCESS = '@@user/LOGIN_SUCCESS',
  LOGOUT_REQUEST = '@@user/LOGOUT_REQUEST',
  LOGOUT = '@@user/LOGOUT',
  FETCH_REQUEST = '@@user/FETCH_REQUEST',
  FETCH_SUCCESS = '@@user/FETCH_SUCCESS',
  FETCH_ERROR = '@@user/FETCH_ERROR',
  SELECT_LOCALE = '@@user/SELECT_LOCALE',
  STORE_SELECTED_LOCALE = '@@user/STORE_SELECTED_LOCALE',
  STORE_USER_DOMAINS = '@@user/STORE_USER_DOMAINS',
  TOKEN_RENEW_SUCCESS = '@@user/TOKEN_RENEW_SUCCESS',
}

export interface UserState {
  readonly isLocaleApplied: boolean;
  readonly loading: boolean;
  readonly user: User;
  readonly errors?: string;
  readonly selectedLocale: string;
  // NOTE(mikkogy,20210114) selection does not mean the role is acceptable for
  // the user. Please use effectiveUiRole in ../utils.ts to find out runtime
  // role which may change as user role or custom role rights are changed or
  // more commonly when user changes the role in the UI.
  readonly selectedUiRole: UiRoles;
}

export enum UiRoles {
  OPERATOR = 'OPERATOR',
  INSPECTOR = 'INSPECTOR',
  DRIVER = 'DRIVER',
}

export enum UiRoleActionTypes {
  ROLE_CHANGED = '@@userrole/ROLE_CHANGED',
}
