import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import { translate } from '../utils/translate';

interface LongTextDialogProps {
  title: string;
  open: boolean;
  onClose: () => void;
  textContent: string;
}

const useStyles = makeStyles({
  longTextContent: {
    overflowWrap: 'break-word',
    whiteSpace: 'pre-line',
  },
});

const LongTextDialog = (props: LongTextDialogProps) => {
  const classes = useStyles();
  const { title, textContent, onClose, open } = props;
  const isMobile = window.innerWidth <= 500;

  return (
    <Dialog
      fullWidth={true}
      onClose={onClose}
      open={open}
      scroll="paper"
      aria-labelledby="simple-dialog-title"
      maxWidth={isMobile ? 'lg' : 'sm'}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.longTextContent}>
        <DialogContentText>{textContent}</DialogContentText>
        <Grid container justifyContent="flex-end">
          <Button onClick={onClose} color="primary">
            {translate('longTextDialog.close')}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default LongTextDialog;
