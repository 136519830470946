import React from 'react';
import { Redirect } from 'react-router';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';

import { connect, localeConnect } from 'localeConnect';

import { OrderDetailsModel } from 'models/orderdetails.model';

import { ApplicationState } from 'store';
import {
  setContextComment,
  setContextCommentImmediately,
  setJobDirection,
} from 'store/orders/actions';
import {
  getContextMainJob,
  getNormalWeighingMainComponents,
  getContextMultipartJob,
} from 'store/orders/utils';
import { DetailedMasterDataType } from 'store/common/types';
import { Context, PendingMasterDataUpdates, WeighingProcess } from 'store/orders/types';
import { Job, JobDirection } from 'store/jobs/types';
import { mobileContentMaxWidth } from 'theme';
import { translate } from 'utils/translate';
import { RoutePaths } from 'routes';
import JobDirectionSelect from './JobDirectionSelect';
import OrderEditorSplits from './OrderEditorSplits';
import OrderMasterDataSearch from './OrderMasterDataSearch';

const useStyles = makeStyles({
  root: {
    textAlign: 'left',
    maxWidth: mobileContentMaxWidth,
    margin: 'auto',
  },
  commentField: {
    // eslint-disable-next-line
    width: '100%',
    // eslint-disable-next-line
    marginTop: '20px',
    // eslint-disable-next-line
    border: 'solid 1px rgba(0, 0, 0, 0.32)',
    // eslint-disable-next-line
    borderRadius: '5px',
    // eslint-disable-next-line
    padding: '16px',
    '& .MuiInput-underline:before': {
      borderBottom: 0,
    },
  },
  containerData: {
    marginBottom: '20px',
  },
});

interface ParameterProps {
  currentContext: Context;
  orderDetailsModel: OrderDetailsModel;
}

interface PropsFromState {
  editedMasterDataType?: DetailedMasterDataType;
  multipartEditOrderComponentId: string;
  pendingMasterDataUpdates: PendingMasterDataUpdates;
}

interface PropsFromDispatch {
  setContextComment: typeof setContextComment;
  setContextCommentImmediately: typeof setContextCommentImmediately;
  setJobDirection: typeof setJobDirection;
}

const mapStateToProps = (state: ApplicationState) => ({
  editedMasterDataType: state.orders.editedMasterDataType,
  multipartEditOrderComponentId: state.orders.multipartEditOrderComponentId,
  pendingMasterDataUpdates: state.orders.pendingMasterDataUpdates,
});

const mapDispatchToProps = {
  setContextComment,
  setContextCommentImmediately,
  setJobDirection,
};

type AllProps = ParameterProps & PropsFromDispatch & PropsFromState;

const OrderEditor = (props: AllProps) => {
  const classes = useStyles();

  let possibleJob: Job | undefined = getContextMainJob(props.currentContext);
  if (props.currentContext.process === WeighingProcess.MULTIPART) {
    possibleJob = getContextMultipartJob(props.currentContext, props.multipartEditOrderComponentId);
  }
  if (possibleJob === undefined) {
    return <Redirect to={RoutePaths.ORDERS} />;
  }
  const job = possibleJob;

  const jobDirectionChanged = (componentId: string, direction: JobDirection) => {
    if (!props.currentContext.order) {
      return;
    }
    props.setJobDirection(props.currentContext.order.key, componentId, direction);
  };

  const commentBlurred = (event: any) => {
    const comment = event.target.value;
    props.setContextCommentImmediately(props.currentContext.contextId, comment, job.key);
  };

  const commentChanged = (event: any) => {
    const comment = event.target.value;
    props.setContextComment(props.currentContext.contextId, comment, job.key);
  };

  const truckComponent = getNormalWeighingMainComponents(props.currentContext.order).truck;

  const typeListAndComment = () => {
    return (
      <div>
        <OrderEditorSplits
          currentContext={props.currentContext}
          orderDetailsModel={props.orderDetailsModel}
        />
        <JobDirectionSelect
          handleDirectionChange={(direction) => {
            jobDirectionChanged(truckComponent.id, direction);
          }}
          disabled={props.orderDetailsModel.isOrderUpdateDisabled}
          value={truckComponent.jobDirection ?? JobDirection.UNDEFINED}
          isMultipart={!!props.multipartEditOrderComponentId}
        />
        <TextField
          variant="standard"
          className={classes.commentField}
          defaultValue={job.comment}
          onBlur={commentBlurred}
          onChange={commentChanged}
          placeholder={translate('orders.editNotePlaceholder')}
        />
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {props.editedMasterDataType && <OrderMasterDataSearch />}
      {!props.editedMasterDataType && typeListAndComment()}
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(OrderEditor);
