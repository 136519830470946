import React from 'react';

import SettingsDialog from 'components/SettingsDialog';
import { SettingsButton } from 'components/NavDrawerContent';

interface ParameterProps {
  iconVisible: boolean;
  onOpen: (() => void) | undefined;
  onClose: (() => void) | undefined;
}

const SettingsDialogButton = (props: ParameterProps) => {
  const [areSettingsOpen, setAreSettingsOpen] = React.useState(false);

  return (
    <>
      <SettingsDialog
        onClose={() => {
          setAreSettingsOpen(false);
          if (props.onClose !== undefined) {
            props.onClose();
          }
        }}
        visible={areSettingsOpen}
      />
      <SettingsButton
        iconVisible={props.iconVisible}
        onClick={() => {
          setAreSettingsOpen(true);
          if (props.onOpen !== undefined) {
            props.onOpen();
          }
        }}
      />
    </>
  );
};

export default SettingsDialogButton;
