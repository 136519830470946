import { action } from 'typesafe-actions';
import { UiRoleActionTypes, UiRoles, UserActionTypes, User, LoginData, UserDomain } from './types';

export const fetchRequest = () => action(UserActionTypes.FETCH_REQUEST);
export const loginRequest = (loginData: LoginData) =>
  action(UserActionTypes.LOGIN_REQUEST, loginData);
export const logoutRequest = () => action(UserActionTypes.LOGOUT_REQUEST);
export const logout = () => action(UserActionTypes.LOGOUT);

export const loginSuccess = (user: User) => action(UserActionTypes.LOGIN_SUCCESS, user);
export const fetchSuccess = (user: User) => action(UserActionTypes.FETCH_SUCCESS, user);
export const fetchError = (message: string) => action(UserActionTypes.FETCH_ERROR, message);
export const tokenRenewSuccess = (token: string) =>
  action(UserActionTypes.TOKEN_RENEW_SUCCESS, token);
export const destroyToken = () => action(UserActionTypes.DESTROY_TOKEN);

export const roleChanged = (role: UiRoles) => action(UiRoleActionTypes.ROLE_CHANGED, role);

export const applyLocale = (locale: string) => action(UserActionTypes.APPLY_LOCALE, locale);
export const appliedLocale = () => action(UserActionTypes.APPLIED_LOCALE);
export const selectLocale = (locale: string) => action(UserActionTypes.SELECT_LOCALE, locale);
export const storeSelectedLocale = (locale: string) =>
  action(UserActionTypes.STORE_SELECTED_LOCALE, locale);
export const storeUserDomains = (domains: UserDomain[]) =>
  action(UserActionTypes.STORE_USER_DOMAINS, domains);
