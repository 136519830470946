import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';

import { Container } from '@mui/material';

import { connect, localeConnect } from 'localeConnect';
import { defaultRoot } from 'theme';
import { ApplicationState, ConnectedReduxProps } from 'store';
import {
  createContext,
  selectMeasDeviceGroup,
  requestMasterDataUpdate,
  setMultipartEditComponentId,
  setPendingMasterDataUpdate,
  searchOrder,
} from 'store/orders/actions';
import {
  confirmWeighing,
  destroyContext,
  finishWithContextTare,
  saveTareAndFinishContext,
} from 'store/weighing/actions';
import { Context, OrderSearch } from 'store/orders/types';
import {
  getContextMainJob,
  getNormalWeighingMainComponents,
  MainJobMultipartBehavior,
} from 'store/orders/utils';
import { BridgeEnabledFeatures } from 'store/scale-info/types';
import { UserSpecificSettings } from 'store/settings/types';
import { UserDetails } from 'store/user/types';
import { getHasOrganizationRoleRight } from 'store/user/utils';
import { getCurrentUserSettings, getSelectedVehicle } from 'store/selectors';
import VehicleModel from 'models/vehicle.model';
import { RoutePaths } from 'routes';
import { scrollToTop } from 'components/utils';

import LoadingIndicator from 'components/LoadingIndicator';
import { narrowContainerStyle } from '../utils';

import { findContext } from './utils';
import DriverOrderDetails from './DriverOrderDetails';
import DriverOrderNavBar from './DriverOrderNavBar';
import SelectOrder from './SelectOrder';

// Separate state props + dispatch props to their own interfaces.
interface PropsFromState {
  currentScaleKey: string;
  currentScaleName: string;
  enabledFeatures: BridgeEnabledFeatures;
  loading: boolean;
  orderSearch: OrderSearch;
  selectedVehicle?: VehicleModel;
  trailerKey: string | undefined;
  user?: UserDetails;
  userSettings: UserSpecificSettings;
  weighingContext: Context | undefined;
}

// We can use `typeof` here to map our dispatch types to the props, like so.
interface PropsFromDispatch {
  confirmWeighing: typeof confirmWeighing;
  finishWithContextTare: typeof finishWithContextTare;
  createContext: typeof createContext;
  destroyContext: typeof destroyContext;
  selectMeasDeviceGroup: typeof selectMeasDeviceGroup;
  requestMasterDataUpdate: typeof requestMasterDataUpdate;
  saveTareAndFinishContext: typeof saveTareAndFinishContext;
  setMultipartEditComponentId: typeof setMultipartEditComponentId;
  setPendingMasterDataUpdate: typeof setPendingMasterDataUpdate;
  searchOrder: typeof searchOrder;
}

/**
 * NOTE(anttipalola, 20190611) There's a bug in Eslint config that has been reported but not yet fixed
 * which complains about missing indentation when multiline binary expressions are used.
 * See: https://github.com/typescript-eslint/typescript-eslint/issues/398
 */
/*eslint-disable */
export type AllProps = PropsFromDispatch &
  PropsFromState &
  RouteComponentProps<{}> &
  ConnectedReduxProps;
/*eslint-enable */

function getVehicle(state: ApplicationState) {
  const vehicle = getSelectedVehicle(state);
  if (vehicle == null) {
    console.log('No vehicle set');
    return undefined;
  }
  return VehicleModel.vehicleModelFromVehicle(vehicle);
}

function getCurrentContextInfo(state: ApplicationState) {
  const weighingContext = findContext(state);
  if (weighingContext) {
    return { weighingContext };
  }
  return {};
}

const mapStateToProps = (state: ApplicationState) => ({
  currentScaleKey: state.currentScaleInfo.scaleKey,
  currentScaleName: state.currentScaleInfo.scaleName,
  enabledFeatures: state.currentScaleInfo.enabledFeatures,
  pendingMasterDataUpdates: state.orders.pendingMasterDataUpdates,
  loading: state.orders.loading,
  errors: state.orders.errors,
  orderSearch: state.orders.search,
  selectedVehicle: getVehicle(state),
  trailerKey: state.vehicles.trailerKey,
  user: state.user.user.userData,
  userSettings: getCurrentUserSettings(state),
  ...getCurrentContextInfo(state),
});

// mapDispatchToProps is especially useful for constraining our actions to the connected component.
// You can access these via `this.props`.
const mapDispatchToProps = {
  confirmWeighing,
  finishWithContextTare,
  createContext,
  destroyContext,
  saveTareAndFinishContext,
  selectMeasDeviceGroup,
  setMultipartEditComponentId,
  requestMasterDataUpdate,
  setPendingMasterDataUpdate,
  searchOrder,
};

interface ContentProps {
  currentScaleKey: string;
  currentScaleName: string;
  enabledFeatures: BridgeEnabledFeatures;
  history: any;
  orderSearch: OrderSearch;
  selectedVehicle?: VehicleModel;
  trailerKey: string | undefined;
  userDetails: UserDetails;
  userSettings: UserSpecificSettings;
  weighingContext: Context | undefined;
  confirmWeighing: typeof confirmWeighing;
  createContext: typeof createContext;
  destroyContext: typeof destroyContext;
  finishWithContextTare: typeof finishWithContextTare;
  saveTareAndFinishContext: typeof saveTareAndFinishContext;
  selectMeasDeviceGroup: typeof selectMeasDeviceGroup;
  setMultipartEditComponentId: typeof setMultipartEditComponentId;
  searchOrder: typeof searchOrder;
}

const OrderContent = (props: ContentProps) => {
  const doSearch = props.searchOrder;
  const selectedVehicleKey = props.selectedVehicle?.key;
  const [filterText, setFilterText] = React.useState('');
  const doFiltering = React.useCallback(
    (filterText: string) => {
      if (selectedVehicleKey) {
        setFilterText(filterText);
        doSearch(filterText, selectedVehicleKey);
      }
    },
    [selectedVehicleKey, doSearch],
  );
  React.useEffect(() => {
    doFiltering('');
    // eslint-disable-next-line
  }, []);

  if (!props.selectedVehicle) {
    console.log("Can't select order without vehicle");
    return <Redirect to={RoutePaths.VEHICLES} />;
  }

  const createOrder = () => {
    if (!props.selectedVehicle) {
      console.error('No vehicle');
      return;
    }
    props.createContext(props.selectedVehicle.key, '', props.trailerKey, false);
    scrollToTop();
  };

  if (
    props.weighingContext &&
    props.weighingContext.order &&
    getContextMainJob(props.weighingContext, MainJobMultipartBehavior.FIRST) &&
    props.selectedVehicle
  ) {
    const components = getNormalWeighingMainComponents(props.weighingContext.order);
    const truckLinks = components.truck.dataLinks ?? [];
    if (!truckLinks.includes(props.selectedVehicle.key)) {
      // NOTE(mikkogy,20220706) when selected vehicle is not included in order
      // we can assume truck has been changed in order which means driver can't
      // be weighing the same truck in the real-world unless truck change was a
      // mistake. Even in case of a mistake it's probably better to destroy
      // context and avoid confusion. Timeout is needed to avoid dispatch
      // directly from render which causes an error.
      setTimeout(() => props.destroyContext(), 0);
    }
    const startEditing = (multipartComponentId: string | undefined) => {
      props.setMultipartEditComponentId(multipartComponentId ?? '');
      props.history.push(RoutePaths.EDIT_ORDER);
    };
    const finishWithContextTare = () => {
      if (!props.weighingContext) {
        throw new Error('context must exist');
      }
      props.finishWithContextTare(props.weighingContext.contextId);
    };
    const saveTareAndFinish = () => {
      if (!props.weighingContext) {
        throw new Error('context must exist');
      }
      props.saveTareAndFinishContext(props.weighingContext.contextId);
    };
    return (
      <DriverOrderDetails
        confirmWeighing={props.confirmWeighing}
        context={props.weighingContext}
        editOrder={startEditing}
        enabledFeatures={props.enabledFeatures}
        finishWithContextTare={finishWithContextTare}
        saveTareAndFinish={saveTareAndFinish}
        scaleKey={props.currentScaleKey}
        selectMeasDeviceGroup={props.selectMeasDeviceGroup}
        userSettings={props.userSettings}
      />
    );
  } else {
    const isCreatingOrderEnabled = getHasOrganizationRoleRight(props.userDetails, 'createOrder');
    const selectOrder = (orderKey: string) => {
      if (!props.selectedVehicle) return;
      const result = props.createContext(
        props.selectedVehicle.key,
        orderKey,
        props.trailerKey,
        false,
      );
      scrollToTop();
      return result;
    };
    return (
      <SelectOrder
        createOrder={createOrder}
        orderSearch={props.orderSearch}
        isCreatingOrderEnabled={isCreatingOrderEnabled}
        filterText={filterText}
        handleFiltering={doFiltering}
        selectOrder={selectOrder}
        scaleName={props.currentScaleName}
      />
    );
  }
};

export class OrdersComponent extends React.PureComponent<AllProps, Record<string, never>> {
  componentDidMount(): void {
    scrollToTop();
  }

  public render() {
    if (!this.props.user) {
      console.warn('Trying to render DriverOrders without user. Redirecting to login.');
      return <Redirect to={RoutePaths.LOGIN} />;
    }
    const orderContentProps: ContentProps = {
      weighingContext: this.props.weighingContext,
      currentScaleKey: this.props.currentScaleKey,
      currentScaleName: this.props.currentScaleName,
      enabledFeatures: this.props.enabledFeatures,
      history: this.props.history,
      orderSearch: this.props.orderSearch,
      selectedVehicle: this.props.selectedVehicle,
      trailerKey: this.props.trailerKey,
      userDetails: this.props.user,
      userSettings: this.props.userSettings,
      confirmWeighing: this.props.confirmWeighing,
      createContext: this.props.createContext,
      destroyContext: this.props.destroyContext,
      finishWithContextTare: this.props.finishWithContextTare,
      saveTareAndFinishContext: this.props.saveTareAndFinishContext,
      selectMeasDeviceGroup: this.props.selectMeasDeviceGroup,
      setMultipartEditComponentId: this.props.setMultipartEditComponentId,
      searchOrder: this.props.searchOrder,
    };

    return (
      <div style={defaultRoot}>
        {this.props.selectedVehicle == null && <Redirect to={RoutePaths.VEHICLES} />}
        <DriverOrderNavBar
          createContext={() => {
            if (!this.props.selectedVehicle) return;
            this.props.createContext(this.props.selectedVehicle.key, '', undefined, false);
          }}
          history={this.props.history}
          showSiteInfo={!!this.props.weighingContext}
        />
        <Container style={narrowContainerStyle()}>
          <LoadingIndicator isLoading={this.props.loading} />
          <OrderContent {...orderContentProps} />
        </Container>
      </div>
    );
  }
}

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(OrdersComponent);
