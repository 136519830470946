import { all, fork, put, select, takeLatest } from 'redux-saga/effects';
import { incomingEvent } from '../common/actions';
import { LiveDataTypes, RemoteReceiveTypes } from '../common/types';
import { getCurrentWsScaleKey, getVersionMismatchReloadTime } from '../selectors';
import {
  domainInfo,
  scaleInfoSuccess,
  setIsVersionMismatchShown,
  siteInfoSuccess,
  storeVersionMismatchReloadTime,
} from './actions';
import { DomainInfo, SiteInfo, ScaleInfo } from './types';

const refreshIntervalMs = 60 * 1000;

export function isVersionMismatch(
  uiVersion: string,
  bridgeVersion: string,
  compatibleVersionConfig?: string,
) {
  let compatibleVersions = [];
  try {
    if (compatibleVersionConfig) {
      const parsed = JSON.parse(compatibleVersionConfig);
      if (typeof parsed !== 'object' || typeof parsed.length !== 'number') {
        throw new Error(
          `compatible version config "${compatibleVersionConfig}" is not a JSON array`,
        );
      }
      if (typeof parsed === 'object' && parsed.length > 0) {
        compatibleVersions = parsed;
      }
    }
  } catch (e) {
    console.warn('got exception trying to parse compatible bridge versions', e);
  }
  return uiVersion !== bridgeVersion && !compatibleVersions.includes(bridgeVersion);
  return true;
}

function* handleInfoMessages(action: ReturnType<typeof incomingEvent>): any {
  if (action.payload.msgType === RemoteReceiveTypes.DOMAIN_INFO) {
    const response: DomainInfo = action.payload.payload;
    yield put(domainInfo(response));
  } else if (action.payload.msgType === RemoteReceiveTypes.SCALE_INFO) {
    const response: ScaleInfo = action.payload.payload;
    yield put(scaleInfoSuccess(response));
    if (process.env.REACT_APP_RELOAD_ON_VERSION_MISMATCH === 'true') {
      const uiVersion = process.env.REACT_APP_GIT_SHA;
      const compatibleVersionConfig = process.env.REACT_APP_COMPATIBLE_BRIDGE_VERSIONS;
      if (
        uiVersion &&
        isVersionMismatch(uiVersion, response.swVersionHash, compatibleVersionConfig)
      ) {
        console.warn(
          `Version mismatch between Bridge (${response.swVersionHash}) and UI (${uiVersion}).`,
        );
        yield put(setIsVersionMismatchShown(true));
        const reloadTime = yield select(getVersionMismatchReloadTime);
        const currentTime = new Date().getTime();
        if (reloadTime < currentTime - refreshIntervalMs) {
          yield put(storeVersionMismatchReloadTime(currentTime));
          // NOTE(mikkogy,20210308) we need to delay reload to give application
          // time to store timestamp to avoid reload loop.
          console.warn('Forcing reload soon to update UI from server.');
          setTimeout(() => {
            const versionUrl = process.env.REACT_APP_FRONTEND_VERSION_URL;
            if (versionUrl === undefined || versionUrl.length === 0) {
              console.warn(
                `REACT_APP_FRONTEND_VERSION_URL missing. \
This is a configuration error that prevents switching to another version. Reloading instead.`,
              );
              window.location.reload();
              return;
            }
            const version = response.swVersionHash;
            console.warn(`Forcing reload version ${version} now to update UI from server.`);
            const url = versionUrl.replace('UI_VERSION', version);
            window.location.replace(url);
          }, 2000);
        }
      } else {
        // NOTE(mikkogy,20230131) when a matching version is got we can make
        // another version switch possible immediately. This enables using
        // longer refreshIntervalMs to avoid refresh loop but enable working
        // version switch anytime. When user uses an alternative version of
        // bridge the default version is loaded first on refresh and once
        // version mismatch is detected UI changes to the correct alternative
        // version.
        yield put(storeVersionMismatchReloadTime(0));
        yield put(setIsVersionMismatchShown(false));
      }
    } else {
      console.log('Version mismatch check configured off so not checking.');
      yield put(setIsVersionMismatchShown(false));
    }
  } else if (action.payload.msgType === RemoteReceiveTypes.SITE_INFO) {
    const response: SiteInfo = action.payload.payload;
    const scaleKey = yield select(getCurrentWsScaleKey);
    yield put(siteInfoSuccess(scaleKey, response));
  }
}

function* watchInfoMessages() {
  yield takeLatest(LiveDataTypes.INCOMING_EVENT, handleInfoMessages);
}

function* scaleInfoSaga() {
  yield all([fork(watchInfoMessages)]);
}

export default scaleInfoSaga;
