import * as i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import moment from 'moment';

import fiTranslations from './assets/i18n/fi/resource.json';
import enTranslations from './assets/i18n/en/resource.json';
import svTranslations from './assets/i18n/sv/resource.json';
import plTranslations from './assets/i18n/pl/resource.json';
import daTranslations from './assets/i18n/da/resource.json';
import deTranslations from './assets/i18n/de/resource.json';
import csTranslations from './assets/i18n/cs/resource.json';
import { SupportedLanguage } from './models/languages.model';

import 'moment/locale/fi';
import 'moment/locale/sv';
import 'moment/locale/pl';

// NOTE(anttipalola, 20190613) Translations could be imported dynamically from URL
// but use a more simple way of just adding them to bundle.
const resources = {
  fi: { translation: fiTranslations },
  en: { translation: enTranslations },
  sv: { translation: svTranslations },
  pl: { translation: plTranslations },
  da: { translation: daTranslations },
  de: { translation: deTranslations },
  cs: { translation: csTranslations },
};

i18n.use(initReactI18next).init({
  resources,
  lng: SupportedLanguage.en,
  debug: process.env.NODE_ENV === 'development',

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export function setupMoment() {
  moment.updateLocale('en', {
    longDateFormat: {
      LT: 'h:mm A',
      LTS: 'h:mm:ss A',
      L: 'YYYY-MM-DD',
      l: 'YYYY-MM-DD',
      LL: 'MMMM Do YYYY',
      ll: 'MMM D YYYY',
      LLL: 'MMMM Do YYYY LT',
      lll: 'MMM D YYYY LT',
      LLLL: 'dddd, MMMM Do YYYY LT',
      llll: 'ddd, MMM D YYYY LT',
    },
  });
}

export default i18n;
