import { ContainerItem } from '../common/types';

// Response object for getting vehicles
export interface Vehicle extends ContainerItem {}

export enum VehiclesActionTypes {
  FETCH_SUCCESS = '@@vehicles/FETCH_SUCCESS',
  SELECT_VEHICLE = '@@vehicles/SELECT_VEHICLE',
  SELECTED = '@@vehicles/SELECTED',
  CHANGE_VEHICLE = '@@vehicles/CHANGE_VEHICLE',
  UPDATE_SELECTED_VEHICLE = '@@vehicles/UPDATE_SELECTED_VEHICLE',
}

export interface VehiclesState {
  readonly selectedVehicle?: Vehicle;
  readonly trailerKey?: string;
  readonly previousTrailerKey?: string;
}
