import React from 'react';
import { AppBar, Button, CardContent, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';

import { makeStyles } from '@mui/styles';

import { MeasDeviceAvailability, MeasDeviceGroup } from 'store/meas-devices/types';
import theme, { mobileContentMaxWidth, paperRoot } from 'theme';
import { ContainerType } from 'store/common/types';
import { containerToBeWeightedNext } from 'store/meas-devices/utils';
import { translate } from 'utils/translate';
import DeepLinkReturnLink from '../DeepLinkReturnLink';
import SelectedVehicle from '../SelectedVehicle';
import { MeasDeviceGroupComponent } from './MeasDeviceGroup';

interface SelectMeasDeviceProps {
  acceptableGroupSizes: number[];
  selectMeasDeviceGroup: (id: string) => void;
  measDeviceGroups: MeasDeviceGroup[];
  selectedMeasDeviceGroupId?: string;
  startWeighing: () => void;
  abortMeasDeviceSelection(): void;
  isLoading: boolean;
  hasWeighingStartError: boolean;
  isTrailerInUse: boolean;
  vehicleName: string | undefined;
}

const listStyles = makeStyles({
  root: paperRoot,
  container: {
    padding: '30px',
    maxWidth: mobileContentMaxWidth,
    margin: 'auto',
    textAlign: 'left',
  },
  clear: {
    clear: 'both',
  },
  continue: {
    marginTop: '40px',
    float: 'right',
  },
  error: {
    color: theme.palette.error.main,
    marginTop: '15px',
  },
  title: {
    flexGrow: 1,
  },
});

const SelectMeasDevice = (props: SelectMeasDeviceProps) => {
  const classes = listStyles();
  const selectedGroupId = props.selectedMeasDeviceGroupId ?? '';
  const applicableMeasDeviceGroups = props.measDeviceGroups.filter(
    (g) => !g.isManual && !g.isHiddenFromDriverUi,
  );
  const selectedMeasDeviceGroupList = applicableMeasDeviceGroups.filter(
    (g) => g.id === selectedGroupId,
  );
  const selectedMeasDeviceGroup =
    selectedMeasDeviceGroupList.length > 0
      ? selectedMeasDeviceGroupList[0]
      : { availability: MeasDeviceAvailability.InUse };
  const isSelectedGroupDisabled =
    selectedMeasDeviceGroup.availability !== MeasDeviceAvailability.Available;

  const nextContainer = containerToBeWeightedNext(
    props.isTrailerInUse,
    props.acceptableGroupSizes,
    selectedMeasDeviceGroup as MeasDeviceGroup,
  );

  return (
    <div className={classes.root}>
      <AppBar position="relative">
        <DeepLinkReturnLink isContextIgnored={false} />
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label={translate('measDevices.return')}
            edge="start"
            onClick={props.abortMeasDeviceSelection}
          >
            <BackIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            {translate('measDevices.appBarTitle')}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.container}>
        <SelectedVehicle
          isTrailerInUse={props.isTrailerInUse}
          isCollapsed={false}
          isCollapsable={false}
          vehicleName={props.vehicleName}
          isHighlightTruck={
            nextContainer === ContainerType.TRUCK || nextContainer === ContainerType.TRUCK_TRAILER
          }
          isHighlightTrailer={
            nextContainer === ContainerType.TRAILER || nextContainer === ContainerType.TRUCK_TRAILER
          }
        ></SelectedVehicle>
        <h1>{translate('measDevices.title')}</h1>
        <h2>{translate('measDevices.subtitle')}</h2>
        <Grid container spacing={2}>
          {applicableMeasDeviceGroups
            .sort((g1, g2) => g1.name.localeCompare(g2.name))
            .map((measDeviceGroup: MeasDeviceGroup) => (
              <Grid item key={measDeviceGroup.id}>
                <MeasDeviceGroupComponent
                  isSelectable={props.acceptableGroupSizes.includes(
                    measDeviceGroup.measDeviceKeys.length,
                  )}
                  isSelected={props.selectedMeasDeviceGroupId === measDeviceGroup.id}
                  selectMeasDeviceGroup={props.selectMeasDeviceGroup}
                  measDeviceGroup={measDeviceGroup}
                />
              </Grid>
            ))}
        </Grid>
        {applicableMeasDeviceGroups.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            disabled={
              !props.selectedMeasDeviceGroupId || props.isLoading || isSelectedGroupDisabled
            }
            className={classes.continue}
            onClick={props.startWeighing}
          >
            {translate('vehicles.continue')}
          </Button>
        )}
      </div>
      {applicableMeasDeviceGroups.length === 0 && (
        <CardContent>
          <Typography variant="body1">{translate('measDevices.noMeasDevices')}</Typography>
        </CardContent>
      )}
      <div className={classes.clear} />
      {props.hasWeighingStartError && (
        <Typography variant="body1" className={classes.error}>
          {translate('measDevices.weighingStartError')}
        </Typography>
      )}
    </div>
  );
};

export default SelectMeasDevice;
