import { makeStyles } from '@mui/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { translate } from '../utils/translate';

const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    display: 'flex',
    fontWeight: 'normal',
  },
  hintExplanation: {
    fontSize: '0.8rem',
    paddingLeft: '5px',
    textAlign: 'center',
  },
});

interface AllProps {
  hasError: boolean;
}

export const DeductionHint = (props: AllProps) => {
  const classes = useStyles();
  if (!props.hasError) return null;
  const explanation = translate('deductionDetails.fixDeduction');
  return (
    <div className={classes.container}>
      <ErrorOutlineIcon color={'error'} />
      <span className={classes.hintExplanation}>{`${explanation}`}</span>
    </div>
  );
};

export default DeductionHint;
