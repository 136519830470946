import React from 'react';
import { FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { JobDirection } from 'store/jobs/types';
import { translate } from 'utils/translate';

interface Props {
  handleDirectionChange: (direction: JobDirection) => void;
  disabled: boolean;
  value: JobDirection;
  isMultipart: boolean;
}

const useStyles = makeStyles({
  jobDirectionSelect: {
    margin: 0,
    minWidth: '100%',
  },
});

const JobDirectionSelect = (props: Props) => {
  const classes = useStyles();

  const menuItem = (direction: JobDirection) => (
    <MenuItem key={direction} value={direction}>
      {translate(`jobDirection.values.JOB_DIRECTION_ENUM_${direction}`)}
    </MenuItem>
  );

  const jobDirectionTitle = props.isMultipart
    ? translate('jobDirection.multipartTitle')
    : translate('jobDirection.title');

  return (
    <div>
      <FormControl variant="standard" className={classes.jobDirectionSelect}>
        <InputLabel htmlFor="direction-select-label">{jobDirectionTitle}</InputLabel>
        <Select
          variant="standard"
          inputProps={{
            id: 'direction-select-label',
            name: 'direction-simple-select',
          }}
          value={props.value}
          onChange={(event) => props.handleDirectionChange(event.target.value as JobDirection)}
          disabled={props.disabled}
        >
          {Object.keys(JobDirection).map((direction) => menuItem(direction as JobDirection))}
        </Select>
      </FormControl>
    </div>
  );
};

export default JobDirectionSelect;
