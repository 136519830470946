import React from 'react';

import { Button, emphasize } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { MeasDeviceGroup, MeasDeviceAvailability } from 'store/meas-devices/types';
import theme, { dotStatusColors } from 'theme';

interface MeasDeviceProps {
  isSelectable: boolean;
  isSelected: boolean;
  measDeviceGroup: MeasDeviceGroup;
  selectMeasDeviceGroup: (id: string) => void;
}

const useStyles = makeStyles({
  button: (props: MeasDeviceProps) => ({
    // eslint-disable-next-line
    backgroundColor: props.isSelected ? theme.palette.primary.main : undefined,
    // eslint-disable-next-line
    color: props.isSelected ? theme.palette.common.white : undefined,
    '&:hover, &:focus': {
      backgroundColor: props.isSelected ? emphasize(theme.palette.primary.main, 0.2) : undefined,
      color: props.isSelected ? theme.palette.common.white : undefined,
    },
    // eslint-disable-next-line
    minHeight: '74px',
  }),
  vehicleContent: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '18px',
  },
  title: {
    fontWeight: 300,
  },
  subtitle: {
    fontWeight: 200,
  },
  notAvailable: {
    width: '10px',
    height: '10px',
    position: 'absolute',
    top: 4,
    right: 4,
    borderRadius: '5px',
    backgroundColor: dotStatusColors.fail,
  },
  vehicle: {
    position: 'relative',
  },
});

export const MeasDeviceGroupComponent = (props: MeasDeviceProps) => {
  const isSelected = props.isSelected;
  const classes = useStyles(props);
  const isAvailable = props.measDeviceGroup.availability === MeasDeviceAvailability.Available;
  return (
    <div className={classes.vehicle} key={props.measDeviceGroup.id}>
      <Button
        color={isSelected ? 'primary' : 'inherit'}
        variant="outlined"
        className={classes.button}
        disabled={!isAvailable || !props.isSelectable}
        onClick={() => {
          props.selectMeasDeviceGroup(props.measDeviceGroup.id);
        }}
      >
        <div className={classes.vehicleContent}>
          <div className={classes.title}>{props.measDeviceGroup.name}</div>
        </div>
      </Button>
      {!isAvailable && <div className={classes.notAvailable} />}
    </div>
  );
};
