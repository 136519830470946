import React, { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { connect, localeConnect } from 'localeConnect';
import { ApplicationState } from 'store';

import { startLatestJobsPoll, stopLatestJobsPoll } from 'store/jobs/actions';
import { Job } from 'store/jobs/types';
import { showOrderInfo } from 'store/orders/actions';

import { operatorColors } from 'theme';
import { translate } from 'utils/translate';
import { OperatorJobTableColumns, OperatorJobTableStyles } from './OperatorJobTable';
import OperatorTable from './OperatorTable';

const useStyles = makeStyles({
  ...OperatorJobTableStyles,
  title: {
    textAlign: 'left',
  },
  titleCount: {
    color: operatorColors.secondary,
  },
});

interface PropsFromState {
  latestJobs: Job[] | undefined;
}

interface PropsFromDispatch {
  startLatestJobsPoll: typeof startLatestJobsPoll;
  stopLatestJobsPoll: typeof stopLatestJobsPoll;
  showOrderInfo: typeof showOrderInfo;
}

const mapStateToProps = (state: ApplicationState) => ({
  latestJobs: state.jobs.latestJobs ? state.jobs.latestJobs.results : undefined,
});

const mapDispatchToProps = {
  startLatestJobsPoll,
  stopLatestJobsPoll,
  showOrderInfo,
};

type AllProps = PropsFromDispatch & PropsFromState;

const DashboardLatestJobs = (props: AllProps) => {
  const classes = useStyles();
  const [anchorElState, setAnchorElState] = useState<{ [key: string]: Element }>({});
  const isNoteOpen: { [key: string]: boolean } = {};

  const { startLatestJobsPoll, stopLatestJobsPoll } = props;
  useEffect(() => {
    startLatestJobsPoll();
    return () => {
      stopLatestJobsPoll();
    };
  }, [startLatestJobsPoll, stopLatestJobsPoll]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, key: string) => {
    const anchorEl: { [key: string]: any } = {};
    anchorEl[key] = event.currentTarget;
    setAnchorElState(anchorEl);
  };

  const handleClose = () => {
    setAnchorElState({});
  };

  const columnDefinitions = OperatorJobTableColumns(
    anchorElState,
    isNoteOpen,
    handleClick,
    handleClose,
    props.showOrderInfo,
  );
  const columns = [
    columnDefinitions.registerNumber,
    columnDefinitions.material,
    columnDefinitions.customer,
    columnDefinitions.sumMass(classes.mass),
    columnDefinitions.receipt,
    columnDefinitions.timestamp,
    columnDefinitions.menu,
  ];

  return (
    <div>
      <Typography className={classes.title} variant="h5">
        {translate('dashboard.latestJobs.title')}
      </Typography>
      {props.latestJobs && (
        <OperatorTable
          columns={columns}
          data={props.latestJobs}
          keyGetter={(job: Job) => job.key}
        />
      )}
      {!props.latestJobs && <div>{translate('dashboard.latestJobs.notAvailable')}</div>}
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardLatestJobs);
