import { Reducer } from 'redux';
import { Vehicle, VehiclesState, VehiclesActionTypes } from './types';

export const initialState: VehiclesState = {
  selectedVehicle: undefined,
  trailerKey: undefined,
  previousTrailerKey: undefined,
};

const reducer: Reducer<VehiclesState> = (state = initialState, action) => {
  switch (action.type) {
    case VehiclesActionTypes.SELECT_VEHICLE: {
      return { ...state, loading: true };
    }
    case VehiclesActionTypes.SELECTED: {
      return {
        ...state,
        loading: false,
        selectedVehicle: action.payload.vehicle,
        trailerKey: action.payload.trailerKey,
      };
    }
    case VehiclesActionTypes.CHANGE_VEHICLE: {
      return {
        ...state,
        loading: false,
        previousTrailerKey: state.trailerKey,
        selectedVehicle: undefined,
      };
    }
    case VehiclesActionTypes.UPDATE_SELECTED_VEHICLE: {
      const newState = { ...state };
      const masterData: Vehicle[] = action.payload.masterData;
      newState.selectedVehicle = masterData.find((vehicle) => {
        return vehicle.key === newState.selectedVehicle?.key;
      });
      return newState;
    }
    default: {
      return state;
    }
  }
};

export { reducer as vehiclesReducer };
