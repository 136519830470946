import React from 'react';

import { makeStyles } from '@mui/styles';
import {
  FormControl,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { connect, localeConnect } from '../localeConnect';
import { ApplicationState } from '../store';
import { roleChanged } from '../store/user/actions';
import { UiRoles, User } from '../store/user/types';
import { effectiveUiRole, isUiRoleEnabled } from '../store/utils';

import { BridgeEnabledFeatures } from '../store/scale-info/types';
import { translate } from '../utils/translate';

const useStyles = makeStyles({
  roleSelect: {
    margin: 0,
    minWidth: '100%',
  },
});

interface PropsFromState {
  selectedUiRole: UiRoles;
  user: User;
  enabledFeatures: BridgeEnabledFeatures;
  isEnabledFeaturesLoaded: boolean;
}

interface PropsFromDispatch {
  roleChanged: typeof roleChanged;
}

const mapStateToProps = ({ currentScaleInfo, user }: ApplicationState) => ({
  selectedUiRole: user.selectedUiRole,
  user: user.user,
  enabledFeatures: currentScaleInfo.enabledFeatures,
  isEnabledFeaturesLoaded: currentScaleInfo.isEnabledFeaturesLoaded,
});

const mapDispatchToProps = {
  roleChanged,
};

type AllProps = PropsFromDispatch & PropsFromState;

const RoleSelect = (props: AllProps) => {
  const classes = useStyles();
  if (!props.isEnabledFeaturesLoaded || !props.user.isAuthed) {
    return null;
  }
  if (
    !isUiRoleEnabled(props.user, UiRoles.OPERATOR, props.enabledFeatures) &&
    !isUiRoleEnabled(props.user, UiRoles.INSPECTOR, props.enabledFeatures)
  ) {
    return null;
  }

  const handleRoleChange = (event: SelectChangeEvent<UiRoles>) => {
    if (event.target.value !== '') props.roleChanged(event.target.value as UiRoles);
  };

  const menuItem = (role: string) => (
    <MenuItem key={role} value={role}>
      {translate(`uiRoles.roles.${role}`)}
    </MenuItem>
  );

  const effectiveRole = effectiveUiRole(props.selectedUiRole, props.user, props.enabledFeatures);

  return (
    <div>
      <ListItem component={'li'}>
        <FormControl variant="standard" className={classes.roleSelect}>
          <InputLabel htmlFor="role-select-label">{translate('uiRoles.label')}</InputLabel>
          <Select
            variant="standard"
            inputProps={{
              id: 'role-select-label',
              name: 'role-simple-select',
            }}
            value={effectiveRole === props.selectedUiRole ? props.selectedUiRole : ''}
            onChange={handleRoleChange}
          >
            {Object.keys(UiRoles)
              .filter((item) => isNaN(Number(item)))
              .filter((role) => isUiRoleEnabled(props.user, role as UiRoles, props.enabledFeatures))
              .map((role) => menuItem(role))}
          </Select>
        </FormControl>
      </ListItem>
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(mapStateToProps, mapDispatchToProps)(RoleSelect);
