import { BridgeEnabledFeatures } from '../store/scale-info/types';
import MasterDataVisibleFeature from './MasterDataVisibleFeature';

interface Props {
  children: React.ReactNode;
}

export default function MasterDataLocalityVisibleFeature(props: Props) {
  return (
    <MasterDataVisibleFeature
      isFeatureEnabled={(enabledFeatures: BridgeEnabledFeatures) =>
        enabledFeatures.bridgeIsMasterDataLocalityVisible
      }
    >
      {props.children}
    </MasterDataVisibleFeature>
  );
}
