import React from 'react';

import { makeStyles } from '@mui/styles';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';

import { connect, localeConnect } from 'localeConnect';
import { ApplicationState } from 'store';
import {
  getMultipartRounds,
  MULTIPART_INITIAL_ROUND,
  roundKgs,
  roundLoadType,
  roundMaterial,
} from 'models/multipart.model';
import { createContainerModels } from 'models/normalweighingcontainer.model';
import { createOrderDetailsModel } from 'models/orderdetails.model';
import { SplitLoadModel, createSplitLoadModelFromContext } from 'models/splitload.model';

import { ContainerItem } from 'store/common/types';
import { setContextProcess, setDefaultWeighingProcess } from 'store/orders/actions';
import { ComponentGroup, Context, WeighingProcess } from 'store/orders/types';
import { hasContextRequiredMasterData } from 'store/orders/utils';
import {
  BridgeEnabledFeatures,
  OrganizationEnabledFeatures,
  SiteInfo,
} from 'store/scale-info/types';
import { finishContext } from 'store/weighing/actions';

import theme, {
  dotStatusColors,
  fadedGreyColor,
  inspectionStatusColors,
  breakLongText,
  mobileContentMaxWidth,
} from 'theme';
import { isNetNegative, toMultipartVehicleWeighingModels } from 'models/vehicleweighing.model';
import { CacheImage } from 'store/images/types';
import { FormatUnit } from 'store/weighing/types';
import { UserDetails } from 'store/user/types';
import { translate } from 'utils/translate';
import { formatMass } from '../utils';

import CardDetailItem from '../CardDetailItem';
import DeductionInfo from '../DeductionInfo';
import EnableMultipartWhenMultipleSharesDialog from '../EnableMultipartWhenMultipleSharesDialog';
import SecondaryContainerTareFeature from '../SecondaryContainerTareFeature';

import ConnectedPhotos from './ConnectedPhotos';
import NormalOrderInfo from './NormalOrderInfo';

const useStyles = makeStyles({
  root: {
    // eslint-disable-next-line
    textAlign: 'left',
    // eslint-disable-next-line
    maxWidth: mobileContentMaxWidth,
    // eslint-disable-next-line
    margin: 'auto',
    '& .Mui-disabled .MuiAvatar-colorDefault': {
      backgroundColor: 'rgba(0, 0, 0, 0.26)',
    },
  },
  contentSection: {
    marginBottom: '16px',
  },
  comment: {
    // eslint-disable-next-line
    maxWidth: 'calc(100% - 1em)',
    // eslint-disable-next-line
    overflow: 'hidden',
    // eslint-disable-next-line
    position: 'relative',
    // eslint-disable-next-line
    lineHeight: '1.15em',
    // eslint-disable-next-line
    maxHeight: '2.3em',
    // eslint-disable-next-line
    textAlign: 'justify',
    // eslint-disable-next-line
    marginRight: '-1em',
    // eslint-disable-next-line
    paddingRight: '1em',
    // eslint-disable-next-line
    marginBottom: '0.5em',
    '&&:before': {
      content: '"..."',
      position: 'absolute',
      right: 0,
      bottom: 0,
    },
    '&&:after': {
      content: '""',
      position: 'absolute',
      right: 0,
      width: '1em',
      height: '1em',
      marginTop: '0.2em',
      background: theme.palette.common.white,
    },
  },
  containerTypeContainer: {
    // eslint-disable-next-line
    paddingBottom: '10px',
    '& > div:not(:last-child)': {
      marginBottom: '1px',
    },
  },
  cardData: {
    margin: '20px',
    overflowWrap: 'break-word',
    wordBreak: 'normal',
  },
  cardHeader: {
    fontWeight: 500,
    ...breakLongText,
  },
  containerTitle: {
    fontSize: '20px',
    fontWeight: 600,
    marginBottom: '20px',
    ...breakLongText,
  },
  deduction: {
    color: theme.palette.error.main,
  },
  deductionsContainerErrors: {
    borderLeft: `5px solid ${inspectionStatusColors.fail}`,
  },
  deductionContainer: {
    padding: '16px 0 16px 0',
  },
  deductionInfo: {
    textAlign: 'left',
  },
  editButton: {
    color: 'black',
  },
  finishContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  multipartSwitch: {
    transform: 'translate(0px, -2px)',
  },
  roundCard: {
    marginBottom: '20px',
    paddingBottom: '20px',
  },
  roundCount: {
    color: '#8d8d8d',
  },
});

const multipartStyles = makeStyles({
  activeContainer: {
    textAlign: 'right',
    fontSize: '1.1rem',
    fontWeight: 400,
    color: fadedGreyColor,
  },
  activeDot: {
    width: '16px',
    height: '16px',
    backgroundColor: dotStatusColors.inProgress,
    borderRadius: '8px',
    display: 'inline-block',
    transform: 'translateY(3px)',
    marginLeft: '30px',
    marginRight: '10px',
  },
  evenItem: {
    '&:not(:first-child)': {
      paddingLeft: '20px',
    },
    // eslint-disable-next-line
    flexGrow: 1,
  },
  multipart: {
    '& .MuiCardHeader-content': {
      borderBottom: '1px solid #c7c7c7',
    },
    '& .MuiCardHeader-action': {
      marginTop: '-9px',
      marginRight: '0px',
      borderBottom: '1px solid #c7c7c7',
    },
    '& .MuiTypography-body1': {
      fontSize: '1.2rem',
    },
  },
  typeInfo: {
    color: '#595959',
    textTransform: 'uppercase',
  },
});

type EditDeductionsCallback = (container: ContainerItem) => void;

interface ParameterProps {
  context: Context;
  editDeductions: EditDeductionsCallback;
  editOrder: (multipartComponentId: string | undefined) => void;
  isEditingDeductionsSupported: boolean;
  isRouteShown: boolean;
}

interface PropsFromState {
  enabledFeatures: BridgeEnabledFeatures;
  organizationEnabledFeatures: OrganizationEnabledFeatures;
  scaleKey: string;
  scaleName: string;
  siteInfo?: SiteInfo;
  userDetails: UserDetails | undefined;
}

interface PropsFromDispatch {
  finishContext: typeof finishContext;
  setContextProcess: typeof setContextProcess;
  setDefaultWeighingProcess: typeof setDefaultWeighingProcess;
}

export type AllProps = PropsFromDispatch & PropsFromState & ParameterProps;

const mapStateToProps = (state: ApplicationState) => ({
  enabledFeatures: state.currentScaleInfo.enabledFeatures,
  organizationEnabledFeatures: state.currentScaleInfo.organizationEnabledFeatures,
  scaleKey: state.currentScaleInfo.scaleKey,
  scaleName: state.currentScaleInfo.scaleName,
  siteInfo: state.currentScaleInfo.siteInfo,
  userDetails: state.user.user.userData,
});

const mapDispatchToProps = {
  finishContext,
  setContextProcess,
  setDefaultWeighingProcess,
};

interface MultipartInfoProps {
  context: Context;
}

const MultipartInfo = (props: MultipartInfoProps) => {
  const classes = useStyles();
  return (
    <Card>
      <CardHeader title={props.context.order.externalId} className={classes.cardHeader} />
    </Card>
  );
};

interface NormalWeighingsProps {
  context: Context;
  enabledFeatures: BridgeEnabledFeatures;
  editDeductions: EditDeductionsCallback;
  isEditingDeductionsSupported: boolean;
  organizationEnabledFeatures: OrganizationEnabledFeatures;
  scaleKey: string;
  userDetails: UserDetails;
}

const NormalWeighings = (props: NormalWeighingsProps) => {
  const classes = useStyles();
  if (!props.context.weighingJobs) return null;

  const containerModels = createContainerModels(
    props.context,
    props.enabledFeatures,
    props.organizationEnabledFeatures,
    props.scaleKey,
    props.userDetails,
  );

  return (
    <Card>
      <CardContent>
        {containerModels.map((model) => {
          const containerTitle = translate(
            `weighing.containerTypes.${model.container.containerType}`,
          );
          const isNetKgsNegative = isNetNegative(model.net);
          return (
            <div key={model.net.key} className={classes.containerTypeContainer}>
              <Typography variant="h5" className={classes.containerTitle}>
                {containerTitle}
              </Typography>
              <Grid container spacing={2} direction={'column'}>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <CardDetailItem
                        title={translate('weighing.incoming')}
                        value={formatMass(model.firstRound.kgs)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CardDetailItem
                        title={translate('weighing.outgoing')}
                        value={formatMass(model.secondRound.kgs)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CardDetailItem
                        title={translate('weighing.net')}
                        value={formatMass(model.net.weighedKgs)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Divider />

              <CardHeader
                className={`${classes.deductionContainer} ${
                  isNetKgsNegative ? classes.deductionsContainerErrors : ''
                }`}
                title={
                  <DeductionInfo
                    deductionClassName={classes.deductionInfo}
                    net={model.net}
                    order={props.context.order}
                    enabledFeatures={props.enabledFeatures}
                    splitLoadModel={model.splitLoadModel}
                  />
                }
                action={
                  props.isEditingDeductionsSupported && (
                    <Button
                      className={classes.editButton}
                      aria-label="Edit"
                      onClick={() => props.editDeductions(model.container)}
                    >
                      <EditIcon />
                    </Button>
                  )
                }
              />
              <Divider />
            </div>
          );
        })}
        <ConnectedPhotos
          context={props.context}
          imageFilter={undefined}
          jobKey={props.context.weighingJobs[0]?.key ?? ''}
        />
      </CardContent>
    </Card>
  );
};

interface MultipartWeighingsProps {
  context: Context;
  editOrder: (componentId: string) => void;
  enabledFeatures: BridgeEnabledFeatures;
  finish: () => void;
}

const InProgressRound = () => {
  const classes = multipartStyles();
  return (
    <span className={classes.activeContainer}>
      <>
        <div className={classes.activeDot}>&nbsp;</div>
        {translate('orders.multipartRoundInProgress')}
      </>
    </span>
  );
};

const MultipartWeighings = (props: MultipartWeighingsProps) => {
  const classes = useStyles();
  const multipartClasses = multipartStyles();
  const contextJobs = props.context.weighingJobs;
  if (!contextJobs) return null;

  const jobs = [...contextJobs].reverse();
  const rounds = getMultipartRounds(jobs, props.context.order, props.enabledFeatures);
  const model = toMultipartVehicleWeighingModels(contextJobs, []);

  const hasRequiredMasterData = hasContextRequiredMasterData(props.context);

  return (
    <div className={multipartClasses.multipart}>
      <Typography variant="h6">
        <>
          {translate('orders.multipartWeighingsTitle')}{' '}
          <span className={classes.roundCount}>({rounds.length})</span>
        </>
      </Typography>
      {rounds.map((round) => (
        <Card className={classes.roundCard} key={round.key}>
          <CardHeader
            title={
              <div>
                <span>
                  {translate('orders.multipartWeighingRoundTitle', {
                    round: round.roundNumber,
                  })}
                </span>
                {round.isActive && round.truckKgs !== undefined ? <InProgressRound /> : undefined}
              </div>
            }
            className={classes.cardHeader}
            action={
              round.componentId && (
                <Button
                  className={classes.editButton}
                  aria-label="Edit"
                  onClick={() => props.editOrder(round.componentId)}
                >
                  <EditIcon />
                </Button>
              )
            }
          />
          <CardContent>
            <Grid container spacing={2} direction={'column'}>
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item className={multipartClasses.evenItem}>
                    <Typography variant={'body1'}>
                      {roundKgs(round, FormatUnit.WITHOUT_UNIT)}
                    </Typography>
                    <div className={multipartClasses.typeInfo}>{roundLoadType(round)}</div>
                  </Grid>
                  <Grid item className={multipartClasses.evenItem}>
                    {round.roundNumber > 1 && (
                      <div>
                        <Typography variant={'body1'}>{roundMaterial(round)}</Typography>
                        <div className={multipartClasses.typeInfo}>
                          {translate('masterData.MATERIAL_TITLE')}
                        </div>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <SecondaryContainerTareFeature
                  componentId={round.componentId}
                  order={props.context.order}
                >
                  <Typography variant={'subtitle1'}>
                    {translate('weighing.secondaryContainerDeduction')}
                  </Typography>
                  <CardDetailItem
                    title={round.secondaryContainerName ?? ''}
                    value={
                      <span className={classes.deduction}>
                        {formatMass(-round.secondaryContainerTareKgs)}
                      </span>
                    }
                  />
                </SecondaryContainerTareFeature>
              </Grid>
            </Grid>
          </CardContent>
          {round.key !== MULTIPART_INITIAL_ROUND && (
            <ConnectedPhotos
              context={props.context}
              imageFilter={(image: CacheImage) =>
                round.key === image.uploadMeta?.jobKey ||
                round.attachments.includes(image.attachmentKey)
              }
              jobKey={round.key}
            />
          )}
        </Card>
      ))}
      <div className={classes.finishContainer}>
        <Button
          disabled={!model.isFinishingEnabled || !hasRequiredMasterData}
          onClick={props.finish}
          color="primary"
          variant="outlined"
        >
          {translate('orders.multipartFinish')}
        </Button>
      </div>
    </div>
  );
};

const CommonOrderDetails = (props: AllProps) => {
  const classes = useStyles();

  const [
    isEnableMultipartWhenMultipleSharesDialogVisible,
    setIsEnableMultipartWhenMultipleSharesDialogVisible,
  ] = React.useState(false);

  if (!props.context.weighingJobs || !props.userDetails) return null;

  const orderDetailsModel = createOrderDetailsModel(
    props.context,
    props.context.order,
    props.context.process,
    props.context.weighingJobs,
    props.scaleKey,
    props.userDetails,
  );

  function setProcess(process: WeighingProcess) {
    props.setContextProcess(props.context.contextId, process);
    props.setDefaultWeighingProcess(process);
  }

  const splitLoadModels: SplitLoadModel[] = [
    createSplitLoadModelFromContext({
      context: props.context,
      componentGroup: ComponentGroup.TRUCK,
      organizationEnabledFeatures: props.organizationEnabledFeatures,
      scaleKey: props.scaleKey,
      userDetails: props.userDetails,
    }),
  ];

  if (orderDetailsModel.isTrailerUsed) {
    splitLoadModels.push(
      createSplitLoadModelFromContext({
        context: props.context,
        componentGroup: ComponentGroup.TRAILER,
        organizationEnabledFeatures: props.organizationEnabledFeatures,
        scaleKey: props.scaleKey,
        userDetails: props.userDetails,
      }),
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.contentSection}>
        {orderDetailsModel.process !== WeighingProcess.MULTIPART && (
          <NormalOrderInfo
            context={props.context}
            editDeductions={(containerType) => props.editDeductions(containerType)}
            editOrder={() => props.editOrder(undefined)}
          />
        )}
        {orderDetailsModel.process === WeighingProcess.MULTIPART && (
          <MultipartInfo context={props.context} />
        )}
      </div>
      <div className={classes.contentSection}>
        <Typography variant="h6" component="span">
          {translate('orderDetails.weighingModeMultipart')}
        </Typography>
        <Switch
          checked={orderDetailsModel.process === WeighingProcess.MULTIPART}
          onChange={(e) => {
            const process = e.target.checked ? WeighingProcess.MULTIPART : WeighingProcess.NORMAL;
            const isSplitEnabled = splitLoadModels.reduce(
              (isEnabled, model) => isEnabled || model.isSplitEnabled,
              false,
            );
            if (process === WeighingProcess.MULTIPART && isSplitEnabled) {
              setIsEnableMultipartWhenMultipleSharesDialogVisible(true);
              return;
            }
            setProcess(process);
          }}
          disabled={orderDetailsModel.isProcessChangeDisabled}
          className={classes.multipartSwitch}
          color="primary"
          name="multipart-switch"
          inputProps={{ 'aria-label': 'multipart switch' }}
        />
      </div>
      <div className={classes.contentSection}>
        {orderDetailsModel.process !== WeighingProcess.MULTIPART && (
          <NormalWeighings
            context={props.context}
            enabledFeatures={props.enabledFeatures}
            editDeductions={props.editDeductions}
            isEditingDeductionsSupported={props.isEditingDeductionsSupported}
            organizationEnabledFeatures={props.organizationEnabledFeatures}
            scaleKey={props.scaleKey}
            userDetails={props.userDetails}
          />
        )}
        {orderDetailsModel.process === WeighingProcess.MULTIPART && (
          <MultipartWeighings
            context={props.context}
            editOrder={(componentId: string) => props.editOrder(componentId)}
            enabledFeatures={props.enabledFeatures}
            finish={() => {
              props.finishContext(false);
            }}
          />
        )}
      </div>
      <EnableMultipartWhenMultipleSharesDialog
        visible={isEnableMultipartWhenMultipleSharesDialogVisible}
        onConfirmed={() => {
          setProcess(WeighingProcess.MULTIPART);
          setIsEnableMultipartWhenMultipleSharesDialogVisible(false);
        }}
        onNotConfirmed={() => setIsEnableMultipartWhenMultipleSharesDialogVisible(false)}
      />
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(CommonOrderDetails);
