import { action } from 'typesafe-actions';
import { Status, WeighingActionTypes } from './types';

export const doWeighing = (measDeviceKeys: string[]) =>
  action(WeighingActionTypes.DO_WEIGHING, measDeviceKeys);
export const discardPreviousLoad = () => action(WeighingActionTypes.DISCARD_PREVIOUS_LOAD);
export const confirmWeighing = (isSaveTareRequested: boolean) =>
  action(WeighingActionTypes.CONFIRM_WEIGHING, isSaveTareRequested);
export const requestZeroing = (measDeviceKeys: string[]) =>
  action(WeighingActionTypes.REQUEST_ZERO, measDeviceKeys);
export const destroyContext = () => action(WeighingActionTypes.DESTROY_CONTEXT);
export const destroyContextWithId = (contextId: string) =>
  action(WeighingActionTypes.DESTROY_CONTEXT_WITH_ID, contextId);
export const destroyContextSucceeded = () => action(WeighingActionTypes.DESTROY_CONTEXT_SUCCEEDED);
export const finishContext = (isSaveTareRequested: boolean) =>
  action(WeighingActionTypes.FINISH_CONTEXT, isSaveTareRequested);
export const finishWithContextTare = (contextId: string) =>
  action(WeighingActionTypes.FINISH_WITH_CONTEXT_TARE, contextId);
export const inactivateContext = () => action(WeighingActionTypes.INACTIVATE_CONTEXT);
export const inactivateContextSucceeded = () =>
  action(WeighingActionTypes.INACTIVATE_CONTEXT_SUCCEEDED);
export const inactivateContextFailed = () => action(WeighingActionTypes.INACTIVATE_CONTEXT_FAILED);
export const saveTareAndFinishContext = (contextId: string) =>
  action(WeighingActionTypes.SAVE_TARE_AND_FINISH_CONTEXT, contextId);
export const statusSuccess = (data: Status) => action(WeighingActionTypes.STATUS_SUCCESS, data);
export const weighingSuccess = () => action(WeighingActionTypes.WEIGHING_SUCCESS);

// Operator
export const doManualWeighingByContextId = (contextId: string, kgs: number) =>
  action(WeighingActionTypes.DO_MANUAL_WEIGHING_BY_CONTEXT_ID, { contextId, kgs });
export const doWeighingByContextId = (contextId: string, measDeviceKeys: string[]) =>
  action(WeighingActionTypes.DO_WEIGHING_BY_CONTEXT_ID, { contextId, measDeviceKeys });
export const discardPreviousLoadByContextId = (contextId: string) =>
  action(WeighingActionTypes.DISCARD_PREVIOUS_LOAD_BY_CONTEXT_ID, contextId);
export const finishContextById = (contextId: string, isSaveTareRequested: boolean) =>
  action(WeighingActionTypes.FINISH_CONTEXT_BY_ID, { contextId, isSaveTareRequested });
export const inactivateContextById = (contextId: string) =>
  action(WeighingActionTypes.INACTIVATE_CONTEXT_BY_ID, contextId);
export const setOperatorTrailerKey = (operatorTrailerKey: string | undefined) =>
  action(WeighingActionTypes.SET_OPERATOR_TRAILER_KEY, operatorTrailerKey);
export const setOperatorFinishingOrderKey = (orderKey: string) =>
  action(WeighingActionTypes.SET_OPERATOR_FINISHING_ORDER_KEY, orderKey);
