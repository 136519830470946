import React, { useCallback, useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { Button, TextField } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import moment from 'moment';
import { connect, localeConnect } from 'localeConnect';
import { ApplicationState } from 'store';
import { ContainerRole, ContainerType, RemoteMasterDataType } from 'store/common/types';
import { searchCloudMasterData } from 'store/master-data/actions';
import {
  MasterDataCloudSearchResultItem,
  MasterDataCloudSearchResultMap,
} from 'store/master-data/types';
import {
  getTranslatedAndSortedSchemaEntries,
  getTranslatedTypeTitle,
} from 'store/master-data/utils';
import { MeasDevice } from 'store/meas-devices/types';

import { jobQueryRefresh, jobQueryRequest, storeOperatorQuery } from 'store/jobs/actions';
import {
  Job,
  JobQueryResponse,
  OperatorQueryMasterData,
  OperatorQueryParams,
} from 'store/jobs/types';
import { defaultOperatorQueryParams } from 'store/jobs/utils';
import { showOrderInfo } from 'store/orders/actions';

import theme, { operatorPaginationStyles, operatorSearchFilter } from 'theme';

import { translate } from 'utils/translate';
import { findTypesAndTitles, SchemaEntry } from 'store/scale-info/utils';
import Pagination from '../Pagination';
import { OperatorJobTableColumns, OperatorJobTableStyles } from './OperatorJobTable';
import OperatorSearch, { OperatorSearchResultItem } from './OperatorSearch';
import OperatorTable from './OperatorTable';

const dateRangeValue = parseInt(`${process.env.REACT_APP_JOB_QUERY_VALUE || '12'}`) as number;
const dateRangeUnit = `${process.env.REACT_APP_JOB_QUERY_UNIT || 'months'}` as string;

const filterHighlightColor = theme.palette.primary.main;

const useStyles = makeStyles({
  textField: {
    marginLeft: '0px',
    marginRight: '0px',
    width: '150px',
  },
  ...OperatorJobTableStyles,
  filterContainer: {
    // eslint-disable-next-line
    display: 'flex',
    // eslint-disable-next-line
    flexWrap: 'wrap',
    '& > div': {
      // eslint-disable-next-line
      border: `2px solid ${filterHighlightColor}`,
      // eslint-disable-next-line
      borderRadius: operatorSearchFilter.spacing,
      // eslint-disable-next-line
      padding: operatorSearchFilter.spacing,
      // eslint-disable-next-line
      marginRight: operatorSearchFilter.spacing,
      // eslint-disable-next-line
      marginBottom: operatorSearchFilter.spacing,
      '& label': {
        position: 'absolute',
        color: filterHighlightColor,
        transform: 'translate(0px, -20px) scale(0.75)',
        backgroundColor: theme.palette.background.default,
        paddingLeft: operatorSearchFilter.spacing,
        paddingRight: operatorSearchFilter.spacing,
      },
      '& label + .MuiInput-root': {
        marginTop: '0px',
      },
    },
  },
  filtersHidden: {
    display: 'none',
  },
  filterControlRow: {
    // eslint-disable-next-line
    marginBottom: operatorSearchFilter.spacing,
    // eslint-disable-next-line
    textAlign: 'left',
    '& .MuiButton-label': {
      textTransform: 'initial',
    },
  },
  jobTable: {
    marginBottom: '30px',
  },
  ...operatorPaginationStyles,
});

interface DatePickerProps {
  id: string;
  label: string;
  value: string;
  max?: string;
  min?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function DatePicker(props: DatePickerProps) {
  const classes = useStyles();
  const minMaxProps = { min: props.min, max: props.max };
  return (
    <div>
      <TextField
        variant="standard"
        id={props.id}
        label={props.label}
        inputProps={minMaxProps}
        type="date"
        value={props.value}
        className={classes.textField}
        onChange={props.onChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
  );
}

interface PropsFromState {
  measDevices: MeasDevice[];
  queryJobs: JobQueryResponse | undefined;
  queryParams: OperatorQueryParams;
  masterDataSearchResults: MasterDataCloudSearchResultMap;
  schema: Record<string, unknown> | undefined;
}

interface PropsFromDispatch {
  jobQueryRefresh: typeof jobQueryRefresh;
  jobQueryRequest: typeof jobQueryRequest;
  searchCloudMasterData: typeof searchCloudMasterData;
  showOrderInfo: typeof showOrderInfo;
  storeOperatorQuery: typeof storeOperatorQuery;
}

/**
 * NOTE(mikkogy, 20200221) There's a bug in Eslint config that has been reported but not yet fixed
 * which complains about missing indentation when multiline binary expressions are used.
 * See: https://github.com/typescript-eslint/typescript-eslint/issues/398
 */
/*eslint-disable */
export type AllProps = PropsFromDispatch & PropsFromState;
/*eslint-enable */

const mapStateToProps = (state: ApplicationState) => ({
  masterDataSearchResults: state.masterData.stringSearchResults,
  measDevices: state.measDevices.measDevices,
  queryJobs: state.jobs.queryJobs,
  queryParams: state.jobs.queryParams,
  schema: state.currentScaleInfo.domainInfo.masterDataCombo.schema,
});

const mapDispatchToProps = {
  jobQueryRefresh,
  jobQueryRequest,
  searchCloudMasterData,
  showOrderInfo,
  storeOperatorQuery,
};

const OperatorQueryJobs = (props: AllProps) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<{ [key: string]: Element }>({});
  const [areFiltersExpanded, setAreFiltersExpanded] = useState(true);
  const [isNoteOpen, setIsNoteOpen] = useState<{ [key: string]: boolean }>({});
  const [truckSearchStr, setTruckSearchStr] = useState('');
  const [schemaFilterStrings, setSchemaFilterStrings] = useState<Record<string, string>>({});
  const { schema } = props;

  function noTypables(entry: SchemaEntry) {
    return !entry.isTypable;
  }
  const entries = schema ? findTypesAndTitles(schema).filter(noTypables) : [];
  const schemaEntries = getTranslatedAndSortedSchemaEntries(entries);

  const { storeOperatorQuery, jobQueryRefresh, jobQueryRequest } = props;
  const doQuery = useCallback(
    (queryParams: OperatorQueryParams) => {
      storeOperatorQuery(queryParams);
      jobQueryRequest(queryParams);
    },
    [storeOperatorQuery, jobQueryRequest],
  );

  useEffect(() => {
    jobQueryRefresh();
  }, [jobQueryRefresh]);

  const doQueryWithChangedParams = (queryParams: OperatorQueryParams) => {
    // NOTE(mikkogy,20200304) user is not interested of a specific page when
    // filters have changed and results will change. We should show the first
    // page.
    const params = { ...queryParams };
    params.page = 0;
    doQuery(params);
  };

  const clearFilters = () => {
    const params = defaultOperatorQueryParams();
    setAnchorEl({});
    setAreFiltersExpanded(true);
    setIsNoteOpen({});
    setTruckSearchStr('');
    setSchemaFilterStrings({});
    doQuery(params);
  };

  const queryJobs = props.queryJobs;
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, key: string) => {
    const anchorEl: { [key: string]: Element } = {};
    anchorEl[key] = event.currentTarget;
    setAnchorEl(anchorEl);
  };

  const handleMenuClose = () => {
    setAnchorEl({});
  };

  const handleNoteOpen = (key: string) => {
    const isNoteOpen: { [key: string]: boolean } = {};
    isNoteOpen[key] = true;
    setIsNoteOpen(isNoteOpen);
  };

  const handleNoteClose = () => {
    setIsNoteOpen({});
  };

  const columnDefinitions = OperatorJobTableColumns(
    anchorEl,
    isNoteOpen,
    handleMenuClick,
    handleMenuClose,
    props.showOrderInfo,
  );
  const columns = [
    columnDefinitions.timestamp,
    columnDefinitions.status,
    columnDefinitions.receipt,
    columnDefinitions.registerNumber,
    columnDefinitions.transportCo,
    columnDefinitions.customer,
    columnDefinitions.material,
    columnDefinitions.orderNumber(queryJobs ? queryJobs.names : {}),
    columnDefinitions.note(classes.iconButton, handleNoteOpen, handleNoteClose),
    columnDefinitions.sumMass(classes.mass),
    columnDefinitions.measDevice(props.measDevices),
    columnDefinitions.menu,
  ];

  const pagination = {
    currentPageItemCount: queryJobs ? queryJobs.results.length : 0,
    itemCount: -1,
    pageNumber: queryJobs ? queryJobs.number + 1 : 1,
    pageSize: queryJobs ? queryJobs.size : 0,
  };

  const formatInitialDate = (time: number) => {
    return moment(time).format('YYYY-MM-DD');
  };

  const startDateChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newDate = event.target.value;
    if (!newDate) {
      newDate = formatInitialDate(defaultOperatorQueryParams().startTime);
    }
    const params = { ...props.queryParams };
    params.startTime = moment(newDate).startOf('day').toDate().getTime();
    doQueryWithChangedParams(params);
  };

  const endDateChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newDate = event.target.value;
    if (!newDate) {
      newDate = formatInitialDate(defaultOperatorQueryParams().endTime);
    }
    const params = { ...props.queryParams };
    params.endTime = moment(newDate).endOf('day').toDate().getTime();
    doQueryWithChangedParams(params);
  };

  const addMasterData = (queryItem: OperatorQueryMasterData) => {
    const params: OperatorQueryParams = { ...props.queryParams };
    params.masterData = [...params.masterData, queryItem];
    doQueryWithChangedParams(params);
  };

  const removeMasterData = (key: string) => {
    const params: OperatorQueryParams = { ...props.queryParams };
    params.masterData = params.masterData.filter((item) => item.key !== key);
    doQueryWithChangedParams(params);
  };

  const truckResults = props.masterDataSearchResults[RemoteMasterDataType.CONTAINER];
  const truckFilter = (item: MasterDataCloudSearchResultItem) =>
    item.structure?.containerType === ContainerType.TRUCK;

  const containerFilter = (item: MasterDataCloudSearchResultItem) =>
    item.structure?.containerType === ContainerType.GENERIC;

  const truckSearch = {
    searchResults: {
      searchStr: truckResults ? truckResults.searchString : '',
      // NOTE(mikkogy,20200228) UI side filtering is used instead of structure
      // parameter in API as also TRUCK_TRAILERS are likely needed in the
      // future. Also we'd have to refer to a known container by key which
      // would be a hack in this use case. By filtering in UI we may end up
      // without visible results if there's a lot of results with wrong type.
      result: truckResults ? truckResults.results.filter(truckFilter) : [],
    },
    search: (searchString: string) => {
      const params = {
        searchString,
        acceptType: RemoteMasterDataType.CONTAINER,
        acceptRole: ContainerRole.DIRECTIONLESS_CONTAINER,
        ignoredTypes: [],
      };
      props.searchCloudMasterData(params);
      setTruckSearchStr(searchString);
    },
    currentSelection: props.queryParams.masterData.filter(
      (item) =>
        item.key.includes(RemoteMasterDataType.CONTAINER) &&
        item.role === ContainerRole.DIRECTIONLESS_CONTAINER,
    ),
    select: (item: OperatorSearchResultItem) => {
      const queryItem: OperatorQueryMasterData = {
        key: item.key,
        name: item.name,
        role: ContainerRole.DIRECTIONLESS_CONTAINER,
      };
      addMasterData(queryItem);
      setTruckSearchStr('');
    },
    searchStr: truckSearchStr,
  };

  const remove = (key: string) => {
    removeMasterData(key);
  };

  function getRole(typeName: string) {
    return typeName === RemoteMasterDataType.CONTAINER ? ContainerRole.SOURCE_CONTAINER : undefined;
  }

  function getSearchResults(typeName: string) {
    const results = props.masterDataSearchResults[typeName];
    if (typeName === RemoteMasterDataType.CONTAINER) {
      return {
        searchStr: results ? results.searchString : '',
        result: results ? results.results.filter(containerFilter) : [],
      };
    }
    return {
      searchStr: results ? results.searchString : '',
      result: results ? results.results : [],
    };
  }

  function setFilterString(typeName: string, searchString: string) {
    const filterStrings = Object.assign({}, schemaFilterStrings);
    filterStrings[typeName] = searchString;
    setSchemaFilterStrings(filterStrings);
  }

  return (
    <div>
      <div className={classes.filterControlRow}>
        <Button onClick={() => setAreFiltersExpanded(!areFiltersExpanded)}>
          <>
            {translate('operatorJobs.filters.title')}
            {areFiltersExpanded ? <ExpandLess /> : <ExpandMore />}
          </>
        </Button>
        <Button color="primary" onClick={clearFilters}>
          {translate('operatorJobs.filters.clear')}
        </Button>
      </div>
      <form
        className={`${classes.filterContainer} ${areFiltersExpanded ? '' : classes.filtersHidden} `}
        noValidate
      >
        <DatePicker
          id="start-picker"
          max={formatInitialDate(moment(props.queryParams.endTime).valueOf())}
          min={formatInitialDate(
            //@ts-ignore
            moment(props.queryParams.endTime)
              // NOTE(mikkogy,20200228) TS compiler does not handle variables
              // if read from env instead of hard-coding even when types match
              // exactly.
              //@ts-ignore
              .subtract(dateRangeValue, dateRangeUnit)
              .valueOf(),
          )}
          label={translate('operatorJobs.filters.startDate')}
          value={formatInitialDate(props.queryParams.startTime)}
          onChange={startDateChanged}
        />
        <DatePicker
          id="end-picker"
          max={formatInitialDate(
            //@ts-ignore
            moment(props.queryParams.startTime)
              // NOTE(mikkogy,20200228) TS compiler does not handle variables
              // if read from env instead of hard-coding even when types match
              // exactly.
              //@ts-ignore
              .add(dateRangeValue, dateRangeUnit)
              .valueOf(),
          )}
          min={formatInitialDate(moment(props.queryParams.startTime).valueOf())}
          label={translate('operatorJobs.filters.endDate')}
          value={formatInitialDate(props.queryParams.endTime)}
          onChange={endDateChanged}
        />
        <OperatorSearch
          title={translate('operatorJobs.filters.trucks')}
          searchResults={truckSearch.searchResults}
          search={truckSearch.search}
          currentSelection={truckSearch.currentSelection}
          select={truckSearch.select}
          remove={remove}
          searchStr={truckSearch.searchStr}
        />
        {schemaEntries.map((entry: SchemaEntry) => {
          const searchResults = getSearchResults(entry.typeName);
          const search = (searchString: string) => {
            const params = {
              searchString,
              acceptType: entry.typeName,
              acceptRole: getRole(entry.typeName),
              ignoredTypes: [],
            };
            props.searchCloudMasterData(params);
            setFilterString(entry.typeName, searchString);
          };
          const currentSelection = props.queryParams.masterData.filter((item) => {
            const isCorrectType = item.key.includes(entry.typeName);
            if (entry.typeName === RemoteMasterDataType.CONTAINER) {
              return isCorrectType && item.role === ContainerRole.SOURCE_CONTAINER;
            }
            return isCorrectType;
          });
          const select = (item: OperatorSearchResultItem) => {
            const queryItem: OperatorQueryMasterData = {
              key: item.key,
              name: item.name,
              role: getRole(entry.typeName),
            };
            addMasterData(queryItem);
            setFilterString(entry.typeName, '');
          };
          const title = getTranslatedTypeTitle(entry);
          return (
            <OperatorSearch
              key={entry.title}
              title={title}
              searchResults={searchResults}
              search={search}
              currentSelection={currentSelection}
              select={select}
              remove={remove}
              searchStr={schemaFilterStrings[entry.typeName] ?? ''}
            />
          );
        })}
      </form>
      <div className={classes.pagination}>
        <Pagination
          pagination={pagination}
          showPage={(pageNumber: number) => {
            const params = { ...props.queryParams };
            params.page = pageNumber - 1;
            doQuery(params);
          }}
        />
      </div>
      <div>
        {queryJobs && queryJobs.results.length > 0 && (
          <div className={classes.jobTable}>
            <OperatorTable
              columns={columns}
              data={queryJobs.results}
              keyGetter={(job: Job) => job.key}
            />
          </div>
        )}
        {queryJobs && queryJobs.results.length === 0 && (
          <div>{translate('operatorJobs.noResults')}</div>
        )}
        {!queryJobs && <div>{translate('operatorJobs.notAvailable')}</div>}
      </div>
    </div>
  );
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
export default localeConnect<typeof connectResult>(
  mapStateToProps,
  mapDispatchToProps,
)(OperatorQueryJobs);
