import { makeStyles } from '@mui/styles';
import { breakLongText } from '../theme';
import { BridgeEnabledFeatures } from '../store/scale-info/types';
import { FormatUnit, undefinedMassKg } from '../store/weighing/types';

import {
  NormalWeighingDeduction,
  toNormalWeighingDeduction,
} from '../models/normalweighingdeduction.model';
import { getMaterialData } from '../models/order.model';
import { SplitLoadModel } from '../models/splitload.model';
import { NetContainer, isNetNegative, isNetWeightVisible } from '../models/vehicleweighing.model';

import { ComponentGroup, Order } from '../store/orders/types';
import { getNormalWeighingMainComponents } from '../store/orders/utils';

import { truncateLongTextWithEllipsis } from '../utils/stringUtils';
import { translate } from '../utils/translate';
import CardDetailItem from './CardDetailItem';
import SecondaryContainerTareFeature from './SecondaryContainerTareFeature';
import DeductionHint from './DeductionHint';
import { formatMass } from './utils';

const useStyles = makeStyles({
  deductionInfoSection: {
    paddingBottom: '10px',
    ...breakLongText,
  },
  deductionInfoContainer: {
    paddingBottom: '10px',
  },
  lineWithKgs: {
    display: 'inline-block',
  },
  comment: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
  },
});

function formatExplanation(explanationText: string | undefined) {
  return explanationText ? ` ${explanationText}` : '';
}

function getKgsWithDeductions(
  weighingDeduction: NormalWeighingDeduction,
  weighedKgs: number | undefined,
) {
  if (weighedKgs === undefinedMassKg || weighedKgs === undefined) return undefinedMassKg;
  const tareDeductionKgs = weighingDeduction.tareDeductionKgs || 0;
  const manualDeductionKgs = weighingDeduction.manualDeductionKgs || 0;
  return weighedKgs - tareDeductionKgs - manualDeductionKgs;
}

interface NoNetDeductionLineProps {
  deductionKgs: number;
  explanationText: string | undefined;
}

const NoNetDeductionLine = (props: NoNetDeductionLineProps) => {
  const explanation = formatExplanation(props.explanationText);
  return (
    <div>{`${explanation} ${formatMass(Math.abs(props.deductionKgs), FormatUnit.WITH_UNIT)}`}</div>
  );
};

export interface DeductionLineWithMaterialProps {
  deductionKgs: number;
  materialName?: string;
  comment: string;
}

export const DeductionLineWithMaterial = (props: DeductionLineWithMaterialProps) => {
  let explanation: string;
  if (props.materialName === undefined) {
    explanation = translate('deductionDetails.otherMaterial');
  } else {
    explanation = props.materialName;
  }
  return (
    <MaterialWithKgs
      materialName={explanation}
      materialKgs={props.deductionKgs}
      comment={props.comment}
    />
  );
};

function formatMassKg(kgs: number) {
  return formatMass(Math.abs(kgs), FormatUnit.WITH_UNIT);
}

interface MaterialWithKgsProps {
  materialName: string;
  materialKgs: number;
  comment: string;
}

const MaterialWithKgs = (props: MaterialWithKgsProps) => {
  const classes = useStyles();

  const commentToShow = truncateLongTextWithEllipsis(props.comment, 400);

  return (
    <div className={classes.deductionInfoContainer}>
      {props.materialName && <span>{`${props.materialName}`}&nbsp;</span>}
      <span className={classes.lineWithKgs}>{`${formatMassKg(props.materialKgs)}`}</span>
      <div className={classes.comment}>{commentToShow}</div>
    </div>
  );
};

interface ParameterProps {
  deductionClassName: string;
  net: NetContainer;
  order: Order;
  enabledFeatures: BridgeEnabledFeatures;
  splitLoadModel: SplitLoadModel;
}

type AllProps = ParameterProps;

const DeductionInfo = (props: AllProps) => {
  const classes = useStyles();
  const manualDeductionKgs =
    props.net.manualDeductions?.reduce((sum, deduction) => {
      return sum + deduction.kgs;
    }, 0) ?? 0;
  const weighedKgs = props.net.weighedKgs;
  const order = props.order;
  const enabledFeatures = props.enabledFeatures;
  const manualDeductions = props.net.manualDeductions;

  const components = getNormalWeighingMainComponents(props.order);
  const componentId =
    props.splitLoadModel.componentGroup === ComponentGroup.TRUCK
      ? components.truck.id
      : components.trailer?.id;

  const deduction = toNormalWeighingDeduction(
    manualDeductionKgs,
    order,
    componentId,
    enabledFeatures,
  );
  const filteredAdditions = manualDeductions?.filter(
    (manualDeduction) => manualDeduction.isAddition,
  );
  const filteredDeductions = manualDeductions?.filter(
    (manualDeduction) => !manualDeduction.isAddition,
  );
  function getSelectedMainMaterialName(componentId: string) {
    const selectedMainMaterial = getMaterialData(order, componentId);
    return selectedMainMaterial?.name ?? translate('deductionDetails.otherMaterial');
  }

  function getTotal(componentId: string) {
    if (props.splitLoadModel.isSplitEnabled) {
      return <div>{formatMassKg(getKgsWithDeductions(deduction, weighedKgs))}</div>;
    }
    return (
      <div className={classes.deductionInfoSection}>
        <MaterialWithKgs
          materialName={getSelectedMainMaterialName(componentId)}
          materialKgs={getKgsWithDeductions(deduction, weighedKgs)}
          comment={''} // NOTE(renttom,20220725) main material does not have a comment
        />
      </div>
    );
  }

  return (
    <div className={props.deductionClassName}>
      {deduction.manualDeductionKgs && weighedKgs !== undefinedMassKg && (
        <div className={classes.deductionInfoSection}>
          <DeductionHint hasError={isNetNegative(props.net)} />
        </div>
      )}
      <CardDetailItem
        title={translate('deductionDetails.deductionsTitle')}
        value={
          <div className={classes.deductionInfoSection}>
            {deduction.tareDeductionKgs && componentId && (
              <SecondaryContainerTareFeature componentId={componentId} order={order}>
                <NoNetDeductionLine
                  deductionKgs={deduction.tareDeductionKgs}
                  explanationText={`${deduction.tareContainerName} (${translate(
                    'masterData.CONTAINER_TITLE',
                  ).toLowerCase()})`}
                />
              </SecondaryContainerTareFeature>
            )}
            {filteredDeductions?.length ? (
              <div>
                {filteredDeductions.map((deduction, index) => {
                  return (
                    <DeductionLineWithMaterial
                      key={index}
                      deductionKgs={deduction.kgs}
                      materialName={deduction.material?.name}
                      comment={deduction.comment}
                    />
                  );
                })}
              </div>
            ) : (
              !deduction.tareDeductionKgs && (
                <div>{formatMass(undefinedMassKg, FormatUnit.WITHOUT_UNIT)}</div>
              )
            )}
          </div>
        }
      />
      <CardDetailItem
        title={translate('deductionDetails.additionsTitle')}
        value={
          <div className={classes.deductionInfoSection}>
            {filteredAdditions?.length ? (
              <div>
                {filteredAdditions.map((addition, index) => {
                  return (
                    <DeductionLineWithMaterial
                      key={index}
                      deductionKgs={addition.kgs}
                      materialName={
                        props.splitLoadModel.isSplitEnabled || componentId === undefined
                          ? ''
                          : getSelectedMainMaterialName(componentId)
                      }
                      comment={addition.comment}
                    />
                  );
                })}
              </div>
            ) : (
              <div>{formatMass(undefinedMassKg, FormatUnit.WITHOUT_UNIT)}</div>
            )}
          </div>
        }
      />
      {componentId && isNetWeightVisible(props.net) && (
        <div>
          <CardDetailItem
            title={translate('deductionDetails.totalTitle')}
            value={getTotal(componentId)}
          />
        </div>
      )}
    </div>
  );
};

export default DeductionInfo;
